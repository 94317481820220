import React, { useCallback, useState } from 'react'
import { BUTTON_SIZE, BUTTON_THEME, Button, icons } from 'src/lib'
import { Steps } from 'src/lib/steps'
import { IUsePurpose } from './types'
import TypesOfBusiness from 'src/components/user-classify/types-of-business/TypesOfBusiness'
import HearAboutUs from 'src/components/user-classify/hear-about-us/HearAboutUs'
import userClassifyImg from 'src/assets/images/job-icon.svg'
import businessImg from 'src/assets/images/business-icon.svg'
import educationImg from 'src/assets/images/education-icon.svg'
import findImg from 'src/assets/images/find-us-icon.svg'
import {
  wrapperStyles,
  leftStyles,
  descriptionStyles,
  stepStyles,
  rightStyles,
  textWrapperStyles,
  titleStyles,
  buttonsStyles,
} from '../styles'
import { useLanguage } from 'src/hooks'
import { EducationStep } from '../education-step'

export const UsePurpose: React.FC<IUsePurpose> = React.memo(
  ({ step, onNextStep }) => {
    const { t } = useLanguage()
    const [selectedPurpose, setSelectedPurpose] = useState('')
    const [selectedBusinessType, setSelectedBusinessType] = useState('')
    const handleClick = async (purpose: string) => {
      setSelectedPurpose(purpose)
      onNextStep()
    }
    const purposeData = [
      {
        step: 1,
        icon: userClassifyImg,
        text: t('user_classify.classify_question'),
      },
      {
        step: 2,
        icon: selectedPurpose !== 'Education' ? businessImg : educationImg,
        text: t('user_classify.business_question'),
      },
      { step: 3, icon: findImg, text: t('user_classify.how_to_hear_about_us') },
    ]
    const handleImage = useCallback(() => {
      const stepData = purposeData.find((data) => data.step === step)
      return stepData ? <img src={stepData.icon} /> : null
    }, [step, purposeData])
    return (
      <div css={wrapperStyles}>
        <div css={leftStyles}>{handleImage()}</div>
        <div css={rightStyles}>
          <div css={stepStyles}>
            <Steps steps={3} current={step}></Steps>
          </div>
          <div css={textWrapperStyles}>
            <div css={titleStyles}>
              {t('user_classify.tell_us_about_yourself')}
            </div>
            <div css={descriptionStyles}>
              {purposeData.find((data) => data.step === step)?.text}
            </div>
          </div>
          <div css={buttonsStyles}>
            {step === 1 && (
              <>
                <Button
                  icon={icons.business}
                  onClick={() => handleClick('Business')}
                  size={BUTTON_SIZE.XSMALL}
                  text={t('user_classify.business')}
                  theme={BUTTON_THEME.WHITE}
                />
                <Button
                  icon={icons.user}
                  onClick={() => handleClick('Personal')}
                  size={BUTTON_SIZE.XSMALL}
                  text={t('common.personal')}
                  theme={BUTTON_THEME.WHITE}
                />
                <Button
                  icon={icons.graduation_cap}
                  onClick={() => handleClick('Education')}
                  size={BUTTON_SIZE.XSMALL}
                  text={t('user_classify.education')}
                  theme={BUTTON_THEME.WHITE}
                />
              </>
            )}
            {step === 2 && selectedPurpose === 'Education' && (
              <EducationStep
                onNextStep={onNextStep}
                selectedPurpose={selectedPurpose}
                selectedBusinessType={selectedBusinessType}
                setSelectedBusinessType={setSelectedBusinessType}
              />
            )}
            {step === 2 && selectedPurpose !== 'Education' && (
              <TypesOfBusiness
                onNextStep={onNextStep}
                selectedPurpose={selectedPurpose}
                selectedBusinessType={selectedBusinessType}
                setSelectedBusinessType={setSelectedBusinessType}
              />
            )}
            {step === 3 && (
              <HearAboutUs
                onNextStep={onNextStep}
                selectedPurpose={selectedPurpose}
                selectedBusinessType={selectedBusinessType}
              />
            )}
          </div>
        </div>
      </div>
    )
  },
)
export default UsePurpose
