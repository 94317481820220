import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { useLanguage } from 'src/hooks'
import { IColorPalette } from './types'
import { paletteWrapperStyles } from './styles'
import { Icon, TooltipView, icons } from 'src/lib'
import { colors } from 'src/theme'

export const ColorPalette: React.FC<IColorPalette> = React.memo(
  ({
    id,
    first,
    second,
    third,
    fourth,
    className,
    dataAttr,
    displayOnly,
    deleteMode,
    context,
  }) => {
    const { t } = useLanguage()

    const { activeThemeColorData, activeThemeColorId } = useSelector(
      ({ edit }: RootState) => ({
        activeThemeColorData: edit.activeDeck.data?.deckData?.themeColor.data,
        activeThemeColorId: edit.activeDeck.data?.deckData?.themeColor.id,
      }),
    )

    const firstColor = first ?? activeThemeColorData?.first
    const secondColor = second ?? activeThemeColorData?.second
    const thirdColor = third ?? activeThemeColorData?.third
    const fourthColor = fourth ?? activeThemeColorData?.fourth

    return (
      <div
        css={paletteWrapperStyles({
          isSelected: activeThemeColorId === id && !displayOnly,
          deleteMode,
          context,
          displayOnly,
        })}
        className={className}
        {...dataAttr}
      >
        {context && context !== 'predefined' && (
          <div className="font-box-icon">
            {context === 'sharedOnOrg' && (
              <Icon icon={icons.team} color={colors.white.DEFAULT} size={16} />
            )}
            {context === 'own' && (
              <Icon icon={icons.user} color={colors.white.DEFAULT} size={16} />
            )}
          </div>
        )}
        <div className="palette-inline">
          <div className="color-box" style={{ backgroundColor: firstColor }} />
          <div className="color-box" style={{ backgroundColor: secondColor }} />
          <div className="color-box" style={{ backgroundColor: thirdColor }} />
          <div className="color-box" style={{ backgroundColor: fourthColor }} />
        </div>
        {context !== 'predefined' && (
          <div className="palette-actions">
            <Icon icon={icons.trash_can} color={colors.white.DEFAULT} />
          </div>
        )}
        <TooltipView
          width={200}
          text={t('edit.design.default_color_palettes')}
          className="palette-tooltip"
        />
      </div>
    )
  },
)

ColorPalette.displayName = 'ColorPalette'
