import React, { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SubscriptionSettings } from 'src/components/settings-panel/components'
import { useStripePortalUrls } from 'src/components/settings-panel/components/subscriptions/hooks/useStripePortalUrls'
import { usePlanApi, useUserApi } from 'src/hooks'
import { PlanNames, PlanPeriods } from 'src/types/api/enums'

export const PaymentPage: React.FC = React.memo(() => {
  const [searchParams] = useSearchParams()
  const { getPortalUrl } = useUserApi()
  const { purchasePlan } = usePlanApi()
  const { getStripePortalUrl } = useStripePortalUrls()

  useEffect(() => {
    const planName = searchParams.get('name') as PlanNames
    const planPeriod = searchParams.get('period') as PlanPeriods
    const seatSize = searchParams.get('size')
    const subscriptionSettings = searchParams.get(
      'subscriptionSettings',
    ) as SubscriptionSettings
    if (subscriptionSettings) {
      subscriptionSettingsHandler(subscriptionSettings)
      return
    }
    if (planName && planPeriod && seatSize) {
      updatePlanHandler()
    }
  }, [searchParams])

  const subscriptionSettingsHandler = useCallback(
    async (setting: SubscriptionSettings) => {
      const res = await getPortalUrl()

      const url = getStripePortalUrl(res, setting)
      if (res) window.location.href = url
    },
    [],
  )

  const updatePlanHandler = useCallback(async () => {
    const planName = searchParams.get('name') as PlanNames
    const planPeriod = searchParams.get('period') as PlanPeriods
    const seatSize = searchParams.get('size')

    const res = await purchasePlan({
      planName,
      planPeriod,
      seatSize: Number(seatSize),
      isPreview: false,
    })
    if (res.checkoutSessionUrl) window.location.href = res.checkoutSessionUrl
    else {
      localStorage.removeItem('payment-awaits')
      window.close()
    }
  }, [searchParams])

  return <></>
})

PaymentPage.displayName = 'Payment page'
