export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_119)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_9_119"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_164)"><path fill-rule="evenodd" clip-rule="evenodd" d="M704 0H1920V1080H0V720H704V0Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1056 360H704V0H1056V360Z" fill="$color1"/><path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M704 360H352V0H704V360Z" fill="$color2"/><path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" d="M704 720H352V360H704V720Z" fill="$color3"/><path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M352 360H0V0H352V360Z" fill="$color3"/><path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M352 720H0V360H352V720Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M352 1080H0V720H352V1080Z" fill="$color2"/></g><defs><clipPath id="clip0_9_164"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_174)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM102 102H960V978H102V102Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000488281 132.978H66.4884V66.4883H-0.000488281V132.978Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M66.4884 66.4883L132.979 66.4889V0H66.4888L66.4884 66.4883Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000488281 66.4883H66.4884L66.4888 0H-0.000488281V66.4883Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 947.023H1853.51V1013.51H1920V947.023Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1853.51 1013.51L1787.02 1013.51V1080H1853.51L1853.51 1013.51Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 1013.51H1853.51L1853.51 1080H1920V1013.51Z" fill="$color2"/></g><defs><clipPath id="clip0_9_174"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_148)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1216 0H0V1080H1920V720H1216V0Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M864 360H1216V0H864V360Z" fill="$color1"/><path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M1216 360H1568V0H1216V360Z" fill="$color2"/><path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" d="M1216 720H1568V360H1216V720Z" fill="$color3"/><path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M1568 360H1920V0H1568V360Z" fill="$color3"/><path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M1568 720H1920V360H1568V720Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1568 1080H1920V720H1568V1080Z" fill="$color2"/></g><defs><clipPath id="clip0_9_148"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_158)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1818 102H960V978H1818V102Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 132.978H1853.51V66.4883H1920V132.978Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1853.51 66.4883L1787.02 66.4889V0H1853.51L1853.51 66.4883Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 66.4883H1853.51L1853.51 0H1920V66.4883Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 947.023H66.4889V1013.51H0V947.023Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M66.4889 1013.51L132.979 1013.51V1080H66.4893L66.4889 1013.51Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 1013.51H66.4889L66.4893 1080H0V1013.51Z" fill="$color2"/></g><defs><clipPath id="clip0_9_158"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_196)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000488281 132.978H66.4884V66.4883H-0.000488281V132.978Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M66.4884 66.4883L132.979 66.4889V0H66.4888L66.4884 66.4883Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000488281 66.4883H66.4884L66.4888 0H-0.000488281V66.4883Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 947.023H1853.51V1013.51H1920V947.023Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1853.51 1013.51L1787.02 1013.51V1080H1853.51L1853.51 1013.51Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 1013.51H1853.51L1853.51 1080H1920V1013.51Z" fill="$color2"/></g><defs><clipPath id="clip0_9_196"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_188)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM104 540H1816V976H104V540Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000488281 132.978H66.4884V66.4883H-0.000488281V132.978Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M66.4884 66.4883L132.979 66.4889V0H66.4888L66.4884 66.4883Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-0.000488281 66.4883H66.4884L66.4888 0H-0.000488281V66.4883Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 947.023H1853.51V1013.51H1920V947.023Z" fill="$color1"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1853.51 1013.51L1787.02 1013.51V1080H1853.51L1853.51 1013.51Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 1013.51H1853.51L1853.51 1080H1920V1013.51Z" fill="$color2"/></g><defs><clipPath id="clip0_9_188"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="540" transform="matrix(-1 0 0 1 1920 0)" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H1920V1080H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H0V1080H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
