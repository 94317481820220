import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  display: flex;
  align-items: flex-start;
  gap: ${theme.gap.small}px;
`

interface IButtonStyles {
  isFixedWidth?: boolean
  disabled?: boolean
}
export const buttonStyles =
  ({ isFixedWidth, disabled }: IButtonStyles) =>
  (theme: ITheme) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${isFixedWidth ? theme.dimensions.formElementHeight.medium : 86}px;
    height: ${theme.dimensions.formElementHeight.DEFAULT}px;
    border: ${'1px solid' + theme.colors.primary.DEFAULT};
    border-radius: ${theme.borderRadius[4]};
    transition: background 0.3s;
    user-select: none;
    cursor: pointer;

    &:not(:active):hover:before {
      background: ${theme.colors.black[10]};
    }
    &:hover {
      background: ${theme.colors.black[10]};
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    .button-text {
      font-size: ${theme.fontSize.small};
      font-weight: ${theme.fontWeight.medium};
      color: ${theme.colors.primary.DEFAULT};
    }

    .minus {
      background: ${theme.colors.primary.DEFAULT};
      width: 10px;
      height: 1.5px;
      border-radius: 1px;
    }

    ${disabled &&
    `
      pointer-events: none;
      background: ${theme.colors.deactive.DEFAULT};
    `}
  `
