import { dataAttrType } from 'src/types'

export interface ISubscriptions {
  onPanelOpen?: (index: number) => void
  className?: string
  dataAttr?: dataAttrType
}
export enum SubscriptionSettings {
  PAYMENT_METHOD = 'payment-method',
  UPDATE_BILLING = 'update-billing',
  INVOICES = 'invoices',
  CANCEL = 'cancel',
  RENEW = 'renew',
}
