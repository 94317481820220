import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setFolders } from 'src/store'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { getFoldersResponse } from 'src/types/api/responseObjects'
import { ViewModes } from 'src/types/api/enums'

interface IUseFolderApiReturn {
  getFolders: () => Promise<void>
  deleteFolder: (id: number) => Promise<boolean>
  createFolder: (name: string) => Promise<boolean>
  renameFolder: (params: { id: number; name: string }) => Promise<boolean>
  isLoading: boolean
}

export const useFolderApi = (): IUseFolderApiReturn => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { workspaceId, workspaceFiltering } = useSelector(
    ({ workspace }: RootState) => ({
      workspaceId: workspace.id,
      workspaceFiltering: workspace.filtering,
    }),
  )

  const getFolders = async () => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getFoldersResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.FOLDERS,
          params: {
            ...(workspaceId && {
              organizationId: workspaceId,
            }),
            ...(workspaceId && {
              viewMode:
                workspaceId && workspaceFiltering === 'organization'
                  ? ViewModes.ORGANIZATION
                  : ViewModes.PRIVATE,
            }),
          },
        })
      dispatch(setFolders(res.data.data.folders))
    } finally {
      setIsLoading(false)
    }
  }

  const renameFolder = async ({
    id,
    name,
  }: {
    id: number
    name: string
  }): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: `${API_CONFIG.FOLDERS}/${id}`,
        data: {
          name,
        },
      })
      getFolders()
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const createFolder = async (name: string): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: API_CONFIG.FOLDERS,
        data: {
          name,
          organizationId: workspaceId,
          viewMode:
            workspaceId && workspaceFiltering === 'organization'
              ? ViewModes.ORGANIZATION
              : ViewModes.PRIVATE,
        },
      })
      getFolders()
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const deleteFolder = async (id: number): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.request({
        method: 'DELETE',
        url: `${API_CONFIG.FOLDERS}/${id}`,
      })
      getFolders()
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return { getFolders, createFolder, deleteFolder, renameFolder, isLoading }
}
