import React, { useEffect } from 'react'
import { useLayoutsApi } from 'src/hooks'
import { DeckPageLayout } from './DeckPageLayout'
import { DECK_PAGE_MODE, IDeckPageLayout } from './types'

export const DeckPageLayoutV3: React.FC<IDeckPageLayout> = React.memo(
  ({ mode = DECK_PAGE_MODE.EDIT }) => {
    const { setLayoutVersion } = useLayoutsApi()

    useEffect(() => {
      setLayoutVersion('v3')
    }, [setLayoutVersion])

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <DeckPageLayout mode={mode} />
      </div>
    )
  },
)
