import React from 'react'
import { Button } from 'src/lib/button/Button'
import {
  customDomainContainerStyles,
  shareTextStyles,
  spanWrapperStyles,
  tableWrapperStyles,
} from 'src/components/share-view/styles'
import { BUTTON_THEME, Icon, icons, BUTTON_SIZE } from 'src/lib'
import { CustomDomainProps } from './types'
import { useNotification, useLanguage } from 'src/hooks'
import env from 'react-dotenv'

export const CustomDomain: React.FC<CustomDomainProps> = React.memo(
  ({ onClose, customDomain, isVerified }) => {
    const { t } = useLanguage()
    const { success, error } = useNotification()

    return (
      <div>
        <div css={customDomainContainerStyles}>
          <Icon
            icon={icons.chevron_left}
            size={20}
            onClick={() => onClose()}
          ></Icon>
          <span css={spanWrapperStyles}>
            {t('share_modal.custom_domain.connect_your_custom_domain')}
          </span>
        </div>
        <span css={shareTextStyles}>
          {t('share_modal.custom_domain.dns_record')}
        </span>
        <div css={tableWrapperStyles}>
          <table>
            <thead>
              <tr>
                <th>{t('share_modal.custom_domain.status')}</th>
                <th>{t('share_modal.custom_domain.host')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  {isVerified
                    ? t('share_modal.custom_domain.completed')
                    : t('share_modal.custom_domain.pending')}
                </th>
                <th>{customDomain.replace(/http(s)?\:\/\//, '')}</th>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>{t('share_modal.custom_domain.record')}</th>
                <th>{t('share_modal.custom_domain.data')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{t('share_modal.custom_domain.cname')}</th>
                <th>{env.CNAME_URL || 'cname.decktopus.com'}</th>
              </tr>
            </tbody>
          </table>
        </div>
        <Button
          size={BUTTON_SIZE.SMALL}
          theme={BUTTON_THEME.PRIMARY}
          text={t('common.actions.check')}
          onClick={() => {
            if (isVerified) {
              success('share_modal.custom_domain.domain_successfully')
            } else {
              error('share_modal.custom_domain.domain_failed')
            }
          }}
        />
      </div>
    )
  },
)
