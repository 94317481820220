import { UserLanguages } from 'src/types/api/enums'

export const languageToEnum = (lang: string) => {
  switch (lang) {
    case 'tr':
      return UserLanguages.TR_TR
    case 'fr':
      return UserLanguages.FR_CA
    case 'pt':
      return UserLanguages.PT_BR
    case 'es':
      return UserLanguages.ES_ES
    case 'de':
      return UserLanguages.DE_DE
    case 'en':
    default:
      return UserLanguages.EN_US
  }
}

type UserLanguagesValues = UserLanguages
export const enumToLanguage = (value: UserLanguagesValues) => {
  switch (value) {
    case UserLanguages.TR_TR:
      return 'tr'
    case UserLanguages.FR_CA:
      return 'fr'
    case UserLanguages.DE_DE:
      return 'de'
    case UserLanguages.PT_BR:
      return 'pt'
    case UserLanguages.ES_ES:
      return 'es'
    case UserLanguages.EN_US:
    default:
      return 'en'
  }
}
