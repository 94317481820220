import { css, keyframes } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IColorItem, IPropColor } from './types'

const shake = keyframes`
	0% {
		transform: translate(0, -0.5px);
	}
	25% {
		transform: translate(0.5px, 0.5px);
	}

	50% {
		transform: translate(-0.5px, 0);
	}

	75% {
		transform: translate(0.5px, 0.5px);
	}

	100% {
		transform: translate(0, -0.5px);
	}
`

type IPropColorStyles = Pick<IPropColor, 'disabled'>

export const propColorStyles =
  ({ disabled }: IPropColorStyles) =>
  ({ colors, dimensions, gap, borderRadius }: ITheme) => css`
    position: relative;
    width: 100%;
    background: ${colors.dark.DEFAULT};
    height: ${dimensions.formElementHeight.big}px;
    border-radius: ${borderRadius[4]};
    display: flex;
    gap: ${gap.xsmall}px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${disabled &&
    `
			opacity: 0.3;
			pointer-events: none;
		`}
  `

export const colorDisplayStyles = ({ borderRadius, colors }: ITheme) => css`
  width: 100%;
  height: 100%;
  border-radius: ${borderRadius[4]};
  overflow: hidden;
  border: 1px solid ${colors.white.DEFAULT};
`

export const menuStyles = ({ gap }: ITheme) => css`
  z-index: 1;
  display: flex;
  gap: ${gap['3xsmall']}px;

  .color-picker {
    position: absolute;
    left: calc(100% + ${gap['3xsmall']}px);
    bottom: 0;
  }
`

export const colorSelectionStyles = ({
  gap,
  colors,
  borderRadius,
  boxShadow,
}: ITheme) => css`
  position: relative;
  width: 264px;
  padding: ${gap.medium}px;
  background: ${colors.dark.DEFAULT};
  border-radius: ${borderRadius[4]};
  display: flex;
  flex-direction: column;
  gap: ${gap.medium}px;
  box-shadow: ${boxShadow[4]};
  cursor: default;

  .close-button {
    position: absolute;
    top: ${gap.medium}px;
    right: ${gap.medium}px;
  }
`

export const colorCategoryStyles = ({ gap, colors, fontSize }: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap.xsmall}px;

  .title {
    color: ${colors.white.DEFAULT};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .edit-button {
    color: ${colors.text[2]};
    cursor: pointer;
    text-decoration: underline;
    font-size: ${fontSize.small};
  }

  .color-list {
    display: flex;
    flex-wrap: wrap;
    gap: ${gap.xsmall}px;
    max-height: 244px;
    overflow-y: auto;
    overflow-x: hidden;
  }
`

type IColorItemStyles = Pick<
  IColorItem,
  'isActive' | 'disabled' | 'isDeleteMode'
>
export const colorItemStyles =
  ({ isActive, disabled }: IColorItemStyles) =>
  ({ colors, borderRadius }: ITheme) => css`
    width: 32px;
    height: 32px;
    border: 1px solid ${colors.text[2]};
    border-radius: ${borderRadius[4]};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    ${isActive &&
    `
    	border: 2px solid ${colors.primary.DEFAULT};
		`}

    ${disabled && `opacity: 0.5;`}

		&.shake {
      animation-name: ${shake};
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      &.sh-1 {
        animation-duration: 0.2s;
      }
      &.sh-2 {
        animation-duration: 0.25s;
      }
      &.sh-3 {
        animation-duration: 0.32s;
      }
      &.sh-4 {
        animation-duration: 0.4s;
      }
      &.sh-5 {
        animation-duration: 0.15s;
      }
    }
  `
