import React, { useEffect, useMemo, useRef } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { ISlideCard } from './types'
import { draggableAreaStyles, slideCardStyles } from './styles'
import { Canvas, CANVAS_DATA_TYPE, CANVAS_TYPE } from 'src/components/canvas'
import { useActiveColorMap, useDecksApi } from 'src/hooks'
import { RootState, SAVE_STATE, setSaveState, slideShift } from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'src/lib'
import { SlideMenu, SlideNumber } from './components'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'

const SLIDE_CARD_TYPE = 'slide_card_type'

export const SlideCard: React.FC<ISlideCard> = React.memo(
  ({
    canvasType = CANVAS_TYPE.STATIC,
    data,
    isActive,
    onClick,
    className,
    dataAttr,
  }) => {
    const dispatch = useDispatch()
    const { getSlideData, getPublicSlideData, isLoading } = useDecksApi()

    const [{ isDragging }, elementDrag] = useDrag(() => {
      return {
        type: SLIDE_CARD_TYPE,
        item: {
          slideId: data.slideId,
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }
    }, [data])

    const [{ isOver, dropItem }, dropTarget] = useDrop(
      () => ({
        accept: [SLIDE_CARD_TYPE],
        collect: (monitor: DropTargetMonitor<{ slideId: number }, void>) => ({
          isOver: monitor.isOver(),
          dropItem: monitor.getItem(),
        }),
      }),
      [],
    )

    const elRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (isActive && elRef.current) {
        elRef.current.scrollIntoView({ block: 'end', inline: 'center' })
      }
    }, [isActive])

    useEffect(() => {
      if (isOver && data.slideId && dropItem.slideId !== data.slideId) {
        dispatch(slideShift({ from: dropItem.slideId, to: data.slideId }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }
    }, [isOver, dropItem, data.slideId])

    const { slideData, deckId, activeSlides, themeId } = useSelector(
      ({ edit }: RootState) => ({
        slideData: edit.activeSlideData.data,
        deckId: edit.activeDeck.data?.deck?.id,
        activeSlides: edit.activeSlideData.data,
        themeId: edit.activeDeck.data?.deckData?.theme.id,
        activeDeckData: edit.activeDeck.data?.deckData?.theme.data.colorMap,
      }),
    )

    useEffect(() => {
      if (deckId && data.slideId && data.slideDataId) {
        if (location.pathname.includes('share')) {
          getPublicSlideData({
            deckId,
            slideId: data.slideId,
            slideDataId: data.slideDataId,
          })
        } else
          getSlideData({
            deckId,
            slideId: data.slideId,
            slideDataId: data.slideDataId,
          })
      }
    }, [deckId, data.slideDataId])

    const slideComponents = useMemo(
      () => activeSlides?.find(({ id }) => id === data.slideDataId),
      [activeSlides, data.slideDataId],
    )

    const components = useMemo(
      () =>
        slideComponents?.slideDataComponents.map(({ component }) => component),
      [slideComponents],
    )

    const isSwapped = useMemo(
      () => slideData?.find(({ id }) => id === data.slideDataId)?.isSwapColor,
      [slideData, data.slideDataId],
    )

    const currentSlideData = useMemo(
      () => slideData?.find(({ id }) => id === data.slideDataId),
      [slideData, data.slideDataId],
    )

    const tempSvgUrl = useMemo(() => {
      if (currentSlideData?.tempSvg && themeId) {
        return (currentSlideData.tempSvg as any)[themeId]
      }
    }, [currentSlideData, themeId])

    const activeColorMap = useActiveColorMap({})

    const activeColorFromMap = useMemo<
      GradientLikeColorSchema | undefined
    >(() => {
      const targetBackground =
        activeColorMap?.background?.[isSwapped ? 'swap' : 'default']
      return targetBackground
        ? {
            colors: targetBackground.colors.map((val) =>
              ['first', 'second', 'third', 'fourth', 'wht', 'blck'].includes(
                val,
              )
                ? `var(--${val})`
                : val,
            ),
            rotation: targetBackground.rotation,
          }
        : undefined
    }, [activeColorMap?.background, isSwapped])

    const background = useMemo(() => {
      return (
        slideData?.find(({ id }) => id === data.slideDataId)?.background ||
        activeColorFromMap ||
        undefined
      )
    }, [slideData, activeColorFromMap, data])

    const renderComponents = useMemo(
      () =>
        components && (
          <div
            css={draggableAreaStyles}
            style={{
              opacity: isDragging ? 0 : 1,
            }}
            className={isSwapped ? `theme-swapped` : `theme-default`}
            ref={elementDrag}
          >
            <div ref={elRef} />

            <SlideMenu
              data={data}
              isSwapColor={isSwapped}
              background={background}
              svgType={currentSlideData?.svgType}
            />
            <Canvas
              components={components}
              canvasType={canvasType}
              dataType={CANVAS_DATA_TYPE.ACTIVE_DECK}
              dataProps={{
                slideDataId: data.slideDataId,
              }}
            />
            <SlideNumber current={data.orderIndex} />
          </div>
        ),
      [
        components,
        draggableAreaStyles,
        isDragging,
        isSwapped,
        elementDrag,
        data,
        tempSvgUrl,
        canvasType,
        elRef,
      ],
    )

    return (
      <div
        css={slideCardStyles({ isActive, isDragging })}
        onClick={onClick}
        className={className}
        {...dataAttr}
        ref={dropTarget}
      >
        {isLoading ? <Spinner size={48} /> : renderComponents}
      </div>
    )
  },
)

SlideCard.displayName = 'SlideCard'
