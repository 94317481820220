import { APP_CONFIG } from 'src/config'

export const gridParams = () => {
  const masterWidth = APP_CONFIG.canvasMasterWidth
  const masterHeight = APP_CONFIG.canvasMasterWidth / APP_CONFIG.canvasRatio
  const gridWidth = masterWidth / APP_CONFIG.canvasHorGridCount
  const vertCount = Math.round(masterHeight / gridWidth)
  const gridHeight = masterHeight / vertCount

  return {
    height: gridHeight,
    width: gridWidth,
    horCount: APP_CONFIG.canvasHorGridCount,
    vertCount,
  }
}
