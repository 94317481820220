import React, { useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CANVAS_TYPE, ICanvasStatic } from './types'
import { NonDragController } from './components/drag-controller/NonDragController'

export const CanvasStatic: React.FC<ICanvasStatic> = React.memo(
  ({
    canvasType = CANVAS_TYPE.STATIC,
    components = [],
    slideDataId,
    scale,
  }) => {
    const [nonDragControllerKey, setNonDragControllerKey] = useState(uuidv4())

    useEffect(() => {
      const uuid = uuidv4()
      setNonDragControllerKey(uuid)
    }, [JSON.stringify(components)])

    const zIndexList: number[] = useMemo(
      () =>
        components
          ?.map(({ positions: { zIndex } }) => zIndex || 5000)
          .filter((v) => v)
          .sort() || [],
      [JSON.stringify(components)],
    )

    return (
      <>
        {components?.map((component, index) => (
          <NonDragController
            canvasType={canvasType}
            scale={scale}
            key={nonDragControllerKey + '_' + index}
            data={component}
            slideDataId={slideDataId}
            zIndexList={zIndexList}
          />
        ))}
      </>
    )
  },
)

CanvasStatic.displayName = 'CanvasStatic'
