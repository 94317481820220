import React, { useCallback, useMemo } from 'react'

import { IBanner } from './types'
import { bannerStyles } from './styles'
import { Icon, icons } from 'src/lib'
import { useLanguage, useNotification, useUserApi } from 'src/hooks'
import { colors } from 'src/theme'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthProvider } from 'src/provider'
import { RequestServices } from 'src/services'
import { useDispatch } from 'react-redux'
import {
  setAdminToken,
  setDeckQueryToInitial,
  setWorkspaceClearState,
  setWorkspaceId,
} from 'src/store'
import { DECKTOPUS_WORKSPACE_ID } from '../workspace-selector/consts'
import delay from 'src/helpers/delay'

export const Banner: React.FC<IBanner> = React.memo(
  ({ adminToken, className, dataAttr }) => {
    const { t } = useLanguage()
    const { error } = useNotification()
    const navigate = useNavigate()
    const { setToken } = useAuthProvider()
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    const { getOrganizations } = useUserApi()

    const adminTokenSelector = useMemo(() => {
      const lsAdminToken = localStorage.getItem('adminToken')
      return adminToken ?? lsAdminToken
    }, [adminToken])

    const backToSupportHandler = useCallback(async () => {
      if (!adminTokenSelector) {
        // If no admin token found redirect to login to re-auth
        localStorage.removeItem('token')
        error('login.session_expired')
        await delay(1000)
        navigate('/login', { replace: true })
        return
      }

      // Set admin token and workspaceId to previous (admin account's) state
      localStorage.setItem('token', adminTokenSelector!)
      localStorage.removeItem('adminToken')
      setToken(adminTokenSelector)
      localStorage.setItem('token', adminTokenSelector)
      RequestServices.setAuthHeader(adminTokenSelector)
      await getOrganizations()
      dispatch(setAdminToken({ adminToken: null }))
      navigate('/admin', { replace: true })
      dispatch(setDeckQueryToInitial())
      dispatch(setWorkspaceClearState())
      dispatch(setWorkspaceId(DECKTOPUS_WORKSPACE_ID))
      searchParams.delete('folderId')
      setSearchParams({})
    }, [adminTokenSelector])

    return (
      <div css={bannerStyles} className={className} {...dataAttr}>
        <div style={{ cursor: 'pointer' }} onClick={backToSupportHandler}>
          <Icon icon={icons.chevron_left} color={colors.white.DEFAULT} />
          <span>{t('admin.dashboard.header.back_to_support')}</span>
        </div>
        <div>
          <Icon icon={icons.admin} color={colors.white.DEFAULT} />
          <span>{t('admin.dashboard.header.logged_in_as_user')}</span>
        </div>
        <div />
      </div>
    )
  },
)

Banner.displayName = 'Banner'
