export const planFeatures = {
  'plan_features.pro.monthly.title': 'AI Credits 750 / Monat',
  'plan_features.pro.monthly.1': 'PDF / PPT-Export',
  'plan_features.pro.monthly.2': 'Präsentationsanalyse',
  'plan_features.pro.monthly.3': 'Link teilen',
  'plan_features.pro.monthly.4':
    'E-Mail-Benachrichtigungen zur Formularantwort',
  'plan_features.pro.monthly.5': 'PDF-Import',
  'plan_features.pro.monthly.6': 'AI Bilderzeugung',
  'plan_features.pro.monthly.7': 'Folien und Vortragsnotizen',
  'plan_features.pro.monthly.8':
    'Tipps und Qualitätssicherung für die Produktion',
  'plan_features.pro.annual.title': 'AI Credits 750 / Monat',
  'plan_features.pro.annual.1': 'PDF / PPT-Export',
  'plan_features.pro.annual.2': 'Präsentationsanalyse',
  'plan_features.pro.annual.3': 'Link teilen',
  'plan_features.pro.annual.4': 'E-Mail-Benachrichtigungen zur Formularantwort',
  'plan_features.pro.annual.5': 'PDF-Import',
  'plan_features.pro.annual.6': 'AI Bilderzeugung',
  'plan_features.pro.annual.7': 'Folien und Vortragsnotizen',
  'plan_features.pro.annual.8':
    'Tipps und Qualitätssicherung für die Produktion',
  'plan_features.business.monthly.title': 'AI Credits 1000 / Monat',
  'plan_features.business.monthly.1': 'Pro AI-Funktionen +',
  'plan_features.business.monthly.2':
    'Hochladen einer benutzerdefinierten Schriftart',
  'plan_features.business.monthly.3': 'Fußnotizen und Logos',
  'plan_features.business.monthly.4': 'Erweiterte Formularantwort',
  'plan_features.business.monthly.5': 'Formularantworten als CSV exportieren',
  'plan_features.business.monthly.6': 'Webhook',
  'plan_features.business.monthly.7': 'Benutzerdefinierter Domain-Link (CNAME)',
  'plan_features.business.monthly.8': 'Teams & Organisation',
  'plan_features.business.annual.title': 'AI Credits 1000 / Monat',
  'plan_features.business.annual.1': 'Pro AI-Funktionen +',
  'plan_features.business.annual.2':
    'Hochladen einer benutzerdefinierten Schriftart',
  'plan_features.business.annual.3': 'Fußnotizen und Logos',
  'plan_features.business.annual.4': 'Erweiterte Formularantwort',
  'plan_features.business.annual.5': 'Formularantworten als CSV exportieren',
  'plan_features.business.annual.6': 'Webhook',
  'plan_features.business.annual.7': 'Benutzerdefinierter Domain-Link (CNAME)',
  'plan_features.business.annual.8': 'Teams & Organisation',
}
