import React, { useRef, useState } from 'react'

import { IPropDropdown } from './types'
import { propDropdownMenuStyles, propDropdownStyles } from './styles'
import { useClickOutside } from 'src/hooks'
import { PropButton } from '../prop-button'

export const PropDropdown: React.FC<IPropDropdown> = React.memo(
  ({ icon, children, className, dataAttr }) => {
    const dropdownRef = useRef(null)
    const [isOpen, setOpen] = useState(false)
    useClickOutside(dropdownRef, () => setOpen(false))

    return (
      <div
        css={propDropdownStyles}
        className={className}
        {...dataAttr}
        ref={dropdownRef}
      >
        <PropButton
          onClick={() => setOpen(true)}
          isFocus={isOpen}
          icon={icon}
          isMenuButton
        />
        <div css={propDropdownMenuStyles({ isOpen })}>{children}</div>
      </div>
    )
  },
)

PropDropdown.displayName = 'PropDropdown'
