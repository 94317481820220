import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const deckNameEditStyles = ({ gap }: ITheme) => css`
  display: flex;
  align-items: center;
  gap: ${gap.medium}px;

  .deck-name-input {
    width: 200px;
  }
`
