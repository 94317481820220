import { makeMuiStyles } from 'src/theme/muiTheme'

export const useStyles = makeMuiStyles()((theme) => ({
  workspaceWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: [1, '0 1 100%'],
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  header: {
    padding: theme.spacing(2, 4),
    borderBottom: '1px solid',
    borderColor: theme.palette.elevation?.outlined,
  },
  noPages: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabs: {
    width: '200px',
    flexShrink: 0,
    height: '100%',
    borderRight: '1px solid',
    borderColor: theme.palette.elevation?.outlined,
    padding: theme.spacing(4, 0),
  },
  tabPanel: {
    width: '350px',
    flexShrink: 0,
    height: '100%',
    borderRight: '1px solid',
    borderColor: theme.palette.elevation?.outlined,
    padding: theme.spacing(4, 4),
  },
  addButton: {
    width: '28px',
    height: '28px',
    backgroundColor: theme.palette.primary._states?.focus,
    color: theme.palette.primary.main,
  },
}))
