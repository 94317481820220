import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editElementsStyles = ({ gap, colors }: ITheme) => css`
  background: ${colors.dark2.DEFAULT};
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${gap.small}px;
`

export const headerStyles = ({ gap, fontWeight }: ITheme) => css`
  padding: ${gap.xlarge}px ${gap.small}px 0 ${gap.xlarge}px;
  font-weight: ${fontWeight.medium};
`

export const scrollAreaStyles = ({ gap }: ITheme) => css`
  height: 100%;
  overflow-x: auto;
  padding: ${gap.small}px ${gap.xlarge}px ${gap.xlarge}px ${gap.xlarge}px;
  display: flex;
  flex-direction: column;
  gap: ${gap.large}px;
`
