import { useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react-lite'
import { StoreType } from 'polotno/model/store'
import { Vector2d } from 'konva/lib/types'
import { useLanguage, useMediaApi } from 'src/hooks'

import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { MaterialSymbol } from 'react-material-symbols'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { MediaItemTypes, setSelectedMediaTab } from 'src/store/generalV3'
import MediaCreateButtons from '../media-create-buttons'
import AutoScrollArea from '../scroll-area'
import { useStyles } from './styles'
import { ImagesGrid } from 'polotno/side-panel/images-grid'
import {
  IconProviders,
  StockMediaProviders,
  StockMediaQualities,
} from 'src/types/api/enums'
import { NodeType } from 'polotno/model/node-model'
import { dropMediaElement } from '../../helpers/dropMediaElement'
import { useInfiniteAPI } from 'src/hooks/useInfinityApi'
import { IMediaItem } from '../image-grid-loader/types'

const limit = 20

const getMediaProvider = (type?: MediaItemTypes) => {
  switch (type) {
    case 'videos':
      return StockMediaProviders.PEXELS
    case 'images':
    default:
      return StockMediaProviders.UNSPLASH
  }
}

const MediaSearchPanel = observer(({ store }: { store: StoreType }) => {
  const { t } = useLanguage()
  const { classes } = useStyles()
  const [search, setSearch] = useState<string>('')

  const { searchIcon, getIcon, searchMedia, triggerMediaDownload } =
    useMediaApi()

  const dispatch = useDispatch()
  const { selectedType, medias } = useSelector(({ generalV3 }: RootState) => ({
    selectedType: generalV3.selectedMediaTab,
    medias: generalV3.medias[generalV3.selectedMediaTab ?? 'images'],
  }))

  const { data, isLoading, loadMore, setQuery } = useInfiniteAPI({
    getAPI: ({ page, query }) =>
      JSON.stringify({ page, query, type: selectedType }),
    getSize: (lastResult) => Math.ceil(lastResult.metadata.total / limit),
    fetchFunc: (queryString) => {
      const { page, query: q } = JSON.parse(queryString)
      if (!q) {
        return Promise.resolve(null)
      }
      const offset = (page - 1) * limit
      return selectedType === 'icons'
        ? searchIcon({
            q,
            limit,
            offset,
            provider: IconProviders.FREEPIK,
            size: '128',
          })
        : searchMedia({
            q,
            limit,
            offset,
            provider: getMediaProvider(selectedType),
            quality: StockMediaQualities.MEDIUM,
          })
    },
  })
  const items = data?.reduce((acc: IMediaItem[], page) => {
    switch (selectedType) {
      case 'icons':
        return [
          ...acc,
          ...page.icons.map((item: any) => ({
            id: item.externalId,
            url: item.icon,
            type: 'icon',
          })),
        ]
      case 'videos':
        return [
          ...acc,
          ...page.mediaUrls.map((url: string, index: number) => ({
            url,
            type: 'video',
            thumbnailUrl: page.metadata.pexels.metaInfo[index].thumbnailUrl,
            width: page.metadata.pexels.metaInfo[index].width,
            height: page.metadata.pexels.metaInfo[index].height,
            duration: page.metadata.pexels.metaInfo[index].duration,
            ownerUrl: page.metadata.pexels.metaInfo[index].ownerUrl,
            ownerName: page.metadata.pexels.metaInfo[index].ownerName,
          })),
        ]
      case 'images':
      default:
        return [
          ...acc,
          ...page.mediaUrls.map((url: string, index: number) => ({
            url,
            type: 'image',
            ownerUrl: page.metadata.unsplash.metaInfo[index].ownerUrl,
            ownerName: page.metadata.unsplash.metaInfo[index].ownerName,
          })),
        ]
    }
  }, [] as IMediaItem[])

  const handleBack = () => {
    dispatch(setSelectedMediaTab(undefined))
    store.openSidePanel('Media')
  }

  const onSearch = useCallback(
    async (value: string) => {
      if (value.length > 2) {
        setSearch(value)
        setQuery(value)
      }
    },
    [setQuery],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearchChange = useCallback(debounce(onSearch, 500), [
    onSearch,
  ])

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    debouncedHandleSearchChange.cancel()
    debouncedHandleSearchChange(event.target.value)
  }

  // const showAi = selectedType === 'images'

  const beforeIconDrop = useCallback(
    (externalId: string) =>
      getIcon({ provider: IconProviders.FREEPIK }, { externalId }),
    [getIcon],
  )

  const onSelect = async (
    media: IMediaItem,
    droppedPos?: Vector2d,
    targetElement?: NodeType,
  ) => {
    let url: string | undefined = undefined
    if (media.type === 'icon') {
      url = await beforeIconDrop(media.id as string)
    }

    dropMediaElement({
      store,
      image: { url: url ?? media.url },
      droppedPos,
      targetElement,
      ...(media.type === 'video' && {
        attrs: {
          width: media.width,
          height: media.height,
        },
      }),
    })
    if (media.type === 'image') {
      triggerMediaDownload({
        provider: StockMediaProviders.UNSPLASH,
        downloadUrl: media.url,
      })
    }
  }

  return (
    <AutoScrollArea>
      <Stack className={classes.container} spacing={3}>
        <Stack direction="column" width="100%" spacing={3}>
          {selectedType && (
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="text"
                onClick={() => handleBack()}
                startIcon={
                  <MaterialSymbol icon="arrow_back" size={20} weight={400} />
                }
              >
                {t(`v3.${selectedType}`)}
              </Button>
            </Stack>
          )}
          <MediaCreateButtons store={store} />
          <TextField
            variant="outlined"
            label={null}
            placeholder={t('common.actions.search_with_name', {
              name: selectedType ? t(`v3.${selectedType}`) : '',
            })}
            onChange={handleSearchChange}
            slotProps={{
              input: {
                className: classes.searchField,
                startAdornment: (
                  <InputAdornment position="start">
                    <MaterialSymbol
                      icon="image_search"
                      size={24}
                      weight={300}
                    />
                  </InputAdornment>
                ),
              },
            }}
            sx={{ mt: 2 }}
          />
          <Typography variant="body2" fontWeight={500}>
            {search
              ? t('v3.results', {
                  search,
                })
              : t('v3.trending')}
          </Typography>
          {items?.length ? (
            <ImagesGrid
              images={items}
              getPreview={(image) => image.thumbnailUrl ?? image.url}
              onSelect={onSelect}
              rowsNumber={2}
              isLoading={isLoading}
              loadMore={loadMore}
              getCredit={(image) =>
                image.ownerUrl && (
                  <a href={image.ownerUrl} target="_blank" rel="noreferrer">
                    {image.ownerName}
                  </a>
                )
              }
            />
          ) : (
            <ImagesGrid
              images={medias}
              getPreview={(media) => media.thumbnailUrl ?? media.url}
              onSelect={onSelect}
              rowsNumber={2}
              isLoading={false}
              getCredit={(media) => (
                <>
                  {media.ownerUrl && (
                    <a href={media.ownerUrl} target="_blank" rel="noreferrer">
                      {media.ownerName}
                    </a>
                  )}
                  {media.duration && <span>{media.duration}</span>}
                </>
              )}
            />
          )}
        </Stack>
      </Stack>
    </AutoScrollArea>
  )
})

export default MediaSearchPanel
