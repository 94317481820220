import React from 'react'
import { createPortal } from 'react-dom'

import { IEditComponentFixer } from './types'
import { editComponentFixerStyles } from './styles'
import { useActiveCanvas, useLanguage } from 'src/hooks'
import { Loader } from 'src/lib'
import { useTheme } from 'src/theme'
import { useComponentFit } from '../../hooks'

export const EditComponentFixer: React.FC<IEditComponentFixer> = React.memo(
  ({ className, dataAttr }) => {
    const { autoResizeNeeded } = useActiveCanvas({})
    const isLoading = useComponentFit(autoResizeNeeded)
    const { t } = useLanguage()
    const { colors } = useTheme()

    return (
      isLoading &&
      createPortal(
        <div css={editComponentFixerStyles} className={className} {...dataAttr}>
          <span className="component-fixer-detail">
            {t('edit.component_fix.detail')}
          </span>
          <span className="component-fixer-info">
            {t('edit.component_fix.info')}
          </span>
          <Loader size={32} color={colors.white.DEFAULT} />
        </div>,
        document.body,
      )
    )
  },
)

EditComponentFixer.displayName = 'EditComponentFixer'
