import React, { useMemo, useRef } from 'react'
import { useClickOutside, useLanguage } from 'src/hooks'
import { BUTTON_THEME, BUTTON_TYPE, Button, Tooltip } from 'src/lib'
import { icons } from 'src/lib/icon'
import {
  bottomStyles,
  errorTextStyle,
  titleStyles,
  topStyles,
  wrapperStyles,
} from './styles'
import { menuAreaStyles, menuStyles } from './styles'
import { ICardHeader } from './types'
import { Menu } from 'src/lib/menu'

export const CardHeader: React.FC<ICardHeader> = React.memo(
  ({
    isLoading,
    isMenuOpen,
    menuItems,
    menuOnClick,
    title,
    subtitle,
    subtitleInfo,
    hasError,
    onClickDelete,
    isNewDeck,
    publishButton,
  }) => {
    const { t } = useLanguage()
    const menuRef = useRef(null)
    const closeMenu = () => menuOnClick && menuOnClick(false)
    useClickOutside(menuRef, closeMenu)

    const iconOnClick = () => {
      if (isLoading) return
      else if (hasError) {
        onClickDelete && onClickDelete()
      } else {
        menuOnClick && menuOnClick(!isMenuOpen)
      }
    }

    const menuComponent = useMemo(() => {
      if (!menuItems || !menuItems.length) return

      return (
        <div css={menuStyles({ isMenuOpen })} ref={menuRef}>
          <Menu items={menuItems} onItemClick={closeMenu} />
        </div>
      )
    }, [isMenuOpen, menuItems])

    return (
      <div css={wrapperStyles}>
        <div css={topStyles}>
          {publishButton}
          {title && (
            <Tooltip width={300} text={title}>
              <div
                css={titleStyles}
                style={{ width: publishButton ? '206px' : '220px' }}
              >
                {title}
              </div>
            </Tooltip>
          )}
          <div css={menuAreaStyles}>
            {!isLoading && !isNewDeck && (
              <Button
                icon={hasError ? icons.trash_can : icons.menu_horizontal}
                onClick={iconOnClick}
                theme={hasError ? BUTTON_THEME.RED : BUTTON_THEME.PRIMARY}
                isLink
                type={BUTTON_TYPE.GHOST}
              />
            )}
            {menuComponent}
          </div>
        </div>
        <div css={bottomStyles}>
          {hasError ? (
            <div css={errorTextStyle}>{t('deck_not_created')}</div>
          ) : (
            <>
              <div>{subtitle}</div>
              {subtitleInfo && <span />}
              <div>{subtitleInfo}</div>
            </>
          )}
        </div>
      </div>
    )
  },
)
