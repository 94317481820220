import { IEditInitialState } from '../types'
import { getSlideDataIndex } from './getSlideDataIndex'

export const getComponentIndex = (
  state: IEditInitialState,
  id?: number,
  tempId?: string | null,
): number => {
  const slideIndex = getSlideDataIndex(state)

  if (!state.activeSlideData?.data?.[slideIndex]) {
    return -1
  }

  return state.activeSlideData.data[slideIndex].slideDataComponents.findIndex(
    ({ component }) =>
      component.id ? component.id === id : component.tempId === tempId,
  )
}
