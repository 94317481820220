import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import { StoreType } from 'polotno/model/store'
import { useLanguage, useMediaApi } from 'src/hooks'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { useDispatch, useSelector } from 'react-redux'
import {
  GeneralV3SliceType,
  setMedia,
  setSelectedMediaTab,
} from 'src/store/generalV3'
import AutoScrollArea from '../scroll-area'
import { useStyles } from './styles'
import { useCallback, useEffect } from 'react'
import { IMediaAttributes } from '../../types'
import MediaCreateButtons from '../media-create-buttons'
import { IconProviders, StockMediaProviders } from 'src/types/api/enums'
import {
  dropMediaElement,
  onMediaDragEnd,
  onMediaDragStart,
} from '../../helpers/dropMediaElement'
import { RootState } from 'src/store'
import { IMediaItem } from '../image-grid-loader/types'

const MediaPanel = observer(({ store }: { store: StoreType }) => {
  const { t } = useLanguage()
  const { classes } = useStyles()
  const { getTrendingIcons, getTrendingMedia, getIcon, triggerMediaDownload } =
    useMediaApi()
  const { images, icons, videos } = useSelector(({ generalV3 }: RootState) => ({
    images: generalV3.medias.images,
    icons: generalV3.medias.icons,
    videos: generalV3.medias.videos,
  }))

  // const aiPromptRef = useRef<IPropAiImageProtocol>(null)

  const dispatch = useDispatch()

  const handleChange = (panel: GeneralV3SliceType['selectedMediaTab']) => {
    dispatch(setSelectedMediaTab(panel))
    store.openSidePanel('image-search-panel')
  }

  const beforeIconDrop = useCallback(
    (externalId: string) =>
      getIcon({ provider: IconProviders.FREEPIK }, { externalId }),
    [getIcon],
  )

  const handleMediaClick = async (
    media: IMediaItem,
    attrs?: IMediaAttributes,
  ) => {
    let url: string | undefined = undefined
    if (media.type === 'icon') {
      url = await beforeIconDrop(media.id as string)
    }
    dropMediaElement({ store, image: { url: url ?? media.url }, attrs })
  }

  useEffect(() => {
    const fetchImages = async () => {
      const imgs = await getTrendingMedia({
        provider: StockMediaProviders.UNSPLASH,
      })
      const mappedImages: IMediaItem[] =
        imgs?.mediaUrls?.map((url, index) => ({
          id: index,
          url,
          type: 'image',
          ownerName: imgs?.metadata?.unsplash?.metaInfo?.[index]?.ownerName,
          ownerUrl: imgs?.metadata?.unsplash?.metaInfo?.[index]?.ownerUrl,
        })) ?? []
      dispatch(setMedia({ type: 'images', data: mappedImages }))
    }
    const fetchIcons = async () => {
      const icns = await getTrendingIcons({ provider: IconProviders.FREEPIK })
      const mappedIcons: IMediaItem[] =
        icns?.icons?.map(({ icon, externalId }) => ({
          id: externalId,
          url: icon,
          type: 'icon',
        })) ?? []
      dispatch(setMedia({ type: 'icons', data: mappedIcons }))
    }
    const fetchVideos = async () => {
      const vids = await getTrendingMedia({
        provider: StockMediaProviders.PEXELS,
      })
      const mappedVideos: IMediaItem[] =
        vids?.metadata?.pexels?.metaInfo?.map(
          ({ duration, thumbnailUrl, width, height }, index) => ({
            id: index,
            url: vids?.mediaUrls?.[index],
            thumbnailUrl,
            type: 'video',
            duration: `${duration}s`,
            width,
            height,
            ownerName: vids?.metadata?.pexels?.metaInfo?.[index]?.ownerName,
            ownerUrl: vids?.metadata?.pexels?.metaInfo?.[index]?.ownerUrl,
          }),
        ) ?? []
      dispatch(setMedia({ type: 'videos', data: mappedVideos }))
    }

    !images.length && fetchImages()
    !icons.length && fetchIcons()
    !videos.length && fetchVideos()
  }, [
    dispatch,
    getTrendingIcons,
    getTrendingMedia,
    icons.length,
    images.length,
    videos.length,
  ])
  // const onAssetReady = async () => {
  //   //todo: add asset to page or what?
  // }

  // const onAiMediaChange = useCallback(
  //   (url: string) => {
  //     if (url) {
  //       handleMediaClick({
  //         id: 100,
  //         url,
  //         type: 'image',
  //       })
  //     }
  //   },
  //   [handleMediaClick],
  // )

  return (
    <AutoScrollArea>
      <Stack className={classes.container} spacing={3}>
        <MediaCreateButtons store={store} />
        <Stack direction="column" width="100%" sx={{ mt: 2 }} spacing={2}>
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" fontWeight={500}>
              {t('v3.images')}
            </Typography>
            <Button
              variant="text"
              className={classes.showMoreButton}
              onClick={() => handleChange('images')}
            >
              {t('v3.see_all')}
            </Button>
          </Stack>
          <Grid container spacing={2}>
            {images.slice(0, 3).map((img) => (
              <Grid key={img.id} size={4}>
                <div
                  className={classes.mediaGridItem}
                  role="button"
                  onClick={() => handleMediaClick(img)}
                >
                  <img
                    id={'media-thumbnail-image-' + img.id}
                    width="100%"
                    height="100%"
                    src={img.url}
                    loading="lazy"
                    onDragStart={() =>
                      onMediaDragStart({
                        store,
                        url: img.url,
                        afterDrop: () => {
                          triggerMediaDownload({
                            provider: StockMediaProviders.UNSPLASH,
                            downloadUrl: img.url,
                          })
                        },
                      })
                    }
                    onDragEnd={onMediaDragEnd}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Stack direction="column" width="100%" spacing={2}>
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" fontWeight={500}>
              {t('v3.icons')}
            </Typography>
            <Button
              variant="text"
              className={classes.showMoreButton}
              onClick={() => handleChange('icons')}
            >
              {t('v3.see_all')}
            </Button>
          </Stack>
          <Grid container spacing={2}>
            {icons.slice(0, 4).map((icon, index) => (
              <Grid key={index} size={3}>
                <Button
                  fullWidth
                  draggable
                  className={classes.figureButton}
                  onClick={() => handleMediaClick(icon)}
                  onDragStart={() =>
                    onMediaDragStart({
                      store,
                      url: icon.url,
                      beforeDrop: () => beforeIconDrop(icon.id as string),
                    })
                  }
                  onDragEnd={onMediaDragEnd}
                >
                  <img
                    width={30}
                    height={30}
                    src={icon.url}
                    loading="lazy"
                    draggable
                    onDragStart={() =>
                      onMediaDragStart({
                        store,
                        url: icon.url,
                        beforeDrop: () => beforeIconDrop(icon.id as string),
                      })
                    }
                    onDragEnd={onMediaDragEnd}
                  />
                </Button>
              </Grid>
            ))}
          </Grid>
        </Stack>
        <Stack direction="column" width="100%" spacing={2}>
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" fontWeight={500}>
              {t('v3.videos')}
            </Typography>
            <Button
              variant="text"
              className={classes.showMoreButton}
              onClick={() => handleChange('videos')}
            >
              {t('v3.see_all')}
            </Button>
          </Stack>
          <Grid container spacing={2}>
            {videos.slice(0, 3).map((video) => (
              <Grid key={video.id} size={4}>
                <div
                  className={classes.mediaGridItem}
                  role="button"
                  onClick={() =>
                    handleMediaClick(video, {
                      width: video.width,
                      height: video.height,
                    })
                  }
                >
                  <img
                    id={'media-thumbnail-image-' + video.id}
                    width="100%"
                    height="100%"
                    src={video.thumbnailUrl}
                    loading="lazy"
                    onDragStart={() =>
                      onMediaDragStart({
                        store,
                        url: video.url,
                        attrs: {
                          width: video.width,
                          height: video.height,
                        },
                      })
                    }
                    onDragEnd={onMediaDragEnd}
                  />
                  <span>{video.duration}</span>
                </div>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </AutoScrollArea>
  )
})

export default MediaPanel
