import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICacheSlice, ICacheSVGType } from './types'

const initialState: ICacheSlice = {
  svg: {},
}

export const cacheSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setSvgCache: (
      state,
      action: PayloadAction<{
        key: string
        content?: ICacheSVGType
      }>,
    ) => {
      if (!state.svg[action.payload.key]) {
        state.svg[action.payload.key] = action.payload.content || null
      }
    },
    removeSvgCache: (state, action: PayloadAction<string>) => {
      delete state.svg[action.payload]
    },
  },
})

export const { setSvgCache, removeSvgCache } = cacheSlice.actions

export default cacheSlice.reducer
