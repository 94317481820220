import React, { useCallback, useEffect, useMemo } from 'react'

import { IEditPanel } from './types'
import { editPanelItem, editPanelStyles } from './styles'
import { Icon, Tooltip, icons } from 'src/lib'
import { useTheme } from '@emotion/react'
import { CANVAS_PANEL } from 'src/store/canvas/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setPanel, setSelectedComponents } from 'src/store'
import { useLanguage } from 'src/hooks'
import aiAssistant from 'src/assets/images/ai-assistant.gif'
import { DeckTypes } from 'src/types/api/enums'

export const EditPanel: React.FC<IEditPanel> = React.memo(
  ({ onItemClick, className, dataAttr }) => {
    const dispatch = useDispatch()
    const { colors } = useTheme()
    const { t } = useLanguage()

    const { currentPanel, isAiDeck } = useSelector(
      ({ canvas, edit }: RootState) => ({
        currentPanel: canvas.panel,
        isAiDeck: edit.activeDeck.data?.deck?.type === DeckTypes.AI,
      }),
    )
    const clickedAiButton = useMemo(() => {
      return localStorage.getItem('clickedAiAssistantButton')
    }, [currentPanel])

    const menuButtons: {
      label: string
      icon: icons
      isGradient?: boolean
      panel: CANVAS_PANEL
      disabled?: boolean
      img?: string
      hidden?: boolean
    }[] = [
      {
        panel: CANVAS_PANEL.DESIGN,
        label: t('common.design'),
        icon: icons.paint_palette,
      },
      {
        panel: CANVAS_PANEL.SLIDE,
        label: t('edit.panel.background'),
        icon: icons.background,
      },
      {
        panel: CANVAS_PANEL.ELEMENTS,
        label: t('edit.panel.elements'),
        icon: icons.dashboard_layout,
      },
      {
        panel: CANVAS_PANEL.NOTES,
        label: t('edit.panel.notes'),
        icon: icons.terms_and_conditions,
      },
      {
        panel: CANVAS_PANEL.AI,
        label: t('edit.panel.ai_assistant'),
        icon: icons.sparkling,
        img: aiAssistant,
        isGradient: true,
      },
      {
        panel: CANVAS_PANEL.BRAND,
        label: t('edit.panel.brand'),
        icon: icons.approval,
        disabled: true,
      },
    ]

    const onPanelItemClick = useCallback((panel: CANVAS_PANEL) => {
      dispatch(setPanel(panel))
      dispatch(setSelectedComponents())
      panel == CANVAS_PANEL.AI &&
        localStorage.setItem('clickedAiAssistantButton', 'true')
      onItemClick?.()
    }, [])

    useEffect(() => {
      if (isAiDeck) {
        dispatch(setPanel(CANVAS_PANEL.NOTES))
      }
    }, [isAiDeck])

    return (
      <div css={editPanelStyles} className={className} {...dataAttr}>
        {menuButtons.map(
          ({ panel, label, icon, isGradient, disabled, hidden }) => {
            if (hidden) {
              return null
            }

            return disabled ? (
              <Tooltip
                text={t('common.informative.coming_soon')}
                key={label}
                width={80}
              >
                <div
                  css={editPanelItem({
                    isGradient,
                    isSelected: currentPanel === panel,
                  })}
                  className="disabled"
                  onClick={() => !disabled && onPanelItemClick(panel)}
                >
                  <div className="icon-area">
                    <Icon
                      icon={icon}
                      size={isGradient ? 24 : 32}
                      color={colors.white.DEFAULT}
                    />
                  </div>
                  {label}
                </div>
              </Tooltip>
            ) : (
              <div
                key={label}
                css={editPanelItem({
                  isGradient,
                  isSelected: currentPanel === panel,
                })}
                onClick={() => !disabled && onPanelItemClick(panel)}
              >
                <div className="icon-area">
                  {(clickedAiButton || panel != CANVAS_PANEL.AI) && (
                    <Icon
                      icon={icon}
                      size={isGradient ? 24 : 32}
                      color={colors.white.DEFAULT}
                    />
                  )}
                  {panel == CANVAS_PANEL.AI && !clickedAiButton && (
                    <img src={aiAssistant} className="animation" />
                  )}
                </div>
                {label}
              </div>
            )
          },
        )}
      </div>
    )
  },
)

EditPanel.displayName = 'EditPanel'
