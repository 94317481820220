import React, { useCallback, useState } from 'react'

import { IPropButton } from './types'
import { propButtonStyles } from './styles'
import { Icon, icons } from 'src/lib'
import { useTheme } from 'src/theme'
import { useDispatch } from 'react-redux'
import { setAllowDeleteShortcut } from 'src/store'

export const PropButton: React.FC<IPropButton> = React.memo(
  ({
    value,
    isActive,
    isFocus,
    isBox,
    icon,
    suffix,
    text,
    onClick,
    onChange,
    isMenuButton,
    mixedText,
    className,
    dataAttr,
  }) => {
    const dispatch = useDispatch()
    const { colors } = useTheme()
    const [wasMixed, setWasMixed] = useState(false)

    const onKeyDownHandler = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isNumber = /^[0-9]$/i.test(e.key)
        if (mixedText && isNumber) {
          setWasMixed(true)
          onChange?.(e.key)
          dispatch(setAllowDeleteShortcut(false))
        }
      },
      [onChange, mixedText],
    )

    const onChangeHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!mixedText && !wasMixed) {
          onChange?.(event.target.value ? parseInt(event.target.value) : 0)
          dispatch(setAllowDeleteShortcut(false))
        }

        setWasMixed(false)
      },
      [onChange, mixedText, wasMixed],
    )

    return (
      <div
        css={propButtonStyles({ isOpen: isFocus, isBox, hasIcon: !!icon })}
        onClick={onClick}
        className={className}
        {...dataAttr}
      >
        {icon && (
          <Icon
            icon={icon}
            size={value === undefined && !mixedText ? 24 : 16}
            color={isActive ? colors.primary.DEFAULT : colors.white.DEFAULT}
          />
        )}
        {text}
        {(value !== undefined || mixedText) && (
          <div className="input">
            <input
              value={value !== undefined ? value : mixedText}
              onChange={onChangeHandler}
              onKeyDown={onKeyDownHandler}
              onFocus={() => dispatch(setAllowDeleteShortcut(false))}
              onBlur={() => dispatch(setAllowDeleteShortcut(true))}
            />
            {suffix}
          </div>
        )}
        {isMenuButton && (
          <Icon
            icon={icons.chevron_down}
            size={16}
            color={colors.white.DEFAULT}
          />
        )}
      </div>
    )
  },
)

PropButton.displayName = 'PropButton'
