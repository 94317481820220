import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const formPropsStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap['2xlarge']}px;
`

export const formQuestionStyles = ({ gap, colors }: ITheme) => css`
  padding-bottom: ${gap['2xlarge']}px;
  border-bottom: 1px solid ${colors.elements.DEFAULT};
`
