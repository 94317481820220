import { dataAttrType } from 'src/types'
import { DeckInvitePermissions, DeckPermissions } from 'src/types/api/enums'

export enum COLLABORATOR_TYPE {
  SMALL = 'small',
  BIG = 'big',
}
export enum ACTION_TYPE {
  PERMISSION_R = 'PERMISSION_R',
  PERMISSION_RW = 'PERMISSION_RW',
  REMOVE = 'REMOVE',
}

export interface ICollaborator {
  className?: string
  dataAttr?: dataAttrType
  email: string
  collaboratorType: COLLABORATOR_TYPE
  onAction?: (email: string, action: ACTION_TYPE) => void
  invitePermission?: DeckInvitePermissions
  deckPermission?: DeckPermissions
  label?: string
  imageUrl?: string | null
  disabled?: boolean
  isDeckOwner?: boolean
}
