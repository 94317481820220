import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const contextMenuStyles = css`
  position: fixed;
  z-index: 1000;
`

export const promptModalWrapperStyles = ({
  borderRadius,
  colors,
  gap,
}: ITheme) => css`
  width: 420px;
  height: 231px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadius[6]};
  padding: ${gap.xlarge}px;
  gap: ${gap.medium}px;
  background-color: ${colors.white.DEFAULT};
`

export const promptModalTitleStyles = ({
  fontSize,
  fontWeight,
  gap,
}: ITheme) => css`
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.medium};
  padding-right: ${gap.medium}px;

  & .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`

export const promptModalFooterStyles = css`
  display: flex;
  justify-content: end;
`
