import React from 'react'
import { BUTTON_SIZE, BUTTON_THEME, Button, icons } from 'src/lib'
import { buttonStyles } from '../styles'
import { useLanguage } from 'src/hooks'
import { IEducationStep } from './types'

export const EducationStep: React.FC<IEducationStep> = ({
  onNextStep,
  setSelectedBusinessType,
}) => {
  const { t } = useLanguage()
  const handleBusinessTypeClick = (businessType: string) => {
    setSelectedBusinessType(businessType)
    onNextStep()
  }
  return (
    <div css={buttonStyles}>
      <Button
        icon={icons.graduate}
        onClick={() => handleBusinessTypeClick('Student')}
        size={BUTTON_SIZE.XSMALL}
        text={t('user_classify.student')}
        theme={BUTTON_THEME.WHITE}
      />
      <Button
        icon={icons.bitten_apple}
        onClick={() => handleBusinessTypeClick('Educator')}
        size={BUTTON_SIZE.XSMALL}
        text={t('user_classify.educator')}
        theme={BUTTON_THEME.WHITE}
      />
    </div>
  )
}
export default EducationStep
