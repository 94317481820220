import React, { useCallback, useMemo } from 'react'

import { Icon, icons } from 'src/lib'

import {
  ComponentChartDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { ComponentChartTypes } from 'src/types/api/enums'
import { chartTypeButtonStyles } from './styles'
import { useTheme } from 'src/theme'
import { IEditPropSection } from '../../types'

export const ChartType: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()
    const { colors } = useTheme()

    const selectedChartType = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const type = (c.data as ComponentChartDataSchema).options.type
          if (a === '' && type) {
            return type
          }

          return type !== a ? '' : a
        }, ''),
      [components],
    )

    const handleChartTypeChange = useCallback(
      (value: ComponentChartTypes) => {
        const type = (value as ComponentChartTypes) || ComponentChartTypes.PIE

        const partialData: DeepPartial<ComponentChartDataSchema> = {
          options: {
            type,
          },
        }
        const updatedComponents = ComponentServices.updateComponentData({
          components,
          partialData,
        })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const chartTypeButtons = [
      { type: ComponentChartTypes.BAR, icon: icons.bar_chart },
      { type: ComponentChartTypes.PIE, icon: icons.pie_chart },
      { type: ComponentChartTypes.LINE, icon: icons.graph },
      { type: ComponentChartTypes.DONUT, icon: icons.donut },
    ]

    return chartTypeButtons.map(({ type, icon }) => (
      <div
        key={type}
        css={chartTypeButtonStyles({
          isActive: selectedChartType === type,
        })}
        onClick={() => handleChartTypeChange(type)}
      >
        <Icon icon={icon} size={16} color={colors.primary.DEFAULT} />
      </div>
    ))
  },
)

ChartType.displayName = 'ChartType'
