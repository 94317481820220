import React, { useCallback } from 'react'
import { useLanguage } from 'src/hooks'
import { IElement } from './types'
import { ComponentTypes } from 'src/types/api/enums'
import {
  Text,
  Media,
  Chart,
  Table,
  Button,
  List,
  Form,
  Device,
  Quote,
} from './components'

export const Element: React.FC<IElement> = React.memo(
  ({
    isSelected,
    canvasType,
    data,
    scale,
    overrides,
    isScaling,
    slideDataId,
    canDrag,
    onHeightChange,
  }) => {
    const { t } = useLanguage()
    const renderComponent = useCallback(() => {
      switch (data.type) {
        case ComponentTypes.TEXT:
          return (
            <Text
              overrides={overrides}
              isSelected={isSelected}
              isScaling={isScaling}
              data={data}
              scale={scale}
              slideDataId={slideDataId}
              canvasType={canvasType}
              canDrag={canDrag}
              onHeightChange={onHeightChange}
            />
          )
        case ComponentTypes.MEDIA:
          return (
            <Media data={data} canvasType={canvasType} overrides={overrides} />
          )
        case ComponentTypes.TABLE:
          return <Table data={data} />
        case ComponentTypes.CHART:
          return <Chart data={data} scale={scale} />
        case ComponentTypes.BUTTON:
          return <Button data={data} canvasType={canvasType} />
        case ComponentTypes.LIST:
          return (
            <List
              data={data}
              overrides={overrides}
              canvasType={canvasType}
              scale={scale}
              slideDataId={slideDataId}
              onHeightChange={onHeightChange}
            />
          )
        case ComponentTypes.FORM:
          return <Form data={data} canvasType={canvasType} />
        case ComponentTypes.DEVICE:
          return <Device data={data} />
        case ComponentTypes.QUOTE:
          return (
            <Quote
              data={data}
              overrides={overrides}
              canvasType={canvasType}
              scale={scale}
            />
          )
        default:
          return <div style={{ fontSize: '12em' }}>{t('not_supported')}</div>
      }
    }, [isSelected, overrides, data.data, scale])
    return renderComponent()
  },
)

Element.displayName = 'Element'
