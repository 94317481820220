import { ErrorDefinitions } from 'src/types/api/ErrorDefinitions'

type IErrorDefinitions = {
  [key in ErrorDefinitions]?: string
}

export const errors: IErrorDefinitions = {
  [ErrorDefinitions.VALIDATION_EMAIL_MALFORMED]:
    "L'adresse e-mail n'est pas valide",
  [ErrorDefinitions.VALIDATION_PASSWORD_LENGTH]:
    'Le mot de passe doit contenir au moins 10 caractères',
  [ErrorDefinitions.AICREDITREASONDEFAULTVALUE_NOT_FOUND]:
    'Valeur par défaut de la raison du crédit IA non trouvée',
  [ErrorDefinitions.API_ERROR]: 'Erreur API',
  [ErrorDefinitions.DATE_ERROR]: 'Format de date invalide',
  [ErrorDefinitions.INCORRECT_PROVIDER]:
    'Veuillez utiliser la connexion Google pour ce compte',
  [ErrorDefinitions.KEY_NOT_FOUND]: 'Clé non trouvée',
  [ErrorDefinitions.NO_ORG_ACCESS]: "Pas d'accès à l'organisation",
  [ErrorDefinitions.NO_TEAM_ACCESS]: "Pas d'accès à l'équipe",
  [ErrorDefinitions.PLAN_NOT_FOUND]: 'Plan non trouvé',
  [ErrorDefinitions.ACTIVE_PAYMENT_IN_PROGRESS]: 'Paiement actif en cours',
  [ErrorDefinitions.PLAN_IS_NOT_AVAILABLE]: "Le plan n'est pas disponible",
  [ErrorDefinitions.PLAN_PRODUCT_NOT_FOUND]: 'Produit du plan non trouvé',
  [ErrorDefinitions.PRICE_NOT_FOUND]: 'Prix non trouvé',
  [ErrorDefinitions.USER_NOT_FOUND]: 'Utilisateur non trouvé',
  [ErrorDefinitions.USER_PLAN_NOT_FOUND]: 'Plan utilisateur non trouvé',
  [ErrorDefinitions.USER_EXIST]: "L'adresse e-mail existe déjà",
  [ErrorDefinitions.WRONG_PASSWORD]: 'Mot de passe incorrect',
  [ErrorDefinitions.USER_NOT_VERIFIED]: 'Utilisateur non vérifié',
  [ErrorDefinitions.REFERRED_USER_EXIST]: "L'utilisateur référé existe déjà",
  [ErrorDefinitions.CAN_NOT_PURCHASE]: 'Achat impossible',
  [ErrorDefinitions.CAN_NOT_UPGRADE]: 'Mise à niveau impossible',
  [ErrorDefinitions.SUBSCRIPTION_NOT_FOUND]: 'Abonnement non trouvé',
  [ErrorDefinitions.SUBSCRIPTION_ITEM_NOT_FOUND]:
    "Élément d'abonnement non trouvé",
  [ErrorDefinitions.SUBSCRIPTION_CHANGE_NOT_FOUND]:
    "Changement d'abonnement non trouvé",
  [ErrorDefinitions.SUBSCRIPTION_IS_NOT_ACTIVE]: "L'abonnement n'est pas actif",
  [ErrorDefinitions.UNKNOWN_EVENT_TYPE]: "Type d'événement inconnu",
  [ErrorDefinitions.TEAM_NOT_FOUND]: 'Équipe non trouvée',
  [ErrorDefinitions.ORG_NOT_FOUND]: 'Organisation non trouvée',
  [ErrorDefinitions.ACCESS_NOT_FOUND]: 'Accès non trouvé',
  [ErrorDefinitions.USER_ALREADY_EXISTS_IN_THIS_ORGANIZATION]:
    "L'utilisateur existe déjà dans cette organisation",
  [ErrorDefinitions.USERS_ALREADY_EXISTS_IN_THIS_TEAM]:
    'Les utilisateurs existent déjà dans cette équipe',
  [ErrorDefinitions.INVITATION_ALREADY_EXISTS]: "L'invitation existe déjà",
  [ErrorDefinitions.INVITATION_NOT_FOUND]: 'Invitation non trouvée',
  [ErrorDefinitions.ORGANIZATION_NOT_FOUND]: 'Organisation non trouvée',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_DOWNGRADE]:
    "Réduction du nombre de sièges de l'organisation",
  [ErrorDefinitions.AI_CREDIT_REASON_NOT_DEFINED]:
    'Raison du crédit IA non définie',
  [ErrorDefinitions.INSUFFICIENT_AI_CREDIT]: 'Crédit IA insuffisant',
  [ErrorDefinitions.WEBHOOK_EXISTS]: 'Le webhook existe déjà',
  [ErrorDefinitions.WEBHOOK_NOT_EXISTS]: "Le webhook n'existe pas",
  [ErrorDefinitions.WEBHOOK_NO_CHANGE]: 'Aucun changement du webhook',
  [ErrorDefinitions.PASSWORD_RESET_REQUEST_EXPIRED_OR_NOT_FOUND]:
    'Demande de réinitialisation de mot de passe expirée ou non trouvée',
  [ErrorDefinitions.TEAM_IS_NOT_IN_THIS_ORGANIZATION]:
    "L'équipe n'est pas dans cette organisation",
  [ErrorDefinitions.USERS_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Utilisateurs non trouvés dans cette organisation',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_TEAM]:
    'Utilisateur non trouvé dans cette équipe',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Utilisateur non trouvé dans cette organisation',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_USER]:
    'Impossible de mettre à jour son propre utilisateur',
  [ErrorDefinitions.MISSING_QUESTION_TYPES]: 'Types de questions manquants',
  [ErrorDefinitions.USER_ALREADY_CLASSIFIED]: 'Utilisateur déjà classifié',
  [ErrorDefinitions.WRONG_VERIFICATION_CODE]:
    'Le code saisi est incorrect. Veuillez vérifier votre e-mail.',
  [ErrorDefinitions.USER_ALREADY_VERIFIED]: 'Utilisateur déjà vérifié',
  [ErrorDefinitions.TARGET_USER_IS_OWNER]:
    "L'utilisateur cible est le propriétaire",
  [ErrorDefinitions.CANNOT_REMOVE_SELF_USER]:
    'Impossible de supprimer son propre utilisateur',
  [ErrorDefinitions.CANNOT_REMOVE_ADMIN_USER]:
    "Impossible de supprimer l'utilisateur administrateur",
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_REACHED]:
    "Nombre maximum de sièges de l'organisation atteint",
  [ErrorDefinitions.THEME_NOT_FOUND]: 'Thème non trouvé',
  [ErrorDefinitions.SLIDELAYOUT_EXIST]:
    'La mise en page de diapositive existe déjà',
  [ErrorDefinitions.SLIDELAYOUT_NOT_FOUND]:
    'Mise en page de diapositive non trouvée',
  [ErrorDefinitions.THEMECOLOR_NOT_FOUND]: 'Couleur du thème non trouvée',
  [ErrorDefinitions.THEMEFONT_NOT_FOUND]: 'Police du thème non trouvée',
  [ErrorDefinitions.COMPONENT_NOT_FOUND]: 'Composant non trouvé',
  [ErrorDefinitions.STRIPE_CUSTOMER_NOT_FOUND]: 'Client Stripe non trouvé',
  [ErrorDefinitions.ASSET_NOT_FOUND]: 'Ressource non trouvée',
  [ErrorDefinitions.FOLDER_NOT_FOUND]: 'Dossier non trouvé',
  [ErrorDefinitions.DECK_NOT_FOUND]: 'Présentation non trouvée',
  [ErrorDefinitions.DECK_ALREADY_SHARED]: 'Présentation déjà partagée',
  [ErrorDefinitions.DECKSHARE_NOT_FOUND]: 'Partage de présentation non trouvé',
  [ErrorDefinitions.CUSTOMDOMAIN_NOT_FOUND]: 'Domaine personnalisé non trouvé',
  [ErrorDefinitions.SHORTCODE_ALREADY_EXISTS]: 'Le code court existe déjà',
  [ErrorDefinitions.CUSTOMDOMAIN_ALREADY_EXISTS]:
    'Le domaine personnalisé existe déjà',
  [ErrorDefinitions.CUSTOMDOMAINSLUG_ALREADY_EXISTS]:
    'Le slug de domaine personnalisé existe déjà',
  [ErrorDefinitions.SLIDEDATA_NOT_FOUND]: 'Données de diapositive non trouvées',
  [ErrorDefinitions.DECKDATA_NOT_FOUND]: 'Données de présentation non trouvées',
  [ErrorDefinitions.OPENAI_MAXRETRY_REACHED]:
    'Limite maximale de tentatives OpenAI atteinte',
  [ErrorDefinitions.THEMECATEGORY_NOT_FOUND]: 'Catégorie de thème non trouvée',
  [ErrorDefinitions.MINROLE_ERROR]: "Erreur d'autorisation de rôle minimal",
  [ErrorDefinitions.SLIDE_NOT_FOUND]: 'Diapositive non trouvée',
  [ErrorDefinitions.INVALID_OFFSET]: 'Décalage invalide',
  [ErrorDefinitions.FORM_QUESTION_NOT_FOUND]:
    'Question de formulaire non trouvée',
  [ErrorDefinitions.UNKNOWN_FORMQUESTION_TYPE]:
    'Type de question de formulaire inconnu',
  [ErrorDefinitions.MODERATION_ERROR]: 'Erreur de modération',
  [ErrorDefinitions.DECKPROMPT_NOT_FOUND]: 'Invite de présentation non trouvée',
  [ErrorDefinitions.DECKSTATE_IS_NOT_FINAL]:
    "L'état de la présentation n'est pas final",
  [ErrorDefinitions.THEMECOLOR_THEMEFONT_NOT_IN_SAME_THEMECATEGORY]:
    'La couleur du thème et la police du thème doivent être dans la même catégorie',
  [ErrorDefinitions.CONTENT_TOO_LONG]: 'Le contenu est trop long',
  [ErrorDefinitions.DECK_EXPORT_IN_PROGRESS]:
    'Exportation de la présentation en cours',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_PERMISSION]:
    'Impossible de mettre à jour ses propres permissions',
  [ErrorDefinitions.CANNOT_UPDATE_OWNERS_PERMISSION]:
    "Impossible de mettre à jour les permissions des propriétaires de l'organisation",
  [ErrorDefinitions.USER_DECK_NOT_FOUND]:
    "Présentation de l'utilisateur non trouvée",
  [ErrorDefinitions.PASSWORD_RESET_REQUIRED]:
    'La réinitialisation du mot de passe est requise, veuillez changer votre mot de passe',
  [ErrorDefinitions.ALREADY_REFERRED]: 'Cet utilisateur a déjà été référé',
  [ErrorDefinitions.CAPTCHA_NOT_CORRECT]: 'Impossible de valider le Captcha',
  [ErrorDefinitions.CANCEL_PROMO_ALREADY_USED]:
    'La promotion a déjà été utilisée',
  [ErrorDefinitions.SUBSCRIPTION_ID_NOT_FOUND]: "ID d'abonnement non trouvé",
}
