import { css } from '@emotion/react'

export const chartStyles =
  ({
    width,
    height,
    bgColor,
    opacity,
  }: {
    width?: number
    height?: number
    bgColor?: string
    opacity?: number
  }) =>
  () => css`
    position: relative;

    &:before {
      content: '';
      width: ${width ? width + 'px' : '100%'};
      max-width: 100%;
      height: ${height ? height + 'px' : '100%'};
      max-height: 100%;
      background-color: ${bgColor};
      opacity: ${opacity};
      position: absolute;
      top: 0;
      left: 0;
    }
  `
