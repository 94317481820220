import React, { useEffect, useMemo, useState } from 'react'

import { IFormResponses } from './types'
import {
  formResponsesStyles,
  headerStyles,
  titleStyles,
  closeWrapperStyles,
  bodyStyles,
  noAnswerStyles,
  noAnswersDesprictionStyles,
} from './styles'
import { useDecksApi, useLanguage } from 'src/hooks'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'

import faq from 'src/assets/images/faq.svg'
import { FormTable } from './form-table'
import { getDeckFormResponsesResponse } from 'src/types/api/responseObjects'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const FormResponses: React.FC<IFormResponses> = React.memo(
  ({ className, dataAttr, onClickClose, onClickShare, deckId }) => {
    const { t } = useLanguage()
    const { decks } = useSelector(({ decks }: RootState) => ({
      decks: decks.list.data,
    }))
    const { deckFormResponses } = useDecksApi()
    const [data, setData] = useState<
      getDeckFormResponsesResponse['data'] | null
    >(null)

    const activeDeck = useMemo(() => {
      return decks?.find((deck) => deck.deck.id === deckId)
    }, [decks, deckId])

    const getDeckFormResponses = async () => {
      const deckOrganizationId = activeDeck?.deck.organizationId
      const response = await deckFormResponses(
        { deckId: deckId! },
        deckOrganizationId,
      )
      setData(response)
    }

    useEffect(() => {
      getDeckFormResponses()
    }, [deckId])

    return (
      <div css={formResponsesStyles} className={className} {...dataAttr}>
        <div css={headerStyles}>
          <div css={titleStyles}>{t('form_response.form_responses')}</div>
          <Button
            css={closeWrapperStyles}
            icon={icons.close}
            type={BUTTON_TYPE.GHOST}
            onClick={onClickClose}
            theme={BUTTON_THEME.GRAY}
          />
        </div>

        <div css={bodyStyles}>
          {data && data.responses.length !== 0 ? (
            <FormTable data={data} />
          ) : (
            <div css={noAnswerStyles}>
              <img src={faq} />
              <div css={noAnswersDesprictionStyles}>
                {t('form_response.no_answers_yet')}
              </div>
              <Button
                onClick={onClickShare}
                type={BUTTON_TYPE.GHOST}
                text={t('analytics.share_this_deck')}
                size={BUTTON_SIZE.XSMALL}
              />
            </div>
          )}
        </div>
      </div>
    )
  },
)

FormResponses.displayName = 'FormResponses'
