import React, { useCallback } from 'react'

import { IUpgradeModals } from './types'
import { upgradeModalsStyles } from './styles'
import { Plans } from '../settings-panel/components/plans/Plans'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE, Button } from 'src/lib/button'
import { contentAreaStyles } from '../settings-panel/styles'
import { icons } from 'src/lib'
import { useLanguage } from 'src/hooks/useLanguage'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { PlanNames } from 'src/types/api/enums'

export const UpgradeModals: React.FC<IUpgradeModals> = React.memo(
  ({
    refillMode,
    className,
    dataAttr,
    onCloseClick,
    availablePlanNames,
    context,
  }) => {
    const { t } = useLanguage()

    const { currentPlan } = useSelector(({ user }: RootState) => ({
      currentPlan: user.data?.activeUserPlan?.plan.name,
    }))

    const title = useCallback(() => {
      if (currentPlan) {
        if (!refillMode && currentPlan == PlanNames.PRO) {
          return t('upgrade_modal.upgrade_to_business')
        } else {
          return t('upgrade_modal.refill_your_credits')
        }
      } else return t('upgrade_modal.choose_plan')
    }, [])

    const subtitle = useCallback(() => {
      if (currentPlan) {
        if (!refillMode && currentPlan == PlanNames.PRO) {
          return t('upgrade_modal.upgrade_to_business_text')
        } else {
          return t('upgrade_modal.upgrade_refill_modal_subtitle')
        }
      } else return t('upgrade_modal.upgrade_modal_subtitle')
    }, [])
    return (
      <div css={upgradeModalsStyles} className={className} {...dataAttr}>
        <div className="close-button-area">
          <Button
            icon={icons.close}
            size={BUTTON_SIZE.AUTO}
            type={BUTTON_TYPE.GHOST}
            theme={BUTTON_THEME.ELEMENTS}
            iconSize={20}
            onClick={onCloseClick}
          />
        </div>
        <div css={contentAreaStyles(true)}>
          <Plans
            key="plans"
            upgradeModal={true}
            title={title()}
            subtitle={subtitle()}
            availablePlanNames={availablePlanNames}
            context={context}
            onCloseClick={onCloseClick}
          />
        </div>
      </div>
    )
  },
)

UpgradeModals.displayName = 'UpgradeModals'
