import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { VALIDATION_RULE_TYPES, useLanguage, useUserApi } from 'src/hooks'
import { RootState } from 'src/store'
import { Button, Input } from 'src/lib'

export const NameEdit: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const { updateUser } = useUserApi()

  const { fullName } = useSelector(({ user }: RootState) => ({
    fullName: user.data?.fullName,
  }))

  const [fullNameValid, setFullNameValid] = useState(false)
  const [fullNameState, setFullNameState] = useState<string>(fullName || '')

  const handleChange = (value: string) => {
    setFullNameState(value)
  }

  const onSaveChanges = useCallback(() => {
    if (fullNameState.length >= 3 && fullName !== fullNameState) {
      updateUser({ fullName: fullNameState })
    }
  }, [fullNameState])

  return (
    <>
      <Input
        value={fullNameState}
        label={t('profile.edit_your_name')}
        onChange={handleChange}
        validation={{
          [VALIDATION_RULE_TYPES.MIN]: {
            value: 3,
            text: t('validation.min_char', {
              name: t('common.full_name'),
              value: 3,
            }),
          },
        }}
        onValidation={setFullNameValid}
        solidError
      />
      <Button
        text={t('profile.update.save_changes')}
        disabled={!fullNameValid}
        onClick={onSaveChanges}
      />
    </>
  )
})
