import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLanguage } from 'src/hooks'
import { INPUT_THEME, Input, SWITCH_SIZE, Switch } from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  ComponentChartDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { useDebouncer } from 'src/hooks'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { IEditPropSection } from '../../types'

export const XAxis: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const selectedXLabel = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const xLabel = (c.data as ComponentChartDataSchema).options.labels.x
          if (a === '' && xLabel) {
            return xLabel
          }

          return xLabel !== a ? '' : a
        }, ''),
      [components],
    )

    const selectedShowX = useMemo(
      () =>
        components?.reduce(
          (a: boolean | undefined, c: UpdateComponentSchema) => {
            const showX = (c.data as ComponentChartDataSchema).options.labels
              .showX
            if (a === undefined && showX) {
              return showX
            }

            return showX !== a ? false : a
          },
          undefined,
        ),
      [components],
    )

    const [value, setValue] = useState(selectedXLabel)
    const handleOnChange = useCallback(
      (value?: string) => {
        setValue(value)
      },
      [components],
    )

    const debounced = useDebouncer(
      () => {
        updateComponentState(value)
      },
      { delay: 500 },
    )

    useEffect(() => {
      if (value !== selectedXLabel) {
        debounced()
      }
    }, [value])

    const updateComponentState = useCallback(
      (val?: string) => {
        const partialData: DeepPartial<ComponentChartDataSchema> = {
          options: { labels: { x: val } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentChartDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const handleClick = useCallback(
      (value?: boolean) => {
        const partialData: DeepPartial<ComponentChartDataSchema> = {
          options: { labels: { showX: value } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentChartDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <>
        <div className="c-full control-row">
          <span>{t('edit.properties.x_axis.show_x_axis')}</span>
          <Switch
            size={SWITCH_SIZE.SMALL}
            selected={selectedShowX}
            onChange={handleClick}
          />
        </div>
        <div className="c-full control-row">
          <Input
            theme={INPUT_THEME.DARK}
            placeholder={t('edit.properties.x_axis.x_axis')}
            value={value}
            onChange={handleOnChange}
            onFocus={() => dispatch(setAllowDeleteShortcut(false))}
            onBlur={() => dispatch(setAllowDeleteShortcut(true))}
          />
        </div>
      </>
    )
  },
)

XAxis.displayName = 'XAxis'
