import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setDecksQuery, setShouldClearSearch } from 'src/store'
import { useLanguage } from 'src/hooks'
import { useSearchParams } from 'react-router-dom'
import noResultsDeckto from 'src/assets/images/deckto-noresult.svg'
import {
  searchResultsWrapperStyles,
  searchResultsTitleStyles,
  searchResultsPlaceholderStyles,
  searchResultsInnerStyles,
  searchResultsDecktoStyles,
  searchResultsPlaceholderTitleStyles,
  searchResultsPlaceholderSubTitleStyles,
  searchResultsPlaceholderLinkStyles,
} from './styles'

export const DashboardSearchResults: React.FC = React.memo(() => {
  const { t, Trans } = useLanguage()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const { decksQuery, decksCount } = useSelector(({ decks }: RootState) => ({
    decksQuery: decks.decksQuery,
    decksCount: decks.totalCount,
  }))

  const searchParam = useMemo(() => decksQuery.search, [decksQuery.search])

  const returnDashboardHandler = useCallback(() => {
    searchParams.delete('folderId')
    setSearchParams(searchParams)
    dispatch(setShouldClearSearch(true))
    dispatch(setDecksQuery({}))
  }, [searchParams])

  return (
    <div css={searchResultsWrapperStyles}>
      <div css={searchResultsTitleStyles}>
        {t('dashboard.search.results_for', { searchParam })}
      </div>
      {!decksCount && (
        <div css={searchResultsPlaceholderStyles}>
          <div css={searchResultsInnerStyles}>
            <div css={searchResultsDecktoStyles}>
              <div>
                <img src={noResultsDeckto} />
              </div>
            </div>
            <div css={searchResultsPlaceholderTitleStyles}>
              <Trans
                i18nKey={'dashboard.search.no_results_for'}
                values={{ searchParam }}
              />
            </div>
            <div css={searchResultsPlaceholderSubTitleStyles}>
              {t('dashboard.search.try_another_search')}
            </div>
            <div
              onClick={returnDashboardHandler}
              css={searchResultsPlaceholderLinkStyles}
            >
              {t('dashboard.search.return_dashboard')}
            </div>
          </div>
        </div>
      )}
    </div>
  )
})
