import React, { useState } from 'react'
import { useLanguage } from 'src/hooks'
import { useTheme } from 'src/theme'
import { IOrganizationHeader } from './types'
import {
  orgHeaderStyles,
  orgHeaderIconStyles,
  orgHeaderTitle,
  orgHeaderAddMembers,
  orgHeaderAddMembersWrapperStyles,
} from './styles'
import { Icon, icons, Modal } from 'src/lib'
import { OrganizationAddMembers } from '../OrganizationMembers/components'

export const OrganizationHeader: React.FC<IOrganizationHeader> = React.memo(
  ({
    onMenuClick,
    title,
    isAddMembersAvailable,
    totalSeats,
    occupiedSeats,
    workspaceId,
  }) => {
    const theme = useTheme()
    const { t } = useLanguage()

    const [addMembersModal, setAddMembersModal] = useState(false)

    return (
      <div css={orgHeaderStyles}>
        <div className="flex-row">
          <Icon
            icon={icons.menu}
            color={theme.colors.white.DEFAULT}
            size={24}
            css={orgHeaderIconStyles}
            onClick={() => onMenuClick()}
          />
          <div css={orgHeaderTitle}>{title}</div>
        </div>
        {isAddMembersAvailable && (
          <>
            <div
              css={orgHeaderAddMembersWrapperStyles}
              className="flex-row"
              onClick={() => setAddMembersModal(true)}
            >
              <Icon
                icon={icons.add}
                color={theme.colors.white.DEFAULT}
                size={24}
              />
              <div css={orgHeaderAddMembers}>
                {t('organization.add_members')}
              </div>
            </div>
            <Modal
              isOpen={addMembersModal}
              onClose={() => setAddMembersModal(false)}
            >
              <OrganizationAddMembers
                totalSeats={totalSeats}
                occupiedSeats={occupiedSeats}
                onClose={() => setAddMembersModal(false)}
                workspaceId={workspaceId}
              />
            </Modal>
          </>
        )}
      </div>
    )
  },
)
