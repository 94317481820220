import React, { useCallback, useRef } from 'react'

import { ITextarea } from './types'
import { labelStyles, textAreaElementStyles, textAreaStyles } from './style'

export const Textarea: React.FC<ITextarea> = React.memo(
  ({
    value,
    placeholder,
    rows,
    label,
    onChange,
    onBlur,
    onFocus,
    className,
    dataAttr,
    note,
  }) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.currentTarget.value)
      },
      [onChange],
    )

    const handleOnBlur = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onBlur && onBlur(e.currentTarget.value)
      },
      [onBlur],
    )

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        const cursorPosition = e.currentTarget.selectionStart
        const textBeforeCursor = value.substring(0, cursorPosition)
        const textAfterCursor = value.substring(cursorPosition)

        const newValue = textBeforeCursor + '\n' + textAfterCursor
        onChange(newValue)

        setTimeout(() => {
          if (textareaRef.current) {
            textareaRef.current.selectionStart =
              textareaRef.current.selectionEnd = cursorPosition + 1
          }
        }, 0)
      }
    }

    return (
      <div css={textAreaStyles} {...dataAttr} className={className}>
        {label && <div css={labelStyles}>{label}</div>}
        <textarea
          ref={textareaRef}
          css={textAreaElementStyles(note)}
          value={value}
          placeholder={placeholder}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          rows={rows}
          onBlur={handleOnBlur}
          onFocus={onFocus}
        />
      </div>
    )
  },
)
