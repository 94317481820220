import { dataAttrType } from 'src/types'

export enum ELEMENTS_BLOCK_TYPE {
  DEFAULT = 'default',
  FULL_BOX = 'full_box',
}

export interface IElementsBlock {
  name?: string
  children?: React.ReactNode
  expandable?: boolean
  type?: ELEMENTS_BLOCK_TYPE
  className?: string
  dataAttr?: dataAttrType
}
