import React, { useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import { IEditAiAssistant } from './types'
import {
  animationStyles,
  answerStyles,
  createQaStyles,
  editAiAssistantStyles,
  paddingStyles,
  qaStyles,
  questionStyles,
  tipContentStyles,
  tipStyles,
  tipTitleStyles,
} from './styles'
import { BUTTON_THEME, Button, TABS_THEME, Tabs, Tooltip, icons } from 'src/lib'
import { useAiApi, useLanguage } from 'src/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import tipsImage from 'src/assets/images/tips.svg'
import qaImage from 'src/assets/images/qa.svg'
import qaAnimation from 'src/assets/images/qa.gif'
import tipsAnimation from 'src/assets/images/tips.gif'
import SectionTab from 'src/pages/deck-page-v3/components/section-tab'
import tipsIcon from '../../lib/icon/svg/tips.svg'
import { MaterialSymbol } from 'react-material-symbols'

export const EditAiAssistant: React.FC<IEditAiAssistant> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()

    const { generateTips, generateQA } = useAiApi()

    const { deckId, deckStatus, workspaceId, tips, qa, version } = useSelector(
      ({ edit, workspace, layouts }: RootState) => ({
        deckId: edit.activeDeck.data?.deck?.id,
        deckStatus: edit.activeDeck.data?.deck?.deckPrompt,
        tips: edit.tips,
        qa: edit.qa,
        workspaceId: workspace.id,
        sessionId: edit.activeDeck.data?.deckData?.sessionId,
        version: layouts.version,
      }),
    )

    const [activeTab, setActiveTab] = useState(0)
    const [isLoading, setIsLoading] = useState(false)

    const createQA = async () => {
      setIsLoading(true)
      await generateQA({ deckId: deckId!, organizationId: workspaceId })
      setIsLoading(false)
    }

    const createTips = async () => {
      setIsLoading(true)
      await generateTips({ deckId: deckId!, organizationId: workspaceId })
      setIsLoading(false)
    }
    const isV3 = version === 'v3'

    return (
      <div
        css={(theme) => editAiAssistantStyles(theme, isV3)}
        className={className}
        {...dataAttr}
      >
        {isV3 && (
          <SectionTab
            size="small"
            exclusive
            value={activeTab}
            fullWidth
            onChange={(_, value) => setActiveTab(value)}
            sx={{ margin: '0px', gap: 2 }}
          >
            <ToggleButton fullWidth value={0} sx={{ fontSize: '13px' }}>
              <img
                src={tipsIcon}
                width={20}
                height={20}
                style={{ marginRight: 4 }}
                alt="tips"
              />

              {t('edit.panel.menu_tabs.tips')}
            </ToggleButton>
            <ToggleButton value={1} fullWidth sx={{ fontSize: '13px' }}>
              <MaterialSymbol icon="quiz" size={20} weight={300} />
              {t('edit.panel.menu_tabs.qa')}
            </ToggleButton>
          </SectionTab>
        )}
        <div css={(theme) => paddingStyles(theme, isV3)}>
          {version !== 'v3' && (
            <Tabs
              theme={TABS_THEME.DARK}
              tabs={[
                t('edit.panel.menu_tabs.tips'),
                t('edit.panel.menu_tabs.qa'),
              ]}
              activeTab={activeTab}
              onChange={setActiveTab}
            />
          )}

          {((activeTab == 0 && !tips?.length) ||
            (activeTab == 1 && !qa?.length)) && (
            <div css={(theme) => createQaStyles(theme, isV3)}>
              {!isLoading && <img src={activeTab ? qaImage : tipsImage} />}
              {isLoading && (
                <img
                  src={activeTab ? qaAnimation : tipsAnimation}
                  css={animationStyles}
                />
              )}
              <div
                style={{
                  lineHeight: isV3 ? '166%' : 'inherit',
                }}
              >
                {activeTab
                  ? t('edit.ai.create_qa_text')
                  : t('edit.ai.create_tip_text')}
              </div>

              {!deckStatus && (
                <Tooltip width={300} text={t('edit.ai.create_tips_hover')}>
                  <div>
                    <Button
                      text={
                        activeTab == 0
                          ? t('edit.ai.create_tip_button')
                          : t('edit.ai.create_qa_button')
                      }
                      icon={icons.sparkling}
                      theme={BUTTON_THEME.GRADIENT}
                      onClick={
                        activeTab == 0 ? () => createTips() : () => createQA()
                      }
                      disabled={!deckStatus}
                      isLoading={isLoading}
                    />
                  </div>
                </Tooltip>
              )}

              {deckStatus && (
                <Button
                  text={
                    activeTab == 0
                      ? t('edit.ai.create_tip_button')
                      : t('edit.ai.create_qa_button')
                  }
                  icon={icons.sparkling}
                  theme={BUTTON_THEME.GRADIENT}
                  onClick={
                    activeTab == 0 ? () => createTips() : () => createQA()
                  }
                  disabled={!deckStatus}
                  isLoading={isLoading}
                />
              )}
            </div>
          )}
        </div>
        {activeTab == 1 && qa && qa.length > 0 && (
          <div css={(theme) => createQaStyles(theme, isV3)}>
            {qa?.map((item, index) => (
              <div css={(theme) => paddingStyles(theme, isV3)} key={index}>
                <div css={(theme) => qaStyles(theme, isV3)}>
                  <div css={(theme) => questionStyles(theme, isV3, true)}>
                    {index + 1}.{' ' + item.question}
                  </div>
                  <div css={(theme) => answerStyles(theme, isV3)}>
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab == 0 && tips?.length != undefined && (
          <div css={tipStyles}>
            {activeTab == 0 &&
              tips?.map((item, index) => (
                <div key={index} css={(theme) => createQaStyles(theme, isV3)}>
                  <div css={(theme) => tipTitleStyles(theme, isV3)}>
                    {index + 1}.{' ' + item.title}
                  </div>
                  {item.deckTips.map((tips, index) => (
                    <div
                      key={index}
                      css={(theme) => paddingStyles(theme, isV3)}
                    >
                      <div css={(theme) => questionStyles(theme, isV3)}>
                        {tips.title}
                      </div>
                      <div css={() => tipContentStyles(isV3)}>
                        {tips.content}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        )}
      </div>
    )
  },
)

EditAiAssistant.displayName = 'EditAiAssistant'
