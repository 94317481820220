export const placeToNextRange = (
  target: number,
  list: (number | undefined)[],
): number => {
  // Filter the array to keep only numbers greater than the target
  const filteredArray = list.filter(
    (num) => num !== undefined && num > target,
  ) as number[]

  // Sort the filtered array in ascending order
  filteredArray.sort((a, b) => a - b)

  if (filteredArray.length >= 2) {
    // If there are at least two numbers in the filtered array,
    // calculate the result as the average of the next two bigger numbers
    return (filteredArray[0] + filteredArray[1]) / 2
  } else if (filteredArray.length === 1) {
    // If there is only one number in the filtered array,
    // return the average of that number and the target plus 1
    return (filteredArray[0] + target + 1) / 2
  } else {
    // If there are no numbers greater than the target,
    // return the target plus 1
    return target + 1
  }
}
