import { dataAttrType } from 'src/types'

export enum TOOLTIP_THEME {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum TOOLTIP_PLACE {
  TOP_LEFT = 'top-start',
  TOP = 'top',
  TOP_RIGHT = 'top-end',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM_LEFT = 'bottom-start',
  BOTTOM = 'bottom',
  BOTTOM_RIGHT = 'bottom-end',
}
export interface ITooltip {
  height?: number
  text: string
  theme?: TOOLTIP_THEME
  width?: number
  place?: TOOLTIP_PLACE
  children?: React.ReactElement
  className?: string
  dataAttr?: dataAttrType
}
