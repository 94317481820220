import React, { useMemo, useRef, useState } from 'react'
import { Icon, icons } from 'src/lib/icon'
import {
  iconStyles,
  menuIconStyles,
  menuStyles,
  textStyles,
  wrapperStyles,
  icontextWrapperStyles,
} from './styles'
import { FOLDER_TYPE, IFolderItem } from './types'
import { useClickOutside } from 'src/hooks'
import { RootState, setActiveFolderId, setDeckCountInFolder } from 'src/store'
import { useDispatch, useSelector } from 'react-redux'

export const FolderItem: React.FC<IFolderItem> = React.memo(
  ({
    menu,
    isMenuOpen,
    text,
    folderID,
    isActive = false,
    type = FOLDER_TYPE.DEFAULT,
    onClick,
    onMenuChange,
    className,
    dataAttr,
  }) => {
    const menuRef = useRef(null)
    const dispatch = useDispatch()
    const [isHover, setIsHover] = useState(false)
    const outsideClick = () => {
      onMenuChange?.(false)
    }
    useClickOutside(menuRef, outsideClick)

    const { folders } = useSelector(({ folders }: RootState) => ({
      folders: folders.list,
    }))

    const deckCount = useMemo(() => {
      return folders.find((items) => items.id == folderID)?.deckCount || 0
    }, [folders, folderID])

    const handleMouseEnter = () => {
      if (FOLDER_TYPE.CREATE || FOLDER_TYPE.DEFAULT) {
        setIsHover(true)
      }
    }

    const handleMouseLeave = () => {
      if (FOLDER_TYPE.CREATE || FOLDER_TYPE.DEFAULT) {
        setIsHover(false)
      }
    }

    const handleMenuClick = (
      event: React.MouseEvent<HTMLOrSVGElement, MouseEvent>,
    ) => {
      {
        dispatch(setActiveFolderId(folderID!))
        dispatch(setDeckCountInFolder(deckCount))
      }
      event.stopPropagation()
      onMenuChange?.(true)
    }

    return (
      <div
        css={wrapperStyles({ type, isHover, isActive })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={className}
        {...dataAttr}
      >
        <div onClick={onClick} css={icontextWrapperStyles}>
          <Icon
            css={iconStyles({ type, isHover, isActive })}
            icon={icons.form}
          />
          <span css={textStyles({ type, isHover, isActive })}>{text}</span>
        </div>

        {menu && (
          <Icon
            css={menuIconStyles}
            icon={icons.menu_horizontal}
            onClick={handleMenuClick}
          />
        )}
        {isMenuOpen && (
          <div css={menuStyles} ref={menuRef}>
            {menu}
          </div>
        )}
      </div>
    )
  },
)

export { FOLDER_TYPE }

FolderItem.displayName = 'FolderItem'
