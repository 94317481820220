import React, { useCallback, useMemo } from 'react'

import { PropColor } from 'src/pages/deck-page/components/edit-properties/props'
import {
  ComponentMediaDataSchema,
  GradientLikeColorSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { IEditPropSection } from '../../types'

export const BackgroundColor: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()
    const selectedColor = useMemo(
      () =>
        components?.reduce(
          (
            a: GradientLikeColorSchema | undefined,
            c: UpdateComponentSchema,
          ) => {
            const fillColor = (c.data as ComponentMediaDataSchema).style?.color
              .background
            if (a === undefined && fillColor) {
              return fillColor
            }

            return fillColor !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleOnChange = useCallback(
      (newColor: GradientLikeColorSchema) => {
        const partialData: DeepPartial<ComponentMediaDataSchema> = {
          style: { color: { background: newColor } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentMediaDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return <PropColor color={selectedColor} onChange={handleOnChange} />
  },
)

BackgroundColor.displayName = 'BackgroundColor'
