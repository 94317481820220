import { css } from '@emotion/react'
import { ITheme, fontWeight } from 'src/theme'

export const editPanelStyles = ({ colors, dimensions, gap }: ITheme) => css`
  background: ${colors.dark.DEFAULT};
  width: ${dimensions.editPanelWidth.DEFAULT}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${gap.xsmall}px;
  padding: ${gap.xsmall}px 0;
  height: 100%;
  overflow-x: auto;
  flex-shrink: 0;
`
interface IEditPanelItem {
  isGradient?: boolean
  isSelected?: boolean
}
export const editPanelItem =
  ({ isGradient, isSelected }: IEditPanelItem) =>
  ({
    colors,
    dimensions,
    borderRadius,
    gap,
    fontSize,
    gradient,
  }: ITheme) => css`
    cursor: pointer;
    color: ${colors.white.DEFAULT};
    width: ${dimensions.editPanelItemSize.DEFAULT}px;
    height: ${dimensions.editPanelItemSize.DEFAULT}px;
    border-radius: ${borderRadius[4]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${gap['3xsmall']}px;
    transition: background 0.2s;
    font-size: ${fontSize.small};
    font-weight: ${fontWeight.medium};

    ${isGradient &&
    `
		.icon-area {
			width: 40px;
			height: 40px;
			background: ${gradient.DEFAULT};
			border-radius: ${borderRadius[8]};
			display: flex;
			align-items: center;
			justify-content: center;

		}
    .animation {
      height: 70px;
      margin-top: 8px;
      margin-left: 4px;
    }`}

    ${isSelected && `background: ${colors.dark2.DEFAULT};`}


    &:not(.disabled):hover {
      background: ${colors.dark2.DEFAULT};
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  `
