import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const propMediaModalStyles = ({ colors, borderRadius }: ITheme) => css`
  background: ${colors.white.DEFAULT};
  width: 625px;
  height: 400px;
  display: flex;
  border-radius: ${borderRadius[10]};
`

export const leftMenuStyles = ({ gap }: ITheme) => css`
  padding: ${gap.xlarge}px;
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
`
export const rightAreaStyles = ({ gap }: ITheme) => css`
  position: relative;
  padding: ${gap.xlarge}px ${gap.xlarge}px 0 ${gap.xlarge}px;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
`

export const mediaTypeStyles = css`
  width: 100%;
  flex-grow: 1;
`

interface IMediaTypeItemStyles {
  isSelected?: boolean
}
export const mediaTypeItemStyles =
  ({ isSelected }: IMediaTypeItemStyles) =>
  ({ gap, colors }: ITheme) => css`
    cursor: pointer;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    gap: ${gap['2xsmall']}px;
    ${isSelected && `color: ${colors.primary.DEFAULT};`}
  `

export const uploadButtonStyles = css`
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }
`

export const rightAreaHeaderStyles = ({
  gap,
  colors,
  fontSize,
  fontWeight,
}: ITheme) => css`
  position: relative;
  .close-button {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
  }

  .title {
    font-size: ${fontSize.base};
    font-weight: ${fontWeight.medium};
    margin-bottom: ${gap.small}px;
  }

  a {
    color: ${colors.black.DEFAULT};
  }
`

export const searchInputStyles = ({ gap }: ITheme) => css`
  margin-bottom: ${gap.medium}px;
`
