import { setUploadFunc } from 'polotno/config'
import { uploadImageProcess } from 'src/hooks/api/useConfigApi'

// define our upload function
// you have to write your own logic, that fits your API
async function upload(file: File) {
  return await uploadImageProcess(file)
}

// set new function
setUploadFunc(upload)
