export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_16)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_9_16"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1079H0V0ZM140 539.5C140 277.58 390.046 65.1205 699 64V1015C390.046 1013.88 140 801.42 140 539.5Z" fill="url(#decor-bg)"/><path d="M960 858C960 957.964 878.964 1039 779 1039C679.036 1039 598 957.964 598 858C598 758.036 679.036 677 779 677C878.964 677 960 758.036 960 858Z" fill="$color4"/><path d="M262.998 146.499C262.998 185.986 230.986 217.998 191.499 217.998C152.011 217.998 120 185.986 120 146.499C120 107.011 152.011 75 191.499 75C230.986 75 262.998 107.011 262.998 146.499Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M314.115 291.586C378.066 291.586 429.908 239.744 429.908 175.793C429.908 111.842 378.066 60 314.115 60C250.165 60 198.322 111.842 198.322 175.793C198.322 239.744 250.165 291.586 314.115 291.586ZM314.115 298.586C381.932 298.586 436.908 243.61 436.908 175.793C436.908 107.976 381.932 53 314.115 53C246.299 53 191.322 107.976 191.322 175.793C191.322 243.61 246.299 298.586 314.115 298.586Z" fill="$color4"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_54)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1079H0V0ZM762 63C784.091 63 802 80.9087 802 103V977.114C802 999.205 784.091 1017.11 762 1017.11H107.589C85.4977 1017.11 67.5891 999.205 67.5891 977.114V103C67.5891 80.9086 85.4977 63 107.589 63H762Z" fill="url(#decor-bg)"/><path d="M1763.99 74.53C1784.21 115.233 1833.6 131.838 1874.3 111.62C1915 91.402 1931.61 42.015 1911.39 1.313C1911.17 0.873 1910.95 0.436 1910.72 0L1875.05 18.11C1885.63 38.931 1877.32 64.384 1856.5 74.96C1835.68 85.536 1810.23 77.231 1799.65 56.41L1763.99 74.53Z" fill="$color4"/><path fill-rule="evenodd" clip-rule="evenodd" d="M98 211C171.454 211 231 151.454 231 78C231 4.54614 171.454 -55 98 -55C24.5462 -55 -35 4.54609 -35 78C-35 151.454 24.5461 211 98 211ZM98 221C176.977 221 241 156.977 241 78C241 -0.976715 176.977 -65 98 -65C19.0233 -65 -45 -0.976771 -45 78C-45 156.977 19.0233 221 98 221Z" fill="$color2"/><path d="M148.445 362L-45.3041 176.891C-20.7571 151.198 12.9909 136.309 48.5158 135.499C84.0407 134.689 118.432 148.024 144.125 172.571C169.818 197.118 184.707 230.866 185.517 266.391C186.328 301.915 172.992 336.307 148.445 362Z" fill="$color3"/></g><defs><clipPath id="clip0_9_54"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1079H1920V0ZM1780 539.5C1780 277.58 1529.95 65.1205 1221 64V1015C1529.95 1013.88 1780 801.42 1780 539.5Z" fill="url(#decor-bg)"/><path d="M960 858C960 957.964 1041.04 1039 1141 1039C1240.96 1039 1322 957.964 1322 858C1322 758.036 1240.96 677 1141 677C1041.04 677 960 758.036 960 858Z" fill="$color4"/><path d="M1657 146.499C1657 185.986 1689.01 217.998 1728.5 217.998C1767.99 217.998 1800 185.986 1800 146.499C1800 107.011 1767.99 75 1728.5 75C1689.01 75 1657 107.011 1657 146.499Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1605.88 291.586C1541.93 291.586 1490.09 239.744 1490.09 175.793C1490.09 111.842 1541.93 60 1605.88 60C1669.84 60 1721.68 111.842 1721.68 175.793C1721.68 239.744 1669.84 291.586 1605.88 291.586ZM1605.88 298.586C1538.07 298.586 1483.09 243.61 1483.09 175.793C1483.09 107.976 1538.07 53 1605.88 53C1673.7 53 1728.68 107.976 1728.68 175.793C1728.68 243.61 1673.7 298.586 1605.88 298.586Z" fill="$color4"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_47)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1079H1920V0ZM1158 63C1135.91 63 1118 80.9087 1118 103V977.114C1118 999.205 1135.91 1017.11 1158 1017.11H1812.41C1834.5 1017.11 1852.41 999.205 1852.41 977.114V103C1852.41 80.9086 1834.5 63 1812.41 63H1158Z" fill="url(#decor-bg)"/><path d="M156.007 74.53C135.789 115.233 86.4031 131.838 45.7001 111.62C4.99712 91.402 -11.6089 42.015 8.61011 1.313C8.82811 0.873 9.05011 0.436 9.27711 0L44.9471 18.11C34.3711 38.931 42.6761 64.384 63.4971 74.96C84.3181 85.536 109.771 77.231 120.347 56.41L156.007 74.53Z" fill="$color4"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1822 211C1748.55 211 1689 151.454 1689 78C1689 4.54614 1748.55 -55 1822 -55C1895.45 -55 1955 4.54609 1955 78C1955 151.454 1895.45 211 1822 211ZM1822 221C1743.02 221 1679 156.977 1679 78C1679 -0.976715 1743.02 -65 1822 -65C1900.98 -65 1965 -0.976771 1965 78C1965 156.977 1900.98 221 1822 221Z" fill="$color2"/><path d="M1771.55 362L1965.3 176.891C1940.76 151.198 1907.01 136.309 1871.48 135.499C1835.96 134.689 1801.57 148.024 1775.87 172.571C1750.18 197.118 1735.29 230.866 1734.48 266.391C1733.67 301.915 1747.01 336.307 1771.55 362Z" fill="$color3"/></g><defs><clipPath id="clip0_9_47"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_92)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M101 22C27.5462 22 -32 81.5461 -32 155C-32 228.454 27.5461 288 101 288C174.454 288 234 228.454 234 155C234 81.5461 174.454 22 101 22ZM101 12C22.0233 12 -42 76.0233 -42 155C-42 233.977 22.0233 298 101 298C179.977 298 244 233.977 244 155C244 76.0233 179.977 12 101 12Z" fill="$color2"/><path d="M50.5545 -128.999L244.304 56.1096C219.757 81.8024 186.009 96.6916 150.484 97.5018C114.959 98.312 80.5675 84.9768 54.8747 60.4299C29.182 35.8829 14.2927 2.13484 13.4825 -33.39C12.6723 -68.9149 26.0075 -103.307 50.5545 -128.999Z" fill="$color3"/></g><defs><clipPath id="clip0_9_92"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_99)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path d="M231 36C231 135.964 149.964 217 50 217C-49.9635 217 -131 135.964 -131 36C-131 -63.9635 -49.9635 -145 50 -145C149.964 -145 231 -63.9635 231 36Z" fill="$color4"/><path d="M1547 977L1891.68 778C1918.07 823.707 1925.22 878.025 1911.56 929.005C1897.9 979.985 1864.55 1023.45 1818.84 1049.84C1773.13 1076.23 1718.81 1083.38 1667.83 1069.72C1616.85 1056.06 1573.39 1022.71 1547 977Z" fill="$color3"/><path d="M1756.09 850.172C1756.09 880.548 1731.46 905.172 1701.09 905.172C1670.71 905.172 1646.09 880.548 1646.09 850.172C1646.09 819.796 1670.71 795.172 1701.09 795.172C1731.46 795.172 1756.09 819.796 1756.09 850.172Z" fill="$color2"/></g><defs><clipPath id="clip0_9_99"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_3:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_103)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path d="M1764 74.53C1784.22 115.233 1833.6 131.838 1874.31 111.62C1915.01 91.402 1931.62 42.015 1911.4 1.313C1911.18 0.873 1910.96 0.436 1910.73 0L1875.06 18.11C1885.64 38.931 1877.33 64.384 1856.51 74.96C1835.69 85.536 1810.24 77.231 1799.66 56.41L1764 74.53Z" fill="$color4"/><path fill-rule="evenodd" clip-rule="evenodd" d="M98 869C171.454 869 231 928.546 231 1002C231 1075.45 171.454 1135 98 1135C24.5461 1135 -35 1075.45 -35 1002C-35 928.546 24.5461 869 98 869ZM98 859C176.977 859 241 923.023 241 1002C241 1080.98 176.977 1145 98 1145C19.0233 1145 -45 1080.98 -45 1002C-45 923.023 19.0233 859 98 859Z" fill="$color2"/><path d="M148.445 718L-45.3041 903.109C-20.7572 928.802 12.9909 943.691 48.5157 944.501C84.0406 945.311 118.432 931.976 144.125 907.429C169.818 882.882 184.707 849.134 185.517 813.609C186.328 778.085 172.992 743.693 148.445 718Z" fill="$color3"/></g><defs><clipPath id="clip0_9_103"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_4:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_95)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path d="M1689 36C1689 135.964 1770.04 217 1870 217C1969.96 217 2051 135.964 2051 36C2051 -63.9635 1969.96 -145 1870 -145C1770.04 -145 1689 -63.9635 1689 36Z" fill="$color4"/><path d="M747 17C747 47.3757 771.624 72 802 72C832.376 72 857 47.3757 857 17C857 -13.3757 832.376 -38 802 -38C771.624 -38 747 -13.3757 747 17Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M118 1070C58.3532 1070 10 1021.65 10 962C10 902.353 58.3532 854 118 854C177.647 854 226 902.353 226 962C226 1021.65 177.647 1070 118 1070ZM118 1080C52.8304 1080 0 1027.17 0 962C0 896.83 52.8304 844 118 844C183.17 844 236 896.83 236 962C236 1027.17 183.17 1080 118 1080Z" fill="$color3"/></g><defs><clipPath id="clip0_9_95"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9_84)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1079H0V0ZM1812 632C1834.09 632 1852 649.909 1852 672V977C1852 999.091 1834.09 1017 1812 1017H108C85.9086 1017 68 999.091 68 977V672C68 649.909 85.9087 632 108 632H1812Z" fill="url(#decor-bg)"/><path d="M1920 17C1920 47.3757 1895.38 72 1865 72C1834.62 72 1810 47.3757 1810 17C1810 -13.3757 1834.62 -38 1865 -38C1895.38 -38 1920 -13.3757 1920 17Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M118 1069C177.647 1069 226 1020.65 226 961C226 901.353 177.647 853 118 853C58.3532 853 10 901.353 10 961C10 1020.65 58.3532 1069 118 1069ZM118 1079C183.17 1079 236 1026.17 236 961C236 895.83 183.17 843 118 843C52.8304 843 0 895.83 0 961C0 1026.17 52.8304 1079 118 1079Z" fill="$color3"/></g><defs><clipPath id="clip0_9_84"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="540" transform="matrix(-1 0 0 1 1920 0)" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H1920V1079H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H1920V1079H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H0V1079H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H0V1079H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
