import React, { useCallback } from 'react'

import { IAiError } from './types'
import {
  aiErrorStyles,
  buttonWrapperStyles,
  errorImageWrapperStyles,
  errorTextStyles,
} from './styles'
import { useLanguage } from 'src/hooks'
import { BUTTON_SIZE, BUTTON_TYPE, Button, icons } from 'src/lib'
import aiErrorImg from 'src/assets/images/ai-error.svg'
import { useDispatch } from 'react-redux'
import { AI_FLOW_STEP, setAiFlowStep } from 'src/store'
export const AiError: React.FC<IAiError> = React.memo(
  ({ className, dataAttr }) => {
    const dispatch = useDispatch()

    const backToFirst = useCallback(() => {
      dispatch(setAiFlowStep(AI_FLOW_STEP.PROMPT))
    }, [])

    const { t } = useLanguage()

    return (
      <div css={aiErrorStyles} className={className} {...dataAttr}>
        <div css={errorImageWrapperStyles}>
          <img src={aiErrorImg} />
        </div>
        <div css={errorTextStyles}>{t('common.informative.ai_error_text')}</div>
        <div css={buttonWrapperStyles}>
          <Button
            text={t('common.informative.back_to_first')}
            type={BUTTON_TYPE.GHOST}
            onClick={backToFirst}
            icon={icons.chevron_left}
            size={BUTTON_SIZE.LARGE}
          />
        </div>
      </div>
    )
  },
)

AiError.displayName = 'AiError'
