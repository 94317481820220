import React, { useCallback, useMemo } from 'react'

import { PropColor } from 'src/pages/deck-page/components/edit-properties/props'
import {
  ComponentTextDataSchema,
  GradientLikeColorSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { IEditPropSection } from '../../types'

export const PropTextFillColor: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()
    const selectedColor = useMemo(
      () =>
        components?.reduce(
          (
            a: GradientLikeColorSchema | undefined,
            c: UpdateComponentSchema,
          ) => {
            const textColor = (c.data as ComponentTextDataSchema).style.color
              .text
            if (a === undefined && textColor) {
              return textColor
            }

            return textColor !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleOnChange = useCallback(
      (newColor: GradientLikeColorSchema) => {
        const partialData: DeepPartial<ComponentTextDataSchema> = {
          style: { color: { text: newColor } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTextDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return <PropColor color={selectedColor} onChange={handleOnChange} />
  },
)

PropTextFillColor.displayName = 'PropTextFillColor'
