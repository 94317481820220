import React from 'react'
import { useLanguage, VALIDATION_RULE_TYPES } from 'src/hooks'
import { BUTTON_THEME, Dialog, DIALOG_THEME } from 'src/lib'
import { wrapperStyles } from './styles'
import { ICancelReasonDialog } from './types'
import { APP_CONFIG } from 'src/config'

export const CancelReasonDialog: React.FC<ICancelReasonDialog> = ({
  onSubmit,
  onClose,
  isLoading,
}) => {
  const { t } = useLanguage()
  const [cancelReason, setCancelReason] = React.useState('')
  return (
    <Dialog
      css={wrapperStyles}
      title={t('subscriptions.cancel_reason_dialog.title')}
      theme={DIALOG_THEME.LIGHT}
      text={[t('subscriptions.cancel_reason_dialog.subtitle')]}
      promptPlaceholder={t(
        'subscriptions.cancel_reason_dialog.input_placeholder',
      )}
      promptValue={cancelReason}
      onPromptChange={setCancelReason}
      submitLabel={t('subscriptions.cancel_reason_dialog.submit')}
      submitTheme={BUTTON_THEME.PRIMARY}
      onSubmit={() => onSubmit(cancelReason)}
      titleClassName="title"
      textClassName="text"
      isLoading={isLoading}
      inputProps={{
        multiline: true,
        clearButton: false,
      }}
      promptValidationRules={{
        [VALIDATION_RULE_TYPES.REQUIRED]: {
          text: t('validation.error.required', {
            name: t('subscriptions.cancel_reason_dialog.input_label'),
          }),
        },
        [VALIDATION_RULE_TYPES.MIN]: {
          value: APP_CONFIG.cancelReasonMinLength,
          text: t('validation.min_char', {
            name: t('subscriptions.cancel_reason_dialog.input_label'),
            value: APP_CONFIG.cancelReasonMinLength,
          }),
        },
      }}
      onCloseClick={onClose}
      footerText={t('subscriptions.cancel_reason_dialog.footer_text')}
    />
  )
}
