import { css } from '@emotion/react'
import { ITheme, fontSize } from 'src/theme'

export const editControlsStyles = ({
  colors,
  borderRadius,
  gap,
  fontWeight,
}: ITheme) => css`
  background: ${colors.primary[10]};
  border-radius: ${borderRadius[2]};
  height: 44px;
  display: flex;
  align-items: center;
  gap: ${gap.xsmall}px;
  padding: ${gap['3xsmall']}px ${gap['2xsmall']}px;

  .slide-control {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.primary.DEFAULT};
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.small};
    gap: ${gap['4xsmall']}px;

    input {
      width: 28px;
      text-align: right;
      background: transparent;
      color: ${colors.primary.DEFAULT};
      font-weight: ${fontWeight.medium};
      font-size: ${fontSize.small};
      border: none;
      outline: none;
    }

    .max-slides {
      width: 28px;
    }
  }
`
