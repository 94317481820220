import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ComponentRadiusTypes } from 'src/types/api/enums'

export const listMediaStyles = ({ colors }: ITheme) => css`
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  position: relative;
  padding: 10px;
  max-width: 100%;

  .list-media-item-inner {
    width: 100%;
    height: 100%;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
  }

  .list-media-caption {
    background: rgba(0, 0, 0, 0.4);
    color: ${colors.white.DEFAULT};
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

interface IIconStyles {
  radiusType?: ComponentRadiusTypes
}
export const iconStyles =
  ({ radiusType }: IIconStyles) =>
  ({ borderRadius, colors }: ITheme) => css`
    width: 2em;
    aspect-ratio: 1;
    padding: 0.4em;

    ${radiusType === ComponentRadiusTypes.OUTLINE &&
    `
			border-radius: 50%;
			
		`}

    ${radiusType === ComponentRadiusTypes.ROUNDED &&
    `
			border-radius: 0.2em;
			background: ${colors.dark2[70]};
		`}

    ${radiusType === ComponentRadiusTypes.CIRCLE &&
    `
			border-radius: ${borderRadius.DEFAULT};
			background: ${colors.dark2[70]};
		`}
  `
