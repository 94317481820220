import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { IHeaderLogo } from './types'
import {
  decktopusLogoStyles,
  decktopusStyles,
  headerLogoStyles,
} from './styles'

import logo from 'src/assets/images/decktopus-logo.svg'
import decktopus from 'src/assets/images/decktopus.svg'

export const HeaderLogo: React.FC<IHeaderLogo> = React.memo(
  ({ className, dataAttr }) => {
    const navigate = useNavigate()
    const handleOnClick = useCallback(() => {
      navigate('/dashboard', { replace: true })
    }, [])
    return (
      <div
        css={headerLogoStyles}
        onClick={handleOnClick}
        className={className}
        {...dataAttr}
      >
        <img src={logo} css={decktopusLogoStyles} />
        <img src={decktopus} css={decktopusStyles} />
      </div>
    )
  },
)

HeaderLogo.displayName = 'HeaderLogo'
