import React, { useCallback, useMemo, useRef, useState } from 'react'
import { ACTION_TYPE, COLLABORATOR_TYPE, ICollaborator } from './types'
import {
  avatarStyles,
  collaboratorPermissionOwnerStyles,
  collaboratorPermissionStyles,
  editStyles,
  iconStyles,
  menuStyles,
  spanStyles,
  wrapperStyles,
} from './style'
import { Button } from '../button/Button'
import { icons } from '../icon/icon-list'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE } from '../button/types'
import { Avatar } from '../avatar'
import { Icon } from '../icon/Icon'
import { useClickOutside, useLanguage } from 'src/hooks'
import { IMenuItem, Menu } from 'src/lib/menu'
import { theme } from 'src/theme'
import { DeckInvitePermissions, DeckPermissions } from 'src/types/api/enums'

export const Collaborator: React.FC<ICollaborator> = React.memo(
  ({
    email,
    collaboratorType,
    onAction,
    invitePermission,
    deckPermission,
    label,
    imageUrl,
    disabled,
    isDeckOwner,
  }) => {
    const { t } = useLanguage()

    const clickOutsidehandler = () => {
      setMenuButtonState(false)
    }

    const menuRef = useRef(null)

    useClickOutside(menuRef, clickOutsidehandler)

    const [menuButtonState, setMenuButtonState] = useState(false)

    const isRW =
      invitePermission === DeckInvitePermissions.READWRITE ||
      deckPermission === DeckPermissions.READWRITE

    const items = useMemo<IMenuItem[]>(
      () => [
        {
          label: t('share_modal.can_edit'),
          rightIcon: isRW ? icons.done : undefined,
          onClick: (e) => {
            e.preventDefault()

            onAction?.(email, ACTION_TYPE.PERMISSION_RW)
          },
        },
        {
          label: t('share_modal.can_view'),
          rightIcon: isRW ? undefined : icons.done,
          onClick: (e) => {
            e.preventDefault()

            onAction?.(email, ACTION_TYPE.PERMISSION_R)
          },
        },
        ...(collaboratorType !== COLLABORATOR_TYPE.BIG
          ? [
              {
                label: t('common.actions.remove'),
                color: theme.colors.error.DEFAULT,
                onClick: (e) => {
                  e.preventDefault()

                  onAction?.(email, ACTION_TYPE.REMOVE)
                },
              } as IMenuItem,
            ]
          : []),
      ],
      [email, collaboratorType, onAction],
    )

    const onClickHandler = useCallback(() => {
      if (disabled) return
      setMenuButtonState(!menuButtonState)
    }, [disabled, menuButtonState])

    return (
      <div css={wrapperStyles({ collaboratorType })}>
        {collaboratorType == COLLABORATOR_TYPE.BIG && (
          <div css={avatarStyles}>
            <Avatar
              size={24}
              data={[
                {
                  image: imageUrl,
                  name: email,
                },
              ]}
            />
          </div>
        )}
        <span css={spanStyles} onClick={onClickHandler}>
          {(label ? `(${label}) ` : '') + email}
        </span>
        {collaboratorType == COLLABORATOR_TYPE.BIG && (
          <>
            <div css={editStyles} onClick={onClickHandler} ref={menuRef}>
              {isDeckOwner ? (
                <span css={collaboratorPermissionOwnerStyles}>
                  {t('common.roles.owner')}
                </span>
              ) : (
                <span css={collaboratorPermissionStyles}>
                  {isRW ? t('share_modal.can_edit') : t('share_modal.can_view')}
                </span>
              )}
              {!isDeckOwner && (
                <Icon size={16} icon={icons.chevron_down} css={iconStyles} />
              )}
              {!isDeckOwner && menuButtonState && (
                <Menu items={items} css={menuStyles({ collaboratorType })} />
              )}
            </div>
            {!isDeckOwner && (
              <Button
                icon={icons.trash_can}
                type={BUTTON_TYPE.GHOST}
                theme={BUTTON_THEME.RED}
                size={BUTTON_SIZE.XSMALL}
                disabled={disabled}
                onClick={() => {
                  if (!disabled) {
                    onAction?.(email, ACTION_TYPE.REMOVE)
                  }
                }}
              />
            )}
          </>
        )}
        {collaboratorType == COLLABORATOR_TYPE.SMALL && (
          <div ref={menuRef} onClick={onClickHandler}>
            <Button
              icon={icons.chevron_down}
              type={BUTTON_TYPE.GHOST}
              theme={BUTTON_THEME.GRAY}
              size={BUTTON_SIZE.XSMALL}
              disabled={disabled}
            />
            {menuButtonState && (
              <Menu items={items} css={menuStyles({ collaboratorType })} />
            )}
          </div>
        )}
      </div>
    )
  },
)
