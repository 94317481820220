import {
  ComponentMediaZoomTypes,
  ComponentRadiusTypes,
  ComponentTypes,
  MediaTypes,
} from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import gifBg from 'src/assets/images/element-display-gif.png'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentMediaDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const gifDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.gif.display_name', { lng }),
      styles: {},
      image: gifBg,
      icon: icons.gif,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.MEDIA,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 400,
          height: 400,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          mediaType: MediaTypes.GIF,
          mediaUrl: 'https://cdn.decktopus.com/images/defaultGif.gif',
          style: {
            color: {
              text: { colors: ['#000000'] },
            },
            radiusType: ComponentRadiusTypes.FLAT,
          },
          caption: null,
          zoom: {
            x: '0',
            y: '0',
            level: 1,
            type: ComponentMediaZoomTypes.FILL,
          },
        } as ComponentMediaDataSchema,
      },
    ],
  }
}
