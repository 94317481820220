import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const quillButtonStyles = ({ colors }: ITheme) => css`
  position: relative;
  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
  }

  button.ql-active + svg {
    path {
      fill: ${colors.primary.DEFAULT};
    }
  }
`
