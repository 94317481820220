import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { IPolotnoComponent } from '../../types'
import { useDispatch } from 'react-redux'
import { setDeletedSlides } from 'src/store/decks-v3'

const DeletePageAction = observer<IPolotnoComponent>(({ store }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    store.activePage.custom?.internalId &&
      dispatch(setDeletedSlides([store.activePage.custom?.internalId]))
    store.deletePages([store.activePage.id])
  }

  return (
    <BaseIconButton
      onClick={handleClick}
      sx={{
        padding: '6px',
      }}
      tooltip="v3.delete_page"
    >
      <MaterialSymbol icon="delete" size={20} weight={400} />
    </BaseIconButton>
  )
})

export default DeletePageAction
