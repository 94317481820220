export const planFeatures = {
  'plan_features.pro.monthly.title': 'Créditos de IA 750 / mes',
  'plan_features.pro.monthly.1': 'Exportación PDF / PPT',
  'plan_features.pro.monthly.2': 'Análisis de presentaciones',
  'plan_features.pro.monthly.3': 'Enlace para compartir',
  'plan_features.pro.monthly.4':
    'Notificaciones por correo de respuestas de formularios',
  'plan_features.pro.monthly.5': 'Notas de diapositivas y del presentador',
  'plan_features.pro.monthly.6':
    'Generación de consejos y preguntas y respuestas',
  'plan_features.pro.annual.title': 'Créditos de IA 750 / mes',
  'plan_features.pro.annual.1': 'Exportación PDF / PPT',
  'plan_features.pro.annual.2': 'Análisis de presentaciones',
  'plan_features.pro.annual.3': 'Enlace para compartir',
  'plan_features.pro.annual.4':
    'Notificaciones por correo de respuestas de formularios',
  'plan_features.pro.annual.5': 'Notas de diapositivas y del presentador',
  'plan_features.pro.annual.6':
    'Generación de consejos y preguntas y respuestas',
  'plan_features.business.monthly.title': 'Créditos de IA 1000 / mes',
  'plan_features.business.monthly.1': 'Todas las funciones de IA PRO +',
  'plan_features.business.monthly.2': 'Funciones de marca',
  'plan_features.business.monthly.3': 'Respuesta de formulario avanzada',
  'plan_features.business.monthly.4': 'Webhook',
  'plan_features.business.monthly.5': 'Conexión de dominio personalizado',
  'plan_features.business.monthly.6': 'Equipo y organización',
  'plan_features.business.annual.title': 'Créditos de IA 1000 / mes',
  'plan_features.business.annual.1': 'Todas las funciones de IA Pro +',
  'plan_features.business.annual.2': 'Funciones de marca',
  'plan_features.business.annual.3': 'Respuesta de formulario avanzada',
  'plan_features.business.annual.4': 'Webhook',
  'plan_features.business.annual.5': 'Conexión de dominio personalizado',
  'plan_features.business.annual.6': 'Equipos y organización',
}
