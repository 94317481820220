import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editSlideStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  padding: ${gap.xlarge}px;
`

interface IDecorItem {
  selected?: boolean
}

export const decorItem =
  ({ selected }: IDecorItem) =>
  ({ colors }: ITheme) => css`
    width: 100%;
    border: 1px solid #000;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    aspect-ratio: 16 / 9;
    ${selected &&
    `
        outline: 2px solid ${colors.primary.DEFAULT};
    `}
  `
