import React from 'react'
import { Helmet } from 'react-helmet-async'

export const NewRelic: React.FC = () => {
  return (
    <Helmet>
      <script src="./nr.js"></script>
    </Helmet>
  )
}
