import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const listEditorStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  overflow: auto;

  .ql-container.ql-snow {
    border: none;
  }
  .ql-editor {
    padding: 0;
    font-size: ${theme.fontSize.small} @media
      (min-width: ${theme.breakpointsV2.tablet}px) {
      font-size: ${theme.fontSize.base};
    }
    @media (min-width: ${theme.breakpointsV2.desktop}px) {
      font-size: ${theme.fontSize.medium};
    }
  }
  .ql-editor ul {
    padding-left: 0;
  }

  .ql-editor ul li {
    margin-bottom: ${theme.gap.small}px;

    @media (min-width: ${theme.breakpointsV2.desktop}px) {
      margin-bottom: ${theme.gap.xsmall}px;
    }
  }
`
