import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const lottieWrapperStyle = ({ dimensions, borderRadius }: ITheme) => css`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: ${dimensions.buttonHeight.small}px;
  cursor: pointer;
  border-radius: ${borderRadius[4]};
`

export const buttonTextStyle = ({
  zIndex,
  colors,
  fontSize,
  fontWeight,
}: ITheme) => css`
  z-index: ${zIndex.modal};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${colors.white.DEFAULT};
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
`
