import hash from 'object-hash'
import {
  UpdateComponentSchema,
  putUpdateDeckDataBody,
} from 'src/types/api/requestObjects'
import { looseObject } from 'src/types'

/**
 * flattenObjectArray is to flat object array that holds the same type
 * of structure recursively in any of it's field.
 *
 * eg: itemList[0].subList[0].subList[0]...
 */
interface IFlattenObjectArray<T> {
  array: T[]
  key: keyof T
}

export const flattenObjectArray = <T>({
  array,
  key,
}: IFlattenObjectArray<T>): T[] => {
  return array?.reduce((a: T[], c: T) => {
    if (c[key]) {
      return [
        ...a,
        c,
        ...(flattenObjectArray<T>({
          array: c[key] as T[],
          key,
        }) || []),
      ]
    }
    return [...a, c]
  }, [])
}

export const deepMerge = <T extends looseObject, U extends looseObject>(
  obj1: T,
  obj2: U,
): T & U => {
  if (typeof obj1 !== 'object' || obj1 === null || Array.isArray(obj1)) {
    return obj2 as T & U
  }

  const merged: any = { ...obj1 }

  const keys = Object.keys(obj2)
  for (const key of keys) {
    if (
      typeof obj2[key] === 'object' &&
      obj2[key] !== null &&
      !Array.isArray(obj2[key])
    ) {
      if (
        obj1[key] &&
        typeof obj1[key] === 'object' &&
        !Array.isArray(obj1[key])
      ) {
        merged[key] = deepMerge(obj1[key] as any, obj2[key] as any)
      } else {
        merged[key] = { ...obj2[key] }
      }
    } else {
      merged[key] = obj2[key]
    }
  }

  return merged as T & U
}

type StrictComponentType = NonNullable<
  NonNullable<putUpdateDeckDataBody['deckData']['slides']>[0]['components']
>

type ISlideType = NonNullable<putUpdateDeckDataBody['deckData']['slides']>[0]

export interface IData extends Omit<ISlideType, 'components'> {
  components: StrictComponentType
}

export const mergeSaveLog = (
  data: IData[],
): putUpdateDeckDataBody['deckData']['slides'] => {
  return data
    .reduce((a: IData[], c: IData) => {
      if (!c) {
        return a
      }

      const currentValue = [...a]
      let slideIndex = currentValue.findIndex(
        ({ id, tempId }) =>
          (id && id === c.id) || (tempId && tempId === c.tempId),
      )

      if (slideIndex === -1) {
        // create new slide field
        currentValue.push({ ...c, components: [...(c.components || [])] })
        slideIndex = currentValue.length - 1
      }

      // update slide swapColor
      if (c.isSwapColor !== undefined) {
        currentValue[slideIndex].isSwapColor = c.isSwapColor
      }

      // update slide svgType
      if (c.svgType !== undefined) {
        currentValue[slideIndex].svgType = c.svgType
      }

      // update slide background
      if (c.background !== undefined) {
        currentValue[slideIndex].background = c.background
      }

      // update slide orderIndex
      if (c.orderIndex !== undefined) {
        currentValue[slideIndex].orderIndex = c.orderIndex
      }
      // update slide isDeleted
      if (c.isDeleted !== undefined) {
        currentValue[slideIndex].isDeleted = c.isDeleted
      }

      // update slide orderIndex
      if (c.tempSvg !== undefined) {
        currentValue[slideIndex].tempSvg = {
          ...currentValue[slideIndex].tempSvg,
          ...c.tempSvg,
        }
      }

      // update slide components
      if (c.components?.length) {
        c.components.forEach((component) => {
          const currentComponents = [...currentValue[slideIndex].components]
          const cIndex = component
            ? currentComponents.findIndex((currentComponent) =>
                (currentComponent as UpdateComponentSchema).id
                  ? (currentComponent as UpdateComponentSchema).id ===
                    (component as UpdateComponentSchema)?.id
                  : currentComponent.tempId === component?.tempId,
              )
            : -1

          if (cIndex === -1) {
            currentValue[slideIndex].components.push(component)
          } else {
            currentValue[slideIndex].components[cIndex] = component
          }
        })
      }

      // return generated data
      return currentValue
    }, [])
    .map((slide) =>
      slide.isDeleted
        ? {
            id: slide.id,
            isDeleted: slide.isDeleted,
            orderIndex: slide.orderIndex,
          }
        : slide,
    )
}

export const objectHash = (obj: looseObject): string => {
  return hash(obj)
}
