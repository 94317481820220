import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${theme.spacingV2.xlarge};

  .title {
    font-size: ${theme.fontSize.medium};
    font-weight: ${theme.fontWeight.medium};
  }

  .subtitle {
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.medium};
    margin-bottom: ${theme.spacingV2.small};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .plan-badge {
    margin-bottom: ${theme.spacingV2.small};
  }

  .subtext {
    font-size: ${theme.fontSize.xsmall};
    font-weight: ${theme.fontWeight.medium};
    color: ${theme.colors.text[2]};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
