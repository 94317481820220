import React from 'react'
import { Group, Rect } from 'react-konva'

import { Html } from 'react-konva-utils'
import { IHtmlWrapperInnerProps, IHtmlWrapperProps } from './types'

const Inner: React.FC<IHtmlWrapperInnerProps> = ({
  htmlZIndex,
  isPresenting,
  children,
}) => {
  const iframeRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (!isPresenting) {
      const child = iframeRef.current?.parentElement
      const parent = child?.parentElement
      if (!child || !parent) return

      parent.removeChild(child)
      // Get the reference element (the element before which the new element will be inserted)
      const referenceElement = parent?.children[htmlZIndex]

      // Insert the new element before the reference element
      parent.insertBefore(child, referenceElement)
    }
  }, [htmlZIndex, isPresenting])

  return <div ref={iframeRef}>{children}</div>
}

export const HtmlWrapper = ({
  element,
  isPresenting,
  children,
  customTransform,
}: IHtmlWrapperProps) => {
  const handleChange = (e: any) => {
    const node = e.currentTarget
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()
    // Konva.Transformer is changing scale by default
    // we don't need that, so we reset it back to 1.
    node.scaleX(1)
    node.scaleY(1)
    // and then save all changes back to the model
    element.set({
      x: node.x(),
      y: node.y(),
      rotation: e.target.rotation(),
      width: element.width * scaleX,
      height: element.height * scaleY,
      ...customTransform?.(scaleX, scaleY),
    })
  }

  return (
    <Group
      // remember to use "element" name. Polotno will use it internally to find correct node
      name="element"
      // also it is important to pass id
      // so polotno can automatically do selection
      id={element.id}
      x={element.x}
      y={element.y}
      rotation={element.rotation}
      opacity={element.opacity}
      draggable={!element.locked}
      onDragMove={handleChange}
      onTransform={handleChange}
      width={element.width}
      height={element.height}
      visible={!element.page._exporting}
    >
      <Rect width={element.width} height={element.height} fill="white" />

      <Rect
        x={0}
        y={0}
        width={element.width}
        height={element.height}
        fill="white"
        globalCompositeOperation="destination-out"
        preventDefault={false}
        listening={false}
      />
      <Html
        divProps={{
          style: {
            zIndex: 0,
            height: element.height,
            width: element.width,
            backgroundColor: 'white',
          },
        }}
      >
        <Inner htmlZIndex={0} isPresenting={isPresenting}>
          {children}
        </Inner>
      </Html>
    </Group>
  )
}
