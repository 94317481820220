import { ICustomerStripePortal } from 'src/types/api/responseObjects'
import { SubscriptionSettings } from '../types'

export const useStripePortalUrls = () => {
  const getStripePortalUrl = (
    urls: ICustomerStripePortal,
    setting: SubscriptionSettings,
  ) => {
    switch (setting) {
      case SubscriptionSettings.PAYMENT_METHOD:
        return urls.changePaymentMethodUrl
      case SubscriptionSettings.UPDATE_BILLING:
        return urls.updateBillingInfoUrl
      case SubscriptionSettings.CANCEL:
        return urls.cancelSubscriptionUrl
      case SubscriptionSettings.RENEW:
        return urls.renewSubscriptionUrl
      default:
        return urls.portalUrl
    }
  }
  return { getStripePortalUrl }
}
