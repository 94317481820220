import React, { useCallback, useMemo } from 'react'

import { SWITCH_SIZE, Switch } from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  ComponentChartDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { IEditPropSection } from '../../types'

export const GridLines: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()

    const selectedShowGrids = useMemo(
      () =>
        components?.reduce(
          (a: boolean | undefined, c: UpdateComponentSchema) => {
            const showGrids = (c.data as ComponentChartDataSchema).options
              .showGrids
            if (a === undefined && showGrids) {
              return showGrids
            }

            return showGrids !== a ? false : a
          },
          undefined,
        ),
      [components],
    )

    const handleClick = useCallback(
      (value?: boolean) => {
        const partialData: DeepPartial<ComponentChartDataSchema> = {
          options: { showGrids: value },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentChartDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <Switch
        size={SWITCH_SIZE.SMALL}
        selected={selectedShowGrids}
        onChange={handleClick}
      />
    )
  },
)

GridLines.displayName = 'GridLines'
