export enum AUTH_PAGE_LAYOUT {
  LOGIN = 'login',
  SIGN_UP = 'sign-up',
  RESET = 'reset',
}

export interface IAuthPageLayout {
  children: React.ReactNode
  type?: AUTH_PAGE_LAYOUT
}
