import { COLOR } from './types'

export const dark = {
  DEFAULT: COLOR.dark_100,
  90: COLOR.dark_90,
  80: COLOR.dark_80,
  70: COLOR.dark_70,
  60: COLOR.dark_60,
  50: COLOR.dark_50,
  40: COLOR.dark_40,
  30: COLOR.dark_30,
  20: COLOR.dark_20,
  10: COLOR.dark_10,
}
