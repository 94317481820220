import React from 'react'

import { IQuillToolbar } from './types'
import { QuillButton } from 'src/lib/quill-button'
import { icons } from 'src/lib'
import { quillToolbarStyles } from './styles'

export const QuillToolbar: React.FC<IQuillToolbar> = React.memo(() => {
  return (
    <div css={quillToolbarStyles}>
      <QuillButton quillHandler="ql-bold" icon={icons.bold} />
      <QuillButton quillHandler="ql-italic" icon={icons.italic} />
      <QuillButton quillHandler="ql-underline" icon={icons.underlined} />
      <QuillButton quillHandler="ql-link" icon={icons.hyperlink} />
      <QuillButton
        quillHandler="ql-list"
        quillValue="ordered"
        icon={icons.numbered_list}
      />
      <QuillButton
        quillHandler="ql-list"
        quillValue="bullet"
        icon={icons.list}
      />
    </div>
  )
})

QuillToolbar.displayName = 'QuillToolbar'
