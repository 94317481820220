import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const analyticsStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  flex-flow: column;
  position: relative;
  width: 336px;
  height: 479px;
  margin: auto;
  align-self: center;
  border-radius: ${theme.borderRadius[10]};
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: 624px;
    height: 414px;
  }
`
export const headerStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  font-weight: ${theme.fontWeight.medium};
  width: 100%;
  height: 43px;
  padding: ${theme.gap.small}px;
  font-size: ${theme.fontSize.small};
  box-shadow: ${theme.boxShadow[1]};
  border-radius: ${theme.borderRadius[10]} ${theme.borderRadius[10]} 0 0;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    height: 70px;
    padding: ${theme.gap.xlarge}px;
    font-size: ${theme.fontSize.base};
  }
`
export const closeWrapperStyles = (theme: ITheme) => css`
  height: 16px;
  width: 16px;
  position: absolute;
  top: ${theme.gap.medium}px;
  right: ${theme.gap.medium}px;
`

export const bodyStyles = (theme: ITheme) => css`
  background: ${theme.colors.background.DEFAULT};
  width: 100%;
  display: flex;
  gap: ${theme.gap.small}px;
  border-radius: 0 0 ${theme.borderRadius[10]} ${theme.borderRadius[10]};
  height: 436px;
  padding: ${theme.gap.small}px;
  flex-flow: column;

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    padding: ${theme.gap.xlarge}px;
    height: 344px;
    flex-flow: row;
  }
`
export const chartWrapperStyles = (noChart?: boolean) => (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  flex-flow: column;
  border-radius: ${theme.borderRadius[8]};
  width: 312px;
  height: 258px;
  padding: ${theme.gap.small}px ${theme.gap.small}px ${theme.gap.xlarge}px
    ${theme.gap.small}px;
  gap: ${theme.gap.medium}px;
  box-shadow: ${theme.boxShadow[1]};
  ${noChart &&
  'justify-content: center; align-items: center; text-align: center;'}

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: 380px;
    height: 296px;
    padding: ${theme.gap.small}px ${theme.gap.xlarge}px;
    gap: ${theme.gap.xlarge}px;
  }
`
export const chartStyles = (noChart?: boolean) => (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  height: 160px;
  ${noChart && 'display: none;'}
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    height: 198px;
  }
`
export const chartTitleWrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  height: 38px;
  display: flex;
  flex-flow: column;
  gap: ${theme.gap['3xsmall']}px;
`

export const chartTitleStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  height: 19px;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
`
export const chartSubtitleStyles =
  (noChart?: boolean) => (theme: ITheme) => css`
    background: ${theme.colors.white.DEFAULT};
    width: 100%;
    height: 15px;
    font-size: ${theme.fontSize.xsmall};
    color: ${theme.colors.text[2]};
    ${noChart && 'display: none;'}
  `
export const infoStyles = (theme: ITheme) => css`
  text-align: center;
  display: flex;
  gap: ${theme.gap.small}px;
  white-space: nowrap;
  flex-flow: row;
  width: 312px;
  height: 142px;

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: 184px;
    height: 296px;
    flex-flow: column;
  }
`
export const lastTimeViewStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 150px;
  height: 142px;
  border-radius: ${theme.borderRadius[8]};
  box-shadow: ${theme.boxShadow[1]};
  padding-top: ${theme.gap.xsmall}px;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: 184px;
    height: 142px;
  }
`
export const lastTimeViewTitleStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  margin: ${theme.gap.xlarge}px 0 4px 0;
  height: 19px;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
`
export const lastTimeViewDescriptionStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  height: 15px;
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.colors.text[2]};
`
export const deckViewStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 150px;
  height: 142px;
  padding: ${theme.gap.xsmall}px ${theme.gap['2xlarge']}px;
  border-radius: ${theme.borderRadius[8]};
  box-shadow: ${theme.boxShadow[1]};
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: 184px;
    height: 142px;
    padding: ${theme.gap.xsmall}px ${theme.gap['5xlarge']}px;
  }
`
export const deckViewTitleStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  margin: ${theme.gap.xlarge}px 0 ${theme.gap['3xsmall']}px 0;
  height: 19px;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
`
export const deckViewDescriptionStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  height: 15px;
  font-size: ${theme.fontSize.xsmall};
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.colors.text[2]};
`
export const noViewsStyles = (theme: ITheme) => css`
  display: flex;
  flex-flow: column;
  gap: ${theme.gap['3xsmall']}px;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: -42px;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    margin-top: -70px;
  }
`
export const eyeStyles = () => css`
  width: 64px;
  height: 64px;
`
export const noViewsDescription = () => css`
  margin: 8px 0 -5px 0;
`
