import Button from '@mui/material/Button'
import { observer } from 'mobx-react-lite'
import { IPolotnoComponent } from '../../types'
import { useLanguage } from 'src/hooks'

const SizePanelTriggerAction = observer<IPolotnoComponent>(({ store }) => {
  const { t } = useLanguage()
  const handleClick = () => {
    if (store.openedSidePanel === 'size-panel') {
      store.openSidePanel('')
      return
    }
    store.openSidePanel('size-panel')
  }

  return (
    <Button
      size="small"
      variant="text"
      disableElevation
      onClick={handleClick}
      sx={{
        flexShrink: 0,
      }}
    >
      {t('v3.toolbar.size_and_position')}
    </Button>
  )
})

export default SizePanelTriggerAction
