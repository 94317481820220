import React, { useCallback, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { IFormQuestion } from './types'
import { icons } from 'src/lib'
import { PropButton, PropHeader } from '../../props'
import {
  ComponentFormDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'

export const FormQuestionRate: React.FC<IFormQuestion> = React.memo(
  ({ question, components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedQuestions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['questions'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentFormDataSchema).questions

            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const changeIcon = useCallback(
      (rateIcon: string) => {
        const newQuestionsData: ComponentFormDataSchema['questions'] =
          JSON.parse(JSON.stringify(selectedQuestions))

        const targetQuestion = newQuestionsData.findIndex(
          ({ questionId }) => questionId === question.questionId,
        )

        newQuestionsData[targetQuestion].rate!.icon = rateIcon

        const partialData: DeepPartial<ComponentFormDataSchema> = {
          questions: newQuestionsData,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedQuestions, question],
    )

    const changeRateRange = useCallback(
      (
        rateRange: NonNullable<
          ComponentFormDataSchema['questions'][0]['rate']
        >['range'],
      ) => {
        const newQuestionsData: ComponentFormDataSchema['questions'] =
          JSON.parse(JSON.stringify(selectedQuestions))

        const targetQuestion = newQuestionsData.findIndex(
          ({ questionId }) => questionId === question.questionId,
        )

        newQuestionsData[targetQuestion].rate!.range = rateRange

        const partialData: DeepPartial<ComponentFormDataSchema> = {
          questions: newQuestionsData,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedQuestions, question],
    )

    return (
      <>
        <PropHeader
          text={t('edit.properties.form_question_rate.choose_icon')}
        />
        <div className="grid">
          <div className="c-1">
            <PropButton
              icon={icons.like}
              onClick={() => changeIcon('like')}
              isFocus={question.rate?.icon === 'like'}
            />
          </div>
          <div className="c-1">
            <PropButton
              icon={icons.user}
              onClick={() => changeIcon('user')}
              isFocus={question.rate?.icon === 'user'}
            />
          </div>
          <div className="c-1">
            <PropButton
              icon={icons.star}
              onClick={() => changeIcon('star')}
              isFocus={question.rate?.icon === 'star'}
            />
          </div>
          <div className="c-1">
            <PropButton
              icon={icons.heart}
              onClick={() => changeIcon('heart')}
              isFocus={question.rate?.icon === 'heart'}
            />
          </div>
          <div className="c-1">
            <PropButton
              icon={icons.happy}
              onClick={() => changeIcon('happy')}
              isFocus={question.rate?.icon === 'happy'}
            />
          </div>
        </div>
        <PropHeader
          text={t('edit.properties.form_question_rate.choose_icon')}
        />
        <div className="grid">
          <div className="c-1">
            <PropButton
              text="5"
              onClick={() => changeRateRange(5)}
              isFocus={question.rate?.range === 5}
            />
          </div>
          <div className="c-1">
            <PropButton
              text="10"
              onClick={() => changeRateRange(10)}
              isFocus={question.rate?.range === 10}
            />
          </div>
        </div>
      </>
    )
  },
)

FormQuestionRate.displayName = 'FormQuestionRate'
