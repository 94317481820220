import React, { useCallback, useMemo, useRef } from 'react'

import {
  bulletItemStyles,
  detailStyles,
  footerStyles,
  listItemStyles,
} from './styles'
import { IListItem } from './types'
import { Icon, icons } from 'src/lib'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { useTheme } from 'src/theme'
import { CSSTransition } from 'react-transition-group'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { ListItemImageTypes, ListTypes } from 'src/types/api/enums'
import { ListMedia } from './ListMedia'
import { PROP_QUILL_TOOLBAR_SIDE, PropQuill } from '../../props/prop-quill'

export const ListItem: React.FC<IListItem> = React.memo(
  ({
    data,
    type,
    onClick,
    index,
    listItems,
    components,
    isGrid,
    isExpanded,
  }) => {
    const detailRef = useRef(null)
    const { colors } = useTheme()
    const dispatch = useDispatch()

    const onListTitleChange = useCallback(
      (value?: string) => {
        const newListItems = JSON.parse(JSON.stringify(listItems))
        newListItems[index].title = value
        const partialData: DeepPartial<ComponentListDataSchema> = {
          listItems: newListItems,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, listItems],
    )

    const onListBodyChange = useCallback(
      (value?: string) => {
        const newListItems = JSON.parse(JSON.stringify(listItems))
        newListItems[index].body = value || ''
        const partialData: DeepPartial<ComponentListDataSchema> = {
          listItems: newListItems,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, listItems],
    )
    const onDeleteItem = useCallback(
      (e: React.MouseEvent<HTMLOrSVGElement>) => {
        e.stopPropagation()
        if (listItems.length <= 1) {
          return
        }
        const newListItems = listItems.filter((_, i) => i !== index)
        const partialData: DeepPartial<ComponentListDataSchema> = {
          listItems: newListItems,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
        onClick()
      },
      [components, listItems, index],
    )

    const isLogo = useMemo(
      () => data?.image?.type === ListItemImageTypes.LOGO,
      [data],
    )
    if (!data) {
      return null
    }
    return (
      <div css={listItemStyles({ isExpanded })} onClick={() => onClick(index)}>
        <CSSTransition nodeRef={detailRef} in={isExpanded} timeout={200}>
          <div
            css={detailStyles({ isExpanded, type, isLogo, isGrid })}
            ref={detailRef}
          >
            {type === ListTypes.MEDIA && (
              <ListMedia
                data={data}
                components={components}
                index={index}
                listItems={listItems}
                isGrid={isGrid}
              />
            )}
            {!isLogo && (
              <>
                <PropQuill
                  toolbarSide={PROP_QUILL_TOOLBAR_SIDE.BOTTOM}
                  background={colors.dark2.DEFAULT}
                  id={`data-title-${data?.id}`}
                  maxHeight="126px"
                  value={data.title}
                  onChange={onListTitleChange}
                  contentCheck={true}
                />
                {!isGrid && (
                  <PropQuill
                    background={colors.dark2.DEFAULT}
                    id={`list-body-${data.id}`}
                    height="100px"
                    value={data.body}
                    onChange={onListBodyChange}
                    contentCheck={true}
                  />
                )}
              </>
            )}
          </div>
        </CSSTransition>
        <div css={footerStyles}>
          <div css={bulletItemStyles}>
            {isLogo ? (
              `Logo ${index + 1}`
            ) : !isExpanded ? (
              <span dangerouslySetInnerHTML={{ __html: data.title || '' }} />
            ) : (
              ''
            )}
          </div>
          {listItems.length > 1 && (
            <Icon
              icon={icons.trash_can}
              color={colors.error.DEFAULT}
              size={20}
              onClick={onDeleteItem}
            />
          )}
        </div>
      </div>
    )
  },
)

ListItem.displayName = 'ListItem'
