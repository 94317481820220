import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState, setQA, setTips } from 'src/store'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import {
  postAiTextEditResponse,
  postCreateAIImageResponse,
  postCreateQaResponse,
  postCreateTipsResponse,
} from 'src/types/api/responseObjects'
import {
  postAiTextEditBody,
  postCreateAIImageBody,
  postCreateQaBody,
  postCreateTipsBody,
  putUpdateDeckPromptWithThemeBody,
} from 'src/types/api/requestObjects'
import { AxiosError } from 'axios'

export const useAiApi = () => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { deckId, workspaceId } = useSelector(
    ({ edit, workspace }: RootState) => ({
      deckId: edit.activeDeck.data?.deck?.id,
      slideData: edit.activeSlideData.data,
      slideDataId: edit.activeSlideDataID,
      workspaceId: workspace.id,
    }),
  )

  const generateAiImage = useCallback(
    async (prompts: postCreateAIImageBody['prompts']) => {
      setIsLoading(true)
      try {
        const res: RequestResponse<postCreateAIImageResponse, any> =
          await RequestServices.callApi({
            method: 'POST',
            url: API_CONFIG.AI_IMAGE,
            data: { deckId, organizationId: workspaceId, prompts },
          })

        return res.data.data.imageUrl
      } finally {
        setIsLoading(false)
      }
    },
    [deckId, workspaceId],
  )

  const generateTips = useCallback(async (params: postCreateTipsBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postCreateTipsResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/tips`,
          data: {
            deckId: params.deckId,
            organizationId: params.organizationId,
          },
        })
      dispatch(setTips(res.data.data.deckTipGroups))
      return res.data.data.deckTipGroups
    } finally {
      setIsLoading(false)
    }
  }, [])

  const generateQA = useCallback(async (params: postCreateQaBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postCreateQaResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.AI}/qa`,
          data: {
            deckId: params.deckId,
            organizationId: params.organizationId,
          },
        })
      dispatch(setQA(res.data.data.deckQAs))
      return res.data.data.deckQAs
    } finally {
      setIsLoading(false)
    }
  }, [])

  const editText = useCallback(
    async ({
      text,
      textEditType,
      instruction,
    }: {
      text: postAiTextEditBody['text']
      textEditType: postAiTextEditBody['textEditType']
      instruction?: postAiTextEditBody['instruction']
    }) => {
      setIsLoading(true)
      try {
        const res: RequestResponse<postAiTextEditResponse, any> =
          await RequestServices.callApi({
            method: 'POST',
            url: `${API_CONFIG.AI_EDIT_TEXT}`,
            data: {
              text,
              textEditType,
              organizationId: workspaceId,
              ...(instruction?.length && { instruction }),
            },
          })
        return res.data.data.editedText
      } catch (error) {
        return ((error as AxiosError)?.response?.data as any)?.message as string
      } finally {
        setIsLoading(false)
      }
    },
    [workspaceId],
  )

  const updateDeckPrompt = useCallback(
    async (params: putUpdateDeckPromptWithThemeBody) => {
      setIsLoading(true)
      try {
        await RequestServices.callApi({
          method: 'PUT',
          url: `${API_CONFIG.AI_UPDATE_DECK_PROMPT}`,
          data: {
            ...params,
          },
        })

        return true
      } catch (error) {
        return ((error as AxiosError)?.response?.data as any)?.message as string
      } finally {
        setIsLoading(false)
      }
    },
    [],
  )

  return {
    generateAiImage,
    generateTips,
    generateQA,
    editText,
    updateDeckPrompt,
    isLoading,
  }
}
