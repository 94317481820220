import { dataAttrType } from 'src/types'
import React from 'react'

export enum FOLDER_TYPE {
  CREATE = 'create',
  DEFAULT = 'default',
}
export interface IFolderItem {
  text: string
  isActive?: boolean
  folderID?: number
  type?: FOLDER_TYPE
  onClick?: () => void
  isMenuOpen?: boolean
  onMenuChange?: (state: boolean) => void
  menu?: React.ReactElement
  className?: string
  dataAttr?: dataAttrType
}
