import { makeMuiStyles } from 'src/theme/muiTheme'

export const useStyles = makeMuiStyles()((theme) => ({
  searchField: {
    input: {
      fontSize: '13px',
      padding: '9px 4px',
    },
  },
  card: {
    borderRadius: theme.spacing(2),
    width: '270px',
    height: '175px',
    backgroundColor: theme.palette.blueGrey[50],
    [theme.breakpoints.down('xs')]: {
      maxWidth: '170px',
      maxHeight: '124px',
    },
    cursor: 'pointer',
  },
  header: {
    '& .MuiFormControl-root': {
      marginTop: 0,
    },
    'padding': theme.spacing(2),
  },
  drawer: {
    height: '95vh',
    width: '100%',
  },
  tab: {
    height: '38px',
    minHeight: '38px',
    justifyContent: 'center',
  },
  cardMedia: {
    backgroundSize: 'contain',
    maxHeight: '73%',
    height: '100%',
    margin: theme.spacing(2, 2, 0),
    [theme.breakpoints.down('xs')]: {
      maxHeight: '67%',
    },
  },
  cardContent: {
    alignItems: 'center',
    padding: `${theme.spacing(2, 1, 3)} !important`,
  },
  title: {
    textAlign: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))
