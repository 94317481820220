import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { setBaseTheme } from 'src/store/decks-v3'
import {
  getDeckV3ThemeDetailsResponse,
  getDeckV3ThemeLayoutResponse,
} from 'src/types/api/responseObjects'

interface IUseDeckV3ThemeApiReturn {
  getThemeDetails: (
    themeId: string,
  ) => Promise<getDeckV3ThemeDetailsResponse['data'] | undefined>
  getThemeLayout: (
    themeLayoutId: string,
  ) => Promise<getDeckV3ThemeLayoutResponse['data'] | undefined>
  isLoading: boolean
}

export const useDecksThemeV3Api = (): IUseDeckV3ThemeApiReturn => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getThemeDetails = useCallback(
    async (themeId: string) => {
      setIsLoading(true)
      try {
        const res: RequestResponse<getDeckV3ThemeDetailsResponse, any> =
          await RequestServices.callApi({
            method: 'GET',
            url: API_CONFIG.DECK_V3_THEME_DETAILS(themeId),
          })
        dispatch(setBaseTheme(res.data.data))
        return res.data.data
      } catch {
        return undefined
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch],
  )

  const getThemeLayout = useCallback(async (themeLayoutId: string) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getDeckV3ThemeLayoutResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.DECK_V3_THEME_LAYOUT(themeLayoutId),
        })
      return res.data.data
    } catch {
      return undefined
    } finally {
      setIsLoading(false)
    }
  }, [])

  return { getThemeDetails, getThemeLayout, isLoading }
}
