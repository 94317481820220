import env from 'react-dotenv'

const sendEvent = (name: string, params?: object) => {
  if (window.gtag && window.dataLayer) {
    sendGTAG(name, params)
  } else {
    const interval = setInterval(() => {
      if (window.gtag && window.dataLayer) {
        clearInterval(interval)
        sendGTAG(name, params)
      }
    }, 2000)
  }
}

const sendGTAG = (name: string, params?: object) => {
  ;(window as any).gtag?.('event', name, {
    environment: env.ENV_NAME || '',
    ...params,
  })
}

const sendDataLayer = (name: string, key: string, params?: object) => {
  ;(window as any).dataLayer?.push({
    event: name,
    [key]: { ...params, environment: env.ENV_NAME || '' },
  })
}

const sendEcommerceEvent = (ecommerce?: object) => {
  if (window.dataLayer) {
    sendDataLayer('purchase', 'ecommerce', ecommerce)
  } else {
    const interval = setInterval(() => {
      if (window.dataLayer) {
        clearInterval(interval)
        sendDataLayer('purchase', 'ecommerce', ecommerce)
      }
    }, 2000)
  }
}

export { sendEvent, sendEcommerceEvent }
