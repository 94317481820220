import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import isEqual from 'lodash/isEqual'
import { observer } from 'mobx-react-lite'
import { VideoElementType } from 'polotno/model/video-model'
import { useEffect, useRef, useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import StyledToggleButton from 'src/components/toggle-button'
import { useLanguage } from 'src/hooks'
import usePrevious from 'src/hooks/usePrevious'
import {
  getVideoThumbnailImages,
  loadVideoAndStart,
} from 'src/utils/videoThumbnailUtility'
import { useVideoTrimStyles } from './styles'
import { IPolotnoComponent } from '../../types'
import VideoSlider, { VideoSliderThumb } from './VideoSlider'

const VideoTrimAction = observer<IPolotnoComponent>(({ store }) => {
  const hasLockedItems = store.selectedElements?.some(
    (element) => element.locked,
  )
  const element = store.selectedElements[0] as VideoElementType

  const { classes } = useVideoTrimStyles()

  const containerRef = useRef<HTMLDivElement>(null)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { t } = useLanguage()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'video-trim-popover' : undefined

  const [imgs, setImgs] = useState<string[]>()

  useEffect(() => {
    let timer: NodeJS.Timer
    async function getImages() {
      loadVideoAndStart(element.src, async (duration) => {
        const seconds = duration
        const container = containerRef.current
        const divWidth = container?.getBoundingClientRect()?.width ?? 440

        const imageWidth = 24
        const countToProduce = Math.min(divWidth / imageWidth, 20)
        const interval = seconds / countToProduce

        const imgsArr = await getVideoThumbnailImages(
          'video-to-trim',
          element.src,
          countToProduce,
          interval,
          48,
          4,
        )
        const newImgs: string[] = []
        imgsArr.forEach((arr) => newImgs.push(...arr))
        setImgs(newImgs)
      })
    }

    if (element.src && open && !imgs) {
      timer = setTimeout(() => {
        getImages()
      }, 300)
    }

    return () => {
      timer && clearTimeout(timer)
    }
  }, [open])

  const minDistance = 5
  const [trimPoints, setTrimPoints] = useState<number[]>([0, 100])
  const oldPoints = usePrevious(trimPoints)
  const handleTrimAreaChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance)
        setTrimPoints([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setTrimPoints([clamped - minDistance, clamped])
      }
    } else {
      setTrimPoints(newValue as number[])
    }
  }

  useEffect(() => {
    if (!!oldPoints && !isEqual(oldPoints, trimPoints)) {
      element.set({
        startTime: trimPoints[0] / 100,
        endTime: trimPoints[1] / 100,
      })
    }
  })

  return !hasLockedItems ? (
    <>
      <StyledToggleButton selected={open} value={open} onClick={handleClick}>
        <MaterialSymbol icon="cut" size={20} weight={300} />
      </StyledToggleButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 48,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={32}
        elevation={6}
      >
        <Stack sx={{ py: 2, px: 3 }}>
          <Typography variant="caption" align="left">
            {`${t('v3.trim')} ${!imgs ? '- ' + t('v3.preparing') : ''}`}
          </Typography>
          <div className={classes.container}>
            <Stack
              ref={containerRef}
              id="decktopus-video-trimmer"
              direction="row"
              className={classes.imageContainer}
            >
              {!imgs && (
                <Skeleton
                  animation="wave"
                  width="100%"
                  height="100%"
                  style={{
                    transformOrigin: 'none',
                    transform: 'none',
                  }}
                />
              )}
              {imgs &&
                imgs.map((imgSrc, index) => (
                  <img
                    key={index}
                    src={imgSrc}
                    width={48}
                    height={48}
                    style={{
                      objectFit: 'cover',
                      marginLeft: '-24px',
                    }}
                    alt={'thumbnail' + index}
                  />
                ))}
            </Stack>
            {imgs && (
              <VideoSlider
                slots={{ thumb: VideoSliderThumb }}
                value={trimPoints}
                onChange={handleTrimAreaChange}
                defaultValue={[0, 100]}
              />
            )}
          </div>
        </Stack>
      </Popover>
    </>
  ) : null
})
export default VideoTrimAction
