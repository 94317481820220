import React, { useMemo } from 'react'
import { gridViewStyles } from './styles'
import { IList } from '../../types'
import { ComponentListDataSchema } from 'src/types/api/requestObjects'
import { ItemMedia } from '../ItemMedia'

export const GridView: React.FC<IList> = React.memo(({ data, overrides }) => {
  const componentData = useMemo(
    () => data.data as ComponentListDataSchema,
    [data],
  )

  const itemCount = useMemo(
    () => componentData.listItems.length || 4,
    [componentData.listItems.length],
  )

  const listItemWidth = useMemo(() => {
    if (itemCount < 4) {
      return `calc(100% / ${itemCount})`
    } else {
      return `calc(100% / ${Math.ceil(itemCount / 2)})`
    }
  }, [itemCount])

  const listItemHeight = useMemo(() => {
    if (itemCount < 4) {
      return '100%'
    } else {
      return `50%`
    }
  }, [itemCount])

  return (
    <div css={gridViewStyles({ itemCount })}>
      {componentData.listItems.map(({ image, title }, index) => (
        <ItemMedia
          key={index}
          image={image}
          width={listItemWidth}
          height={listItemHeight}
          color={componentData.style.colorIcon.text}
          caption={title}
          captionSize={
            overrides?.fontSize || componentData.style.font.size || ''
          }
          onClick={() => {
            document.dispatchEvent(
              new CustomEvent('list-item-click', { detail: index }),
            )
          }}
        />
      ))}
    </div>
  )
})

GridView.displayName = 'GridView'
