import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const aiCategoryPageStyles = ({ gap }: ITheme) => css`
  width: 100%;
  min-height: 383px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${gap['3xsmall']}px;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const aiCategoryWrapperStyles = ({
  gap,
  spacingV2: spacing,
}: ITheme) => css`
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: ${gap.large}px;
  justify-content: center;
  margin-top: ${spacing.small};
`

export const aiCategoryItemStyles = () => css`
  height: 115px;
  position: relative;
`

export const aiCategoryStyles =
  (isOther: boolean) =>
  ({ colors, borderRadius, gap, breakpointsV2: breakpoints }: ITheme) => css`
    background: ${isOther ? colors.white.DEFAULT : colors.background[80]};
    border: 1px solid ${colors.primary[20]};
    height: 115px;
    border-radius: ${borderRadius[8]};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${gap.small}px;
    padding: ${gap['xlarge']}px;

    &:hover {
      border: 1px solid ${colors.primary.DEFAULT};
      cursor: pointer;
    }

    &.selected {
      border: 1px solid ${colors.primary.DEFAULT};
    }

    width: 140px;
    @media (min-width: ${breakpoints.small}px) {
      width: 156.67px;
    }

    @media (min-width: ${breakpoints.tablet}px) {
      width: 156.67px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      width: 199px;
    }
  `

export const aiCategoryNameStyles = ({
  fontSize,
  fontWeight,
  breakpointsV2: breakpoints,
}: ITheme) => css`
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
  @media (min-width: ${breakpoints.desktop}px) {
    font-size: ${fontSize.base};
  }
`
