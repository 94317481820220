import React from 'react'
import { IMembersName } from './types'
import {
  nameStyles,
  wrapperStyles,
  userInfo,
  roleStyles,
  emailStyles,
} from './styles'
import { Avatar } from 'src/lib'

export const MembersName: React.FC<IMembersName> = React.memo(
  ({ className, dataAttr, items, width, smallInputSize }) => {
    return (
      <div css={wrapperStyles({ width })} className={className} {...dataAttr}>
        {!smallInputSize && (
          <Avatar
            size={48}
            data={[
              {
                name: items.name,
                image: items.image,
              },
            ]}
          />
        )}
        <div css={userInfo}>
          {!smallInputSize && <span css={nameStyles}>{items.name}</span>}
          {smallInputSize && (
            <span css={nameStyles}>
              {items.name} <div css={roleStyles}>({items.status})</div>
            </span>
          )}
          <span css={emailStyles}>{items.email}</span>
        </div>
      </div>
    )
  },
)
