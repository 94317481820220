import React, { useMemo } from 'react'

import { IUserTable } from './types'
import {
  userTableStyles,
  userTableHeaderStyles,
  userTableColumnStyles,
} from './styles'
import { v4 as uuidv4 } from 'uuid'

export const UserTable: React.FC<IUserTable> = React.memo(
  ({ tableHeaders, tableColumns, className, dataAttr }) => {
    const headers = useMemo(() => {
      return tableHeaders.map((header: string) => (
        <div key={header}>{header}</div>
      ))
    }, [tableHeaders])

    const columns = useMemo(() => {
      return tableColumns.map((column: React.ReactElement) => (
        <div key={uuidv4()}>{column}</div>
      ))
    }, [tableColumns])

    return (
      <div css={userTableStyles} className={className} {...dataAttr}>
        <div css={userTableHeaderStyles}>{headers}</div>
        <div css={userTableColumnStyles}>{columns}</div>
      </div>
    )
  },
)

UserTable.displayName = 'UserTable'
