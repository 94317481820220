import { dataAttrType } from 'src/types'

export interface IEditNotes {
  className?: string
  dataAttr?: dataAttrType
}

export enum NOTE_TYPE {
  SLIDE = 'slideNote',
  SPEAKER = 'speakerNote',
}

export interface ISetSlideNotesPayload {
  activeSlideDataId?: number
  slideNote?: string
  speakerNote?: string
}
