export const POSTHOG_EVENTS = {
  CANCEL_PROMOTION_APPLY: 'cancel_promotion_apply',
  CANCEL_PROMOTION_CANCEL: 'cancel_promotion_cancel',
  AI_FLOW_PROMPT_LANDING: 'ai_flow_prompt_landing',
  SIGNUP: 'signup',
  AI_FLOW_PROMPT_VIEW: 'ai_flow_prompt_view',
  AI_FLOW_FILE_UPLOAD_VIEW: 'ai_flow_file_upload_view',
  AI_FLOW_FILE_UPLOADED: 'ai_flow_file_uploaded',
  AI_FLOW_CATEGORY_VIEW: 'ai_flow_category_view',
  AI_FLOW_CATEGORY_SELECTED: 'ai_flow_category_selected',
  AI_FLOW_AUDIENCE_VIEW: 'ai_flow_audience_view',
  AI_FLOW_AIM_VIEW: 'ai_flow_aim_view',
  AI_FLOW_OUTLINE_VIEW: 'ai_flow_outline_view',
  AI_FLOW_THEME_VIEW: 'ai_flow_theme_view',
  AI_FLOW_THEME_SELECTED: 'ai_flow_theme_selected',
  AI_FLOW_WAIT_DECK_VIEW: 'ai_flow_wait_deck_view',
  ADD_TO_CART: 'add_to_cart',
  PURCHASE: 'purchase',
}
