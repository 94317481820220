import { useState } from 'react'
import { Alignment, Navbar } from '@blueprintjs/core'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import ColorPicker from 'polotno/toolbar/color-picker'
import { useFillStyles } from './styles'
import { IPolotnoComponent } from '../../types'

interface IColorPickerElementProps extends IPolotnoComponent {
  prop: string
  className?: string
  label?: string
  color?: string
  onSelect?: (color: string) => void
}

const ColorPickerElement = observer<IColorPickerElementProps>(
  ({ store, prop, className, color, label, onSelect }) => {
    const { cx, classes } = useFillStyles()
    const element = store.selectedElements[0]
    const [internalColor, setInternalColor] = useState<string>(
      color ? color : (element as any)?.[prop],
    )

    const handleChange = (fill: string) => {
      if (onSelect) {
        setInternalColor(fill)
        onSelect(fill)
        return
      }

      if (prop === 'stroke') {
        store.selectedElements.forEach((element) => {
          if ((element as any).strokeWidth === 0) {
            element.set({
              strokeWidth: 2,
            })
          }
        })
      }

      store.selectedElements.forEach((element) => {
        element.set({
          [prop]: fill,
        })
      })
    }

    return element ? (
      <Navbar.Group align={Alignment.LEFT}>
        <div className={cx(classes.fillStyles, className)}>
          {label && <Typography variant="caption">{label}</Typography>}
          <ColorPicker
            value={internalColor}
            style={{
              fontOpticalSizing: 'auto',
            }}
            onChange={handleChange}
            store={store}
          />
        </div>
      </Navbar.Group>
    ) : null
  },
)
export default ColorPickerElement
