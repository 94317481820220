import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IMenuItem } from './types'

export const wrapperStyles = css`
  position: relative;
  user-select: none;
  z-index: 1000;

  &:hover {
    .sub-menu {
      opacity: 1;
      z-index: inherit;
      pointer-events: auto;
    }
  }
`

export const separatorStyles = ({ colors, gap }: ITheme) => css`
  margin: ${gap['4xsmall']}px 0;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${colors.background.DEFAULT};
`

type IItemAreaStyles = Pick<IMenuItem, 'isSelected' | 'color'>
export const itemAreaStyles =
  ({ isSelected, color }: IItemAreaStyles) =>
  (theme: ITheme) => css`
    display: flex;
    padding: ${theme.spacingV2.xsmall} ${theme.spacingV2.small};
    gap: ${theme.spacingV2['3xsmall']};
    align-items: center;
    cursor: pointer;
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSize.small};
    white-space: nowrap;
    background: ${isSelected
      ? theme.colors.primary[10]
      : theme.colors.white.DEFAULT};
    color: ${color
      ? color
      : isSelected
        ? theme.colors.primary.DEFAULT
        : theme.colors.text.DEFAULT};

    &:hover {
      background: ${theme.colors.primary[10]};
    }
    & .menu-item-label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 300px;
    }
  `

export const subMenuStyles = (position: 'left' | 'right') => css`
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  position: absolute;
  ${position}: 100%;
  top: 0;
  padding: 0 4px;
  box-sizing: border-box;
`

export const subMenuInnerStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  padding: ${theme.spacingV2['3xsmall']} 0;
  border-radius: ${theme.borderRadius[4]};
  box-shadow: ${theme.boxShadow[2]};
`
