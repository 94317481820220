import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { ObjectServices } from 'src/services'
import { colors, theme } from 'src/theme'
import { ThemeColorMapSchema } from 'src/types/api/requestObjects'

export const useActiveColorMap = ({
  providedDefaultColorMap,
  providedColorMap,
}: {
  providedDefaultColorMap?: ThemeColorMapSchema
  providedColorMap?: ThemeColorMapSchema
}) => {
  const { defaultColorMap, colorMap } = useSelector(({ edit }: RootState) => ({
    defaultColorMap:
      providedDefaultColorMap ??
      edit.activeDeck.data?.deckData?.theme.data.colorMap,
    colorMap:
      providedColorMap ??
      edit.activeDeck.data?.deckData?.data.userThemePreferences?.colorMap,
  }))

  if (!defaultColorMap) return
  const filteredDefaultColorMap = {
    ...defaultColorMap,
  }
  delete filteredDefaultColorMap.chart

  const DEFAULT_COLORS = Object.values(theme.colors.data)
  const chartDefaultColors = DEFAULT_COLORS.map((color) => {
    return {
      default: {
        colors: [color as string],
      },
      swap: {
        colors: [color as string],
      },
    }
  })
  const chartBackgroundColor = [
    {
      default: {
        colors: [colors.white.DEFAULT as string],
      },
      swap: {
        colors: [colors.black.DEFAULT as string],
      },
    },
  ]

  const charts = chartBackgroundColor.concat(chartDefaultColors)

  const decorations = [
    {
      default: {
        colors: ['first'],
      },
      swap: {
        colors: ['fourth'],
      },
    },
    {
      default: {
        colors: ['second'],
      },
      swap: {
        colors: ['third'],
      },
    },
    {
      default: {
        colors: ['third'],
      },
      swap: {
        colors: ['second'],
      },
    },
    {
      default: {
        colors: ['fourth'],
      },
      swap: {
        colors: ['first'],
      },
    },
  ]

  if (!colorMap)
    return {
      ...filteredDefaultColorMap,
      label: filteredDefaultColorMap!.bodyText!,
      shape: filteredDefaultColorMap!.title!,
      charts,
      decorations,
    }

  const userColorMap = ObjectServices.deepMerge(
    filteredDefaultColorMap,
    colorMap,
  )

  return {
    ...userColorMap,
    label: colorMap!.label || filteredDefaultColorMap!.bodyText!,
    shape: colorMap!.shape || filteredDefaultColorMap!.title!,
    charts: colorMap!.charts?.length ? colorMap!.charts : charts,
    decorations: colorMap!.decorations?.length
      ? colorMap!.decorations
      : decorations,
  }
}
