import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const userTableStyles = (theme: ITheme) => css`
  width: 100%;
  height: 100%;
  background: ${theme.colors.white.DEFAULT};
`

export const userTableHeaderStyles = ({
  colors,
  fontWeight,
  gap,
}: ITheme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  background: ${colors.primary.DEFAULT};
  color: ${colors.white.DEFAULT};
  padding-left: ${gap['6xlarge']}px;
  padding-right: ${gap['6xlarge']}px;
  font-weight: ${fontWeight.medium};
`

export const userTableColumnStyles = ({ colors, gap }: ITheme) => css`
  display: flex;
  width: calc(100% - (${gap['4xlarge']}px * 2));
  height: 72px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${colors.outline.DEFAULT};
  margin-top: ${gap['2xlarge']}px;
  margin-left: ${gap['4xlarge']}px;
  margin-right: ${gap['4xlarge']}px;
  padding-left: ${gap['xlarge']}px;
  padding-right: ${gap['xlarge']}px;
`
