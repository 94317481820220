import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'

import {
  useAiApi,
  useLanguage,
  useNotification,
  VALIDATION_RULE_TYPES,
} from 'src/hooks'
import { useTheme } from 'src/theme'
import sparkleIcon from '../../../../lib/icon/svg/sparkle.svg'
import { IPolotnoComponent } from '../../types'

import { TextElementType } from 'polotno/model/text-model'
import {
  promptModalFooterStyles,
  promptModalTitleStyles,
  promptModalWrapperStyles,
} from 'src/components/canvas/components/drag-controller/components/context-menu/styles'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { TKey } from 'src/i18n/types'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  Button as ButtonV2,
  Icon,
  icons,
  Input,
  INPUT_SIZE,
  Modal,
} from 'src/lib'
import { ACTION_CLICKS } from 'src/plugins/google/consts'
import { AiTextEditTypes } from 'src/types/api/enums'
import { ErrorDefinitions } from 'src/types/api/ErrorDefinitions'
import { useAIContextMenuStyles } from './styles'

type TextAIActionItemType = {
  id: string
  label: TKey
  type: AiTextEditTypes
}

const TextAIActions: TextAIActionItemType[] = [
  {
    id: 'summarize',
    label: 'edit.canvas.context_menu.edit_with_ai.summarize',
    type: AiTextEditTypes.SHORTEN,
  },
  {
    id: 'fix',
    label: 'edit.canvas.context_menu.edit_with_ai.fix',
    type: AiTextEditTypes.FIX,
  },
  {
    id: 'rewrite',
    label: 'edit.canvas.context_menu.edit_with_ai.rewrite',
    type: AiTextEditTypes.REWRITE,
  },
  {
    id: 'extend',
    label: 'edit.canvas.context_menu.edit_with_ai.extend',
    type: AiTextEditTypes.EXTEND,
  },
  {
    id: 'other',
    label: 'edit.canvas.context_menu.edit_with_ai.other',
    type: AiTextEditTypes.OTHER,
  },
]

type AiCallType = {
  type: AiTextEditTypes
  isCallback: boolean
}

const TextAIAction = observer<IPolotnoComponent>(({ store }) => {
  const { t } = useLanguage()
  const { editText } = useAiApi()

  const { cx, classes } = useAIContextMenuStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [otherPrompt, setOtherPrompt] = useState('')
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false)
  const [selectedType, setSelectedType] = useState<null | AiTextEditTypes>(null)
  const { success } = useNotification()

  const open = Boolean(anchorEl)

  const allTextItems = store.selectedElements?.every(
    (element) => element.type === 'text',
  )
  const textElement = store.selectedElements[0] as TextElementType

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (open) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const editWithAiHandler = useCallback(
    async ({ type, isCallback }: AiCallType) => {
      if (type === AiTextEditTypes.OTHER && !otherPrompt.length) {
        setIsPromptModalOpen(true)
        return
      }
      setIsPromptModalOpen(false)
      setIsLoading(true)
      textElement?.set({
        contentEditable: false,
      })
      const editedText = await editText({
        text: textElement?.text,
        textEditType: type,
        instruction: otherPrompt,
      })
      setIsLoading(false)
      setOtherPrompt('')
      if (isCallback) {
        setIsUpgradeModalOpen(false)
        if (editedText === ErrorDefinitions.INSUFFICIENT_AI_CREDIT) {
          return
        }
      }
      if (
        editedText === ErrorDefinitions.INSUFFICIENT_AI_CREDIT &&
        !isCallback
      ) {
        setSelectedType(type)
        setIsUpgradeModalOpen(true)
        return
      }
      const editedTextWithoutTags = editedText
        ? editedText.replace(/<p>/g, '').replace(/<\/p>/g, '')
        : editedText

      if (
        editedTextWithoutTags ===
        textElement?.text?.replace(/<p>/g, '')?.replace(/<\/p>/g, '')
      ) {
        success('edit.canvas.context_menu.edit_with_ai.nothing_improved')
      }

      textElement?.set({
        text: editedText,
        contentEditable: true,
      })
    },
    [editText, otherPrompt, success, textElement],
  )

  const handleActionClick = (action: AiTextEditTypes) => {
    if (action) {
      editWithAiHandler({ type: action, isCallback: false })
    }
    setAnchorEl(null)
  }

  const handleInputChange = useCallback(
    (val: string) => setOtherPrompt(val),
    [],
  )

  return allTextItems ? (
    <>
      <Button
        size="small"
        variant="outlined"
        disableElevation
        startIcon={
          isLoading ? (
            <CircularProgress size={16} />
          ) : (
            <img width={16} height={16} src={sparkleIcon} alt="sparkle" />
          )
        }
        onClick={handleClick}
        sx={{
          flexShrink: 0,
        }}
        className={cx(classes.button, open ? classes.isOpen : '')}
        aria-controls={open ? 'ai-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={isLoading}
      >
        {t('v3.text.ai_write')}
      </Button>
      <Menu
        id="ai-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClick}
        MenuListProps={{
          'aria-labelledby': 'ai-button',
        }}
        anchorOrigin={{
          vertical: 48,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        {TextAIActions.map((item) => (
          <MenuItem
            key={item.id}
            className={classes.menuItem}
            onClick={() => handleActionClick(item.type)}
          >
            {t(item.label)}
          </MenuItem>
        ))}
      </Menu>
      <UpgradeModalManager
        isOpen={isUpgradeModalOpen}
        callback={() =>
          editWithAiHandler({ type: selectedType!, isCallback: true })
        }
        refillMode={true}
        context={ACTION_CLICKS.AI_CLICK}
        onClose={() => setIsUpgradeModalOpen(false)}
      />
      <AiOtherTextPrompt
        isPromptModalOpen={isPromptModalOpen}
        setIsPromptModalOpen={setIsPromptModalOpen}
        editWithAiHandler={editWithAiHandler}
        otherPrompt={otherPrompt}
        handleInputChange={handleInputChange}
      />
    </>
  ) : null
})

export default TextAIAction

interface IAiOtherTextPromptProps {
  isPromptModalOpen: boolean
  setIsPromptModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  editWithAiHandler: (props: AiCallType) => void
  otherPrompt: string
  handleInputChange?: (value: string) => void
}

const AiOtherTextPrompt: React.FC<IAiOtherTextPromptProps> = React.memo(
  ({
    isPromptModalOpen,
    setIsPromptModalOpen,
    editWithAiHandler,
    otherPrompt,
    handleInputChange,
  }) => {
    const { t } = useLanguage()
    const { colors } = useTheme()
    return (
      <Modal
        isOpen={isPromptModalOpen}
        onClose={() => setIsPromptModalOpen(false)}
      >
        <div css={promptModalWrapperStyles}>
          <div css={promptModalTitleStyles}>
            {t('edit.canvas.context_menu.edit_with_ai.title')}

            <Icon
              className="close-button"
              icon={icons.close}
              color={colors.outline[2]}
              size={16}
              onClick={() => setIsPromptModalOpen(false)}
            />
          </div>

          <Input
            placeholder={t(
              'edit.canvas.context_menu.edit_with_ai.prompt_placeholder',
            )}
            value={otherPrompt}
            size={INPUT_SIZE.SMALL}
            onChange={handleInputChange}
            validation={{
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t(
                  'edit.canvas.context_menu.edit_with_ai.input_error_message',
                ),
              },
            }}
          />
          <div css={promptModalFooterStyles}>
            <ButtonV2
              text={t('edit.canvas.context_menu.edit_with_ai.generate')}
              theme={BUTTON_THEME.GRADIENT}
              onClick={() =>
                editWithAiHandler({
                  type: AiTextEditTypes.OTHER,
                  isCallback: false,
                })
              }
              disabled={otherPrompt.length < 3}
              icon={icons.sparkling}
              size={BUTTON_SIZE.XSMALL}
            />
          </div>
        </div>
      </Modal>
    )
  },
)
