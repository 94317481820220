import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IMembersName } from './types'

type IMembersNameStyles = Pick<IMembersName, 'width'>
export const wrapperStyles =
  ({ width }: IMembersNameStyles) =>
  (theme: ITheme) => css`
    background: ${theme.colors.white.DEFAULT};
    width: ${width}px;
    height: ${theme.dimensions.memberRowHeight.DEFAULT}px;
    display: flex;
    align-items: center;
    column-gap: ${theme.gap.xsmall}px;

    @media (min-width: ${theme.breakpointsV2.small}px) {
      width: 100%;
    }

    @media (min-width: ${theme.breakpointsV2.tablet}px) {
      min-width: ${width}px;
    }
  `

export const userInfo = css`
  display: flex;
  flex-direction: column;
  width: max-content;
`

export const nameStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base}px;
  font-weight: ${theme.fontWeight.medium};
  width: ${theme.dimensions.userMailWidth.DEFAULT}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const emailStyles = (theme: ITheme) => css`
  width: ${theme.dimensions.userMailWidth.DEFAULT}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const roleStyles = ({ gap, colors }: ITheme) => css`
  color: ${colors.text[2]};
  display: inline;
  margin-left: ${gap['4xsmall']}px;
`
