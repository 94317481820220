import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const searchResultsWrapperStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacingV2.large};
  width: 100%;
`

export const searchResultsTitleStyles = (theme: ITheme) => css`
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.colors.text.DEFAULT};
  margin-bottom: ${theme.spacingV2.large};
`

export const searchResultsPlaceholderStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: center;
  gap: ${theme.spacingV2.large};
  width: 100%;
`

export const searchResultsInnerStyles = ({ spacingV2: spacing }: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${spacing.medium};
  text-align: center;
`

export const searchResultsDecktoStyles = css`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const searchResultsPlaceholderTitleStyles = ({
  fontSize,
  spacingV2: spacing,
}: ITheme) => css`
  font-size: ${fontSize.large};
  padding-top: ${spacing.small};
`

export const searchResultsPlaceholderSubTitleStyles = ({
  fontSize,
}: ITheme) => css`
  font-size: ${fontSize.small};
`

export const searchResultsPlaceholderLinkStyles = ({
  colors,
  fontWeight,
}: ITheme) => css`
  text-decoration: underline;
  color: ${colors.primary.DEFAULT};
  cursor: pointer;
  font-weight: ${fontWeight.medium};
`
