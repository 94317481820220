import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IPropButton } from './types'

interface IPropButtonStyles extends Pick<IPropButton, 'isBox'> {
  isOpen?: boolean
  hasIcon?: boolean
}
export const propButtonStyles =
  ({ isOpen, isBox, hasIcon }: IPropButtonStyles) =>
  ({ colors, dimensions, gap, borderRadius, fontSize }: ITheme) => css`
    position: relative;
    width: 100%;
    background: ${colors.dark.DEFAULT};
    ${isBox
      ? `
				flex-direction: column;
				aspect-ratio: 1;`
      : `
				height: ${dimensions.formElementHeight.big}px;
		`}

    border-radius: ${borderRadius[4]};
    display: flex;
    gap: ${gap.xsmall}px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${isOpen &&
    `
			border: 1px solid ${colors.primary.DEFAULT};
		`}

    .input {
      font-size: ${fontSize.small};
      input {
        width: 26px;
        border: none;
        outline: none;
        background: transparent;
        color: ${colors.white.DEFAULT};
        text-align: ${hasIcon ? 'right' : 'center'};
      }
    }
  `
