import { TextItemType, TextSubType } from 'src/pages/deck-page-v3/types'

export const TextElements: TextItemType[] = [
  {
    id: 1,
    label: 'v3.text.bigtext',
    props: {
      fontSize: 20,
      fontWeight: 700,
      textAlign: 'center',
      custom: {
        subType: TextSubType.BIG_TEXT,
      },
    },
  },
  {
    id: 2,
    label: 'v3.text.heading',
    props: {
      fontSize: 18,
      fontWeight: 500,
      textAlign: 'center',
      custom: {
        subType: TextSubType.HEADING,
      },
    },
  },
  {
    id: 3,
    label: 'v3.text.subheading',

    props: {
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center',
      custom: {
        subType: TextSubType.SUBHEADING,
      },
    },
  },
  {
    id: 4,
    label: 'v3.text.normaltext',

    props: {
      fontSize: 12,
      fontWeight: 500,
      textAlign: 'center',
      custom: {
        subType: TextSubType.NORMAL_TEXT,
      },
    },
  },
  {
    id: 5,
    label: 'v3.text.smalltext',

    props: {
      fontSize: 11,
      fontWeight: 400,
      textAlign: 'center',
      custom: {
        subType: TextSubType.SMALL_TEXT,
      },
    },
  },
]
