export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_16_138)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_16_138"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_22_8)"><path fill-rule="evenodd" clip-rule="evenodd" d="M634.68 0H1920V1080H780.034C876.165 949.968 933 789.121 933 615C933 365.575 816.374 143.389 634.68 0Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M958 615C958 175.657 601.843 -180.5 162.5 -180.5C-276.843 -180.5 -633 175.657 -633 615C-633 1054.34 -276.843 1410.5 162.5 1410.5C601.843 1410.5 958 1054.34 958 615ZM162.5 -155.5C588.035 -155.5 933 189.465 933 615C933 1040.54 588.035 1385.5 162.5 1385.5C-263.035 1385.5 -608 1040.54 -608 615C-608 189.465 -263.035 -155.5 162.5 -155.5Z" fill="$color2"/></g><defs><clipPath id="clip0_22_8"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H1920V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_22_3)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1285.32 0H0V1080H1139.97C1043.84 949.968 987 789.121 987 615C987 365.575 1103.63 143.389 1285.32 0Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M962 615C962 175.657 1318.16 -180.5 1757.5 -180.5C2196.84 -180.5 2553 175.657 2553 615C2553 1054.34 2196.84 1410.5 1757.5 1410.5C1318.16 1410.5 962 1054.34 962 615ZM1757.5 -155.5C1331.96 -155.5 987 189.465 987 615C987 1040.54 1331.96 1385.5 1757.5 1385.5C2183.04 1385.5 2528 1040.54 2528 615C2528 189.465 2183.04 -155.5 1757.5 -155.5Z" fill="$color2"/></g><defs><clipPath id="clip0_22_3"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H0V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_22_13)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V899.416C1638.39 733.863 1310.28 638.922 960 638.922C609.716 638.922 281.606 733.863 0 899.416V0Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-949 2523.48C-949 1468.91 -94.0948 614 960.484 614C2015.06 614 2869.97 1468.91 2869.97 2523.48C2869.97 3578.06 2015.06 4432.97 960.484 4432.97C-94.0948 4432.97 -949 3578.06 -949 2523.48ZM960.484 639C-80.2877 639 -924 1482.71 -924 2523.48C-924 3564.26 -80.2877 4407.97 960.484 4407.97C2001.26 4407.97 2844.97 3564.26 2844.97 2523.48C2844.97 1482.71 2001.26 639 960.484 639Z" fill="$color2"/></g><defs><clipPath id="clip0_22_13"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="540" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
