import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editSlidesStyles = ({ gap }: ITheme) => css`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 ${gap.xlarge}px;
  gap: ${gap.small}px;
  overflow-x: auto;
`
