import i18n from 'src/i18n/config'

export const TESTIMONY_DATA = (lang: string) => [
  {
    title: i18n.t('login.title1', { lang }),
    text: i18n.t('login.text1', { lang }),
  },
  {
    title: i18n.t('login.title2', { lang }),
    text: i18n.t('login.text2', { lang }),
  },
  {
    title: i18n.t('login.title3', { lang }),
    text: i18n.t('login.text3', { lang }),
  },
  {
    title: i18n.t('login.title4', { lang }),
    text: i18n.t('login.text4', { lang }),
  },
]
