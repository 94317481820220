import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const useCanvas = () => {
  const { isComponentFixing } = useSelector(({ canvas }: RootState) => ({
    isComponentFixing: canvas.componentFixInProgress,
  }))

  return {
    isComponentFixing,
  }
}
