import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IListCard } from './types'
import { wrapperStyles } from './styles'
import { Icon } from '../icon'
import { COLOR } from 'src/theme'

export const ListCard: React.FC<IListCard> = React.memo(
  ({ items, className, dataAttr }) => {
    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        {items?.map(({ text, value, icon, onClick }) => {
          return (
            <div
              className={`item${onClick ? ' clickable' : ''}`}
              key={uuidv4()}
              onClick={onClick}
            >
              <div className="text">{text}</div>
              {value && <div className="value">{value}</div>}
              {icon && <Icon icon={icon} size={16} color={COLOR.outline_90} />}
            </div>
          )
        })}
      </div>
    )
  },
)
