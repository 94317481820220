import React, { useCallback } from 'react'
import { wrapperStyles, innerStyles, buttonStyles } from './styles'
import { HeaderLogo } from '../header/components/header-logo'
import { BUTTON_TYPE, Button } from 'src/lib/button'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from 'src/hooks'

export const ExpiredHeader: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const navigate = useNavigate()

  const handleLoginClick = useCallback(() => {
    navigate('/login', { replace: true })
  }, [])

  const handleSignUpClick = useCallback(() => {
    navigate('/signup', { replace: true })
  }, [])

  return (
    <div css={wrapperStyles}>
      <div css={innerStyles}>
        <HeaderLogo />
        <div css={buttonStyles}>
          <Button
            type={BUTTON_TYPE.GHOST}
            text={t('login.login')}
            onClick={handleLoginClick}
          />
          <Button text={t('expired.get_started')} onClick={handleSignUpClick} />
        </div>
      </div>
    </div>
  )
})
