import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { useUserApi, useConfigApi } from 'src/hooks'
import { wrapperStyles } from './styles'
import { ImageUpload } from 'src/lib'

export const UserData: React.FC = React.memo(() => {
  const { uploadImage } = useConfigApi()
  const { updateUser } = useUserApi()

  const { userImage, fullName, email } = useSelector(({ user }: RootState) => ({
    userImage: user.data?.picture,
    fullName: user.data?.fullName,
    email: user.data?.email,
  }))

  const onImageChangeHandler = useCallback(async (file: File) => {
    let picture = null
    if (file) {
      picture = await uploadImage({ file })
    }
    updateUser({ picture })
  }, [])

  return (
    <div css={wrapperStyles}>
      <div className="profile-picture-area">
        <ImageUpload
          defaultImage={userImage}
          onChange={onImageChangeHandler}
          text={fullName}
        />
      </div>
      <div className="full-name">{fullName}</div>
      <div className="email">{email}</div>
    </div>
  )
})
