import React from 'react'
import { Helmet } from 'react-helmet-async'

export const Gtm: React.FC = () => {
  return (
    <Helmet>
      <script>{`(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-T26G8Z8");`}</script>
      <script
        // async=""
        src="https://www.googletagmanager.com/gtag/js?id=GTM-T26G8Z8"
      ></script>
    </Helmet>
  )
}
