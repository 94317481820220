import React, { useState } from 'react'

import { ICardTheme } from './types'
import { wrapperStyles } from './styles'
import { Spinner } from '../spinner'

export const CardTheme: React.FC<ICardTheme> = React.memo(
  ({ thumbnail, className, dataAttr, onClick }) => {
    const [isDownloading, setIsDownloading] = useState(true)
    return (
      <div
        css={wrapperStyles({ isDownloading })}
        onClick={onClick}
        className={className}
        {...dataAttr}
      >
        {isDownloading && (
          <div className="spinner">
            <Spinner size={40} />
          </div>
        )}
        <img src={thumbnail} onLoad={() => setIsDownloading(false)} />
      </div>
    )
  },
)
