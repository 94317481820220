import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  BUTTON_THEME,
  BUTTON_WIDTH,
  Button,
  Icon,
  Modal,
  Table,
  icons,
} from 'src/lib'
import {
  ComponentTableDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { chartEditModalStyles } from './styles'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { ComponentServices } from 'src/services'
import { DeepPartial } from 'src/types'
import { IEditPropSection } from '../../types'
import { useLanguage, useDebouncer } from 'src/hooks'

export const EditTable: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()
    const { t } = useLanguage()
    const [isModalOpen, setModalOpen] = useState(false)

    const selectedTableData = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentTableDataSchema['tableContent'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const data = (c.data as ComponentTableDataSchema).tableContent
            if (a === undefined && data) {
              return data
            }

            return data !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const [tableData, setTableData] = useState<
      ComponentTableDataSchema['tableContent'] | undefined
    >(selectedTableData)

    const handleSave = useCallback(() => {
      const partialData: DeepPartial<ComponentTableDataSchema> = {
        tableContent: tableData,
      }
      const updatedComponents: UpdateComponentSchema[] =
        ComponentServices.updateComponentData<ComponentTableDataSchema>({
          components,
          partialData,
        })

      dispatch(componentsUpdate({ components: updatedComponents }))
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }, [components, tableData])

    useEffect(() => {
      const listener = () => {
        setModalOpen(true)
        dispatch(setAllowDeleteShortcut(false))
      }
      document.addEventListener('element-double-click', listener)
      return () => {
        document.removeEventListener('element-double-click', listener)
      }
    }, [])

    useEffect(() => {
      debounced()
    }, [tableData])

    const debounced = useDebouncer(
      () => {
        handleSave()
      },
      { delay: 300 },
    )

    return (
      <>
        <Button
          theme={BUTTON_THEME.PRIMARY}
          text={t('edit.properties.table')}
          width={BUTTON_WIDTH.FULL}
          onClick={() => {
            setModalOpen(true)
            dispatch(setAllowDeleteShortcut(false))
          }}
        />
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setModalOpen(false)
            dispatch(setAllowDeleteShortcut(true))
          }}
        >
          <div css={chartEditModalStyles}>
            <div className="inner">
              <div className="chart-edit-header">
                <div className="chart-edit-title">
                  {t('edit.properties.table')}
                </div>
                <div className="chart-edit-type"></div>
                <Icon
                  icon={icons.close}
                  size={16}
                  onClick={() => {
                    setModalOpen(false)
                  }}
                />
              </div>
              <div className="table-area">
                <Table
                  options={{
                    edit: true,
                    headerCol: true,
                    headerRow: true,
                  }}
                  onChange={setTableData}
                  data={tableData?.map((val) =>
                    val.map((val2) => val2.toString()),
                  )}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  },
)

EditTable.displayName = 'EditTable'
