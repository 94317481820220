import React, { useMemo } from 'react'

import { ISvgMasked } from './types'
import { svgMaskedStyles } from './styles'
import { useCachedSvg } from 'src/hooks/useCachedSvg'

export const SvgMasked: React.FC<ISvgMasked> = React.memo(
  ({
    isCached = true,
    blob,
    url: svgUrl,
    color = '#000',
    gradientColor,
    className,
    dataAttr,
    distort,
  }) => {
    const { url } = useCachedSvg(svgUrl, { isCached, distort })

    const colorStyles = useMemo(() => {
      if (gradientColor?.colors.length) {
        return {
          background: `linear-gradient(${
            gradientColor?.rotation || 0
          }deg, ${gradientColor?.colors[0]} 0%, ${
            gradientColor?.colors[1] || gradientColor?.colors[0]
          } 100%)`,
        }
      } else {
        return {
          backgroundColor: color,
        }
      }
    }, [gradientColor, color])

    return (
      <div
        css={svgMaskedStyles}
        style={{
          ...colorStyles,
          maskImage: `url(${url || blob})`,
        }}
        className={className}
        {...dataAttr}
      ></div>
    )
  },
)

SvgMasked.displayName = 'SvgMasked'
