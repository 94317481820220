import React, { useState, useCallback, useRef, useMemo } from 'react'
import { useLanguage, useWindowSize } from 'src/hooks'
import { HorizontalProgress, Icon, icons } from 'src/lib'
import { IRemainingSeats } from './types'
import {
  remainingSeatsStyles,
  remainingSeatsTitleStyles,
  seatsWrapperStyles,
  usedSeatsStyles,
  manageSeatsStyles,
  manageSeatsToggleStyles,
  adjustSeatsStyles,
  adjustSeatsOverlayStyles,
  adjustSeatsContainerStyles,
} from './styles'
import { AdjustSeats } from '../adjust-seats'
import { useTheme } from 'src/theme'

export const RemainingSeats: React.FC<IRemainingSeats> = React.memo(
  ({
    totalSeats,
    occupiedSeats,
    isManageSeatsAvailable,
    className,
    dataAttr,
  }) => {
    const { t, Trans } = useLanguage()
    const { colors, dimensions, gap } = useTheme()

    const [isAdjustSeatsOpen, setIsAdjustSeatsOpen] = useState(false)
    const targetRef = useRef<HTMLInputElement>(null)

    const calculatePercentage = useMemo(
      () => (occupiedSeats * 100) / totalSeats,
      [occupiedSeats, totalSeats],
    )
    const isFull = useMemo(
      () => calculatePercentage === 100,
      [calculatePercentage],
    )

    const windowSize = useWindowSize()
    const targetRect = useMemo(
      () => targetRef?.current?.getBoundingClientRect(),
      [targetRef?.current?.getBoundingClientRect(), windowSize],
    )
    const topValueOfAdjustSeats = useMemo(
      () =>
        targetRect
          ? targetRect.top - dimensions.adjustSeatsHeight.DEFAULT + 'px'
          : gap.xsmall + 'px',
      [targetRect],
    )

    const handleManageSeats = useCallback(() => {
      setIsAdjustSeatsOpen(true)
    }, [])

    const handleOnClose = useCallback(() => {
      setIsAdjustSeatsOpen(false)
    }, [])

    return (
      <div css={remainingSeatsStyles} className={className} {...dataAttr}>
        <span css={remainingSeatsTitleStyles}>
          {t('organization.remaining_seats')}
        </span>

        <HorizontalProgress percentage={calculatePercentage} isFull={isFull} />

        <div css={seatsWrapperStyles}>
          <div css={usedSeatsStyles(isFull)}>
            <Trans
              i18nKey={'organization.used_seats'}
              values={{ occupiedSeats, totalSeats }}
            />
          </div>
          <div css={manageSeatsStyles}>
            {isManageSeatsAvailable && (
              <div
                css={manageSeatsToggleStyles(isAdjustSeatsOpen)}
                onClick={handleManageSeats}
              >
                <Icon
                  icon={icons.tune}
                  color={colors.primary.DEFAULT}
                  size={16}
                />
                <span ref={targetRef}>{t('organization.manage_seats')}</span>
              </div>
            )}
            <div css={adjustSeatsStyles(isAdjustSeatsOpen)}>
              <div
                css={adjustSeatsContainerStyles(
                  isAdjustSeatsOpen,
                  topValueOfAdjustSeats,
                )}
              >
                <AdjustSeats totalSeats={totalSeats} onClose={handleOnClose} />
              </div>
              <div css={adjustSeatsOverlayStyles} />
            </div>
          </div>
        </div>
      </div>
    )
  },
)
