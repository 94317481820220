import { useMemo } from 'react'
import { useLocation as useRouterLocation } from 'react-router-dom'

export interface IUseLocation {
  path: string[]
  backFromPath: string
  lastPath: string
}

export const useLocation = (): IUseLocation => {
  const location = useRouterLocation()

  const path = useMemo(
    () => location.pathname.split('/').filter((v) => v),
    [location],
  )

  const lastPath = useMemo(() => path[path.length - 1], [path])

  const backFromPath = useMemo(() => {
    const spliced = [...path]
    spliced.splice(-1)
    return `/${spliced.join('/')}`
  }, [path])

  return { path, backFromPath, lastPath }
}
