import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import ReactQuill from 'react-quill'
import {
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'

import {
  SAVE_STATE,
  addComponentFixQueue,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { DeepPartial } from 'src/types'
import { ComponentServices, StringServices } from 'src/services'
import { APP_CONFIG } from 'src/config'

import { IText } from './types'
import { editStyles, placeholderStyles, textStyles } from './styles'
import {
  TextAlignmentsHorizontal,
  TextAlignmentsVertical,
} from 'src/types/api/enums'
import {
  useActiveCanvas,
  useCanvas,
  useDebouncer,
  useLanguage,
} from 'src/hooks'
import { CANVAS_TYPE } from 'src/components/canvas/types'

export const Text: React.FC<IText> = React.memo(
  ({
    overrides,
    data,
    scale,
    slideDataId,
    isSelected,
    className,
    dataAttr,
    isScaling,
    canvasType,
    canDrag,
    onHeightChange,
  }) => {
    const { t } = useLanguage()
    const editorRef = useRef<ReactQuill>(null)
    const textAreaRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const { autoResizeNeeded } = useActiveCanvas({})
    const { isComponentFixing } = useCanvas()

    const componentData = useMemo(
      () => data.data as ComponentTextDataSchema,
      [data],
    )

    const textValue = useMemo(
      () => componentData.text,
      [componentData, (data.data as ComponentTextDataSchema).text],
    )

    const isPreview = useMemo(
      () => canvasType === CANVAS_TYPE.PREVIEW,
      [canvasType],
    )

    const [isEditMode, setIsEditMode] = useState(false)
    const cleanValue = useMemo(
      () => StringServices.clearHtmlTags([textValue])[0],
      [textValue],
    )

    useEffect(() => {
      if (isSelected && !cleanValue) {
        ;(document?.querySelector?.('.edit .ql-editor') as any)?.focus()
      }

      if (!isSelected) {
        canDrag?.(true)
        setIsEditMode(false)
      }
    }, [isSelected, cleanValue])

    const componentUpdateData = useCallback(
      (value: string) => {
        const partialData: DeepPartial<ComponentTextDataSchema> = {
          text: value,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTextDataSchema>({
            components: [data],
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [data, componentData],
    )

    const onChangeHandler = useCallback(
      (value: string) => {
        componentUpdateData(value)
      },
      [data],
    )

    const handleOnFocus = useCallback(() => {
      canDrag?.(false)
      dispatch(setAllowDeleteShortcut(false))
      const el = document.querySelector('.ql-tooltip [data-link]')
      const qlInput: HTMLInputElement | null = document.querySelector(
        '.ql-tooltip > input',
      )

      if (!el || !qlInput) {
        return
      }

      el.setAttribute('placeholder', 'https://example.com')
      el.setAttribute('data-link', 'https://example.com')
      setTimeout(() => {
        qlInput.value = 'https://'
      }, 1000)
    }, [])

    const labelTextReplace = (colorName?: string) => {
      return colorName === 'var(--label)' ? `var(--labelText)` : colorName
    }

    const textColorStyles = useMemo(() => {
      const colorData = componentData.style.color.text
      const isGradient = colorData.colors[1]
      if (isGradient) {
        return {
          '--dt-grad-background': `-webkit-linear-gradient(${
            90 - (colorData?.rotation || 0)
          }deg, ${labelTextReplace(colorData.colors[0])} 0%, ${labelTextReplace(
            colorData.colors[1],
          )} 100%)`,
          '--dt-grad--webkit-background-clip': 'text',
          '--dt-grad--webkit-text-fill-color': 'transparent',
        }
      }

      return {
        color: labelTextReplace(colorData.colors?.[0]),
        background:
          labelTextReplace(componentData.style.color.background?.colors[0]) ||
          undefined,
      }
    }, [componentData])

    const textAlignment = useMemo(() => {
      switch (
        (data.data as ComponentTextDataSchema).style.font.alignmentHorizontal
      ) {
        case TextAlignmentsHorizontal.LEFT:
          return 'left'
        case TextAlignmentsHorizontal.CENTER:
          return 'center'
        case TextAlignmentsHorizontal.RIGHT:
          return 'right'
        default:
          return 'center'
      }
    }, [data])

    const flexAlignment = useMemo(() => {
      switch (
        (data.data as ComponentTextDataSchema).style.font.alignmentHorizontal
      ) {
        case TextAlignmentsHorizontal.LEFT:
          return 'flex-start'
        case TextAlignmentsHorizontal.CENTER:
          return 'center'
        case TextAlignmentsHorizontal.RIGHT:
          return 'flex-end'
        default:
          return 'center'
      }
    }, [data])

    const verticalAlignment = useMemo(() => {
      switch (
        (data.data as ComponentTextDataSchema).style.font.alignmentVertical
      ) {
        case TextAlignmentsVertical.TOP:
          return 'flex-start'
        case TextAlignmentsVertical.CENTER:
          return 'center'
        case TextAlignmentsVertical.BOTTOM:
          return 'flex-end'
        default:
          return 'center'
      }
    }, [data])

    const editVerticalAlignment = useMemo(() => {
      switch (
        (data.data as ComponentTextDataSchema).style.font.alignmentVertical
      ) {
        case TextAlignmentsVertical.TOP:
          return {
            top: 0,
          }
        case TextAlignmentsVertical.CENTER:
          return {
            top: '50%',
            transform: `translateY(-50%)`,
          }
        case TextAlignmentsVertical.BOTTOM:
          return {
            bottom: 0,
            transform: `translate(0, 0)`,
          }
        default:
          return {
            top: '50%',
            transform: `translateY(-50%)`,
          }
      }
    }, [data])

    const renderStaticText = useMemo(
      () =>
        cleanValue ? (
          <div
            ref={textAreaRef}
            className="preview ql-editor"
            style={{
              opacity: !isScaling && isEditMode ? 0 : 1,
            }}
            dangerouslySetInnerHTML={{
              __html: textValue,
            }}
          />
        ) : (
          <div css={placeholderStyles} style={editVerticalAlignment}>
            {isPreview ? '' : `[${t('edit.canvas.blank')}]`}
          </div>
        ),
      [
        componentData,
        data,
        textValue,
        isScaling,
        isSelected,
        isPreview,
        isEditMode,
      ],
    )

    const fontFamily = useMemo(() => {
      switch (componentData.style.font.family) {
        case 'primary':
          return 'var(--font-primary)'
        case 'secondary':
          return 'var(--font-secondary)'
        default:
          return componentData.style.font.family
      }
    }, [componentData.style.font.family])

    const fontWeight = useMemo(() => {
      switch (componentData.style.font.family) {
        case 'primary':
          return 'var(--font-primary-weight)'
        case 'secondary':
          return 'var(--font-secondary-weight)'
        default:
          return componentData.style.font.weight === null
            ? 400
            : componentData.style.font.weight
      }
    }, [componentData.style.font])

    const handleClick = useCallback(() => {
      if (isSelected) {
        canDrag?.(false)
        setIsEditMode(true)
      }
    }, [isSelected])

    const [fitRatio, setFitRatio] = useState(0)

    const addFixRequestToQueue = useCallback(() => {
      const newValue = parseInt(overrides?.fontSize) / Math.sqrt(fitRatio || 1)

      if (slideDataId && newValue) {
        dispatch(
          addComponentFixQueue({
            slideDataId,
            data,
            updateData: {
              style: { font: { size: `${newValue}em` } },
            },
          }),
        )
      }
    }, [fitRatio, overrides?.fontSize, slideDataId, data])

    const fixRequestDebounce = useDebouncer(
      () => {
        addFixRequestToQueue()
      },
      { delay: 300 },
    )

    useLayoutEffect(() => {
      if (autoResizeNeeded && canvasType === CANVAS_TYPE.SLIDE_CARD) {
        const currentHeight = data.positions.height || 1
        const contentHeight =
          (textAreaRef.current?.getBoundingClientRect()?.height || 1) /
          (scale || 1)
        const calcFitRatio = contentHeight / currentHeight
        setFitRatio(calcFitRatio)
      }
    }, [scale, data.positions.height])

    useLayoutEffect(() => {
      if (fitRatio > 1.01) {
        fixRequestDebounce()
      }
    }, [fitRatio])

    const updateBoxHeight = useCallback(() => {
      const currentHeight = data.positions.height || 1
      const contentHeight =
        (textAreaRef.current?.getBoundingClientRect()?.height || 1) /
        (scale || 1)
      if (contentHeight > currentHeight) {
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponent<UpdateComponentSchema>({
            components: [data],
            partialUpdate: {
              positions: {
                height: contentHeight,
              },
            },
          })
        dispatch(
          componentsUpdate({
            components: updatedComponents,
            isAutoProcess: true,
          }),
        )
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }
    }, [textAreaRef.current, scale, data])

    useLayoutEffect(() => {
      if (!isComponentFixing && canvasType === CANVAS_TYPE.DND) {
        if (isEditMode) {
          const currentHeight = data.positions.height || 1
          const contentHeight =
            (textAreaRef.current?.getBoundingClientRect()?.height || 1) /
            (scale || 1)
          if (contentHeight > currentHeight) {
            onHeightChange?.(contentHeight)
          }
        } else {
          updateBoxHeight()
        }
      }
    }, [componentData.text, componentData.style.font, scale, isEditMode])

    return (
      <div
        css={textStyles}
        onClick={handleClick}
        onFocus={handleOnFocus}
        style={{
          ...(overrides?.fontSize ? { fontSize: overrides.fontSize } : {}),
          fontFamily,
          fontWeight,
          textAlign: textAlignment,
          justifyContent: flexAlignment,
          alignItems: verticalAlignment,
          opacity: data.style.opacity!,
          ...textColorStyles,
          cursor: isEditMode ? 'text' : 'default',
        }}
        className={className}
        {...dataAttr}
      >
        {renderStaticText}
        {!isScaling && isEditMode && (
          <div css={editStyles} style={editVerticalAlignment}>
            <ReactQuill
              modules={{
                toolbar: {
                  container: `#quill-toolbar`,
                },
              }}
              formats={APP_CONFIG.canvasTextAllowedFormats}
              theme="snow"
              value={textValue}
              onChange={onChangeHandler}
              ref={editorRef}
            />
          </div>
        )}
      </div>
    )
  },
)

Text.displayName = 'Text'
