import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = ({ colors, gap }: ITheme) => css`
  background: ${colors.white.DEFAULT};
  column-gap: ${gap['2xsmall']}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const roleStyles = ({ fontSize }: ITheme) => css`
  font-size: ${fontSize.small}px;
`
