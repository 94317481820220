import { ComponentTypes } from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentTextDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const codeDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.code.display_name', { lng }),
      styles: {},
      icon: icons.code,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.BUTTON,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 400,
          height: 400,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {} as ComponentTextDataSchema,
      },
    ],
  }
}
