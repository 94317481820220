import React from 'react'
import { Helmet } from 'react-helmet-async'

export const Gtag: React.FC = () => {
  return (
    <Helmet>
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
    
          gtag("config", "GTM-T26G8Z8");`}
      </script>
    </Helmet>
  )
}
