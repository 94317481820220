export enum FontTypes {
  PRIMARY = '--font-primary',
  SECONDARY = '--font-secondary',
  INTER = 'Inter',
  MERRIWEATHER = 'Merriweather',
  NUNITO = 'Nunito',
  OPEN_SANS = 'Open Sans',
  PLAYFAIR_DISPLAY = 'Playfair Display',
  RALEWAY = 'Raleway',
  ROBOTO = 'Roboto',
}
