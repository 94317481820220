import React, { useCallback, useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useElementDefinitions, useLanguage } from 'src/hooks'
import { CANVAS_ITEM_TYPE } from 'src/components/canvas'
import { IBaseElement } from './types'
import { baseElementStyles } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { ComponentServices } from 'src/services'
import { ComponentSchema } from 'src/types/api/responseObjects'
import {
  RootState,
  SAVE_STATE,
  addClickCreateList,
  componentCreate,
  setSaveState,
} from 'src/store'
import {
  ComponentFormDataSchema,
  // ComponentFormDataSchema,
  ComponentMediaDataSchema,
  NewComponentSchema,
} from 'src/types/api/requestObjects'
import { ELEMENT_DEFINITION } from '../../types'
import { ComponentTypes } from 'src/types/api/enums'

export const BaseElement: React.FC<IBaseElement> = React.memo(
  ({ definition, svgUrl, disabled }) => {
    const { lang } = useLanguage()
    const dispatch = useDispatch()
    const { definitions } = useElementDefinitions()

    const { clickCreateCount } = useSelector(({ canvas }: RootState) => ({
      clickCreateCount: canvas.clickCreateList.length,
    }))
    const definitionData = useMemo(() => {
      return definitions[definition as ELEMENT_DEFINITION]
    }, [definition, lang])

    const Component = useMemo(
      () => definitionData?.display.Component,
      [definitionData],
    )

    const definitionSchema = useMemo(
      () =>
        definitionData.schemas.map((schema) => {
          const alteredData = JSON.parse(JSON.stringify(schema))

          if (svgUrl && schema.type === ComponentTypes.MEDIA) {
            ;(alteredData.data as ComponentMediaDataSchema)!.mediaUrl = svgUrl
          }

          if (schema.type === ComponentTypes.FORM) {
            ;(alteredData.data as ComponentFormDataSchema).formId = uuidv4()
            ;(alteredData.data as ComponentFormDataSchema).questions = (
              alteredData.data as ComponentFormDataSchema
            ).questions.map((question) => ({
              ...question,
              questionId: uuidv4(),
            }))
          }

          return alteredData
        }),
      [definitionData.schemas, svgUrl],
    )

    const [{ isDragging }, drag, preview] = useDrag(() => {
      return {
        type: CANVAS_ITEM_TYPE.SIDEBAR_ITEM,
        item: definitionSchema,
        collect: (monitor: any) => ({
          isDragging: monitor.isDragging(),
        }),
      }
    }, [definitionSchema])

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true })
    }, [])

    const handleOnClick = useCallback(() => {
      if (disabled) {
        return
      }
      const newUuid = uuidv4()
      const createdComponent =
        ComponentServices.updateComponent<NewComponentSchema>({
          components: definitionSchema,
          partialUpdate: {
            positions: {
              x: (clickCreateCount + 1) * 50,
              y: (clickCreateCount + 1) * 50,
            },
            tempId: newUuid,
          },
        })

      dispatch(addClickCreateList(newUuid))
      dispatch(componentCreate(createdComponent as ComponentSchema[]))
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }, [definitionData, clickCreateCount, svgUrl, disabled])

    return (
      Component && (
        <Component
          ref={drag}
          css={baseElementStyles({ isDragging, disabled })}
          onClick={handleOnClick}
          {...definitionData?.display}
          svgUrl={svgUrl}
        />
      )
    )
  },
)

BaseElement.displayName = 'BaseElement'
