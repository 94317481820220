import React from 'react'
import { useLanguage } from 'src/hooks'
import { BUTTON_THEME, Dialog, DIALOG_PADDING, DIALOG_THEME } from 'src/lib'
import { wrapperStyles } from './styles'
import inviteFormImage from 'src/assets/images/invite-form-image.png'
import { ICancelPromotionDialog } from './types'
import { PlanPeriods } from 'src/types/api/enums'

export const CancelPromotionDialog: React.FC<ICancelPromotionDialog> = ({
  onCancel,
  onApply,
  onClose,
  isLoading,
  period = PlanPeriods.MONTHLY,
}) => {
  const { t } = useLanguage()

  const tPeriod = period === PlanPeriods.MONTHLY ? 'monthly' : 'annually'
  return (
    <Dialog
      css={wrapperStyles}
      image={inviteFormImage}
      title={t('subscriptions.cancel_promo_modal.subtitle')}
      theme={DIALOG_THEME.LIGHT}
      text={[
        t('subscriptions.cancel_promo_modal.description', {
          period: t(`subscriptions.cancel_promo_modal.period_${tPeriod}`),
          discount: t(`subscriptions.cancel_promo_modal.discount_${tPeriod}`),
        }),
      ]}
      submitLabel={t('subscriptions.cancel_promo_modal.apply')}
      submitTheme={BUTTON_THEME.GRADIENT}
      cancelTheme={BUTTON_THEME.ELEMENTS}
      cancelLabel={t('subscriptions.cancel_promo_modal.cancel')}
      onSubmit={onApply}
      onCancel={onCancel}
      onCloseClick={onClose}
      titleClassName="title"
      textClassName="text"
      padding={DIALOG_PADDING.BIG}
      isLoading={isLoading}
    />
  )
}
