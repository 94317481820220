import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import { APP_CONFIG } from 'src/config'
import 'dayjs/locale/tr'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/pt'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'

dayjs.extend(utc)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

dayjs.locale(localStorage.getItem('currentLanguage') || 'en')

export const now = () => dayjs().toISOString()

export const isExpired = (time: dayjs.Dayjs | string): boolean => {
  return dayjs().isSameOrAfter(dayjs(time))
}

export const isTokenExpired = (time: dayjs.Dayjs | string): boolean => {
  return isExpired(dayjs(time).add(APP_CONFIG.authTokenExpireTime, 'second'))
}

export const formatDate = (
  date?: Date | dayjs.Dayjs | string | null,
): string => {
  return date ? dayjs(date).format('MMM D, YYYY') : ''
}

export const formatDateLongerMonth = (
  date?: Date | dayjs.Dayjs | string | null,
): string => {
  return date ? dayjs(date).format('DD MMMM YYYY') : ''
}

export const formatDateTime = (
  date?: Date | dayjs.Dayjs | string | null,
): string => {
  return date ? dayjs(date).format('DD MMM YYYY h:mm A') : ''
}

export const getEpsilon = (date1: Date, date2: Date) => {
  return dayjs(date1).unix() - dayjs(date2).unix()
}

export { dayjs }
