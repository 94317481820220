import { css } from '@emotion/react'
import { COLLABORATOR_TYPE, ICollaborator } from './types'
import { ITheme } from 'src/theme'

type IWrapperStyles = Pick<ICollaborator, 'collaboratorType'>

export const wrapperStyles =
  ({ collaboratorType }: IWrapperStyles) =>
  (theme: ITheme) => css`
    width: ${collaboratorType === COLLABORATOR_TYPE.SMALL
      ? 'calc(50% - 6px)'
      : '100%'};
    height: 38px;
    background-color: ${theme.colors.background.DEFAULT};
    position: relative;
    padding-left: ${theme.spacingV2['xsmall']};
    margin-bottom: ${theme.spacingV2['2xsmall']};
    display: flex;
    justify-content: start;
    align-items: center;
    border-radius: ${theme.borderRadius[4]};
  `
export const spanStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.xsmall};
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const menuStyles =
  ({ collaboratorType }: IWrapperStyles) =>
  (theme: ITheme) => css`
    position: absolute;
    margin-top: revert;

    top: 10px;
    @media (min-width: ${theme.breakpointsV2.small}px) {
      width: 119px;
      right: ${collaboratorType === COLLABORATOR_TYPE.SMALL ? 'auto' : '30px'};
    }
    @media (min-width: ${theme.breakpointsV2.tablet}px) {
      right: ${collaboratorType === COLLABORATOR_TYPE.SMALL ? '0' : '30px'};
    }
  `

export const avatarStyles = (theme: ITheme) => css`
  margin-right: ${theme.spacingV2['xsmall']};
`

export const collaboratorPermissionStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.xsmall};
  width: inherit;
  font-weight: ${theme.fontWeight.medium};
`

export const collaboratorPermissionOwnerStyles = ({
  fontSize,
  fontWeight,
  gap,
}: ITheme) => css`
  font-size: ${fontSize.xsmall};
  width: inherit;
  font-weight: ${fontWeight.medium};
  padding-right: ${gap['xsmall']}px;
`

export const editStyles = css`
  width: max-content;
  display: flex;
  height: 100%;
  align-items: center;
`

export const iconStyles = (theme: ITheme) => css`
  justify-content: end;
  margin-top: -3px;
  margin-left: ${theme.spacingV2['3xsmall']};
`
