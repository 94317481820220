import React, { useEffect, useMemo } from 'react'

import { ICreateWithAiForm } from './types'
import { closeButtonAreaStyles, titleStyles, wrapperStyles } from './styles'

import {
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  Icon,
  TOOLTIP_THEME,
  Tooltip,
  icons,
} from 'src/lib'
import { useLanguage } from 'src/hooks'
import { colors } from 'src/theme'
import {
  AI_FLOW_STEP,
  RootState,
  setAiFlowId,
  setAim,
  setAudience,
  setCategory,
  setOutline,
  setOutlineParams,
  setPrompt,
  setPromptId,
  setTheme,
  setUploadedFiles,
} from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  AiTemplates,
  AiOutline,
  AiAudience,
  AiAim,
  AiPrompt,
  FileImport,
} from 'src/components/ai-modals'
import { v4 as uuidv4 } from 'uuid'
import { AiCategory } from '../ai-modals/ai-category'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'

export const CreateWithAiForm: React.FC<ICreateWithAiForm> = React.memo(
  ({ className, dataAttr, onCloseClick: onCloseForm }) => {
    const dispatch = useDispatch()
    const { t } = useLanguage()
    const { deleteAiFlowFiles } = useAiFlowApi()

    const { aiFlowStep, uploadedFiles } = useSelector(
      ({ aiFlow }: RootState) => ({
        aiFlowStep: aiFlow.currentStep,
        uploadedFiles: aiFlow.uploadedFiles,
      }),
    )

    useEffect(() => {
      const uuid = uuidv4()
      dispatch(setAiFlowId(uuid))
      sessionStorage.setItem('aiFlowId', uuid)
    }, [])

    const clearPreviousData = () => {
      dispatch(setPrompt(''))
      dispatch(setPromptId(0))
      dispatch(setAudience(''))
      dispatch(setAim(''))
      dispatch(setOutline([]))
      dispatch(setOutlineParams(null))
      dispatch(setUploadedFiles([]))
      dispatch(setCategory(null))
      dispatch(setTheme('0'))
    }

    const onCloseClick = () => {
      if (
        aiFlowStep &&
        uploadedFiles.length > 0 &&
        ![
          AI_FLOW_STEP.LOADING,
          AI_FLOW_STEP.COMPLETED,
          AI_FLOW_STEP.ERRORED,
        ].includes(aiFlowStep)
      ) {
        deleteAiFlowFiles({
          fileIds: uploadedFiles.map((file) => file.id || ''),
        })
      }
      clearPreviousData()
      localStorage.removeItem('saved-ai-flow')
      onCloseForm?.()
    }

    const selectTopic = useMemo(() => {
      switch (aiFlowStep) {
        case AI_FLOW_STEP.PROMPT:
          return t('create_with_ai.prompt_title')
        case AI_FLOW_STEP.CATEGORY:
          return t('create_with_ai.category_title')
        case AI_FLOW_STEP.AUDIENCE:
          return t('create_with_ai.audience_title')
        case AI_FLOW_STEP.AIM:
          return t('create_with_ai.aim_title')
        case AI_FLOW_STEP.OUTLINE:
          return t('create_with_ai.outline_title')
        case AI_FLOW_STEP.THEME:
          return t('create_with_ai.theme_title')
        case AI_FLOW_STEP.IMPORT:
          return t('file_import.import_file_title')
        case AI_FLOW_STEP.LOADING:
        case AI_FLOW_STEP.ERRORED:
        case AI_FLOW_STEP.COMPLETED:
          return ''
        default:
          return t('create_with_ai.create_with_ai')
      }
    }, [aiFlowStep])

    const selectTopicTooltip = useMemo(() => {
      switch (aiFlowStep) {
        case AI_FLOW_STEP.IMPORT:
          return t('file_import.import_file_info')
        default:
          return undefined
      }
    }, [aiFlowStep])

    const getPage = useMemo(() => {
      switch (aiFlowStep) {
        case AI_FLOW_STEP.PROMPT:
          return <AiPrompt />
        case AI_FLOW_STEP.CATEGORY:
          return <AiCategory />
        case AI_FLOW_STEP.AUDIENCE:
          return <AiAudience />
        case AI_FLOW_STEP.AIM:
          return <AiAim />
        case AI_FLOW_STEP.OUTLINE:
          return <AiOutline />
        case AI_FLOW_STEP.THEME:
        case AI_FLOW_STEP.LOADING:
        case AI_FLOW_STEP.ERRORED:
        case AI_FLOW_STEP.COMPLETED:
          return <AiTemplates onClose={onCloseClick} />
        default:
          return <FileImport />
      }
    }, [aiFlowStep])

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div css={closeButtonAreaStyles}>
          <Button
            icon={icons.close}
            theme={BUTTON_THEME.GRAY}
            type={BUTTON_TYPE.GHOST}
            isLink
            iconSize={20}
            onClick={onCloseClick}
          />
        </div>
        <div css={titleStyles(aiFlowStep == AI_FLOW_STEP.MODAL)}>
          <span>{selectTopic}</span>
          {selectTopicTooltip && (
            <Tooltip
              text={selectTopicTooltip}
              className="tooltip"
              theme={TOOLTIP_THEME.DARK}
            >
              <div>
                <Icon
                  icon={icons.info}
                  size={16}
                  color={colors.black.DEFAULT}
                />
              </div>
            </Tooltip>
          )}
        </div>
        {!(aiFlowStep === AI_FLOW_STEP.MODAL) && getPage}
      </div>
    )
  },
)
