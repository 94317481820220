import { useState } from 'react'
import {
  postLoginBody,
  postPasswordResetBody,
  postPasswordResetEmailBody,
  postRegisterBody,
} from 'src/types/api/requestObjects'
import {
  postLoginResponse,
  postRegisterResponse,
} from 'src/types/api/responseObjects'
import { API_CONFIG } from 'src/config'
import { useAuthProvider } from 'src/provider'
import {
  RequestServices,
  RequestResponse,
  DateTimeServices,
} from 'src/services'
import { setAuthHeader } from 'src/services/requestServices'
import { sendEvent } from 'src/plugins/google/gtag'
import {
  GTAG_EVENTS,
  GTAG_EVENT_CATEGORIES,
  PROVIDER,
} from 'src/plugins/google/consts'

interface IUseAuthApi {
  googleLogin: (token: string) => void
  login: (data: postLoginBody) => Promise<void>
  signUp: (data: postRegisterBody) => Promise<boolean>
  sendResetEmail: (data: postPasswordResetEmailBody) => Promise<void>
  resetPassword: (data: postPasswordResetBody) => Promise<boolean>
  refreshToken: () => Promise<boolean>
  isLoading: boolean
}

export const useAuthApi = (): IUseAuthApi => {
  const { setToken, clearToken } = useAuthProvider()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const googleLogin = (token: string) => {
    setToken(token)
  }

  const login = async (data: postLoginBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postLoginResponse, any> =
        await RequestServices.request({
          method: 'POST',
          url: API_CONFIG.AUTH_LOGIN,
          data,
        })
      setToken(res.data.data.authToken)

      sendEvent(GTAG_EVENTS.SIGN_IN, {
        sign_in_method: PROVIDER.DECKTOPUS,
        event_category: GTAG_EVENT_CATEGORIES.ENGAGEMENT,
        user_email: data.email,
      })
    } catch {
      clearToken()
    } finally {
      setIsLoading(false)
    }
  }

  const refreshToken = async () => {
    setIsLoading(true)
    try {
      const res = await RequestServices.request({
        method: 'GET',
        url: API_CONFIG.AUTH_REFRESH,
      })
      const token = res.data.data.authToken
      localStorage.setItem('token', token)
      localStorage.setItem('tokenCreatedAt', DateTimeServices.now())
      setAuthHeader(token)
      return true
    } catch {
      clearToken()
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const signUp = async (data: postRegisterBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postRegisterResponse, any> =
        await RequestServices.request({
          method: 'POST',
          url: API_CONFIG.AUTH_SIGNUP,
          data,
        })
      setToken(res.data.data.authToken)

      sendEvent(GTAG_EVENTS.SIGN_UP, {
        signup_method: PROVIDER.DECKTOPUS,
        event_category: GTAG_EVENT_CATEGORIES.ENGAGEMENT,
        user_email: data.email,
      })
      return true
    } catch {
      clearToken()
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const sendResetEmail = async (data: postPasswordResetEmailBody) => {
    setIsLoading(true)
    try {
      await RequestServices.request({
        method: 'POST',
        url: API_CONFIG.AUTH_RESET_EMAIL,
        data,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const resetPassword = async (
    data: postPasswordResetBody,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.request({
        method: 'POST',
        url: API_CONFIG.AUTH_RESET,
        data,
      })

      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return {
    googleLogin,
    login,
    signUp,
    sendResetEmail,
    resetPassword,
    refreshToken,
    isLoading,
  }
}
