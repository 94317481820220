import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { IPolotnoComponent } from '../../types'
import { ImageElementType } from 'polotno/model/image-model'

const CropAction = observer<IPolotnoComponent>(({ store }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    const element = store.selectedElements[0] as ImageElementType
    element.toggleCropMode(true)
  }

  return (
    <BaseIconButton
      onClick={handleClick}
      sx={{
        padding: '6px',
      }}
    >
      <MaterialSymbol icon="crop" size={20} weight={300} />
    </BaseIconButton>
  )
})

export default CropAction
