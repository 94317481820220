import React, { useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { mediaPropsStyles } from './styles'
import { PropHeader } from 'src/pages/deck-page/components/edit-properties/props'
import { ZoomTypes } from './ZoomTypes'
import { IEditPropSection } from '../../types'
import { MediaTypes } from 'src/types/api/enums'
import {
  ComponentMediaDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { IconFrame } from './IconFrame'
// import { PropButton } from '../prop-button'
// import { icons } from 'src/lib'
// import { ZoomLevel } from './ZoomLevel'
export const MediaProps: React.FC<IEditPropSection> = React.memo(
  ({ components, className, dataAttr }) => {
    const { t } = useLanguage()

    const selectedMediaTypes = useMemo(
      () =>
        components?.reduce(
          (a: MediaTypes | undefined | null, c: UpdateComponentSchema) => {
            const cType = (c.data as ComponentMediaDataSchema).mediaType
            if (a === undefined && cType) {
              return cType
            }

            return cType !== a ? null : a
          },
          undefined,
        ),
      [components],
    )

    return (
      <div css={mediaPropsStyles} className={className} {...dataAttr}>
        {/* <div className="grid">
          <div className="c-1">
            <ZoomLevel components={components} />
          </div>
          <div className="c-1">
            <PropButton icon={icons.radius} value={44} />
          </div>
          <div className="c-1">
            <PropButton icon={icons.retry} value={180} suffix="°" />
          </div>
          <div className="c-1">
            <PropButton icon={icons.eye} value={50} suffix="%" />
          </div>
        </div> */}
        {(selectedMediaTypes === MediaTypes.IMAGE ||
          selectedMediaTypes === MediaTypes.GIF) && (
          <>
            <PropHeader text={t('edit.properties.media.image_props')} />
            <ZoomTypes components={components} />
          </>
        )}

        {selectedMediaTypes === MediaTypes.ICON && (
          <IconFrame components={components} />
        )}
      </div>
    )
  },
)

MediaProps.displayName = 'MediaProps'
