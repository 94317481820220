import React, { useCallback, useMemo } from 'react'

import { mediaButtonsStyles } from './styles'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { MediaTypes } from 'src/types/api/enums'
import {
  ComponentMediaDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import {
  PropMedia,
  PropAiImage,
} from 'src/pages/deck-page/components/edit-properties/props'
import { IEditPropSection } from '../../types'
import { useLanguage } from 'src/hooks'

export const MediaButtons: React.FC<IEditPropSection> = React.memo(
  ({ components, className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedMediaType = useMemo(
      () =>
        components?.reduce(
          (a: MediaTypes | undefined, c: UpdateComponentSchema) => {
            const mediaType = (c.data as ComponentMediaDataSchema).mediaType

            if (a === undefined && mediaType) {
              return mediaType
            }

            return mediaType !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const onMediaChange = useCallback(
      (mediaType: MediaTypes, mediaUrl: string) => {
        const partialData: DeepPartial<ComponentMediaDataSchema> = {
          mediaUrl,
          mediaType,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentMediaDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const onAiMediaChange = useCallback(
      (url: string) => {
        onMediaChange(MediaTypes.IMAGE, url)
      },
      [onMediaChange],
    )

    return (
      <div css={mediaButtonsStyles} className={className} {...dataAttr}>
        <div className="grid">
          <div
            className={
              selectedMediaType === MediaTypes.IMAGE ? 'c-2 c-t-full' : 'c-full'
            }
          >
            <PropMedia
              label={t('edit.media.modal.search_media', {
                type: t('common.files.media'),
              })}
              selectedType={selectedMediaType}
              availableTypes={[
                MediaTypes.IMAGE,
                MediaTypes.GIF,
                MediaTypes.ICON,
              ]}
              directUpload={selectedMediaType === MediaTypes.LOGO}
              onChange={onMediaChange}
            />
          </div>
          {selectedMediaType === MediaTypes.IMAGE && (
            <div className="c-2 c-t-full">
              <PropAiImage onChange={onAiMediaChange} />
            </div>
          )}
        </div>
      </div>
    )
  },
)

MediaButtons.displayName = 'MediaButtons'
