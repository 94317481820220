import React, { useCallback, useMemo } from 'react'

import { IEditPropSection } from '../../types'
import { PropButton, PropSection } from '../../props'
import { useLanguage } from 'src/hooks'
import { icons } from 'src/lib'
import { Sides } from 'src/types/api/enums'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'

export const ListMediaPosition: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedMediaPlace = useMemo(
      () =>
        components?.reduce((a: Sides | undefined, c: UpdateComponentSchema) => {
          const targetField = (c.data as ComponentListDataSchema).style.layout
            .mediaPlace

          if (a === undefined && targetField) {
            return targetField
          }

          return targetField !== a ? undefined : a
        }, undefined),
      [components],
    )

    const handleMediaPlaceChange = useCallback(
      (mediaPlace?: Sides) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: {
            layout: {
              mediaPlace,
            },
          },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <PropSection
        title={t('edit.properties.list.media_position')}
        initialState={false}
        expandable
      >
        <div className="grid">
          <div className="c-1">
            <PropButton
              icon={icons.media_top}
              onClick={() => handleMediaPlaceChange(Sides.TOP)}
              isFocus={selectedMediaPlace === Sides.TOP}
            />
          </div>
          <div className="c-1">
            <PropButton
              icon={icons.media_side}
              onClick={() => handleMediaPlaceChange(Sides.LEFT)}
              isFocus={selectedMediaPlace === Sides.LEFT}
            />
          </div>
        </div>
      </PropSection>
    )
  },
)

ListMediaPosition.displayName = 'ListMediaPosition'
