import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  height: 60px;
  width: 100%;
  background: ${theme.colors.white.DEFAULT};
`

export const innerStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: space-between;
  padding: 0 ${theme.gap['2xlarge']}px;
  height: 60px;
`

export const buttonStyles = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
