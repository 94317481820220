import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.gradient.DEFAULT};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
