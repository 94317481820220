import React, { useEffect, useState } from 'react'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno'
import { handleHotkey } from 'polotno/canvas/hotkeys'
import { Tooltip } from 'polotno/canvas/tooltip'
import { Workspace } from 'polotno/canvas/workspace'
import { StoreType } from 'polotno/model/store'
import { SidePanel } from 'polotno/side-panel'
import { Toolbar } from 'polotno/toolbar/toolbar'
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons'
import { useDispatch, useSelector } from 'react-redux'
import useTheme from '@mui/material/styles/useTheme'
import { useParams } from 'react-router-dom'
import sparkleIcon from '../../lib/icon/svg/sparkle.svg'

import '@blueprintjs/core/lib/css/blueprint.css'
import { NoPages } from '../deck-page-v3/components'
import './components/elements/html-elements/button/Button'

import { MaterialSymbol } from 'react-material-symbols'

import { useLanguage } from 'src/hooks'
import { Expander, EXPANDER_DIR, EXPANDER_THEME } from 'src/lib'
import { setSaveNow } from 'src/store/decks-v3'
import { PolotnoStore } from 'src/utils/PolotnoStore'
import AiAssistantPanel from './components/panels/AiAssistantPanel'
import ElementsPanel from './components/panels/ElementsPanel'
import MediaPanel from './components/panels/MediaPanel'
import MediaSearchPanel from './components/panels/MediaSearchPanel'
import NotesPanel from './components/panels/NotesPanel'
import SizePanel from './components/panels/SizePanel'
import StoragePanel from './components/panels/StoragePanel'
import AutoScrollArea from './components/scroll-area'
import StoreListener from './components/store/StoreListener'
import StoreLoader from './components/store/StoreLoader'
import Timeline from './components/timeline'
import ApplyMaskAction from './components/toolbar/ApplyMaskAction'
import ColorPickerElement from './components/toolbar/ColorPickerElement'
import CropAction from './components/toolbar/CropAction'
import DeleteAction from './components/toolbar/DeleteAction'
import DuplicateAction from './components/toolbar/DuplicateAction'
import EffectsAction from './components/toolbar/EffectsAction'
import ElementFillActions from './components/toolbar/ElementFillActions'
import FitToPageAction from './components/toolbar/FitToPageAction'
import FlipAction from './components/toolbar/FlipAction'
import GroupAction from './components/toolbar/GroupAction'
import HistoryActions from './components/toolbar/HistoryActions'
import LineEdgesAction from './components/toolbar/LineEdgesAction'
import LockAction from './components/toolbar/LockAction'
import PageControls from './components/toolbar/PageControls'
import PositionAction from './components/toolbar/PositionAction'
import SizePanelTriggerAction from './components/toolbar/SizePanelTriggerAction'
import StrokeSettingsAction from './components/toolbar/StrokeSettingsAction'
import SvgColorsAction from './components/toolbar/SvgColorsAction'
import TextFillActions from './components/toolbar/TextFillActions'
import TextFontSizeAction from './components/toolbar/TextFontSizeAction'
import TextFontVariantActions from './components/toolbar/TextFontVariantActions'
import TextSpacingAction from './components/toolbar/TextSpacingAction'
import TransparencyActionForRightPane from './components/toolbar/TransparencyActionForRightPane'
import VideoTrimAction from './components/toolbar/VideoTrimAction'
import VolumeAction from './components/toolbar/VolumeAction'
import './config/imageUpload'
import BaseSection from './sections/BaseSection'
import './styles/polotno-override.css'
import './styles/scrollarea.css'
import { useStyles } from './styles/styles'
import TextAIAction from './components/toolbar/TextAIAction'
import AddSlideDrawer from './components/add-slide-drawer'
import { RootState } from 'src/store'

export const { store } = new PolotnoStore()

const sections = [
  // BaseSection({
  //   name: 'Design',
  //   labelIdentifier: 'common.design',
  //   Icon: <MaterialSymbol icon="palette" size={24} weight={300} />,
  //   Panel: observer(({ store }: { store: StoreType }) => {
  //     return (
  //       <div>
  //         <p>Side panel {store.openedSidePanel}</p>
  //       </div>
  //     )
  //   }),
  // }),
  BaseSection({
    name: 'Elements',
    labelIdentifier: 'edit.panel.elements',
    Icon: <MaterialSymbol icon="interests" size={24} weight={300} />,
    Panel: ElementsPanel,
  }),
  BaseSection({
    name: 'Media',
    labelIdentifier: 'common.files.media',
    Icon: <MaterialSymbol icon="imagesmode" size={24} weight={300} />,
    Panel: MediaPanel,
  }),
  BaseSection({
    name: 'Storage',
    labelIdentifier: 'edit.panel.storage',
    Icon: <MaterialSymbol icon="backup" size={24} weight={300} />,
    Panel: StoragePanel,
  }),
  BaseSection({
    name: 'Notes',
    labelIdentifier: 'edit.panel.notes',
    Icon: <MaterialSymbol icon="description" size={24} weight={300} />,
    Panel: NotesPanel,
  }),
  BaseSection({
    name: 'AI-Assistant',
    labelIdentifier: 'edit.panel.ai_assistant',
    Icon: <img src={sparkleIcon} alt="sparkle" />,
    Panel: AiAssistantPanel,
  }),
  BaseSection({
    name: 'image-search-panel',
    labelIdentifier: '',
    Icon: null,
    Panel: MediaSearchPanel,
    visibleInList: false,
  }),
  BaseSection({
    name: 'size-panel',
    labelIdentifier: '',
    Icon: null,
    Panel: SizePanel,
    visibleInList: false,
  }),
]

export const EditPageV3: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const theme = useTheme()
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const params = useParams()
  const deckId = Number(params.id ?? 0)
  const [isSlidesExpanded, setSlidesExpanded] = useState(true)
  const { showAddSlide } = useSelector(({ generalV3 }: RootState) => ({
    showAddSlide: generalV3.showAddSlide,
  }))

  const onKeyDown = (e: KeyboardEvent, store: StoreType) => {
    if (e.code === 'KeyS' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()
      dispatch(setSaveNow(true))
    } else {
      handleHotkey(e, store)
    }
  }

  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        const element = mutationRecord.target as HTMLElement
        element.style?.setProperty('overflow', null)
      })
    })
    const elements = document.getElementsByClassName('polotno-workspace-inner')
    if (elements && elements.length > 0) {
      const arr = Array.from(elements) as HTMLElement[]
      arr.forEach((element: HTMLElement) => {
        element.classList.add('decktopus-workspace-inner')
        const target = element
        target.style?.setProperty('overflow', null)
        observer.observe(target, {
          attributes: true,
          attributeFilter: ['style'],
        })
      })
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <PolotnoContainer
      style={{ display: 'flex', width: '100vw', height: '100%' }}
    >
      <StoreLoader store={store} deckId={deckId} />
      <StoreListener store={store} deckId={deckId} />
      <SidePanelWrap>
        <SidePanel
          store={store}
          sections={sections}
          defaultSection="Elements"
        />
      </SidePanelWrap>
      <WorkspaceWrap className={classes.workspaceWrapper}>
        <Toolbar
          store={store}
          components={{
            ImageRemoveBackground: () => null,
            History: HistoryActions,
            TextFill: TextFillActions,
            TextFontSize: TextFontSizeAction,
            TextFontVariant: TextFontVariantActions,
            TextSpacing: TextSpacingAction,
            TextFilters: EffectsAction,
            TextAiWrite: () => null,
            // right actions
            Group: GroupAction,
            Opacity: TransparencyActionForRightPane,
            Lock: LockAction,
            Position: () => null,
            Duplicate: () => null,
            Remove: () => null,
            // right actions
            LineSettings: StrokeSettingsAction,
            LineHeads: LineEdgesAction,
            LineColor: () => (
              <ElementFillActions store={store} types={['line']} />
            ),
            ManyColor: () => (
              <ElementFillActions store={store} types={['line', 'figure']} />
            ),
            ManySettings: () => <StrokeSettingsAction store={store} />,
            FigureFill: () => (
              <ElementFillActions store={store} types={['figure']} />
            ),
            FigureSettings: () => (
              <StrokeSettingsAction store={store} type="figure" />
            ),
            FigureStroke: () => (
              <ColorPickerElement
                store={store}
                prop="stroke"
                label={t('v3.stroke')}
              />
            ),
            FigureFilters: EffectsAction,
            ImageFilters: EffectsAction,
            ImageCrop: CropAction,
            ImageFlip: FlipAction,
            ImageFitToBackground: FitToPageAction,
            ImageClip: ApplyMaskAction,
            SvgFlip: FlipAction,
            SvgFilters: EffectsAction,
            SvgColors: SvgColorsAction,
            VideoTrim: VideoTrimAction,
            VideoClip: ApplyMaskAction,
            VideoVolume: VolumeAction,
            TextSize: SizePanelTriggerAction,
            FigureSize: SizePanelTriggerAction,
            VideoSize: SizePanelTriggerAction,
            ImageSize: SizePanelTriggerAction,
            SvgSize: SizePanelTriggerAction,
          }}
        />
        <AutoScrollArea>
          <Workspace
            store={store}
            components={{
              NoPages,
              Tooltip,
              Duplicate: DuplicateAction,
              Position: PositionAction,
              Remove: DeleteAction,
              PageControls: PageControls,
              TextAiWrite: TextAIAction,
            }}
            backgroundColor={theme.palette.elevation?.outlined}
            renderOnlyActivePage
            onKeyDown={onKeyDown}
            activePageBorderColor={theme.palette.primary.main}
          />
        </AutoScrollArea>
        <Expander
          theme={EXPANDER_THEME.LIGHT}
          dir={EXPANDER_DIR.TOP}
          expanded={isSlidesExpanded}
          onStateChange={setSlidesExpanded}
          props={['height', ['0px', '120px']]}
          outerChildren={
            <div className={classes.zoomButtons}>
              <ZoomButtons store={store} />
            </div>
          }
          expander={
            <MaterialSymbol
              icon={
                isSlidesExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
              }
              size={20}
              weight={300}
            />
          }
        >
          <Timeline store={store} />
        </Expander>
        <AddSlideDrawer store={store} open={showAddSlide ?? false} />
      </WorkspaceWrap>
    </PolotnoContainer>
  )
})
