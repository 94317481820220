import React, { useCallback, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { IFormElement } from './types'
import { checklistStyles } from './styles'
import {
  optionLabelStyles,
  checklistItemStyles,
  checkboxWrapperStyles,
} from './styles'

export const FormOption: React.FC<IFormElement> = React.memo(
  ({ question, formDTO, formDTOHandler }) => {
    const answerArray = useMemo(() => {
      return formDTO?.data[question.questionId] as string[]
    }, [formDTO?.data, question.questionId])

    const handleChoiceSelection = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, choice: string) => {
        const answer = e.target.checked
          ? [...(answerArray || []), choice]
          : [...answerArray].filter((ch) => ch !== choice)

        if (formDTO && answer.length) {
          formDTOHandler(
            {
              id: formDTO.id,
              state: formDTO.state,
              data: {
                ...formDTO?.data,
                [question.questionId]: answer,
              },
            },
            question.questionId,
          )
        }
      },
      [formDTO, formDTOHandler, question.questionId, answerArray],
    )

    return (
      <div css={checklistStyles}>
        {question.options?.choices.map((choice, index) => (
          <div css={checklistItemStyles} key={uuidv4()}>
            <div css={checkboxWrapperStyles}>
              <input
                id={`${index}`}
                type="checkbox"
                onChange={(e) => handleChoiceSelection(e, choice)}
                checked={(answerArray || []).includes(choice)}
              />
            </div>
            <label htmlFor={`${index}`} css={optionLabelStyles}>
              {choice}
            </label>
          </div>
        ))}
      </div>
    )
  },
)
