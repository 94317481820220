import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import watermark from 'src/assets/images/watermark.svg'
import { Canvas, CANVAS_DATA_TYPE, CANVAS_TYPE } from 'src/components/canvas'
import { VoiceRecording } from 'src/components/voice-recording'
import { DECK_PAGE_MODE } from 'src/layouts/deck-page-layout/types'
import { Icon, icons, Spinner } from 'src/lib'
import { RootState } from 'src/store'
import { colors } from 'src/theme'
import { IPolotnoComponent } from '../deck-page-v3/types'
import { EditSlides } from './components'
import { FontImporter } from './components/font-importer'
import { lockPageElements } from './helpers/previewV3helpers'
import { PreviewForV3 } from './PreviewForV3'
import {
  footerStyles,
  headerStyles,
  previewPageWrapperStyles,
  slideNavigations,
  slideStyles,
} from './styles'
import { IPreviewPage } from './types'

interface IPreviewPageProps extends IPreviewPage, IPolotnoComponent {
  isV3?: boolean
}

export const PreviewPage = observer<IPreviewPageProps>(
  ({ store, mode, isV3 }) => {
    const isExportMode = useMemo(() => mode === DECK_PAGE_MODE.EXPORT, [])
    const params = useParams()
    const navigate = useNavigate()

    const [isFullScreen, setIsFullScreen] = useState(false)
    const [activeSlide, setActiveSlide] = useState<number | ''>(
      params.slide ? parseInt(params.slide) : 1,
    )

    const [loadedSlides, setLoadedSlides] = useState<number[]>([])

    const {
      activeSlides,
      deckName,
      soundUrl,
      isNextSlideBlocked,
      deckOrganizationId,
    } = useSelector(({ edit, canvas }: RootState) => ({
      activeSlides: edit.activeDeck.data?.deckData?.data.slides,
      deckName: edit.activeDeck.data?.deck?.name,
      soundUrl: edit.activeDeck.data?.deckData?.data.slides.find(
        ({ slideId }) => slideId === edit.activeSlideID,
      )?.soundUrl,
      isNextSlideBlocked: canvas.isNextSlideBlocked,
      deckOrganizationId: edit.activeDeck.data?.deck?.organizationId,
    }))

    const maxSlide = useMemo(() => {
      if (isV3) {
        return store.pages.length
      } else {
        return activeSlides?.filter(({ isDeleted }) => !isDeleted)?.length || 0
      }
    }, [activeSlides, isV3, store.pages.length])

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetNumber =
          e.currentTarget.value === '' ? '' : parseInt(e.currentTarget.value)
        setActiveSlide(targetNumber)
      },
      [],
    )

    const locationContext = useMemo(() => {
      const contextArray = location.pathname.split('/')
      return contextArray[1]
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const handleInputKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          const targetSlide =
            activeSlide === ''
              ? 1
              : activeSlide > maxSlide
                ? maxSlide
                : activeSlide
          setActiveSlide(targetSlide)
          navigate(`/${locationContext}/${params.id}/${targetSlide}`)
        }
      },
      [activeSlide, locationContext, maxSlide, navigate, params.id],
    )

    const onInputClickHandler = useCallback(
      (e: React.MouseEvent<HTMLInputElement>) => {
        e.currentTarget.select()
      },
      [],
    )

    useEffect(() => {
      params.slide && setActiveSlide(parseInt(params.slide))
    }, [params.slide])

    const handleFullscreen = useCallback(() => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        }
      }
      setIsFullScreen(!isFullScreen)
    }, [isFullScreen])

    const handleSlideNavigation = useCallback(
      (param: 'decrement' | 'increment') => {
        if (param === 'decrement' && Number(activeSlide) > 1) {
          setActiveSlide(Number(activeSlide) - 1)
          navigate(
            `/${locationContext}/${params.id}/${Number(activeSlide) - 1}`,
          )
        } else {
          if (!isNextSlideBlocked) {
            setActiveSlide(Number(activeSlide) + 1)
            navigate(
              `/${locationContext}/${params.id}/${Number(activeSlide) + 1}`,
            )
          }
        }
      },
      [activeSlide, navigate, locationContext, params.id, isNextSlideBlocked],
    )

    const fullScreenChangeHandler = useCallback(() => {
      document.fullscreenElement
        ? setIsFullScreen(true)
        : setIsFullScreen(false)
    }, [])

    useEffect(() => {
      document.addEventListener('fullscreenchange', fullScreenChangeHandler)

      return () => {
        document.removeEventListener(
          'fullscreenchange',
          fullScreenChangeHandler,
        )
      }
    }, [fullScreenChangeHandler])

    const showWatermark = useMemo(
      () => !deckOrganizationId,
      [deckOrganizationId],
    )

    useEffect(() => {
      let timer: NodeJS.Timer
      async function waitForSlide(nextPageId: string, index: number) {
        const noNeedToWait = loadedSlides.includes(index)
        if (!noNeedToWait) {
          nextPageId && store.selectPage(nextPageId)
          await store.waitLoading()
          lockPageElements(store, nextPageId)
          setLoadedSlides([...loadedSlides, index])
        } else {
          nextPageId && store.selectPage(nextPageId)
        }
      }
      if (store.pages.length > 0) {
        const index = store.pages.findIndex(
          (page) => page.id === store.activePage?.id,
        )
        const slideNo =
          typeof activeSlide === 'number' ? activeSlide : parseInt(activeSlide)

        if (index + 1 !== slideNo || (slideNo === 1 && index === 0)) {
          const nextPageId = store.pages[slideNo - 1]?.id
          nextPageId && waitForSlide(nextPageId, slideNo - 1)
        }
      }

      return () => timer && clearTimeout(timer)
    }, [activeSlide, loadedSlides, store, store.pages.length])

    if (!maxSlide && !isV3) {
      return null
    }

    const slideNo =
      typeof activeSlide === 'number' ? activeSlide : parseInt(activeSlide)
    const noNeedToWait = loadedSlides.includes(slideNo - 1)

    return (
      <div css={previewPageWrapperStyles}>
        <div css={headerStyles({ isFullScreen, isExportMode })}>
          <div className="deck-name">{deckName}</div>
          <div className="voice">
            {soundUrl && <VoiceRecording audioUrl={soundUrl} noUpload={true} />}
          </div>
        </div>
        <div css={slideStyles({ isFullScreen, isExportMode })}>
          {activeSlide && activeSlide > 1 && (
            <div
              css={slideNavigations({
                direction: 'left',
                isFullScreen,
                isExportMode,
              })}
              className="slide-navigation-area"
              onClick={() => handleSlideNavigation('decrement')}
            >
              <div className="navigation-area">
                <Icon icon={icons.chevron_left} color={colors.white.DEFAULT} />
              </div>
            </div>
          )}
          {!isV3 ? (
            <>
              <Canvas
                canvasType={CANVAS_TYPE.PREVIEW}
                dataType={CANVAS_DATA_TYPE.ACTIVE_SLIDE}
              />
              <EditSlides
                canvasType={CANVAS_TYPE.PREVIEW}
                className="preview-edit-slides-styles"
              />
            </>
          ) : (
            <>
              {!noNeedToWait ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Spinner size={128} />
                </div>
              ) : (
                <PreviewForV3 store={store} />
              )}
            </>
          )}
          {activeSlide && activeSlide < maxSlide && (
            <div
              css={slideNavigations({
                direction: 'right',
                isFullScreen,
                isExportMode,
              })}
              className="slide-navigation-area"
              onClick={() => handleSlideNavigation('increment')}
            >
              <div className="navigation-area">
                <Icon icon={icons.chevron_right} color={colors.white.DEFAULT} />
              </div>
            </div>
          )}
        </div>
        <div css={footerStyles({ isFullScreen, isExportMode })}>
          <div className="watermark">
            {showWatermark && (
              <img
                src={watermark}
                onClick={() =>
                  window.location.replace('https://www.decktopus.com/')
                }
              />
            )}
          </div>
          <div className="slide-control">
            <input
              value={activeSlide}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              onClick={onInputClickHandler}
            />
            <span>/</span>
            <div className="max-slides">{maxSlide}</div>
          </div>
          <div className="icon-wrapper" onClick={() => handleFullscreen()}>
            <Icon
              icon={isFullScreen ? icons.exit_fullscreen : icons.fullscreen}
              color={colors.white.DEFAULT}
            />
          </div>
        </div>
        <FontImporter />
      </div>
    )
  },
)
