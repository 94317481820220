import React, { useMemo } from 'react'

import { IMedia } from './types'
import { iconStyles } from './styles'
import { ComponentMediaDataSchema } from 'src/types/api/requestObjects'
import { SvgMasked } from 'src/lib/svg-masked'
import { useGradientColor } from 'src/hooks'
import { ComponentRadiusTypes } from 'src/types/api/enums'

export const Icon: React.FC<IMedia> = React.memo(
  ({ data, className, dataAttr }) => {
    const componentData: ComponentMediaDataSchema = useMemo(
      () => data.data as ComponentMediaDataSchema,
      [data.data],
    )

    const { asBackground } = useGradientColor(
      componentData.style?.color.background,
    )

    const iconCss = useMemo(() => {
      if (componentData.style?.radiusType) {
        if (componentData.style.radiusType === ComponentRadiusTypes.OUTLINE) {
          return {
            border: `5em solid ${componentData.style.color.background?.colors[0]}`,
          }
        } else if (
          componentData.style.radiusType !== ComponentRadiusTypes.FLAT
        ) {
          return asBackground
        }
      }
    }, [componentData.style, asBackground])

    const mediaUrl = useMemo(() => componentData.mediaUrl, [componentData])

    const opacity = useMemo(() => data.style.opacity || 1, [data])

    return (
      <div
        css={iconStyles({ radiusType: componentData.style?.radiusType })}
        className={className}
        {...dataAttr}
        style={{
          ...iconCss,
          fontSize: `${(data.positions.width || 1) / 600}em`,
          opacity: opacity,
        }}
      >
        <SvgMasked
          url={mediaUrl}
          gradientColor={componentData.style?.color.text}
        />
      </div>
    )
  },
)

Icon.displayName = 'Icon'
