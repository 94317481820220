import React from 'react'

import { ISteps } from './types'
import { stepStyles, wrapperStyles } from './styles'
export const Steps: React.FC<ISteps> = React.memo(
  ({ steps, current, className, dataAttr }) => (
    <div css={wrapperStyles} className={className} {...dataAttr}>
      {[...Array(steps)].map((_item, index) => (
        <div css={stepStyles({ index, steps, current })} key={index} />
      ))}
    </div>
  ),
)
