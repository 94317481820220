import React, { useCallback } from 'react'
import useSWRInfinite from 'swr/infinite'

export const fetcher = (url: string) => fetch(url).then((r) => r.json())

type GET_FUNC = (params: { query: string; page: number }) => string

export const useInfiniteAPI = ({
  defaultQuery = '',
  timeout = 100,
  getAPI,
  getSize = (firstResult) => firstResult.total_pages,
  fetchFunc,
}: {
  defaultQuery?: string
  timeout?: number
  getAPI: GET_FUNC
  getSize?: (firstResult: any) => number
  fetchFunc?: (url: string, attrs?: any) => Promise<any>
}) => {
  const lastQuery = React.useRef(defaultQuery)
  const requestTimeout = React.useRef<any>()

  const { data, error, size, setSize, mutate } = useSWRInfinite(
    (index) => getAPI({ query: lastQuery.current, page: index + 1 }),
    fetchFunc ?? fetcher,
    { revalidateAll: false, revalidateFirstPage: false },
  )

  const isLoadingInitialData = !data && !error
  const isLoading =
    isLoadingInitialData ||
    !!(size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0]?.length === 0
  const isReachingEnd =
    isEmpty || (data && data[0] && getSize(data[0]) === size)

  const loadMore = useCallback(() => {
    if (!isReachingEnd) {
      setSize(size + 1)
    }
  }, [isReachingEnd, size, setSize])

  const setQuery = React.useCallback(
    (query: string) => {
      lastQuery.current = query
      clearTimeout(requestTimeout.current)
      requestTimeout.current = setTimeout(() => {
        mutate()
      }, timeout)
    },
    [mutate, timeout],
  )

  return {
    setQuery,
    isLoading,
    loadMore,
    isReachingEnd,
    data: data?.filter((d) => d),
    items: data?.reduce((acc, val) => acc.concat(val), []),
    hasMore: !isReachingEnd,
    reset: mutate,
    error,
  }
}
