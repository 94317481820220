import React, { useCallback, useMemo } from 'react'

import { IEditPropSection } from '../../types'
import { PropColor, PropSection } from '../../props'
import { useLanguage } from 'src/hooks'
import {
  ComponentListDataSchema,
  GradientLikeColorSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { ListItemImageTypes, ListTypes } from 'src/types/api/enums'

export const ListColors: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedType = useMemo(
      () =>
        components?.reduce(
          (a: ListTypes | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).listType
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedIconColorFieldName = useMemo(() => {
      let relatedColorField: 'colorIcon' | 'colorBullet' = 'colorIcon'
      switch (selectedType) {
        case ListTypes.BULLET:
          relatedColorField = 'colorBullet'
          break
        case ListTypes.NUMBER:
          relatedColorField = 'colorBullet'
          break
        case ListTypes.TIMELINE:
          relatedColorField = 'colorBullet'
          break
        default:
          relatedColorField = 'colorIcon'
      }

      return relatedColorField
    }, [selectedType])

    const selectedMediaType = useMemo(
      () =>
        components?.reduce(
          (a: ListItemImageTypes | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).listItems[0]
              .image?.type
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedColor = useMemo(
      () =>
        components?.reduce(
          (
            a: GradientLikeColorSchema | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentListDataSchema)?.style
              ?.color?.text
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedBodyColor = useMemo(
      () =>
        components?.reduce(
          (
            a: GradientLikeColorSchema | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentListDataSchema)?.style
              ?.colorBody?.text
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedIconColor = useMemo(
      () =>
        components?.reduce(
          (
            a: GradientLikeColorSchema | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentListDataSchema)?.style[
              selectedIconColorFieldName
            ]?.text
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components, selectedIconColorFieldName],
    )

    const selectedIconFrameColor = useMemo(
      () =>
        components?.reduce(
          (
            a: GradientLikeColorSchema | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentListDataSchema)?.style
              ?.colorIcon?.background
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleOnColorChange = useCallback(
      (newColor: GradientLikeColorSchema) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: { color: { text: newColor } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const handleOnBodyColorChange = useCallback(
      (newColor: GradientLikeColorSchema) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: { colorBody: { text: newColor } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const handleOnIconColorChange = useCallback(
      (newColor: GradientLikeColorSchema) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: { [selectedIconColorFieldName]: { text: newColor } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedIconColorFieldName],
    )

    const handleOnIconFrameColorChange = useCallback(
      (newColor: GradientLikeColorSchema) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: { colorIcon: { background: newColor } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const displayIconColor = useMemo(
      () =>
        !(
          selectedMediaType === ListItemImageTypes.IMAGE ||
          selectedMediaType === ListItemImageTypes.LOGO ||
          selectedType === ListTypes.PLAIN
        ),
      [selectedMediaType, selectedType],
    )

    const displayFrameColor = useMemo(
      () => selectedMediaType === ListItemImageTypes.ICON,
      [selectedMediaType],
    )

    const iconColorName = useMemo(() => {
      switch (selectedType) {
        case ListTypes.NUMBER:
          return t('edit.properties.list.color.number')
        case ListTypes.BULLET:
          return t('edit.properties.list.color.bullet')
        case ListTypes.TIMELINE:
          return t('edit.properties.list.color.bullet')
        default:
          return t('edit.properties.list.color.icon')
      }
    }, [selectedType])

    return (
      <PropSection
        title={t('edit.properties.list.colors')}
        initialState={false}
        expandable
      >
        <div>
          <div className="grid">
            <div className="c-1">{t('edit.properties.list.color.title')}</div>
            <div className="c-1">{t('edit.properties.list.color.body')}</div>
            {displayIconColor && <div className="c-1">{iconColorName}</div>}
            {displayFrameColor && (
              <div className="c-1">
                {t('edit.properties.list.color.icon_frame')}
              </div>
            )}
          </div>
          <div className="grid">
            <div className="c-1">
              <PropColor color={selectedColor} onChange={handleOnColorChange} />
            </div>
            <div className="c-1">
              <PropColor
                color={selectedBodyColor}
                onChange={handleOnBodyColorChange}
              />
            </div>
            {displayIconColor && (
              <div className="c-1">
                <PropColor
                  color={selectedIconColor}
                  onChange={handleOnIconColorChange}
                />
              </div>
            )}
            {displayFrameColor && (
              <div className="c-1">
                <PropColor
                  color={selectedIconFrameColor}
                  onChange={handleOnIconFrameColorChange}
                />
              </div>
            )}
          </div>
        </div>
      </PropSection>
    )
  },
)

ListColors.displayName = 'ListColors'
