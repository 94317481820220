import { useCallback, useEffect } from 'react'
import debounce from 'lodash/debounce'
import { useDispatch, useSelector } from 'react-redux'
import { observer } from 'mobx-react-lite'
import { RootState, SAVE_STATE } from 'src/store'
import { calculateDiff, haveDiff, mapSlidesFromPages } from './utils'
import { IStoreListenerProps, IStoreSyncHandler } from './types'
import { useDecksV3Api } from 'src/hooks/api/useDecksV3Api'
import { IDeckV3SlideContent } from 'src/types/deck-v3'
import {
  setDeletedSlides,
  setDocument,
  setSaveNow,
  setV3SaveState,
} from 'src/store/decks-v3'
import { DeckV3Document } from 'src/types/api/responseObjects'

const StoreListener = observer<IStoreListenerProps>(({ store, deckId }) => {
  const dispatch = useDispatch()
  const { syncSlides } = useDecksV3Api()

  const { document, saveNow, organizationId, deletedSlides } = useSelector(
    ({ persistedDeckV3: deckV3, workspace }: RootState) => ({
      document: deckV3.documentData?.document,
      saveNow: deckV3.saveNow,
      organizationId: workspace.id,
      deletedSlides: deckV3.deletedSlides,
    }),
  )

  const handleStoreChange = useCallback(
    async ({ beforeSync, deletedSlides }: IStoreSyncHandler = {}) => {
      const newDocument = store.toJSON()
      const clonedNewDocument = JSON.parse(
        JSON.stringify(newDocument),
      ) as DeckV3Document

      const [previousSlides, newSlides] = mapSlidesFromPages(
        document?.pages,
        clonedNewDocument?.pages,
      )

      const diff = calculateDiff(previousSlides, newSlides, deletedSlides)

      if (!haveDiff(diff)) {
        return
      }
      dispatch(setV3SaveState(SAVE_STATE.SAVING))

      beforeSync?.()

      const createdSlides = await syncSlides(deckId, {
        organizationId,
        diff,
      })

      if (createdSlides && createdSlides.length > 0) {
        createdSlides.forEach((createdSlide) => {
          const slide = clonedNewDocument?.pages.find(
            (page: any) => page.id === createdSlide.id,
          ) as IDeckV3SlideContent
          if (slide) {
            slide.custom = {
              ...slide.custom,
              internalId: createdSlide.internalId,
            }
            const page = store.pages?.find(
              (page) => page.id === createdSlide.id,
            )
            page?.set({
              custom: { ...page.custom, internalId: createdSlide.internalId },
            })
          }
        })
      }
      dispatch(setV3SaveState(SAVE_STATE.SAVED))
      dispatch(setDocument(clonedNewDocument as DeckV3Document))
    },
    [deckId, document?.pages, organizationId, store, syncSlides, dispatch],
  )

  useEffect(() => {
    if (saveNow) {
      handleStoreChange()
      dispatch(setSaveNow(false))
    }
  }, [dispatch, handleStoreChange, saveNow])

  useEffect(() => {
    const dispose = store.on('change', () => {
      const event = new Event('editor:store:change')
      window.dispatchEvent(event)
    })
    return () => {
      dispose()
    }
  }, [store])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleStoreChange = useCallback(
    debounce(handleStoreChange, 10000),
    [handleStoreChange],
  )

  useEffect(() => {
    window.addEventListener(
      'editor:store:change',
      debouncedHandleStoreChange as EventListener,
      false,
    )

    return () => {
      window.removeEventListener(
        'editor:store:change',
        debouncedHandleStoreChange as EventListener,
        false,
      )
      debouncedHandleStoreChange.cancel()
    }
  }, [debouncedHandleStoreChange])

  // beforeunload saving
  useEffect(() => {
    const uListener = (e: any) => {
      handleStoreChange({
        beforeSync: () => {
          e.preventDefault()
          e.stopPropagation()
          e.returnValue = ''
        },
      })
    }

    window.addEventListener('beforeunload', uListener)
    return () => {
      window.removeEventListener('beforeunload', uListener)
    }
  }, [handleStoreChange])

  useEffect(() => {
    if (deletedSlides.length > 0) {
      dispatch(setDeletedSlides([]))
      handleStoreChange({ deletedSlides })
    }
  }, [deckId, deletedSlides, dispatch, handleStoreChange])

  return null
})

export default StoreListener
