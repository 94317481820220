import React, { useEffect, useState } from 'react'
import { useAuthProvider } from 'src/provider/authProvider'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import { useUserApi } from 'src/hooks'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, setAdminToken, setWorkspaceClearState } from 'src/store'
import RefreshOverlay from './components/RefreshOverlay'
import useIdleTimer from 'src/hooks/useIdleTimer'
import { HelmetProvider } from 'src/provider'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { usePostHog } from 'posthog-js/react'
import Gleap from 'gleap'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'
import { decodeLandingPrompt } from 'src/services/stringServices'

export const ProtectedPageLayout: React.FC = React.memo(() => {
  const { token } = useAuthProvider()
  const [searchParams] = useSearchParams()
  const ldClient = useLDClient()
  const posthog = usePostHog()
  const posthogCapture = usePostHogCapture()
  const { getUser, getOrganizations } = useUserApi()
  const [isOutletReadyToRender, setIsOutletReadyToRender] = useState(false)

  const { user } = useSelector(({ user }: RootState) => ({
    user: user.data,
  }))
  const { isIdle, isExpired } = useIdleTimer()

  useEffect(() => {
    const handleFetching = async () => {
      await getUser()
      const organizations = await getOrganizations()
      if (!organizations.length) {
        dispatch(setWorkspaceClearState())
      }
      setIsOutletReadyToRender(true)
    }

    if (token) {
      handleFetching()
    }
  }, [token])

  useEffect(() => {
    const lsAdminToken = localStorage.getItem('adminToken')
    if (lsAdminToken) {
      dispatch(setAdminToken({ adminToken: lsAdminToken! }))
    }
  }, [])

  useEffect(() => {
    const isDecktopus = user?.email.includes('@decktopus.com')

    let ldKey = 'USER_ANNMYS'
    let ldName = 'User Anonymous'
    let ldEmail = 'user_annmys@decktopus.com'

    if (isDecktopus) {
      ldKey = 'USER_DECKTOPUS'
      ldName = 'User Decktopus'
      ldEmail = 'user@decktopus.com'
    }

    ldClient
      ?.identify({
        kind: 'user',
        key: ldKey,
        name: ldName,
        email: ldEmail,
      })
      .catch((error) => {
        console.error('Launchdarkly client identify error:', error.message)
      })
  }, [user])

  const dispatch = useDispatch()

  const tryParam = searchParams.get('try')
  const aiParam = searchParams.get('ai')
  const promoCodeParam = searchParams.get('promo_code')

  if (tryParam) {
    localStorage.setItem('try', tryParam)
  }

  if (promoCodeParam) {
    localStorage.setItem('promo_code', promoCodeParam)
  }

  // Check if the user is authenticated
  if (!token || isExpired) {
    if (isExpired) {
      try {
        if (posthog && posthog.__loaded) {
          posthog.reset()
        }
      } catch {
        console.error('posthog reset ProtectedPageLayout error: ')
      }
    }

    try {
      if (Gleap) {
        Gleap.clearIdentity()
      }
    } catch {
      console.error('Gleap clearIdentity ProtectedPageLayout error: ')
    }
    // If not authenticated, redirect to the login page
    if (aiParam) {
      posthogCapture(POSTHOG_EVENTS.AI_FLOW_PROMPT_LANDING, {
        input_value: decodeLandingPrompt(aiParam),
      })
    }

    return (
      <Navigate
        to={tryParam || aiParam || promoCodeParam ? '/sign-up' : '/login'}
      />
    )
  }

  if (tryParam) {
    return <Navigate to={'/dashboard'} />
  }

  return (
    <>
      <HelmetProvider isExportMode={window.location.href.includes('/export')} />
      {isIdle && <RefreshOverlay />}
      {isOutletReadyToRender && <Outlet />}
    </>
  )
})

export default ProtectedPageLayout
