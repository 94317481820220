import { DeckData_DataSchema } from 'src/types/api/requestObjects'

export const slideOrderInsert = (
  slides: DeckData_DataSchema['slides'],
  insert: DeckData_DataSchema['slides'][0],
): DeckData_DataSchema['slides'] => {
  const spanSlides = slides.map((slide) => ({
    ...slide,
    orderIndex:
      slide.orderIndex + (slide.orderIndex >= insert.orderIndex ? 1 : 0),
  }))

  return [...spanSlides, insert]
}
