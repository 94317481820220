import { css } from '@emotion/react'
import { ListLayoutDirections, Sides } from 'src/types/api/enums'

interface IBulletListStyles {
  direction: ListLayoutDirections
}
export const bulletListStyles = ({ direction }: IBulletListStyles) => css`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  ${direction === ListLayoutDirections.HORIZONTAL ? `` : `width: 100%;`}
`

interface IBulletListItemStyles {
  division?: number
  counterDivision?: number
  direction: ListLayoutDirections
  mediaSide: Sides
}
export const bulletListItemStyles = ({
  division,
  counterDivision,
  direction,
  mediaSide,
}: IBulletListItemStyles) => css`
  display: flex;
  gap: 5em;

  ${mediaSide === Sides.TOP
    ? `
			flex-direction: column;
			align-items: center;
			text-align: center;
		`
    : `
			align-items: flex-start;
		`}

  ${direction === ListLayoutDirections.HORIZONTAL
    ? `
			width: calc(100% / ${division});
			height: calc(100% / ${counterDivision});
		`
    : `
			width: calc(100% / ${counterDivision});
			height: calc(100% / ${division});
		`}
`
