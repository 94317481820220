import React, { useMemo } from 'react'

import { IMedia } from './types'
import { ComponentMediaDataSchema } from 'src/types/api/requestObjects'
import { websiteStyles } from './styles'
import { CANVAS_TYPE } from 'src/components/canvas/types'

export const Website: React.FC<IMedia> = React.memo(
  ({ data, canvasType, className, dataAttr }) => {
    const componentData: ComponentMediaDataSchema = useMemo(
      () => data.data as ComponentMediaDataSchema,
      [data.data],
    )

    const isPreview = useMemo(
      () => canvasType === CANVAS_TYPE.PREVIEW,
      [canvasType],
    )

    return (
      <div css={websiteStyles} className={className} {...dataAttr}>
        <iframe
          width={data.positions.width || 0}
          height={data.positions.height || 0}
          src={componentData.mediaUrl}
          allowFullScreen={true}
          style={{ border: 0, ...(isPreview ? {} : { pointerEvents: 'none' }) }}
        />
      </div>
    )
  },
)

Website.displayName = 'Video'
