import styled from '@mui/material/styles/styled'
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    'margin': theme.spacing(1),
    'border': 0,
    'borderRadius': theme.spacing(2),
    'padding': theme.spacing(2),
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
    ':hover, :active': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary._states?.focus,
    },
  },
  [`& .${toggleButtonGroupClasses.selected}`]: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.primary._states?.focus} !important`,
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
  [`& .${toggleButtonGroupClasses.lastButton}`]: {
    marginRight: 0,
  },
}))

export default StyledToggleButtonGroup
