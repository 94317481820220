import { IApiEnums } from 'src/i18n/types'
import {
  AICreditSpendReasonNames,
  PlanNames,
  PlanPeriods,
  UserPlanPaymentStates,
  UserLanguages,
} from 'src/types/api/enums'

export const apiEnums: IApiEnums = {
  [`enum.planNames.${PlanNames.PRO}`]: 'pro',
  [`enum.planNames.${PlanNames.BUSINESS}`]: 'business',
  [`enum.planPeriods.${PlanPeriods.MONTHLY}`]: 'mensal',
  [`enum.planPeriods.${PlanPeriods.ANNUAL}`]: 'anual',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_DECK}`]:
    'Criar Deck de IA',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_PDF_DECK}`]:
    'Criar Deck PDF',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_ICON}`]:
    'Sugestão de ícone',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_IMAGE}`]:
    'Sugestão de imagem',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_LIST_ITEM}`]:
    'Sugestão de item de lista',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_TIPS}`]:
    'Criar dicas',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_QUESTIONS_ANSWERS}`]:
    'Gerar P&R',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SINGLE_SLIDE_NOTES}`]:
    'Criar notas de slide único',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_MULTIPLE_SLIDE_NOTES}`]:
    'Criar notas de múltiplos slides',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.AI_TEXT_EDIT}`]:
    'Edição de Texto por IA',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED}`]:
    'Ação de Pagamento Necessária',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_PENDING}`]:
    'Pagamento Pendente',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_FAILED}`]:
    'Cancelado',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_SUCCESS}`]:
    'Ativo',
  [`enum.language.${UserLanguages.EN_US}`]: 'Inglês',
  [`enum.language.${UserLanguages.TR_TR}`]: 'Turco',
}
