import { useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import {
  ELEMENT_DEFINITION,
  titleDef,
  subtitleDef,
  bodyDef,
  labelDef,
  imageDef,
  videoDef,
  gifDef,
  iconDef,
  logoDef,
  lineChartDef,
  tableDef,
  barChartDef,
  buttonDef,
  formDef,
  pieChartDef,
  donutChartDef,
  ratingDef,
  iconListDef,
  imageGridDef,
  imageListDef,
  bulletListDef,
  timelineDef,
  showcaseDef,
  quoteDef,
  websiteDef,
  codeDef,
  linksDef,
  shapeDef,
  deviceDef,
  jumboDef,
  logoListDef,
} from 'src/pages/deck-page/components/edit-elements/definitions'

export const useElementDefinitions = () => {
  const { lang } = useLanguage()

  const definitions = useMemo(() => {
    return {
      [ELEMENT_DEFINITION.TITLE]: titleDef(lang),
      [ELEMENT_DEFINITION.SUBTITLE]: subtitleDef(lang),
      [ELEMENT_DEFINITION.BODY]: bodyDef(lang),
      [ELEMENT_DEFINITION.LABEL]: labelDef(lang),
      [ELEMENT_DEFINITION.JUMBO]: jumboDef(lang),
      [ELEMENT_DEFINITION.IMAGE]: imageDef(lang),
      [ELEMENT_DEFINITION.VIDEO]: videoDef(lang),
      [ELEMENT_DEFINITION.GIF]: gifDef(lang),
      [ELEMENT_DEFINITION.ICON]: iconDef(lang),
      [ELEMENT_DEFINITION.LOGO]: logoDef(lang),
      [ELEMENT_DEFINITION.SHAPE]: shapeDef,

      [ELEMENT_DEFINITION.LINE_CHART]: lineChartDef(lang),
      [ELEMENT_DEFINITION.TABLE]: tableDef(lang),
      [ELEMENT_DEFINITION.BAR_CHART]: barChartDef(lang),
      [ELEMENT_DEFINITION.BUTTON]: buttonDef(lang),
      [ELEMENT_DEFINITION.FORM]: formDef(lang),
      [ELEMENT_DEFINITION.PIE_CHART]: pieChartDef(lang),
      [ELEMENT_DEFINITION.DONUT_CHART]: donutChartDef(lang),
      [ELEMENT_DEFINITION.RATING]: ratingDef(lang),

      [ELEMENT_DEFINITION.ICON_LIST]: iconListDef(lang),
      [ELEMENT_DEFINITION.IMAGE_LIST]: imageListDef(lang),
      [ELEMENT_DEFINITION.LOGO_LIST]: logoListDef(lang),
      [ELEMENT_DEFINITION.BULLET_LIST]: bulletListDef(lang),
      [ELEMENT_DEFINITION.TIMELINE]: timelineDef(lang),
      [ELEMENT_DEFINITION.SHOWCASE]: showcaseDef(lang),
      [ELEMENT_DEFINITION.QUOTE]: quoteDef(lang),
      [ELEMENT_DEFINITION.IMAGE_GRID]: imageGridDef(lang),

      [ELEMENT_DEFINITION.WEBSITE]: websiteDef(lang),
      [ELEMENT_DEFINITION.CODE]: codeDef(lang),
      [ELEMENT_DEFINITION.LINKS]: linksDef(lang),
      [ELEMENT_DEFINITION.DEVICE]: deviceDef(lang),
    }
  }, [lang])

  return { definitions }
}
