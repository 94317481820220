import React, { useMemo } from 'react'

import { IMedia } from './types'
import { imageStyles } from './styles'
import { ComponentMediaDataSchema } from 'src/types/api/requestObjects'
import { ComponentMediaZoomTypes } from 'src/types/api/enums'

export const Image: React.FC<IMedia> = React.memo(
  ({ data, className, dataAttr }) => {
    const componentData: ComponentMediaDataSchema = useMemo(
      () => data.data as ComponentMediaDataSchema,
      [data.data],
    )

    const calculateBgSize: number = useMemo(() => {
      const widthLevel =
        ((data.positions.width || 1) / 100) * (componentData.zoom?.level || 100)
      const heightLevel =
        ((data.positions.height || 1) / 100) *
        (componentData.zoom?.level || 100)
      return Math.min(widthLevel, heightLevel)
    }, [data, componentData])

    const backgroundSize = useMemo(() => {
      switch (componentData.zoom?.type) {
        case ComponentMediaZoomTypes.FILL:
          return `cover`
        case ComponentMediaZoomTypes.FIT:
          return `contain`
        case ComponentMediaZoomTypes.DISTORT:
          return `100% 100%`
        case ComponentMediaZoomTypes.CROP:
          return `${calculateBgSize}px`
        default:
          return ``
      }
    }, [componentData.zoom?.type, calculateBgSize])

    const opacity = useMemo(() => data.style.opacity || 1, [data])

    return (
      <div
        css={imageStyles}
        className={className}
        {...dataAttr}
        style={{
          backgroundImage: `url(${componentData.mediaUrl})`,
          backgroundSize: backgroundSize,
          opacity,
          borderTopLeftRadius: `${data.style.corner?.tl || 0}em`,
          borderTopRightRadius: `${data.style.corner?.tr || 0}em`,
          borderBottomLeftRadius: `${data.style.corner?.bl || 0}em`,
          borderBottomRightRadius: `${data.style.corner?.br || 0}em`,
        }}
      />
    )
  },
)

Image.displayName = 'Image'
