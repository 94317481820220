import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const customDomainListStyles = css`
  width: 100%;
`

export const customDomainItemStyles = ({ gap }: ITheme) => css`
  margin-bottom: ${gap.xlarge}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const customDomainLeftStyles = ({ fontSize, fontWeight }: ITheme) => css`
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
`

export const customDomainVerifyStyles = ({ colors }: ITheme) => css`
  color: ${colors.text[2]};
`
