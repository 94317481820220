import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const paletteListWrapperStyles = ({
  borderRadius,
  breakpointsV2: breakpoints,
  colors,
  fontSize,
  fontWeight,
  gap,
  spacingV2: spacing,
}: ITheme) => css`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.medium};

  & > div {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  & > .selected-color > .box {
    height: 60px;
  }

  & .style-item-name {
    width: 80px;
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.small};

    @media (min-width: ${breakpoints.desktop}px) {
      font-size: ${fontSize.medium};
      width: 103px;
    }
  }

  & .style-item-change {
    cursor: pointer;
    text-decoration: underline;
    font-size: ${fontSize.small};
    text-align: right;
  }

  & .color-box-wrapper {
    display: grid;
    grid-gap: ${gap.small}px;
    grid-template-columns: repeat(
      2,
      calc((100% / 2) - ${(gap.small * 2) / 2}px)
    );

    @media (min-width: ${breakpoints.desktop}px) {
      grid-template-columns: repeat(
        3,
        calc((100% / 3) - ${(gap.small * 2) / 3}px)
      );
    }
  }

  & .change-color-box {
    width: 50px;
    height: 35px;
  }

  & .color-palette-border {
    width: 100%;
    height: 1px;
    background-color: ${colors.outline[2]};
    border-radius: ${borderRadius[1]};
  }

  & .edit-colors {
    display: flex;
    justify-content: space-between;

    & .palette-color-changer {
      display: flex;
      flex-direction: row;
      gap: ${gap['2xsmall']}px;
      flex-wrap: wrap;
    }
  }
`
