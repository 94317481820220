import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { VALIDATION_RULE_TYPES, useAiApi, useLanguage } from 'src/hooks'
import {
  BUTTON_THEME,
  BUTTON_WIDTH,
  Button,
  Dialog,
  Modal,
  icons,
} from 'src/lib'
import { setAllowDeleteShortcut } from 'src/store'
import { IPropAiImage } from './types'

export interface IPropAiImageProtocol {
  handleClickAction: () => void
}

const PropAiImageComp = forwardRef<IPropAiImageProtocol, IPropAiImage>(
  ({ onChange, disabled, hideButton }, ref) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const { generateAiImage, isLoading } = useAiApi()

    const [isModalOpen, setModalOpen] = useState(false)
    const [aiPrompt, setAiPrompt] = useState('')

    const handleClick = useCallback(() => {
      setModalOpen(true)
      dispatch(setAllowDeleteShortcut(false))
    }, [])

    const handleAiClick = useCallback(async () => {
      const res = await generateAiImage([aiPrompt])
      onChange(res)
      setModalOpen(false)
    }, [aiPrompt, generateAiImage, onChange])

    useImperativeHandle(
      ref,
      () => ({
        handleClickAction: () => {
          handleClick()
        },
      }),
      [handleClick],
    )

    return (
      <>
        {!hideButton && (
          <Button
            theme={BUTTON_THEME.GRADIENT}
            width={BUTTON_WIDTH.FULL}
            icon={icons.sparkling}
            text={t('edit.properties.ai_image.create')}
            onClick={handleClick}
            isLoading={isLoading}
            disabled={disabled || isLoading}
          />
        )}
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <Dialog
            title={t('edit.properties.ai_image.generate_with_ai')}
            onSubmit={handleAiClick}
            promptPlaceholder={t('edit.properties.ai_image.describe')}
            onPromptChange={setAiPrompt}
            onCloseClick={() => setModalOpen(false)}
            submitLabel={t('edit.properties.ai_image.generate')}
            submitTheme={BUTTON_THEME.GRADIENT}
            promptValue={aiPrompt}
            submitIcon={icons.sparkling}
            submitDisabled={isLoading}
            promptValidationRules={{
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  name: t('edit.media.modal.ai.input'),
                  value: 3,
                }),
              },
            }}
          />
        </Modal>
      </>
    )
  },
)

export const PropAiImage = React.memo(PropAiImageComp)
