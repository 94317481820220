import React, { useCallback, useEffect, useState } from 'react'

import { ISlider } from './types'
import { barStyles, sliderStyles } from './styles'
import { useDebouncer } from 'src/hooks'

export const Slider: React.FC<ISlider> = React.memo(
  ({ value, min, max, onChange, className, dataAttr }) => {
    const [debouncedValue, setDebouncedValue] = useState(value.toString())

    useEffect(() => {
      if (debouncedValue !== value.toString()) {
        debounced()
      }
    }, [debouncedValue])

    const debounced = useDebouncer(
      () => {
        updateValueHandler(debouncedValue)
      },
      { delay: 500 },
    )

    const updateValueHandler = useCallback(
      (value: string) => {
        onChange(parseInt(value))
      },
      [onChange],
    )

    const onChangeHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedValue(event.currentTarget.value)
      },
      [],
    )

    return (
      <div css={sliderStyles} className={className} {...dataAttr}>
        <input
          css={barStyles}
          type="range"
          min={min}
          max={max}
          value={debouncedValue || 0}
          onChange={onChangeHandler}
        />
        <div
          className="filled-range"
          style={{
            width: `${
              parseInt(debouncedValue) -
              Math.round((parseInt(debouncedValue) - 50) / 50)
            }%`,
          }}
        />
      </div>
    )
  },
)

Slider.displayName = 'Slider'
