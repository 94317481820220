import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { IPolotnoComponent } from '../../types'

const MoveUpPageAction = observer<IPolotnoComponent>(({ store }) => {
  const activeId = store.activePage?.id
  const pageIdx = store.pages.findIndex((spage) => spage.id === activeId)
  const handleClick = () => {
    if (pageIdx < store.pages.length - 1) {
      store.activePage.setZIndex(pageIdx + 1)
    }
  }

  return (
    <BaseIconButton
      onClick={handleClick}
      sx={{
        padding: '6px',
      }}
      disabled={pageIdx >= store.pages.length - 1}
    >
      <MaterialSymbol icon="keyboard_arrow_up" size={20} weight={400} />
    </BaseIconButton>
  )
})

export default MoveUpPageAction
