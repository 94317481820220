import React, { useEffect, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { useLanguage, useLocation } from 'src/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { Header } from 'src/components/header'
import {
  suspendedWrapper,
  suspendedStyles,
  wrapperStyles,
  headerLayoutStyle,
} from './styles'
import { Icon, icons } from 'src/lib'
import { colors, dimensions } from 'src/theme'
import { Banner } from 'src/components/banner'

export const MainLayout: React.FC = React.memo(() => {
  const { t, lang, changeLanguage } = useLanguage()
  const { path } = useLocation()
  const { isWorkspaceOwner, workspaceId, adminToken, organizations } =
    useSelector(({ workspace, user, admin }: RootState) => ({
      isWorkspaceOwner: workspace.isOwner,
      workspaceId: workspace.id,
      organizations: user.organizationUsers,
      adminToken: admin.adminToken,
    }))

  useEffect(() => {
    const lang = localStorage.getItem('currentLanguage')
    if (lang) {
      changeLanguage(lang)
    }
  }, [])

  const isSearchable = useMemo(() => path.includes('dashboard'), [path])

  const isOrganizationActive = useMemo(() => {
    if (!organizations?.length) {
      return true
    }

    return organizations?.find(
      (organization) => organization.organization.id === workspaceId,
    )?.organization.isActive
  }, [organizations, workspaceId])

  const suspendedOrganization = useMemo(
    () => (
      <div css={suspendedWrapper}>
        <div css={suspendedStyles}>
          <Icon icon={icons.suspended} color={colors.error[90]} size={100} />
          <span>
            {isWorkspaceOwner
              ? t('organization.organization_suspended')
              : t('organization.organization_suspended_member')}
          </span>
        </div>
      </div>
    ),
    [workspaceId, isOrganizationActive, isWorkspaceOwner, lang],
  )

  const isAdminBannerVisible = useMemo(() => {
    return adminToken
  }, [adminToken])

  const calcWrapperMaxHeight = useMemo(() => {
    return adminToken
      ? {
          maxHeight: `calc(100vh - ${
            dimensions.headerHeight.DEFAULT + dimensions.banner.DEFAULT
          }px);`,
        }
      : { maxHeight: `calc(100vh - ${dimensions.headerHeight.DEFAULT}px)` }
  }, [adminToken])

  return (
    <>
      {isAdminBannerVisible && <Banner adminToken={adminToken} />}
      <Header css={headerLayoutStyle} hasSearch={isSearchable} />
      {workspaceId && !isOrganizationActive ? (
        suspendedOrganization
      ) : (
        <div css={wrapperStyles} style={calcWrapperMaxHeight}>
          <Outlet />
        </div>
      )}
    </>
  )
})
