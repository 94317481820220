import React, { useCallback, useMemo, useState } from 'react'
import { HexColorPicker } from 'react-colorful'

import { COLOR_PICKER_TYPE, IColorPicker } from './types'
import {
  colorExportStyles,
  colorPickerStyles,
  colorfulStyles,
  headerStyles,
} from './styles'
import {
  DROPDOWN_SIZE,
  DROPDOWN_THEME,
  DROPDOWN_WIDTH,
  Dropdown,
  IDropdownItem,
  IDropdownValue,
} from '../dropdown'
import { Icon, icons } from '../icon'
import { useTheme } from 'src/theme'
import { GradientBar } from './components'
import { useLanguage } from 'src/hooks'

const ColorPicker: React.FC<IColorPicker> = React.memo(
  ({
    noGradient = false,
    initialColors,
    initialType = COLOR_PICKER_TYPE.SOLID,
    initialRotate = 90,
    onChange,
    onClose,
    onClickAdd,
    className,
    dataAttr,
  }) => {
    const { t } = useLanguage()
    const { colors } = useTheme()
    const [colorType, setColorType] = useState<IDropdownValue>(initialType)
    const [gradientRotate, setGradientRotate] = useState(initialRotate)
    const [selectedColorIndex, setSelectedColorIndex] = useState(0)
    const [pickedColors, setPickedColors] = useState([
      initialColors?.[0] || '#FFFFFF',
      initialColors?.[1] || '#000000',
    ])

    const handleChangeColorType = useCallback(
      (val?: IDropdownItem) => {
        val?.value && setColorType(val?.value)
        onChange(
          val?.value === COLOR_PICKER_TYPE.SOLID
            ? [pickedColors[0]]
            : pickedColors,
          val?.value === COLOR_PICKER_TYPE.GRADIENT
            ? gradientRotate
            : undefined,
        )
      },
      [pickedColors, gradientRotate],
    )

    const handleColorChange = useCallback(
      (color: string) => {
        const newPickedColors = [...pickedColors]
        newPickedColors[selectedColorIndex] = color.toUpperCase()
        setPickedColors(newPickedColors)
        onChange(
          colorType === COLOR_PICKER_TYPE.SOLID
            ? [newPickedColors[0]]
            : newPickedColors,
          colorType === COLOR_PICKER_TYPE.GRADIENT ? gradientRotate : undefined,
        )
      },
      [colorType, pickedColors, selectedColorIndex, gradientRotate],
    )

    const handleColorChangeFromInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const upperCasedValue = e.target.value.toUpperCase()
        if (
          upperCasedValue.startsWith('#') ||
          colorType === COLOR_PICKER_TYPE.GRADIENT
        ) {
          handleColorChange(upperCasedValue)
        } else {
          handleColorChange('#' + upperCasedValue)
        }
      },
      [colorType],
    )

    const handleGradientRotateChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRotate = e.target.value ? parseInt(e.target.value) : 0
        setGradientRotate(newRotate)
        onChange(pickedColors, newRotate)
      },
      [pickedColors],
    )

    const handleOnClose = useCallback(() => {
      onClose?.(
        colorType === COLOR_PICKER_TYPE.SOLID
          ? [pickedColors[0]]
          : pickedColors,
        colorType === COLOR_PICKER_TYPE.GRADIENT ? gradientRotate : undefined,
      )
    }, [colorType, pickedColors, gradientRotate])

    const handleOnClickAdd = useCallback(() => {
      onClickAdd?.(
        colorType === COLOR_PICKER_TYPE.SOLID
          ? [pickedColors[0]]
          : pickedColors,
        colorType === COLOR_PICKER_TYPE.GRADIENT ? gradientRotate : undefined,
      )
    }, [pickedColors, colorType, gradientRotate])

    const colorDisplay = useMemo(
      () =>
        `linear-gradient(${gradientRotate || 0}deg, ${pickedColors[0]} 0%, ${
          pickedColors[colorType === COLOR_PICKER_TYPE.GRADIENT ? 1 : 0]
        } 100%)`,
      [gradientRotate, pickedColors, colorType],
    )

    return (
      <div css={colorPickerStyles} className={className} {...dataAttr}>
        <div css={headerStyles}>
          {noGradient ? (
            <div className="title">{t('edit.color_picker.select_color')}</div>
          ) : (
            <Dropdown
              className="dropdown"
              width={DROPDOWN_WIDTH.NORMAL}
              size={DROPDOWN_SIZE.BIG}
              theme={DROPDOWN_THEME.DARK}
              selected={colorType}
              items={[
                {
                  name: t('edit.color_picker.solid'),
                  value: COLOR_PICKER_TYPE.SOLID,
                },
                {
                  name: t('edit.color_picker.gradient'),
                  value: COLOR_PICKER_TYPE.GRADIENT,
                },
              ]}
              onChange={handleChangeColorType}
            />
          )}
          <div className="close">
            <Icon
              icon={icons.close}
              color={colors.white.DEFAULT}
              onClick={handleOnClose}
              size={16}
            />
          </div>
        </div>
        {colorType === COLOR_PICKER_TYPE.GRADIENT && (
          <GradientBar colors={pickedColors} onChange={setSelectedColorIndex} />
        )}
        <div css={colorfulStyles}>
          <HexColorPicker
            onChange={handleColorChange}
            color={pickedColors[selectedColorIndex]}
          />
        </div>
        <div css={colorExportStyles}>
          {colorType === COLOR_PICKER_TYPE.GRADIENT && (
            <input
              type="number"
              className="gradient-rotate"
              onChange={handleGradientRotateChange}
              value={gradientRotate.toString()}
            />
          )}
          <input
            className="hex-code"
            value={pickedColors[selectedColorIndex]}
            onChange={handleColorChangeFromInput}
          />
          <div className="color-add-to-swatch" onClick={handleOnClickAdd}>
            <Icon icon={icons.plus_square} color={colors.white.DEFAULT} />
          </div>
          <div
            className="color-display"
            style={{
              background: colorDisplay,
            }}
          ></div>
        </div>
      </div>
    )
  },
)

ColorPicker.displayName = 'ColorPicker'

export { ColorPicker }
