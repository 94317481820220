import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IForm, IFormData } from './types'
import { formStateStyles, formStyles } from './styles'
import { ComponentFormDataSchema } from 'src/types/api/requestObjects'
import { usePublicApi } from 'src/hooks'
import { FormContent } from './FormContent'
import { Carousel } from 'src/lib/carousel'
import { CANVAS_TYPE } from 'src/components/canvas/types'

export const Form: React.FC<IForm> = React.memo(
  ({ data, canvasType, className, dataAttr }) => {
    const { postFormResponse } = usePublicApi()
    const componentData: ComponentFormDataSchema = useMemo(
      () => data.data as ComponentFormDataSchema,
      [data.data],
    )

    const isPreview = useMemo(
      () => canvasType === CANVAS_TYPE.PREVIEW,
      [canvasType],
    )

    const [formDTO, setFormDTO] = useState<IFormData | undefined>()

    useEffect(() => {
      setFormDTO({
        id: componentData.formId,
        state: !componentData.options.isSubmitRequired,
        data: componentData.questions
          .map(({ questionId }) => questionId)
          .reduce((a: Record<string, null>, c) => {
            return {
              ...a,
              [c]: null,
            }
          }, {}),
      })
    }, [componentData, setFormDTO])

    const [currentSlide, setCurrentSlide] = useState(0)
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [gdprCheck, setGdprCheck] = useState(false)

    const baseSize = useMemo(() => {
      return (data.positions.width || 1980) / 140
    }, [data])

    const question = useMemo(
      () => componentData.questions[currentSlide],
      [componentData, currentSlide],
    )
    const totalQuestionCount = useMemo(() => {
      return componentData.questions.length
    }, [componentData])

    const answer = useMemo(() => {
      return formDTO?.data[question?.questionId]
    }, [formDTO, question?.questionId])

    const questionValid = useMemo(
      () => !question?.isRequired || !!answer,
      [question?.isRequired, answer],
    )

    const isLastSlide = useMemo(
      () => totalQuestionCount <= currentSlide + 1,
      [currentSlide, totalQuestionCount],
    )

    const slideChangeHandler = useCallback(
      (slideIndex: number, isBack?: boolean) => {
        if (isBack) {
          setCurrentSlide(slideIndex)
          return
        }
        if (isLastSlide) {
          if (componentData.options.isGDPRConsentEnabled && !gdprCheck) {
            return
          } else {
            if (questionValid) {
              handleFormSubmit()
            }
          }
        }
        if (questionValid) {
          setCurrentSlide(slideIndex)
        }
      },
      [componentData, isLastSlide, gdprCheck, questionValid, formDTO],
    )

    const gDPRCheckHandler = useCallback(
      (checked: boolean) => {
        setGdprCheck(checked)
      },
      [gdprCheck, setGdprCheck],
    )

    const handleFormSubmit = useCallback(() => {
      if (formDTO) {
        postFormResponse(formDTO)
      }
      setFormSubmitted(true)
    }, [formDTO])

    const formDTOHandler = useCallback(
      (formData: IFormData) => setFormDTO(formData),
      [setFormDTO],
    )

    const formQuestionsTemplate = useMemo(
      () =>
        componentData.questions.map((question, index) => (
          <FormContent
            baseSize={baseSize}
            canvasType={canvasType}
            currentSlide={currentSlide}
            componentData={componentData}
            formDTO={formDTO}
            gdprCheck={gdprCheck}
            isLastSlide={isLastSlide}
            key={question + '-' + index}
            question={question}
            orderIndex={index}
            totalQuestionCount={totalQuestionCount}
            width={data.positions.width}
            gDPRCheckHandler={gDPRCheckHandler}
            slideChangeHandler={slideChangeHandler}
            formDTOHandler={formDTOHandler}
          />
        )),
      [
        baseSize,
        canvasType,
        currentSlide,
        componentData,
        formDTO,
        gdprCheck,
        isLastSlide,
        totalQuestionCount,
        data.positions.width,
      ],
    )

    if (formSubmitted)
      return (
        <div
          css={formStyles}
          style={{
            fontSize: `${baseSize}em`,
          }}
          className={className}
          {...dataAttr}
        >
          <div css={formStateStyles}>
            {componentData.options.defaultTexts.submitMessage}
          </div>
        </div>
      )

    return (
      <div
        css={formStyles}
        style={{
          fontSize: `${baseSize}em`,
        }}
        className={className}
        {...dataAttr}
      >
        <Carousel
          totalQuestionCount={totalQuestionCount}
          canChangeCurrentSlide={!isPreview}
          current={currentSlide}
          onPageChange={(index) => setCurrentSlide(index)}
        >
          {formQuestionsTemplate}
        </Carousel>
      </div>
    )
  },
)

Form.displayName = 'Form'
