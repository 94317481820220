import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const propSliderStyles = ({
  colors,
  dimensions,
  gap,
  borderRadius,
}: ITheme) => css`
  position: relative;
  width: 100%;
  padding: 12px;
  background: ${colors.dark.DEFAULT};
  height: ${dimensions.formElementHeight.big}px;
  border-radius: ${borderRadius[4]};
  display: flex;
  gap: ${gap.xsmall}px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
