import React, { useCallback, useMemo } from 'react'

import { SWITCH_SIZE, Switch } from 'src/lib'
import {
  ComponentTableDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { ComponentServices } from 'src/services'
import { DeepPartial } from 'src/types'
import { PropHeader } from 'src/pages/deck-page/components/edit-properties/props'
import { IEditPropSection } from '../../types'
import { useLanguage } from 'src/hooks'

export const TableProps: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()
    const { t } = useLanguage()
    const selectedHeaderRow = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentTableDataSchema['headerRow'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const data = (c.data as ComponentTableDataSchema).headerRow
            if (a === undefined && data) {
              return data
            }

            return data !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )
    const selectedHeaderCol = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentTableDataSchema['headerCol'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const data = (c.data as ComponentTableDataSchema).headerCol
            if (a === undefined && data) {
              return data
            }

            return data !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleHeaderRowClick = useCallback(
      (value?: boolean) => {
        const partialData: DeepPartial<ComponentTableDataSchema> = {
          headerRow: value,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTableDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const handleHeaderColClick = useCallback(
      (value?: boolean) => {
        const partialData: DeepPartial<ComponentTableDataSchema> = {
          headerCol: value,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTableDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <div>
        <PropHeader text={t('edit.properties.table.placeholder')} />
        <div className="grid">
          <div className="c-full control-row">
            <Switch
              size={SWITCH_SIZE.SMALL}
              selected={selectedHeaderRow}
              onChange={handleHeaderRowClick}
            />
            {t('edit.properties.show_header_row')}
          </div>
          <div className="c-full control-row">
            <Switch
              size={SWITCH_SIZE.SMALL}
              selected={selectedHeaderCol}
              onChange={handleHeaderColClick}
            />
            {t('edit.properties.show_header_column')}
          </div>
        </div>
      </div>
    )
  },
)

TableProps.displayName = 'TableProps'
