import { ComponentDeviceTypes, ComponentTypes } from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentDeviceDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const deviceDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.device.display_name', { lng }),
      styles: {},
      icon: icons.showcase,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.DEVICE,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 800,
          height: 800,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          type: ComponentDeviceTypes.DESKTOP,
          imageUrl: 'https://cdn.decktopus.com/images/defaultImage.png',
        } as ComponentDeviceDataSchema,
      },
    ],
  }
}
