import { COLOR } from './types'

export const dark2 = {
  DEFAULT: COLOR.dark2_100,
  90: COLOR.dark2_90,
  80: COLOR.dark2_80,
  70: COLOR.dark2_70,
  60: COLOR.dark2_60,
  50: COLOR.dark2_50,
  40: COLOR.dark2_40,
  30: COLOR.dark2_30,
  20: COLOR.dark2_20,
  10: COLOR.dark2_10,
}
