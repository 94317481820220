export enum FONT_WEIGHT_TYPE {
  THIN = ' Thin',
  EXTRA_LIGHT = ' ExtraLight',
  LIGHT = ' Light',
  REGULAR = ' Regular',
  MEDIUM = ' Medium',
  SEMI_BOLD = ' SemiBold',
  BOLD = ' Bold',
  EXTRA_BOLD = ' ExtraBold',
  BLACK = ' Black',
}

export const fontWeightCalculator = (name?: string) => {
  if (!name) {
    return
  }
  if (name.includes(FONT_WEIGHT_TYPE.THIN)) {
    return 100
  }
  if (name.includes(FONT_WEIGHT_TYPE.EXTRA_LIGHT)) {
    return 200
  }
  if (name.includes(FONT_WEIGHT_TYPE.LIGHT)) {
    return 300
  }
  if (name.includes(FONT_WEIGHT_TYPE.REGULAR)) {
    return 400
  }
  if (name.includes(FONT_WEIGHT_TYPE.MEDIUM)) {
    return 500
  }
  if (name.includes(FONT_WEIGHT_TYPE.SEMI_BOLD)) {
    return 600
  }
  if (name.includes(FONT_WEIGHT_TYPE.BOLD)) {
    return 700
  }
  if (name.includes(FONT_WEIGHT_TYPE.EXTRA_BOLD)) {
    return 800
  }
  if (name.includes(FONT_WEIGHT_TYPE.BLACK)) {
    return 900
  }

  return 400
}

export const fontFamilyCleaner = (name?: string) => {
  if (!name) {
    return
  }
  let updatedName = name
  Object.values(FONT_WEIGHT_TYPE).forEach((weight) => {
    updatedName = updatedName.replace(weight, '')
  })
  return updatedName
}

const isFontWeightTypeIncluded = (name?: string) => {
  if (!name) {
    return
  }
  return Object.values(FONT_WEIGHT_TYPE).some((value) => name.includes(value))
}

export const fontWeightGoogleValue = (name?: string) => {
  if (!isFontWeightTypeIncluded(name)) {
    return ''
  }
  return `:wght@${fontWeightCalculator(name)}`
}
