import { useCallback, useState } from 'react'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import {
  getIconResponse,
  getSearchIconsResponse,
  getSearchMediaResponse,
} from 'src/types/api/responseObjects'
import {
  getIconParams,
  getIconQuery,
  getSearchIconsQuery,
  getSearchMediaQuery,
  getTrendingIconsQuery,
  getTrendingMediaQuery,
  postMediaTriggerDownloadBody,
} from 'src/types/api/requestObjects'

export const useMediaApi = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const searchMedia = useCallback(
    async (
      params: getSearchMediaQuery,
    ): Promise<getSearchMediaResponse['data'] | undefined> => {
      setIsLoading(true)
      try {
        const res: RequestResponse<getSearchMediaResponse, any> =
          await RequestServices.callApi({
            method: 'GET',
            url: API_CONFIG.MEDIA,
            params,
          })

        setIsLoading(false)
        return res.data.data
      } catch {
        setIsLoading(false)
        return undefined
      }
    },
    [],
  )

  const getTrendingMedia = useCallback(
    async (
      params: getTrendingMediaQuery,
    ): Promise<getSearchMediaResponse['data'] | undefined> => {
      setIsLoading(true)
      try {
        const res: RequestResponse<getSearchMediaResponse, any> =
          await RequestServices.callApi({
            method: 'GET',
            url: API_CONFIG.MEDIA_TRENDS,
            params,
          })

        setIsLoading(false)
        return res.data.data
      } catch {
        setIsLoading(false)
        return undefined
      }
    },
    [],
  )

  const triggerMediaDownload = useCallback(
    async (data: postMediaTriggerDownloadBody): Promise<boolean> => {
      setIsLoading(true)
      try {
        await RequestServices.callApi({
          method: 'POST',
          url: API_CONFIG.MEDIA_TRIGGER_DOWNLOAD,
          data,
        })

        setIsLoading(false)
        return true
      } catch {
        setIsLoading(false)
        return false
      }
    },
    [],
  )

  const searchIcon = useCallback(
    async (
      params: getSearchIconsQuery,
    ): Promise<getSearchIconsResponse['data'] | undefined> => {
      setIsLoading(true)
      try {
        const res: RequestResponse<getSearchIconsResponse, any> =
          await RequestServices.callApi({
            method: 'GET',
            url: API_CONFIG.ICONS,
            params,
          })

        setIsLoading(false)
        return res.data.data
      } catch {
        setIsLoading(false)
        return undefined
      }
    },
    [],
  )

  const getIcon = useCallback(
    async (
      query: getIconQuery,
      params: getIconParams,
    ): Promise<getIconResponse['data']['url'] | undefined> => {
      setIsLoading(true)
      try {
        const res: RequestResponse<getIconResponse, any> =
          await RequestServices.callApi({
            method: 'GET',
            url: API_CONFIG.GET_ICON(params.externalId),
            params: query,
          })

        setIsLoading(false)
        return res.data.data.url
      } catch {
        setIsLoading(false)
        return undefined
      }
    },
    [],
  )

  const getTrendingIcons = useCallback(
    async (
      params: getTrendingIconsQuery,
    ): Promise<getSearchIconsResponse['data'] | undefined> => {
      setIsLoading(true)
      try {
        const res: RequestResponse<getSearchIconsResponse, any> =
          await RequestServices.callApi({
            method: 'GET',
            url: API_CONFIG.ICONS_TRENDS,
            params,
          })

        setIsLoading(false)
        return res.data.data
      } catch {
        setIsLoading(false)
        return undefined
      }
    },
    [],
  )

  return {
    searchMedia,
    triggerMediaDownload,
    searchIcon,
    getIcon,
    getTrendingMedia,
    getTrendingIcons,
    isLoading,
  }
}
