import { ComponentTypes } from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentTableDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const tableDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.table.display_name', { lng }),
      styles: {},
      icon: icons.table,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.TABLE,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 1200,
          height: 600,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          headerCol: false,
          headerRow: false,
          tableContent: [
            [
              i18n.t('edit.definitions.table.header1', { lng }),
              i18n.t('edit.definitions.table.header2', { lng }),
              i18n.t('edit.definitions.table.header3', { lng }),
              i18n.t('edit.definitions.table.header4', { lng }),
              i18n.t('edit.definitions.table.header5', { lng }),
            ],
            [
              i18n.t('edit.definitions.table.row1.value1', { lng }),
              i18n.t('edit.definitions.table.row1.value2', { lng }),
              i18n.t('edit.definitions.table.row1.value3', { lng }),
              i18n.t('edit.definitions.table.row1.value4', { lng }),
              i18n.t('edit.definitions.table.row1.value5', { lng }),
            ],
            [
              i18n.t('edit.definitions.table.row2.value1', { lng }),
              i18n.t('edit.definitions.table.row2.value2', { lng }),
              i18n.t('edit.definitions.table.row2.value3', { lng }),
              i18n.t('edit.definitions.table.row2.value4', { lng }),
              i18n.t('edit.definitions.table.row2.value5', { lng }),
            ],
            [
              i18n.t('edit.definitions.table.row3.value1', { lng }),
              i18n.t('edit.definitions.table.row3.value2', { lng }),
              i18n.t('edit.definitions.table.row3.value3', { lng }),
              i18n.t('edit.definitions.table.row3.value4', { lng }),
              i18n.t('edit.definitions.table.row3.value5', { lng }),
            ],
            [
              i18n.t('edit.definitions.table.row4.value1', { lng }),
              i18n.t('edit.definitions.table.row4.value2', { lng }),
              i18n.t('edit.definitions.table.row4.value3', { lng }),
              i18n.t('edit.definitions.table.row4.value4', { lng }),
              i18n.t('edit.definitions.table.row4.value5', { lng }),
            ],
          ],
        } as ComponentTableDataSchema,
      },
    ],
  }
}
