import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editAiAssistantStyles = (theme: ITheme, isV3 = false) => css`
  display: flex;
  gap: ${isV3 ? theme.gap.xsmall : theme.gap.medium}px;
  flex-direction: column;
  margin-top: ${isV3 ? '0' : theme.gap.xlarge}px;
`

export const tipStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.medium}px;
`

export const paddingStyles = (theme: ITheme, isV3 = false) => css`
  padding: ${isV3 ? 0 : '0 ' + theme.gap.xlarge + 'px'};
  display: flex;
  flex-direction: column;
  gap: ${isV3 ? '0' : theme.gap.small + 'px'};
}
`

export const animationStyles = () => css`
  width: 200px;
}
`
export const answerStyles = (theme: ITheme, isV3 = false) => css`
  padding: 0 ${isV3 ? 0 : theme.gap.small}px;
  text-align: ${isV3 ? 'left' : 'inherit'};
`
export const questionStyles = (
  theme: ITheme,
  isV3 = false,
  isQA = false,
) => css`
  background: ${isV3 ? 'unset' : theme.colors.dark2[90]};
  padding: ${isV3
    ? '0px 0px 0px ' + (isQA ? '0px' : '12px')
    : '10px ' + theme.gap.small + 'px'};
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  border-radius: ${theme.borderRadius[4]};
  display: flex;
  align-items: center;
  text-align: start;
  gap: 8px;
  :before {
    content: '';
    width: ${isV3 ? '4px' : '0px'};
    height: ${isV3 ? '4px' : '0px'};
    border-radius: 50%;
    background: black;
    display: ${isQA ? 'none' : 'block'};
  }
  line-height: ${isV3 ? '157%' : 'inherit'};
`
export const qaStyles = (theme: ITheme, isV3 = false) => css`
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.small}px;
  line-height: ${isV3 ? '143%' : 'inherit'};
  font-size: ${isV3 ? '14px' : 'inherit'};
`
export const tipTitleStyles = (theme: ITheme, isV3: boolean) => css`
  background: ${isV3 ? 'unset' : theme.colors.dark.DEFAULT};
  padding: ${isV3 ? '0' : theme.gap.xsmall + 'px ' + theme.gap.xlarge + 'px'};
  width: 100%;
  text-align: start;
  font-size: ${isV3 ? '14px' : 'inherit'};
  font-weight: ${isV3 ? '500' : 'inherit'};
`
export const tipContentStyles = (isV3: boolean) => css`
  text-align: start;
  padding: ${isV3 ? '0px 0px 0px 24px' : '0'};
  line-height: ${isV3 ? '143%' : 'inherit'};
  font-size: ${isV3 ? '14px' : 'inherit'};
`
export const createQaStyles = (theme: ITheme, isV3 = false) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.medium}px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${isV3 ? 'rgba(0, 0, 0, 0.02)' : undefined};
  padding: ${isV3 ? '12px' : undefined};
  border-radius: ${isV3 ? '8px' : undefined};
`
