import Button from '@mui/material/Button'
import { observer } from 'mobx-react-lite'
import { useLanguage } from 'src/hooks'
import { IPolotnoComponent } from '../../types'

const ApplyMaskAction = observer<IPolotnoComponent>(({ store }) => {
  const hasLockedItems = store.selectedElements?.some(
    (element) => element.locked,
  )
  const { t } = useLanguage()

  const hasClip = !!(store.selectedElements[0] as any)['clipSrc']
  const handleClick = () => {
    store.openSidePanel('image-clip')
    const element = store.selectedElements[0] as any
    if (hasClip) {
      element.set({
        clipSrc: '',
      })
    }
  }

  return !hasLockedItems ? (
    <Button
      size="small"
      variant="text"
      disableElevation
      onClick={handleClick}
      sx={{
        flexShrink: 0,
      }}
    >
      {hasClip ? t('v3.remove_mask') : t('v3.apply_mask')}
    </Button>
  ) : null
})
export default ApplyMaskAction
