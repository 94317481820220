import { css } from '@emotion/react'

interface IButtonStyles {
  isOutlined?: boolean
}
export const buttonStyles = ({ isOutlined }: IButtonStyles) => css`
  ${isOutlined
    ? `
			background: transparent;
			color: var(--buttonBg) !important;
			border: 0.1em solid var(--buttonBg);
		`
    : `
			background: var(--buttonBg);
			color: var(--buttonText) !important;
			border: 0.1em solid var(--buttonBorder);
		`}
  text-decoration: none;
  padding: 0.2em 0.6em;
`
