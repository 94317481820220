import React, { useCallback, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { PropButton } from 'src/pages/deck-page/components/edit-properties/props'
import {
  ComponentMediaDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ComponentMediaZoomTypes } from 'src/types/api/enums'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { IEditPropSection } from '../../types'

export const ZoomTypes: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const selectedZoomType = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentMediaZoomTypes | undefined,
            c: UpdateComponentSchema,
          ) => {
            const zoomType = (c.data as ComponentMediaDataSchema).zoom?.type

            if (a === undefined && zoomType !== undefined) {
              return zoomType
            }

            return zoomType !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const changeZoomType = useCallback(
      (value: ComponentMediaZoomTypes) => {
        const partialData: DeepPartial<ComponentMediaDataSchema> = {
          zoom: {
            type: value,
          },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentMediaDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedZoomType],
    )

    return (
      <div className="grid">
        <div className="c-1">
          <PropButton
            text={t('edit.properties.zoom_types.fill')}
            isFocus={selectedZoomType === ComponentMediaZoomTypes.FILL}
            onClick={() => changeZoomType(ComponentMediaZoomTypes.FILL)}
          />
        </div>
        <div className="c-1">
          <PropButton
            text={t('edit.properties.zoom_types.fit')}
            isFocus={selectedZoomType === ComponentMediaZoomTypes.FIT}
            onClick={() => changeZoomType(ComponentMediaZoomTypes.FIT)}
          />
        </div>
        <div className="c-1">
          <PropButton
            text={t('edit.properties.zoom_types.distort')}
            isFocus={selectedZoomType === ComponentMediaZoomTypes.DISTORT}
            onClick={() => changeZoomType(ComponentMediaZoomTypes.DISTORT)}
          />
        </div>
        {/* <div className="c-1">
        <PropButton
          text={t('edit.properties.zoom_types.crop')}
          isFocus={selectedZoomType === ComponentMediaZoomTypes.CROP}
          onClick={() => changeZoomType(ComponentMediaZoomTypes.CROP)}
        />
      </div> */}
      </div>
    )
  },
)

ZoomTypes.displayName = 'ZoomTypes'
