import React, { useCallback, useMemo, useRef, useState } from 'react'

import { ISlideMenu } from './types'
import { slideMenuStyles } from './styles'
import {
  BUTTON_THEME,
  ContextMenu,
  Dialog,
  IContextMenu,
  Icon,
  Menu,
  Modal,
  icons,
} from 'src/lib'
import { useTheme } from 'src/theme'
import {
  VALIDATION_RULE_TYPES,
  useClickOutside,
  useLanguage,
  useLayoutsApi,
} from 'src/hooks'
import { useDispatch, useSelector } from 'react-redux'
import {
  SAVE_STATE,
  setSaveState,
  slideDelete,
  slideCreate,
  RootState,
  setAddSlideModal,
  setAllowDeleteShortcut,
} from 'src/store'

export const SlideMenu: React.FC<ISlideMenu> = React.memo(
  ({ data, isSwapColor, svgType, background, className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const menuRef = useRef(null)
    const { colors } = useTheme()
    const { saveLayout, updateLayout } = useLayoutsApi()
    const { layouts } = useSelector(({ layouts }: RootState) => ({
      layouts,
    }))

    const [isMenuOpen, setMenuOpen] = useState<IContextMenu['pos'] | null>(null)
    const [isReplaceConfirm, setReplaceConfirm] = useState<boolean>(false)
    const [saveLayoutDialog, setSaveLayoutDialog] = useState(false)
    const [newLayoutName, setNewLayoutName] = useState('')

    useClickOutside(
      menuRef,
      () => {
        setMenuOpen(null)
      },
      { events: ['wheel'] },
    )

    const handleOnClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setMenuOpen({ x: e.pageX, y: e.pageY })
    }, [])

    const onDeleteClick = useCallback(() => {
      if (data.slideId) {
        dispatch(slideDelete(data.slideId))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }
    }, [data.slideId])

    const onCreateClick = useCallback(() => {
      dispatch(setAddSlideModal({ isOpen: true, targetSlide: data }))
    }, [data])

    const onDuplicateClick = useCallback(() => {
      dispatch(
        slideCreate({
          targetSlide: data,
          isDuplicate: true,
          isSwapColor,
          svgType,
          background,
        }),
      )
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }, [data, svgType, background, isSwapColor])

    const existingLayoutId = useMemo(
      () => layouts.own.find(({ name }) => newLayoutName === name)?.id,
      [newLayoutName, layouts],
    )

    const onSaveLayout = useCallback(() => {
      if (existingLayoutId) {
        setReplaceConfirm(true)
      } else {
        saveLayout(newLayoutName, data.slideDataId)
        dispatch(setAllowDeleteShortcut(true))
      }
      setSaveLayoutDialog(false)
    }, [newLayoutName, existingLayoutId, data.slideDataId])

    const onReplaceLayout = useCallback(() => {
      existingLayoutId && updateLayout(existingLayoutId, data.slideDataId)
      setSaveLayoutDialog(false)
      setReplaceConfirm(false)
      dispatch(setAllowDeleteShortcut(true))
    }, [existingLayoutId, updateLayout, data.slideDataId])

    return (
      <>
        <div
          css={slideMenuStyles}
          onClick={handleOnClick}
          className={className}
          {...dataAttr}
        >
          <Icon
            icon={icons.menu_horizontal}
            color={colors.white.DEFAULT}
            size={20}
          />
          {isMenuOpen && (
            <ContextMenu pos={isMenuOpen} ref={menuRef} side={['top', 'left']}>
              <Menu
                shadow
                onItemClick={() => setMenuOpen(null)}
                items={[
                  {
                    label: t('common.deck.add_slide'),
                    onClick: onCreateClick,
                  },
                  {
                    label: t('common.deck.duplicate_slide'),
                    onClick: onDuplicateClick,
                  },
                  {
                    label: t('layout.save'),
                    onClick: () => {
                      setSaveLayoutDialog(true)
                      dispatch(setAllowDeleteShortcut(false))
                    },
                  },
                  {
                    label: t('common.deck.delete_slide'),
                    color: colors.error.DEFAULT,
                    onClick: onDeleteClick,
                  },
                ]}
              />
            </ContextMenu>
          )}
        </div>
        <Modal
          isOpen={saveLayoutDialog}
          onClose={() => {
            setSaveLayoutDialog(false)
            dispatch(setAllowDeleteShortcut(true))
          }}
        >
          <Dialog
            title={t('layout.save')}
            submitLabel={
              existingLayoutId ? t('layout.save') : t('layout.save_new')
            }
            onSubmit={onSaveLayout}
            promptPlaceholder={t('layout.name')}
            promptValue={newLayoutName}
            onPromptChange={setNewLayoutName}
            onCloseClick={() => {
              setSaveLayoutDialog(false)
              dispatch(setAllowDeleteShortcut(true))
            }}
            promptValidationRules={{
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  value: 3,
                  name: t('edit.slide_menu.layout'),
                }),
              },
            }}
          />
        </Modal>
        <Modal
          isOpen={isReplaceConfirm}
          onClose={() => setReplaceConfirm(false)}
        >
          <Dialog
            title={t('edit.slide_menu.warning')}
            text={[t('edit.slide_menu.warning_text')]}
            onCloseClick={() => setReplaceConfirm(false)}
            onSubmit={onReplaceLayout}
            cancelLabel={t('common.actions.cancel')}
            onCancel={() => {
              setReplaceConfirm(false)
              setSaveLayoutDialog(true)
              dispatch(setAllowDeleteShortcut(true))
            }}
            cancelTheme={BUTTON_THEME.SECONDARY}
            submitLabel={t('edit.slide_menu.replace')}
            submitTheme={BUTTON_THEME.RED}
          />
        </Modal>
      </>
    )
  },
)

// create MenuPortal, portal menu to a location, calculate viewport

SlideMenu.displayName = 'SlideMenu'
