import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import { CSSTransition } from 'react-transition-group'
import { IPropSection } from './types'
import {
  expandableAreaStyles,
  propSectionHeaderStyles,
  propSectionStyles,
  propSectionTitleStyles,
} from './styles'
import { Icon, icons } from 'src/lib'
import { useTheme } from 'src/theme'

const timeout = 150

export const PropSection: React.FC<IPropSection> = React.memo(
  ({
    children,
    title,
    initialHeight = 1000,
    initialState = false,
    expandable = false,
  }) => {
    const { colors } = useTheme()
    const expandableAreaRef = useRef<HTMLDivElement | null>(null)

    const [expanded, setExpanded] = useState(!expandable || initialState)
    const [init, setInit] = useState(false)
    const [currentHeight, setCurrentHeight] = useState(0)
    const [headerMargin, setHeaderMargin] = useState(!expandable)

    const changeExpanded = useCallback(
      () => setExpanded((prevState) => !prevState),
      [],
    )

    useEffect(() => {
      if (expandableAreaRef.current) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            if (entry.target === expandableAreaRef.current) {
              setCurrentHeight((prevHeight) =>
                Math.max(entry.contentRect.height, prevHeight),
              )
            }
          }
        })

        resizeObserver.observe(expandableAreaRef.current)

        return () => {
          resizeObserver.disconnect()
        }
      }
    }, [expandableAreaRef.current])

    const maxHeightStyleVars = useMemo(() => {
      return {
        '--expand-timeout': `${timeout}ms`,
        '--expand-area-max-height': `${init ? currentHeight : initialHeight}px`,
      } as React.CSSProperties
    }, [currentHeight, init, initialHeight])

    return (
      <div css={propSectionStyles} style={{ ...maxHeightStyleVars }}>
        {(title || expandable) && (
          <div
            css={propSectionHeaderStyles({
              expandable,
              transition: initialState ? init : true,
            })}
            className={headerMargin ? 'margin' : ''}
            onClick={() => expandable && changeExpanded()}
          >
            {title && <div css={propSectionTitleStyles}>{title}</div>}
            {expandable && (
              <Icon
                icon={expanded ? icons.chevron_up : icons.chevron_down}
                color={colors.white.DEFAULT}
                size={24}
              />
            )}
          </div>
        )}

        {expandable ? (
          <CSSTransition
            nodeRef={expandableAreaRef}
            in={expanded}
            timeout={timeout}
            onEntering={() => setHeaderMargin(true)}
            onEntered={() => setInit(true)}
            onExit={() => setHeaderMargin(false)}
            appear={!expandable || initialState}
          >
            <div
              ref={expandableAreaRef}
              css={expandableAreaStyles({ initialState })}
            >
              {children}
            </div>
          </CSSTransition>
        ) : (
          <div
            ref={expandableAreaRef}
            css={expandableAreaStyles({ initialState: true })}
          >
            {children}
          </div>
        )}
      </div>
    )
  },
)
