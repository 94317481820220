import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ComponentRadiusTypes } from 'src/types/api/enums'

export const imageStyles = css`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
`

export const videoStyles = css`
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

export const websiteStyles = ({ colors }: ITheme) => css`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.elements.DEFAULT};
`

export const playIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

interface IIconStyles {
  radiusType?: ComponentRadiusTypes
}
export const iconStyles =
  ({ radiusType }: IIconStyles) =>
  ({ borderRadius, colors }: ITheme) => css`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 15%;

    ${radiusType === ComponentRadiusTypes.OUTLINE &&
    `
			border-radius: 50%;
			
		`}

    ${radiusType === ComponentRadiusTypes.ROUNDED &&
    `
			border-radius: 20em;
			background: ${colors.dark2[70]};
		`}

    ${radiusType === ComponentRadiusTypes.CIRCLE &&
    `
			border-radius: ${borderRadius.DEFAULT};
			background: ${colors.dark2[70]};
		`}
  `

export const shapeWrapperStyles = ({ zIndex }: ITheme) => css`
  z-index: ${zIndex.icon};
  height: 100%;
  width: 100%;
  overflow: hidden;
`
