import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  display: flex;
  gap: 10px;
`
export const nameStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  line-height: 155%;
  align-self: center;
`
