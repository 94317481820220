import { css } from '@emotion/react'
import { ITheme, dimensions, gap } from 'src/theme'
import { CARD_LIST_ALIGN, ICardListLayout } from './type'

const calcWidth = (column: number): number =>
  dimensions.columnWidth.DEFAULT * column + gap.large * (column - 1)

type ICardListStyles = Pick<ICardListLayout, 'align'>
export const cardListStyles =
  ({ align }: ICardListStyles) =>
  ({ gap, breakpointsV2: breakpoints, dimensions }: ITheme) => css`
    display: inline-flex;
    justify-content: ${align === CARD_LIST_ALIGN.LEFT
      ? 'flex-start'
      : 'center'};
    flex-wrap: wrap;
    gap: ${gap.large}px;
    width: ${dimensions.columnWidth.DEFAULT}px;

    @media (min-width: ${breakpoints.tablet}px) {
      width: ${calcWidth(2)}px;
    }

    @media (min-width: ${breakpoints.laptop}px) {
      width: ${calcWidth(3)}px;
    }

    @media (min-width: ${breakpoints.desktop}px) {
      width: ${calcWidth(4)}px;
    }

    @media (min-width: ${breakpoints.large}px) {
      width: ${calcWidth(5)}px;
    }

    @media (min-width: ${breakpoints.xlarge}px) {
      width: ${calcWidth(6)}px;
    }

    @media (min-width: ${breakpoints.xxlarge}px) {
      width: ${calcWidth(7)}px;
    }
  `
