import { ComponentChartTypes, ComponentTypes } from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentChartDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const donutChartDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.donut_chart.display_name', { lng }),
      styles: {},
      icon: icons.donut,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.CHART,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 1200,
          height: 800,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          options: {
            type: ComponentChartTypes.DONUT,
            title: '',
            showGrids: true,
            legends: true,
            labels: {
              x: '',
              showX: false,
              y: '',
              showY: false,
            },
          },
          series: [
            {
              label: i18n.t('edit.definitions.donut_chart.label', { lng }),
              values: [
                {
                  name: i18n.t('edit.definitions.donut_chart.value1', { lng }),
                  value: 501.9,
                },
                {
                  name: i18n.t('edit.definitions.donut_chart.value2', { lng }),
                  value: 301.9,
                },
                {
                  name: i18n.t('edit.definitions.donut_chart.value3', { lng }),
                  value: 201.1,
                },
                {
                  name: i18n.t('edit.definitions.donut_chart.value4', { lng }),
                  value: 165.8,
                },
                {
                  name: i18n.t('edit.definitions.donut_chart.value5', { lng }),
                  value: 139.9,
                },
              ],
            },
          ],
        } as ComponentChartDataSchema,
      },
    ],
  }
}
