import React, { useCallback, useMemo } from 'react'

import { IDragPreview } from './types'
import { useDragLayer } from 'react-dnd'
import { CANVAS_ITEM_TYPE, CANVAS_TYPE } from '../../types'
import { dragPreviewStyles } from './styles'
import { DragController } from '../drag-controller'
import { ComponentSchema } from 'src/types/api/responseObjects'
import { DragRectEvent } from 'src/events'

export const DragPreview: React.FC<IDragPreview> = React.memo(({ scale }) => {
  const {
    item,
    itemType,
    isDragging,
    initialOffset,
    currentOffset,
    sourceOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getClientOffset(),
    sourceOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  const { x, y } = useMemo(() => {
    switch (itemType) {
      case CANVAS_ITEM_TYPE.CANVAS_ITEM:
        return {
          x: sourceOffset?.x || 0,
          y: sourceOffset?.y || 0,
        }
      default:
        return {
          x: currentOffset?.x || 0,
          y: currentOffset?.y || 0,
        }
    }
  }, [itemType, currentOffset, sourceOffset])

  const renderPreview = useCallback(() => {
    switch (itemType) {
      case CANVAS_ITEM_TYPE.CANVAS_ITEM:
        // Calculate the bounding box for all elements
        let minX = item[0].positions.x
        let minY = item[0].positions.y
        let maxX = item[0].positions.x + (item[0].positions.width || 0)
        let maxY = item[0].positions.y + (item[0].positions.height || 0)

        item.forEach((el: ComponentSchema) => {
          const elRight = el.positions.x + (el.positions.width || 0)
          const elBottom = el.positions.y + (el.positions.height || 0)

          if (el.positions.x < minX) minX = el.positions.x
          if (el.positions.y < minY) minY = el.positions.y
          if (elRight > maxX) maxX = elRight
          if (elBottom > maxY) maxY = elBottom
        })

        // Calculate the dimensions of the bounding box
        const boundingBoxWidth = maxX - minX
        const boundingBoxHeight = maxY - minY

        // Dispatch event with the bounding box dimensions
        const event = new DragRectEvent({
          top: y - (item[0].positions.y - minY) * scale,
          left: x - (item[0].positions.x - minX) * scale,
          width: boundingBoxWidth,
          height: boundingBoxHeight,
        })

        document.dispatchEvent(event)
        return (
          <>
            {item.map((el: ComponentSchema, index: number) => {
              const correctX = index
                ? x - (item[0].positions.x - el.positions.x) * scale
                : x
              const correctY = index
                ? y - (item[0].positions.y - el.positions.y) * scale
                : y

              const transform = `translate(${correctX}px, ${correctY}px) scale(${scale})`

              return (
                <div
                  key={el.id || el.tempId}
                  style={{
                    background: '#faa',
                    transformOrigin: 'left top',
                    transform,
                    WebkitTransform: transform,
                  }}
                >
                  <DragController
                    key={el.id || el.tempId}
                    hasBorder
                    scale={scale}
                    canvasType={CANVAS_TYPE.FIXED}
                    data={el}
                    zIndexList={[]}
                    isDragPreview={true}
                  />
                </div>
              )
            })}
          </>
        )
      case CANVAS_ITEM_TYPE.SIDEBAR_ITEM:
        return (
          <DragController
            hasBorder
            scale={scale}
            canvasType={CANVAS_TYPE.FIXED}
            data={item[0]}
            zIndexList={[]}
          />
        )
      default:
        return null
    }
  }, [item, itemType, x, y])

  const canRender = useMemo(
    () => initialOffset && currentOffset,
    [initialOffset, currentOffset],
  )

  return isDragging ? (
    <div css={dragPreviewStyles} data-font-family-exception="true">
      {canRender && (
        <div
          style={{
            background: '#faa',
            transformOrigin: 'left top',
            transform:
              itemType === CANVAS_ITEM_TYPE.CANVAS_ITEM
                ? ``
                : `translate(${x}px, ${y}px) scale(${scale})`,
            WebkitTransform:
              itemType === CANVAS_ITEM_TYPE.CANVAS_ITEM
                ? ``
                : `translate(${x}px, ${y}px) scale(${scale})`,
          }}
        >
          {renderPreview()}
        </div>
      )}
    </div>
  ) : null
})

DragPreview.displayName = 'DragPreview'
