import React, { useCallback, useMemo } from 'react'

import { IEditPropSection } from '../../types'
import { PropButton, PropSection } from '../../props'
import { useLanguage } from 'src/hooks'
import { icons } from 'src/lib'
import { ListTypes } from 'src/types/api/enums'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'

export const ListType: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedType = useMemo(
      () =>
        components?.reduce(
          (a: ListTypes | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).listType
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleTypeChange = useCallback(
      (value?: ListTypes) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          listType: value,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <PropSection
        title={t('edit.properties.list.list_type')}
        initialState={true}
        expandable
      >
        <div className="grid">
          <div className="c-1">
            <PropButton
              icon={icons.menu}
              onClick={() => handleTypeChange(ListTypes.PLAIN)}
              isFocus={selectedType === ListTypes.PLAIN}
            />
          </div>
          <div className="c-1">
            <PropButton
              icon={icons.list}
              onClick={() => handleTypeChange(ListTypes.BULLET)}
              isFocus={selectedType === ListTypes.BULLET}
            />
          </div>
          <div className="c-1">
            <PropButton
              icon={icons.numbered_list}
              onClick={() => handleTypeChange(ListTypes.NUMBER)}
              isFocus={selectedType === ListTypes.NUMBER}
            />
          </div>
        </div>
      </PropSection>
    )
  },
)

ListType.displayName = 'ListType'
