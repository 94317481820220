import React, { useCallback, useState } from 'react'

import { ILeaveOrganization } from './types'
import { buttonStyles, textStyles, titleStyles, wrapperStyles } from './styles'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  Dialog,
  Modal,
  icons,
} from 'src/lib'
import { useLanguage } from 'src/hooks'
import { useOrgApi } from 'src/hooks/api/useOrgApi'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, setWorkspaceId } from 'src/store'
import { useNavigate } from 'react-router-dom'

export const LeaveOrganization: React.FC<ILeaveOrganization> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const { leaveOrganization } = useOrgApi()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [openModal, setModal] = useState(false)

    const openModalHandler = () => {
      setModal(!openModal)
    }

    const clickOutsideHandler = () => {
      setModal(false)
    }

    const { orgID, isOwner, orgs, userId } = useSelector(
      ({ org, user }: RootState) => ({
        orgID: org.details?.id,
        isOwner: org.details?.ownerUser.id === user.data?.id,
        orgs: user.organizationUsers,
        userId: user.data?.id,
      }),
    )

    const onLeaveOrganization = useCallback(async () => {
      if (orgID) {
        await leaveOrganization(orgID)
        const ownedOrgId = orgs?.find(
          (organization) => organization.organization.ownerUserId === userId,
        )?.organization.id
        if (ownedOrgId) dispatch(setWorkspaceId(ownedOrgId))
        else dispatch(setWorkspaceId())
        navigate('/dashboard', { replace: true })
      }
    }, [orgID, orgs])

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div css={titleStyles}>{t('organization.leave_team_title')}</div>
        <div css={textStyles}>{t('organization.leave_team_text')}</div>
        <Button
          text={t('organization.leave_team_button')}
          size={BUTTON_SIZE.AUTO}
          type={BUTTON_TYPE.REVERSE}
          css={buttonStyles}
          icon={icons.log_out}
          theme={BUTTON_THEME.RED}
          iconSize={16}
          onClick={openModalHandler}
          disabled={isOwner}
        />
        {openModal && (
          <Modal isOpen={true} onClose={clickOutsideHandler}>
            <Dialog
              title={t('organization.leave_team_button')}
              submitLabel={t('common.actions.leave')}
              cancelLabel={t('common.actions.cancel')}
              submitTheme={BUTTON_THEME.RED}
              text={[t('organization.leave_team_dialog_text')]}
              onSubmit={onLeaveOrganization}
              onCancel={openModalHandler}
              onCloseClick={openModalHandler}
            />
          </Modal>
        )}
      </div>
    )
  },
)
