import React from 'react'
import { observer } from 'mobx-react-lite'
// import toolbar components
import { NumericInput, Navbar, Alignment } from '@blueprintjs/core'
import ColorPicker from 'polotno/toolbar/color-picker'
import {
  unstable_registerShapeComponent,
  unstable_registerShapeModel,
  unstable_registerToolbarComponent,
  unstable_registerTransformerAttrs,
} from 'polotno/config'
import { HtmlWrapper } from '../html-wrapper'
import { Input } from 'src/lib'
import { IShapeProps, CustomShapeType, IToolbarProps } from '../../types'
import { IButtonProps, IButtonModel } from './types'

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    { text, backgroundColor, color, fontSize, borderRadius, width, height },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        style={{
          width,
          height,
          backgroundColor,
          border: 'none',
          color,
          fontSize,
          cursor: 'pointer',
          borderRadius,
        }}
      >
        {text}
      </button>
    )
  },
)

const CanvasButton = observer(({ element }: IShapeProps<IButtonModel>) => {
  const customTransform = (scaleX: number) => ({
    fontSize: element.fontSize * scaleX,
  })

  return (
    <HtmlWrapper element={element} customTransform={customTransform}>
      <Button
        width={element.width}
        height={element.height}
        text={element.text}
        backgroundColor={element.backgroundColor}
        color={element.color}
        fontSize={element.fontSize}
        borderRadius={element.borderRadius}
      />
    </HtmlWrapper>
  )
})

unstable_registerShapeModel({
  type: 'button',
  backgroundColor: 'black',
  color: 'white',
  fontSize: 20,
  borderRadius: 10,
  text: 'Button text',
} as CustomShapeType<IButtonModel>)

unstable_registerShapeComponent('button', CanvasButton as any)
unstable_registerTransformerAttrs('button', {
  enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
})

// now we can define custom toolbar
const ButtonToolbar = observer(({ store }: IToolbarProps) => {
  const element = store.selectedElements[0] as CustomShapeType<IButtonModel>

  return (
    <Navbar.Group align={Alignment.LEFT}>
      <ColorPicker
        value={element.backgroundColor}
        onChange={(backgroundColor) =>
          element.set({
            backgroundColor,
          })
        }
        store={store}
      />
      <NumericInput
        onValueChange={(val) => {
          element.set({ fontSize: val })
        }}
        value={element.fontSize}
        style={{ width: '50px', marginLeft: '10px' }}
        min={1}
        max={500}
      />
      <Input value={element.text} onChange={(text) => element.set({ text })} />
    </Navbar.Group>
  )
})

unstable_registerToolbarComponent('button', ButtonToolbar)
