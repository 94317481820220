import React, { useCallback, useMemo } from 'react'

import { IEditPropSection } from '../../types'
import { PropSection } from '../../props'
import { useLanguage } from 'src/hooks'
import {
  DROPDOWN_SIZE,
  DROPDOWN_THEME,
  DROPDOWN_WIDTH,
  Dropdown,
  IDropdownItem,
} from 'src/lib'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { APP_CONFIG } from 'src/config'
import { ListItemImageTypes } from 'src/types/api/enums'

export const ListFontSize: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t, lang } = useLanguage()
    const dispatch = useDispatch()

    const selectedFontSize = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const targetField = (c.data as ComponentListDataSchema)?.style?.font
            ?.size
          if (a === '' && targetField) {
            return targetField
          }

          return targetField !== a ? 'multi' : a
        }, ''),
      [components],
    )

    const selectedFontSizeValue = useMemo(() => {
      return selectedFontSize === 'multi' ? '' : selectedFontSize
    }, [selectedFontSize])

    const selectedFontBodySize = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const targetField = (c.data as ComponentListDataSchema)?.style
            ?.fontBody?.size
          if (a === '' && targetField) {
            return targetField
          }

          return targetField !== a ? 'multi' : a
        }, ''),
      [components],
    )

    const selectedFontBodySizeValue = useMemo(() => {
      return selectedFontBodySize === 'multi' ? '' : selectedFontBodySize
    }, [selectedFontBodySize])

    const handleFontSizeChange = useCallback(
      (value?: IDropdownItem) => {
        const fontSize = isNaN(parseInt(value?.value as string))
          ? '0em'
          : (value?.value as string)

        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: { font: { size: fontSize } },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const selectedMediaType = useMemo(
      () =>
        components?.reduce(
          (a: ListItemImageTypes | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).listItems[0]
              .image?.type
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleFontBodySizeChange = useCallback(
      (value?: IDropdownItem) => {
        const fontSize = isNaN(parseInt(value?.value as string))
          ? '0em'
          : (value?.value as string)

        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: { fontBody: { size: fontSize } },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const isLogo = useMemo(
      () => selectedMediaType === ListItemImageTypes.LOGO,
      [selectedMediaType],
    )

    const selectedIsGrid = useMemo(
      () =>
        components?.reduce(
          (a: boolean | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).isGrid
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const displayBodySize = useMemo(
      () => !(isLogo || selectedIsGrid),
      [selectedIsGrid, isLogo],
    )

    const textTitle = useMemo(() => {
      if (selectedMediaType === ListItemImageTypes.LOGO) {
        return t('edit.properties.list.font_logo')
      } else {
        return t('edit.properties.list.font_title')
      }
    }, [selectedMediaType, lang])

    return (
      <>
        <PropSection
          title={t(
            isLogo
              ? 'edit.properties.list.size'
              : 'edit.properties.list.font_size',
          )}
          initialState={true}
          expandable
        >
          <div className="grid">
            <div className="c-1">{textTitle}</div>
            {displayBodySize && (
              <div className="c-1">{t('edit.properties.list.font_body')}</div>
            )}
          </div>
          <div className="grid">
            <div className="c-1">
              <Dropdown
                width={DROPDOWN_WIDTH.FULL}
                theme={DROPDOWN_THEME.DARK}
                size={DROPDOWN_SIZE.BIG}
                selected={selectedFontSizeValue}
                onChange={handleFontSizeChange}
                onInputFocus={() => dispatch(setAllowDeleteShortcut(false))}
                onInputBlur={() => dispatch(setAllowDeleteShortcut(true))}
                placeholder={t('edit.list.submenu.placeholder')}
                flexValue
                flexFormatters={[
                  (val) =>
                    !isNaN(parseFloat(val)) ? parseFloat(val).toString() : '',
                  (val) => `${val}em`,
                ]}
                items={APP_CONFIG.editPage.fontSizeOptions}
              />
            </div>
            {displayBodySize && (
              <div className="c-1">
                <Dropdown
                  width={DROPDOWN_WIDTH.FULL}
                  theme={DROPDOWN_THEME.DARK}
                  size={DROPDOWN_SIZE.BIG}
                  selected={selectedFontBodySizeValue}
                  onChange={handleFontBodySizeChange}
                  onInputFocus={() => dispatch(setAllowDeleteShortcut(false))}
                  onInputBlur={() => dispatch(setAllowDeleteShortcut(true))}
                  placeholder={t('edit.list.submenu.placeholder')}
                  flexValue
                  flexFormatters={[
                    (val) =>
                      !isNaN(parseFloat(val)) ? parseFloat(val).toString() : '',
                    (val) => `${val}em`,
                  ]}
                  items={APP_CONFIG.editPage.fontSizeOptions}
                />
              </div>
            )}
          </div>
        </PropSection>
      </>
    )
  },
)

ListFontSize.displayName = 'ListFontSize'
