import React, { Fragment } from 'react'

import { Icon } from 'src/lib'
import { ISideMenu } from './types'
import { sideMenuItemStyles, wrapperStyles } from './styles'

export const SideMenu: React.FC<ISideMenu> = React.memo(
  ({ items, className, dataAttr }) => {
    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        {items
          .filter(({ hidden }) => !hidden)
          .map(({ label, icon, isActive, onClick, separator }, index) => (
            <Fragment key={index}>
              {separator ? (
                <span />
              ) : (
                <div css={sideMenuItemStyles({ isActive })} onClick={onClick}>
                  {icon && <Icon className="icon" icon={icon} />}
                  {label}
                </div>
              )}
            </Fragment>
          ))}
      </div>
    )
  },
)

SideMenu.displayName = 'SideMenu'
