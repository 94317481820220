import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const voiceRecordingStyles = (theme: ITheme) => css`
  z-index: 1;
  background: ${theme.colors.white.DEFAULT};
  width: 142px;
  height: 84px;
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-flow: column;
  padding: ${theme.gap['3xsmall']}px 0 ${theme.gap['3xsmall']}px 0;
  box-shadow: ${theme.boxShadow[2]};
  border-radius: ${theme.borderRadius[4]};
`

export const rowStyles = (isActive: boolean) => (theme: ITheme) => css`
  display: flex;
  height: 38px;
  align-items: center;
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  gap: ${theme.gap['2xsmall']}px;
  padding: ${theme.gap.xsmall}px ${theme.gap.small}px;
  color: ${isActive ? theme.colors.text.DEFAULT : theme.colors.text[2]};
  cursor: ${isActive ? 'pointer' : 'not-allowed'};
  &:hover {
    background: ${theme.gradient.VOICE};
  }
`
