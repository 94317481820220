import React from 'react'
import { useTheme } from 'src/theme'
import { ICanvasGrid } from './types'
import { MathServices } from 'src/services'

export const CanvasGrid: React.FC<ICanvasGrid> = React.memo(({ lines }) => {
  const { colors } = useTheme()
  const gridParams = MathServices.gridParams()

  return (
    <>
      <svg
        id="canvas-grid"
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        style={{ zIndex: 111, position: 'absolute', pointerEvents: 'none' }}
      >
        <defs>
          <pattern
            id="smallGrid"
            width={gridParams.width}
            height={gridParams.height}
            patternUnits="userSpaceOnUse"
          >
            <path
              d={`M ${gridParams.width} 0 L 0 0 0 ${gridParams.height}`}
              fill="none"
              stroke={colors.primary.DEFAULT}
              strokeWidth="1"
            />
          </pattern>
          <pattern
            id="grid"
            width={gridParams.width * 10}
            height={gridParams.height * 10}
            patternUnits="userSpaceOnUse"
          >
            <rect
              width={gridParams.width * 10}
              height={gridParams.height * 10}
              fill="url(#smallGrid)"
            />
            <path
              d={`M ${gridParams.width * 10} 0 L 0 0 0 ${
                gridParams.height * 10
              }`}
              fill="none"
              stroke={colors.primary[60]}
              strokeWidth="1"
            />
          </pattern>
        </defs>
        <rect width="1920" height="1080" fill="url(#grid)" />
        {lines?.x[0] && (
          <line
            x1={lines?.x[0]}
            y1="0"
            x2={lines?.x[0]}
            y2="1080"
            stroke={colors.error[90]}
            strokeWidth="3"
            strokeDasharray="10"
          />
        )}
        {lines?.y[0] && (
          <line
            x1="0"
            y1={lines?.y[0]}
            x2="1920"
            y2={lines?.y[0]}
            stroke={colors.error[90]}
            strokeWidth="3"
            strokeDasharray="10"
          />
        )}
      </svg>
    </>
  )
})

CanvasGrid.displayName = 'CanvasGrid'
