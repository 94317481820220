export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_985)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_1_985"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM958 60C959.105 60 960 60.8955 960 62V1018C960 1019.1 959.105 1020 958 1020H62C60.8954 1020 60 1019.1 60 1018V62C60 60.8954 60.8954 60 62 60H958Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1450.55 67.4476C1449.77 68.2287 1449.77 69.495 1450.55 70.276L1920 539.724V0H1518L1450.55 67.4476Z" fill="url(#paint0_linear_1_1016)"/><path d="M1043.09 991.448C1043.87 992.229 1043.87 993.495 1043.09 994.276L957.362 1080H189.914L129.914 1020H958C959.104 1020 960 1019.1 960 1018V908.362L1043.09 991.448Z" fill="url(#paint1_linear_1_1016)"/><defs><linearGradient id="paint0_linear_1_1016" x1="2391.7" y1="68.0867" x2="1449.14" y2="68.0867" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient><linearGradient id="paint1_linear_1_1016" x1="1959.05" y1="930.014" x2="129.914" y2="930.014" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM958 60C959.105 60 960 60.8955 960 62V1018C960 1019.1 959.105 1020 958 1020H62C60.8954 1020 60 1019.1 60 1018V62C60 60.8954 60.8954 60 62 60H958Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1450.55 1012.55C1449.77 1011.77 1449.77 1010.51 1450.55 1009.72L1920 540.276V1080H1518L1450.55 1012.55Z" fill="url(#paint0_linear_1_1029)"/><defs><linearGradient id="paint0_linear_1_1029" x1="2391.7" y1="1011.91" x2="1449.14" y2="1011.91" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM962 60C960.895 60 960 60.8955 960 62V1018C960 1019.1 960.895 1020 962 1020H1858C1859.1 1020 1860 1019.1 1860 1018V62C1860 60.8954 1859.1 60 1858 60H962Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M469.448 67.4476C470.229 68.2287 470.229 69.495 469.448 70.276L0 539.724V0H402L469.448 67.4476Z" fill="url(#paint0_linear_1_1009)"/><path d="M876.914 991.447C876.133 992.228 876.133 993.494 876.914 994.276L962.638 1080H1730.09L1790.09 1020H962C960.896 1020 960 1019.1 960 1018V908.361L876.914 991.447Z" fill="url(#paint1_linear_1_1009)"/><defs><linearGradient id="paint0_linear_1_1009" x1="-471.698" y1="68.0867" x2="470.862" y2="68.0867" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient><linearGradient id="paint1_linear_1_1009" x1="-39.0516" y1="930.014" x2="1790.09" y2="930.014" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM962 60C960.895 60 960 60.8955 960 62V1018C960 1019.1 960.895 1020 962 1020H1858C1859.1 1020 1860 1019.1 1860 1018V62C1860 60.8954 1859.1 60 1858 60H962Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M469.448 1012.55C470.229 1011.77 470.229 1010.51 469.448 1009.72L0 540.276V1080H402L469.448 1012.55Z" fill="url(#paint0_linear_1_1023)"/><defs><linearGradient id="paint0_linear_1_1023" x1="-471.698" y1="1011.91" x2="470.862" y2="1011.91" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1066)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path d="M805.645 0L1357.29 551.645L828.935 1080H0V0H805.645Z" fill="url(#paint0_linear_1_1066)"/></g><defs><linearGradient id="paint0_linear_1_1066" x1="339.5" y1="-6.71522e-09" x2="333.5" y2="1150.5" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient><clipPath id="clip0_1_1066"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1069)"><rect width="1920" height="1080" transform="matrix(-1 0 0 1 1920 0)" fill="url(#decor-bg)"/><path d="M1114.35 0L562.71 551.645L1091.07 1080H1920V0H1114.35Z" fill="url(#paint0_linear_1_1069)"/></g><defs><linearGradient id="paint0_linear_1_1069" x1="1580.5" y1="-6.71522e-09" x2="1586.5" y2="1150.5" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient><clipPath id="clip0_1_1069"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_3:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path d="M1511 99.8616L1610.86 -0.000258791L1920 -0.000248002L1920 508.862L1511 99.8616Z" fill="url(#paint0_linear_1_1072)"/><path d="M0 790.139L217.309 1007.45C218.09 1008.23 218.09 1009.5 217.309 1010.28L147.585 1080H2.15792e-05L0 790.139Z" fill="url(#paint1_linear_1_1072)"/><defs><linearGradient id="paint0_linear_1_1072" x1="1323.61" y1="-532.199" x2="2142.33" y2="286.527" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient><linearGradient id="paint1_linear_1_1072" x1="172.781" y1="672.543" x2="-117.339" y2="962.662" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_4:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" transform="matrix(-1 0 0 1 1920 0)" fill="url(#decor-bg)"/><path d="M409 99.8625L309.138 0.000717771L0.000248002 0.000728561L0.000258791 508.863L409 99.8625Z" fill="url(#paint0_linear_1_1076)"/><path d="M1920 790.139L1702.69 1007.45C1701.91 1008.23 1701.91 1009.5 1702.69 1010.28L1772.41 1080H1920L1920 790.139Z" fill="url(#paint1_linear_1_1076)"/><defs><linearGradient id="paint0_linear_1_1076" x1="596.393" y1="-532.198" x2="-222.334" y2="286.528" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient><linearGradient id="paint1_linear_1_1076" x1="1747.22" y1="672.543" x2="2037.34" y2="962.662" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM62 540C60.8954 540 60 540.895 60 542V1018C60 1019.1 60.8954 1020 62 1020H1858C1859.1 1020 1860 1019.1 1860 1018V542C1860 540.895 1859.1 540 1858 540H62Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M469.448 67.4476C470.229 68.2287 470.229 69.495 469.448 70.276L0 539.724V0H402L469.448 67.4476Z" fill="url(#paint0_linear_1_1055)"/><path d="M876.914 991.448C876.133 992.229 876.133 993.495 876.914 994.276L962.638 1080H1730.09L1790.09 1020H962C960.896 1020 960 1019.1 960 1018V908.362L876.914 991.448Z" fill="url(#paint1_linear_1_1055)"/><defs><linearGradient id="paint0_linear_1_1055" x1="-471.698" y1="68.0867" x2="470.862" y2="68.0867" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient><linearGradient id="paint1_linear_1_1055" x1="-39.0516" y1="930.014" x2="1790.09" y2="930.014" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V542C0 540.895 0.895431 540 2 540H1918C1919.1 540 1920 540.895 1920 542V0ZM1920 1078C1920 1079.1 1919.1 1080 1918 1080H1920V1078ZM1.99997 1080C0.8954 1080 0 1079.1 0 1078V1080H1.99997Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M958 0H1920V1080H958C959.105 1080 960 1079.1 960 1078V2.00001C960 0.895436 959.105 0 958 0ZM2 0C0.895508 0 0 0.895431 0 2V0H2ZM0 1078C0 1079.1 0.895386 1080 2 1080H0V1078Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1450.55 1012.55C1449.77 1011.77 1449.77 1010.51 1450.55 1009.72L1920 540.276V1080H1518L1450.55 1012.55Z" fill="url(#paint0_linear_1_1041)"/><defs><linearGradient id="paint0_linear_1_1041" x1="2391.7" y1="1011.91" x2="1449.14" y2="1011.91" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M958 0H1920V1080H958C959.105 1080 960 1079.1 960 1078V2.00001C960 0.895436 959.105 0 958 0ZM2 0C0.895508 0 0 0.895431 0 2V0H2ZM0 1078C0 1079.1 0.895386 1080 2 1080H0V1078Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M962 0H0V1080H962C960.895 1080 960 1079.1 960 1078V2.00001C960 0.895436 960.895 0 962 0ZM1918 0C1919.1 0 1920 0.895431 1920 2V0H1918ZM1920 1078C1920 1079.1 1919.1 1080 1918 1080H1920V1078Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M469.448 1012.55C470.229 1011.77 470.229 1010.51 469.448 1009.72L0 540.276V1080H402L469.448 1012.55Z" fill="url(#paint0_linear_1_1035)"/><defs><linearGradient id="paint0_linear_1_1035" x1="-471.698" y1="1011.91" x2="470.862" y2="1011.91" gradientUnits="userSpaceOnUse"><stop stop-color="$color1" stop-opacity="0"/><stop offset="1" stop-color="$color4" stop-opacity="0.2"/></linearGradient></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M962 0H0V1080H962C960.895 1080 960 1079.1 960 1078V2.00001C960 0.895436 960.895 0 962 0ZM1918 0C1919.1 0 1920 0.895431 1920 2V0H1918ZM1920 1078C1920 1079.1 1919.1 1080 1918 1080H1920V1078Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
