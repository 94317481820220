import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAllowDeleteShortcut } from 'src/store'
import { IFormText } from './types'
import { FormQuestionTypes } from 'src/types/api/enums'
import { useDebouncer } from 'src/hooks'
import { textInputStyles } from './styles'

export const FormText: React.FC<IFormText> = React.memo(
  ({ formDTO, question, options, formDTOHandler }) => {
    const dispatch = useDispatch()

    const answerText = useMemo(() => {
      return formDTO?.data[question.questionId]
    }, [formDTO?.data])

    const [textValue, setTextValue] = useState(answerText || '')

    const onChangeHandler = useCallback(() => {
      if (textValue && formDTO) {
        formDTOHandler({
          id: formDTO.id,
          state: formDTO.state,
          data: { ...formDTO?.data, [question.questionId]: textValue },
        })
      }
    }, [formDTO, textValue, question.questionId])

    const debounced = useDebouncer(
      () => {
        onChangeHandler()
      },
      { delay: 300 },
    )

    useEffect(() => {
      if (textValue) debounced()
    }, [textValue])

    return (
      <input
        css={textInputStyles}
        placeholder={
          options.defaultTexts.questionAnswerPlaceholders[
            FormQuestionTypes.TEXT
          ]
        }
        onFocus={() => dispatch(setAllowDeleteShortcut(false))}
        onBlur={() => dispatch(setAllowDeleteShortcut(true))}
        tabIndex={-1}
        value={textValue}
        onChange={(e) => setTextValue(e.currentTarget.value)}
      />
    )
  },
)
