import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - ${theme.dimensions.headerHeight.DEFAULT}px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.gradient.DEFAULT};
  overflow: hidden;
`
