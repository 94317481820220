import { Alignment, Navbar } from '@blueprintjs/core'
import { observer } from 'mobx-react-lite'
import ColorPickerElement from './ColorPickerElement'
import TransparencyAction from './TransparencyAction'
import { IPolotnoComponent } from '../../types'

const TextFillActions = observer<IPolotnoComponent>(({ store }) => {
  const allTextItems = store.selectedElements?.every(
    (element) => element.type === 'text',
  )
  return allTextItems ? (
    <Navbar.Group align={Alignment.LEFT}>
      <TransparencyAction store={store} />
      <ColorPickerElement store={store} prop={'fill'} />
    </Navbar.Group>
  ) : null
})
export default TextFillActions
