import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { DIALOG_PADDING, DIALOG_THEME, IDialog } from './types'

type IWrapperStyles = Pick<IDialog, 'theme' | 'padding'>

export const wrapperStyles = (props: IWrapperStyles) => (theme: ITheme) => css`
  position: relative;
  box-shadow: ${theme.boxShadow[2]};
  padding: ${props.padding === DIALOG_PADDING.BIG
    ? theme.spacingV2['4xlarge']
    : theme.spacingV2.xlarge};
  background: ${props.theme === DIALOG_THEME.DARK
    ? theme.colors.dark2.DEFAULT
    : theme.colors.white.DEFAULT};
  color: ${props.theme === DIALOG_THEME.DARK
    ? theme.colors.white.DEFAULT
    : theme.colors.text.DEFAULT};

  display: flex;
  flex-direction: column;
  ${props.theme === DIALOG_THEME.COLORFUL
    ? `
		align-items: center;
		strong {
			color: ${theme.colors.primary.DEFAULT};
		}`
    : ``}
  width: 100%;
  border-top-left-radius: ${theme.borderRadius[8]};
  border-top-right-radius: ${theme.borderRadius[8]};

  .separator {
    flex-grow: 1;
  }

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: 430px;
    height: auto;
    border-radius: ${theme.borderRadius[8]};

    .separator {
      display: none;
    }
  }
`

export const closeButtonAreaStyles = (theme: ITheme) => css`
  position: absolute;
  top: ${theme.spacingV2.xlarge};
  right: ${theme.spacingV2.xlarge};
`

export const imageStyles = (theme: ITheme) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.spacingV2.xlarge};

  img {
    width: 180px;
  }
`
type ITitleStyles = Pick<IDialog, 'theme'>
export const titleStyles = (props: ITitleStyles) => (theme: ITheme) => css`
  font-size: ${theme.fontSize.xlarge};
  font-weight: ${theme.fontWeight.medium};
  margin-bottom: ${props.theme === DIALOG_THEME.COLORFUL
    ? theme.spacingV2.xsmall
    : theme.spacingV2.xlarge};

  color: ${props.theme === DIALOG_THEME.COLORFUL
    ? theme.colors.primary.DEFAULT
    : props.theme === DIALOG_THEME.DARK
      ? theme.colors.white.DEFAULT
      : theme.colors.dark.DEFAULT};
`

type ITextStyles = Pick<IDialog, 'theme'>
export const textStyles = (props: ITextStyles) => (theme: ITheme) => css`
  margin-bottom: ${theme.spacingV2.xlarge};
  font-size: ${theme.fontSize.medium};

  ${props.theme === DIALOG_THEME.COLORFUL
    ? `
		color: ${theme.colors.text[2]};
	`
    : ``}

  p {
    word-break: break-word;

    &:not(:last-child) {
      margin-bottom: ${theme.spacingV2.small};
    }
  }
`
type IButtonAreaStyles = Pick<IDialog, 'theme'>
export const buttonAreaStyles =
  ({ theme: dialogTheme }: IButtonAreaStyles) =>
  (theme: ITheme) => css`
    display: flex;
    width: 100%;
    justify-content: ${dialogTheme === DIALOG_THEME.COLORFUL
      ? 'stretch'
      : 'flex-end'};
    gap: ${theme.spacingV2.xlarge};
  `

export const inputAreaStyles = (theme: ITheme) => css`
  margin-bottom: ${theme.spacingV2.xlarge};
  width: 100%;
`
