import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { IPolotnoComponent } from '../../types'

const DeleteAction = observer<IPolotnoComponent>(({ store }) => {
  const handleClick = () => {
    store.deleteElements(
      store.selectedElements
        .filter((element) => element.removable)
        .map((element) => element.id),
    )
  }

  return (
    <BaseIconButton
      onClick={handleClick}
      sx={{
        padding: '6px',
      }}
      tooltip="v3.delete_elements"
    >
      <MaterialSymbol icon="delete" size={20} weight={300} />
    </BaseIconButton>
  )
})

export default DeleteAction
