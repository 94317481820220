import React from 'react'
import { useLanguage } from 'src/hooks'
import { textPropsStyles } from './styles'
import { PropHeader } from 'src/pages/deck-page/components/edit-properties/props'
import { TOOLTIP_THEME, Tooltip, icons } from 'src/lib'
import { QuillButton } from 'src/lib/quill-button'
import { useTheme } from 'src/theme'
import { PropDropdown } from 'src/pages/deck-page/components/edit-properties/props'
import { PropTextAlignment } from './PropTextAlignment'
import { PropTextType } from './PropTextType'
import { PropTextSize } from './PropTextSize'
import { PropFontFamily } from './PropFontFamily'
import { PropTextFillColor } from './PropTextFillColor'
import { IEditPropSection } from '../../types'
import { PropTextVAlignment } from './PropTextVAlignment'
import { PropTextOpacity } from './PropTextOpacity'

export const TextProps: React.FC<IEditPropSection> = React.memo(
  ({ components, className, dataAttr }) => {
    const { t } = useLanguage()
    const { colors } = useTheme()
    return (
      <>
        <div css={textPropsStyles} className={className} {...dataAttr}>
          <PropHeader text={t('edit.properties.text_props.header')} />
          <div className="grid">
            <div className="c-full">
              <PropTextType components={components} />
            </div>
          </div>
          <div className="grid">
            <div className="c-3 c-t-2">
              <PropFontFamily components={components} />
            </div>
            <div className="c-1">
              <PropTextSize components={components} />
            </div>
          </div>
          <div className="grid">
            <div className="c-1">
              <QuillButton
                className="quill-button"
                quillHandler="ql-bold"
                icon={icons.bold}
                color={colors.white.DEFAULT}
              />
            </div>
            <div className="c-1">
              <QuillButton
                className="quill-button"
                quillHandler="ql-italic"
                icon={icons.italic}
                color={colors.white.DEFAULT}
              />
            </div>
            <div className="c-1">
              <QuillButton
                className="quill-button"
                quillHandler="ql-underline"
                icon={icons.underlined}
                color={colors.white.DEFAULT}
              />
            </div>
            <div className="c-1">
              <QuillButton
                className="quill-button"
                quillHandler="ql-link"
                icon={icons.hyperlink}
                color={colors.white.DEFAULT}
              />
            </div>
            <div className="c-1">
              <PropTextAlignment components={components} />
            </div>
            <div className="c-1">
              <PropTextVAlignment components={components} />
            </div>
            <div className="c-1">
              <PropDropdown icon={icons.list}>
                <>
                  <QuillButton
                    quillHandler="ql-list"
                    quillValue="ordered"
                    icon={icons.numbered_list}
                    color={colors.white.DEFAULT}
                  />
                  <QuillButton
                    quillHandler="ql-list"
                    quillValue="bullet"
                    icon={icons.list}
                    color={colors.white.DEFAULT}
                  />
                </>
              </PropDropdown>
            </div>
            <div className="c-1">
              <Tooltip
                theme={TOOLTIP_THEME.DARK}
                text={t('edit.properties.text_props.reset')}
              >
                <div>
                  <QuillButton
                    className="quill-button"
                    quillHandler="ql-clean"
                    icon={icons.retry}
                    color={colors.white.DEFAULT}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div>
          <div className="grid">
            <div className="c1">
              <PropHeader text={t('edit.properties.text_props.fill')} />
              <PropTextFillColor components={components} />
            </div>
          </div>
          <div className="grid">
            <div className="c-full">
              <PropHeader text={t('edit.properties.text_props.opacity')} />
              <PropTextOpacity components={components} />
            </div>
          </div>
        </div>
      </>
    )
  },
)

TextProps.displayName = 'TextProps'
