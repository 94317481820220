import React, { useCallback, useMemo } from 'react'

import { IEditPropSection } from '../../types'
import { PropSection } from '../../props'
import { useLanguage } from 'src/hooks'
import { INPUT_THEME, Input, Switch } from 'src/lib'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { ListLayoutDirections, ListTypes } from 'src/types/api/enums'
import { autoLayoutRules } from 'src/config'

export const ListLayout: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedType = useMemo(
      () =>
        components?.reduce(
          (a: ListTypes | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).listType
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedAutoLayout = useMemo(
      () =>
        components?.reduce(
          (a: boolean | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).style.layout
              .auto
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleAutoLayoutChange = useCallback(
      (value?: boolean) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: {
            layout: {
              auto: value,
            },
          },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const selectedDirection = useMemo(
      () =>
        components?.reduce(
          (a: ListLayoutDirections | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).style.layout
              .direction
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedDivision = useMemo(
      () =>
        components?.reduce(
          (a: number | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).style.layout
              .division
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedItemsCount = useMemo(
      () =>
        components?.reduce(
          (a: number | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).listItems
              .length
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const currentDivision = useMemo(() => {
      const itemCount = selectedItemsCount || 1
      return selectedAutoLayout
        ? autoLayoutRules[selectedType || ListTypes.PLAIN][
            selectedDirection || ListLayoutDirections.HORIZONTAL
          ][itemCount] || Math.ceil(itemCount / 2)
        : selectedDivision
    }, [
      selectedAutoLayout,
      selectedType,
      selectedType,
      selectedDirection,
      selectedItemsCount,
      selectedDivision,
    ])

    const handleDivisionChange = useCallback(
      (division: number) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: {
            layout: {
              division,
            },
          },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <PropSection
        title={t('edit.properties.list.layout')}
        initialState={false}
        expandable
      >
        <div className="grid">
          <div className="c-1 control-row control-row-big">
            {t('common.auto')}
          </div>
          <div className="c-1 control-row control-row-big">
            <Switch
              selected={selectedAutoLayout}
              onChange={handleAutoLayoutChange}
            />
          </div>
          <div className="c-1 control-row control-row-big">
            {selectedDirection === ListLayoutDirections.HORIZONTAL
              ? t('edit.list.layout.columns')
              : t('edit.list.layout.rows')}
          </div>
          <div className="c-1 control-row">
            <Input
              theme={INPUT_THEME.DARK}
              type="number"
              numberValue={currentDivision}
              onChangeNumber={handleDivisionChange}
              numberMin={1}
              numberMax={Math.min(10, selectedItemsCount || 1)}
              onFocus={() => dispatch(setAllowDeleteShortcut(false))}
              onBlur={() => dispatch(setAllowDeleteShortcut(true))}
              disabled={selectedAutoLayout}
            />
          </div>
        </div>
      </PropSection>
    )
  },
)

ListLayout.displayName = 'ListLayout'
