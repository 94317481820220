import Tooltip from '@mui/material/Tooltip'
import MUIToggleButton, {
  toggleButtonClasses,
  ToggleButtonProps,
} from '@mui/material/ToggleButton'
import styled from '@mui/material/styles/styled'
import { useLanguage } from 'src/hooks'
import { TKey } from 'src/i18n/types'

export const StyledToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  [`&.${toggleButtonClasses.selected}`]: {
    color: `${theme.palette.primary.main} !important`,
    backgroundColor: `${theme.palette.primary._states?.focus} !important`,
  },
  [`&.${toggleButtonClasses.standard}`]: {
    'border': 'none',
    'margin': theme.spacing(1),
    'padding': theme.spacing(2),
    'borderRadius': theme.spacing(2),
    ':hover': {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.primary._states?.focus} !important`,
    },
  },
}))

interface IToolbarToggleButtonProps extends ToggleButtonProps {
  tooltip?: TKey
}

const ToggleButton: React.FC<IToolbarToggleButtonProps> = ({
  children,
  tooltip,
  ...rest
}) => {
  const { t } = useLanguage()
  return (
    <Tooltip title={tooltip ? t(tooltip) : undefined} arrow>
      <StyledToggleButton {...rest}>{children}</StyledToggleButton>
    </Tooltip>
  )
}

export default ToggleButton
