import React, { useCallback, useMemo, useState } from 'react'
import {
  useLanguage,
  useWindowSize,
  useDecksApi,
  useActiveColorMap,
} from 'src/hooks'
import { IColorMapping } from './types'
import {
  mappingWrapperStyles,
  swapStyles,
  swapSwitchStyles,
  swapSwitchTextStyles,
  defaultColorsStyles,
  swapColorsStyles,
  swapColorsTitleStyles,
  swapColorsInlineStyles,
} from './styles'
import { BUTTON_SIZE, Button, SWITCH_SIZE, Switch } from 'src/lib'
import { breakpoints } from 'src/theme'
import {
  ColorMappingItem,
  IColorMappingChangeParams,
} from '../color-mapping-item'
import { COLOR_MAPPING_BLOCKS } from 'src/pages/deck-page/components/edit-design/components/color-mapping'
import { ThemeColorMapSchema } from 'src/types/api/requestObjects'
import { PropSection } from '../../../edit-properties/props'
import { ColorMapSchema } from 'src/types/api/requestObjects'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, deleteCachedColorMapping } from 'src/store'

export const ColorMapping: React.FC<IColorMapping> = React.memo(
  ({ className, dataAttr }) => {
    const { t, lang } = useLanguage()
    const windowSize = useWindowSize()
    const { updateUserThemeColorMap } = useDecksApi()
    const colorMap = useActiveColorMap({})
    const dispatch = useDispatch()

    const { themeId } = useSelector(({ edit }: RootState) => ({
      themeId: edit.activeDeck.data?.deckData?.theme.id,
    }))

    const [swapColor, setSwapColors] = useState(false)

    const restoreButtonText = useMemo(
      () =>
        windowSize.width > breakpoints.large
          ? t('edit.color_mapping.restore')
          : t('edit.color_mapping.restore_short'),
      [windowSize.width],
    )

    const swapChangeHandler = useCallback((isSwapped: boolean) => {
      setSwapColors(isSwapped)
    }, [])

    const restoreDefaultHandler = useCallback(async () => {
      if (themeId) {
        dispatch(deleteCachedColorMapping({ id: themeId.toString() }))
      }
      await updateUserThemeColorMap({ field: undefined })
    }, [themeId])

    const colorChangeHandler = useCallback(
      async ({
        name,
        mappingIndex,
        color,
        isSwap,
      }: IColorMappingChangeParams) => {
        let field
        const colorAsVariable = {
          colors: color.colors.map((val) =>
            val.replace('var(--', '').replace(')', ''),
          ),
          rotation: color.rotation,
        }
        if (!colorMap || !colorAsVariable) return
        if (isSwap) {
          if (name !== 'decorations' && name !== 'charts') {
            field = {
              [name]: {
                default: (
                  colorMap[name as keyof ThemeColorMapSchema] as ColorMapSchema
                )?.default,
                swap: colorAsVariable,
              },
            } as unknown as ThemeColorMapSchema[keyof ThemeColorMapSchema]
          } else if (mappingIndex !== undefined) {
            field = {
              [name]: [
                ...(colorMap[
                  name as keyof ThemeColorMapSchema
                ] as ColorMapSchema[]),
              ],
            } as any // left as any because could be decorations or charts, as they not defined individually
            field[name][mappingIndex] = {
              default: (
                colorMap[name as keyof ThemeColorMapSchema] as ColorMapSchema[]
              )[mappingIndex].default,
              swap: colorAsVariable,
            }
          }
        } else {
          if (name !== 'decorations' && name !== 'charts') {
            field = {
              [name]: {
                default: colorAsVariable,
                swap: (
                  colorMap[name as keyof ThemeColorMapSchema] as ColorMapSchema
                )?.swap,
              },
            } as unknown as ThemeColorMapSchema[keyof ThemeColorMapSchema]
          } else if (mappingIndex !== undefined) {
            field = {
              [name]: [
                ...(colorMap[
                  name as keyof ThemeColorMapSchema
                ] as ColorMapSchema[]),
              ],
            } as any // left as any because could be decorations or charts as they not defined individually
            field[name][mappingIndex] = {
              default: colorAsVariable,
              swap: (
                colorMap[name as keyof ThemeColorMapSchema] as ColorMapSchema[]
              )[mappingIndex].swap,
            }
          }
        }

        if (!field) return
        await updateUserThemeColorMap({ field })

        // We don't need the cached colorMap data when user changes a color
        // From this point on, userThemePreferences?.colorMap is the source of truth as we merge the new color with the cachedMapping
        if (themeId) {
          dispatch(deleteCachedColorMapping({ id: themeId.toString() }))
        }
      },
      [colorMap, themeId],
    )

    const colorMappingItems = useCallback(
      ({
        mappings,
        isSwap,
        colorMap,
      }: {
        mappings: {
          key: string
          value: string
        }[]
        isSwap: boolean
        colorMap?: ThemeColorMapSchema
      }) => {
        return Object.entries(colorMap || {}).map(([key, values]) => {
          if (!mappings.some((map) => map.key === key)) return
          if (key === 'decorations' || key === 'charts') {
            return (values as ColorMapSchema[]).map((value, index) => {
              if ((isSwap && !value.swap) || (!isSwap && !value.default)) return
              return (
                <ColorMappingItem
                  key={key + index}
                  mappingKey={key}
                  mappingIndex={index}
                  name={mappings.find((map) => map.key === key)?.value || ''}
                  color={isSwap ? value.swap : value.default}
                  isSwap={isSwap}
                  onChange={colorChangeHandler}
                />
              )
            })
          }
          return (
            <ColorMappingItem
              key={key}
              mappingKey={key}
              name={mappings.find((map) => map.key === key)?.value || ''}
              color={isSwap ? values.swap : values.default}
              isSwap={isSwap}
              onChange={colorChangeHandler}
            />
          )
        })
      },
      [colorMap],
    )

    const colorMapper = useMemo(() => {
      return COLOR_MAPPING_BLOCKS(lang).map((block) => (
        <PropSection
          key={block.blockName}
          title={block.blockName}
          expandable
          initialState
        >
          <div css={defaultColorsStyles}>
            {colorMappingItems({
              mappings: block.mapping,
              isSwap: false,
              colorMap,
            })}
          </div>
          {swapColor && block.blockName !== t('edit.color_mapping.chart') && (
            <div css={swapColorsStyles}>
              <span css={swapColorsTitleStyles}>
                {t('edit.color_mapping.swap_colors')}
              </span>
              <div css={swapColorsInlineStyles}>
                {colorMappingItems({
                  mappings: block.mapping,
                  isSwap: true,
                  colorMap,
                })}
              </div>
            </div>
          )}
        </PropSection>
      ))
    }, [colorMap, swapColor, lang, COLOR_MAPPING_BLOCKS])

    return (
      <div css={mappingWrapperStyles} className={className} {...dataAttr}>
        <div css={swapStyles}>
          <div css={swapSwitchStyles}>
            <span css={swapSwitchTextStyles}>
              {t('edit.color_mapping.swap_colors')}
            </span>
            <Switch
              size={SWITCH_SIZE.SMALL}
              selected={swapColor}
              onChange={swapChangeHandler}
            />
          </div>
          <Button
            size={BUTTON_SIZE.XSMALL}
            text={restoreButtonText}
            onClick={restoreDefaultHandler}
          />
        </div>

        {colorMapper}
      </div>
    )
  },
)

ColorMapping.displayName = 'ColorMapping'
