export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_14)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_21_14"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_36)"><path fill-rule="evenodd" clip-rule="evenodd" d="M410.585 0C410.595 1.43079 410.6 2.8628 410.6 4.29602C410.6 271.72 236.835 497.063 0 565.537V0H410.585ZM960 0H1920V1080H229.171C655.38 920.309 960 497.044 960 0Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_21_36"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_51)"><mask id="path-1-inside-1_21_51" fill="white"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM124 96H836V984H124V96Z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM124 96H836V984H124V96Z" fill="url(#decor-bg)"/><path d="M1920 0V-1H1921V0H1920ZM0 0H-1V-1H0V0ZM1920 1080H1921V1081H1920V1080ZM0 1080V1081H-1V1080H0ZM836 96V95H837V96H836ZM124 96H123V95H124V96ZM836 984H837V985H836V984ZM124 984V985H123V984H124ZM1920 1H0V-1H1920V1ZM1919 1080V0H1921V1080H1919ZM0 1079H1920V1081H0V1079ZM1 0V1080H-1V0H1ZM836 97H124V95H836V97ZM835 984V96H837V984H835ZM124 983H836V985H124V983ZM125 96V984H123V96H125Z" fill="#979797" mask="url(#path-1-inside-1_21_51)"/><path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M1735.5 745C1847.89 745 1939 653.889 1939 541.5C1939 429.111 1847.89 338 1735.5 338C1623.11 338 1532 429.111 1532 541.5C1532 653.889 1623.11 745 1735.5 745ZM1734 139C1955.47 139 2135 318.533 2135 540C2135 761.467 1955.47 941 1734 941C1512.53 941 1333 761.467 1333 540C1333 318.533 1512.53 139 1734 139Z" fill="$color2"/></g><defs><clipPath id="clip0_21_51"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_40)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1509.41 0C1509.4 1.43079 1509.4 2.8628 1509.4 4.29602C1509.4 271.72 1683.17 497.063 1920 565.537V0H1509.41ZM960 0H0V1080H1690.83C1264.62 920.309 960 497.044 960 0Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_21_40"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_44)"><mask id="path-1-inside-1_21_44" fill="white"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1796 96H1084V984H1796V96Z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1796 96H1084V984H1796V96Z" fill="url(#decor-bg)"/><path d="M0 0V-1H-1V0H0ZM1920 0H1921V-1H1920V0ZM0 1080H-1V1081H0V1080ZM1920 1080V1081H1921V1080H1920ZM1084 96V95H1083V96H1084ZM1796 96H1797V95H1796V96ZM1084 984H1083V985H1084V984ZM1796 984V985H1797V984H1796ZM0 1H1920V-1H0V1ZM1 1080V0H-1V1080H1ZM1920 1079H0V1081H1920V1079ZM1919 0V1080H1921V0H1919ZM1084 97H1796V95H1084V97ZM1085 984V96H1083V984H1085ZM1796 983H1084V985H1796V983ZM1795 96V984H1797V96H1795Z" fill="#979797" mask="url(#path-1-inside-1_21_44)"/><path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M184.5 745C72.111 745 -19 653.889 -19 541.5C-19 429.111 72.111 338 184.5 338C296.889 338 388 429.111 388 541.5C388 653.889 296.889 745 184.5 745ZM186 139C-35.4666 139 -215 318.533 -215 540C-215 761.467 -35.4666 941 186 941C407.467 941 587 761.467 587 540C587 318.533 407.467 139 186 139Z" fill="$color2"/></g><defs><clipPath id="clip0_21_44"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_87)"><path d="M0 0H1920V1080H0V0Z" fill="url(#decor-bg)"/><path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M184.5 745C72.111 745 -19 653.889 -19 541.5C-19 429.111 72.111 338 184.5 338C296.889 338 388 429.111 388 541.5C388 653.889 296.889 745 184.5 745ZM186 139C-35.4666 139 -215 318.533 -215 540C-215 761.467 -35.4666 941 186 941C407.467 941 587 761.467 587 540C587 318.533 407.467 139 186 139Z" fill="$color2"/></g><defs><clipPath id="clip0_21_87"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_93)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M181 254C223.526 254 258 219.526 258 177C258 134.474 223.526 100 181 100C138.474 100 104 134.474 104 177C104 219.526 138.474 254 181 254Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1825.5 1150C1735.2 1150 1662 1076.8 1662 986.5C1662 896.202 1735.2 823 1825.5 823C1915.8 823 1989 896.202 1989 986.5C1989 1076.8 1915.8 1150 1825.5 1150ZM1826.5 662C1647.84 662 1503 806.836 1503 985.5C1503 1164.16 1647.84 1309 1826.5 1309C2005.16 1309 2150 1164.16 2150 985.5C2150 806.836 2005.16 662 1826.5 662Z" fill="$color1"/></g><defs><clipPath id="clip0_21_93"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_80)"><mask id="path-1-inside-1_21_80" fill="white"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1753 677H163V1022H1753V677Z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1753 677H163V1022H1753V677Z" fill="url(#decor-bg)"/><path d="M0 0V-1H-1V0H0ZM1920 0H1921V-1H1920V0ZM0 1080H-1V1081H0V1080ZM1920 1080V1081H1921V1080H1920ZM163 677V676H162V677H163ZM1753 677H1754V676H1753V677ZM163 1022H162V1023H163V1022ZM1753 1022V1023H1754V1022H1753ZM0 1H1920V-1H0V1ZM1 1080V0H-1V1080H1ZM1920 1079H0V1081H1920V1079ZM1919 0V1080H1921V0H1919ZM163 678H1753V676H163V678ZM164 1022V677H162V1022H164ZM1753 1021H163V1023H1753V1021ZM1752 677V1022H1754V677H1752Z" fill="#979797" mask="url(#path-1-inside-1_21_80)"/><path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M958.5 205C846.111 205 755 113.889 755 1.5C755 -110.889 846.111 -202 958.5 -202C1070.89 -202 1162 -110.889 1162 1.5C1162 113.889 1070.89 205 958.5 205ZM960 -401C738.533 -401 559 -221.467 559 0C559 221.467 738.533 401 960 401C1181.47 401 1361 221.467 1361 0C1361 -221.467 1181.47 -401 960 -401Z" fill="$color2"/></g><defs><clipPath id="clip0_21_80"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="540" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_69)"><path fill-rule="evenodd" clip-rule="evenodd" d="M928 0H1920V1082H928V0Z" fill="url(#decor-bg)"/><path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M1933.65 767.45C2057.25 767.45 2157.45 667.251 2157.45 543.651C2157.45 420.051 2057.25 319.852 1933.65 319.852C1810.05 319.852 1709.85 420.051 1709.85 543.651C1709.85 667.251 1810.05 767.45 1933.65 767.45ZM1932 101C2175.56 101 2373 298.442 2373 542C2373 785.558 2175.56 983 1932 983C1688.44 983 1491 785.558 1491 542C1491 298.442 1688.44 101 1932 101Z" fill="$color2"/></g><defs><clipPath id="clip0_21_69"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_76)"><path fill-rule="evenodd" clip-rule="evenodd" d="M928 0H1920V1082H928V0Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_21_76"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_58)"><path fill-rule="evenodd" clip-rule="evenodd" d="M992 0H0V1082H992V0Z" fill="url(#decor-bg)"/><path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M-13.6495 767.45C-137.249 767.45 -237.449 667.251 -237.449 543.651C-237.449 420.051 -137.249 319.852 -13.6495 319.852C109.95 319.852 210.15 420.051 210.15 543.651C210.15 667.251 109.95 767.45 -13.6495 767.45ZM-12 101C-255.558 101 -453 298.442 -453 542C-453 785.558 -255.558 983 -12 983C231.558 983 429 785.558 429 542C429 298.442 231.558 101 -12 101Z" fill="$color2"/></g><defs><clipPath id="clip0_21_58"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_21_65)"><path fill-rule="evenodd" clip-rule="evenodd" d="M992 0H0V1082H992V0Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_21_65"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
