import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const useActiveSwap = (): boolean => {
  const { activeSlideDataID, slideData } = useSelector(
    ({ edit }: RootState) => ({
      activeSlideDataID: edit.activeSlideDataID,
      slideData: edit.activeSlideData.data,
    }),
  )

  return (
    slideData?.find(({ id }) => id === activeSlideDataID)?.isSwapColor || false
  )
}
