import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const elementsBlockStyles = ({
  gap,
  fontSize,
  fontWeight,
  breakpointsV2: breakpoints,
}: ITheme) => css`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${gap.xsmall}px;
    font-weight: ${fontWeight.medium};

    .expand-button {
      font-size: ${fontSize.small};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: ${gap.xsmall}px;
  }

  .expander {
    --expandable-min: 82px;
    --expandable-max: 172px;
    @media (min-width: ${breakpoints.tablet}px) {
      --expandable-min: 82px;
      --expandable-max: 262px;
    }

    transition: max-height 0.1s;
    max-height: var(--expandable-min);
    overflow: hidden;

    &.enter {
      max-height: var(--expandable-min);
      opacity: 0;
    }

    &.enter-active {
      opacity: 1;
      max-height: var(--expandable-max);
    }

    &.enter-done {
      max-height: var(--expandable-max);
    }

    &.exit {
      max-height: var(--expandable-min);
    }

    &.exit-done {
      max-height: var(--expandable-min);
    }
  }
`
