import { createMakeAndWithStyles } from 'tss-react'
import { Color } from '@mui/material/styles/createPalette'
import createTheme from '@mui/material/styles/createTheme'
import useTheme from '@mui/material/styles/useTheme'
import SailecLight from '../assets/fonts/sailec/Sailec-Light.woff2'
import SailecRegular from '../assets/fonts/sailec/Sailec-Regular.woff2'
import SailecMedium from '../assets/fonts/sailec/Sailec-Medium.woff2'
import SailecBold from '../assets/fonts/sailec/Sailec-Bold.woff2'
import { MuiThemeConfig } from './configs/muiConfig'

declare module '@mui/material/styles' {
  interface PaletteColor {
    _states?: {
      hover?: string
      selected?: string
      focus?: string
      focusVisible?: string
      outlinedBorder?: string
    }
  }

  interface SimplePaletteColorOptions {
    _states?: {
      hover?: string
      selected?: string
      focus?: string
      focusVisible?: string
      outlinedBorder?: string
    }
  }

  interface Palette {
    elevation?: {
      outlined?: string
    }
    blueGrey: Color
  }

  interface PaletteOptions {
    elevation?: {
      outlined?: string
    }
  }
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    tablet: true
    laptop: true
    desktop: true
    small: true
    large: true
    xlarge: true
    xxlarge: true
  }
}

const theme = createTheme({
  cssVariables: true,
  ...MuiThemeConfig,
  typography: {
    fontFamily: 'Sailec, Helvetica',
  },
  components: {
    ...MuiThemeConfig.components,
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sailec';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: url(${SailecLight}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Sailec';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${SailecRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Sailec';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${SailecMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Sailec';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url(${SailecBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
})

export default theme

export const { makeStyles: makeMuiStyles, withStyles: withMuiStyles } =
  createMakeAndWithStyles({
    useTheme,
  })
