import React, { useCallback, useEffect, useMemo, useState } from 'react'
import env from 'react-dotenv'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useNotification, useLanguage, useInterval } from 'src/hooks'
import { LoginForm } from 'src/components/login-form'
import { ILoginType } from 'src/components/login-form/types'
import { useAuthProvider } from 'src/provider/authProvider'
import { AuthPageLayout } from 'src/layouts'
import { AUTH_PAGE_LAYOUT } from 'src/layouts/auth-page-layout/types'
import { sendEvent } from 'src/plugins/google/gtag'
import {
  GTAG_EVENTS,
  GTAG_EVENT_CATEGORIES,
  PROVIDER,
} from 'src/plugins/google/consts'

export const LoginPage: React.FC = React.memo(() => {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const [searchParams, setSearchParams] = useSearchParams()
  const { token, setToken, clearToken } = useAuthProvider()
  const { error } = useNotification()
  const [auth0Awaits, setAuth0Awaits] = useState(false)

  useEffect(() => {
    const errorQuery = searchParams.get('error')
    const loginType = sessionStorage.getItem('loginType')
    if (errorQuery) {
      error(
        t(
          loginType === ILoginType.GOOGLE
            ? 'login.incorrect_provider_jwt'
            : 'login.incorrect_provider_google',
        ),
      )
      setSearchParams()
    }
    clearToken(true)
  }, [])

  const authToken = useMemo(() => searchParams.get('authToken'), [searchParams])
  const isAlreadyUser = useMemo(
    () => searchParams.get('isAlreadyUser'),
    [searchParams],
  )
  const email = useMemo(() => searchParams.get('email'), [searchParams])

  useEffect(() => {
    if (authToken && !auth0Awaits) {
      localStorage.setItem(
        'auth0',
        JSON.stringify({
          authToken,
          isAlreadyUser,
          email,
        }),
      )
      window.open('', '_self')
      window.close()
      return
    }
  }, [authToken, auth0Awaits])

  useEffect(() => {
    if (token && !authToken) {
      navigate('/dashboard', { replace: true })
    }
  }, [token, authToken])

  useInterval(
    () => {
      const authTokenFromUrl = searchParams.get('authToken')
      if (authTokenFromUrl) return
      const auth0LS = localStorage.getItem('auth0')
      const authTokenLS = auth0LS ? JSON.parse(auth0LS).authToken : null
      const isAlreadyUserLS = auth0LS ? JSON.parse(auth0LS).isAlreadyUser : null
      const emailLS = auth0LS ? JSON.parse(auth0LS).email : null

      if (authTokenLS && auth0Awaits) {
        if (isAlreadyUserLS === 'true') {
          sendEvent(GTAG_EVENTS.SIGN_IN, {
            sign_in_method: PROVIDER.GOOGLE,
            event_category: GTAG_EVENT_CATEGORIES.ENGAGEMENT,
            user_email: emailLS,
          })
        } else if (isAlreadyUserLS === 'false') {
          sendEvent(GTAG_EVENTS.SIGN_UP, {
            signup_method: PROVIDER.GOOGLE,
            event_category: GTAG_EVENT_CATEGORIES.ENGAGEMENT,
            user_email: emailLS,
          })
        }
        setAuth0Awaits(false)
        localStorage.removeItem('auth0')
        localStorage.removeItem('auth0-awaits')
        setToken(authTokenLS)
      }
    },
    true && auth0Awaits ? 1000 : null,
  )

  const onGoogleAuthHandler = useCallback(() => {
    sessionStorage.setItem('loginType', ILoginType.GOOGLE)
    localStorage.setItem('auth0-awaits', 'true')
    setAuth0Awaits(true)
    window.open(
      `${env.BASE_URL}auth/google`,
      '_blank',
      'popup,width=600,height=600,left=100,top=100',
    )
  }, [])

  return (
    <AuthPageLayout type={AUTH_PAGE_LAYOUT.LOGIN}>
      <LoginForm onGoogleAuth={onGoogleAuthHandler} />
    </AuthPageLayout>
  )
})
