import { DeepPartial } from 'src/types'
import { UpdateComponentSchema } from 'src/types/api/requestObjects'
import { ObjectServices } from '.'
import { ComponentSchema } from 'src/types/api/responseObjects'

interface IUpdateTextComponent<T> {
  components?: T[]
  partialUpdate: DeepPartial<T>
}

export const updateComponent = <
  T extends UpdateComponentSchema | ComponentSchema,
>({
  components,
  partialUpdate,
}: IUpdateTextComponent<T>): T[] => {
  return (
    components?.map((component) =>
      ObjectServices.deepMerge(component, partialUpdate),
    ) || []
  )
}
interface IUpdateTextComponentData<T extends UpdateComponentSchema['data']> {
  components?: UpdateComponentSchema[]
  partialData?: DeepPartial<T>
}

export const updateComponentData = <T extends UpdateComponentSchema['data']>({
  components,
  partialData,
}: IUpdateTextComponentData<T>): UpdateComponentSchema[] => {
  return (
    components?.map((component) =>
      ObjectServices.deepMerge(component, {
        data: partialData,
      }),
    ) || []
  )
}
