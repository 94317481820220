import React, { useEffect, useMemo, useRef } from 'react'
import { useDrag, useDragLayer, useDrop } from 'react-dnd'
import { Icon, icons } from 'src/lib'
import { ListItem } from './ListItem'
import {
  draggableListItemStyles,
  dragLayerStyles,
  dragPreviewStyles,
} from './styles'
import { useTheme } from 'src/theme'
import { IListItem } from './types'
import { getEmptyImage } from 'react-dnd-html5-backend'

interface DraggableListItemProps extends IListItem {
  id: string
  index: number
  moveItem: (dragIndex: number, hoverIndex: number) => void
  setExpandedIndex: (index: number | undefined) => void
}

export const DraggableListItem: React.FC<DraggableListItemProps> = ({
  id,
  index,
  moveItem,
  setExpandedIndex,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { colors } = useTheme()

  const [{ handlerId }, drop] = useDrop({
    accept: 'list-item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'list-item',
    item: () => {
      setExpandedIndex(undefined) // Collapse all items when drag starts
      return { id, index, ...props }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      setExpandedIndex(undefined) // Ensure all items remain collapsed after drag ends
    },
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [preview])

  drop(ref)

  return (
    <div
      ref={ref}
      css={draggableListItemStyles(isDragging)}
      data-handler-id={handlerId}
    >
      <div ref={drag} className="drag-handle">
        <Icon icon={icons.menu} color={colors.white.DEFAULT} size={20} />
      </div>
      <ListItem index={index} {...props} />
    </div>
  )
}

export const ListItemDragLayer: React.FC = React.memo(() => {
  const { isDragging, item, currentOffset, itemType } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }),
  )
  const previewStyles = useMemo(
    () => dragPreviewStyles(currentOffset),
    [currentOffset],
  )

  if (!isDragging || itemType !== 'list-item') return null

  return (
    <div css={dragLayerStyles}>
      <div style={previewStyles}>
        <ListItemPreview {...item} />
      </div>
    </div>
  )
})

const ListItemPreview: React.FC<IListItem> = React.memo((props) => {
  const { colors } = useTheme()
  return (
    <div css={draggableListItemStyles(false)}>
      <div className="drag-handle">
        <Icon icon={icons.menu} color={colors.white.DEFAULT} size={20} />
      </div>
      <ListItem {...props} />
    </div>
  )
})
