import { css } from '@emotion/react'
import { APP_CONFIG } from 'src/config'
import { ITheme } from 'src/theme'

export const dragPreviewStyles = ({ zIndex }: ITheme) => css`
  position: fixed;
  pointer-events: none;
  z-index: ${zIndex.dragPreview};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  font-size: ${APP_CONFIG.canvasBaseFontSize}px;
`
