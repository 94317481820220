import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { ICaptcha } from './types'
import { captchaWrapperStyles } from './styles'
import { useLanguage, useNotification } from 'src/hooks'
import { APP_CONFIG } from 'src/config'
import env from 'react-dotenv'

export const Captcha = React.forwardRef<HCaptcha, ICaptcha>(
  (
    {
      fullName,
      email,
      password,
      resetToken,
      resetEmail,
      newPassword,
      shouldResetCaptcha = false,
      onCaptchaReset,
      onVerificationSuccess,
      className,
      dataAttr,
    },
    ref,
  ) => {
    const { error } = useNotification()
    const { lang } = useLanguage()
    const captchaRef = useRef<HCaptcha>(null)

    useImperativeHandle(ref, () => captchaRef.current as HCaptcha)

    useEffect(() => {
      if (shouldResetCaptcha) {
        captchaRef.current?.resetCaptcha()
        onCaptchaReset()
      }
    }, [shouldResetCaptcha])

    const verificationSuccessHandler = useCallback(
      (token: string) => {
        onVerificationSuccess({
          fullName,
          email,
          token,
          password,
          resetToken,
          resetEmail,
          newPassword,
        })
      },
      [fullName, email, password, resetToken, resetEmail, newPassword],
    )

    const captchaErrorHandler = useCallback(
      (type: 'timeout' | 'challange' | 'error') => {
        if (type === 'timeout') {
          error('common.informative.captcha_expired')
        }
        if (type === 'challange') {
          error('common.informative.captcha_challange_expired')
        }

        if (type === 'error') {
          error('common.informative.captcha_error')
        } else {
          captchaRef.current?.resetCaptcha()
        }
      },
      [captchaRef],
    )

    return (
      <div css={captchaWrapperStyles} className={className} {...dataAttr}>
        <HCaptcha
          ref={captchaRef}
          sitekey={env.H_CAPTCHA_KEY ?? APP_CONFIG.hCaptchaLocalKey}
          languageOverride={lang}
          size="invisible"
          onVerify={(token) => verificationSuccessHandler(token)}
          onExpire={() => captchaErrorHandler('timeout')}
          onChalExpired={() => captchaErrorHandler('challange')}
          onError={() => captchaErrorHandler('error')}
        />
      </div>
    )
  },
)

Captcha.displayName = 'Captcha'
