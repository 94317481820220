import React, { useMemo } from 'react'
import Lottie from 'react-lottie'
import { Loader } from 'src/lib/loader'
import buttonAnimation from 'src/assets/lotties/button-animation.json'
import { ILottieButton } from './types'
import { lottieWrapperStyle, buttonTextStyle } from './styles'
import { colors } from 'src/theme'

export const LottieButton: React.FC<ILottieButton> = React.memo(
  ({ text, isLoading, onClick, className, dataAttr }) => {
    const defaultOptions = useMemo(() => {
      return {
        loop: true,
        autoplay: true,
        animationData: buttonAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }
    }, [])

    const textRender = useMemo(() => {
      if (isLoading) {
        return <Loader size={6} isFullWidth color={colors.white.DEFAULT} />
      } else {
        return text
      }
    }, [isLoading, text])

    return (
      <div
        css={lottieWrapperStyle}
        onClick={onClick}
        className={className}
        {...dataAttr}
      >
        <Lottie options={defaultOptions} />
        <span css={buttonTextStyle}>{textRender}</span>
      </div>
    )
  },
)
