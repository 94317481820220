import { dataAttrType } from 'src/types'
import { UpdateComponentSchema } from 'src/types/api/requestObjects'

export interface IEditProperties {
  className?: string
  dataAttr?: dataAttrType
}

export enum EditSubmenuTypes {
  LIST_TEXT_PROPS = 'list_text_props',
  FORM_PROPS = 'form_props',
}

export interface IEditPropSection extends IEditProperties {
  components?: UpdateComponentSchema[]
  isGrid?: boolean
  showSubMenu?: (props: { type: EditSubmenuTypes }) => void
}
