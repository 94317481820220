import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
export const overlayStyles = (theme: ITheme) => css`
  position: fixed;
  align-items: center;
  background-color: ${theme.colors.white.DEFAULT};
  color: ${theme.colors.deactive.DEFAULT};
  display: flex;
  font-size: ${theme.fontSize['2xlarge']};
  height: 100vh;
  justify-content: center;
  width: 100vw;
  z-index: ${theme.zIndex.overlayLayout};
`
