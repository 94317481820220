import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { IPolotnoComponent } from '../../types'

const DuplicatePageAction = observer<IPolotnoComponent>(({ store }) => {
  const handleClick = () => {
    store.activePage.clone({
      custom: undefined,
    })
  }

  return (
    <BaseIconButton
      onClick={handleClick}
      sx={{
        padding: '6px',
      }}
      tooltip="v3.duplicate_page"
    >
      <MaterialSymbol icon="content_copy" size={20} weight={400} />
    </BaseIconButton>
  )
})

export default DuplicatePageAction
