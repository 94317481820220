import React from 'react'
import { IFormGDPRCheck } from './types'
import {
  checklistStyles,
  checklistItemStyles,
  checkboxWrapperStyles,
  optionLabelStyles,
} from './styles'

export const FormGDPRCheck: React.FC<IFormGDPRCheck> = React.memo(
  ({
    isLastSlide,
    gDPRConsentMessage,
    isGDPRConsentEnabled,
    isSelected,
    gDPRCheckHandler,
    className,
    dataAttr,
  }) => {
    if (isLastSlide && isGDPRConsentEnabled)
      return (
        <div css={checklistStyles} className={className} {...dataAttr}>
          <div css={checklistItemStyles}>
            <div css={checkboxWrapperStyles}>
              <input
                id={'gdpr-check'}
                type="checkbox"
                onChange={(e) => gDPRCheckHandler(e.target.checked)}
                checked={isSelected}
              />
            </div>
            <label htmlFor={'gdpr-check'} css={optionLabelStyles}>
              {gDPRConsentMessage}
            </label>
          </div>
        </div>
      )
  },
)
