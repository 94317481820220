import React, { useCallback, useMemo } from 'react'

import { PropColor } from 'src/pages/deck-page/components/edit-properties/props'
import {
  GradientLikeColorSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { IEditPropSection } from '../../types'

export const PropChartBackgroundColor: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()
    const selectedColor = useMemo(
      () =>
        components?.reduce(
          (
            a: GradientLikeColorSchema | undefined,
            c: UpdateComponentSchema,
          ) => {
            const bgColor = c.style?.backgroundColor
            if (a === undefined && bgColor) {
              return bgColor
            }

            return bgColor !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleOnChange = useCallback(
      (newColor: GradientLikeColorSchema) => {
        const partialUpdate: DeepPartial<UpdateComponentSchema> = {
          style: { backgroundColor: newColor },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponent<UpdateComponentSchema>({
            components,
            partialUpdate,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return <PropColor color={selectedColor} onChange={handleOnChange} />
  },
)

PropChartBackgroundColor.displayName = 'PropChartBackgroundColor'
