import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  assetResponseSchema,
  DeckV3Document,
  GetDeckV3Document,
  IDeckV3SlideNote,
} from 'src/types/api/responseObjects'
import { SAVE_STATE } from '../types'

export interface IDeckV3Slice {
  documentData?: GetDeckV3Document
  assets: assetResponseSchema[]
  saveNow: boolean
  deletedSlides: string[]
  saveState: SAVE_STATE
  useBeta?: boolean
}

const initialState: IDeckV3Slice = {
  documentData: undefined,
  assets: [],
  saveNow: false,
  deletedSlides: [],
  saveState: SAVE_STATE.SAVED,
  useBeta: false,
}

export const deckV3Slice = createSlice({
  name: 'deckV3',
  initialState,
  reducers: {
    setDocumentData: (state, action: PayloadAction<GetDeckV3Document>) => {
      state.documentData = action.payload
    },
    setDocument: (state, action: PayloadAction<DeckV3Document>) => {
      state.documentData = {
        extraData: { ...(state.documentData?.extraData ?? { slideNotes: [] }) },
        document: action.payload,
      }
    },
    setSlideNote: (state, action: PayloadAction<IDeckV3SlideNote>) => {
      const slideNote = state.documentData?.extraData.slideNotes.find(
        (item) => item.internalId === action.payload.internalId,
      )
      if (slideNote) {
        slideNote.note = action.payload.note
      } else {
        state.documentData?.extraData.slideNotes.push(action.payload)
      }
    },
    setSaveNow: (state, action: PayloadAction<boolean>) => {
      state.saveNow = action.payload
    },
    setAssets: (state, action: PayloadAction<IDeckV3Slice['assets']>) => {
      state.assets = action.payload
    },
    addAsset: (state, action: PayloadAction<IDeckV3Slice['assets'][0]>) => {
      state.assets = state.assets
        ? [...state.assets, action.payload]
        : [action.payload]
    },
    setDeletedSlides: (state, action: PayloadAction<string[]>) => {
      state.deletedSlides = action.payload
    },
    setV3SaveState: (state, action: PayloadAction<SAVE_STATE>) => {
      state.saveState = action.payload
    },
    setBetaV3: (state, action: PayloadAction<boolean>) => {
      state.useBeta = action.payload
    },
  },
})

export const {
  setDocument,
  setSaveNow,
  setAssets,
  addAsset,
  setDeletedSlides,
  setV3SaveState,
  setDocumentData,
  setSlideNote,
  setBetaV3,
} = deckV3Slice.actions

export default deckV3Slice.reducer
