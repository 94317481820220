import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import { observer } from 'mobx-react-lite'
import { ImageElementType } from 'polotno/model/image-model'
import { useState } from 'react'
import { useLanguage } from 'src/hooks'
import { IPolotnoComponent } from '../../types'
import { MaterialSymbol } from 'react-material-symbols'

const FlipAction = observer<IPolotnoComponent>(({ store }) => {
  const hasLockedItems = store.selectedElements?.some(
    (element) => element.locked,
  )

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { t } = useLanguage()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'transparency-popover' : undefined

  const handleFlip = (flip: 'horizontal' | 'vertical') => {
    const prop = flip === 'horizontal' ? 'flipX' : 'flipY'
    store.selectedElements?.forEach((element) => {
      element.set({
        [prop]: !(element as ImageElementType)[prop],
      })
    })
  }

  return !hasLockedItems ? (
    <>
      <Button
        size="small"
        variant="outlined"
        disableElevation
        onClick={handleClick}
        sx={{
          flexShrink: 0,
        }}
      >
        {t('v3.flip')}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 48,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={32}
        elevation={6}
      >
        <Stack gap={2} sx={{ p: 2 }}>
          <Button
            size="small"
            variant="text"
            disableElevation
            onClick={() => handleFlip('horizontal')}
            sx={{
              flexShrink: 0,
            }}
            startIcon={<MaterialSymbol icon={'swap_horiz'} weight={300} />}
          >
            {t('v3.flip_horizontal')}
          </Button>
          <Button
            size="small"
            variant="text"
            disableElevation
            onClick={() => handleFlip('vertical')}
            sx={{
              flexShrink: 0,
            }}
            startIcon={<MaterialSymbol icon={'swap_vert'} weight={300} />}
          >
            {t('v3.flip_vertical')}
          </Button>
        </Stack>
      </Popover>
    </>
  ) : null
})
export default FlipAction
