import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IMemberRow } from './types'

export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${theme.gap.xlarge}px;
  margin-bottom: ${theme.gap.small}px;
  border-radius: ${theme.borderRadius[6]};
  border: 1px solid;
  border-color: ${theme.colors.outline.DEFAULT};

  @media (min-width: ${theme.breakpointsV2.small}px) {
    height: 76px;
    padding: ${theme.gap.small}px ${theme.gap.xlarge}px;
  }
`

export const iconStyles = css`
  position: relative;
  display: flex;
  width: inherit;
  align-items: center;
  justify-content: flex-end;
`

export const iconHoverStyles = css`
  cursor: pointer;
`

export const menuStyles = ({
  breakpointsV2: breakpoints,
  zIndex,
}: ITheme) => css`
  position: absolute;
  margin-top: 110px;
  z-index: ${zIndex.popUpMenu};

  @media (min-width: ${breakpoints.small}px) {
    width: 123px;
  }
`

type ItabloInfoStyles = Pick<IMemberRow, 'width'>

export const memberRowStyles =
  ({ width }: ItabloInfoStyles) =>
  (theme: ITheme) => css`
    display: flex;

    @media (min-width: ${theme.breakpointsV2.small}px) {
      width: 100%;
    }

    @media (min-width: ${theme.breakpointsV2.tablet}px) {
      width: ${width}px;
    }
  `
