import React, { lazy, useEffect, useState } from 'react'
import { PolotnoContainer, WorkspaceWrap } from 'polotno'
import { handleHotkey } from 'polotno/canvas/hotkeys'
import { Workspace } from 'polotno/canvas/workspace'
import { StoreType } from 'polotno/model/store'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import { MaterialSymbol } from 'react-material-symbols'
import { useLanguage } from 'src/hooks'
import { Expander, EXPANDER_DIR, EXPANDER_THEME, Spinner } from 'src/lib'
import { PolotnoStore } from 'src/utils/PolotnoStore'
import AutoScrollArea from '../deck-page-v3/components/scroll-area'
import file from './style-builder.json'
import '../deck-page-v3/styles/polotno-override.css'
import '../deck-page-v3/styles/scrollarea.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import { useStyles } from './styles/styles'
import { SideBarItemType, SlideStyleType } from './types'

const ColorStylesPanel = lazy(
  () => import('./components/panels/ColorStylesPanel'),
)

const SideBarItems: SideBarItemType[] = [
  { id: 1, label: 'v3.colors', icon: 'palette', isActive: true },
  { id: 2, label: 'v3.font', icon: 'text_format', isActive: false },
  { id: 3, label: 'v3.style', icon: 'design_services', isActive: false },
  { id: 4, label: 'v3.background', icon: 'texture', isActive: false },
]

const { store } = new PolotnoStore()
store.loadJSON(file)

export const StyleBuilder: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const theme = useTheme()
  const { classes } = useStyles()

  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [expanded, setExpanded] = useState<boolean>(true)

  const [slideStyle, setSlideStyle] = useState<SlideStyleType>({
    palette: {
      fill: [
        'rgba(79, 97, 255, 1)',
        'rgba(149, 160, 255, 1)',
        'rgba(38, 44, 56, 1)',
        'rgba(207, 211, 220, 1)',
        'rgba(255, 255, 255, 1)',
      ],
    },
    background: { fill: ['rgb(255, 255, 255)'] },
    icon: { fill: ['rgba(207, 211, 220, 1)'] },
    listBullet: { fill: ['rgba(79, 97, 255, 1)'] },
    timeline: { fill: ['rgba(79, 97, 255, 1)', 'rgba(79, 97, 255, 1)'] },
    tableHighlight: { fill: ['rgba(149, 160, 255, 1))'] },
    shape: {
      fill: [
        'rgba(149, 160, 255, 1))',
        'rgba(7, 32, 247, 1)',
        'rgba(167, 255, 149, 1)',
      ],
    },
  })

  const onKeyDown = (e: KeyboardEvent, store: StoreType) => {
    if (e.code === 'KeyS' && (e.ctrlKey || e.metaKey)) {
      // e.preventDefault()
      // dispatch(setSaveNow(true))
    } else {
      handleHotkey(e, store)
    }
  }

  // to remove blue scrollbars
  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function (mutationRecord) {
        const element = mutationRecord.target as HTMLElement
        element.style?.setProperty('overflow', null)
      })
    })
    const elements = document.getElementsByClassName('polotno-workspace-inner')
    if (elements && elements.length > 0) {
      const arr = Array.from(elements) as HTMLElement[]
      arr.forEach((element: HTMLElement) => {
        element.classList.add('decktopus-workspace-inner')
        const target = element
        target.style?.setProperty('overflow', null)
        observer.observe(target, {
          attributes: true,
          attributeFilter: ['style'],
        })
      })
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Stack width="100%" height="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Typography variant="body2">{t('v3.style.builder')}</Typography>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" disableElevation>
            {t('common.actions.cancel')}
          </Button>
          <Button
            variant="contained"
            disableElevation
            startIcon={<MaterialSymbol icon="save" size={18} weight={300} />}
          >
            {t('common.actions.save')}
          </Button>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        sx={{
          flex: 1,
        }}
      >
        <Box className={classes.tabs}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value as number)}
          >
            {SideBarItems.map((item) => (
              <Tab
                label={t(item.label)}
                key={item.id}
                icon={
                  <MaterialSymbol
                    icon={item.icon as any}
                    size={24}
                    weight={300}
                  />
                }
                sx={{
                  height: '48px',
                  minHeight: '48px',
                }}
                iconPosition="start"
                id={`vertical-tab-${item.id}`}
                aria-controls={`vertical-tabpanel-${item.id}`}
              />
            ))}
          </Tabs>
        </Box>
        <Expander
          theme={EXPANDER_THEME.LIGHT}
          dir={EXPANDER_DIR.RIGHT}
          expanded={expanded}
          onStateChange={setExpanded}
          props={['width', ['0px', '350px']]}
          expander={
            <MaterialSymbol
              icon={
                selectedTab > 0 ? 'keyboard_arrow_left' : 'keyboard_arrow_right'
              }
              size={20}
              weight={300}
            />
          }
        >
          <Box className={classes.tabPanel}>
            {selectedTab === 0 && (
              <ColorStylesPanel
                slideStyle={slideStyle}
                setSlideStyle={setSlideStyle}
                store={store}
              />
            )}
          </Box>
        </Expander>

        <PolotnoContainer
          style={{ display: 'flex', width: '100vw', height: '100%' }}
        >
          {/* <StoreLoader store={store} deckId={deckId} /> */}
          {/* <StoreListener store={store} deckId={deckId} /> */}
          <WorkspaceWrap className={classes.workspaceWrapper}>
            <AutoScrollArea>
              <Workspace
                store={store}
                components={{
                  NoPages: () => (
                    <div className={classes.noPages}>
                      <Spinner size={64} />
                    </div>
                  ),
                  Tooltip: () => null,
                  Duplicate: () => null,
                  Position: () => null,
                  Remove: () => null,
                  PageControls: () => null,
                }}
                backgroundColor={theme.palette.elevation?.outlined}
                renderOnlyActivePage={false}
                onKeyDown={onKeyDown}
                activePageBorderColor={theme.palette.primary.main}
              />
            </AutoScrollArea>
          </WorkspaceWrap>
        </PolotnoContainer>
      </Stack>
    </Stack>
  )
})
