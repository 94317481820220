import React from 'react'
import { ITabItem } from 'src/lib/tabs/types'
import { wrapperStyles } from './styles'

export const TabItem: React.FC<ITabItem> = React.memo(
  ({ isActive, label, onChange, theme }) => {
    return (
      <div css={wrapperStyles({ isActive, theme })} onClick={onChange}>
        {label}
      </div>
    )
  },
)
