import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { RootState, CANVAS_PANEL } from 'src/store'
import { EditElements, EditProperties } from '../'
import { IEditSideMenu } from './types'
import { editSideMenuStyles } from './styles'
import { EditDesign } from '../edit-design'
import { EditAiAssistant } from 'src/components/edit-ai-assistant'
import { useLanguage } from 'src/hooks'
import { EditSlide } from '../edit-slide'
import { EditNotes } from 'src/components/edit-notes'
export const EditSideMenu: React.FC<IEditSideMenu> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const { selectedComponents, currentPanel } = useSelector(
      ({ canvas, edit }: RootState) => ({
        selectedComponents: canvas.selectedComponents,
        currentPanel: canvas.panel,
        selectedTheme: edit.activeDeck.data?.deckData?.theme,
      }),
    )

    const renderPanel = useMemo(() => {
      switch (currentPanel) {
        case CANVAS_PANEL.DESIGN:
          return <EditDesign />
        case CANVAS_PANEL.SLIDE:
          return <EditSlide />
        case CANVAS_PANEL.ELEMENTS:
          return <EditElements />
        case CANVAS_PANEL.NOTES:
          return <EditNotes />
        case CANVAS_PANEL.AI:
          return <EditAiAssistant />
        case CANVAS_PANEL.BRAND:
          return <div>{t('edit.panel.brand')}</div>
        default:
          return <div>{t('common.empty')}</div>
      }
    }, [currentPanel])

    return (
      <div css={editSideMenuStyles} className={className} {...dataAttr}>
        <div className="inner">{renderPanel}</div>
        {!!selectedComponents.length && (
          <div className="inner">
            <EditProperties />
          </div>
        )}
      </div>
    )
  },
)

EditSideMenu.displayName = 'EditSideMenu'
