import React, { useCallback, useEffect, useState } from 'react'

import { ISlideNumber } from './types'
import { slideNumberStyles } from './styles'
import { SAVE_STATE, setSaveState, slideShift } from 'src/store'
import { useDispatch } from 'react-redux'

export const SlideNumber: React.FC<ISlideNumber> = React.memo(
  ({ current, className, dataAttr }) => {
    const dispatch = useDispatch()
    const [newValue, setNewValue] = useState(current)

    useEffect(() => {
      setNewValue(current)
    }, [current])

    const handleNumberChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const numberValue = isNaN(parseInt(e.target.value))
          ? current
          : parseInt(e.target.value)

        setNewValue(numberValue)
      },
      [current],
    )

    const handleKeyPress = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          if (newValue === 0) {
            setNewValue(current)
          }
          if (current !== newValue) {
            dispatch(slideShift({ fromNumber: current, toNumber: newValue }))
            dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
          }
        }
      },
      [newValue, current, dispatch],
    )

    return (
      <input
        css={slideNumberStyles}
        className={className}
        {...dataAttr}
        value={newValue}
        onChange={handleNumberChange}
        onKeyDown={handleKeyPress}
      />
    )
  },
)

SlideNumber.displayName = 'SlideNumber'
