import { css } from '@emotion/react'
import { IPropQuill, PROP_QUILL_TOOLBAR_SIDE } from './types'
import { ITheme } from 'src/theme'

type IPropQuillStyles = Pick<IPropQuill, 'height' | 'background' | 'maxHeight'>
export const propQuillStyles =
  ({ height, background, maxHeight }: IPropQuillStyles) =>
  ({ colors, borderRadius, fontSize, gap }: ITheme) => css`
    width: 100%;
    height: ${height};
    max-height: ${maxHeight};
    position: relative;

    .quill {
      height: 100%;

      p {
        margin: 0;
      }
    }

    .ql-container {
      border: none;
      background: ${background || colors.dark.DEFAULT};
      border-radius: ${borderRadius[4]};
      font-size: ${fontSize.base};
      padding: ${gap.medium}px;
    }
  `

interface IPropQuillToolbarStyles extends Pick<IPropQuill, 'toolbarSide'> {
  isFocus?: boolean
}
export const propQuillToolbarStyles =
  ({ isFocus, toolbarSide }: IPropQuillToolbarStyles) =>
  ({ colors, gap, borderRadius }: ITheme) => css`
    position: absolute;
    background: ${colors.white.DEFAULT};
    left: 0;
    display: flex;
    gap: ${gap.xsmall}px;
    padding: ${gap.xsmall}px;
    border-radius: ${borderRadius[4]};

    ${toolbarSide === PROP_QUILL_TOOLBAR_SIDE.TOP
      ? `
    		bottom: calc(100% + ${gap.xsmall}px);`
      : `
    		top: calc(100% + ${gap.xsmall}px);`}

    ${!isFocus &&
    `
			opacity: 0;
			pointer-events: none;
		`};
    z-index: 1000;
  `
