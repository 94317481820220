import { DeckData_DataSchema } from 'src/types/api/requestObjects'
import { ISaveQueue } from '../types'

export const slideOrderChanges = (
  slides: DeckData_DataSchema['slides'],
  oldState: DeckData_DataSchema['slides'],
): ISaveQueue['data'] => {
  const changes: ISaveQueue['data'] = []
  slides.forEach((slide) => {
    if (
      oldState.find(({ slideId }) => slideId === slide.slideId)?.orderIndex !==
      slide.orderIndex
    )
      changes.push({
        id: slide.slideId,
        tempId: slide.slideTempId,
        orderIndex: slide.orderIndex,
        components: [],
      })
  })

  return changes
}
