import { ErrorDefinitions } from 'src/types/api/ErrorDefinitions'

type IErrorDefinitions = {
  [key in ErrorDefinitions]?: string
}

export const errors: IErrorDefinitions = {
  [ErrorDefinitions.VALIDATION_EMAIL_MALFORMED]: 'O email não é válido',
  [ErrorDefinitions.VALIDATION_PASSWORD_LENGTH]:
    'A senha deve ter pelo menos 10 caracteres',
  [ErrorDefinitions.AICREDITREASONDEFAULTVALUE_NOT_FOUND]:
    'Valor padrão da razão de crédito de IA não encontrado',
  [ErrorDefinitions.API_ERROR]: 'Erro de API',
  [ErrorDefinitions.DATE_ERROR]: 'Formato de data inválido',
  [ErrorDefinitions.INCORRECT_PROVIDER]:
    'Por favor, use o login do Google para esta conta',
  [ErrorDefinitions.KEY_NOT_FOUND]: 'Chave não encontrada',
  [ErrorDefinitions.NO_ORG_ACCESS]: 'Sem acesso à organização',
  [ErrorDefinitions.NO_TEAM_ACCESS]: 'Sem acesso à equipe',
  [ErrorDefinitions.PLAN_NOT_FOUND]: 'Plano não encontrado',
  [ErrorDefinitions.ACTIVE_PAYMENT_IN_PROGRESS]: 'Pagamento ativo em andamento',
  [ErrorDefinitions.PLAN_IS_NOT_AVAILABLE]: 'Plano não está disponível',
  [ErrorDefinitions.PLAN_PRODUCT_NOT_FOUND]: 'Produto do plano não encontrado',
  [ErrorDefinitions.PRICE_NOT_FOUND]: 'Preço não encontrado',
  [ErrorDefinitions.USER_NOT_FOUND]: 'Usuário não encontrado',
  [ErrorDefinitions.USER_PLAN_NOT_FOUND]: 'Plano do usuário não encontrado',
  [ErrorDefinitions.USER_EXIST]: 'O endereço de email já existe',
  [ErrorDefinitions.WRONG_PASSWORD]: 'Senha incorreta',
  [ErrorDefinitions.USER_NOT_VERIFIED]: 'Usuário não verificado',
  [ErrorDefinitions.REFERRED_USER_EXIST]: 'Usuário referido já existe',
  [ErrorDefinitions.CAN_NOT_PURCHASE]: 'Não é possível comprar',
  [ErrorDefinitions.CAN_NOT_UPGRADE]: 'Não é possível fazer upgrade',
  [ErrorDefinitions.SUBSCRIPTION_NOT_FOUND]: 'Assinatura não encontrada',
  [ErrorDefinitions.SUBSCRIPTION_ITEM_NOT_FOUND]:
    'Item da assinatura não encontrado',
  [ErrorDefinitions.SUBSCRIPTION_CHANGE_NOT_FOUND]:
    'Alteração de assinatura não encontrada',
  [ErrorDefinitions.SUBSCRIPTION_IS_NOT_ACTIVE]: 'A assinatura não está ativa',
  [ErrorDefinitions.UNKNOWN_EVENT_TYPE]: 'Tipo de evento desconhecido',
  [ErrorDefinitions.TEAM_NOT_FOUND]: 'Equipe não encontrada',
  [ErrorDefinitions.ORG_NOT_FOUND]: 'Organização não encontrada',
  [ErrorDefinitions.ACCESS_NOT_FOUND]: 'Acesso não encontrado',
  [ErrorDefinitions.USER_ALREADY_EXISTS_IN_THIS_ORGANIZATION]:
    'O usuário já existe nesta organização',
  [ErrorDefinitions.USERS_ALREADY_EXISTS_IN_THIS_TEAM]:
    'Os usuários já existem nesta equipe',
  [ErrorDefinitions.INVITATION_ALREADY_EXISTS]: 'O convite já existe',
  [ErrorDefinitions.INVITATION_NOT_FOUND]: 'Convite não encontrado',
  [ErrorDefinitions.ORGANIZATION_NOT_FOUND]: 'Organização não encontrada',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_DOWNGRADE]:
    'Redução do tamanho de assentos da organização',
  [ErrorDefinitions.AI_CREDIT_REASON_NOT_DEFINED]:
    'Razão de crédito de IA não definida',
  [ErrorDefinitions.INSUFFICIENT_AI_CREDIT]: 'Crédito de IA insuficiente',
  [ErrorDefinitions.WEBHOOK_EXISTS]: 'Webhook já existe',
  [ErrorDefinitions.WEBHOOK_NOT_EXISTS]: 'Webhook não existe',
  [ErrorDefinitions.WEBHOOK_NO_CHANGE]: 'Nenhuma alteração no webhook',
  [ErrorDefinitions.PASSWORD_RESET_REQUEST_EXPIRED_OR_NOT_FOUND]:
    'Solicitação de redefinição de senha expirada ou não encontrada',
  [ErrorDefinitions.TEAM_IS_NOT_IN_THIS_ORGANIZATION]:
    'A equipe não está nesta organização',
  [ErrorDefinitions.USERS_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Usuários não encontrados nesta organização',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_TEAM]:
    'Usuário não encontrado nesta equipe',
  [ErrorDefinitions.USER_NOT_FOUND_IN_THIS_ORGANIZATION]:
    'Usuário não encontrado nesta organização',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_USER]:
    'Não é possível atualizar o próprio usuário',
  [ErrorDefinitions.MISSING_QUESTION_TYPES]: 'Tipos de pergunta ausentes',
  [ErrorDefinitions.USER_ALREADY_CLASSIFIED]: 'Usuário já classificado',
  [ErrorDefinitions.WRONG_VERIFICATION_CODE]:
    'O código inserido está incorreto. Por favor, verifique seu email.',
  [ErrorDefinitions.USER_ALREADY_VERIFIED]: 'Usuário já verificado',
  [ErrorDefinitions.TARGET_USER_IS_OWNER]: 'O usuário alvo é o proprietário',
  [ErrorDefinitions.CANNOT_REMOVE_SELF_USER]:
    'Não é possível remover o próprio usuário',
  [ErrorDefinitions.CANNOT_REMOVE_ADMIN_USER]:
    'Não é possível remover o usuário administrador',
  [ErrorDefinitions.ORGANIZATION_SEATSIZE_REACHED]:
    'Limite de assentos da organização atingido',
  [ErrorDefinitions.THEME_NOT_FOUND]: 'Tema não encontrado',
  [ErrorDefinitions.SLIDELAYOUT_EXIST]: 'Layout de slide já existe',
  [ErrorDefinitions.SLIDELAYOUT_NOT_FOUND]: 'Layout de slide não encontrado',
  [ErrorDefinitions.THEMECOLOR_NOT_FOUND]: 'Cor do tema não encontrada',
  [ErrorDefinitions.THEMEFONT_NOT_FOUND]: 'Fonte do tema não encontrada',
  [ErrorDefinitions.COMPONENT_NOT_FOUND]: 'Componente não encontrado',
  [ErrorDefinitions.STRIPE_CUSTOMER_NOT_FOUND]: 'Cliente Stripe não encontrado',
  [ErrorDefinitions.ASSET_NOT_FOUND]: 'Ativo não encontrado',
  [ErrorDefinitions.FOLDER_NOT_FOUND]: 'Pasta não encontrada',
  [ErrorDefinitions.DECK_NOT_FOUND]: 'Deck não encontrado',
  [ErrorDefinitions.DECK_ALREADY_SHARED]: 'Deck já compartilhado',
  [ErrorDefinitions.DECKSHARE_NOT_FOUND]:
    'Compartilhamento de deck não encontrado',
  [ErrorDefinitions.CUSTOMDOMAIN_NOT_FOUND]:
    'Domínio personalizado não encontrado',
  [ErrorDefinitions.SHORTCODE_ALREADY_EXISTS]: 'Código curto já existe',
  [ErrorDefinitions.CUSTOMDOMAIN_ALREADY_EXISTS]:
    'Domínio personalizado já existe',
  [ErrorDefinitions.CUSTOMDOMAINSLUG_ALREADY_EXISTS]:
    'Slug de domínio personalizado já existe',
  [ErrorDefinitions.SLIDEDATA_NOT_FOUND]: 'Dados do slide não encontrados',
  [ErrorDefinitions.DECKDATA_NOT_FOUND]: 'Dados do deck não encontrados',
  [ErrorDefinitions.OPENAI_MAXRETRY_REACHED]:
    'Limite máximo de tentativas do OpenAI atingido',
  [ErrorDefinitions.THEMECATEGORY_NOT_FOUND]:
    'Categoria de tema não encontrada',
  [ErrorDefinitions.MINROLE_ERROR]: 'Erro de autorização de função mínima',
  [ErrorDefinitions.SLIDE_NOT_FOUND]: 'Slide não encontrado',
  [ErrorDefinitions.INVALID_OFFSET]: 'Deslocamento inválido',
  [ErrorDefinitions.FORM_QUESTION_NOT_FOUND]:
    'Pergunta do formulário não encontrada',
  [ErrorDefinitions.UNKNOWN_FORMQUESTION_TYPE]:
    'Tipo de pergunta de formulário desconhecido',
  [ErrorDefinitions.MODERATION_ERROR]: 'Erro de moderação',
  [ErrorDefinitions.DECKPROMPT_NOT_FOUND]: 'Prompt do deck não encontrado',
  [ErrorDefinitions.DECKSTATE_IS_NOT_FINAL]: 'O estado do deck não é final',
  [ErrorDefinitions.THEMECOLOR_THEMEFONT_NOT_IN_SAME_THEMECATEGORY]:
    'A cor do tema e a fonte do tema devem estar na mesma categoria',
  [ErrorDefinitions.CONTENT_TOO_LONG]: 'O conteúdo é muito longo',
  [ErrorDefinitions.DECK_EXPORT_IN_PROGRESS]: 'Exportação do deck em andamento',
  [ErrorDefinitions.CANNOT_UPDATE_SELF_PERMISSION]:
    'Não é possível atualizar a própria permissão',
  [ErrorDefinitions.CANNOT_UPDATE_OWNERS_PERMISSION]:
    'Não é possível atualizar a permissão dos proprietários da organização',
  [ErrorDefinitions.USER_DECK_NOT_FOUND]: 'Deck do usuário não encontrado',
  [ErrorDefinitions.PASSWORD_RESET_REQUIRED]:
    'É necessário redefinir a senha, por favor, altere sua senha',
  [ErrorDefinitions.ALREADY_REFERRED]: 'Este usuário já foi referido',
  [ErrorDefinitions.CAPTCHA_NOT_CORRECT]: 'Não foi possível validar o Captcha',
  [ErrorDefinitions.CANCEL_PROMO_ALREADY_USED]: 'A promoção já foi utilizada',
  [ErrorDefinitions.SUBSCRIPTION_ID_NOT_FOUND]:
    'ID da assinatura não encontrado',
}
