import { RefObject, useMemo } from 'react'
import { APP_CONFIG } from 'src/config'
import { useRectObserver } from 'src/hooks'

export const useCanvasScale = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
) => {
  const refRect = useRectObserver(ref)

  const scale = useMemo(() => {
    const maxScaleByWidth = (refRect?.width || 1) / APP_CONFIG.canvasMasterWidth
    const maxScaleByHeight =
      (refRect?.height || 1) /
      (APP_CONFIG.canvasMasterWidth / APP_CONFIG.canvasRatio)
    return Math.min(maxScaleByWidth, maxScaleByHeight)
  }, [refRect])

  return scale
}
