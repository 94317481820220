import React, { useCallback, useMemo, useRef, useState } from 'react'

import { IPropColor } from './types'
import {
  colorCategoryStyles,
  colorDisplayStyles,
  colorSelectionStyles,
  menuStyles,
  propColorStyles,
} from './styles'
import {
  COLOR_PICKER_TYPE,
  ColorPicker,
  ContextMenu,
  IColorPicker,
  IContextMenu,
  Icon,
  icons,
} from 'src/lib'
import { useTheme } from 'src/theme'
import { useClickOutside, useLanguage } from 'src/hooks'
import { ColorItem } from './ColorItem'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'
import { useDispatch, useSelector } from 'react-redux'
import {
  RootState,
  deleteSwatch,
  newSwatch,
  setAllowDeleteShortcut,
} from 'src/store'

export const PropColor: React.FC<IPropColor> = React.memo(
  ({
    color,
    onChange,
    noGradient,
    disabled,
    width,
    height,
    className,
    dataAttr,
  }) => {
    const dispatch = useDispatch()
    const { colors } = useTheme()
    const { t } = useLanguage()

    const menuRef = useRef(null)
    const [isMenuOpen, setMenuOpen] = useState<IContextMenu['pos'] | null>(null)
    const [swatchEdit, setSwatchEdit] = useState(false)
    const colorData = useMemo(() => color, [color])
    const [showPicker, setShowPicker] = useState(false)

    const handleOnClose = useCallback(() => {
      setMenuOpen(null)
      setShowPicker(false)
      setSwatchEdit(false)
      dispatch(setAllowDeleteShortcut(true))
    }, [])

    useClickOutside(menuRef, () => {
      handleOnClose()
    })

    const { recentSwatches } = useSelector(({ recentColors }: RootState) => ({
      recentSwatches: recentColors.swatches,
    }))

    const handleDisplayClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setMenuOpen({ x: e.pageX, y: e.pageY })
        dispatch(setAllowDeleteShortcut(false))
      },
      [],
    )

    const handleColorPickerChange = useCallback<IColorPicker['onChange']>(
      (colors, rotation) => {
        onChange?.({ colors, rotation })
      },
      [onChange],
    )

    const handleNewColorMenu = useCallback(() => {
      setShowPicker(true)
    }, [])

    const handleSwatchClick = useCallback(
      (color?: GradientLikeColorSchema) => {
        if (color) {
          onChange?.(color)
          setShowPicker(false)
        } else {
          colorData && dispatch(newSwatch(colorData))
        }
      },
      [onChange, colorData],
    )

    const handleDeleteSwatch = useCallback((index: number) => {
      dispatch(deleteSwatch(index))
    }, [])

    const colorDisplayStyle = useMemo(
      () => ({
        background: colorData?.colors[1]
          ? `linear-gradient(135deg, ${colorData.colors[0]} 0%, ${colorData.colors[1]} 100%)`
          : colorData?.colors?.[0],
      }),
      [colorData],
    )

    const handleNewColorToSwatches = useCallback(
      (colors: string[], rotation?: number) => {
        dispatch(newSwatch({ colors, rotation }))
        setShowPicker(false)
      },
      [],
    )

    const swatches = useMemo(
      () =>
        Object.values(colors.data).filter((_, index) =>
          [1, 3, 5, 7, 10, 11, 14, 16, 18, 20].includes(index + 1),
        ),
      [],
    )

    const initialColors = useMemo(
      () =>
        colorData?.colors.map((color) =>
          color.includes('#') ? color : '#000000',
        ),
      [colorData],
    )

    return (
      <div
        css={propColorStyles({ disabled })}
        style={{
          width,
          height,
        }}
        className={className}
        {...dataAttr}
      >
        <div
          css={colorDisplayStyles}
          style={{ ...colorDisplayStyle }}
          onClick={handleDisplayClick}
        />
        {isMenuOpen && (
          <ContextMenu
            ref={menuRef}
            pos={isMenuOpen}
            side={['right', 'bottom']}
          >
            <div css={menuStyles}>
              <div css={colorSelectionStyles}>
                <Icon
                  icon={icons.close}
                  color={colors.white.DEFAULT}
                  className="close-button"
                  size={16}
                  onClick={handleOnClose}
                />
                <div css={colorCategoryStyles}>
                  <div className="title">
                    {t('edit.color_picker.theme_colors')}
                  </div>
                  <div className="color-list">
                    <ColorItem
                      disabled={swatchEdit}
                      color={{ colors: ['var(--first)'] }}
                      onClick={handleSwatchClick}
                    />
                    <ColorItem
                      disabled={swatchEdit}
                      color={{ colors: ['var(--second)'] }}
                      onClick={handleSwatchClick}
                    />
                    <ColorItem
                      disabled={swatchEdit}
                      color={{ colors: ['var(--third)'] }}
                      onClick={handleSwatchClick}
                    />
                    <ColorItem
                      disabled={swatchEdit}
                      color={{ colors: ['var(--fourth)'] }}
                      onClick={handleSwatchClick}
                    />
                  </div>
                </div>
                <div css={colorCategoryStyles}>
                  <div className="title">
                    <div>{t('edit.color_picker.recent_colors')}</div>
                    <div
                      className="edit-button"
                      onClick={() => setSwatchEdit(!swatchEdit)}
                    >
                      {swatchEdit
                        ? t('common.informative.done')
                        : t('common.actions.edit')}
                    </div>
                  </div>
                  <div className="color-list">
                    <ColorItem onClick={handleNewColorMenu} />
                    {recentSwatches.map((color, index) => (
                      <ColorItem
                        isDeleteMode={swatchEdit}
                        key={index}
                        color={color}
                        onClick={
                          swatchEdit
                            ? () => handleDeleteSwatch(index)
                            : handleSwatchClick
                        }
                      />
                    ))}
                    <ColorItem
                      disabled={swatchEdit}
                      color={{ colors: ['#FFFFFF'] }}
                      onClick={handleSwatchClick}
                    />
                    {swatches.map((color, index) => (
                      <ColorItem
                        disabled={swatchEdit}
                        key={index}
                        color={{ colors: [color] }}
                        onClick={handleSwatchClick}
                      />
                    ))}
                    <ColorItem
                      disabled={swatchEdit}
                      color={{ colors: ['#000000'] }}
                      onClick={handleSwatchClick}
                    />
                  </div>
                </div>
              </div>
              {showPicker && (
                <ColorPicker
                  className="color-picker"
                  noGradient={noGradient}
                  initialColors={initialColors}
                  initialRotate={colorData?.rotation}
                  initialType={
                    colorData?.colors[1]
                      ? COLOR_PICKER_TYPE.GRADIENT
                      : COLOR_PICKER_TYPE.SOLID
                  }
                  onChange={handleColorPickerChange}
                  onClose={() => setShowPicker(false)}
                  onClickAdd={handleNewColorToSwatches}
                />
              )}
            </div>
          </ContextMenu>
        )}
      </div>
    )
  },
)

PropColor.displayName = 'PropColor'
