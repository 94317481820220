import { css } from '@emotion/react'

export const quoteStyles = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    flex-shrink: 0;
  }
`

export const quoteTextStyles = css`
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: 1.2em;
  max-width: 100%;
  word-break: break-word;
  white-space: break-spaces;
  font-family: var(--font-primary);
`

export const quoteAuthorStyles = css`
  font-family: var(--font-secondary);
  word-break: break-word;
  white-space: break-spaces;
  max-width: 100%;
`
