import React, { useState } from 'react'
import { BUTTON_SIZE, BUTTON_THEME, Button, icons } from 'src/lib'
import { IHearAboutUs } from './types'
import { buttonStyles, continueButtonStyles } from '../styles'
import { useNavigate } from 'react-router-dom'
import { useUserApi } from 'src/hooks'
import { UserClassifyQuestionTypes } from 'src/types/api/enums'
import { useLanguage } from 'src/hooks'
export const HearAboutUs: React.FC<IHearAboutUs> = ({
  selectedPurpose,
  selectedBusinessType,
}) => {
  const navigate = useNavigate()
  const { t } = useLanguage()
  const { postSaveUserClassifies } = useUserApi()
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const handleClick = async (purpose: string) => {
    try {
      const data = [
        {
          questionType: UserClassifyQuestionTypes.CLASS,
          answer: selectedPurpose,
        },
        {
          questionType: UserClassifyQuestionTypes.AREA_OF_USAGE,
          answer: selectedBusinessType,
        },
        {
          questionType: UserClassifyQuestionTypes.HEARD_US_FROM,
          answer: purpose,
        },
      ]
      await postSaveUserClassifies(data)
      setIsButtonClicked(true)
    } catch {
      return false
    }
  }
  const onDashboardClick = () => {
    navigate('/init/dashboard', { replace: true })
  }
  return (
    <div css={buttonStyles}>
      <Button
        icon={icons.like}
        size={BUTTON_SIZE.XSMALL}
        text={t('user_classify.social_media')}
        theme={BUTTON_THEME.WHITE}
        onClick={() => handleClick('Social Media')}
      />
      <Button
        icon={icons.team}
        size={BUTTON_SIZE.XSMALL}
        text={t('user_classify.friends')}
        theme={BUTTON_THEME.WHITE}
        onClick={() => handleClick('Friends')}
      />
      <Button
        icon={icons.search_in_list}
        size={BUTTON_SIZE.XSMALL}
        text={t('user_classify.google_search')}
        theme={BUTTON_THEME.WHITE}
        onClick={() => handleClick('Google Search')}
      />
      <Button
        size={BUTTON_SIZE.XSMALL}
        text={t('user_classify.other')}
        theme={BUTTON_THEME.WHITE}
        onClick={() => handleClick('Other')}
      />
      <Button
        theme={BUTTON_THEME.PURPLE}
        size={BUTTON_SIZE.XSMALL}
        css={continueButtonStyles}
        text={t('user_classify.continue_with_decktopus')}
        onClick={onDashboardClick}
        disabled={!isButtonClicked}
      />
    </div>
  )
}
export default HearAboutUs
