import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const searchResultsWrapperStyles = ({ gap }: ITheme) => css`
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: ${gap['2xsmall']}px;
  height: 270px;
  padding-right: 10px;
  width: calc(100% + 18px);
`

export const searchMediaResultsColumnStyles = css`
  flex: 0 0 32%;
  height: min-content;
`

export const mediaItemResultsStyles = ({ gap }: ITheme) => css`
  cursor: pointer;
  margin-bottom: ${gap.small}px;
`

export const mediaItemResultsImageStyles = ({ borderRadius }: ITheme) => css`
  width: 100%;
  border-radius: ${borderRadius[4]};
  transition: all 0.6s ease-in-out;
  max-height: 181px;
`

export const mediaItemResultsOwnerNameStyles = ({
  colors,
  fontSize,
}: ITheme) => css`
  color: ${colors.text[2]};
  font-size: ${fontSize.xsmall};
  height: 16px;
  width: 116px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`

export const searchIconResultsStyles = ({ gap }: ITheme) => css`
  width: 100%;
  display: grid;
  grid-gap: ${gap.xsmall}px;
  grid-template-columns: repeat(
    6,
    calc((100% / 6) - ${(gap.xsmall * 5) / 6}px)
  );

  .icon-item {
    grid-column: 1 span;
    aspect-ratio: 1;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    padding: ${gap.small}px;
    background-origin: content-box, padding-box;
  }
`

export const searchResultActionsStyles = ({ gap }: ITheme) => css`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${gap.medium}px;
  height: 50px;
`
