import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: ${gap['3xlarge']}px;
  width: 650px;
  min-height: 360px;
`
export const testimonyBoxStyles = ({ borderRadius, gap }: ITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 304px;
  height: 197px;
  border-radius: ${borderRadius.small};
  padding: ${gap.large}px;
  background: rgba(255, 255, 255, 0.3);
`

export const titleStyles = ({ gap, colors, fontWeight }: ITheme) => css`
  text-align: center;
  color: ${colors.white.DEFAULT};
  font-size: 64px;
  font-weight: ${fontWeight.bold};
  padding-bottom: ${gap['2xsmall']}px;
  width: 100%;
`

export const textStyles = ({ fontSize, colors }: ITheme) => css`
  font-size: ${fontSize.medium};
  color: ${colors.white.DEFAULT};
  text-align: center;
  width: 100%;
`
