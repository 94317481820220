import React from 'react'
import { dataAttrType } from 'src/types'

export enum EXPANDER_THEME {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum EXPANDER_DIR {
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export interface IExpanderPropItem {
  prop: 'width'
  change: ['0px', '400px']
}

export interface IExpander {
  theme?: EXPANDER_THEME
  dir?: EXPANDER_DIR
  props: [string, [string, string]]
  expanded?: boolean
  onStateChange?: (state: boolean) => void
  className?: string
  dataAttr?: dataAttrType
  children?: React.ReactNode
  outerChildren?: React.ReactNode
  expander?: React.ReactNode
}
