import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IVoiceRecording } from './types'
import { voiceRecordingStyles, rowStyles } from './styles'
import { Icon, icons } from 'src/lib'
import MicRecorder from 'mic-recorder-to-mp3'
import { colors, theme } from 'src/theme'
import {
  useClickOutside,
  useConfigApi,
  useDecksApi,
  useLanguage,
} from 'src/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const VoiceRecording: React.FC<IVoiceRecording> = React.memo(
  ({ onClose, audioUrl: audioUrlProp, noUpload, className, dataAttr }) => {
    const { updateSlideSound } = useDecksApi()
    const { uploadFile } = useConfigApi()
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [isRecording, setIsRecording] = useState(false)
    const [isAudioPlaying, setIsAudioPlaying] = useState(false)
    const [audioUrl, setAudioUrl] = useState(audioUrlProp)
    const [audioFile, setAudioFile] = useState<File | undefined>()
    const [duration, setDuration] = useState<number>(0)
    const [currentTime, setCurrentTime] = useState<number>(0)
    const recorderRef = useRef<typeof MicRecorder | null>(null)
    const intervalRef = useRef<NodeJS.Timeout | null>(null)
    const audioInstanceRef = useRef<HTMLAudioElement | null>(null)
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const { t } = useLanguage()

    const handleOnClose = useCallback(() => {
      pauseAudio()
      onClose?.()
    }, [])
    useClickOutside(wrapperRef, handleOnClose)

    const { soundUrl } = useSelector(({ edit }: RootState) => ({
      soundUrl: edit.activeDeck.data?.deckData?.data.slides.find(
        ({ slideId }) => slideId === edit.activeSlideID,
      )?.soundUrl,
    }))

    useEffect(() => {
      soundUrl && setAudioUrl(soundUrl)
    }, [soundUrl])

    useEffect(() => {
      if (!isRecording) return

      startRecording()

      return () => {
        clearInterval(intervalRef.current!)
      }
    }, [isRecording])

    useEffect(() => {
      if (audioInstanceRef.current) {
        audioInstanceRef.current.addEventListener('ended', () => {
          setIsAudioPlaying(false)
        })
        audioInstanceRef.current.addEventListener('timeupdate', () => {
          setCurrentTime(audioInstanceRef.current!.currentTime)
        })
      }

      return () => {
        if (audioInstanceRef.current) {
          audioInstanceRef.current.removeEventListener('ended', () => {
            setIsAudioPlaying(false)
          })
          audioInstanceRef.current.removeEventListener('timeupdate', () => {
            setCurrentTime(audioInstanceRef.current!.currentTime)
          })
        }
      }
    }, [audioInstanceRef.current])

    useEffect(() => {
      audioInstanceRef.current = new Audio(audioUrl)
      return () => {
        pauseAudio()
      }
    }, [audioUrl])

    const handleStartStopRecording = async () => {
      if (!isRecording) {
        try {
          setDuration(0)
          setIsRecording(true)
        } catch (error) {
          console.error(t('common.informative.error_start_recording'), error)
        }
      } else {
        try {
          const result = await recorderRef.current!.stop()
          const [, blob] = await result.getMp3()
          // upload blob to backend, get url from promise and store it below state
          const file = new File([blob], 'SoundRecord', {
            type: 'audio/mpeg',
          })

          setAudioFile(file)
          setAudioUrl(URL.createObjectURL(blob))
          setIsRecording(false)
          if (intervalRef.current) clearInterval(intervalRef.current)
        } catch (error) {
          console.error(t('common.informative.error_stop_recording'), error)
        }
      }
    }

    const startRecording = async () => {
      try {
        recorderRef.current = new MicRecorder({
          bitRate: 128,
        })

        await recorderRef.current.start()

        setIsRecording(true)

        intervalRef.current = setInterval(() => {
          setDuration((prevDuration) => prevDuration + 1)
        }, 1000)
      } catch (error) {
        console.error(t('common.informative.error_start_recording'), error)
      }
    }

    const playAudio = () => {
      if (!isAudioPlaying && audioInstanceRef.current) {
        audioInstanceRef.current.volume = 1
        audioInstanceRef.current.play()
        setIsAudioPlaying(true)
      }
    }

    const pauseAudio = () => {
      if (audioInstanceRef.current) {
        audioInstanceRef.current.pause()
        setIsAudioPlaying(false)
      }
    }

    const uploadAudio = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }

    const deleteAudio = () => {
      if (isAudioPlaying) return
      setAudioUrl('')
      setDuration(0)
      setAudioFile(undefined)
      updateSlideSound()
    }

    const formatDuration = (timeInSeconds: number): string => {
      const minutes = Math.floor(timeInSeconds / 60)
      const seconds = Math.floor(timeInSeconds % 60)
      return `${minutes < 10 ? '0' : ''}${minutes}:${
        seconds < 10 ? '0' : ''
      }${seconds}`
    }

    const handleFileInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0]
        const fileNameParts = file.name.split('.')
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase()
        if (fileExtension !== 'mp3') {
          console.error('Wrong format. Only MP3 files are allowed.')
          return
        }
        setAudioFile(file)
        setAudioUrl(URL.createObjectURL(file))
      }
    }

    const saveAudioToSlide = useCallback(async () => {
      if (!audioFile) {
        return
      }
      const accessUrl = await uploadFile({ file: audioFile, extension: 'mp3' })
      updateSlideSound(accessUrl)
    }, [audioFile, duration])

    useEffect(() => {
      saveAudioToSlide()
    }, [saveAudioToSlide, audioFile, duration])

    const playElement = (
      <div
        css={rowStyles(true)}
        className={noUpload ? 'voice-recording-no-upload' : 'voice-recording'}
        onClick={isAudioPlaying ? pauseAudio : playAudio}
      >
        <Icon
          size={14}
          icon={isAudioPlaying ? icons.stop : icons.play}
          color={noUpload ? colors.white.DEFAULT : colors.black.DEFAULT}
        />
        <div>
          {isAudioPlaying
            ? formatDuration(currentTime)
            : t('common.actions.listen_voice')}
        </div>
      </div>
    )
    if (noUpload) return playElement

    return (
      <div
        css={voiceRecordingStyles}
        ref={wrapperRef}
        className={className}
        {...dataAttr}
      >
        {!audioUrl ? (
          <>
            <div css={rowStyles(true)} onClick={handleStartStopRecording}>
              <Icon icon={isRecording ? icons.stop : icons.record} size={14} />
              <div>
                {isRecording
                  ? formatDuration(duration)
                  : t('common.actions.record_voice')}
              </div>
            </div>

            <div css={rowStyles(!isRecording)} onClick={uploadAudio}>
              <Icon
                size={14}
                icon={icons.upload}
                color={
                  isRecording ? theme.colors.text[2] : theme.colors.text.DEFAULT
                }
              />
              <div>{t('common.actions.upload')}</div>
              <input
                type="file"
                accept="audio/mp3"
                style={{ display: 'none' }}
                onChange={handleFileInputChange}
                ref={fileInputRef}
              />
            </div>
          </>
        ) : (
          <>
            {playElement}
            <div css={rowStyles(!isAudioPlaying)} onClick={deleteAudio}>
              <Icon
                size={14}
                icon={icons.trash_can}
                color={
                  isAudioPlaying
                    ? theme.colors.text[2]
                    : theme.colors.text.DEFAULT
                }
              />
              <div>{t('common.actions.delete')}</div>
            </div>
          </>
        )}
      </div>
    )
  },
)

VoiceRecording.displayName = 'VoiceRecording'
