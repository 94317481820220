import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const propHeaderStyles = ({
  fontWeight,
  gap,
  colors,
  fontSize,
}: ITheme) => css`
  font-weight: ${fontWeight.medium};
  margin-bottom: ${gap.small}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .side-button {
    text-decoration: underline;
    color: ${colors.elements.DEFAULT};
    font-size: ${fontSize.small};
    cursor: pointer;
  }
`
