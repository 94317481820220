import { useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'

import Box from '@mui/material/Box'

import { AssetTypes } from 'src/types/api/enums'
import sparkleIcon from 'src/lib/icon/svg/sparkle.svg'
import ToggleButton from '@mui/material/ToggleButton'
import { IPolotnoComponent } from '../../types'
import SectionTab from '../section-tab'
import MediaUploader from '../media-uploader'
import ImageGridLoader from '../image-grid-loader'
import { IImageGridRef } from '../image-grid-loader/types'
import { uploaderConfig } from './constants/mediaConstants'

type StorageTabs = 'uploaded' | 'aiImage'
const StoragePanel = observer<IPolotnoComponent>(({ store }) => {
  const [selectedTab, setSelectedTab] = useState<StorageTabs>('uploaded')
  const uploadedGridRef = useRef<IImageGridRef>(null)

  const onAssetReady = async () => {
    uploadedGridRef.current?.reset()
    setSelectedTab('uploaded')
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        p: 3,
      }}
    >
      <MediaUploader config={uploaderConfig} onAssetReady={onAssetReady} />
      <SectionTab
        size="small"
        exclusive
        value={selectedTab}
        fullWidth
        onChange={(_, value) => value && setSelectedTab(value)}
        sx={{ margin: '20px 0 20px 0px' }}
      >
        <ToggleButton fullWidth value="uploaded" sx={{ fontSize: '13px' }}>
          Uploaded Files
        </ToggleButton>
        <ToggleButton value="aiImage" fullWidth sx={{ fontSize: '13px' }}>
          <img
            src={sparkleIcon}
            width={20}
            height={20}
            style={{ marginRight: 4 }}
            alt="sparkle"
          />
          AI Images
        </ToggleButton>
      </SectionTab>
      {selectedTab === 'uploaded' && (
        <ImageGridLoader
          store={store}
          types={[AssetTypes.IMAGE, AssetTypes.LOGO, AssetTypes.VIDEO]}
          ref={uploadedGridRef}
        />
      )}
      {selectedTab === 'aiImage' && (
        <ImageGridLoader store={store} types={[AssetTypes.AIIMAGE]} />
      )}
    </Box>
  )
})

export default StoragePanel
