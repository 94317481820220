import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const textStyles = css`
  white-space: wrap;
  word-break: break-word;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ql-editor {
    height: auto;
  }

  .preview {
    overflow: visible;
    font-family: inherit;
    font-weight: inherit;
    text-align: inherit;
    justify-content: inherit;
  }

  .preview * {
    font-family: inherit;
    font-weight: inherit;
  }

  .preview > p {
    background: var(--dt-grad-background);
    -webkit-background-clip: var(--dt-grad--webkit-background-clip);
    -webkit-text-fill-color: var(--dt-grad--webkit-text-fill-color);
    cursor: inherit;
  }
`

export const placeholderStyles = ({ colors }: ITheme) => css`
  color: ${colors.elements.DEFAULT};
  position: absolute;
  width: 100%;
  font-family: inherit;
  font-weight: inherit;
`

export const editStyles = ({ colors, borderRadius, gap }: ITheme) => css`
  position: absolute;
  z-index: 10;
  width: 100%;
  overflow: visible;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;

  .quill {
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
  }

  .ql-container.ql-snow {
    border: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
  }

  .ql-editor {
    overflow: visible;
    font-size: inherit;
    text-align: inherit;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
  }

  .ql-editor * {
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
  }

  .ql-editor > p {
    background: var(--dt-grad-background);
    -webkit-background-clip: var(--dt-grad--webkit-background-clip);
    -webkit-text-fill-color: var(--dt-grad--webkit-text-fill-color);
    cursor: inherit;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .ql-container > .ql-tooltip:not(.ql-hidden) {
    &:before {
      display: none !important;
    }

    box-shadow: none !important;
    top: 100% !important;
    left: 0 !important;
    right: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${gap.xlarge}px;

    &:not(.ql-editing) {
      flex-wrap: wrap;
    }

    input {
      height: 100px !important;
      width: 100%;
      font-size: 32px;
      border-radius: ${borderRadius[4]};
      padding: 0 ${gap.medium}px;
      outline: none;
      border: 2px solid ${colors.text[2]} !important;

      &:focus {
        border: 4px solid ${colors.primary.DEFAULT} !important;
      }
    }

    .ql-action,
    .ql-remove {
      flex-shrink: 0;
      background: ${colors.primary.DEFAULT};
      height: 100px;
      border-radius: ${borderRadius[10]};
      color: ${colors.white.DEFAULT};
      padding: 0 24px;
    }

    .ql-action {
      display: flex;
      align-items: center;
      &:after {
        content: 'Add';
        font-size: 32px;
        margin: 0;
        padding: 0;
      }
    }

    &:not(.ql-editing) .ql-remove {
      display: flex;
      align-items: center;
      &:before {
        font-size: 32px;
      }
    }

    .ql-preview {
      &:before {
        content: 'Link: ';
        color: ${colors.text[2]};
      }
      text-align: center;
      width: 100%;
      pointer-events: none;
      font-size: 50px;
      line-height: normal;
      flex-grow: 1;
      max-width: none;
      background: ${colors.white.DEFAULT};
    }
  }
`
