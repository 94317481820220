import React, { useRef, useLayoutEffect, useMemo } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { IAnalyticsGraph } from './types'
import { analyticsGraphStyles } from './styles'
import { v4 as uuidv4 } from 'uuid'

export const AnalyticsGraph: React.FC<IAnalyticsGraph> = React.memo(
  ({ className, dataAttr, data }) => {
    const chartId = useMemo(() => {
      return uuidv4()
    }, [])

    const chartRef = useRef<am5xy.XYChart | null>(null)
    useLayoutEffect(() => {
      const root = am5.Root.new(`chartdiv-${chartId}`)
      root.setThemes([am5themes_Animated.new(root)])
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          layout: root.verticalLayout,
        }),
      )
      chart.gridContainer.toFront()

      //Y-axis
      const yRenderer = am5xy.AxisRendererY.new(root, {})
      yRenderer.labels.template.set('visible', false)
      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer,
        }),
      )
      yAxis.get('renderer').grid.template.set('forceHidden', true)

      //X-Axis
      const xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 30,
      })
      xRenderer.grid.template.setAll({
        location: 0.5,
      })
      xRenderer.labels.template.set('visible', false)
      const xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          renderer: xRenderer,
          categoryField: 'orderIndex',
          startLocation: 0.5,
          endLocation: 0.5,
        }),
      )

      xAxis.data.setAll(data)
      const tooltip = am5.Tooltip.new(root, {
        labelText:
          '[fontWeight: 400 fontSize: 10px #9AA0AB]{orderIndex} slide[/]\n[fontWeight: 500 fontSize: 12px #242424]{viewCount}[/]',
        getFillFromSprite: false,
        keepTargetHover: true,
        width: 67,
        tooltipY: 0,
      })
      tooltip.get('background')?.setAll({
        fill: am5.color('#FFFFFF'),
        fillOpacity: 1,
        shadowColor: am5.color(0x000000),
        shadowBlur: 10,
        shadowOpacity: 0.1,
        shadowOffsetX: 10,
        shadowOffsetY: 10,
      })
      const series = chart.series.push(
        am5xy.SmoothedXLineSeries.new(root, {
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'viewCount',
          categoryXField: 'orderIndex',
          tooltip: tooltip,
        }),
      )
      const gradient = am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color('#5D4FFF'),
          },
          {
            color: am5.color('#FFFFFF'),
          },
        ],
      })
      series.fills.template.setAll({
        fillOpacity: 1,
        visible: true,
        fillGradient: gradient,
      })
      series.data.setAll(data)
      chart.set('cursor', am5xy.XYCursor.new(root, {}))
      const cursor = chart.get('cursor')
      cursor?.lineX.setAll({
        visible: false,
      })

      cursor?.lineY.setAll({
        visible: false,
      })
      chartRef.current = chart
      root._logo?.dispose()
      return () => {
        root.dispose()
      }
    }, [])

    return (
      <div
        id={`chartdiv-${chartId}`}
        css={analyticsGraphStyles}
        className={className}
        {...dataAttr}
      />
    )
  },
)
AnalyticsGraph.displayName = 'AnalyticsGraph'
