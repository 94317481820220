import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as htmlToImage from 'html-to-image'

import { v4 as uuidv4 } from 'uuid'
import {
  RootState,
  SAVE_STATE,
  setActiveDeck,
  setDecks,
  setSlideData,
  setDeckSave,
  setSaveState,
  setDeckName,
  setDeleteDeck,
  setDuplicateDeck,
  setMoveDeck,
  slideCreateUpdate,
  setNewDeckFromNextPage,
  setDeckOrgPermission,
  setCollabratorDelete,
  setCollabratorPermissionUpdate,
  setExportData,
  setDeckShareStatus,
  setSingleDeck,
  setWaitingDecksStates,
  setTips,
  setQA,
  setSlideNotes,
  setPivotDeckPublishStatus,
  setPivotDecks,
  setPivotLength,
} from 'src/store'
import { API_CONFIG, APP_CONFIG } from 'src/config'
import { ObjectServices, RequestResponse, RequestServices } from 'src/services'
import {
  defaultResponseSchema,
  getDeckAnalyticsResponse,
  getDeckDetailedResponse,
  getDeckExportsResponse,
  getDeckFormResponsesResponse,
  getDecksResponse,
  getPendingDeckInvites,
  getPivotDecksResponse,
  getSlideDataResponse,
  postCreateDeckResponse,
  postDuplicateDeckResponse,
  postShareDeckResponse,
  putUpdateDeckDataResponse,
} from 'src/types/api/responseObjects'
import {
  postCreateDeckBody,
  deckIdParams,
  deckIdSlideIdSlideDataIdParams,
  putDeckUpdateBody,
  putUpdateDeckDataBody,
  postDeckMoveBody,
  postDeckInviteBody,
  putUpdateDeckInviteBody,
  putUpdateDeckCollaboratorPermissionBody,
  putUpdateDeckShareBody,
  postDeckVisitBody,
  putUpdateSlideDataBody,
  ThemeColorMapSchema,
  postDuplicateDeckBody,
} from 'src/types/api/requestObjects'
import { IData } from 'src/services/objectServices'
import { IWorkspace } from 'src/components/workspace-selector'
import { useConfigApi } from 'src/hooks'
import { THUMBNAIL_TARGET_ID } from 'src/components/canvas/consts'
import { getDeckExportsQuery } from 'src/types/api/requestObjects'
import {
  DeckExportTypes,
  DeckStates,
  EditorVersions,
  PivotDeckCategories,
} from 'src/types/api/enums'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { IExportDropdownValue } from 'src/components/share-view/components/export'

interface IUseDecksApi {
  getDecks: () => Promise<void>
  getPivotDecks: ({
    category,
    offset,
    search,
  }: {
    category: PivotDeckCategories | string
    offset?: number
    search?: string
  }) => Promise<getPivotDecksResponse['data']['pivotDecks']>
  getSingleDeck: (params: deckIdParams) => Promise<DeckStates>
  getDeck: (
    params: deckIdParams,
    forceSessionId?: string,
  ) => Promise<getDeckDetailedResponse['data'] | void>
  newDeck: (params: {
    themeId?: number
    editor?: EditorVersions
  }) => Promise<postCreateDeckResponse['data'] | undefined>
  getExport: ({
    selectedExportType,
    exportSlideNotes,
    deckId,
  }: {
    selectedExportType: IExportDropdownValue
    exportSlideNotes: boolean
    deckId: number
  }) => Promise<boolean>
  oldGetExport: (exportType: boolean, id: number) => Promise<boolean>
  getDownloadedExportList: (
    params: getDeckExportsQuery,
    id: number,
  ) => Promise<getDeckExportsResponse['data']['exports']>
  newAiDeck: (
    params: postCreateDeckBody,
  ) => Promise<number | string | undefined>
  getSlideData: (
    params: deckIdSlideIdSlideDataIdParams,
  ) => Promise<getSlideDataResponse['data']['slideData'] | undefined>
  updateThumbnail: () => Promise<boolean>
  updateSlideNotes: (params: putUpdateSlideDataBody) => Promise<boolean>
  moveDeck: (
    data: postDeckMoveBody,
    params: deckIdParams,
    folderName?: string,
  ) => Promise<boolean>
  duplicateDeck: (params: {
    id: number
    workspaceId?: IWorkspace['id']
  }) => Promise<number | undefined>
  deleteDeck: (params: deckIdParams) => Promise<boolean>
  isLoading: boolean
  updateDeck: (
    params: deckIdParams,
    data: putDeckUpdateBody,
    updateThumbnailUrl?: boolean,
    options?: {
      replaceDeck?: boolean
    },
  ) => Promise<boolean>
  updateDeckShare: (
    deckId: number,
    params: putUpdateDeckShareBody,
  ) => Promise<boolean>
  shareDeck: (
    params: deckIdParams,
    deckOrganizationId?: number | null,
    context?: 'deck' | 'dashboard',
  ) => Promise<postShareDeckResponse['data']['shortCode']>
  deckAnalytics: (
    params: deckIdParams,
    deckOrganizationId?: number | null,
  ) => Promise<getDeckAnalyticsResponse['data']['analytics']>
  deckFormResponses: (
    params: deckIdParams,
    deckOrganizationId?: number | null,
  ) => Promise<getDeckFormResponsesResponse['data']>
  getDeckPendingInvites: (
    params: deckIdParams,
    deckOrganizationId?: number | null,
  ) => Promise<getPendingDeckInvites['data'] | undefined>
  deleteDeckInvite: (
    deckId: number,
    inviteId: number,
    organizationId?: number,
  ) => Promise<boolean>
  putUpdateDeckInvite: (
    ddeckId: number,
    inviteId: number,
    body: putUpdateDeckInviteBody,
  ) => Promise<boolean>
  deleteDeckCollabrator: (
    deckId: number,
    inviteId: number,
    organizationId?: number,
  ) => Promise<boolean>
  putUpdateDeckCollaboratorPermission: (
    ddeckId: number,
    inviteId: number,
    body: putUpdateDeckCollaboratorPermissionBody,
  ) => Promise<boolean>
  postDeckInvite: (deckId: number, body: postDeckInviteBody) => Promise<void>
  saveChanges: (isInstantSave?: boolean) => Promise<boolean>
  updateSlideSound: (soundUrl?: string) => Promise<boolean>
  getNextDeckAfterDeleteOrMove: () => Promise<void>
  postDeckVisit: ({
    deckId,
    body,
  }: {
    deckId: number
    body?: postDeckVisitBody
  }) => void
  getPublicSlideData: (
    params: deckIdSlideIdSlideDataIdParams,
    doNotStoreData?: boolean,
  ) => Promise<getSlideDataResponse['data']['slideData']>
  getPublicDeckData: ({
    shareKey,
    customDomain,
    doNotStoreData,
  }: {
    shareKey: string
    customDomain?: string
    doNotStoreData?: boolean
  }) => Promise<getDeckDetailedResponse['data'] | undefined>
  updateDeckThemeFont: (
    data: putUpdateDeckDataBody['deckData'],
  ) => Promise<boolean>
  updateDeckThemeColor: (
    data: putUpdateDeckDataBody['deckData'],
  ) => Promise<boolean>
  migrateDeck: (params: deckIdParams) => Promise<boolean>
  updateUserThemeColorMap: ({
    field,
    themeId,
  }: {
    field: ThemeColorMapSchema[keyof ThemeColorMapSchema]
    themeId?: number
  }) => Promise<boolean>
  updateUserThemeColorMapWithCachedMapping: ({
    mapping,
    themeId,
  }: {
    mapping: ThemeColorMapSchema
    themeId?: number
  }) => Promise<boolean>
  publishPivotDeck: (params: {
    id: number
    workspaceId?: IWorkspace['id']
    isHidden?: boolean
  }) => Promise<boolean>
}

export const useDecksApi = (): IUseDecksApi => {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { uploadImage } = useConfigApi()
  const navigate = useNavigate()

  const {
    deckId,
    sessionId,
    slideId,
    savingStack,
    toBeSaved,
    saveState,
    instantSave,
    decksQuery,
    activePage,
    workspaceId,
    activeSlideDataId,
    deckColorMap,
    pivotDecksLength,
  } = useSelector(({ edit, canvas, decks, workspace }: RootState) => ({
    deckId: edit.activeDeck.data?.deck?.id,
    sessionId: edit.activeDeck.data?.deckData?.sessionId,
    toBeSaved: edit.toBeSaved,
    instantSave: edit.instantSave,
    savingStack: edit.savingStack,
    slideId: edit.activeSlideID,
    saveState: canvas.saveState,
    decksQuery: decks.decksQuery,
    activePage: decks.activePage,
    workspaceId: workspace.id,
    activeSlideDataId: edit.activeSlideDataID,
    deckColorMap:
      edit.activeDeck.data?.deckData?.data.userThemePreferences?.colorMap,
    pivotDecksLength: decks.pivotLength,
  }))

  const migrateDeck = useCallback(
    async (params: deckIdParams) => {
      setIsLoading(true)
      try {
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.DECK_MIGRATE(params.deckId.toString())}`,
          params: {
            ...(workspaceId ? { organizationId: workspaceId } : {}),
          },
        })

        setIsLoading(false)
        return true
      } catch {
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [workspaceId],
  )

  const getDecks = async () => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getDecksResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.DECKS,
          params: { ...decksQuery, organizationId: workspaceId },
        })
      dispatch(setDecks({ data: res.data.data }))
    } finally {
      setIsLoading(false)
      dispatch(setDecks({ isLoading: false }))
    }
  }

  const getPivotDecks = useCallback(
    async ({
      category,
      offset,
      search,
    }: {
      category: PivotDeckCategories | string
      offset?: number
      search?: string
    }) => {
      setIsLoading(true)
      try {
        const params = {
          offset: offset !== undefined ? offset : pivotDecksLength,
          limit: search ? APP_CONFIG.templatesMaxLimit : 10,
          category,
          search,
        }
        const res: RequestResponse<getPivotDecksResponse, any> =
          await RequestServices.callApi({
            method: 'GET',
            url: API_CONFIG.DECKS_PIVOTS,
            params,
          })
        dispatch(setPivotDecks(res.data.data.pivotDecks))
        dispatch(
          setPivotLength(
            search
              ? 0
              : offset !== undefined
                ? offset + res.data.data.pivotDecks.length
                : pivotDecksLength + res.data.data.pivotDecks.length,
          ),
        )
        return res.data.data.pivotDecks
      } finally {
        setIsLoading(false)
      }
    },
    [pivotDecksLength],
  )

  const getSingleDeck = async (params: deckIdParams) => {
    setIsLoading(true)
    const singleDeckQuery = {
      ...decksQuery,
      limit: 1,
    }
    try {
      const res: RequestResponse<getDecksResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.DECKS,
          params: {
            ...singleDeckQuery,
            organizationId: workspaceId,
            deckId: params.deckId,
          },
        })
      const data = res.data.data.decksWithMeta[0]
      dispatch(setSingleDeck({ data: data }))
      dispatch(
        setWaitingDecksStates({
          id: data.deck.id,
          state: data.deck.state,
          name: data.deck.name,
        }),
      )
      return data.deck.state
    } finally {
      setIsLoading(false)
      dispatch(setSingleDeck({ isLoading: false }))
    }
  }

  const getDeck = useCallback(
    async (params: deckIdParams, sessionId?: string) => {
      setIsLoading(true)
      dispatch(setActiveDeck({ isLoading: true }))
      try {
        const res: RequestResponse<getDeckDetailedResponse, any> =
          await RequestServices.callApi({
            method: 'GET',
            url: API_CONFIG.DECK(params.deckId.toString()),
            params: {
              sessionId: sessionId || uuidv4(),
              organizationId: workspaceId,
            },
          })

        dispatch(setActiveDeck({ data: res.data.data }))
        dispatch(setTips(res.data.data.deck?.deckTipGroups || []))
        dispatch(setQA(res.data.data.deck?.deckQAs || []))
        return res.data.data
      } catch (error: any) {
        if ([404].includes(error?.response?.status))
          navigate('/expired-page', { replace: true })
      } finally {
        setIsLoading(false)
        dispatch(setActiveDeck({ isLoading: false }))
      }
    },
    [workspaceId],
  )

  const newDeck = async ({
    themeId,
    editor = EditorVersions.V2,
  }: {
    themeId?: number
    editor?: EditorVersions
  }): Promise<postCreateDeckResponse['data'] | undefined> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postCreateDeckResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: API_CONFIG.DECKS,
          data: {
            themeId,
            organizationId: workspaceId,
            viewMode: decksQuery.viewMode,
            folderId: decksQuery.folderId,
            editor,
          },
        })

      return res.data.data
    } catch {
      return undefined
    } finally {
      setIsLoading(false)
    }
  }

  const getExport = async ({
    selectedExportType,
    exportSlideNotes,
    deckId,
  }: {
    selectedExportType: IExportDropdownValue
    exportSlideNotes: boolean
    deckId: number
  }): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: `${API_CONFIG.DECKS}/${deckId}/exports`,
        data: {
          organizationId: workspaceId,
          type:
            selectedExportType === IExportDropdownValue.PPT ||
            selectedExportType === IExportDropdownValue.PPTCOMPRESSED
              ? DeckExportTypes.PPTX
              : DeckExportTypes.PDF,
          isCompressed:
            selectedExportType === IExportDropdownValue.PPTCOMPRESSED ||
            selectedExportType === IExportDropdownValue.PDFCOMPRESSED,
          exportSlideNotes,
        },
      })
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const oldGetExport = async (
    exportType: boolean,
    id: number,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: `${API_CONFIG.DECKS}/${id}/exports`,
        data: {
          organizationId: workspaceId,
          type: exportType ? DeckExportTypes.PPTX : DeckExportTypes.PDF,
        },
      })
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getDownloadedExportList = async (
    params: getDeckExportsQuery,
    id: number,
  ) => {
    setIsLoading(true)
    dispatch(setSlideData({ isLoading: true }))
    try {
      const res: RequestResponse<getDeckExportsResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: `${API_CONFIG.DECKS}/${id}/exports`,
          params: {
            organizationId: params.organizationId,
          },
        })
      dispatch(setExportData(res.data.data.exports))
      return res.data.data.exports
    } finally {
      setIsLoading(false)
      dispatch(setSlideData({ isLoading: false }))
    }
  }

  const newAiDeck = async (
    params: postCreateDeckBody,
  ): Promise<number | string | undefined> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postCreateDeckResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: API_CONFIG.DECKS,
          data: {
            ...(params.organizationId && {
              organizationId: params.organizationId,
            }),
            themeId: params.themeId,
            deckPromptId: params.deckPromptId,
            organizationId: workspaceId,
            viewMode: decksQuery.viewMode,
            folderId: decksQuery.folderId,
            fileIds: params.fileIds,
          },
        })
      return res.data.data.deckId
    } catch (error) {
      return ((error as AxiosError)?.response?.data as any)?.message as string
    } finally {
      setIsLoading(false)
    }
  }

  const getSlideData = async (params: deckIdSlideIdSlideDataIdParams) => {
    setIsLoading(true)
    dispatch(setSlideData({ isLoading: true }))
    try {
      const res: RequestResponse<getSlideDataResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.SLIDE_DATA(params),
          params: {
            organizationId: workspaceId,
          },
        })
      dispatch(setSlideData({ data: res.data.data.slideData }))
      return res.data.data.slideData
    } finally {
      setIsLoading(false)
      dispatch(setSlideData({ isLoading: false }))
    }
  }

  const updateThumbnail = async (): Promise<boolean> => {
    if (!deckId) return false
    setIsLoading(true)
    try {
      const target = document.getElementById(THUMBNAIL_TARGET_ID)
      if (target) {
        const thumbnailDataURL = await htmlToImage.toPng(target)
        const res = await fetch(thumbnailDataURL)
        const blob = await res.blob()
        const file = new File([blob], 'File name', { type: 'image/png' })
        const imageUploadUrl = await uploadImage({
          file: file,
        })

        await RequestServices.callApi({
          method: 'PUT',
          url: API_CONFIG.DECK_THUMBNAIL_UPDATE(deckId.toString()),
          params: {
            organizationId: workspaceId,
          },
          data: {
            thumbnailUrl: imageUploadUrl,
          },
        })
      }
      return true
    } catch (error) {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const updateSlideNotes = async (
    params: putUpdateSlideDataBody,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: `${API_CONFIG.DECKS}/${deckId}/slides/${slideId}`,
        data: {
          slideNote: params.slideNote,
          speakerNote: params.speakerNote,
          organizationId: workspaceId,
        },
      })
      dispatch(
        setSlideNotes({
          activeSlideDataId,
          slideNote: params.slideNote,
          speakerNote: params.speakerNote,
        }),
      )
      return true
    } catch (error) {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const updateSlideSound = useCallback(
    async (soundUrl?: string): Promise<boolean> => {
      const hasRequiredData = deckId && sessionId
      if (!hasRequiredData) {
        return false
      }

      const data: putUpdateDeckDataBody = {
        organizationId: workspaceId,
        sessionId,
        deckData: {
          slides: [
            {
              id: slideId,
              soundUrl: soundUrl || null,
            },
          ],
        },
      }

      try {
        await RequestServices.callApi({
          method: 'PUT',
          url: API_CONFIG.DECK_DATA_UPDATE(deckId.toString()),
          data,
        })

        getDeck({ deckId }, sessionId)
        return true
      } catch {
        return false
      }
    },
    [deckId, sessionId, slideId],
  )

  const saveChanges = useCallback(
    async (isInstantSave?: boolean): Promise<boolean> => {
      const hasRequiredData = deckId && sessionId
      const saveRequired =
        saveState === SAVE_STATE.NOT_SAVED || saveState === SAVE_STATE.FAILED
      if (!(hasRequiredData && (saveRequired || isInstantSave))) {
        return true
      }

      const deckDataSlides = ObjectServices.mergeSaveLog([
        ...(savingStack as IData[]),
        ...(toBeSaved.data as IData[]),
        ...(instantSave.data as IData[]),
      ])

      const updateThumbnailStatus = deckDataSlides?.find(
        ({ orderIndex }) => orderIndex === 1,
      )

      const data: putUpdateDeckDataBody = {
        organizationId: workspaceId,
        sessionId,
        deckData: {
          slides: deckDataSlides,
        },
      }

      dispatch(setDeckSave(SAVE_STATE.SAVING))
      dispatch(setSaveState(SAVE_STATE.SAVING))
      try {
        const res: RequestResponse<putUpdateDeckDataResponse, any> =
          await RequestServices.callApi({
            method: 'PUT',
            url: API_CONFIG.DECK_DATA_UPDATE(deckId.toString()),
            data,
          })
        updateThumbnailStatus && updateThumbnail()

        dispatch(slideCreateUpdate(res.data.data.newSlides))
        dispatch(setDeckSave(SAVE_STATE.SAVED))
        dispatch(setSaveState(SAVE_STATE.SAVED))
        return true
      } catch {
        dispatch(setDeckSave(SAVE_STATE.FAILED))
        dispatch(setSaveState(SAVE_STATE.FAILED))
        return false
      }
    },
    [
      deckId,
      sessionId,
      saveState,
      savingStack,
      toBeSaved.data,
      instantSave.data,
    ],
  )

  const updateDeck = async (
    params: deckIdParams,
    data: putDeckUpdateBody,
    updateThumbnailUrl: boolean | undefined,
    options?: {
      replaceDeck?: boolean
    },
  ): Promise<boolean> => {
    if (!updateThumbnailUrl) {
      dispatch(setSaveState(SAVE_STATE.SAVING))
    }
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: API_CONFIG.DECK(params.deckId.toString()),
        data,
      })

      if (updateThumbnailUrl) {
        return true
      }

      if (options?.replaceDeck) {
        dispatch(setDeleteDeck({ deckId: params.deckId }))
        await getNextDeckAfterDeleteOrMove({
          organizationId: data.organizationId,
        })
      }

      if (data.name) {
        // Only if name changed
        dispatch(setDeckName({ deckId: params.deckId, name: data.name }))
      }
      if (data.deckPermission) {
        // Only if deck permission changed
        dispatch(
          setDeckOrgPermission({
            deckId: params.deckId,
            deckPermission: data.deckPermission,
          }),
        )
      }

      return true
    } catch {
      return false
    } finally {
      dispatch(setSaveState(SAVE_STATE.SAVED))

      setIsLoading(false)
    }
  }

  const updateDeckShare = async (
    deckId: number,
    params: putUpdateDeckShareBody,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: `${API_CONFIG.DECKS}/${deckId.toString()}/share`,
        data: {
          organizationId: workspaceId,
          isActive: params.isActive,
          customDomainId: params.customDomainId,
          slug: params.slug,
        },
      })
      dispatch(
        setDeckShareStatus({ deckId, params: { isActive: params.isActive } }),
      )
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const moveDeck = async (
    data: postDeckMoveBody,
    params: deckIdParams,
    folderName?: string,
  ): Promise<boolean> => {
    setIsLoading(true)
    const payload: postDeckMoveBody = {
      ...(data.organizationId
        ? { organizationId: data.organizationId ?? workspaceId }
        : {}),
      ...(data.folderId ? { folderId: data.folderId } : {}),
    }

    try {
      await RequestServices.callApi({
        method: 'POST',
        url: `${API_CONFIG.DECK_MOVE(params.deckId.toString())}`,
        data: {
          ...payload,
        },
      })
      dispatch(
        setMoveDeck({
          deckId: params.deckId,
          folderName: folderName,
          ...payload,
        }),
      )
      await getNextDeckAfterDeleteOrMove(payload)
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDeck = async (params: deckIdParams): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'DELETE',
        url: `${API_CONFIG.DECKS}/${params.deckId}`,
        params: {
          organizationId: workspaceId,
        },
      })
      dispatch(setDeleteDeck({ deckId: params.deckId }))
      await getNextDeckAfterDeleteOrMove()
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const getNextDeckAfterDeleteOrMove = async (
    queryPayload?: postDeckMoveBody,
  ) => {
    setIsLoading(true)
    const nextDeckQuery = {
      ...decksQuery,
      ...queryPayload,
      folderId: decksQuery.folderId,
      ...(workspaceId ? { organizationId: workspaceId } : {}),
    }
    nextDeckQuery.limit = 1
    nextDeckQuery.offset = activePage * APP_CONFIG.decksPerPage - 1
    try {
      const res: RequestResponse<getDecksResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.DECKS,
          params: nextDeckQuery,
        })
      dispatch(setNewDeckFromNextPage({ data: res.data.data }))
    } finally {
      setIsLoading(false)
      dispatch(setNewDeckFromNextPage({ isLoading: false }))
    }
  }
  const duplicateDeck = async ({
    id,
    workspaceId,
  }: {
    id: number
    workspaceId?: IWorkspace['id']
  }): Promise<number | undefined> => {
    setIsLoading(true)
    const payload: postDuplicateDeckBody = {
      ...(workspaceId ? { organizationId: workspaceId } : {}),
      viewMode: decksQuery.viewMode,
      folderId: decksQuery.folderId,
    }

    try {
      const res: RequestResponse<postDuplicateDeckResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.DECK_DUPLICATE(id.toString())}`,
          data: {
            ...payload,
          },
        })
      dispatch(setDuplicateDeck(res.data.data))
      return res.data.data.deck.id
    } catch {
      return undefined
    } finally {
      setIsLoading(false)
    }
  }

  const shareDeck = async (
    params: deckIdParams,
    deckOrganizationId?: number | null,
    context?: 'deck' | 'dashboard',
  ): Promise<postShareDeckResponse['data']['shortCode']> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postShareDeckResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: API_CONFIG.DECK_SHARE(params.deckId.toString()),
          params: {
            ...(deckOrganizationId
              ? { organizationId: deckOrganizationId }
              : {}),
          },
        })
      if (context === 'dashboard') {
        await getDecks()
      }
      if (context === 'deck') {
        await getDeck({ deckId: params.deckId }, sessionId)
      }
      return res.data.data.shortCode
    } finally {
      setIsLoading(false)
    }
  }

  const deckAnalytics = async (
    params: deckIdParams,
    deckOrganizationId?: number | null,
  ): Promise<getDeckAnalyticsResponse['data']['analytics']> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getDeckAnalyticsResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.DECK_ANALYTICS(params.deckId.toString()),
          params: {
            ...(deckOrganizationId
              ? { organizationId: deckOrganizationId }
              : {}),
          },
        })

      return res.data.data.analytics
    } finally {
      setIsLoading(false)
    }
  }

  const deckFormResponses = async (
    params: deckIdParams,
    deckOrganizationId?: number | null,
  ): Promise<getDeckFormResponsesResponse['data']> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getDeckFormResponsesResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.DECK_FORM_RESPONSES(params.deckId.toString()),
          params: {
            ...(deckOrganizationId
              ? { organizationId: deckOrganizationId }
              : {}),
          },
        })

      return res.data.data
    } finally {
      setIsLoading(false)
    }
  }

  const getDeckPendingInvites = async (
    params: deckIdParams,
    deckOrganizationId?: number | null,
  ): Promise<getPendingDeckInvites['data'] | undefined> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getPendingDeckInvites, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.DECK_PENDING_INVITES(params.deckId.toString()),
          params: {
            ...(deckOrganizationId
              ? { organizationId: deckOrganizationId }
              : {}),
          },
        })

      setIsLoading(false)
      return res.data.data
    } finally {
      setIsLoading(false)
    }
  }

  const postDeckInvite = async (
    deckId: number,
    body: postDeckInviteBody,
  ): Promise<void> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: API_CONFIG.DECK_INTIVE(deckId.toString()),
        data: body,
      })

      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDeckInvite = async (
    deckId: number,
    inviteId: number,
    organizationId?: number,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<defaultResponseSchema, any> =
        await RequestServices.callApi({
          method: 'DELETE',
          url: API_CONFIG.DELETE_DECK_INTIVE(
            deckId.toString(),
            inviteId.toString(),
          ),
          params: {
            organizationId,
          },
        })

      setIsLoading(false)

      return res.data.code === 200
    } finally {
      setIsLoading(false)
    }
  }

  const putUpdateDeckInvite = async (
    deckId: number,
    inviteId: number,
    body: putUpdateDeckInviteBody,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<defaultResponseSchema, any> =
        await RequestServices.callApi({
          method: 'PUT',
          url: API_CONFIG.UPDATE_DECK_INTIVE(
            deckId.toString(),
            inviteId.toString(),
          ),
          data: body,
        })

      setIsLoading(false)

      return res.data.code === 200
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDeckCollabrator = async (
    deckId: number,
    userId: number,
    organizationId?: number,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<defaultResponseSchema, any> =
        await RequestServices.callApi({
          method: 'DELETE',
          url: API_CONFIG.DELETE_DECK_COLLABRATOR(
            deckId.toString(),
            userId.toString(),
          ),
          params: {
            organizationId,
          },
        })

      const isSuccess = res.data.code === 200

      if (isSuccess) {
        dispatch(
          setCollabratorDelete({
            deckId,
            userId,
          }),
        )
      }

      setIsLoading(false)
      return isSuccess
    } finally {
      setIsLoading(false)
    }
  }

  const putUpdateDeckCollaboratorPermission = async (
    deckId: number,
    userId: number,
    body: putUpdateDeckCollaboratorPermissionBody,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<defaultResponseSchema, any> =
        await RequestServices.callApi({
          method: 'PUT',
          url: API_CONFIG.UPDATE_DECK_COLLABRATOR_PERMISSION(
            deckId.toString(),
            userId.toString(),
          ),
          data: body,
        })

      const isSuccess = res.data.code === 200

      if (isSuccess) {
        dispatch(
          setCollabratorPermissionUpdate({
            deckId,
            userId,
            newPermission: body.newPermission,
          }),
        )
      }

      setIsLoading(false)
      return isSuccess
    } finally {
      setIsLoading(false)
    }
  }

  const postDeckVisit = ({
    deckId,
    body,
  }: {
    deckId: number
    body?: postDeckVisitBody
  }) => {
    try {
      RequestServices.callApi({
        method: 'POST',
        url: API_CONFIG.DECK_VISIT(deckId.toString()),
        data: body,
      }).catch((error) => {
        console.error(error)
      })
    } catch (error) {
      return false
    }
  }

  const getPublicDeckData = async ({
    shareKey,
    customDomain,
    doNotStoreData,
  }: {
    shareKey: string
    customDomain?: string
    doNotStoreData?: boolean
  }) => {
    setIsLoading(true)
    dispatch(setSlideData({ isLoading: true }))
    try {
      const res: RequestResponse<getDeckDetailedResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.PUBLIC_DECK_DATA(shareKey),
          params: {
            customDomain,
          },
        })
      if (doNotStoreData) {
        return res.data.data
      }
      dispatch(setActiveDeck({ data: res.data.data }))
      return res.data.data
    } catch (error) {
      navigate('/expired-page', { replace: true })
      return
    } finally {
      setIsLoading(false)
      dispatch(setSlideData({ isLoading: false }))
    }
  }

  const getPublicSlideData = async (
    params: deckIdSlideIdSlideDataIdParams,
    doNotStoreData?: boolean,
  ) => {
    setIsLoading(true)
    dispatch(setSlideData({ isLoading: true }))
    try {
      const res: RequestResponse<getSlideDataResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.PUBLIC_SLIDE_DATA(params),
        })
      if (doNotStoreData) {
        return res.data.data.slideData
      }
      dispatch(setSlideData({ data: res.data.data.slideData }))
      return res.data.data.slideData
    } finally {
      setIsLoading(false)
      dispatch(setSlideData({ isLoading: false }))
    }
  }

  const updateDeckThemeFont = useCallback(
    async ({ themeFontId }: putUpdateDeckDataBody['deckData']) => {
      setIsLoading(true)
      const hasRequiredData = deckId && sessionId
      if (!hasRequiredData) {
        return false
      }

      try {
        await RequestServices.callApi({
          method: 'PUT',
          url: API_CONFIG.DECK_DATA_UPDATE(deckId.toString()),
          data: {
            organizationId: workspaceId,
            sessionId,
            deckData: {
              themeFontId,
            },
          },
        })
        getDeck({ deckId }, sessionId)
        return true
      } catch {
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [deckId, sessionId, workspaceId, getDeck],
  )

  const updateDeckThemeColor = useCallback(
    async ({ themeColorId }: putUpdateDeckDataBody['deckData']) => {
      setIsLoading(true)
      const hasRequiredData = deckId && sessionId
      if (!hasRequiredData) {
        return false
      }
      try {
        await RequestServices.callApi({
          method: 'PUT',
          url: API_CONFIG.DECK_DATA_UPDATE(deckId.toString()),
          data: {
            organizationId: workspaceId,
            sessionId,
            deckData: {
              themeColorId,
            },
          },
        })
        await getDeck({ deckId }, sessionId)
        return true
      } catch {
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [deckId, sessionId, workspaceId, getDeck],
  )

  const updateUserThemeColorMap = useCallback(
    async ({
      field,
      themeId,
    }: {
      field: ThemeColorMapSchema[keyof ThemeColorMapSchema]
      themeId?: number
    }) => {
      setIsLoading(true)
      const hasRequiredData = deckId && sessionId
      if (!hasRequiredData) {
        return false
      }

      let colorMap
      if (field === undefined) {
        colorMap = {}
      } else if (deckColorMap) {
        colorMap = ObjectServices.deepMerge(deckColorMap, field)
      }

      try {
        await RequestServices.callApi({
          method: 'PUT',
          url: API_CONFIG.DECK_DATA_UPDATE(deckId.toString()),
          data: {
            organizationId: workspaceId,
            sessionId,
            deckData: {
              themeId,
              userThemePreferences: {
                colorMap,
              },
            },
          },
        })
        await getDeck({ deckId }, sessionId)
        return true
      } catch {
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [deckId, sessionId, workspaceId, getDeck, deckColorMap],
  )

  const updateUserThemeColorMapWithCachedMapping = useCallback(
    async ({
      mapping,
      themeId,
    }: {
      mapping: ThemeColorMapSchema
      themeId?: number
    }) => {
      setIsLoading(true)
      const hasRequiredData = deckId && sessionId
      if (!hasRequiredData) {
        return false
      }

      try {
        await RequestServices.callApi({
          method: 'PUT',
          url: API_CONFIG.DECK_DATA_UPDATE(deckId.toString()),
          data: {
            organizationId: workspaceId,
            sessionId,
            deckData: {
              themeId,
              userThemePreferences: {
                colorMap: mapping,
              },
            },
          },
        })
        await getDeck({ deckId }, sessionId)
        return true
      } catch {
        return false
      } finally {
        setIsLoading(false)
      }
    },
    [deckId, sessionId, workspaceId, getDeck],
  )

  const publishPivotDeck = async ({
    id,
    workspaceId,
    isHidden,
  }: {
    id: number
    workspaceId?: IWorkspace['id']
    isHidden?: boolean
  }): Promise<boolean> => {
    setIsLoading(true)
    const hiddenState = isHidden === undefined ? false : !isHidden

    const data: { organizationId?: number; isHidden: boolean } = {
      ...(workspaceId ? { organizationId: workspaceId } : {}),
      isHidden: hiddenState,
    }

    try {
      RequestServices.callApi({
        method: 'POST',
        url: `${API_CONFIG.DECK_PUBLISH_PIVOT(id.toString())}`,
        data,
      })
      dispatch(
        setPivotDeckPublishStatus({
          deckId: id,
          isHidden: hiddenState,
        }),
      )
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return {
    getDecks,
    getPivotDecks,
    getSingleDeck,
    getDeck,
    newDeck,
    getExport,
    oldGetExport,
    getDownloadedExportList,
    newAiDeck,
    getSlideData,
    saveChanges,
    updateSlideNotes,
    updateSlideSound,
    updateDeck,
    updateDeckShare,
    moveDeck,
    deleteDeck,
    duplicateDeck,
    getNextDeckAfterDeleteOrMove,
    shareDeck,
    updateThumbnail,
    deckAnalytics,
    deckFormResponses,
    getDeckPendingInvites,
    postDeckInvite,
    deleteDeckInvite,
    putUpdateDeckInvite,
    deleteDeckCollabrator,
    putUpdateDeckCollaboratorPermission,
    postDeckVisit,
    getPublicDeckData,
    getPublicSlideData,
    updateDeckThemeFont,
    updateDeckThemeColor,
    migrateDeck,
    updateUserThemeColorMap,
    updateUserThemeColorMapWithCachedMapping,
    publishPivotDeck,
    isLoading,
  }
}
