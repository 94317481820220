import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  position: relative;
  height: ${theme.dimensions.horizontalProgressHeight.DEFAULT}px;
  width: 100%;
  background-color: ${theme.colors.outline.DEFAULT};
  border-radius: ${theme.borderRadius[12]};
`

export const progressStyles =
  (percentage: number, isFull: boolean) => (theme: ITheme) => css`
    height: 100%;
    width: ${percentage}%;
    max-width: 100%;
    transition: width 0.3s ease-in-out;
    background-color: ${isFull
      ? theme.colors.error[90]
      : theme.colors.primary.DEFAULT};
    border-radius: ${theme.borderRadius[12]};
  `
