import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const orgPageStyles = (theme: ITheme) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    flex-direction: row;
  }
`

export const orgViewWrapperStyles = css`
  width: 100%;
  height: 100%;
  position: relative;
`
