import React, { useCallback, useEffect, useState } from 'react'
import { IHitPoint, IHitPoints, IScaleValues } from './types'
import { hitPointsStyles } from './styles'
import { CANVAS_ITEM_TYPE } from 'src/components/canvas'
import { useDrag, useDragDropManager } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

const calculateValues = (
  currentValues: IScaleValues,
  offset: { x: number; y: number },
  className: string,
) => {
  let newWidth = currentValues.width
  let newHeight = currentValues.height
  let newLeft = currentValues.left
  let newTop = currentValues.top

  // Calculate new width and height based on the dragged edge or corner
  if (className.includes('corner')) {
    if (className.includes('right')) {
      newWidth = currentValues.width + offset.x
    } else if (className.includes('left')) {
      newWidth = currentValues.width - offset.x
      newLeft = currentValues.left + offset.x
    }

    if (className.includes('bottom')) {
      newHeight = currentValues.height + offset.y
    } else if (className.includes('top')) {
      newHeight = currentValues.height - offset.y
      newTop = currentValues.top + offset.y
    }
  } else if (className.includes('top')) {
    newHeight = currentValues.height - offset.y
    newTop = currentValues.top + offset.y
  } else if (className.includes('bottom')) {
    newHeight = currentValues.height + offset.y
  } else if (className.includes('left')) {
    newWidth = currentValues.width - offset.x
    newLeft = currentValues.left + offset.x
  } else if (className.includes('right')) {
    newWidth = currentValues.width + offset.x
  }

  // Ensure that width and height don't drop below a minimum value
  const minValue = 50 // Example minimum value, replace with your config if needed
  newWidth = Math.max(newWidth, minValue)
  newHeight = Math.max(newHeight, minValue)

  return {
    width: newWidth,
    height: newHeight,
    top: newTop,
    left: newLeft,
  }
}
const HitPoint: React.FC<IHitPoint> = React.memo(
  ({ className = '', onScale, currentValues, onScaleEnd }) => {
    const dragDropManager = useDragDropManager()
    const monitor = dragDropManager.getMonitor()
    const [offset, setOffset] = useState<{ x: number; y: number } | null>(null)

    const [{ isDragging }, elementDrag, preview] = useDrag(
      () => ({
        type: CANVAS_ITEM_TYPE.HITPOINT,
        end() {
          document.dispatchEvent(
            new CustomEvent('element-scale', { detail: false }),
          )
        },
        collect: (monitor) => ({ isDragging: monitor.isDragging() }),
      }),
      [className, currentValues, offset],
    )

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true })
    }, [])

    useEffect(() => {
      monitor.subscribeToOffsetChange(() => {
        if (
          monitor.getClientOffset()?.x &&
          monitor.getInitialSourceClientOffset()?.x &&
          monitor.getClientOffset()?.y &&
          monitor.getInitialSourceClientOffset()?.y
        ) {
          const diff = {
            x:
              (monitor.getClientOffset()?.x || 0) -
              (monitor.getInitialSourceClientOffset()?.x || 0),
            y:
              (monitor.getClientOffset()?.y || 0) -
              (monitor.getInitialSourceClientOffset()?.y || 0),
          }
          setOffset(diff)
        }
      })
    }, [monitor])

    useEffect(() => {
      if (isDragging && offset) {
        if (currentValues) {
          onScale?.({
            values: calculateValues(currentValues, offset, className),
            isCorner: className.includes('corner'), // Pass isCorner info
          })
        }
      }
    }, [isDragging, currentValues, offset, className])

    const handleMouseDown = useCallback(() => {
      document.dispatchEvent(new CustomEvent('element-scale', { detail: true }))
    }, [])

    const handleMouseUp = useCallback(() => {
      if (offset) {
        if (currentValues) {
          onScaleEnd?.({
            values: calculateValues(currentValues, offset, className),
            isCorner: className.includes('corner'), // Pass isCorner info
          })
        }
      }
    }, [currentValues, offset, className])

    return (
      <div
        ref={elementDrag}
        css={hitPointsStyles}
        className={className}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />
    )
  },
)
export const HitPoints: React.FC<IHitPoints> = React.memo(
  ({ onScale, onScaleEnd, currentValues }) => {
    const points = [
      'corner top left',
      'corner top right',
      'corner bottom right',
      'corner bottom left',
      'top',
      'right',
      'bottom',
      'left',
    ]

    return points.map((className) => (
      <HitPoint
        key={className}
        className={className}
        onScale={onScale}
        onScaleEnd={onScaleEnd}
        currentValues={currentValues}
      />
    ))
  },
)
