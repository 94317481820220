export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_24_183)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_24_183"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_24_225)"><path d="M960 0H968V1080H960V0Z" fill="$color1"/><rect x="968" width="952" height="1080" fill="$color2" fill-opacity="0.25"/><rect width="960" height="1080" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_24_225"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H1920V1080H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_24_213)"><path d="M960 0H968V1080H960V0Z" fill="$color1"/><rect x="968" width="952" height="1080" fill="$color2" fill-opacity="0.25"/><rect width="960" height="1080" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_24_213"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H0V1080H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1848 72H72V1008H1848V72ZM64 64V1016H1856V64H64Z" fill="$color2"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_24_229)"><rect width="1920" height="457" transform="matrix(-1 0 0 1 1920 623)" fill="$color2" fill-opacity="0.25"/><rect width="1920" height="623" transform="matrix(-1 0 0 1 1920 0)" fill="url(#decor-bg)"/><path d="M1920 615H0V623H1920V615Z" fill="$color1"/></g><defs><clipPath id="clip0_24_229"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V635H1920V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
