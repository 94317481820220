import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { CREATECARD_TYPE, ICreateCard } from './types'

type ICreateCardType = Pick<ICreateCard, 'type'>

export const wrapperStyles =
  ({ type }: ICreateCardType) =>
  (theme: ITheme) => css`
    width: ${theme.dimensions.columnWidth.DEFAULT}px;
    height: ${theme.dimensions.cardHeight.DEFAULT}px;
    box-shadow: ${theme.boxShadow[1]};
    border-radius: ${theme.borderRadius[4]};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;

    background: ${type === CREATECARD_TYPE.GRADIENT
      ? theme.gradient.DEFAULT
      : theme.colors.white.DEFAULT};

    flex-direction: ${type === CREATECARD_TYPE.DEFAULT ? `column` : `row`};

    gap: ${type === CREATECARD_TYPE.DEFAULT
      ? theme.spacingV2.large
      : theme.spacingV2.medium};

    ${type === CREATECARD_TYPE.DEFAULT &&
    `
			border-width: 2px;
			border-style: solid;
			border-color: ${theme.colors.white.DEFAULT};
			transition:
				box-shadow 0.3s,
				border-color 0.3s;

			&:hover {
				box-shadow: ${theme.boxShadow[2]};
				border-color: ${theme.colors.primary.DEFAULT};
			}`}

    @media (max-width: ${theme.breakpointsV2.tablet - 1}px) {
      height: ${theme.dimensions.cardHeight.small}px;
      flex-direction: row;
    }
  `

export const imageAreaStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;

  @media (max-width: ${theme.breakpointsV2.tablet - 1}px) {
    height: 24px;
    width: 24px;
  }
`

export const imageStyles = (theme: ITheme) => css`
  height: 48px;
  width: 48px;

  @media (max-width: ${theme.breakpointsV2.tablet - 1}px) {
    height: 24px;
    width: 24px;
  }
`

export const textStyles =
  ({ type }: ICreateCardType) =>
  (theme: ITheme) => css`
    color: ${type === CREATECARD_TYPE.DEFAULT
      ? theme.colors.primary.DEFAULT
      : theme.colors.white.DEFAULT};
    font-weight: ${theme.fontWeight.bold};

    font-size: ${type === CREATECARD_TYPE.DEFAULT
      ? theme.fontSize.base
      : theme.fontSize.large};

    @media (max-width: ${theme.breakpointsV2.tablet - 1}px) {
      font-size: ${theme.fontSize.base};
    }
  `
