import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const colorPickerStyles = ({ colors }: ITheme) => css`
  background: ${colors.dark.DEFAULT};
`

export const headerStyles = ({ gap, colors }: ITheme) => css`
  padding: ${gap.xsmall}px ${gap.medium}px 0 ${gap.medium}px;
  display: flex;

  .dropdown {
    width: 120px;
  }

  .title {
    height: 45px;
    display: flex;
    align-items: center;
    color: ${colors.white.DEFAULT};
  }

  .close {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

export const colorfulStyles = css`
  .react-colorful {
    width: 250px;
    height: auto;

    &__saturation {
      height: 160px;
      border-radius: 0;
    }
    &__hue {
      margin: 18px;
      height: 12px;
      border-radius: 6px;
    }
  }
`

export const colorExportStyles = ({ borderRadius, colors, gap }: ITheme) => css`
  display: flex;
  gap: ${gap.small}px;
  padding: 0 ${gap.medium}px ${gap.medium}px;

  input {
    background: ${colors.dark2.DEFAULT};
    border-radius: ${borderRadius[4]};
    height: 32px;
    border: none;
    outline: none;
    color: ${colors.white.DEFAULT};
    text-align: center;
  }

  .gradient-rotate {
    width: 32px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .hex-code {
    flex-grow: 1;
    width: 0;
  }

  .color-add-to-swatch {
    width: 32px;
    height: 32px;
    background: ${colors.dark2.DEFAULT};
    border-radius: ${borderRadius[4]};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .color-display {
    width: 32px;
    height: 32px;
    border-radius: ${borderRadius[4]};
    border: 1px solid ${colors.white.DEFAULT};
  }
`
