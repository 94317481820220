import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ISlideCard } from './types'
import { makeMuiStyles } from 'src/theme/muiTheme'
import { alpha } from '@mui/material/styles'

type ISlideCardStyles = Pick<ISlideCard, 'isActive'> & {
  isDragging: boolean
  isOver?: boolean
}

export const slideCardStyles =
  ({ isActive, isDragging, isOver }: ISlideCardStyles) =>
  ({ colors, borderRadius }: ITheme) => css`
    background: ${colors.black[10]};
    position: relative;
    outline: 1px solid ${colors.elements.DEFAULT};
    height: 136px;
    width: 240px;
    border-radius: ${borderRadius[4]};
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    ${isDragging &&
    `
		outline: none;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background: ${colors.elements.DEFAULT};
			}
		`}

    ${isActive
      ? `outline: 3px solid ${colors.primary.DEFAULT};`
      : `&:hover {
					outline: 3px solid ${colors.primary[60]};
				}`}

    ${isOver && `outline: 3px solid ${colors.secondary.DEFAULT};`}
  `

export const draggableAreaStyles = css`
  width: 100%;
  height: 100%;
`
export const useStyles = makeMuiStyles<ISlideCardStyles>()((theme, props) => {
  const isActiveProps = props.isActive
    ? {
        'outline': `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
          'div[data-label="slide-menu-v3"]': {
            display: 'block',
          },
        },
      }
    : {
        '&:hover': {
          'outline': `1px solid ${alpha(theme.palette.primary.main, 0.6)}`,
          'div[data-label="slide-menu-v3"]': {
            display: 'block',
          },
        },
      }

  const isOverProps = props.isOver
    ? { outline: `1px solid ${theme.palette.secondary.main}` }
    : {}

  return {
    root: {
      'background': `${theme.palette.background.default}`,
      'position': 'relative',
      'outline': props.isDragging
        ? `none`
        : `2px solid ${theme.palette.grey[200]}`,
      'height': '90px',
      'width': '160px',
      'borderRadius': theme.spacing(2),
      'overflow': 'hidden',
      'flexShrink': 0,
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',
      'cursor': 'pointer',
      'boxSizing': 'border-box',
      'div[data-label="slide-menu-v3"]': {
        display: 'none',
      },
      ':before': props.isDragging
        ? {
            content: `""`,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            background: theme.palette.grey[100],
          }
        : undefined,
      ...isActiveProps,
      ...isOverProps,
    },
    slideNumber: {
      fontSize: '12px',
      cursor: 'default',
      pointerEvents: 'none',
    },
  }
})

export const useContextMenuStyles = makeMuiStyles()((theme) => ({
  root: {
    'position': 'absolute',
    'top': '8px',
    'right': '8px',
    'backgroundColor': theme.palette.grey[800],
    'width': '30px',
    'height': '16px',
    'color': 'white',
    ':hover': {
      backgroundColor: theme.palette.grey[800],
      boxShadow: theme.shadows[3],
      color: 'white',
    },
    ':active': {
      backgroundColor: theme.palette.grey[800],
      boxShadow: theme.shadows[3],
      color: 'white',
    },
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: 400,
  },
  dangerMenuItem: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.error.main,
  },
  paper: {
    borderRadius: theme.spacing(1),
  },
}))
