import React, { useMemo } from 'react'

import { INumberSelect } from './types'
import { buttonStyles, wrapperStyles } from './styles'
import { Icon, icons } from 'src/lib/icon'
import { useTheme } from 'src/theme'

export const NumberSelect: React.FC<INumberSelect> = React.memo(
  ({
    value = 0,
    name,
    min = 0,
    max = Infinity,
    onChange,
    className,
    dataAttr,
  }) => {
    const { colors } = useTheme()

    const isMin = useMemo(() => value === min, [value, min])
    const isMax = useMemo(() => value === max, [value, max])

    const handleOnClick = (type: 'inc' | 'dec') => {
      const newVal = value + (type === 'inc' ? 1 : -1)
      const clamp = newVal < min ? min : newVal > max ? max : newVal
      onChange?.(clamp)
    }

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div
          css={buttonStyles({
            isFixedWidth: true,
            disabled: isMin,
          })}
          onClick={() => handleOnClick('dec')}
        >
          <div className="minus"></div>
        </div>
        <div css={buttonStyles({ isFixedWidth: false })}>
          <span className="button-text">
            {value}
            {name && ` ${name}`}
          </span>
        </div>
        <div
          css={buttonStyles({
            isFixedWidth: true,
            disabled: isMax,
          })}
          onClick={() => handleOnClick('inc')}
        >
          <Icon icon={icons.plus} color={colors.primary.DEFAULT} size={18} />
        </div>
      </div>
    )
  },
)
