import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { IContextMenu } from './types'
import {
  contextMenuStyles,
  promptModalWrapperStyles,
  promptModalTitleStyles,
  promptModalFooterStyles,
} from './styles'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  Button,
  IMenuItem,
  INPUT_SIZE,
  Icon,
  Input,
  Menu,
  Modal,
  icons,
} from 'src/lib'
import { useTheme } from 'src/theme'
import {
  SAVE_STATE,
  componentCreate,
  componentOrder,
  componentsDelete,
  componentsUpdate,
  removeClickCreateList,
  setAllowDeleteShortcut,
  setSaveState,
  toggleSelectedComponents,
} from 'src/store'
import { useDispatch } from 'react-redux'
import { ComponentSchema } from 'src/types/api/responseObjects'
import { AiTextEditTypes, ComponentTypes } from 'src/types/api/enums'
import {
  VALIDATION_RULE_TYPES,
  useAiApi,
  useLanguage,
  useNotification,
  useSelectedComponents,
} from 'src/hooks'
import { duplicateComponentData } from './helpers'
import {
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { UpgradeModalManager } from 'src/components/upgrade-modals/UpgradeModalManager'
import { ACTION_CLICKS } from 'src/plugins/google/consts'
import { ErrorDefinitions } from 'src/types/api/ErrorDefinitions'

export const ContextMenu = React.forwardRef<HTMLDivElement, IContextMenu>(
  ({ pos, data, onClose, className, dataAttr }, ref) => {
    const dispatch = useDispatch()
    const { colors } = useTheme()
    const { t } = useLanguage()
    const { editText } = useAiApi()
    const { success } = useNotification()

    const [otherPrompt, setOtherPrompt] = useState('')

    const { components: selectedComponents } = useSelectedComponents()

    useEffect(() => {
      const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault()
          if (otherPrompt.length > 2) {
            editWithAiHandler({
              type: AiTextEditTypes.OTHER,
              isCallback: false,
            })
          }
        }
      }
      document.addEventListener('keydown', keyDownHandler)
      return () => {
        document.removeEventListener('keydown', keyDownHandler)
      }
    }, [otherPrompt])

    const pasteComponent = useCallback((componentData: ComponentSchema) => {
      onClose?.()
      if (!componentData) {
        return
      }
      const duplicatedComponent = duplicateComponentData(componentData)
      dispatch(componentCreate([duplicatedComponent]))
    }, [])

    const [selectedType, setSelectedType] = useState<null | AiTextEditTypes>(
      null,
    )
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
    const editWithAiHandler = useCallback(
      async ({
        type,
        isCallback,
      }: {
        type: AiTextEditTypes
        isCallback: boolean
      }) => {
        if (type === AiTextEditTypes.OTHER && !otherPrompt.length) {
          dispatch(setAllowDeleteShortcut(false))
          setIsPromptModalOpen(true)
          return
        }
        setIsPromptModalOpen(false)
        onClose?.()

        const editedText = await editText({
          text: (data.data as ComponentTextDataSchema).text,
          textEditType: type,
          instruction: otherPrompt,
        })

        setOtherPrompt('')
        if (isCallback) {
          setIsUpgradeModalOpen(false)
          if (editedText === ErrorDefinitions.INSUFFICIENT_AI_CREDIT) {
            return
          }
        }
        if (
          editedText === ErrorDefinitions.INSUFFICIENT_AI_CREDIT &&
          !isCallback
        ) {
          setSelectedType(type)
          setIsUpgradeModalOpen(true)
          return
        }
        const editedTextWithoutTags = editedText
          ? editedText.replace(/<p>/g, '').replace(/<\/p>/g, '')
          : editedText

        const partialData: DeepPartial<ComponentTextDataSchema> = {
          text: '<p>' + editedTextWithoutTags + '</p>',
        }
        if (
          editedTextWithoutTags ===
          (data.data as ComponentTextDataSchema).text
            ?.replace(/<p>/g, '')
            ?.replace(/<\/p>/g, '')
        ) {
          success('edit.canvas.context_menu.edit_with_ai.nothing_improved')
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTextDataSchema>({
            components: [data],
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [data, otherPrompt],
    )

    const handleInputChange = useCallback(
      (val: string) => setOtherPrompt(val),
      [],
    )
    const [isPromptModalOpen, setIsPromptModalOpen] = useState(false)
    useEffect(() => {
      if (!isPromptModalOpen) {
        dispatch(setAllowDeleteShortcut(true))
      }
    }, [isPromptModalOpen])
    const promptModal = useMemo(
      () => (
        <Modal
          isOpen={isPromptModalOpen}
          onClose={() => setIsPromptModalOpen(false)}
        >
          <div css={promptModalWrapperStyles}>
            <div css={promptModalTitleStyles}>
              {t('edit.canvas.context_menu.edit_with_ai.title')}

              <Icon
                className="close-button"
                icon={icons.close}
                color={colors.outline[2]}
                size={16}
                onClick={() => setIsPromptModalOpen(false)}
              />
            </div>

            <Input
              placeholder={t(
                'edit.canvas.context_menu.edit_with_ai.prompt_placeholder',
              )}
              value={otherPrompt}
              size={INPUT_SIZE.SMALL}
              onChange={handleInputChange}
              validation={{
                [VALIDATION_RULE_TYPES.MIN]: {
                  value: 3,
                  text: t(
                    'edit.canvas.context_menu.edit_with_ai.input_error_message',
                  ),
                },
              }}
            />
            <div css={promptModalFooterStyles}>
              <Button
                text={t('edit.canvas.context_menu.edit_with_ai.generate')}
                theme={BUTTON_THEME.GRADIENT}
                onClick={() =>
                  editWithAiHandler({
                    type: AiTextEditTypes.OTHER,
                    isCallback: false,
                  })
                }
                disabled={otherPrompt.length < 3}
                icon={icons.sparkling}
                size={BUTTON_SIZE.XSMALL}
              />
            </div>
          </div>
        </Modal>
      ),
      [isPromptModalOpen, setIsPromptModalOpen, otherPrompt],
    )

    const editWithAiItems = useMemo<IMenuItem[]>(
      () => [
        {
          label: t('edit.canvas.context_menu.edit_with_ai.summarize'),
          onClick: () => {
            editWithAiHandler({
              type: AiTextEditTypes.SHORTEN,
              isCallback: false,
            })
          },
        },
        {
          label: t('edit.canvas.context_menu.edit_with_ai.fix'),
          onClick: () => {
            editWithAiHandler({ type: AiTextEditTypes.FIX, isCallback: false })
          },
        },
        {
          label: t('edit.canvas.context_menu.edit_with_ai.rewrite'),
          onClick: () => {
            editWithAiHandler({
              type: AiTextEditTypes.REWRITE,
              isCallback: false,
            })
          },
        },
        {
          label: t('edit.canvas.context_menu.edit_with_ai.extend'),
          onClick: () => {
            editWithAiHandler({
              type: AiTextEditTypes.EXTEND,
              isCallback: false,
            })
          },
        },
        {
          label: t('edit.canvas.context_menu.edit_with_ai.other'),
          onClick: () => {
            editWithAiHandler({
              type: AiTextEditTypes.OTHER,
              isCallback: false,
            })
          },
        },
      ],
      [data, otherPrompt],
    )

    const menuItems = useMemo<IMenuItem[]>(
      () => [
        {
          label: t('edit.canvas.context_menu.bring_forward'),
          onClick: () => {
            selectedComponents.components?.forEach((data) => {
              dispatch(componentOrder({ data, to: 'forward' }))
            })
            dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
          },
          hidden: true,
        },
        {
          label: t('edit.canvas.context_menu.send_backward'),
          onClick: () => {
            selectedComponents.components?.forEach((data) => {
              dispatch(componentOrder({ data, to: 'backward' }))
            })
            dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
          },
          hidden: true,
        },
        {
          label: t('edit.canvas.context_menu.bring_to_front'),
          onClick: () => {
            selectedComponents.components?.forEach((data) => {
              dispatch(componentOrder({ data, to: 'front' }))
            })
            dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
          },
        },
        {
          label: t('edit.canvas.context_menu.send_to_back'),
          onClick: () => {
            selectedComponents.components?.forEach((data) => {
              dispatch(componentOrder({ data, to: 'back' }))
            })
            dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
          },
        },
        {
          label: t('edit.canvas.context_menu.send_to_background'),
          onClick: () => {
            selectedComponents.components?.forEach((data) => {
              dispatch(componentOrder({ data, to: 'mask' }))
            })
            dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
          },
        },
        {
          label: t('common.actions.duplicate'),
          onClick: () => {
            selectedComponents.components?.forEach((data) => {
              pasteComponent(data)
            })
            dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
            onClose?.()
          },
          hidden: data.type === ComponentTypes.FORM,
        },
        {
          label: t('edit.canvas.context_menu.edit_with_ai'),
          items: editWithAiItems,
          hidden: data.type !== ComponentTypes.TEXT,
        },
        {
          label: t('common.actions.delete'),
          color: colors.error.DEFAULT,
          onClick: (e) => {
            e.preventDefault()
            e.stopPropagation()

            selectedComponents.components?.forEach((data) => {
              dispatch(
                toggleSelectedComponents({
                  id: data.id,
                  tempId: data.tempId || undefined,
                }),
              )
              data.tempId && dispatch(removeClickCreateList([data.tempId]))
              dispatch(componentsDelete([data]))
            })

            dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
            onClose?.()
          },
        },
      ],
      [data, editWithAiItems, selectedComponents],
    )

    const contextMenuRenderer = useMemo(() => {
      if (isPromptModalOpen) {
        return promptModal
      }
      if (!isPromptModalOpen && isUpgradeModalOpen) {
        return (
          <UpgradeModalManager
            isOpen={isUpgradeModalOpen}
            callback={() =>
              editWithAiHandler({ type: selectedType!, isCallback: true })
            }
            refillMode={true}
            context={ACTION_CLICKS.AI_CLICK}
            onClose={() => setIsUpgradeModalOpen(false)}
          />
        )
      }
      if (!isPromptModalOpen && !isUpgradeModalOpen && pos) {
        return createPortal(
          <div
            ref={ref}
            css={contextMenuStyles}
            style={{
              left: `${pos.x}px`,
              top: `${pos.y}px`,
            }}
            className={className}
            {...dataAttr}
          >
            <Menu shadow items={menuItems} />
          </div>,
          document.body,
        )
      }
    }, [
      pos,
      ref,
      menuItems,
      className,
      dataAttr,
      isUpgradeModalOpen,
      selectedType,
      isPromptModalOpen,
      promptModal,
    ])

    return contextMenuRenderer
  },
)

ContextMenu.displayName = 'ContextMenu'
