// Warning: This code is like a maze. It has twists, turns, and some hidden traps.
// Proceed with caution and bring snacks; you might be here a while!
// If you get lost, just remember: persistence is key, and coffee helps. Good luck!

import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { IOnSclaeProps, IScaleValues, ISelectionLayer } from './types'
import { selectionLayerStyles } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  componentsUpdate,
  RootState,
  SAVE_STATE,
  setSaveState,
} from 'src/store'
import { useActiveSlideData } from 'src/hooks'
import {
  CanvasRectEvent,
  CanvasRectEventName,
  DragRectEvent,
  DragRectEventDetail,
  DragRectEventName,
} from 'src/events'
import { HitPoints } from './HitPoints'
import { ComponentServices } from 'src/services'
import {
  ComponentListDataSchema,
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ComponentSchema } from 'src/types/api/responseObjects'
import { ComponentTypes } from 'src/types/api/enums'

export const SelectionLayer: React.FC<ISelectionLayer> = React.memo(
  ({ className }) => {
    const dispatch = useDispatch()
    const layerRef = useRef<HTMLDivElement>(null)
    const [canvasRect, setCanvasRect] = useState<DOMRect>()
    const [canvasScale, setCanvasScale] = useState(1)
    const [box, setBox] = useState<CSSProperties>()
    const [currentValues, setCurrentValues] = useState<IScaleValues>()
    const [initialValues, setInitialValues] = useState<IScaleValues>()
    const [dragPos, setDragPos] = useState<DragRectEventDetail>({
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    })

    const activeComponents = useActiveSlideData()
    const { selectedComponents, componentsDragging } = useSelector(
      ({ canvas, edit }: RootState) => ({
        selectedComponents: canvas.selectedComponents,
        componentsData: edit.activeSlideData,
        componentsDragging: canvas.componentsDragging,
      }),
    )

    const calculateScaledComponentPositions = useCallback(
      (values: IScaleValues) => {
        if (initialValues) {
          const selectedIDs = selectedComponents.map(({ id }) => id)
          const selectedTempIDs = selectedComponents.map(({ tempId }) => tempId)

          const selectedComps = activeComponents?.slideDataComponents
            .filter(({ component }) =>
              component.id
                ? selectedIDs.includes(component.id)
                : selectedTempIDs.includes(component.tempId || undefined),
            )
            .map(({ component }) => component)

          if (selectedComps?.length) {
            const boundingBox = calculateBoundingBox(selectedComps)

            const leftScale = (values.left - initialValues.left) / canvasScale
            const topScale = (values.top - initialValues.top) / canvasScale
            const widthScaleRatio = values.width / initialValues.width
            const heightScaleRatio = values.height / initialValues.height

            return selectedComps
              .map((component) => {
                const pos = component.positions
                if (!pos) return null

                const newLeft =
                  boundingBox.left +
                  leftScale +
                  (pos.x - boundingBox.left) * widthScaleRatio
                const newTop =
                  boundingBox.top +
                  topScale +
                  (pos.y - boundingBox.top) * heightScaleRatio
                const newWidth = (pos.width || 0) * widthScaleRatio
                const newHeight = (pos.height || 0) * heightScaleRatio

                return {
                  componentId: component.id || component.tempId,
                  updatedComponent: {
                    positions: {
                      x: newLeft,
                      y: newTop,
                      width: newWidth,
                      height: newHeight,
                    },
                    ...calculateNewFontSize(
                      component,
                      newWidth,
                      pos.width || 0,
                    ),
                  },
                }
              })
              .filter(Boolean)
          }
        }
        return []
      },
      [initialValues, selectedComponents, activeComponents, canvasScale],
    )

    const calculateNewFontSize = (
      component: ComponentSchema,
      newWidth: number,
      originalWidth: number | undefined,
    ) => {
      let newFontSize, newFontBodySize
      const componentIsList = component.type === ComponentTypes.LIST

      if ((component.data as ComponentTextDataSchema)?.style?.font?.size) {
        const currentFontSize = parseFloat(
          (component.data as ComponentTextDataSchema).style.font.size || '0em',
        )
        newFontSize = `${(currentFontSize * newWidth) / (originalWidth || 1)}em`
      }

      if (componentIsList) {
        const currentFontBodySize = parseFloat(
          (component.data as ComponentListDataSchema)?.style?.fontBody?.size ||
            '0',
        )
        newFontBodySize = `${
          (currentFontBodySize * newWidth) / (originalWidth || 1)
        }em`
      }

      return newFontSize || newFontBodySize
        ? {
            data: {
              style: {
                ...(newFontSize ? { font: { size: newFontSize } } : {}),
                ...(newFontBodySize
                  ? { fontBody: { size: newFontBodySize } }
                  : {}),
              },
            },
          }
        : {}
    }

    const calculateBoundingBox = (components: ComponentSchema[]) => {
      return components.reduce(
        (acc, component) => {
          const pos = component.positions
          if (!pos) return acc

          const componentRight = pos.x + (pos.width || 0)
          const componentBottom = pos.y + (pos.height || 0)

          return {
            left: Math.min(acc.left, pos.x),
            top: Math.min(acc.top, pos.y),
            right: Math.max(acc.right, componentRight),
            bottom: Math.max(acc.bottom, componentBottom),
          }
        },
        {
          left: Number.POSITIVE_INFINITY,
          top: Number.POSITIVE_INFINITY,
          right: Number.NEGATIVE_INFINITY,
          bottom: Number.NEGATIVE_INFINITY,
        },
      )
    }

    useEffect(() => {
      const handleCanvasRectChange = (e: CanvasRectEvent) => {
        setCanvasRect(e.detail.rect)
        setCanvasScale(e.detail.scale)
      }

      document.addEventListener(
        CanvasRectEventName,
        handleCanvasRectChange as EventListener,
      )

      return () => {
        document.removeEventListener(
          CanvasRectEventName,
          handleCanvasRectChange as EventListener,
        )
      }
    }, [])

    useEffect(() => {
      const handleDragPositionChange = (e: DragRectEvent) => {
        setDragPos(e.detail)
      }

      document.addEventListener(
        DragRectEventName,
        handleDragPositionChange as EventListener,
      )

      return () => {
        document.removeEventListener(
          DragRectEventName,
          handleDragPositionChange as EventListener,
        )
      }
    }, [])

    const layerRect = useMemo(
      () => layerRef.current?.getBoundingClientRect(),
      [layerRef.current?.getBoundingClientRect()],
    )

    const positionCorrection = useMemo(
      () => ({
        left: (layerRect?.x || 0) - (canvasRect?.x || 0),
        top: (layerRect?.y || 0) - (canvasRect?.y || 0),
      }),
      [layerRect, canvasRect],
    )

    useEffect(() => {
      const selectedIDs = selectedComponents.map(({ id }) => id)
      const selectedTempIDs = selectedComponents.map(({ tempId }) => tempId)

      const positions = activeComponents?.slideDataComponents
        .filter(({ component }) =>
          component.id
            ? selectedIDs.includes(component.id)
            : selectedTempIDs.includes(component.tempId || undefined),
        )
        .map(({ component }) => component.positions)

      if (componentsDragging) {
        const topSide =
          (dragPos.top - (canvasRect?.y || 0) - positionCorrection.top) /
          canvasScale
        const leftSide =
          (dragPos.left - (canvasRect?.x || 0) - positionCorrection.left) /
          canvasScale
        const height = dragPos.height
        const width = dragPos.width

        setBox({
          width: `${(width || 0) * canvasScale}px`,
          height: `${(height || 0) * canvasScale}px`,
          left: `${(leftSide || 0) * canvasScale}px`,
          top: `${(topSide || 0) * canvasScale}px`,
        })
      } else {
        const topSide = Math.min(...(positions || []).map(({ y }) => y || 0))
        const leftSide = Math.min(...(positions || []).map(({ x }) => x || 0))

        const height =
          Math.max(
            ...(positions || []).map(
              ({ y, height }) => (y || 0) + (height || 0),
            ),
          ) - topSide

        const width =
          Math.max(
            ...(positions || []).map(({ x, width }) => (x || 0) + (width || 0)),
          ) - leftSide

        setInitialValues({
          width: width * canvasScale,
          height: height * canvasScale,
          left: leftSide * canvasScale - positionCorrection.left,
          top: topSide * canvasScale - positionCorrection.top,
        })

        setBox({
          width: `${(width || 0) * canvasScale}px`,
          height: `${(height || 0) * canvasScale}px`,
          left: `${(leftSide || 0) * canvasScale - positionCorrection.left}px`,
          top: `${(topSide || 0) * canvasScale - positionCorrection.top}px`,
        })
      }
    }, [
      selectedComponents,
      activeComponents,
      canvasScale,
      componentsDragging,
      dragPos,
      canvasRect,
    ])

    useEffect(() => {
      setCurrentValues(initialValues)
    }, [initialValues])

    const [isScaling, setIsScaling] = useState(false)

    useEffect(() => {
      const handleScaleChange = (e: any) => {
        setIsScaling(e.detail)

        if (!e.detail && currentValues) {
          onScaleEnd({ values: currentValues, isCorner: false })
        }
      }

      document.addEventListener('element-scale', handleScaleChange)

      return () => {
        document.removeEventListener('element-scale', handleScaleChange)
      }
    }, [initialValues, currentValues])

    const handleComponentScaling = useCallback(
      (updatedComponents: any, isCorner?: boolean) => {
        updatedComponents.forEach(({ componentId, updatedComponent }: any) => {
          if (
            isCorner &&
            updatedComponent.data &&
            updatedComponent.data.style
          ) {
            const component = activeComponents?.slideDataComponents.find(
              ({ component }) =>
                component.id === componentId ||
                component.tempId === componentId,
            )?.component

            if (component) {
              const componentIsList = component.type === ComponentTypes.LIST
              const pos = component.positions

              if (
                (component.data as ComponentTextDataSchema)?.style?.font?.size
              ) {
                const currentFontSize = parseFloat(
                  (component.data as ComponentTextDataSchema).style.font.size ||
                    '0em',
                )

                const newFontSize =
                  (currentFontSize * updatedComponent.positions.width) /
                    (pos.width || 1) +
                  'em'
                updatedComponent.data.style.font.size = newFontSize
              }

              if (componentIsList) {
                const currentFontBodySize = parseFloat(
                  (component.data as ComponentListDataSchema)?.style?.fontBody
                    ?.size || '0',
                )

                const newFontBodySize =
                  (currentFontBodySize * updatedComponent.positions.width) /
                    (pos.width || 1) +
                  'em'
                updatedComponent.data.style.fontBody.size = newFontBodySize
              }
            }
          }

          const scaleEvent = new CustomEvent('element-scale-update', {
            detail: { componentId, updatedComponent },
          })

          document.dispatchEvent(scaleEvent)
        })
      },
      [activeComponents],
    )

    const onScale = useCallback(
      ({ values, isCorner }: IOnSclaeProps) => {
        setCurrentValues(values)

        const updatedComponents = calculateScaledComponentPositions(values)
        handleComponentScaling(updatedComponents, isCorner)

        document.dispatchEvent(
          new CustomEvent('element-scaling', { detail: true }),
        )
      },
      [calculateScaledComponentPositions, handleComponentScaling],
    )

    const onScaleEnd = useCallback(
      ({ values, isCorner }: IOnSclaeProps) => {
        if (initialValues && values) {
          const updatedComponents = calculateScaledComponentPositions(values)
          handleComponentScaling(updatedComponents, isCorner)

          updatedComponents.forEach(
            ({ componentId, updatedComponent }: any) => {
              const selectedIDs = selectedComponents.map(({ id }) => id)
              const selectedTempIDs = selectedComponents.map(
                ({ tempId }) => tempId,
              )

              const selectedComps = activeComponents?.slideDataComponents
                .filter(({ component }) =>
                  component.id
                    ? selectedIDs.includes(component.id)
                    : selectedTempIDs.includes(component.tempId || undefined),
                )
                .map(({ component }) => component)

              if (selectedComps) {
                const componentToUpdate = selectedComps.find(
                  (comp) =>
                    comp.id === componentId || comp.tempId === componentId,
                )

                if (componentToUpdate) {
                  const updatedData =
                    ComponentServices.updateComponent<UpdateComponentSchema>({
                      components: [componentToUpdate],
                      partialUpdate: updatedComponent,
                    })

                  dispatch(componentsUpdate({ components: updatedData }))
                }
              }
            },
          )

          dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
          document.dispatchEvent(
            new CustomEvent('element-scaling', { detail: false }),
          )
        }
      },
      [
        calculateScaledComponentPositions,
        handleComponentScaling,
        initialValues,
        dispatch,
      ],
    )

    return (
      <div ref={layerRef} css={selectionLayerStyles} className={className}>
        {selectedComponents.length ? (
          <div
            style={{
              border: `2px solid #4f61ff`,
              position: 'absolute',
              width: isScaling ? `${currentValues?.width}px` : box?.width,
              height: isScaling ? `${currentValues?.height}px` : box?.height,
              left: isScaling ? `${currentValues?.left}px` : box?.left,
              top: isScaling ? `${currentValues?.top}px` : box?.top,
            }}
          >
            <HitPoints currentValues={initialValues} onScale={onScale} />
          </div>
        ) : null}
      </div>
    )
  },
)

SelectionLayer.displayName = 'SelectionLayer'
