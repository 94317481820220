import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const suspendedWrapper = (theme: ITheme) => css`
  height: 100vh;
  max-height: calc(100vh - ${theme.dimensions.headerHeight.DEFAULT}px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.background.DEFAULT};
`

export const suspendedStyles = (theme: ITheme) => css`
  width: ${theme.dimensions.suspendedWidth.DEFAULT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${theme.colors.error[90]};
  row-gap: ${theme.gap['3xlarge']}px;

  span {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSize.large};
    text-align: center;
    line-height: 25px;
  }
`

export const wrapperStyles = css`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

export const headerLayoutStyle = (theme: ITheme) => css`
  position: sticky;
  top: 0;
  z-index: ${theme.zIndex.header};
`
