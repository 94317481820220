import { css } from '@emotion/react'

interface IListBulletStyles {
  type: 'number' | 'bullet'
}
export const listBulletStyles = ({ type }: IListBulletStyles) => css`
  & > div {
    width: 1em;
    display: flex;

    ${type === 'number'
      ? `
				height: 1.35em;
				padding-right: 0.2em;
				align-items: flex-end;
				justify-content: flex-end;
			`
      : `
				height: 1.5em;
				align-items: center;
				justify-content: center;
			`}

    & > div {
      font-size: 0.75em;

      & > div {
        width: 0.6em;
        height: 0.6em;
        border-radius: 50%;
      }
    }
  }
`
