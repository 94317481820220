import React from 'react'

import { IMediaDisplay } from './types'
import {
  mediaDisplayContentStyles,
  mediaDisplayOverlayStyles,
  mediaDisplayStyles,
  mediaDisplayNameStyles,
  svgMaskStyles,
} from './styles'
import { Icon } from 'src/lib'
import { useTheme } from 'src/theme'

export const MediaDisplay: React.FC<IMediaDisplay> = React.forwardRef<
  HTMLDivElement,
  IMediaDisplay
>(
  (
    { name, styles, svgUrl, image, icon, onClick, className, dataAttr },
    ref,
  ) => {
    const { colors } = useTheme()

    return (
      <div
        ref={ref}
        css={mediaDisplayStyles}
        style={{ ...styles, backgroundImage: `url(${image})` }}
        onClick={onClick}
        className={className}
        {...dataAttr}
      >
        {svgUrl && (
          <div
            css={svgMaskStyles}
            style={{ mask: `url(${svgUrl}) no-repeat center` }}
          />
        )}
        {image && <div css={mediaDisplayOverlayStyles} />}
        <div css={mediaDisplayContentStyles}>
          <div className="icon-wrapper">
            {icon && (
              <Icon icon={icon} size={36} color={colors.white.DEFAULT} />
            )}
          </div>
          <div css={mediaDisplayNameStyles}>{name}</div>
        </div>
      </div>
    )
  },
)

MediaDisplay.displayName = 'MediaDisplay'
