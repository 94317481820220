import React, { useCallback, useMemo } from 'react'

import { IButton } from './types'
import { buttonStyles } from './styles'
import { ComponentButtonDataSchema } from 'src/types/api/requestObjects'
import { CANVAS_TYPE } from 'src/components/canvas/types'

export const Button: React.FC<IButton> = React.memo(
  ({ data, className, dataAttr, canvasType }) => {
    const componentData: ComponentButtonDataSchema = useMemo(
      () => data.data as ComponentButtonDataSchema,
      [data.data],
    )

    const isPreview = useMemo(
      () => canvasType === CANVAS_TYPE.PREVIEW,
      [canvasType],
    )

    const handleOnClick = useCallback(
      (e: React.MouseEvent) => {
        !isPreview && e.preventDefault()
      },
      [isPreview],
    )

    return (
      <a
        href={componentData.linkUrl}
        onClick={handleOnClick}
        target="_blank"
        rel="noreferrer"
        css={buttonStyles({ isOutlined: componentData.style.isOutlined })}
        className={className}
        {...dataAttr}
        style={{
          fontSize: `${12}em`,
        }}
      >
        {componentData.text}
      </a>
    )
  },
)

Button.displayName = 'Button'
