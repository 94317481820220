import React, { useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { contentAreaStyles, wrapperStyles } from './styles'
import { InitHeader } from 'src/components/init-header'
import { InitFooter } from 'src/components/init-footer'
import { CSSTransition } from 'react-transition-group'
import { useLocation } from 'src/hooks'

export const InitPageLayout: React.FC = React.memo(() => {
  const nodeRef = useRef(null)
  const [inProp, fadeIn] = useState(false)
  const { lastPath } = useLocation()

  useEffect(() => {
    fadeIn(false)

    setTimeout(() => fadeIn(true), 10)
  }, [lastPath])

  return (
    <>
      <InitHeader />
      <div css={wrapperStyles}>
        <CSSTransition nodeRef={nodeRef} in={inProp} timeout={300}>
          <div css={contentAreaStyles} ref={nodeRef}>
            <Outlet />
          </div>
        </CSSTransition>
        <InitFooter />
      </div>
    </>
  )
})
