import React, { useState, useCallback, useMemo } from 'react'
import { VoiceRecording } from 'src/components/voice-recording'
import { ICanvasSideBar } from './types'
import { canvasSideBarStyles } from './styles'
import { Icon, TOOLTIP_PLACE, TOOLTIP_THEME, Tooltip, icons } from 'src/lib'
import { useTheme } from 'src/theme'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  RootState,
  SAVE_STATE,
  historyUndo,
  setAddSlideModal,
  setSaveState,
  slideCreate,
  slideDelete,
  slideSwapColor,
} from 'src/store'
import { useDecksApi, useLanguage } from 'src/hooks'

export const CanvasSideBar: React.FC<ICanvasSideBar> = React.memo(
  ({ pos, scale, className, dataAttr }) => {
    const { t } = useLanguage()
    const { gap, colors } = useTheme()
    const params = useParams()
    const dispatch = useDispatch()
    const { saveChanges } = useDecksApi()
    const [showVoiceRecording, setShowVoiceRecording] = useState(false)
    const { activeSlideId, slides, saveState, slideData, activeSlideDataID } =
      useSelector(({ edit, canvas }: RootState) => ({
        activeSlideId: edit.activeSlideID,
        activeSlideDataID: edit.activeSlideDataID,
        slides: edit.activeDeck.data?.deckData?.data.slides,
        saveState: canvas.saveState,
        slideData: edit.activeSlideData.data,
      }))

    const activeSlideData = useMemo(
      () => slides?.find(({ slideId }) => slideId === activeSlideId),
      [activeSlideId, slides],
    )

    const isSwapped = useMemo(
      () => slideData?.find(({ id }) => id === activeSlideDataID)?.isSwapColor,
      [slideData, activeSlideDataID],
    )

    const svgType = useMemo(
      () => slideData?.find(({ id }) => id === activeSlideDataID)?.svgType,
      [slideData, activeSlideDataID],
    )

    const background = useMemo(
      () => slideData?.find(({ id }) => id === activeSlideDataID)?.background,
      [slideData, activeSlideDataID],
    )

    const onPlayClick = useCallback(async () => {
      if (saveState === SAVE_STATE.NOT_SAVED) {
        await saveChanges()
      }
      window.open(
        `/preview/${params.id}/${params.slide}`,
        '_blank',
        'noopener,noreferrer',
      )
    }, [params, saveState, saveChanges])

    const onMicClick = useCallback(() => {
      setShowVoiceRecording((state) => !state)
    }, [])

    const onCreateClick = useCallback(() => {
      dispatch(setAddSlideModal({ isOpen: true, targetSlide: activeSlideData }))
    }, [activeSlideData])

    const isSwapColor = useMemo(
      () =>
        slideData?.find(({ id }) => id === activeSlideData?.slideDataId)
          ?.isSwapColor,
      [slideData, activeSlideData?.slideDataId],
    )

    const onDuplicateClick = useCallback(() => {
      dispatch(
        slideCreate({
          targetSlide: activeSlideData,
          isDuplicate: true,
          isSwapColor,
          svgType,
          background,
        }),
      )
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }, [activeSlideData, isSwapColor, svgType, background])

    const onUndoClick = useCallback(() => {
      dispatch(historyUndo())
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }, [])

    const onDeleteClick = useCallback(() => {
      if (activeSlideId) {
        dispatch(slideDelete(activeSlideId))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }
    }, [activeSlideId])

    const onSwapClick = useCallback(() => {
      if (activeSlideId && activeSlideDataID) {
        dispatch(
          slideSwapColor({
            slideId: activeSlideId,
            slideDataId: activeSlideDataID,
            state: !isSwapped,
          }),
        )
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }
    }, [activeSlideId, activeSlideDataID, isSwapped])

    const iconSize = useMemo(() => 48 * scale, [scale])

    const gapSize = useMemo(() => gap['3xlarge'] * scale, [scale])

    return (
      <div
        css={canvasSideBarStyles}
        style={{
          left: `${pos.left + gap.medium}px`,
          height: `${pos.height}px`,
          width: `calc(80px * ${scale})`,
          gap: gapSize,
        }}
        className={className}
        {...dataAttr}
      >
        <div className="top" style={{ gap: gapSize }}>
          <Tooltip
            text={t('edit.canvas_sidebar.start_show')}
            theme={TOOLTIP_THEME.DARK}
            width={120}
            height={28}
          >
            <div className="button" onClick={onPlayClick}>
              <Icon icon={icons.play} size={iconSize} />
            </div>
          </Tooltip>
          <Tooltip
            text={t('edit.canvas_sidebar.start_voice')}
            theme={TOOLTIP_THEME.DARK}
            width={88}
            height={28}
          >
            <div className="button">
              <Icon icon={icons.mic} size={iconSize} onClick={onMicClick} />
              {showVoiceRecording && (
                <VoiceRecording onClose={() => setShowVoiceRecording(false)} />
              )}
            </div>
          </Tooltip>
          <Tooltip
            text={t('edit.canvas_sidebar.swap_colors')}
            theme={TOOLTIP_THEME.DARK}
            width={130}
            height={28}
          >
            <div className={`button ${isSwapped && 'swap'}`}>
              <Icon
                icon={icons.paint_palette}
                size={iconSize}
                color={isSwapped ? colors.white.DEFAULT : colors.black.DEFAULT}
                onClick={onSwapClick}
              />
            </div>
          </Tooltip>
        </div>
        <Tooltip
          text={t('common.deck.add_slide')}
          theme={TOOLTIP_THEME.DARK}
          width={88}
          height={28}
        >
          <div className="button" onClick={onCreateClick}>
            <Icon icon={icons.plus} size={iconSize} />
          </div>
        </Tooltip>
        <Tooltip
          text={t('common.deck.duplicate_slide')}
          theme={TOOLTIP_THEME.DARK}
          width={130}
          height={28}
        >
          <div className="button" onClick={onDuplicateClick}>
            <Icon icon={icons.duplicate} size={iconSize} />
          </div>
        </Tooltip>
        <Tooltip
          text={t('common.deck.undo')}
          theme={TOOLTIP_THEME.DARK}
          width={130}
          height={28}
        >
          <div className="button" onClick={onUndoClick}>
            <Icon icon={icons.undo} size={iconSize} />
          </div>
        </Tooltip>
        <div className="blank"></div>
        <Tooltip
          text={t('common.deck.delete_slide')}
          theme={TOOLTIP_THEME.DARK}
          place={TOOLTIP_PLACE.TOP}
          width={130}
          height={28}
        >
          <div className="button" onClick={onDeleteClick}>
            <Icon icon={icons.trash_can} size={iconSize} />
          </div>
        </Tooltip>
      </div>
    )
  },
)

CanvasSideBar.displayName = 'CanvasSideBar'
