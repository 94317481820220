import React from 'react'
import { Icon } from 'src/lib/icon'
import { Loader } from 'src/lib/loader'
import { useIconColor, useLoaderColor } from './hooks'
import { innerStyles, loadingStyles, wrapperStyles } from './styles'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  IButton,
} from './types'

import googleLogo from 'src/assets/images/google.svg'

export const Button: React.FC<IButton> = React.memo(
  ({
    text,
    value,
    size = BUTTON_SIZE.SMALL,
    theme = BUTTON_THEME.PRIMARY,
    type = BUTTON_TYPE.DEFAULT,
    width = BUTTON_WIDTH.DEFAULT,
    icon,
    iconSize = 16,
    isLink = false,
    isLightText = false,
    isRounded = false,
    disabled = false,
    isLoading,
    preventFocus,
    onClick,
    className,
    dataAttr,
  }) => {
    const iconColor = useIconColor({ type, theme, disabled })
    const loaderColor = useLoaderColor({ type, theme, disabled })

    return (
      <button
        onClick={!isLoading ? onClick : undefined}
        css={wrapperStyles({
          type,
          theme,
          text,
          isLink,
          size,
          isRounded,
          width,
          isLightText,
        })}
        className={className}
        disabled={disabled}
        value={value}
        {...dataAttr}
        {...(preventFocus ? { tabIndex: -1 } : {})}
      >
        <Loader
          size={6}
          isFullWidth
          css={loadingStyles({ isLoading })}
          color={loaderColor}
        />
        <div css={innerStyles({ isLoading })}>
          {theme === BUTTON_THEME.GOOGLE && (
            <img src={googleLogo} alt="google" />
          )}
          {icon && <Icon icon={icon} color={iconColor} size={iconSize} />}
          {text && <span className="button-text">{text}</span>}
        </div>
      </button>
    )
  },
)
