import { css, keyframes } from '@emotion/react'
import { ITheme } from 'src/theme'

export const animationBarStyles = ({ colors, gap }: ITheme) => css`
  width: 86%;
  display: flex;
  margin: ${gap['2xlarge']}px ${gap['8xlarge']}px;
  outline: 5px solid ${colors.outline.DEFAULT};
  height: 16px;
  background-color: #fefefe;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

export const progressBarStyles = ({ colors }: ITheme) => css`
  width: 0;
  height: 100%;
  background: linear-gradient(
    270deg,
    ${colors.pink.DEFAULT},
    ${colors.primary.DEFAULT}
  ); /* Simple gradient from blue to purple */
  transition: width 0.06s linear; /* Smooth linear transition for width */
  border-radius: 15px; /* Rounded corners for the bar */
  animation: ${colorTransition} 60s ease infinite; /* Animation for color change */
`

/* Keyframes for gradient color animation */
const colorTransition = keyframes`
  0% {
    background: #b157fe;
  }
  5% {
    background: #4f61ff;
  }
  10% {
    background: #b157fe;
  }
  15% {
    background: #4f61ff;
  }
  20% {
    background: #b157fe;
  }
  25% {
    background: #4f61ff;
  }
  30% {
    background: #b157fe;
  }
  35% {
    background: #4f61ff;
  }
  40% {
    background: #b157fe;
  }
  45% {
    background: #4f61ff;
  }
  50% {
    background: #b157fe;
  }
  55% {
    background: #4f61ff;
  }
  60% {
    background: #b157fe;
  }
  65% {
    background: #4f61ff;
  }
  70% {
    background: #b157fe;
  }
 75% {
    background: #4f61ff;
  }
  80% {
    background: #b157fe;
  }
  85% {
    background: #4f61ff;
  }
  90% {
    background: #b157fe;
  }
  95% {
    background: #4f61ff;
  }
  100% {
    background: #b157fe;
  }
`
