import React from 'react'
import { IItemBullet } from './types'
import { listBulletStyles } from './styles'

export const ItemBullet: React.FC<IItemBullet> = React.memo(
  ({ size, number, color }) => {
    return (
      <div
        css={listBulletStyles({ type: number ? 'number' : 'bullet' })}
        style={{
          fontSize: size,
          color,
        }}
      >
        <div>
          <div style={{ color }}>
            {number ? `${number}.` : <div style={{ background: color }} />}
          </div>
        </div>
      </div>
    )
  },
)
