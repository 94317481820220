import { useTheme as useEmotionTheme } from '@emotion/react'

import { borderRadius, IBorderRadius } from './borderRadius'
import { boxShadow, IBoxShadow } from './boxShadow'
import { breakpoints, IBreakpoints } from './breakpoints'
import { colors, IColors, COLOR } from './colors'
import { dimensions, IDimensions } from './dimensions'
import { fontSize, IFontSize } from './fontSize'
import { fontStyle, IFontStyle } from './fontStyle'
import { fontWeight, IFontWeight } from './fontWeight'
import { gap, IGap } from './gap'
import { gradient, IGradient } from './gradient'
import { spacing, ISpacing } from './spacing'
import { size, ISize } from './size'
import { zIndex, IZIndex } from './z-index'

export * from './animation'
export * from './keyframes'

interface ITheme {
  borderRadius: IBorderRadius
  boxShadow: IBoxShadow
  breakpointsV2: IBreakpoints
  colors: IColors
  dimensions: IDimensions
  fontSize: IFontSize
  fontStyle: IFontStyle
  fontWeight: IFontWeight
  gap: IGap
  gradient: IGradient
  size: ISize
  spacingV2: ISpacing
  zIndex: IZIndex
}

export const theme: ITheme = {
  borderRadius,
  boxShadow,
  breakpointsV2: breakpoints,
  colors,
  dimensions,
  fontSize,
  fontStyle,
  fontWeight,
  gap,
  gradient,
  size,
  spacingV2: spacing,
  zIndex,
}

export const useTheme = (): ITheme => useEmotionTheme() as ITheme

export {
  COLOR,
  borderRadius,
  boxShadow,
  breakpoints,
  colors,
  dimensions,
  fontSize,
  fontStyle,
  fontWeight,
  gap,
  gradient,
  size,
  spacing,
  zIndex,
}

export type { ITheme }
