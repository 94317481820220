import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IEditPropSection } from '../../types'

import { INPUT_THEME, Input } from 'src/lib'
import {
  ComponentFormDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { useDispatch } from 'react-redux'
import { VALIDATION_RULE_TYPES, useDebouncer, useLanguage } from 'src/hooks'

export const FormNextButtonText: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()
    const { t } = useLanguage()

    const selectedOptions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['options'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentFormDataSchema).options
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const changePlaceholderText = useCallback(
      (text: string) => {
        const partialData: DeepPartial<ComponentFormDataSchema> = {
          options: {
            defaultTexts: {
              buttons: {
                next: text,
              },
            },
          },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const [validUrl, setValidUrl] = useState(false)
    const [value, setValue] = useState<string | undefined>(
      selectedOptions?.defaultTexts.buttons.next,
    )
    const handleOnChange = useCallback(
      (val?: string) => {
        setValue(val)
      },
      [components],
    )

    const debounced = useDebouncer(
      () => {
        if (validUrl && value) {
          changePlaceholderText(value)
        }
      },
      { delay: 500 },
    )

    useEffect(() => {
      debounced()
    }, [value, validUrl])

    return (
      <Input
        theme={INPUT_THEME.DARK}
        value={value}
        onChange={handleOnChange}
        onFocus={() => dispatch(setAllowDeleteShortcut(false))}
        onBlur={() => dispatch(setAllowDeleteShortcut(true))}
        constantValidation
        validation={{
          [VALIDATION_RULE_TYPES.REQUIRED]: {
            text: t('validation.error.required', {
              name: t('edit.properties.form.button_text'),
            }),
          },
          [VALIDATION_RULE_TYPES.MIN]: {
            value: 3,
            text: t('validation.min_char', {
              value: 3,
              name: t('edit.properties.form.button_text'),
            }),
          },
        }}
        onValidation={setValidUrl}
      />
    )
  },
)
