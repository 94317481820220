import MuiButton from '@mui/material/Button'
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ShareView } from 'src/components/share-view'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { useDecksApi, useLanguage, useLocation } from 'src/hooks'
import { Avatar, Button, BUTTON_TYPE, Icon, icons, Modal } from 'src/lib'
import { ACTION_CLICKS, GTAG_EVENTS } from 'src/plugins/google/consts'
import { sendEvent } from 'src/plugins/google/gtag'
import { RootState, SAVE_STATE } from 'src/store'
import { colors } from 'src/theme'

interface IRightActionsProps {
  openMenu?: () => void
  className?: string
}

const RightActions: React.FC<PropsWithChildren<IRightActionsProps>> =
  React.memo(({ children, openMenu }) => {
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)
    const [shareLinkMenuOpen, setShareLinkMenuOpen] = useState(false)

    const { path } = useLocation()
    const { saveChanges } = useDecksApi()
    const params = useParams()
    const { t } = useLanguage()

    const {
      version,
      saveState,
      userPlan,
      workspaceId,
      userData,
      deckId,
      deckShareCode,
    } = useSelector(
      ({ layouts, canvas, user, workspace, edit }: RootState) => ({
        version: layouts.version,
        saveState: canvas.saveState,
        userPlan: user.data?.activeUserPlan,
        workspaceId: workspace.id,
        userData: user.data,
        deckId: edit.activeDeck.data?.deck?.id,
        deckShareCode: edit.activeDeck.data?.deck?.deckShare?.shortCode.value,
      }),
    )

    const onPreviewClick = useCallback(async () => {
      if (saveState === SAVE_STATE.NOT_SAVED) {
        await saveChanges()
      }
      window.open(`/preview/${params.id}/1`, '_blank', 'noopener,noreferrer')
    }, [params, saveState, saveChanges])

    const isShareVisible = useMemo(
      () => (path.includes('deck') || path.includes('deck-v3')) && deckId,
      [path, deckId],
    )

    const isVersion3 = version === 'v3'

    return (
      <>
        {!userPlan && !workspaceId && (
          <Button
            text={t('subscriptions.choose_plan')}
            type={BUTTON_TYPE.REVERSE}
            onClick={() => {
              sendEvent(GTAG_EVENTS.PURCHASE_INTENT, {
                user_email: userData?.email,
                purchase_intent_method: ACTION_CLICKS.CHOOSE_PLAN_CLICK,
              })
              setIsUpgradeModalOpen(true)
            }}
            icon={icons.approval}
            dataAttr={{ 'data-button-id': 'plan-button' }}
          />
        )}
        {isVersion3 && isShareVisible ? (
          <MuiButton
            size="small"
            variant="outlined"
            disableElevation
            startIcon={<MaterialSymbol icon="airplay" size={16} />}
            onClick={onPreviewClick}
          >
            {t('v3.present')}
          </MuiButton>
        ) : null}
        {isShareVisible &&
          (isVersion3 ? (
            <MuiButton
              size="small"
              variant="contained"
              disableElevation
              onClick={() => setShareLinkMenuOpen(true)}
            >
              {t('common.actions.share')}
            </MuiButton>
          ) : (
            <Icon
              icon={icons.share}
              color={colors.elements.DEFAULT}
              size={24}
              onClick={() => setShareLinkMenuOpen(true)}
            />
          ))}
        <Avatar
          size={32}
          data={[{ image: userData?.picture, name: userData?.fullName || '' }]}
          onClick={openMenu}
        />

        {isUpgradeModalOpen && (
          <UpgradeModalManager
            callback={() => setIsUpgradeModalOpen(false)}
            context={ACTION_CLICKS.CHOOSE_PLAN_CLICK}
            isOpen={isUpgradeModalOpen}
            onClose={() => setIsUpgradeModalOpen(false)}
          />
        )}

        <Modal
          isOpen={shareLinkMenuOpen}
          onClose={() => setShareLinkMenuOpen(false)}
        >
          {shareLinkMenuOpen ? (
            <ShareView
              deckId={deckId}
              onClickClose={() => setShareLinkMenuOpen(false)}
              deckShareCode={deckShareCode}
            />
          ) : (
            <></>
          )}
        </Modal>
        {children}
      </>
    )
  })

export default RightActions
