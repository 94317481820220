import React from 'react'
import { Helmet } from 'react-helmet-async'

export const Rewardful: React.FC = () => {
  return (
    <Helmet>
      <script>{`(function (w, r) {
      w._rwq = r;
      w[r] =
        w[r] ||
        function () {
          (w[r].q = w[r].q || []).push(arguments);
        };
    })(window, "rewardful");`}</script>
      <script
        // async=""
        src="https://affiliate.decktopus.com/rw.js"
        data-rewardful="6f7b73"
      ></script>
    </Helmet>
  )
}
