import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { observer } from 'mobx-react-lite'
import { PositionPicker } from 'polotno/toolbar/position-picker'
import { useCallback, useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import { usePositionStyles } from './styles'
import { IPolotnoComponent } from '../../types'
import { useLanguage } from 'src/hooks'

const PositionAction = observer<IPolotnoComponent>(({ store }) => {
  const { classes } = usePositionStyles()
  const { t } = useLanguage()
  const [opened, setOpened] = useState<boolean>(false)
  const handleClick = useCallback(() => {
    const container = document.getElementById('positionPicker')
    const button = container?.querySelector(
      'button.bp5-button',
    ) as HTMLButtonElement
    if (opened) {
      setOpened(false)
      return
    }
    setOpened(true)
    button?.click()
  }, [opened])

  return (
    <Box id="positionPicker" className={classes.container} component="div">
      <PositionPicker store={store} />
      <Button
        size="small"
        variant="outlined"
        disableElevation
        startIcon={<MaterialSymbol icon="layers" size={16} weight={300} />}
        onClick={handleClick}
        sx={{
          flexShrink: 0,
          position: 'relative',
          left: 0,
          top: 0,
        }}
      >
        {t('v3.position')}
      </Button>
    </Box>
  )
})

export default PositionAction
