import React, { useMemo } from 'react'
import { IMembersRole, MEMBER_STATUS } from './types'
import { roleStyles, wrapperStyles } from './styles'
import { Icon, icons } from 'src/lib'
import { useLanguage } from 'src/hooks'

export const MembersRole: React.FC<IMembersRole> = React.memo(
  ({ className, dataAttr, status }) => {
    const { t, lang } = useLanguage()

    const icon = useMemo(() => {
      switch (status) {
        case MEMBER_STATUS.ADMIN:
          return icons.user
        case MEMBER_STATUS.MEMBER:
          return icons.team
        case MEMBER_STATUS.PENDING:
          return icons.available_updates
      }
    }, [status])

    const translatedStatus = useMemo(() => {
      switch (status) {
        case MEMBER_STATUS.ADMIN:
          return t('common.roles.admin')
        case MEMBER_STATUS.MEMBER:
          return t('common.roles.member')
        case MEMBER_STATUS.PENDING:
          return t('common.roles.pending')
      }
    }, [status, lang])

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <Icon size={16} icon={icon} />
        <span css={roleStyles}>{translatedStatus}</span>
      </div>
    )
  },
)
