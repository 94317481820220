import React, { useRef, useState, useEffect, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { IMenuItem } from './types'
import {
  itemAreaStyles,
  separatorStyles,
  subMenuInnerStyles,
  subMenuStyles,
  wrapperStyles,
} from './styles'
import { Icon, icons } from 'src/lib/icon'
import { useTheme } from 'src/theme'
import { useWindowSize } from 'src/hooks'

export const MenuItem: React.FC<IMenuItem> = React.memo(
  ({
    label,
    icon,
    color,
    hidden,
    rightIcon,
    items,
    isSelected,
    onClick,
    onItemClick,
    className,
    dataAttr,
    separator,
  }) => {
    const { colors } = useTheme()

    const [subMenuPosition, setSubMenuPosition] = useState<'left' | 'right'>(
      'left',
    )
    const windowSize = useWindowSize()
    const subMenuRef = useRef<HTMLDivElement>(null)

    const rect = useMemo(
      () => subMenuRef?.current?.getBoundingClientRect(),
      [subMenuRef?.current, windowSize],
    )

    useEffect(() => {
      rect
        ? windowSize.width - rect.left > rect.right - rect.left + 16
          ? setSubMenuPosition('left') // values will be reverted by css
          : setSubMenuPosition('right') // right: 100% will be placed on left, for left: 100% the other way around.
        : setSubMenuPosition('left')
    }, [rect])

    if (hidden) {
      return
    }

    if (separator) {
      return <div key={uuidv4()} css={separatorStyles} />
    }

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div
          css={itemAreaStyles({ isSelected, color })}
          onClick={(e) => {
            onClick?.(e)
            onItemClick?.()
          }}
        >
          {icon && (
            <Icon
              size={16}
              color={
                color ||
                (isSelected ? colors.primary.DEFAULT : colors.text.DEFAULT)
              }
              icon={icon}
            />
          )}
          <span className="menu-item-label">{label}</span>
          {rightIcon ? (
            <Icon
              size={16}
              icon={rightIcon}
              color={isSelected ? colors.primary.DEFAULT : color}
            />
          ) : (
            items && <Icon size={16} icon={icons.chevron_right} />
          )}
        </div>
        <div
          className="sub-menu"
          css={subMenuStyles(subMenuPosition)}
          ref={subMenuRef}
        >
          <div css={subMenuInnerStyles}>
            {items?.map((subItem) => (
              <MenuItem
                key={subItem.label}
                {...subItem}
                onItemClick={onItemClick}
              />
            ))}
          </div>
        </div>
      </div>
    )
  },
)
