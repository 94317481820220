import React, { useCallback, useMemo } from 'react'

import { IEditPropSection } from '../../types'
import { BOX_BUTTON_ICON_FRAME, PropBoxButton, PropSection } from '../../props'
import { useLanguage } from 'src/hooks'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { ComponentRadiusTypes } from 'src/types/api/enums'
import { icons } from 'src/lib'

export const ListIconFrame: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedRadiusType = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentRadiusTypes | undefined | null,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentListDataSchema).style
              ?.iconRadiusType

            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? null : a
          },
          undefined,
        ),
      [components],
    )

    const handleRadiusChange = useCallback(
      (value?: ComponentRadiusTypes) => {
        const partialData: DeepPartial<ComponentListDataSchema> = {
          style: {
            iconRadiusType: value,
          },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <PropSection
        title={t('edit.properties.list.icon_frame')}
        initialState={false}
        expandable
      >
        <div className="grid">
          <div className="c-1">
            <PropBoxButton
              icon={icons.picture}
              iconSize={24}
              isActive={selectedRadiusType === ComponentRadiusTypes.FLAT}
              onClick={() => handleRadiusChange(ComponentRadiusTypes.FLAT)}
            />
          </div>
          <div className="c-1">
            <PropBoxButton
              icon={icons.picture}
              iconSize={24}
              isActive={selectedRadiusType === ComponentRadiusTypes.OUTLINE}
              onClick={() => handleRadiusChange(ComponentRadiusTypes.OUTLINE)}
              iconFrame={BOX_BUTTON_ICON_FRAME.OUTLINE}
            />
          </div>
          <div className="c-1">
            <PropBoxButton
              icon={icons.picture}
              iconSize={24}
              isActive={selectedRadiusType === ComponentRadiusTypes.ROUNDED}
              onClick={() => handleRadiusChange(ComponentRadiusTypes.ROUNDED)}
              iconFrame={BOX_BUTTON_ICON_FRAME.SQUARE}
            />
          </div>
          <div className="c-1">
            <PropBoxButton
              icon={icons.picture}
              iconSize={24}
              iconFrame={BOX_BUTTON_ICON_FRAME.CIRCLE}
              isActive={selectedRadiusType === ComponentRadiusTypes.CIRCLE}
              onClick={() => handleRadiusChange(ComponentRadiusTypes.CIRCLE)}
            />
          </div>
        </div>
      </PropSection>
    )
  },
)

ListIconFrame.displayName = 'ListIconFrame'
