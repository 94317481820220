import { css } from '@emotion/react'
import { ListLayoutDirections } from 'src/types/api/enums'

interface ITimelineItemStyles {
  direction?: ListLayoutDirections
  itemCount?: number
}
export const timelineItemStyles = ({
  direction,
  itemCount,
}: ITimelineItemStyles) => css`
  position: relative;

  ${direction === ListLayoutDirections.HORIZONTAL
    ? `
			width: calc((100% / ${itemCount || 1}) - 10em);`
    : `
		`}

  .pointer {
    aspect-ratio: 1;
    background: var(--third);
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .item-detail {
    position: absolute;

    ${direction === ListLayoutDirections.HORIZONTAL
      ? `
				text-align: center;
				top: calc(100% + 2em);
				left: 50%;
				transform: translateX(-50%);
				word-break: break-word;
				white-space: break-spaces;`
      : `
				left: calc(100% + 5em);
				top: 50%;
				transform: translateY(-50%);
			`}
  }
`
