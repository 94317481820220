import React from 'react'
import { IEditPropSection } from '../../types'

import { PropHeader } from '../../props'
import { Icon, Tooltip, icons } from 'src/lib'
import { useTheme } from '@emotion/react'
import { FormSubmitRequired } from './FormSubmitRequired'
import { FormSubmitGDPREnabled } from './FormGDPREnabled'
import { FormSubmitGDPRMessage } from './FormGDPRMessage'
import { FormPlaceholderText } from './FormPlaceholderText'
import { FormPlaceholderOption } from './FormPlaceholderOption'
import { FormPlaceholderRate } from './FormPlaceholderRate'
import { FormBackButtonText } from './FormBackButtonText'
import { FormNextButtonText } from './FormNextButtonText'
import { FormSubmitButtonText } from './FormSubmitButtonText'
import { useLanguage } from 'src/hooks'
import { FormSubmitMessageText } from './FormSubmitMessageText'

export const FormSubProps: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { colors } = useTheme()
    const { t } = useLanguage()

    return (
      <div style={{ paddingBottom: '80px' }}>
        <div className="grid">
          <div className="c-full control-row">
            <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {t('edit.properties.form.submitting_required')}
              <Tooltip
                text={t('edit.properties.form.submitting_required_tooltip')}
              >
                <span>
                  <Icon
                    icon={icons.info}
                    size={16}
                    color={colors.elements.DEFAULT}
                  />
                </span>
              </Tooltip>
            </span>
            <FormSubmitRequired components={components} />
          </div>
          <div className="c-full control-row">
            <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {t('edit.properties.form.consent')}
              <Tooltip text={t('edit.properties.form.gdpr_tooltip')}>
                <span>
                  <Icon
                    icon={icons.info}
                    size={16}
                    color={colors.elements.DEFAULT}
                  />
                </span>
              </Tooltip>
            </span>
            <FormSubmitGDPREnabled components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.form.gdpr_message')} />
        <div className="grid">
          <div className="c-full">
            <FormSubmitGDPRMessage components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.form.gdpr_prop_placeholder')} />
        <div className="grid">
          <div className="c-full">
            <FormPlaceholderText components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.form.option_list_placeholder')} />
        <div className="grid">
          <div className="c-full">
            <FormPlaceholderOption components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.form.rate_placeholder')} />
        <div className="grid">
          <div className="c-full">
            <FormPlaceholderRate components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.form.back_button_text')} />
        <div className="grid">
          <div className="c-full">
            <FormBackButtonText components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.form.next_button_text')} />
        <div className="grid">
          <div className="c-full">
            <FormNextButtonText components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.form.submit_button_text')} />
        <div className="grid">
          <div className="c-full">
            <FormSubmitButtonText components={components} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.form.submit_message')} />
        <div className="grid">
          <div className="c-full">
            <FormSubmitMessageText components={components} />
          </div>
        </div>
      </div>
    )
  },
)
