import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUploadedFonts } from 'src/pages/deck-page/components/edit-design'
import {
  getThemeCategoriesResponse,
  getThemeColorsResponse,
  getThemeFontsResponse,
  getThemesInThemeCategoryResponse,
} from 'src/types/api/responseObjects'

const initialState: {
  categories: getThemeCategoriesResponse['data']['themeCategories']
  isCategoryLoading: boolean
  themes: getThemesInThemeCategoryResponse['data']['themes']
  isThemesLoading: boolean
  themeFonts: getThemeFontsResponse['data'] | null
  themeColors: getThemeColorsResponse['data'] | null
  fontsFromUrl: IUploadedFonts[] | null
  googleFontsImportUrl: string
  googleFontNames: string[]
} = {
  categories: [],
  isCategoryLoading: false,
  themes: [],
  isThemesLoading: false,
  themeFonts: null,
  themeColors: null,
  fontsFromUrl: null,
  googleFontsImportUrl: '',
  googleFontNames: [],
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeCategories: (
      state,
      action: PayloadAction<
        getThemeCategoriesResponse['data']['themeCategories']
      >,
    ) => {
      state.categories = action.payload
      state.isCategoryLoading = false
    },
    setThemes: (
      state,
      action: PayloadAction<getThemesInThemeCategoryResponse['data']['themes']>,
    ) => {
      state.themes = action.payload
      state.isThemesLoading = false
    },
    resetThemes: (state) => {
      state.themes = []
      state.isCategoryLoading = false
    },
    setCategoryLoading: (state) => {
      state.isCategoryLoading = true
    },
    setThemeLoading: (state) => {
      state.isThemesLoading = true
    },
    setThemeFonts: (
      state,
      action: PayloadAction<getThemeFontsResponse['data']>,
    ) => {
      state.themeFonts = action.payload
    },
    setThemeColors: (
      state,
      action: PayloadAction<getThemeColorsResponse['data']>,
    ) => {
      state.themeColors = action.payload
    },
    setFontsFromUrl: (state, action: PayloadAction<IUploadedFonts[]>) => {
      state.fontsFromUrl = action.payload
    },
    setGoogleFontsImportUrl: (state, action: PayloadAction<string>) => {
      state.googleFontsImportUrl = action.payload
    },
    setGoogleFontNamesAccumulatively: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      if (!state.googleFontNames.includes(action.payload[0])) {
        state.googleFontNames.push(action.payload[0])
      }
      if (!state.googleFontNames.includes(action.payload[1])) {
        state.googleFontNames.push(action.payload[1])
      }
    },
    setFontsFromUrlsAccumulatively: (
      state,
      action: PayloadAction<IUploadedFonts>,
    ) => {
      const isFontInTheList = state.fontsFromUrl?.some(
        (font) => font.id === action.payload.id,
      )
      if (!isFontInTheList) {
        if (state.fontsFromUrl === null) {
          state.fontsFromUrl = []
        }
        state.fontsFromUrl?.push(action.payload)
      }
    },
  },
})

export const {
  setThemeCategories,
  setThemes,
  resetThemes,
  setCategoryLoading,
  setThemeLoading,
  setThemeFonts,
  setThemeColors,
  setFontsFromUrl,
  setGoogleFontsImportUrl,
  setGoogleFontNamesAccumulatively,
  setFontsFromUrlsAccumulatively,
} = themeSlice.actions

export default themeSlice.reducer
