import React from 'react'
import { IProfileBadge } from './types'
import { wrapperStyles, nameStyles } from './styles'
import { Avatar } from 'src/lib/avatar'

export const ProfileBadge: React.FC<IProfileBadge> = React.memo(
  ({ className, dataAttr, data, name }) => {
    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <Avatar data={data} size={48} />
        <div css={nameStyles}>{name}</div>
      </div>
    )
  },
)
