import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { useLanguage } from 'src/hooks'
import { useContextMenuStyles } from './stylesV3'

export type SlideMenuAction = 'add' | 'duplicate' | 'delete'
export interface ISlideCardMenuProp {
  onActionClick?: (action: SlideMenuAction) => void
}

const SlideCardMenu: React.FC<ISlideCardMenuProp> = React.memo(
  ({ onActionClick }) => {
    const { t } = useLanguage()
    const { classes } = useContextMenuStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (open) {
        setAnchorEl(null)
      } else {
        setAnchorEl(event.currentTarget)
      }
    }
    const handleActionClick = (action?: SlideMenuAction) => {
      if (action) {
        onActionClick?.(action)
      }
      setAnchorEl(null)
    }

    return (
      <div data-label={'slide-menu-v3' + (open ? '-open' : '')}>
        <BaseIconButton
          id="menu-button"
          aria-controls={open ? 'slide-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className={classes.root}
        >
          <MaterialSymbol icon="more_horiz" size={16} weight={300} />
        </BaseIconButton>
        <Menu
          id="slide-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClick}
          MenuListProps={{
            'aria-labelledby': 'slide-button',
          }}
          anchorOrigin={{
            vertical: -8,
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{
            paper: classes.paper,
          }}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleActionClick('add')}
          >
            {t('common.deck.add_slide')}
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleActionClick('duplicate')}
          >
            {t('common.deck.duplicate_slide')}
          </MenuItem>
          <MenuItem
            className={classes.dangerMenuItem}
            onClick={() => handleActionClick('delete')}
          >
            {t('common.deck.delete_slide')}
          </MenuItem>
        </Menu>
      </div>
    )
  },
)

export default SlideCardMenu
