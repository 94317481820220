import { IGradient } from 'src/theme/gradient'
import { IButton, BUTTON_THEME, BUTTON_TYPE } from './types'
import { COLOR, colors, gradient } from 'src/theme'

type IUseColorClasses = Pick<IButton, 'type' | 'theme' | 'disabled'>

export const useIconColor = ({
  theme,
  type,
  disabled,
}: IUseColorClasses): COLOR => {
  // for default type, text and icon colors are white for both disabled and active states
  if (type === BUTTON_TYPE.DEFAULT) {
    return theme === BUTTON_THEME.WHITE
      ? colors.black.DEFAULT
      : colors.white.DEFAULT
    // if it's not default type, disabled buttons should have deactive text/icon color
  } else if (disabled) {
    return colors.deactive.DEFAULT
  }

  switch (theme) {
    case BUTTON_THEME.PRIMARY:
      return colors.primary.DEFAULT
    case BUTTON_THEME.SECONDARY:
      return colors.secondary.DEFAULT
    case BUTTON_THEME.RED:
      return colors.error.DEFAULT
    case BUTTON_THEME.WHITE:
      return colors.white.DEFAULT
    case BUTTON_THEME.GRAY:
      return colors.text.DEFAULT
    case BUTTON_THEME.ELEMENTS:
      return colors.elements.DEFAULT
    case BUTTON_THEME.GOOGLE:
      return colors.black.DEFAULT
    default:
      return colors.black.DEFAULT
  }
}

type IUseLoaderClasses = Pick<IButton, 'type' | 'theme' | 'disabled'>
export const useLoaderColor = ({
  theme,
  type,
  disabled,
}: IUseLoaderClasses): IGradient['DEFAULT'] | COLOR => {
  if (
    theme === BUTTON_THEME.GRADIENT &&
    (type === BUTTON_TYPE.REVERSE || type === BUTTON_TYPE.GHOST)
  ) {
    return gradient.DEFAULT
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useIconColor({ theme, type, disabled })
  }
}
