import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IImageUpload } from './types'

export const fileInputStyles = css`
  position: absolute;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
`
type IMenuStyle = Pick<IImageUpload, 'isRight' | 'size'>
export const wrapperStyles =
  ({ isRight, size }: IMenuStyle) =>
  ({
    colors,
    gap,
    borderRadius,
    boxShadow,
    zIndex,
    breakpointsV2: breakpoints,
  }: ITheme) => css`
    position: relative;
    display: flex;
    justify-content: center;
    width: 48px;
    height: 48px;

    @media (min-width: ${breakpoints.tablet}px) {
      width: 136px;
      height: 136px;
    }

    .icon {
      background: ${colors.primary.DEFAULT};
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: ${zIndex.icon};
      border-radius: ${borderRadius.DEFAULT};
      cursor: pointer;
      bottom: 0;
      right: 0;
      width: 20px;
      height: 20px;

      @media (min-width: ${breakpoints.tablet}px) {
        bottom: ${gap['2xsmall']}px;
        right: ${gap['2xsmall']}px;
        width: 34px;
        height: 34px;
      }
    }

    .menu {
      position: absolute;
      z-index: ${zIndex.popUpMenu};
      top: calc(100% - 6px);
      box-shadow: ${boxShadow[2]};
      width: ${size}px;
      ${isRight ? `left:70%; top: 70%;` : `top: calc(100% - 6px);`}
    }
  `

export const imageUploadAvatarStyles =
  (disabled?: boolean) =>
  ({ colors, borderRadius, breakpointsV2: breakpoints }: ITheme) => css`
    overflow: hidden;
    border-radius: ${borderRadius.DEFAULT};
    background: ${colors.white.DEFAULT};
    width: 100%;
    height: 100%;
    position: relative;
    cursor: ${disabled ? 'default' : 'pointer'};
    border: 2px solid ${colors.primary.DEFAULT};

    @media (min-width: ${breakpoints.tablet}px) {
      border: 6px solid ${colors.primary.DEFAULT};
    }

    &:hover {
      img {
        transform: scale(1.1);
        opacity: 0.8;
      }
    }

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    }
  `
