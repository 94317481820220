import removeQueryParams from 'src/utils/removeQueryParams'

type MediaType = 'image' | 'svg' | 'video' | 'audio' | 'unknown'

export default function getMediaTypeFromUrl(url: string): MediaType {
  try {
    // Clean and lowercase the URL

    const cleanUrl = removeQueryParams(url).trim().toLowerCase()

    // Handle data URLs
    if (cleanUrl.startsWith('data:')) {
      if (cleanUrl.startsWith('data:image/svg+xml')) return 'svg'
      if (cleanUrl.startsWith('data:image/')) return 'image'
      if (cleanUrl.startsWith('data:video/')) return 'video'
      if (cleanUrl.startsWith('data:audio/')) return 'audio'
      return 'unknown'
    }

    // File extensions to check
    const extensions = {
      image: ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.bmp', '.tiff'],
      svg: ['.svg'],
      video: ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.wmv', '.flv', '.mkv'],
      audio: ['.mp3', '.wav', '.ogg', '.m4a', '.aac', '.flac', '.wma'],
    }

    // Check file extensions
    if (extensions.svg.some((ext) => cleanUrl.endsWith(ext))) return 'svg'
    if (extensions.image.some((ext) => cleanUrl.endsWith(ext))) return 'image'
    if (extensions.video.some((ext) => cleanUrl.endsWith(ext))) return 'video'
    if (extensions.audio.some((ext) => cleanUrl.endsWith(ext))) return 'audio'

    // Check common URL patterns
    if (cleanUrl.includes('image')) return 'image'
    if (cleanUrl.includes('video')) return 'video'
    if (cleanUrl.includes('audio')) return 'audio'

    return 'unknown'
  } catch {
    return 'unknown'
  }
}
