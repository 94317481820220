import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdminSlice } from './types'

const initialState: IAdminSlice = {
  user: null,
  adminToken: null,
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminUsers: (state, action: PayloadAction<IAdminSlice>) => {
      state.user = action.payload?.user
    },

    setAdminToken: (state, action: PayloadAction<IAdminSlice>) => {
      state.adminToken = action.payload?.adminToken
    },
  },
})

export const { setAdminUsers, setAdminToken } = adminSlice.actions

export default adminSlice.reducer
