export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_985)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_1_985"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2030_39)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM470 32C694.228 32 876 213.772 876 438V1046C876 1047.1 875.105 1048 874 1048H66C64.8954 1048 64 1047.1 64 1046V438C64 213.772 245.772 32 470 32Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1012.5 398C1012.5 213.261 862.739 63.5 678 63.5C493.261 63.5 343.5 213.261 343.5 398V715C343.5 899.739 493.261 1049.5 678 1049.5C862.739 1049.5 1012.5 899.739 1012.5 715V398ZM678 66.5C861.082 66.5 1009.5 214.918 1009.5 398V715C1009.5 898.082 861.082 1046.5 678 1046.5C494.918 1046.5 346.5 898.082 346.5 715V398C346.5 214.918 494.918 66.5 678 66.5Z" fill="$color3"/><g filter="url(#filter0_f_2030_39)"><circle cx="186" cy="186" r="186" transform="matrix(-1 0 0 1 1920 708)" fill="url(#paint0_linear_2030_39)"/></g></g><defs><filter id="filter0_f_2030_39" x="1248" y="408" width="972" height="972" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_2030_39"/></filter><linearGradient id="paint0_linear_2030_39" x1="358.922" y1="348.75" x2="-39.2949" y2="233.444" gradientUnits="userSpaceOnUse"><stop stop-color="$color3"/></linearGradient><clipPath id="clip0_2030_39"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1029)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM958 60C959.105 60 960 60.8955 960 62V1018C960 1019.1 959.105 1020 958 1020H62C60.8954 1020 60 1019.1 60 1018V62C60 60.8954 60.8954 60 62 60H958Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_1_1029"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2023_86)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1450 32C1225.77 32 1044 213.771 1044 438V1046C1044 1047.11 1044.9 1048 1046 1048H1854C1855.1 1048 1856 1047.11 1856 1046V438C1856 213.771 1674.23 32 1450 32Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M907.5 398C907.5 213.261 1057.26 63.5 1242 63.5C1426.74 63.5 1576.5 213.261 1576.5 398V715C1576.5 899.739 1426.74 1049.5 1242 1049.5C1057.26 1049.5 907.5 899.739 907.5 715V398ZM1242 66.5C1058.92 66.5 910.5 214.918 910.5 398V715C910.5 898.082 1058.92 1046.5 1242 1046.5C1425.08 1046.5 1573.5 898.082 1573.5 715V398C1573.5 214.918 1425.08 66.5 1242 66.5Z" fill="$color3"/><g filter="url(#filter0_f_2023_86)"><circle cx="186" cy="894" r="186" fill="url(#paint0_linear_2023_86)"/></g></g><defs><filter id="filter0_f_2023_86" x="-300" y="408" width="972" height="972" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_2023_86"/></filter><linearGradient id="paint0_linear_2023_86" x1="358.922" y1="1056.75" x2="-39.2949" y2="941.444" gradientUnits="userSpaceOnUse"><stop stop-color="$color3"/></linearGradient><clipPath id="clip0_2023_86"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1029)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM962 60C960.895 60 960 60.8955 960 62V1018C960 1019.1 960.895 1020 962 1020H1858C1859.1 1020 1860 1019.1 1860 1018V62C1860 60.8954 1859.1 60 1858 60H962Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_1_1029"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" transform="matrix(-1 0 0 1 1920 0)" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M47.7197 657.86C47.7197 661.679 44.9958 664.862 41.3848 665.572V963.857C41.3848 1005.31 75.1127 1039.04 116.57 1039.04H295.81C296.049 1034.92 299.47 1031.64 303.657 1031.64C307.998 1031.64 311.517 1035.16 311.517 1039.5C311.517 1043.85 307.998 1047.36 303.657 1047.36C300.222 1047.36 297.302 1045.16 296.232 1042.09H116.57C73.4316 1042.09 38.3357 1007 38.3357 963.857V665.572C34.7241 664.863 31.9998 661.68 31.9998 657.86C31.9998 653.519 35.5188 650 39.8597 650C44.2007 650 47.7197 653.519 47.7197 657.86Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1608 39.861C1608 35.52 1611.52 32.001 1615.86 32.001C1619.29 32.001 1622.21 34.2035 1623.28 37.273H1802.95C1846.09 37.273 1881.18 72.369 1881.18 115.507V413.792C1884.79 414.502 1887.52 417.685 1887.52 421.505C1887.52 425.845 1884 429.365 1879.66 429.365C1875.32 429.365 1871.8 425.845 1871.8 421.505C1871.8 417.685 1874.52 414.502 1878.13 413.792V115.507C1878.13 74.05 1844.4 40.3211 1802.95 40.3211H1623.71C1623.47 44.4479 1620.05 47.7209 1615.86 47.7209C1611.52 47.7209 1608 44.2019 1608 39.861Z" fill="$color3"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" transform="matrix(-1 0 0 1 1920 0)" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M70.6746 510H69.3242V538.37L49.2633 518.31L48.3086 519.264L68.3688 539.324H40V540.675H68.3696L48.3105 560.734L49.2653 561.688L69.3242 541.629V570H70.6746V541.63L90.7349 561.69L91.6896 560.735L71.6285 540.675H100V539.324H71.6293L91.6912 519.262L90.7365 518.308L70.6746 538.37V510Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1850.67 510H1849.32V538.37L1829.26 518.31L1828.31 519.264L1848.37 539.324H1820V540.675H1848.37L1828.31 560.734L1829.27 561.688L1849.32 541.629V570H1850.67V541.63L1870.73 561.69L1871.69 560.735L1851.63 540.675H1880V539.324H1851.63L1871.69 519.262L1870.74 518.308L1850.67 538.37V510Z" fill="$color3"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_3:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1072)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M70.6745 510H69.3242V538.37L49.2633 518.31L48.3086 519.264L68.3688 539.324H40V540.675H68.3696L48.3106 560.734L49.2652 561.688L69.3242 541.629V570H70.6745V541.63L90.7348 561.69L91.6895 560.735L71.6285 540.675H100V539.324H71.6293L91.6912 519.262L90.7365 518.308L70.6745 538.37V510Z" fill="$color3"/></g><defs><clipPath id="clip0_1_1072"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_4:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1076)"><rect width="1920" height="1080" transform="matrix(-1 0 0 1 1920 0)" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_1_1076"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1055)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM62 540C60.8954 540 60 540.895 60 542V1018C60 1019.1 60.8954 1020 62 1020H1858C1859.1 1020 1860 1019.1 1860 1018V542C1860 540.895 1859.1 540 1858 540H62Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_1_1055"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1062)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V542C0 540.895 0.895431 540 2 540H1918C1919.1 540 1920 540.895 1920 542V0ZM1920 1078C1920 1079.1 1919.1 1080 1918 1080H1920V1078ZM1.99997 1080C0.8954 1080 0 1079.1 0 1078V1080H1.99997Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_1_1062"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2030_52)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM576 32C741.685 32 876 166.315 876 332V1046C876 1047.1 875.105 1048 874 1048H66C64.8954 1048 64 1047.1 64 1046V332C64 166.315 198.315 32 364 32H576Z" fill="url(#decor-bg)"/><g filter="url(#filter0_f_2030_52)"><circle cx="186" cy="186" r="186" transform="matrix(-1 0 0 1 1920 708)" fill="url(#paint0_linear_2030_52)"/></g></g><defs><filter id="filter0_f_2030_52" x="1248" y="408" width="972" height="972" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_2030_52"/></filter><linearGradient id="paint0_linear_2030_52" x1="358.922" y1="348.75" x2="-39.2949" y2="233.444" gradientUnits="userSpaceOnUse"><stop stop-color="$color3"/></linearGradient><clipPath id="clip0_2030_52"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1051)"><path fill-rule="evenodd" clip-rule="evenodd" d="M958 0H1920V1080H958C959.105 1080 960 1079.1 960 1078V2.00001C960 0.895436 959.105 0 958 0ZM2 0C0.895508 0 0 0.895431 0 2V0H2ZM0 1078C0 1079.1 0.895386 1080 2 1080H0V1078Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_1_1051"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2030_20)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1344 32C1178.31 32 1044 166.315 1044 332V1046C1044 1047.1 1044.9 1048 1046 1048H1854C1855.1 1048 1856 1047.1 1856 1046V332C1856 166.315 1721.69 32 1556 32H1344Z" fill="url(#decor-bg)"/><g filter="url(#filter0_f_2030_20)"><circle cx="186" cy="894" r="186" fill="url(#paint0_linear_2030_20)"/></g></g><defs><filter id="filter0_f_2030_20" x="-300" y="408" width="972" height="972" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_2030_20"/></filter><linearGradient id="paint0_linear_2030_20" x1="358.922" y1="1056.75" x2="-39.2949" y2="941.444" gradientUnits="userSpaceOnUse"><stop stop-color="$color3"/></linearGradient><clipPath id="clip0_2030_20"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1_1047)"><path fill-rule="evenodd" clip-rule="evenodd" d="M962 0H0V1080H962C960.895 1080 960 1079.1 960 1078V2.00001C960 0.895436 960.895 0 962 0ZM1918 0C1919.1 0 1920 0.895431 1920 2V0H1918ZM1920 1078C1920 1079.1 1919.1 1080 1918 1080H1920V1078Z" fill="url(#decor-bg)"/></g><defs><clipPath id="clip0_1_1047"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
