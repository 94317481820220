import React from 'react'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useStyles } from './styles'
import { TKey } from 'src/i18n/types'
import { useLanguage } from 'src/hooks'

interface IBaseIconButtonProps extends IconButtonProps {
  tooltip?: TKey
}

const BaseIconButton: React.FC<IBaseIconButtonProps> = (props) => {
  const { classes, cx } = useStyles()
  const { className, children, tooltip, ...rest } = props
  const { t } = useLanguage()

  return (
    <Tooltip
      title={tooltip ? t(tooltip) : undefined}
      arrow
      disableFocusListener={props.disabled}
      disableHoverListener={props.disabled}
      disableTouchListener={props.disabled}
      disableInteractive={props.disabled}
    >
      <div>
        <IconButton className={cx(classes.icon, className)} {...rest}>
          {children}
        </IconButton>
      </div>
    </Tooltip>
  )
}

export default BaseIconButton
