import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import InputAdornment from '@mui/material/InputAdornment'
import { observer } from 'mobx-react-lite'
import { TextElementType } from 'polotno/model/text-model'
import { useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import StyledToggleButton from 'src/components/toggle-button'
import { useLanguage } from 'src/hooks'
import SliderWithInput from './SliderWithInput'
import { useSpacingStyles } from './styles'
import { IPolotnoComponent } from '../../types'

const RationEndAdornment = (
  <InputAdornment position="end">
    <span
      style={{
        fontSize: '12px',
        padding: 0,
        fontWeight: 500,
      }}
    >
      %
    </span>
  </InputAdornment>
)

const TextSpacingAction = observer<IPolotnoComponent>(({ store }) => {
  const element = store.selectedElements[0] as TextElementType
  const allTextItems = store.selectedElements?.every(
    (element) => element.type === 'text',
  )

  const { t } = useLanguage()
  const { classes } = useSpacingStyles()
  const [spacing, setSpacing] = useState<number>(element.letterSpacing)
  const [lineHeight, setLineHeight] = useState<number>(
    typeof element.lineHeight === 'number'
      ? element.lineHeight
      : parseInt(element.lineHeight),
  )
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'text-spacing-popover' : undefined

  const handleSpacingChange = (newValue: number) => {
    const value = newValue
    setSpacing(value)
    store.selectedElements.forEach((element) => {
      element.set({
        letterSpacing: value,
      })
    })
  }

  const handleLineHeightChange = (newValue: number) => {
    const value = newValue
    setLineHeight(value)
    store.selectedElements.forEach((element) => {
      element.set({
        lineHeight: value,
      })
    })
  }

  return allTextItems ? (
    <>
      <StyledToggleButton selected={open} value={open} onClick={handleClick}>
        <MaterialSymbol icon="format_line_spacing" size={20} weight={300} />
      </StyledToggleButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 48,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={32}
        elevation={6}
      >
        <Stack className={classes.container}>
          <SliderWithInput
            min={-0.5}
            max={2.5}
            step={0.1}
            label={t('v3.letter_spacing')}
            value={spacing}
            onValueChange={handleSpacingChange}
            endAdornment={RationEndAdornment}
          />
          <SliderWithInput
            min={0.5}
            max={2.5}
            step={0.1}
            label={t('v3.line_height')}
            value={lineHeight}
            onValueChange={handleLineHeightChange}
            endAdornment={RationEndAdornment}
          />
        </Stack>
      </Popover>
    </>
  ) : null
})
export default TextSpacingAction
