import React, { useCallback, useMemo, useRef, useState } from 'react'

import { ITable } from './types'
import { tableStyles } from './styles'
import { useTheme } from 'src/theme'
import { ContextMenu, IContextMenu } from '../context-menu'
import { useClickOutside } from 'src/hooks'
import { IMenuItem, Menu } from '../menu'
import { icons } from '../icon'

export const Table: React.FC<ITable> = React.memo(
  ({ data, error, options, onChange, styles, className, dataAttr }) => {
    const { colors } = useTheme()
    const conf: ITable['options'] = {
      edit: false,
      rowNumbers: false,
      headerCol: false,
      headerRow: false,
      headerConst: false,
      ...options,
    }

    const stl: ITable['styles'] = {
      borderRadius: 4,
      colorPri: colors.primary.DEFAULT,
      colorBg: colors.white.DEFAULT,
      colorBgAct: colors.background.DEFAULT,
      colorBorder: colors.outline.DEFAULT,
      colorText: colors.black.DEFAULT,
      borderWidth: 1,
      gaps: 10,
      ...styles,
    }

    const menuRef = useRef(null)
    const [activeCell, setActiveCell] = useState<(number | null)[]>([])
    const [isMenuOpen, setMenuOpen] = useState<IContextMenu['pos'] | null>(null)
    const handleMenuClose = useCallback(() => {
      setMenuOpen(null)
      setActiveCell([])
    }, [])
    useClickOutside(menuRef, handleMenuClose)

    const handleContextClick = useCallback(
      (e: React.MouseEvent<HTMLElement>, row: number, col: number | null) => {
        if (!conf.edit) {
          return
        }
        setActiveCell([row, col])
        e.preventDefault()
        e.stopPropagation()
        setMenuOpen({ x: e.pageX, y: e.pageY })
      },
      [],
    )

    const handleCellChange = useCallback(
      (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        row: number,
        col: number | null,
      ) => {
        if (data && col !== null) {
          const newData = JSON.parse(JSON.stringify(data))
          newData[row][col] = e.currentTarget.value
          onChange?.(newData)
        }
      },
      [data, conf],
    )

    const headerData = useMemo(
      () => (conf.headerRow ? data?.[0] : null),
      [data, conf],
    )

    const renderHeader = useMemo(
      () =>
        headerData ? (
          <thead>
            <tr>
              {conf.rowNumbers && <th />}
              {headerData?.map((val, index) => (
                <th
                  key={index}
                  onContextMenu={(e) => handleContextClick(e, 0, index)}
                >
                  {conf.edit && (
                    <textarea
                      value={val}
                      onChange={(e) => handleCellChange(e, 0, index)}
                    />
                  )}
                  {val.split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                  ))}
                </th>
              ))}
            </tr>
          </thead>
        ) : null,
      [headerData, conf],
    )

    const renderBody = useMemo(() => {
      return (
        <tbody>
          {data?.map((row, ri) => {
            if (conf.headerRow && ri === 0) {
              return null
            }

            return (
              <tr key={ri}>
                {conf.rowNumbers && (
                  <td
                    className="row-number"
                    onContextMenu={(e) => handleContextClick(e, ri, null)}
                  >
                    {conf.headerRow ? ri : ri + 1}
                  </td>
                )}
                {row?.map((cell, ci) => (
                  <td
                    key={ci}
                    onContextMenu={(e) => handleContextClick(e, ri, ci)}
                    className={`${
                      activeCell[0] === ri ||
                      activeCell[1] === ci ||
                      (ci === 0 && conf.headerCol)
                        ? 'active'
                        : ''
                    } ${
                      error?.cell[0] === ri && error?.cell[1] === ci
                        ? 'error'
                        : ''
                    }`}
                  >
                    {conf.edit && (
                      <textarea
                        value={cell}
                        onChange={(e) => handleCellChange(e, ri, ci)}
                      />
                    )}
                    {cell.split('\n').map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                    {error?.cell[0] === ri && error?.cell[1] === ci ? (
                      <span className="error-text">{error.text}</span>
                    ) : null}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      )
    }, [data, conf, error])

    const dimensionChange = useCallback(
      (to: 'left' | 'right' | 'above' | 'below' | 'row_del' | 'col_del') => {
        const newData: string[][] = []
        data?.forEach((row, ri) => {
          if (to === 'row_del' && ri === activeCell[0]) {
            return
          }

          const newEmptyRow = new Array(row.length).fill('0')
          if (activeCell[0] === ri && to === 'above') {
            newData.push(newEmptyRow)
          }

          const newRow: string[] = []
          row.forEach((col, ci) => {
            if (to === 'col_del' && ci === activeCell[1]) {
              return
            }
            if (activeCell[1] === ci && to === 'left') {
              newRow.push('')
            }
            newRow.push(col)
            if (activeCell[1] === ci && to === 'right') {
              newRow.push('')
            }
          })
          newData.push(newRow)

          if (activeCell[0] === ri && to === 'below') {
            newData.push(newEmptyRow)
          }
        })

        onChange?.(newData)
        handleMenuClose()
      },
      [data, activeCell],
    )

    const menuItems = useMemo<IMenuItem[]>(() => {
      const isRowNumber = activeCell[1] === null
      const fixedRow = conf.headerConst && conf.headerRow && activeCell[0] === 0
      const fixedCol =
        (conf.headerConst && conf.headerCol && activeCell[1] === 0) ||
        isRowNumber

      const preventAddRight = isRowNumber && conf.headerCol

      return [
        {
          icon: icons.chevron_up,
          label: 'Add row above',
          onClick: () => dimensionChange('above'),
          hidden: fixedRow,
        },
        {
          icon: icons.chevron_down,
          label: 'Add row below',
          onClick: () => dimensionChange('below'),
        },
        { separator: true, hidden: fixedCol && preventAddRight },
        {
          icon: icons.chevron_left,
          label: 'Add column left',
          onClick: () => dimensionChange('left'),
          hidden: fixedCol,
        },
        {
          icon: icons.chevron_right,
          label: 'Add column right',
          onClick: () => dimensionChange('right'),
          hidden: preventAddRight,
        },
        { separator: true, hidden: fixedCol && fixedRow },
        {
          icon: icons.horizontal_center_positon,
          color: colors.error.DEFAULT,
          label: 'Delete row',
          onClick: () => dimensionChange('row_del'),
          hidden: fixedRow,
        },
        {
          icon: icons.vertical_center_positon,
          color: colors.error.DEFAULT,
          label: 'Delete column',
          onClick: () => dimensionChange('col_del'),
          hidden: fixedCol,
        },
      ]
    }, [data, activeCell, conf])

    return (
      <>
        <table
          css={tableStyles({ styles: stl })}
          className={className}
          {...dataAttr}
        >
          {renderHeader}
          {renderBody}
        </table>
        {isMenuOpen && (
          <ContextMenu pos={isMenuOpen} ref={menuRef}>
            <Menu shadow items={menuItems} />
          </ContextMenu>
        )}
      </>
    )
  },
)

Table.displayName = 'Table'
