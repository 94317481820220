import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { ITheme } from 'src/theme'

export const headerActionsStyles = ({
  gap,
  breakpointsV2: breakpoints,
}: ITheme) => {
  return css`
    display: flex;
    gap: ${gap.large}px;
    align-items: center;
    display: none;

    @media (min-width: ${breakpoints.tablet}px) {
      display: flex;
    }
  `
}

export const userPanelStyles = (theme: ITheme) => {
  const isMuiTheme = !!(theme as any)['palette']
  const breakpoint = isMuiTheme
    ? ((theme as unknown as Theme).breakpoints as any)?.values?.tablet
    : theme.breakpointsV2.tablet
  return css`
    margin-left: auto;
    @media (min-width: ${breakpoint}px) {
      display: none;
    }
  `
}
