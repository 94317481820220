import React, { useCallback, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { CSSTransition } from 'react-transition-group'
import { IPlanDetail } from './types'
import {
  buttonAreaStyles,
  expandButtonStyles,
  listStyles,
  wrapperStyles,
} from './styles'
import { Icon, icons } from 'src/lib'
import { PLAN_CARD_THEME } from '../../types'
import { useTheme } from 'src/theme'
import { useLanguage } from 'src/hooks'

export const PlanDetail: React.FC<IPlanDetail> = React.memo(
  ({ features, featuresHeight, theme, upgradeModal }) => {
    const { t } = useLanguage()
    const { colors } = useTheme()
    const nodeRef = useRef(null)
    const [expanded, setExpanded] = useState(upgradeModal ? true : false)

    const handleExpandClick = useCallback(() => {
      setExpanded(!expanded)
    }, [expanded])

    const buttonText = useMemo(
      () =>
        expanded ? t('subscriptions.show_less') : t('subscriptions.show_more'),
      [expanded],
    )

    return (
      <div css={wrapperStyles}>
        <CSSTransition nodeRef={nodeRef} in={expanded} timeout={500}>
          <div
            ref={nodeRef}
            css={listStyles({ theme, featuresHeight, expanded }, upgradeModal!)}
          >
            {features?.map((item) => (
              <div key={uuidv4()}>
                <Icon
                  icon={icons.done}
                  color={
                    theme === PLAN_CARD_THEME.DEFAULT
                      ? colors.text.DEFAULT
                      : colors.white.DEFAULT
                  }
                />
                {item}
              </div>
            ))}
          </div>
        </CSSTransition>
        <div css={buttonAreaStyles}>
          {!upgradeModal && (
            <div css={expandButtonStyles} onClick={handleExpandClick}>
              {buttonText}
            </div>
          )}
        </div>
      </div>
    )
  },
)
