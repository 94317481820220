interface IShiftSlideOrder {
  currentOrder: number
  from?: number
  to?: number
}

export const shiftSlideOrder = ({
  currentOrder,
  from,
  to,
}: IShiftSlideOrder): number => {
  if (!from || !to) {
    return currentOrder
  }

  if (currentOrder === from) {
    return to
  }

  const shiftForward = from < to && currentOrder <= to && currentOrder >= from
  const shiftBackward = from > to && currentOrder <= from && currentOrder >= to
  const diff = shiftForward ? -1 : shiftBackward ? 1 : 0

  return currentOrder + diff
}
