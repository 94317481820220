import { dataAttrType } from 'src/types'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'

export enum CHART_TYPE {
  PIE_CHART = 'pie_chart',
  LINE_CHART = 'line_chart',
  BAR_CHART = 'bar_chart',
  DONUT_CHART = 'donut_chart',
}

export interface ChartData {
  name: string
  value?: number | number
}

export interface ChartSeries {
  label: string
  values: ChartData[]
}

export interface ChartOptions {
  title?: string
  showGrids?: boolean
  legends?: boolean
  labels?: {
    x: string
    showX?: boolean
    y: string
    showY?: boolean
  }
  type?: CHART_TYPE
  showBackground?: boolean

  donutInnerRadius?: number
  stroke?: {
    width: number
  }
  bullet?: {
    size: number
    marker: number
  }
  titleSize?: number
}

export interface IChart {
  series: ChartSeries[]
  options?: ChartOptions
  size?: {
    width?: number | null
    height?: number | null
  }
  width?: number
  height?: number
  opacity?: number
  backgroundColor?: GradientLikeColorSchema
  chartType?: CHART_TYPE
  className?: string
  dataAttr?: dataAttrType
}

export type RequiredChartOptions = Required<ChartOptions>
export type IChartAltered = Omit<IChart, 'options'> & {
  options: RequiredChartOptions
}
