import { COLOR } from './types'

export const avatar = {
  1: COLOR.primary_100,
  2: COLOR.avatar_color_2,
  3: COLOR.avatar_color_3,
  4: COLOR.avatar_color_4,
  5: COLOR.avatar_color_5,
  6: COLOR.avatar_color_6,
  7: COLOR.avatar_color_7,
}
