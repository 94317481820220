import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { APP_CONFIG } from 'src/config'
import { useLanguage } from 'src/hooks'
import {
  Dropdown,
  DROPDOWN_SIZE,
  DROPDOWN_THEME,
  DROPDOWN_WIDTH,
  IDropdownItem,
} from 'src/lib'
import {
  componentsUpdate,
  SAVE_STATE,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import {
  ComponentQuoteDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { IEditPropSection } from '../../types'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'

export const QuoteAuthorTextSize: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedFontSize = useMemo(
      () =>
        components?.reduce(
          (a: string | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentQuoteDataSchema).style
              .author.font.size
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleFontSizeChange = useCallback(
      (value?: IDropdownItem) => {
        const partialData: DeepPartial<ComponentQuoteDataSchema> = {
          style: {
            author: {
              font: {
                size: value?.value as string,
              },
            },
          },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentQuoteDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <Dropdown
        width={DROPDOWN_WIDTH.FULL}
        theme={DROPDOWN_THEME.DARK}
        size={DROPDOWN_SIZE.BIG}
        selected={selectedFontSize}
        onChange={handleFontSizeChange}
        onInputFocus={() => dispatch(setAllowDeleteShortcut(false))}
        onInputBlur={() => dispatch(setAllowDeleteShortcut(true))}
        placeholder={t('edit.properties.text_size.placeholder')}
        flexValue
        flexFormatters={[
          (val) => (!isNaN(parseInt(val)) ? parseInt(val).toString() : ''),
          (val) => `${val}em`,
        ]}
        items={APP_CONFIG.editPage.fontSizeOptions}
      />
    )
  },
)
