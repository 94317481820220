import { useMemo } from 'react'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'

export const useGradientColor = (color?: GradientLikeColorSchema) => {
  const asBackground = useMemo(() => {
    if (!color) {
      return {}
    }
    if (color.colors.length > 1) {
      return {
        background: `linear-gradient(${color.rotation}deg, ${color.colors[0]} 0%, ${color.colors[1]} 100%)`,
      }
    }
    return {
      background: color.colors[0],
    }
  }, [color])

  const asText = useMemo(() => {
    if (!color) {
      return {}
    }
    if (color.colors.length > 1) {
      return {
        'background': `-webkit-linear-gradient(${color.rotation}deg, ${color.colors[0]} 0%, ${color.colors[1]} 100%) text`,
        '-webkit-text-fill-color': 'transparent',
      }
    }

    return {
      color: color.colors[0],
    }
  }, [color])

  return { asBackground, asText }
}
