import React, { useCallback, useState } from 'react'
import { useFolderApi } from 'src/hooks/api/useFolderApi'
import { VALIDATION_RULE_TYPES, useLanguage, useNotification } from 'src/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { RootState, setDecksQuery } from 'src/store'
import { BUTTON_THEME, Dialog, FolderItem, icons, Modal, Menu } from 'src/lib'
import { IDashboardFolder } from './types'

export const DashboardFolder: React.FC<IDashboardFolder> = React.memo(
  ({ id, name, onClick, isActive = false }) => {
    const { t } = useLanguage()
    const { success } = useNotification()
    const { deleteFolder, renameFolder } = useFolderApi()
    const dispatch = useDispatch()
    const [isDeleting, setIsDeleting] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [newFolderName, setNewFolderName] = useState(name)
    const [renameModal, setRenameModal] = useState(false)

    const [searchParams, setSearchParams] = useSearchParams()
    const { deckCount } = useSelector(({ folders }: RootState) => ({
      deckCount: folders.deckCountInActiveFolder,
    }))

    const onDeleteHandler = useCallback(async () => {
      setIsDeleting(true)
      setDeleteModal(false)

      if (id) {
        const status = await deleteFolder(id)
        if (status) {
          success('dashboard.folder.folder_deleted')
          if (searchParams.get('folderId') === id?.toString()) {
            searchParams.delete('folderId')
            setSearchParams({})
            dispatch(setDecksQuery({}))
          }
        } else {
          setIsDeleting(false)
        }
      }
    }, [id, deleteModal])

    const onClickHandler = useCallback(() => {
      onClick()
    }, [isActive])

    const onRenameHandler = useCallback(async () => {
      setRenameModal(false)

      if (id) {
        const status = await renameFolder({ name: newFolderName, id })
        if (status) {
          success('dashboard.folder.renamed')
          setNewFolderName('')
        }
      }
    }, [newFolderName])

    return (
      !isDeleting && (
        <>
          <FolderItem
            key={id}
            isActive={isActive}
            text={name}
            folderID={id}
            isMenuOpen={isMenuOpen}
            onMenuChange={setIsMenuOpen}
            onClick={onClickHandler}
            menu={
              <Menu
                items={[
                  {
                    label: t('common.actions.rename'),
                    icon: icons.edit,
                    onClick: () => {
                      setRenameModal(true)
                      setIsMenuOpen(false)
                      setNewFolderName(name)
                    },
                  },
                  {
                    label: t('common.actions.delete'),
                    icon: icons.trash_can,
                    onClick: () => {
                      setDeleteModal(true)
                      setIsMenuOpen(false)
                    },
                  },
                ]}
              />
            }
          />
          <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
            <Dialog
              title={t('common.actions.delete')}
              submitLabel={t('common.actions.delete')}
              submitTheme={BUTTON_THEME.RED}
              text={[
                t('dashboard.folder.delete_title', {
                  folder_name: name,
                  folder_id: deckCount,
                }),
                t('dashboard.folder.delete_text'),
              ]}
              onSubmit={onDeleteHandler}
              onCloseClick={() => setDeleteModal(false)}
              promptValue={name}
            />
          </Modal>
          <Modal isOpen={renameModal} onClose={() => setRenameModal(false)}>
            <Dialog
              title={t('dashboard.folder.rename')}
              submitLabel={t('common.actions.rename')}
              onSubmit={onRenameHandler}
              promptValue={newFolderName}
              promptPlaceholder={t('dashboard.folder.type_folder_name')}
              onPromptChange={setNewFolderName}
              onCloseClick={() => setRenameModal(false)}
              promptValidationRules={{
                [VALIDATION_RULE_TYPES.MIN]: {
                  value: 3,
                  text: t('validation.min_char', {
                    value: 3,
                    name: t('dashboard.folder.name'),
                  }),
                },
              }}
            />
          </Modal>
        </>
      )
    )
  },
)

DashboardFolder.displayName = 'DashboardFolder'
