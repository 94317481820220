import React, { useCallback } from 'react'

import { INewSlide } from './types'
import { newSlideStyles } from './styles'
import { Icon, icons } from 'src/lib'
import { useTheme } from 'src/theme'
import { useDispatch } from 'react-redux'
import { setAddSlideModal } from 'src/store'

export const NewSlide: React.FC<INewSlide> = React.memo(
  ({ className, dataAttr, onClick }) => {
    const { colors } = useTheme()
    const dispatch = useDispatch()

    const onClickHandler = useCallback(() => {
      dispatch(setAddSlideModal({ isOpen: true }))
    }, [dispatch])

    return (
      <div
        onClick={onClick ? onClick : onClickHandler}
        css={newSlideStyles}
        className={className}
        {...dataAttr}
      >
        <Icon icon={icons.plus} color={colors.elements.DEFAULT} size={48} />
      </div>
    )
  },
)

NewSlide.displayName = 'NewSlide'
