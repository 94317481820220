import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const editComponentFixerStyles = ({
  colors,
  fontSize,
  fontWeight,
  gap,
}: ITheme) => css`
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.overlay.transparent};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white.DEFAULT};
  font-weight: ${fontWeight.bold};

  .component-fixer-detail {
    font-size: ${fontSize.xlarge};
  }

  .component-fixer-info {
    font-size: ${fontSize['2xlarge']};
    margin-bottom: ${gap.medium}px;
  }
`
