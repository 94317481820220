import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.colors.white.DEFAULT};
`

export const topStyles = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.spacingV2.xsmall};
  margin-bottom: ${theme.spacingV2['4xsmall']};
  white-space: nowrap;
`

export const bottomStyles = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.spacingV2['2xsmall']};
  align-items: center;
`

export const starsStyles = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.spacingV2['2xsmall']};
  align-items: center;
`

export const awardRateStyles = (theme: ITheme) => css`
  padding-top: ${theme.spacingV2['4xsmall']};
`
