import React, { useCallback, useMemo, useState } from 'react'
import { IEditPropSection } from '../../types'
import {
  ComponentStyleSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { PropButton, PropSection } from '../../props'
import { useLanguage } from 'src/hooks'
import { icons } from 'src/lib'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { componentsUpdate, SAVE_STATE, setSaveState } from 'src/store'
import { useDispatch } from 'react-redux'

const DEFAULT_CORNER: ComponentStyleSchema['corner'] = {
  tl: 0,
  tr: 0,
  bl: 0,
  br: 0,
}

export const MediaCorners: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const [showEachCorner, setShowEachCorner] = useState(false)
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedCorners = useMemo(
      () =>
        components?.reduce(
          (
            a: NonNullable<ComponentStyleSchema['corner']>,
            c: UpdateComponentSchema,
          ) => {
            const targetField = {
              ...DEFAULT_CORNER,
              ...(c.style as ComponentStyleSchema).corner,
            }
            const resultField: ComponentStyleSchema['corner'] = {}

            if (a.tl === undefined) {
              resultField.tl = targetField.tl
            } else if (targetField.tl !== a.tl) {
              resultField.tl = null
            } else {
              resultField.tl = targetField.tl
            }

            if (a.tr === undefined) {
              resultField.tr = targetField.tr
            } else if (targetField.tr !== a.tr) {
              resultField.tr = null
            } else {
              resultField.tr = targetField.tr
            }

            if (a.br === undefined) {
              resultField.br = targetField.br
            } else if (targetField.br !== a.br) {
              resultField.br = null
            } else {
              resultField.br = targetField.br
            }

            if (a.bl === undefined) {
              resultField.bl = targetField.bl
            } else if (targetField.bl !== a.bl) {
              resultField.bl = null
            } else {
              resultField.bl = targetField.bl
            }

            return resultField
          },
          {},
        ),
      [components],
    )

    const updateCorners = useCallback(
      (corner: ComponentStyleSchema['corner']) => {
        const partialUpdate: DeepPartial<UpdateComponentSchema> = {
          style: { corner },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponent<UpdateComponentSchema>({
            components,
            partialUpdate,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const handleSingleChange = useCallback(
      (dir: string, value?: number | string) => {
        const intValue = value ? parseInt(value?.toString()) : 0
        const newSelectedCorner: ComponentStyleSchema['corner'] = {}
        if (selectedCorners) {
          switch (dir) {
            case 'tl':
              newSelectedCorner.tl = intValue
              break
            case 'tr':
              newSelectedCorner.tr = intValue
              break
            case 'bl':
              newSelectedCorner.bl = intValue
              break
            case 'br':
              newSelectedCorner.br = intValue
              break
            default:
              break
          }
          updateCorners(newSelectedCorner)
        }
      },
      [selectedCorners],
    )

    const currentOverallCorner = useMemo(() => {
      if (selectedCorners) {
        return Object.values(selectedCorners).reduce(
          (a: string | number | null, c) => {
            if (a === -1) {
              return c
            } else {
              if (c !== a) {
                return null
              } else {
                return c
              }
            }
          },
          -1,
        )
      } else {
        return 0
      }
    }, [selectedCorners])

    const handleOnOverallChange = useCallback(
      (value?: number | string) => {
        const intValue = value ? parseInt(value?.toString()) : 0
        updateCorners({
          tl: intValue,
          tr: intValue,
          bl: intValue,
          br: intValue,
        })
      },
      [updateCorners],
    )

    return (
      <>
        <PropSection title={t('edit.properties.media.corner_radius')}>
          <div className="grid">
            <div className="c-1">
              <PropButton
                mixedText={currentOverallCorner === null ? 'Mixed' : undefined}
                value={
                  currentOverallCorner === null
                    ? undefined
                    : currentOverallCorner
                }
                onChange={handleOnOverallChange}
              />
            </div>
            <div className="c-1">
              <PropButton
                icon={icons.fullscreen}
                isFocus={showEachCorner}
                onClick={() => setShowEachCorner((old) => !old)}
              />
            </div>
          </div>
          {showEachCorner && (
            <div className="grid">
              <div className="c-1">
                <PropButton
                  mixedText={selectedCorners?.tl === null ? '-' : undefined}
                  value={
                    selectedCorners?.tl === null
                      ? undefined
                      : selectedCorners?.tl
                  }
                  icon={icons.radius_tl}
                  onChange={(value) => handleSingleChange('tl', value)}
                />
              </div>
              <div className="c-1">
                <PropButton
                  mixedText={selectedCorners?.tr === null ? '-' : undefined}
                  value={
                    selectedCorners?.tr === null
                      ? undefined
                      : selectedCorners?.tr || 0
                  }
                  icon={icons.radius_tr}
                  onChange={(value) => handleSingleChange('tr', value)}
                />
              </div>
              <div className="c-1">
                <PropButton
                  mixedText={selectedCorners?.bl === null ? '-' : undefined}
                  value={
                    selectedCorners?.bl === null
                      ? undefined
                      : selectedCorners?.bl || 0
                  }
                  icon={icons.radius_bl}
                  onChange={(value) => handleSingleChange('bl', value)}
                />
              </div>
              <div className="c-1">
                <PropButton
                  mixedText={selectedCorners?.br === null ? '-' : undefined}
                  value={
                    selectedCorners?.br === null
                      ? undefined
                      : selectedCorners?.br || 0
                  }
                  icon={icons.radius_br}
                  onChange={(value) => handleSingleChange('br', value)}
                />
              </div>
            </div>
          )}
        </PropSection>
      </>
    )
  },
)

MediaCorners.displayName = 'MediaCorners'
