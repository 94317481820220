import React, { useCallback, useEffect } from 'react'
import { IAiPrompt } from './types'
import {
  aiPromptStyles,
  buttonStyles,
  aiSuggestionStyles,
  exampleAreaStyles,
  viewStyles,
  seeExampleButtonStyles,
  inputStyles,
} from './styles'
import { BUTTON_THEME, Button, INPUT_VARIANT, Input } from 'src/lib'
import { Steps } from 'src/lib/steps'
import {
  AI_FLOW_STEP,
  RootState,
  setAiFlowStep,
  setPrompt,
  setSeeExample,
} from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import {
  VALIDATION_RULE_TYPES,
  useLanguage,
  useValidation,
  useValidatedText,
} from 'src/hooks'
import { APP_CONFIG } from 'src/config'
import { PromptStrengthBar } from 'src/components/prompt-strength-bar'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { examplePrompts } from 'src/data/consts'

export const AiPrompt: React.FC<IAiPrompt> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const posthogCapture = usePostHogCapture()

    const { selectedPrompt, seeExample, selectedCategory, aiFlowId } =
      useSelector(({ aiFlow }: RootState) => ({
        selectedPrompt: aiFlow.selectedPrompt,
        seeExample: aiFlow.seeExample,
        selectedCategory: aiFlow.selectedCategory,
        aiFlowId: aiFlow.aiFlowId,
      }))

    useEffect(() => {
      aiFlowId &&
        posthogCapture(POSTHOG_EVENTS.AI_FLOW_PROMPT_VIEW, { aiFlowId })
    }, [aiFlowId])

    const validatedText = useValidatedText(
      () => {
        nextButton()
      },
      selectedPrompt,
      3,
    )

    const [isValid] = useValidation({
      value: selectedPrompt,
      rules: {
        [VALIDATION_RULE_TYPES.MIN]: {
          value: 3,
          text: t('validation.min_char', {
            value: 3,
            name: selectedPrompt,
          }),
        },
      },
    })

    useEffect(() => {
      validatedText
    }, [selectedPrompt])

    const onTopicChange = useCallback(
      (value: string) => {
        dispatch(setPrompt(value))
      },
      [selectedCategory],
    )

    const changeSeeExample = useCallback(() => {
      dispatch(setSeeExample(!seeExample))
    }, [seeExample])

    const updateSelectedPrompt = useCallback(
      (item: string) => {
        dispatch(setPrompt(item))
      },
      [selectedCategory],
    )

    const nextButton = useCallback(async () => {
      dispatch(setAiFlowStep(AI_FLOW_STEP.CATEGORY))
      dispatch(setPrompt(selectedPrompt))
    }, [selectedPrompt])

    return (
      <div css={aiPromptStyles} className={className} {...dataAttr}>
        <div css={viewStyles(true)}>
          <Input
            placeholder={t('create_with_ai.prompt_placeholder')}
            value={selectedPrompt}
            clearButton={false}
            onChange={onTopicChange}
            css={inputStyles(
              selectedPrompt.length > APP_CONFIG.aiPromptMaxLength,
            )}
            variant={INPUT_VARIANT.GHOST}
            validation={{
              [VALIDATION_RULE_TYPES.MAX]: {
                value: APP_CONFIG.aiPromptMaxLength,
                text: t('validation.max_char', {
                  name: 'common.value',
                  value: APP_CONFIG.aiPromptMaxLength,
                }),
              },
            }}
            multiline
          />
          {selectedPrompt.length > 0 &&
            selectedPrompt.length <= APP_CONFIG.aiPromptMaxLength && (
              <PromptStrengthBar
                inputLength={selectedPrompt.length}
                maxLength={250}
              />
            )}

          <Steps steps={5} current={1} />

          <div css={seeExampleButtonStyles} onClick={changeSeeExample}>
            {!seeExample
              ? t('create_with_ai.see_examples')
              : t('create_with_ai.hide_examples')}
          </div>

          {seeExample && (
            <div css={exampleAreaStyles}>
              <div css={aiPromptStyles}>
                {examplePrompts.map((item, index) => (
                  <div
                    css={aiSuggestionStyles(selectedPrompt == item)}
                    key={index}
                    onClick={() => updateSelectedPrompt(item)}
                  >
                    <div>{item}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div css={buttonStyles}>
          <div />
          <Button
            text={t('common.actions.lets_start')}
            theme={BUTTON_THEME.GRADIENT}
            onClick={nextButton}
            disabled={!isValid || selectedPrompt.length > 450}
          />
        </div>
      </div>
    )
  },
)

AiPrompt.displayName = 'AiPrompt'
