import React, { useCallback, useMemo, useRef, useState } from 'react'

import { ELEMENTS_BLOCK_TYPE, IElementsBlock } from './types'
import { elementsBlockStyles } from './styles'
import { CSSTransition } from 'react-transition-group'
import { useLanguage } from 'src/hooks'

export const ElementsBlock: React.FC<IElementsBlock> = React.memo(
  ({
    name,
    children,
    expandable,
    type = ELEMENTS_BLOCK_TYPE.DEFAULT,
    className,
    dataAttr,
  }) => {
    const nodeRef = useRef(null)
    const [isExpanded, setExpanded] = useState(false)
    const { t } = useLanguage()

    const renderInner = useMemo(
      () => (
        <div
          className={`items ${
            type === ELEMENTS_BLOCK_TYPE.DEFAULT && 'columns'
          } ${expandable && 'expander'}`}
          ref={nodeRef}
        >
          {children}
        </div>
      ),
      [type, children],
    )

    const onSeeAllClick = useCallback(() => {
      setExpanded(!isExpanded)
    }, [isExpanded])

    return (
      <div css={elementsBlockStyles} className={className} {...dataAttr}>
        <div className="header">
          <div>{name}</div>
          {expandable && (
            <div className="expand-button" onClick={onSeeAllClick}>
              {isExpanded
                ? t('edit.elements.see_less')
                : t('edit.elements.see_all')}
            </div>
          )}
        </div>
        {expandable ? (
          <CSSTransition nodeRef={nodeRef} in={isExpanded} timeout={100}>
            {renderInner}
          </CSSTransition>
        ) : (
          renderInner
        )}
      </div>
    )
  },
)

ElementsBlock.displayName = 'ElementsBlock'
