import { css } from '@emotion/react'

export const chartStyles = css`
  background: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: 1.5em;
`
