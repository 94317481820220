import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;

  .user-badge-area {
    margin-bottom: ${theme.spacingV2.xlarge};
  }
`
