import {
  ComponentTypes,
  ListItemImageTypes,
  ListLayoutDirections,
  ListTypes,
  Sides,
} from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentListDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const iconListDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.icon_list.display_name', { lng }),
      styles: {},
      icon: icons.icon_list,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.LIST,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 1400,
          height: 300,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          isGrid: false,
          style: {
            layout: {
              auto: true,
              division: 3,
              direction: ListLayoutDirections.HORIZONTAL,
              mediaPlace: Sides.TOP,
            },
            color: { text: { colors: ['var(--listTitle)'] } },
            colorBody: { text: { colors: ['var(--listBody)'] } },
            colorIcon: {
              text: { colors: ['var(--icon)'] },
              background: { colors: ['var(--iconBg)'] },
            },
            colorBullet: { text: { colors: ['var(--listBullet)'] } },
            font: {
              family: 'primary',
              size: '12em',
            },
            fontBody: {
              family: 'secondary',
              size: '8em',
            },
          },
          listType: ListTypes.MEDIA,
          listItems: [
            {
              id: '58e183b8-9198-4c1f-b964-c1b5193ca177',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.icon_list.title1', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.icon_list.body1', {
                lng,
              })}</p>`,
              image: {
                url: 'https://cdn.decktopus.com/icons/defaultIcon.svg',
                type: ListItemImageTypes.ICON,
                zoom: {
                  x: 0,
                  y: 0,
                  level: 1,
                },
              },
            },
            {
              id: '4adfc40e-eac8-440b-a289-2a7b5d2d4eb5',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.icon_list.title2', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.icon_list.body2', {
                lng,
              })}</p>`,
              image: {
                url: 'https://cdn.decktopus.com/icons/defaultIcon.svg',
                type: ListItemImageTypes.ICON,
                zoom: {
                  x: 0,
                  y: 0,
                  level: 1,
                },
              },
            },
            {
              id: 'c8277c51-2536-4f3f-b10c-1717087f193e',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.icon_list.title3', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.icon_list.body3', {
                lng,
              })}</p>`,
              image: {
                url: 'https://cdn.decktopus.com/icons/defaultIcon.svg',
                type: ListItemImageTypes.ICON,
                zoom: {
                  x: 0,
                  y: 0,
                  level: 1,
                },
              },
            },
          ],
        } as ComponentListDataSchema,
      },
    ],
  }
}
