import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { INPUT_THEME, Input, Switch } from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import {
  ComponentButtonDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { VALIDATION_RULE_TYPES, useDebouncer, useLanguage } from 'src/hooks'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { linkInfoStyles } from './styles'
import { IEditPropSection } from '../../types'
import { PropHeader } from 'src/pages/deck-page/components/edit-properties/props'

export const ButtonProps: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedOutline = useMemo(
      () =>
        components?.reduce(
          (a: boolean | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentButtonDataSchema).style
              .isOutlined
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleOutlineChange = useCallback(
      (isOutlined?: boolean) => {
        const partialData: DeepPartial<ComponentButtonDataSchema> = {
          style: {
            isOutlined,
          },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentButtonDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const [buttonTextValid, setButtonTextValid] = useState(false)
    const selectedButtonText = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const title = (c.data as ComponentButtonDataSchema).text
          if (a === '' && title) {
            return title
          }

          return title !== a ? '' : a
        }, ''),
      [components],
    )

    const [buttonText, setButtonText] = useState(selectedButtonText)
    const handleButtonTextChange = useCallback(
      (value?: string) => {
        setButtonText(value)
      },
      [components],
    )

    const buttonTextDebouncer = useDebouncer(
      () => {
        if (buttonTextValid) {
          updateButtonText(buttonText)
        }
      },
      { delay: 500 },
    )
    useEffect(() => {
      buttonTextDebouncer()
    }, [buttonText, buttonTextValid])

    const updateButtonText = useCallback(
      (val?: string) => {
        const partialData: DeepPartial<ComponentButtonDataSchema> = {
          text: val,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentButtonDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, buttonTextValid],
    )

    const [validUrl, setValidUrl] = useState(false)
    const selectedLinkUrl = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const title = (c.data as ComponentButtonDataSchema).linkUrl
          if (a === '' && title) {
            return title
          }

          return title !== a ? '' : a
        }, ''),
      [components],
    )

    const [urlValue, setUrlValue] = useState(selectedLinkUrl)
    const handleUrlChange = useCallback(
      (value?: string) => {
        setUrlValue(value)
      },
      [components],
    )

    const urlDebouncer = useDebouncer(
      () => {
        if (validUrl) {
          updateButtonUrl(urlValue)
        }
      },
      { delay: 500 },
    )
    useEffect(() => {
      urlDebouncer()
    }, [urlValue, validUrl])

    const updateButtonUrl = useCallback(
      (val?: string) => {
        const partialData: DeepPartial<ComponentButtonDataSchema> = {
          linkUrl: val,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentButtonDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, validUrl],
    )

    return (
      <div className="grid">
        <div className="c-full">
          <PropHeader text={t('common.button')} />
          <Input
            theme={INPUT_THEME.DARK}
            placeholder={t('edit.properties.form.header.button_text')}
            value={buttonText}
            onChange={handleButtonTextChange}
            onFocus={() => dispatch(setAllowDeleteShortcut(false))}
            onBlur={() => dispatch(setAllowDeleteShortcut(true))}
            constantValidation
            validation={{
              [VALIDATION_RULE_TYPES.REQUIRED]: {
                text: t('validation.error.required', {
                  name: t('edit.properties.form.button_text'),
                }),
              },
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  value: 3,
                  name: t('edit.properties.form.button_text'),
                }),
              },
            }}
            onValidation={setButtonTextValid}
          />
        </div>
        <div className="c-full">
          <div css={linkInfoStyles}>
            {t('edit.properties.button_props.paste_url')}
          </div>
          <Input
            theme={INPUT_THEME.DARK}
            placeholder="https://example.com"
            value={urlValue}
            onChange={handleUrlChange}
            onFocus={() => dispatch(setAllowDeleteShortcut(false))}
            onBlur={() => dispatch(setAllowDeleteShortcut(true))}
            constantValidation
            validation={{
              [VALIDATION_RULE_TYPES.REQUIRED]: {
                text: t('validation.error.required', {
                  name: t('webhook.url'),
                }),
              },
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  value: 3,
                  name: t('webhook.url'),
                }),
              },
              [VALIDATION_RULE_TYPES.VALIDURL]: {
                text: t('validation.error.url'),
              },
            }}
            onValidation={setValidUrl}
          />
        </div>
        <div className="c-full control-row">
          <span>{t('edit.properties.button_props.outlined')}</span>
          <Switch selected={selectedOutline} onChange={handleOutlineChange} />
        </div>
      </div>
    )
  },
)

ButtonProps.displayName = 'ButtonProps'
