import React from 'react'
import { IFormActions } from './types'
import { useLanguage } from 'src/hooks'
import { buttonsStyles } from './styles'

export const FormActions: React.FC<IFormActions> = React.memo(
  ({
    currentSlide,
    totalQuestionCount,
    slideChangeHandler,
    defaultTexts,
    submitText,
    isLoading,
    className,
    dataAttr,
  }) => {
    const { t } = useLanguage()

    return (
      <div className={className} {...dataAttr}>
        <div css={buttonsStyles}>
          {!!currentSlide && (
            <button
              disabled={isLoading}
              onClick={() => slideChangeHandler(currentSlide - 1, true)}
            >
              {defaultTexts.buttons.back || t('common.actions.back')}
            </button>
          )}
          {totalQuestionCount === currentSlide + 1 ? (
            <button
              disabled={isLoading}
              onClick={() => slideChangeHandler(currentSlide + 1)}
            >
              {submitText || t('common.actions.submit')}
            </button>
          ) : (
            <button
              disabled={isLoading}
              onClick={() => slideChangeHandler(currentSlide + 1)}
            >
              {defaultTexts.buttons.next || t('common.actions.next')}
            </button>
          )}
        </div>
      </div>
    )
  },
)
