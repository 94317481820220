import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid2'
import { observer } from 'mobx-react-lite'
import { useLanguage } from 'src/hooks'
import { IPolotnoComponent, SizeActionType } from '../../types'
import { useSizeStyles } from './styles'

interface ISizeActionProps extends IPolotnoComponent {
  props: SizeActionType[]
}

const SizeAction = observer<ISizeActionProps>(({ store, props }) => {
  const { t } = useLanguage()
  const element = store.selectedElements[0]

  const { classes } = useSizeStyles()

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: SizeActionType['prop'],
  ) => {
    let value = parseInt(event.target.value)
    if (value > 10000) {
      value = 10000
    } else if (value < 1) {
      value = 1
    }
    const mutation = { [type]: value }

    store.selectedElements.forEach((element) => {
      element.set({
        ...mutation,
      })
    })
  }

  if (element && store.selectedElements.length > 1) {
    return null
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={2} rowGap="24px" mt={2}>
        {props.map((prop) => {
          return (
            <Grid key={prop.prop} size={6}>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                }}
              >
                <Typography width={32} variant="caption">
                  {prop.label}
                </Typography>
                <Tooltip title={t(prop.tooltip)} arrow>
                  <TextField
                    className={classes.input}
                    slotProps={{
                      input: {
                        className: classes.input,
                      },
                    }}
                    size="small"
                    type="number"
                    value={Math.ceil((element as any)?.[prop.prop])}
                    onChange={(event) => handleTextChange(event, prop.prop)}
                  />
                </Tooltip>
              </Stack>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
})
export default SizeAction
