import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setFontsFromUrl } from 'src/store'
import { IFontUploadModal } from './types'
import { fontUploadModalStyles, contentAreaStyles } from './styles'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE, Button } from 'src/lib/button'
import { Icon, icons } from 'src/lib'
import { colors } from 'src/theme'
import {
  useLanguage,
  useConfigApi,
  useThemeApi,
  useDecksApi,
  useFlags,
} from 'src/hooks'

export const FontUploadModal: React.FC<IFontUploadModal> = React.memo(
  ({ themeCategoryId, className, dataAttr, onClose }) => {
    const { t } = useLanguage()
    const { uploadFile } = useConfigApi()
    const { createThemeFont } = useThemeApi()
    const { updateDeckThemeFont } = useDecksApi()
    const flags = useFlags()
    const dispatch = useDispatch()

    const { workspaceId, fontsFromUrl } = useSelector(
      ({ theme, workspace }: RootState) => ({
        workspaceId: workspace.id,
        fontsFromUrl: theme.fontsFromUrl,
      }),
    )

    const [primaryFontName, setPrimaryFontName] = useState<string>('')
    const [secondaryFontName, setSecondaryFontName] = useState<string>('')
    const [primaryFontUrl, setPrimaryFontUrl] = useState<string>('')
    const [secondaryFontUrl, setSecondaryFontUrl] = useState<string>('')
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [isUploadingSecond, setIsUploadingSecond] = useState<boolean>(false)

    const acceptedFileTypes = useMemo(() => '.ttf, .otf', [])

    const handlePrimaryFont = useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploading(true)
        const file = event?.target?.files?.[0]
        if (!file || !file.name) return
        setPrimaryFontName(file?.name.split('.')[0])

        const accessUrl = await uploadFile({
          file,
          extension: file?.name.split('.')[1],
        })
        setPrimaryFontUrl(accessUrl)
        setIsUploading(false)
      },
      [],
    )

    const handleSecondaryFont = useCallback(
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsUploadingSecond(true)
        const file = event?.target?.files?.[0]
        if (!file || !file.name) return
        setSecondaryFontName(file?.name.split('.')[0])

        const accessUrl = await uploadFile({
          file,
          extension: file?.name.split('.')[1],
        })
        setSecondaryFontUrl(accessUrl)
        setIsUploadingSecond(false)
      },
      [],
    )

    const handleUploadFont = useCallback(async () => {
      const data = {
        primary: primaryFontName,
        secondary: secondaryFontName,
        primaryUrl: primaryFontUrl,
        secondaryUrl: secondaryFontUrl,
      }
      if (!themeCategoryId) return
      setIsUploading(true)
      const fonts = await createThemeFont({
        params: { organizationId: workspaceId, data },
        themeCategoryId,
      })
      if (fonts) {
        const uploadedFonts = Object.values(fonts)
          ?.flatMap((values) => values)
          ?.filter((data) => data.data.primaryUrl && data.data.secondaryUrl)
        const fontFromApi = uploadedFonts.find(
          (font) =>
            font.data.primary === primaryFontName &&
            font.data.secondary === secondaryFontName,
        )
        if (fontFromApi) {
          const primary = {
            fontFamily: fontFromApi.data.primary,
            src: fontFromApi.data.primaryUrl!,
          }
          const secondary = {
            fontFamily: fontFromApi.data.secondary,
            src: fontFromApi.data.secondaryUrl!,
          }
          const uploadedFont = {
            id: fontFromApi?.id,
            primary,
            secondary,
            viewMode: fontFromApi.viewMode,
            organizationId: fontFromApi.organizationId || undefined,
          }
          dispatch(
            setFontsFromUrl(
              fontsFromUrl
                ? fontsFromUrl.concat([uploadedFont])
                : [uploadedFont],
            ),
          )
        }
        await updateDeckThemeFont({
          themeFontId: Object.values(fonts)
            ?.flatMap((schema) => schema)
            ?.find((font) => {
              return (
                font.data.primary === primaryFontName &&
                font.data.secondary === secondaryFontName
              )
            })?.id,
        })
      }
      setIsUploading(false)

      setPrimaryFontName('')
      setSecondaryFontName('')
      setPrimaryFontUrl('')
      setSecondaryFontUrl('')
      onClose && onClose()
    }, [
      primaryFontName,
      secondaryFontName,
      primaryFontUrl,
      secondaryFontUrl,
      workspaceId,
      themeCategoryId,
      flags,
      fontsFromUrl,
    ])

    return (
      <div css={fontUploadModalStyles} className={className} {...dataAttr}>
        <div className="close-button-area">
          <Button
            icon={icons.close}
            size={BUTTON_SIZE.AUTO}
            type={BUTTON_TYPE.GHOST}
            theme={BUTTON_THEME.ELEMENTS}
            iconSize={20}
            onClick={onClose}
          />
        </div>
        <div css={contentAreaStyles}>
          <span className="title-row">
            {t('edit.design.upload_custom_font')}
          </span>
          <span className="subtitle-row">
            {t('edit.design.allowed_fonts_text')}
          </span>
          <span className="responsibility-row">
            {t('edit.design.custom_font_responsibility')}
          </span>
          <label htmlFor="primaryFont" className="upload-row">
            <input
              hidden
              type="file"
              name="primaryFont"
              id="primaryFont"
              accept={acceptedFileTypes}
              onChange={handlePrimaryFont}
            />
            <Icon
              icon={icons.upload}
              color={colors.primary.DEFAULT}
              size={14}
            />
            <span>
              {primaryFontName || t('edit.design.upload_primary_font')}
            </span>
          </label>
          <label htmlFor="secondaryFont" className="upload-row">
            <input
              hidden
              type="file"
              name="secondaryFont"
              id="secondaryFont"
              accept={acceptedFileTypes}
              onChange={handleSecondaryFont}
            />
            <Icon
              icon={icons.upload}
              color={colors.primary.DEFAULT}
              size={14}
            />
            <span>
              {secondaryFontName || t('edit.design.upload_secondary_font')}
            </span>
          </label>
          <div className="button-area">
            <Button
              text={t('common.actions.complete')}
              theme={BUTTON_THEME.PRIMARY}
              onClick={handleUploadFont}
              isLoading={isUploading || isUploadingSecond}
              disabled={!primaryFontUrl.length || !secondaryFontUrl.length}
            />
          </div>
        </div>
      </div>
    )
  },
)

FontUploadModal.displayName = 'FontUploadModal'
