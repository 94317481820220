import { css, keyframes } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ISteps } from './types'

export const wrapperStyles = (theme: ITheme) => css`
  display: flex;
  width: 100%;
  gap: ${theme.gap['3xsmall']}px;
  justify-content: stretch;
  height: ${theme.dimensions.stepsHeight.DEFAULT}px;
`

const animationSpeed = 3
const delayBetween = 0.4
const glowAnimation = keyframes`
	0% {
		left: -100%;
	}

	${100 * delayBetween}% {
		left: 200%;
	}

	100% {
		left: 200%;
	}
`

type IStepStyles = Pick<ISteps, 'steps' | 'current'> & {
  index: number
}
export const stepStyles =
  ({ steps, index, current }: IStepStyles) =>
  (theme: ITheme) => css`
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    border-radius: ${theme.borderRadius[4]};

    &:before {
      position: absolute;
      content: '';
      display: block;
      height: 100%;
      width: ${steps * 100}%;
      left: -${index * 100}%;
      background: ${current > index
        ? theme.gradient.DEFAULT
        : theme.colors.background.DEFAULT};
    }

    &:after {
      content: '';
      display: block;
      z-index: 1;
      position: absolute;
      background: linear-gradient(
        96deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 48%,
        rgba(255, 255, 255, 0) 100%
      );
      left: -100%;

      width: 100%;
      height: 100%;
      opacity: 0.4;
      animation-name: ${glowAnimation};
      animation-duration: ${animationSpeed}s;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
      animation-delay: ${(animationSpeed / steps) * index * delayBetween}s;
    }
  `
