import { css } from '@emotion/react'
import { ListLayoutDirections } from 'src/types/api/enums'

interface IListStyles {
  direction?: ListLayoutDirections
}
export const timelineStyles = ({ direction }: IListStyles) => css`
  position: relative;
  width: 100%;
  height: 100%;

  .line {
    display: block;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--timelineBar);

    ${direction === ListLayoutDirections.HORIZONTAL
      ? `
				left: 2em;
				right: 2em;
				height: 2em;
				top: 20em;
			`
      : `
				flex-direction: column;
				top: 2em;
				bottom: 2em;
				width: 2em;
				left: 20em;
			`}

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      aspect-ratio: 1;
      background: var(--timelineDot);

      ${direction === ListLayoutDirections.VERTICAL
        ? `
				height: 6em;
				left: 50%;
				transform: translateX(-50%);
			`
        : `
				width: 6em;
				top: 50%;
				transform: translateY(-50%);
			`}
    }

    &:before {
      ${direction === ListLayoutDirections.VERTICAL
        ? `
				bottom: 0;
			`
        : `
				left: 0;
			`}
    }

    &:after {
      ${direction === ListLayoutDirections.VERTICAL ? `top: 0;` : `right: 0;`}
    }
  }
`
