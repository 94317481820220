import React, { useCallback, useMemo } from 'react'

import { PropDropdown } from 'src/pages/deck-page/components/edit-properties/props'
import { icons } from 'src/lib'
import { PropButton } from 'src/pages/deck-page/components/edit-properties/props'
import { TextAlignmentsVertical } from 'src/types/api/enums'
import { DeepPartial } from 'src/types'
import {
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { IEditPropSection } from '../../types'

export const PropTextVAlignment: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()

    const selectedAlignment = useMemo(
      () =>
        components?.reduce(
          (a: TextAlignmentsVertical | undefined, c: UpdateComponentSchema) => {
            const hAlign = (c.data as ComponentTextDataSchema).style.font
              .alignmentVertical
            if (a === undefined && hAlign) {
              return hAlign
            }

            return hAlign !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleAlignmentClick = useCallback(
      (value?: TextAlignmentsVertical) => {
        const partialData: DeepPartial<ComponentTextDataSchema> = {
          style: { font: { alignmentVertical: value } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTextDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const selectedAlignmentIcon = useMemo(() => {
      switch (selectedAlignment) {
        case TextAlignmentsVertical.TOP:
          return icons.text_vertical_up
        case TextAlignmentsVertical.CENTER:
          return icons.text_vertical_center
        case TextAlignmentsVertical.BOTTOM:
          return icons.text_vertical_down
        default:
          return icons.text_vertical_center
      }
    }, [selectedAlignment])

    return (
      <PropDropdown icon={selectedAlignmentIcon}>
        <>
          <PropButton
            icon={icons.text_vertical_up}
            onClick={() => handleAlignmentClick(TextAlignmentsVertical.TOP)}
            isActive={selectedAlignment === TextAlignmentsVertical.TOP}
          />
          <PropButton
            icon={icons.text_vertical_center}
            onClick={() => handleAlignmentClick(TextAlignmentsVertical.CENTER)}
            isActive={selectedAlignment === TextAlignmentsVertical.CENTER}
          />
          <PropButton
            icon={icons.text_vertical_down}
            onClick={() => handleAlignmentClick(TextAlignmentsVertical.BOTTOM)}
            isActive={selectedAlignment === TextAlignmentsVertical.BOTTOM}
          />
        </>
      </PropDropdown>
    )
  },
)

PropTextVAlignment.displayName = 'PropTextVAlignment'
