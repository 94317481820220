import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { VALIDATION_RULE_TYPES } from 'src/hooks/useValidation'
import { IWebhookForm, WEBHOOK_MODAL_STATE } from './types'
import { useWebhookApi } from 'src/hooks/api/useWebhookApi'
import {
  wrapperStyles,
  titleStyles,
  webhookInfoWrapperStyles,
  webhookGeneralInfoStyles,
  webhookLearnInfoStyles,
  createWebhookWrapperStyles,
  webhookEventFormStyles,
  webhookEventSavedStyles,
  webhookEventSavedMobilStyles,
  webhookFormWrapperStyles,
  webhookInviteWrapperStyles,
  webhookNameWrapperStyles,
  webhookURLWrapperStyles,
  webhookFormButtonWrapperStyles,
  webhookNameStyles,
  webhookURLStyles,
  webhookSavedWrapperStyles,
  webhookSavedButtonsWrapperStyles,
  webhookDeleteWrapperStyles,
  webhookDeleteWarningStyles,
  webhookDeleteButtonsWrapperStyles,
  closeWrapperStyles,
} from './styles'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  Button,
  INPUT_SIZE,
  Input,
  icons,
} from 'src/lib'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const WebhookForm: React.FC<IWebhookForm> = React.memo(
  ({
    className,
    dataAttr,
    onClickClose,
    onClickSave,
    onClickCancel,
    onClickEdit,
    onClickDelete,
    onClickCancelDelete,
  }) => {
    const [webhookModalState, setWebhookModalState] = useState(
      WEBHOOK_MODAL_STATE.DEFAULT_WEBHOOK_INFO,
    )
    const [nameValid, setNameValid] = useState(false)
    const [name, setName] = useState('')
    const [webhookURLValid, setWebhookURLValid] = useState(false)
    const [webhookURL, setWebhookURL] = useState('')
    const [webhookID, setWebhookID] = useState(-1)

    const { t, Trans } = useLanguage()
    const { getWebhooks, createWebhook, deleteWebhook, updateWebhook } =
      useWebhookApi()
    const [isFormValid, setIsFormValid] = useState(false)
    const onNameChange = (val: string) => {
      setName(val)
    }
    const onWebhookURLChange = (val: string) => {
      setWebhookURL(val)
    }
    const onWebhookSave = useCallback(() => {
      if (webhookID === -1) {
        createWebhook({ name, url: webhookURL }).then((webhookID) => {
          setWebhookID(webhookID)
        })
      } else {
        getWebhooks().then((webhooks) => {
          if (webhooks[0].name !== name || webhooks[0].url !== webhookURL) {
            updateWebhook({ name, url: webhookURL, webhookID })
          }
        })
      }

      setWebhookModalState(WEBHOOK_MODAL_STATE.SAVED_WEBHOOK_INFO)
      onClickSave && onClickSave()
    }, [name, webhookURL, webhookID])

    const onWebhookCancel = useCallback(() => {
      setWebhookModalState(WEBHOOK_MODAL_STATE.DEFAULT_WEBHOOK_INFO)
      onClickCancel && onClickCancel()
    }, [])
    const onWebhookCancelDelete = useCallback(() => {
      setWebhookModalState(WEBHOOK_MODAL_STATE.SAVED_WEBHOOK_INFO)

      onClickCancelDelete && onClickCancelDelete()
    }, [])

    const onWebhookEdit = useCallback(() => {
      setWebhookModalState(WEBHOOK_MODAL_STATE.ENTER_WEBHOOK_INFO)
      onClickEdit && onClickEdit()
    }, [])

    const openWebhookDeleteWarning = useCallback(() => {
      setWebhookModalState(WEBHOOK_MODAL_STATE.DELETE_WEBHOOK_INFO)
    }, [])

    const onWebhookDelete = useCallback(() => {
      deleteWebhook(webhookID).then((status) => {
        if (status) {
          setName('')
          setWebhookURL('')
          setWebhookID(-1)
          setWebhookModalState(WEBHOOK_MODAL_STATE.DEFAULT_WEBHOOK_INFO)
          onClickDelete && onClickDelete()
        }
      })
    }, [webhookID])

    useEffect(() => {
      setIsFormValid(nameValid && webhookURLValid)
    }, [nameValid, webhookURLValid])

    const fetchWebhooks = async () => {
      const webhooks = await getWebhooks()
      if (webhooks.length != 0) {
        setWebhookID(webhooks[0].id)
        setName(webhooks[0].name)
        setWebhookURL(webhooks[0].url)
        setWebhookModalState(WEBHOOK_MODAL_STATE.SAVED_WEBHOOK_INFO)
      }
    }
    useEffect(() => {
      fetchWebhooks()
    }, [])

    const { userName, userMail, orgs, workspaceId } = useSelector(
      ({ user, workspace }: RootState) => ({
        userName: user.data?.fullName.split(' ')[0],
        userMail: user.data?.email.split('@')[0],
        orgs: user.organizationUsers,
        workspaceId: workspace.id,
      }),
    )

    const isAdmin = useMemo(() => {
      const activeOrganization = orgs?.find(
        (org) => org.organization.id === workspaceId,
      )
      return activeOrganization && activeOrganization.isAdmin
    }, [workspaceId, orgs])

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <Button
          css={closeWrapperStyles}
          icon={icons.close}
          type={BUTTON_TYPE.GHOST}
          onClick={onClickClose}
          theme={BUTTON_THEME.GRAY}
          size={BUTTON_SIZE.SMALL}
        ></Button>
        <div css={titleStyles}>{t('webhook.webhook')}</div>

        <div css={webhookInfoWrapperStyles}>
          <div css={webhookGeneralInfoStyles}>
            {t('webhook.webhook_form_general_info')}
          </div>
          <div css={webhookLearnInfoStyles}>
            <Trans
              i18nKey={'webhook.webhook_learn'}
              components={[
                <Button
                  key="learn_link"
                  isLink
                  text={t('common.informative.click_here')}
                  theme={BUTTON_THEME.PRIMARY}
                  type={BUTTON_TYPE.GHOST}
                  onClick={() =>
                    window.open(
                      'https://help.decktopus.com/en/articles/42-decktopus-webhook-tutorial',
                    )
                  }
                />,
              ]}
            />
          </div>
        </div>
        {webhookModalState === WEBHOOK_MODAL_STATE.ENTER_WEBHOOK_INFO ? (
          <div css={webhookFormWrapperStyles}>
            <div css={webhookInviteWrapperStyles}>
              <div css={webhookNameWrapperStyles}>
                <div css={webhookNameStyles}>{t('common.name')}</div>
                <Input
                  clearButton={false}
                  size={INPUT_SIZE.SMALL}
                  placeholder={userName}
                  onChange={onNameChange}
                  value={name}
                  validation={{
                    [VALIDATION_RULE_TYPES.REQUIRED]: {
                      text: t('validation.error.required', {
                        name: t('common.name'),
                      }),
                    },
                    [VALIDATION_RULE_TYPES.MIN]: {
                      value: 3,
                      text: t('validation.min_char', {
                        name: t('common.name'),
                        value: 3,
                      }),
                    },
                  }}
                  onValidation={setNameValid}
                />
              </div>
              <div css={webhookURLWrapperStyles}>
                <div css={webhookURLStyles}>{t('webhook.endpoint_url')}</div>
                <Input
                  clearButton={false}
                  size={INPUT_SIZE.SMALL}
                  placeholder={'https://' + userMail + '.com'}
                  onChange={onWebhookURLChange}
                  validation={{
                    [VALIDATION_RULE_TYPES.REQUIRED]: {
                      text: t('validation.error.required', {
                        name: t('webhook.url'),
                      }),
                    },
                    [VALIDATION_RULE_TYPES.MIN]: {
                      value: 3,
                      text: t('validation.min_char', {
                        name: t('webhook.endpoint_url'),
                        value: 3,
                      }),
                    },
                    [VALIDATION_RULE_TYPES.VALIDURL]: {
                      text: t('validation.error.url'),
                    },
                  }}
                  onValidation={setWebhookURLValid}
                  value={webhookURL}
                />
              </div>
            </div>
            <div css={webhookEventFormStyles}>
              {t('webhook.event_form_responses')}
            </div>

            <div css={webhookFormButtonWrapperStyles}>
              <Button
                text={t('common.actions.save')}
                size={BUTTON_SIZE.LARGE}
                onClick={onWebhookSave}
                disabled={!isFormValid || !isAdmin}
              />
              <Button
                text={t('common.actions.cancel')}
                type={BUTTON_TYPE.REVERSE}
                size={BUTTON_SIZE.LARGE}
                onClick={onWebhookCancel}
              />
            </div>
          </div>
        ) : webhookModalState === WEBHOOK_MODAL_STATE.SAVED_WEBHOOK_INFO ? (
          <div>
            <div css={webhookSavedWrapperStyles}>
              <div css={webhookURLWrapperStyles}>
                <div css={webhookNameStyles}>{name}</div>
                <Input
                  clearButton={false}
                  size={INPUT_SIZE.SMALL}
                  value={webhookURL}
                  disabled={true}
                />
              </div>
              <div css={webhookEventSavedMobilStyles}>
                {t('webhook.event_form_responses')}
              </div>
              <div css={webhookSavedButtonsWrapperStyles}>
                <Button
                  text={t('common.actions.edit')}
                  size={BUTTON_SIZE.SMALL}
                  onClick={onWebhookEdit}
                  disabled={!isAdmin}
                />
                <Button
                  text={t('common.actions.delete')}
                  type={BUTTON_TYPE.REVERSE}
                  size={BUTTON_SIZE.SMALL}
                  theme={BUTTON_THEME.RED}
                  onClick={openWebhookDeleteWarning}
                  disabled={!isAdmin}
                />
              </div>
            </div>
            <div css={webhookEventSavedStyles}>
              {t('webhook.event_form_responses')}
            </div>
          </div>
        ) : webhookModalState === WEBHOOK_MODAL_STATE.DELETE_WEBHOOK_INFO ? (
          <div>
            <div css={webhookDeleteWrapperStyles}>
              <div css={webhookDeleteWarningStyles}>
                <Trans
                  i18nKey={'webhook.webhook_delete_warning'}
                  values={{ name: name }}
                />
              </div>
              <div css={webhookDeleteButtonsWrapperStyles}>
                <Button
                  text={t('common.actions.cancel')}
                  size={BUTTON_SIZE.SMALL}
                  onClick={onWebhookCancelDelete}
                  type={BUTTON_TYPE.REVERSE}
                />
                <Button
                  text={t('common.actions.delete')}
                  size={BUTTON_SIZE.SMALL}
                  theme={BUTTON_THEME.RED}
                  onClick={onWebhookDelete}
                />
              </div>
            </div>
          </div>
        ) : (
          <div css={createWebhookWrapperStyles}>
            <Button
              text={t('webhook.create_webhook')}
              icon={icons.plus}
              width={BUTTON_WIDTH.FULL}
              size={BUTTON_SIZE.LARGE}
              onClick={onWebhookEdit}
              disabled={!isAdmin}
            />
          </div>
        )}
      </div>
    )
  },
)
