import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import { StoreType } from 'polotno/model/store'
import { useLanguage } from 'src/hooks'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { svgToURL } from 'polotno/utils/svg'
import { useState } from 'react'
import getScaleFactor from 'src/helpers/getScaleFactor'
import getTextSize from 'src/helpers/getTextSize'
import { TextItemType } from '../../types'
import AutoScrollArea from '../scroll-area'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './AccordionPanelItem'
import FIGURES from './constants/figureConstants'
import { LINES } from './constants/lineConstants'
import { TextElements } from './constants/textConstants'
import { useStyles } from './styles'

const ElementsPanel = observer(({ store }: { store: StoreType }) => {
  const { t } = useLanguage()
  const { classes } = useStyles()
  const [expanded, setExpanded] = useState<string | false>()

  const handleChange = (panel: string | false) => {
    if (expanded === panel) {
      setExpanded(false)
      return
    }
    setExpanded(panel)
  }

  const fontSizeFactor = getScaleFactor(store.width)

  const handleItemClick = (element: TextItemType) => {
    const rect = getTextSize(
      t(element.label),
      element.props.fontSize * fontSizeFactor + 'px',
      '1.2',
      element.props.fontWeight.toString(),
    )

    store.activePage.addElement({
      type: 'text',
      text: '<p>' + t(element.label) + '</p>',
      ...element.props,
      fontSize: element.props.fontSize * fontSizeFactor,
      fontWeight: element.props.fontWeight.toString(),
      fontFamily: 'Sailec',
      x: (store.activePage.computedWidth - (rect.width + 64)) / 2,
      y: (store.activePage.computedHeight - rect.height) / 2,
      width: rect.width + 64,
      height: rect.height,
    })
  }

  const handleFigureClick = (figure: any) => {
    store.activePage.addElement({
      type: 'figure',
      ...figure,
      x: (store.activePage.computedWidth - 300) / 2,
      y: (store.activePage.computedHeight - 300) / 2,
      width: 300,
      height: 300,
    })
  }

  const handleLineClick = (line: any) => {
    store.activePage.addElement({
      type: 'line',
      ...line.data,
      x: store.activePage.computedWidth / 3,
      y: (store.activePage.computedHeight - 8) / 2,
      width: store.activePage.computedWidth / 3,
      height: 8,
    })
  }

  return (
    <AutoScrollArea>
      <Stack className={classes.container}>
        <Typography variant="body2" fontWeight={500}>
          {t('common.text')}
        </Typography>
        <Grid container spacing={2}>
          {TextElements.map((item) => (
            <Grid key={item.id} size={6}>
              <Button
                fullWidth
                className={classes.textButton}
                onClick={() => handleItemClick(item)}
              >
                <Typography
                  variant="body2"
                  width="100%"
                  {...item.props}
                  textAlign={item.props.textAlign as any}
                >
                  {t(item.label)}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
        <Accordion expanded={expanded === 'figures-panel'}>
          <AccordionSummary
            aria-controls="figures-panel-content"
            id="figures-panel-header"
          >
            <Stack direction="column" width="100%" spacing={2}>
              <Stack
                direction="row"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body2" fontWeight={500}>
                  {t('v3.panel.shapes_lines')}
                </Typography>
                <Button
                  variant="text"
                  className={classes.showMoreButton}
                  onClick={() => handleChange('figures-panel')}
                >
                  {expanded === 'figures-panel'
                    ? t('subscriptions.show_less')
                    : t('subscriptions.show_more')}
                </Button>
              </Stack>
              <Grid container spacing={2}>
                {FIGURES.slice(0, 4).map((figure) => (
                  <Grid key={figure.subType} size={3}>
                    <Button
                      fullWidth
                      className={classes.figureButton}
                      onClick={() => handleFigureClick(figure)}
                    >
                      <img
                        key={figure.subType}
                        width={30}
                        height={30}
                        src={svgToURL(figure.url)}
                      />
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              {LINES.map((line, index) => (
                <Grid key={index} size={3}>
                  <Button
                    fullWidth
                    className={classes.figureButton}
                    onClick={() => handleLineClick(line)}
                  >
                    <img width={30} height={30} src={line.preview} />
                  </Button>
                </Grid>
              ))}
              {FIGURES.slice(4).map((figure) => (
                <Grid key={figure.subType} size={3}>
                  <Button
                    fullWidth
                    className={classes.figureButton}
                    onClick={() => handleFigureClick(figure)}
                  >
                    <img
                      key={figure.subType}
                      width={30}
                      height={30}
                      src={svgToURL(figure.url)}
                    />
                  </Button>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Stack>
    </AutoScrollArea>
  )
})

export default ElementsPanel
