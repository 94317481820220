import { styled } from '@mui/system'
import MUITextareaAutosize from '@mui/material/TextareaAutosize'

export const TextareaAutosize = styled(MUITextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 12px;
  border-radius: 8px;
  background: rgba(0,0,0,0.02);
  border: 1px solid rgba(0,0,0,0);
  box-shadow: none;
  resize: none;

  &:hover {
    border-color: ${theme.palette.blueGrey[400]};
  }

  &:focus {
    border-color: ${theme.palette.blueGrey[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
)
