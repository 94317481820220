import React from 'react'

import { IQuillButton } from './types'
import { quillButtonStyles } from './styles'
import { Icon } from '../icon'

export const QuillButton: React.FC<IQuillButton> = React.memo(
  ({
    quillHandler,
    quillValue,
    icon,
    color,
    iconSize = 24,
    className,
    dataAttr,
  }) => {
    return (
      <div css={quillButtonStyles} className={className} {...dataAttr}>
        <button className={quillHandler} value={quillValue} />
        <Icon icon={icon} size={iconSize} color={color} />
      </div>
    )
  },
)

QuillButton.displayName = 'QuillButton'
