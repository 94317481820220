import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const dashboardFoldersStyles = css`
  width: 100%;
`

export const titleStyles = (theme: ITheme) => css`
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.colors.text.DEFAULT};
  margin-bottom: ${theme.spacingV2.large};
`

export const areaStyles = (theme: ITheme) => css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${theme.spacingV2.large};

  .area-loading {
    width: ${theme.dimensions.columnWidth.DEFAULT}px;
    display: flex;
    justify-content: center;
  }
`
