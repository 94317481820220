import React, { useCallback } from 'react'
import { useElementDefinitions, useLanguage } from 'src/hooks'
import { v4 as uuidv4 } from 'uuid'
import { INewListItem } from './types'
import { Button, icons } from 'src/lib'
import { DeepPartial } from 'src/types'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { useDispatch } from 'react-redux'
import { ComponentServices } from 'src/services'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { ListItemImageTypes } from 'src/types/api/enums'

export const NewListItem: React.FC<INewListItem> = React.memo(
  ({ components, selectedItems, onNewItemClick, selectedMediaType }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const { definitions } = useElementDefinitions()

    const addNewItem = useCallback(() => {
      const newItemIndex = selectedItems?.length || 0
      const newListItem: ComponentListDataSchema['listItems']['0'] = {
        id: uuidv4(),
        parentId: null,
        title: `<p>Item ${newItemIndex + 1}</p>`,
        body: '<p>Description</p>',
      }

      if (selectedMediaType === ListItemImageTypes.IMAGE) {
        newListItem.image = (
          definitions.image_list.schemas[0].data as ComponentListDataSchema
        ).listItems[0].image
      }

      if (selectedMediaType === ListItemImageTypes.ICON) {
        newListItem.image = (
          definitions.icon_list.schemas[0].data as ComponentListDataSchema
        ).listItems[0].image
      }

      if (selectedMediaType === ListItemImageTypes.LOGO) {
        newListItem.image = (
          definitions.logo_list.schemas[0].data as ComponentListDataSchema
        ).listItems[0].image
      }

      const partialData: DeepPartial<ComponentListDataSchema> = {
        listItems: [...(selectedItems || []), newListItem],
      }

      const updatedComponents: UpdateComponentSchema[] =
        ComponentServices.updateComponentData<ComponentListDataSchema>({
          components,
          partialData,
        })
      dispatch(componentsUpdate({ components: updatedComponents }))
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      onNewItemClick?.(newItemIndex)
    }, [components, selectedItems, selectedMediaType])

    return (
      <Button
        icon={icons.plus}
        text={t('edit.properties.list.new_list.add_new')}
        onClick={addNewItem}
      />
    )
  },
)
