import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const slideNumberStyles = ({
  colors,
  gap,
  gradient,
  borderRadius,
  zIndex,
}: ITheme) => css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  outline: none;
  z-index: ${zIndex.slideNumber};
  width: 24px;
  height: 24px;
  bottom: ${gap.xsmall}px;
  left: ${gap.xsmall}px;
  border-radius: ${borderRadius.DEFAULT};
  background: ${gradient.CARD};
  color: ${colors.white.DEFAULT};
`
