import { texts } from './texts'
import { planFeatures } from './planFeatures'
import { errors } from './errors'
import { apiEnums } from './apiEnums'
import { TranslationTypes } from '../en'

const frTranslations: TranslationTypes = {
  ...texts,
  ...planFeatures,
  ...errors,
  ...apiEnums,
}

export default frTranslations
