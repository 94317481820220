import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const propSubMenuStyles = ({ gap, colors }: ITheme) => css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: ${gap.xlarge}px;
  gap: ${gap['2xlarge']}px;

  .sub-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colors.elements.DEFAULT};
  }
`
