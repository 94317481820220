import React, { useMemo } from 'react'

import { IDevice } from './types'
import { deviceStyles } from './styles'
import { ComponentDeviceDataSchema } from 'src/types/api/requestObjects'
import { ComponentDeviceTypes } from 'src/types/api/enums'
// import { ComponentDeviceTypes } from 'src/types/api/enums'

export const Device: React.FC<IDevice> = React.memo(
  ({ data, className, dataAttr }) => {
    const componentData: ComponentDeviceDataSchema = useMemo(
      () => data.data as ComponentDeviceDataSchema,
      [data.data],
    )

    const frameClassName = useMemo(() => {
      switch (componentData.type) {
        case ComponentDeviceTypes.DESKTOP:
          return 'laptop-frame'
        case ComponentDeviceTypes.TABLET:
          return 'tablet-frame'
        case ComponentDeviceTypes.PHONE:
          return 'mobile-frame'
      }
    }, [componentData])

    return (
      <div css={deviceStyles} className={className} {...dataAttr}>
        <div
          className={`${frameClassName} media`}
          style={{ backgroundImage: `url(${componentData.imageUrl})` }}
        />
        <div className={`${frameClassName} frame`} />
      </div>
    )
  },
)

Device.displayName = 'Device'
