import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { IPropMediaSearchResults } from './types'
import { useDebouncer, useLanguage, useMediaApi } from 'src/hooks'
import {
  IconStyleColor,
  MediaTypes,
  StockMediaProviders,
  StockMediaQualities,
  IconProviders,
} from 'src/types/api/enums'
import {
  getSearchIconsResponse,
  getSearchMediaResponse,
} from 'src/types/api/responseObjects'
import {
  searchResultActionsStyles,
  searchMediaResultsColumnStyles,
  mediaItemResultsImageStyles,
  mediaItemResultsOwnerNameStyles,
  searchResultsWrapperStyles,
  searchIconResultsStyles,
  mediaItemResultsStyles,
} from './styles'
import { Button, Spinner } from 'src/lib'
import { gap } from 'src/theme'

export interface IMetaInfo {
  downloadUrl: string
  ownerUrl: string
  ownerName: string
}

export const PropMediaSearchResults: React.FC<IPropMediaSearchResults> =
  React.memo(({ searchText, currentType, onMediaUrlChange }) => {
    const { t } = useLanguage()
    const {
      isLoading,
      searchMedia,
      triggerMediaDownload,
      searchIcon,
      getIcon,
    } = useMediaApi()
    const [imageList, setImageList] =
      useState<getSearchMediaResponse['data']['mediaUrls']>()
    const [iconList, setIconList] =
      useState<getSearchIconsResponse['data']['icons']>()
    const [totalCount, setTotalCount] =
      useState<getSearchMediaResponse['data']['metadata']['total']>()
    const [chunk, setChunk] = useState(0)
    const [imageMetaList, setImageMetaList] = useState<IMetaInfo[]>()

    const chunkSize = useMemo(
      () => (currentType === MediaTypes.ICON ? 36 : 12),
      [currentType],
    )

    const runSearchMedia = useCallback(
      async (offset: number) => {
        if (searchText.length > 2) {
          if (currentType === MediaTypes.ICON) {
            const iconRes = await searchIcon({
              q: searchText,
              size: '128',
              provider: IconProviders.FREEPIK,
              offset,
              limit: chunkSize,
              styleColor: IconStyleColor.BLACK,
            })

            setTotalCount(iconRes?.metadata.total)
            if (offset === 0) {
              setIconList(iconRes?.icons)
            } else {
              setIconList((prev) =>
                prev ? [...prev, ...(iconRes?.icons || [])] : prev,
              )
            }
          } else {
            const res = await searchMedia({
              q: searchText,
              quality: StockMediaQualities.MEDIUM,
              offset,
              limit: chunkSize,
              provider:
                currentType === MediaTypes.IMAGE
                  ? StockMediaProviders.UNSPLASH
                  : StockMediaProviders.GIPHY,
            })

            setTotalCount(res?.metadata.total)

            if (offset === 0) {
              setImageList(res?.mediaUrls)
              setImageMetaList(res?.metadata.unsplash?.metaInfo)
            } else {
              setImageList((prev) =>
                prev ? [...prev, ...(res?.mediaUrls || [])] : prev,
              )
              setImageMetaList((prev) =>
                prev
                  ? [...prev, ...(res?.metadata.unsplash?.metaInfo || [])]
                  : prev,
              )
            }
          }
        }
      },
      [chunk, chunkSize, searchText, imageList, currentType],
    )

    const debounced = useDebouncer(
      () => {
        runSearchMedia(0)
      },
      { delay: 500 },
    )

    useEffect(() => {
      setImageList(undefined)
      setImageMetaList(undefined)
      setIconList(undefined)
      setTotalCount(undefined)
      setColumns([[], [], []])
      setChunk(0)
      setIsPrepared(false)
      debounced()
    }, [searchText, currentType])

    const handleLoadMore = useCallback(() => {
      setChunk(chunk + chunkSize)
      runSearchMedia(chunk + chunkSize)
      setIsPrepared(false)
    }, [chunk, chunkSize, searchText])

    const isLoadMoreVisible = useMemo(
      () =>
        (imageList || iconList) && totalCount && totalCount - chunkSize > chunk,
      [imageList, iconList, totalCount, chunk, chunkSize, isLoading],
    )
    const renderLoader = useMemo(
      () => !isLoadMoreVisible && isLoading && <Spinner size={24} />,
      [isLoading],
    )

    const handleIconClick = async (
      icon: getSearchIconsResponse['data']['icons'][0],
    ) => {
      const iconUrl = await getIcon(
        { provider: icon.provider },
        { externalId: icon.externalId },
      )
      iconUrl && onMediaUrlChange?.(iconUrl)
    }

    useEffect(() => {
      if (!searchText) {
        setImageList(undefined)
        setImageMetaList(undefined)
        setIconList(undefined)
        setTotalCount(undefined)
        setColumns([[], [], []])
        setIsPrepared(false)
        setChunk(0)
      }
    }, [searchText])

    const columnRefs = [
      useRef<HTMLDivElement>(null),
      useRef<HTMLDivElement>(null),
      useRef<HTMLDivElement>(null),
    ]
    const [columns, setColumns] = useState<
      { image: string; owner?: string; ownerUrl?: string }[][]
    >([[], [], []])

    const [isPrepared, setIsPrepared] = useState(false)
    useEffect(() => {
      // Reorder possible wrong positioned image
      const flatColumns = columns.flatMap((col) => col)
      if (
        flatColumns?.length &&
        flatColumns?.length % 12 === 0 &&
        columns[0][0]?.image &&
        imageList &&
        !isPrepared
      ) {
        // Add 500ms buffer for slow networks & possible flickers on last image only
        setTimeout(() => {
          const heights = columnRefs.map(
            (ref) => ref.current?.clientHeight ?? 0,
          )

          const smallest = heights.indexOf(Math.min(...heights))
          const highest = heights.indexOf(Math.max(...heights))
          let theImageHeight
          if (columnRefs[highest]?.current?.children?.length) {
            theImageHeight =
              columnRefs[highest]?.current?.children[
                (columnRefs[highest]?.current?.children.length as number) - 1
              ].clientHeight
          }
          if (
            theImageHeight &&
            Math.max(...heights) - theImageHeight < Math.min(...heights) + 50
          )
            return

          const reorderedCols = [...columns]
          const itemToBeMoved = columns[highest][columns[highest].length - 1]
          reorderedCols[highest].splice(reorderedCols[highest].length - 1, 1)
          reorderedCols[smallest].push(itemToBeMoved)
          setColumns(reorderedCols)
          setIsPrepared(true)
        }, 500)
      }
    }, [columns])

    useEffect(() => {
      if (imageList) {
        prepareColumns()
      }
    }, [imageList])

    const prepareColumns = useCallback(async () => {
      if (!imageList) return

      const listColumns = [...columns]
      const imageCounter = listColumns.reduce(
        (accumulator, value) => accumulator.concat(value),
        [],
      )
      if (!imageList[imageCounter.length]) return

      const image = imageList[imageCounter.length]
      const owner = imageMetaList
        ? imageMetaList[imageCounter.length].ownerName
        : undefined
      const ownerUrl = imageMetaList
        ? imageMetaList[imageCounter.length].ownerUrl
        : undefined

      const heights = columnRefs.map((ref) => ref.current?.clientHeight ?? 0)

      const smallest = heights.indexOf(Math.min(...heights))
      listColumns[smallest === -1 ? 0 : smallest].push({
        image,
        owner,
        ownerUrl,
      })

      setColumns(listColumns)
    }, [columns, imageList, imageMetaList, columnRefs])

    const imageSelectionHandler = useCallback(
      (image: string) => {
        onMediaUrlChange?.(image)
        if (currentType === MediaTypes.IMAGE) {
          triggerMediaDownload({
            downloadUrl: image,
            provider: StockMediaProviders.UNSPLASH,
          })
        }
      },
      [currentType],
    )

    const imageOwnerSelectionHandler = useCallback((ownerUrl?: string) => {
      window.open(ownerUrl, '_blank', 'noopener,noreferrer')
    }, [])

    return (
      <div css={searchResultsWrapperStyles}>
        {columns.map((column, i) => (
          <div
            css={searchMediaResultsColumnStyles}
            key={column[i] ? column[i].image : 'column' + i}
            ref={columnRefs[i]}
          >
            {column.map(
              (media: { image: string; owner?: string; ownerUrl?: string }) => {
                if (!media) return
                return (
                  <div
                    css={mediaItemResultsStyles}
                    key={media.image}
                    style={{
                      marginBottom:
                        currentType === MediaTypes.IMAGE
                          ? `${gap.small}px`
                          : '0',
                    }}
                  >
                    <img
                      onLoad={prepareColumns}
                      css={mediaItemResultsImageStyles}
                      src={media.image}
                      onClick={() => imageSelectionHandler(media.image)}
                    />
                    {currentType === MediaTypes.IMAGE && (
                      <div
                        onClick={() =>
                          imageOwnerSelectionHandler(media.ownerUrl)
                        }
                        css={mediaItemResultsOwnerNameStyles}
                      >
                        {media.owner}
                      </div>
                    )}
                  </div>
                )
              },
            )}
          </div>
        ))}
        {iconList && (
          <div css={searchIconResultsStyles}>
            {iconList.map((icon) => (
              <div
                className="icon-item"
                key={icon.externalId}
                onClick={() => handleIconClick(icon)}
                style={{ backgroundImage: `url(${icon.icon})` }}
              />
            ))}
          </div>
        )}
        <div css={searchResultActionsStyles}>
          {renderLoader}
          {isLoadMoreVisible ? (
            <Button
              text={t('edit.media.modal.load_more')}
              onClick={handleLoadMore}
              isLoading={isLoading}
              disabled={isLoading}
            />
          ) : null}
        </div>
      </div>
    )
  })

PropMediaSearchResults.displayName = 'PropMediaSearchResults'
