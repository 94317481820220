import { useState } from 'react'
import { useSelector } from 'react-redux'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { RootState } from 'src/store'
import { AssetTypes } from 'src/types/api/enums'
import { postCreateAssetBody } from 'src/types/api/requestObjects'
import {
  defaultResponseSchema,
  getAssetsResponse,
} from 'src/types/api/responseObjects'

interface IUseDecksV3ApiReturn {
  createAsset: (data: postCreateAssetBody) => Promise<boolean>
  getAssets: (
    page: string,
    types: AssetTypes[],
  ) => Promise<getAssetsResponse['data']>
  isLoading: boolean
}

export const useAssetsApi = (): IUseDecksV3ApiReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { organizationId } = useSelector(({ workspace }: RootState) => ({
    organizationId: workspace.id,
  }))

  const createAsset = async (data: postCreateAssetBody) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<defaultResponseSchema, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: API_CONFIG.ASSETS,
          data: { ...data, organizationId },
        })

      return res.data.code === 200
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }
  const getAssets = async (page: string, types: AssetTypes[]) => {
    const res: RequestResponse<getAssetsResponse, any> =
      await RequestServices.callApi({
        method: 'GET',
        url: API_CONFIG.ASSETS,
        params: {
          page,
          organizationId,
          sharedOnOrg: !!organizationId,
          own: true,
          predefined: false,
          types,
          limit: 20,
        },
      })
    return res.data.data
  }

  return { createAsset, getAssets, isLoading }
}
