import { deckIdSlideIdSlideDataIdParams } from 'src/types/api/requestObjects'

export const API_CONFIG = {
  CONFIG_TYPE: '/config/typeFile/enums',
  CONFIG_UPLOAD: '/config/upload',

  AUTH_LOGIN: '/auth/login',
  AUTH_REFRESH: '/auth/refresh',
  AUTH_SIGNUP: '/auth/register',
  AUTH_RESET: '/auth/password/reset',
  AUTH_RESET_EMAIL: '/auth/password/reset/email',

  USER: '/user',
  USER_PORTAL_URL: '/user/portal',
  USER_AICREDITS_TOTAL: '/user/aicredits/total',
  USER_AICREDITS_HISTORY_SPEND_SUMMARY: '/user/aicredits/history/spend/summary',
  USER_VERIFICATION: '/user/verification',
  USER_VERIFICATION_EMAIL: '/user/verification/email',
  USER_ORGANIZATIONS: '/user/organizations',
  USER_INVITES: '/user/invites',
  USER_REFER: '/user/refer',

  AI: '/ai',
  AI_IMAGE: '/ai/image',
  AI_EDIT_TEXT: '/ai/edit/text',
  AI_UPDATE_DECK_PROMPT: '/ai/theme',

  ORGANIZATION: '/organization',
  ORGANIZATION_USERS: (id: string) => `/organization/${id}/users`,
  ORGANIZATION_USERS_UPDATE: (id: string, userId: string) =>
    `/organization/${id}/users/${userId}`,
  ORGANIZATION_USERS_DELETE: (id: string, userId: string) =>
    `/organization/${id}/users/${userId}`,
  ORGANIZATION_INVITE: (id: string) => `/organization/${id}/invite`,
  ORGANIZATION_INVITE_RESPOND: ({
    orgId,
    inviteId,
  }: {
    orgId: string
    inviteId: string
  }) => `/organization/${orgId}/invites/${inviteId}`,
  ORGANIZATION_INVITE_DELETE: (id: string, inviteId: string) =>
    `/organization/${id}/invites/${inviteId}`,
  ORGANIZATION_ACTIVATE: (id: string) => `/organization/${id}/activate`,

  FOLDERS: '/folders',

  PLAN_POSSIBLE: '/plan/possible',
  PLAN_PURCHASE: '/plan/purchase',
  PLAN_APPlY_PROMO_CANCEL: '/plan/apply/promo/cancel',
  PLAN_CANCEL: '/plan/cancel',

  THEME_CATEGORIES: '/themes/categories',

  DECKS: '/decks',
  DECK: (id: string) => `/decks/${id}`,
  DECK_MOVE: (id: string) => `/decks/${id}/move`,
  DECK_MIGRATE: (id: string) => `/decks/${id}/migrate`,
  DECK_DUPLICATE: (id: string) => `/decks/${id}/duplicate`,
  DECK_SHARE: (id: string) => `/decks/${id}/share`,
  DECK_ANALYTICS: (id: string) => `/decks/${id}/analytics`,
  DECK_FORM_RESPONSES: (id: string) => `/decks/${id}/responses`,
  DECK_INTIVE: (deckId: string) => `/decks/${deckId}/invite`,
  DELETE_DECK_INTIVE: (deckId: string, inviteId: string) =>
    `/decks/${deckId}/invites/${inviteId}`,
  UPDATE_DECK_INTIVE: (deckId: string, inviteId: string) =>
    `/decks/${deckId}/invites/${inviteId}`,
  DELETE_DECK_COLLABRATOR: (deckId: string, userId: string) =>
    `/decks/${deckId}/users/${userId}`,
  UPDATE_DECK_COLLABRATOR_PERMISSION: (deckId: string, userId: string) =>
    `/decks/${deckId}/users/${userId}`,
  DECK_PENDING_INVITES: (deckId: string) => `/decks/${deckId}/invites`,
  DECK_DATA_UPDATE: (id: string) => `/decks/${id}/data`,
  DECK_THUMBNAIL_UPDATE: (id: string) => `/decks/${id}/thumbnail`,
  DECK_INVITE_RESPOND: ({
    deckId,
    inviteId,
  }: {
    deckId: string
    inviteId: string
  }) => `/decks/${deckId}/invites/${inviteId}`,
  DECK_VISIT: (deckId: string) => `/public/decks/${deckId}/visit`,
  DECK_RESPONSE: (deckId: string) => `/public/decks/${deckId}/response`,
  DECKS_PIVOTS: '/decks/pivots',
  DECK_PUBLISH_PIVOT: (id: string) => `/admin/decks/${id}/pivot`,

  SLIDE_DATA: ({
    deckId,
    slideId,
    slideDataId,
  }: deckIdSlideIdSlideDataIdParams) =>
    `/decks/${deckId}/slides/${slideId}/data/${slideDataId}`,

  LAYOUTS: '/slides/layouts',

  MEDIA: '/media',
  MEDIA_TRENDS: '/media/trends',
  MEDIA_TRIGGER_DOWNLOAD: '/media/download',
  ICONS: '/icons',
  ICONS_TRENDS: '/icons/trends',
  GET_ICON: (externalId: string) => `/icons/${externalId}`,

  PUBLIC_DECK_DATA: (shareKey: string) => `/public/decks/${shareKey}`,
  PUBLIC_SLIDE_DATA: ({
    deckId,
    slideId,
    slideDataId,
  }: deckIdSlideIdSlideDataIdParams) =>
    `/public/decks/${deckId}/slides/${slideId}/data/${slideDataId}`,

  THEMES_FONTS: '/themes/fonts',
  DELETE_THEMES_FONTS: (themeFontId: string) => `/themes/fonts/${themeFontId}`,
  THEMES_COLORS: '/themes/colors',
  DELETE_THEMES_COLORS: (themeColorId: string) =>
    `/themes/colors/${themeColorId}`,

  ADMIN_USERS: '/admin/users',
  ADMIN_DELETE_USER: (userId: string) => `/admin/users/${userId}`,
  ADMIN_LOGIN_AS_USER: `/admin/users/token`,

  PUBLIC_DECK_V3_DOCUMENT: (shareKey: string) =>
    `/public/decks/v3/${shareKey}/document`,
  DECK_V3_DOCUMENT: (deckId: number) => `/decks/v3/${deckId}/document`,
  DECK_V3_SYNC_SLIDES: (deckId: number) => `/decks/v3/${deckId}/sync/slides`,
  DECK_V3_SAVE_SLIDE_NOTE: (deckId: number) =>
    `/decks/v3/${deckId}/save/slide/note`,
  DECK_V3_THEMES: '/themes/v3',
  DECK_V3_THEME_DETAILS: (themeId: string) => `/themes/v3/${themeId}`,
  DECK_V3_THEME_LAYOUT: (themeLayoutId: string) =>
    `/themes/v3/layouts/${themeLayoutId}`,
  ASSETS: '/assets',
}
