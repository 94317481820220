import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const slideMenuStyles = ({
  colors,
  gap,
  gradient,
  borderRadius,
  zIndex,
}: ITheme) => css`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  outline: none;
  height: 20px;
  width: 38px;
  z-index: ${zIndex.slideNumber};
  top: ${gap.xsmall}px;
  right: ${gap.xsmall}px;
  border-radius: ${borderRadius[10]};
  background: ${gradient.CARD};
  color: ${colors.white.DEFAULT};
`
