import React, { useMemo } from 'react'

import { IList } from './types'
import { GridView, ListView, TimelineView } from './components'
import { listStyles } from './styles'
import { ComponentListDataSchema } from 'src/types/api/requestObjects'
import { ListTypes } from 'src/types/api/enums'

export const List: React.FC<IList> = React.memo(
  ({ data, overrides, canvasType, scale, slideDataId }) => {
    const componentData = useMemo(
      () => data.data as ComponentListDataSchema,
      [data],
    )

    const renderView = useMemo(() => {
      if (componentData.listType === ListTypes.TIMELINE) {
        return <TimelineView data={data} overrides={overrides} />
      }

      if (componentData.isGrid) {
        return <GridView data={data} overrides={overrides} />
      }

      return (
        <div css={listStyles}>
          <ListView
            data={data}
            overrides={overrides}
            canvasType={canvasType}
            scale={scale}
            slideDataId={slideDataId}
          />
        </div>
      )
    }, [slideDataId, componentData, data, overrides, scale])

    return renderView
  },
)

List.displayName = 'List'
