export const planFeatures = {
  'plan_features.pro.monthly.title': 'AI Kredileri 750 / ay',
  'plan_features.pro.monthly.1': 'PDF / PPT Dışa Aktarımı',
  'plan_features.pro.monthly.2': 'Sunum Analitikleri',
  'plan_features.pro.monthly.3': 'Paylaşım Bağlantısı',
  'plan_features.pro.monthly.4': 'Form Yanıtı E-posta Bildirimleri',
  'plan_features.pro.monthly.5': 'PDF İçe Aktarımı',
  'plan_features.pro.monthly.6': 'AI Resim Üretimi',
  'plan_features.pro.monthly.7': 'Slayt & Konuşmacı Notları',
  'plan_features.pro.monthly.8': 'İpuçları & QA Üretimi',
  'plan_features.pro.annual.title': 'AI Kredileri 750 / ay',
  'plan_features.pro.annual.1': 'PDF / PPT Dışa Aktarımı',
  'plan_features.pro.annual.2': 'Sunum Analitikleri',
  'plan_features.pro.annual.3': 'Paylaşım Bağlantısı',
  'plan_features.pro.annual.4': 'Form Yanıtı E-posta Bildirimleri',
  'plan_features.pro.annual.5': 'PDF İçe Aktarımı',
  'plan_features.pro.annual.6': 'AI Resim Üretimi',
  'plan_features.pro.annual.7': 'Slayt & Konuşmacı Notları',
  'plan_features.pro.annual.8': 'İpuçları & QA Üretimi',
  'plan_features.business.monthly.title': 'AI Kredileri 1000 / ay',
  'plan_features.business.monthly.1': 'Pro AI Özellikleri +',
  'plan_features.business.monthly.2': 'Özel Yazı Tipi Yükleme',
  'plan_features.business.monthly.3': 'Altbilgi Notları ve Logolar',
  'plan_features.business.monthly.4': 'Gelişmiş Form Yanıtı',
  'plan_features.business.monthly.5': 'Form Yanıtlarını CSV Olarak Dışa Aktar',
  'plan_features.business.monthly.6': 'Webhook',
  'plan_features.business.monthly.7': 'Özel Alan Adı Bağlantısı (CNAME)',
  'plan_features.business.monthly.8': 'Takımlar & Organizasyon',
  'plan_features.business.annual.title': 'AI Kredileri 1000 / ay',
  'plan_features.business.annual.1': 'Pro AI Özellikleri +',
  'plan_features.business.annual.2': 'Özel Yazı Tipi Yükleme',
  'plan_features.business.annual.3': 'Altbilgi Notları ve Logolar',
  'plan_features.business.annual.4': 'Gelişmiş Form Yanıtı',
  'plan_features.business.annual.5': 'Form Yanıtlarını CSV Olarak Dışa Aktar',
  'plan_features.business.annual.6': 'Webhook',
  'plan_features.business.annual.7': 'Özel Alan Adı Bağlantısı (CNAME)',
  'plan_features.business.annual.8': 'Takımlar & Organizasyon',
}
