import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const workspaceBadgeStyles = ({
  dimensions,
  gap,
  breakpointsV2: breakpoints,
}: ITheme) => css`
  max-width: ${dimensions.menuWidth.DEFAULT}px;
  width: max-content;
  height: ${dimensions.menuHeight.small}px;
  padding: ${gap.xsmall}px ${gap.small}px;
  gap: ${gap.xsmall}px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (min-width: ${breakpoints.laptop}px) {
    width: ${dimensions.menuWidth.DEFAULT}px;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    width: auto;
  }
`

export const orgNameStyles = ({
  dimensions,
  fontSize,
  fontWeight,
  breakpointsV2: breakpoints,
}: ITheme) => css`
  max-width: ${dimensions.menuWidth.xsmall}px;
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: none;

  @media (min-width: ${breakpoints.tablet}px) {
    display: block;
  }

  @media (min-width: ${breakpoints.laptop}px) {
    max-width: ${dimensions.menuWidth.medium}px;
  }
`

export const menuStyles = ({ gap, boxShadow, dimensions }: ITheme) => css`
  position: fixed;
  margin-top: -${gap['3xsmall']}px;
  box-shadow: ${boxShadow[1]};
  min-width: ${dimensions.menuWidth.small};
`

export const menuItemStyles =
  (isAdmin: boolean) =>
  ({ dimensions, gap }: ITheme) => css`
    ${isAdmin ? `background-color: #edefff;` : ` background-color: #FFFFFF;`}
    max-width: ${dimensions.menuWidth.DEFAULT}px;
    height: ${dimensions.menuHeight.DEFAULT}px;
    padding: ${gap.xsmall}px ${gap.small}px;
    gap: ${gap.xsmall}px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
  `

export const nameWidthStyles = ({ dimensions, fontWeight }: ITheme) => css`
  max-width: ${dimensions.menuWidth.large}px;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: ${fontWeight.medium};
`

export const nameStyles = () => css`
  display: flex;
  flex-direction: column;
`

export const statusStyles = ({ colors }: ITheme) => css`
  color: ${colors.text[2]};
`

export const lineStyles = ({ colors }: ITheme) => css`
  background-color: ${colors.outline.DEFAULT};
  width: 90%;
  height: 1px;
  margin: 0 5%;
`
