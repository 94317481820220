import { NewComponentSchema } from 'src/types/api/requestObjects'
import { ComponentSchema } from 'src/types/api/responseObjects'
import { v4 as uuidv4 } from 'uuid'

export const duplicateComponentData = (
  data: ComponentSchema,
): NewComponentSchema => {
  return {
    type: data.type,
    style: data.style,
    isLocked: data.isLocked,
    data: data.data,
    tempId: uuidv4(),
    positions: {
      ...data.positions,
      x: data.positions.x + 50,
      y: data.positions.y + 50,
    },
  }
}
