import React, { useCallback, useMemo } from 'react'
import { listLogoStyles, listMediaStyles } from './styles'
import { PropAiImage, PropMedia } from '../../props'
import { ListItemImageTypes, MediaTypes } from 'src/types/api/enums'
import { IListMedia } from './types'
import { DeepPartial } from 'src/types'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { SvgMasked } from 'src/lib/svg-masked'
import { useTheme } from '@emotion/react'
import { useLanguage } from 'src/hooks'
import { Tooltip, icons } from 'src/lib'

const mapMediaToListImageType: Partial<Record<MediaTypes, ListItemImageTypes>> =
  {
    [MediaTypes.IMAGE]: ListItemImageTypes.IMAGE,
    [MediaTypes.ICON]: ListItemImageTypes.ICON,
  }

const mapListImageToMediaType: Partial<Record<ListItemImageTypes, MediaTypes>> =
  {
    [ListItemImageTypes.IMAGE]: MediaTypes.IMAGE,
    [ListItemImageTypes.ICON]: MediaTypes.ICON,
  }

export const ListMedia: React.FC<IListMedia> = React.memo(
  ({ data, index, components, listItems, isGrid }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const { colors } = useTheme()

    const isLogo = useMemo(
      () => data.image?.type === ListItemImageTypes.LOGO,
      [data.image],
    )
    const onMediaChange = useCallback(
      (mediaType: MediaTypes, mediaUrl: string) => {
        if (!listItems) {
          return
        }
        const newListItems: typeof listItems = JSON.parse(
          JSON.stringify(listItems),
        )

        newListItems[index].image = {
          zoom: { x: 0, y: 0, level: 0 },
          url: mediaUrl,
          type: mapMediaToListImageType[mediaType] || ListItemImageTypes.IMAGE,
        }
        const partialData: DeepPartial<ComponentListDataSchema> = {
          listItems: newListItems,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentListDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const onAiMediaChange = useCallback(
      (url: string) => {
        onMediaChange(MediaTypes.IMAGE, url)
      },
      [onMediaChange],
    )

    const mediaTypeName = useMemo(() => {
      switch (data.image?.type) {
        case ListItemImageTypes.IMAGE:
          return t('common.files.image')
        case ListItemImageTypes.ICON:
          return t('common.files.icon')

        default:
          return t('common.files.media')
      }
    }, [data])

    if (isLogo) {
      return (
        <div css={listLogoStyles}>
          <div className="logo-preview">
            <div
              className="image-preview"
              style={{ backgroundImage: `url(${data.image?.url})` }}
            />
          </div>
          <PropMedia
            icon={icons.search_in_list}
            label={t('edit.media.modal.search_media', { type: mediaTypeName })}
            directUpload
            selectedType={MediaTypes.LOGO}
            availableTypes={[MediaTypes.LOGO]}
            onChange={onMediaChange}
          />
        </div>
      )
    }

    return (
      <div css={listMediaStyles}>
        <div className="media-preview">
          {data.image?.type === ListItemImageTypes.ICON ? (
            <SvgMasked url={data.image.url} color={colors.white.DEFAULT} />
          ) : (
            <div
              className="image-preview"
              style={{ backgroundImage: `url(${data.image?.url})` }}
            />
          )}
        </div>
        <div className="media-buttons">
          <PropMedia
            icon={icons.search_in_list}
            label={t('edit.media.modal.search_media', { type: mediaTypeName })}
            selectedType={
              mapListImageToMediaType[
                data.image?.type || ListItemImageTypes.IMAGE
              ] || MediaTypes.IMAGE
            }
            availableTypes={[
              mapListImageToMediaType[
                data.image?.type || ListItemImageTypes.IMAGE
              ] || MediaTypes.IMAGE,
              ...(isGrid ? [MediaTypes.GIF] : []),
            ]}
            onChange={onMediaChange}
          />
          {data.image?.type === ListItemImageTypes.ICON ? (
            <Tooltip text={t('common.informative.coming_soon')}>
              <div>
                <PropAiImage onChange={onAiMediaChange} disabled />
              </div>
            </Tooltip>
          ) : (
            <PropAiImage onChange={onAiMediaChange} />
          )}
        </div>
      </div>
    )
  },
)
