import Button from '@mui/material/Button'
import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import { IPolotnoComponent } from '../../types'
import { useLanguage } from 'src/hooks'

const EffectsAction = observer<IPolotnoComponent>(({ store }) => {
  const { t } = useLanguage()
  const allTextItems = store.selectedElements?.every(
    (element) => element.type === 'text',
  )

  const allFigures = store.selectedElements?.every(
    (element) => element.type === 'figure',
  )

  const allImages = store.selectedElements?.every(
    (element) => element.type === 'image',
  )

  const allSvgs = store.selectedElements?.every(
    (element) => element.type === 'svg',
  )

  const handleClick = () => {
    if (store.openedSidePanel === 'effects') {
      store.openSidePanel('')
      return
    }
    store.openSidePanel('effects')
  }

  return allTextItems || allFigures || allImages || allSvgs ? (
    <Button
      size="small"
      variant="outlined"
      disableElevation
      startIcon={<MaterialSymbol icon="contrast" size={16} weight={100} />}
      onClick={handleClick}
      sx={{
        flexShrink: 0,
      }}
    >
      {t('v3.effects')}
    </Button>
  ) : null
})

export default EffectsAction
