import { Alignment, Navbar } from '@blueprintjs/core'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'
import { observer } from 'mobx-react-lite'
import { LineElementType } from 'polotno/model/line-model'
import { StoreType } from 'polotno/model/store'
import React, { useEffect, useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import StyledToggleButton from 'src/components/toggle-button'
import StyledToggleButtonGroup from 'src/components/toggle-button-group'
import arrowCircle from '../../../../assets/images/arrowCircle.svg'
import arrowClosed from '../../../../assets/images/arrowClosed.svg'
import arrowClosedFilled from '../../../../assets/images/arrowClosedFilled.svg'
import arrowFilled from '../../../../assets/images/arrowFilled.svg'
import arrowHead from '../../../../assets/images/arrowHead.svg'
import singleLine from '../../../../assets/images/singleLine.svg'
import { useLineSettingsStyles } from './styles'
import { LineEdge, LineEdgeEnum } from '../../types'

const LineIcons = [
  singleLine,
  arrowHead,
  arrowFilled,
  arrowClosed,
  arrowClosedFilled,
  arrowCircle,
]

const LineEdgesAction = observer(({ store }: { store: StoreType }) => {
  const hasLockedItems = store.selectedElements?.some(
    (element) => element.locked,
  )

  const allLines = store.selectedElements?.every(
    (element) => element.type === 'line',
  )

  const element = store.selectedElements?.[0] as LineElementType

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [edge, setEdge] = useState<LineEdge>()
  const [lineEdge, setLineEdge] = React.useState<string>('')

  const { classes } = useLineSettingsStyles()

  useEffect(() => {
    if (edge === 'start') {
      setLineEdge(element.startHead)
    } else if (edge === 'end') {
      setLineEdge(element.endHead)
    }
  }, [edge, lineEdge])

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    edge: LineEdge,
  ) => {
    setAnchorEl(event.currentTarget)
    setEdge(edge)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setTimeout(() => {
      setEdge(undefined)
    }, 250)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'line-edges-popover' : undefined

  const handleLineEdge = (
    event: React.MouseEvent<HTMLElement>,
    newLineEdge: LineEdgeEnum | undefined,
  ) => {
    if (!newLineEdge) {
      return
    }
    setLineEdge(newLineEdge)
    const updateItem: any = {}
    if (edge === 'start') {
      updateItem.startHead = newLineEdge
    } else {
      updateItem.endHead = newLineEdge
    }
    store.selectedElements.forEach((element) => {
      element.set({
        ...updateItem,
      })
    })
  }

  const Arrows = Object.values(LineEdgeEnum).map((item, index) => {
    return (
      <ToggleButton
        key={item}
        value={item}
        aria-label="arrow"
        className={classes.button}
      >
        <img
          src={LineIcons[index]}
          className="imgClass"
          style={{
            rotate: edge === 'end' ? '0deg' : '-180deg',
          }}
          alt="arrow"
        />
      </ToggleButton>
    )
  })

  return allLines && !hasLockedItems ? (
    <>
      <Navbar.Group align={Alignment.LEFT} className={classes.container}>
        <StyledToggleButton
          selected={edge === 'start'}
          value={'start'}
          onClick={(e) => handleClick(e, 'start')}
        >
          <MaterialSymbol icon={'line_start'} size={20} weight={400} />
        </StyledToggleButton>
        <StyledToggleButton
          selected={edge === 'end'}
          value={'end'}
          onClick={(e) => handleClick(e, 'end')}
        >
          <MaterialSymbol icon={'line_end'} size={20} weight={400} />
        </StyledToggleButton>
      </Navbar.Group>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 48,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={32}
        elevation={6}
        classes={{
          paper: classes.container,
        }}
      >
        <Stack sx={{ p: 3 }}>
          <StyledToggleButtonGroup
            size="small"
            value={lineEdge}
            onChange={handleLineEdge}
            aria-label="arrow head selection"
            exclusive
            sx={{
              'button:first-of-type': {
                marginLeft: 0,
              },
            }}
          >
            {Arrows}
          </StyledToggleButtonGroup>
        </Stack>
      </Popover>
    </>
  ) : null
})
export default LineEdgesAction
