import { DeckData_DataSchema } from 'src/types/api/requestObjects'

export const slideOrderRevise = (
  slides: DeckData_DataSchema['slides'],
): DeckData_DataSchema['slides'] => {
  const slidesDeleted = slides.filter(({ isDeleted }) => isDeleted)
  const slidesNotDeleted = slides.filter(({ isDeleted }) => !isDeleted)
  return [
    ...slidesNotDeleted
      .sort((a, b) => a.orderIndex - b.orderIndex)
      .map((slide, index) => ({
        ...slide,
        orderIndex: index + 1,
      })),
    ...slidesDeleted,
  ]
}
