import React from 'react'
import { Avatar, Icon } from 'src/lib'
import { ICardFooter } from './types'
import {
  cardFooterWrapperStyles,
  cardFooterLeftStyles,
  cardFooterRightStyles,
  cardFooterBulletStyles,
  cardFooterFolderNameStyles,
  slideTextStyles,
} from './styles'
import { CARD_TYPE } from '../../types'
import { useTheme } from 'src/theme'

export const CardFooter: React.FC<ICardFooter> = React.memo(
  ({ type, icon, text, textInfo, avatar }) => {
    const theme = useTheme()

    if (type === CARD_TYPE.SLIDE) {
      return <span css={slideTextStyles}>{text}</span>
    }

    return (
      <div css={cardFooterWrapperStyles({ type })}>
        <div css={cardFooterLeftStyles}>
          {icon && <Icon icon={icon} size={16} color={theme.colors.text[2]} />}
          <div css={cardFooterFolderNameStyles}>{text}</div>
          {icon && <span css={cardFooterBulletStyles} />}
          <div>{textInfo}</div>
        </div>
        <div css={cardFooterRightStyles}>
          <div>{avatar && <Avatar size={28} data={avatar} />}</div>
        </div>
      </div>
    )
  },
)
