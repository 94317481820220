import React, { useCallback } from 'react'
import { observer } from 'mobx-react-lite'

import { initPageStyles } from './styles'

import emptyStateImage from 'src/assets/images/init-empty-state.svg'
import { Button, icons } from 'src/lib'
import { StoreType } from 'polotno/model/store'
import { useLanguage } from 'src/hooks'

export const NoPages = observer<{ store: StoreType }>(({ store }) => {
  const { t } = useLanguage()
  const handleOnClick = useCallback(() => {
    store.addPage()
  }, [])

  return (
    <div css={initPageStyles}>
      <img src={emptyStateImage} alt="" />
      <Button
        icon={icons.plus}
        text={t('common.deck.add_slide')}
        onClick={handleOnClick}
      />
    </div>
  )
})
