import React from 'react'
import { IAwardItem } from '../types'
import awardIcon from 'src/assets/images/award.svg'
import starIcon from 'src/assets/images/star.svg'
import {
  bottomStyles,
  starsStyles,
  topStyles,
  wrapperStyles,
  awardRateStyles,
} from './styles'

export const AwardItem: React.FC<IAwardItem> = React.memo(({ text, rate }) => {
  return (
    <div css={wrapperStyles}>
      <div css={topStyles}>
        <img src={awardIcon} alt="award" />
        <span>|</span>
        <span>{text}</span>
      </div>
      <div css={bottomStyles}>
        <span css={awardRateStyles}>{rate}</span>
        {
          <div css={starsStyles}>
            {[...Array(5)].map((_x, i) => (
              <img key={i} src={starIcon} alt="star" />
            ))}
          </div>
        }
      </div>
    </div>
  )
})
