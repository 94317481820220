import {
  ComponentTypes,
  ListLayoutDirections,
  ListTypes,
  Sides,
} from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentListDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const timelineDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.timeline.display_name', { lng }),
      styles: {},
      icon: icons.timeline,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.LIST,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 800,
          height: 400,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          isGrid: false,
          style: {
            layout: {
              auto: true,
              division: 3,
              direction: ListLayoutDirections.HORIZONTAL,
              mediaPlace: Sides.TOP,
            },
            color: { text: { colors: ['var(--listTitle)'] } },
            colorBody: { text: { colors: ['var(--listBody)'] } },
            colorIcon: { text: { colors: ['var(--listTitle)'] } },
            colorBullet: { text: { colors: ['var(--listBullet)'] } },
            font: {
              family: 'primary',
              size: '12em',
            },
            fontBody: {
              family: 'secondary',
              size: '8em',
            },
          },
          listType: ListTypes.TIMELINE,
          listItems: [
            {
              id: '58df05ea-e94f-46a3-a917-7a7bfa63d771',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.timeline.item1', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.timeline.description1', {
                lng,
              })}</p>`,
            },
            {
              id: '58df05ea-e94f-46a3-a917-7a7bfa63d772',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.timeline.item2', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.timeline.description2', {
                lng,
              })}</p>`,
            },
            {
              id: '58df05ea-e94f-46a3-a917-7a7bfa63d773',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.timeline.item3', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.timeline.description3', {
                lng,
              })}</p>`,
            },
          ],
        } as ComponentListDataSchema,
      },
    ],
  }
}
