import { observer } from 'mobx-react-lite'
import ColorPickerElement from './ColorPickerElement'
import { IPolotnoComponent } from '../../types'
import { useLanguage } from 'src/hooks'

interface IElementFillActionsProps extends IPolotnoComponent {
  types: string[]
}

const ElementFillActions = observer<IElementFillActionsProps>(
  ({ store, types }) => {
    const { t } = useLanguage()
    const allElements = store.selectedElements?.every((element) =>
      types.includes(element.type),
    )
    const prop = types.includes('line') ? 'color' : 'fill'
    const label = types.includes('line') ? undefined : t('v3.fill')

    return allElements ? (
      <ColorPickerElement store={store} prop={prop} label={label} />
    ) : null
  },
)
export default ElementFillActions
