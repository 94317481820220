import { useCallback } from 'react'
import { APP_CONFIG } from '../../config'

type convertFunction = (rotation: number) => {
  x1: string
  x2: string
  y1: string
  y2: string
}

export const useRotationToCoords = () => {
  const convert = useCallback<convertFunction>((rotation) => {
    if (typeof rotation !== 'number') {
      // If rotation is not a number, return default coordinates
      return { x1: '0', x2: '0', y1: '0', y2: '0' }
    }

    // Ensure angle is within 0 to 359 degrees
    const angle = ((rotation % 360) + 360) % 360

    // Calculate coordinates based on angle
    const canvasWidth = APP_CONFIG.canvasMasterWidth
    const canvasHeight = APP_CONFIG.canvasMasterWidth / APP_CONFIG.canvasRatio

    if (angle === 0) {
      // Horizontal gradient
      return { x1: '0', y1: '0', x2: `${canvasWidth}`, y2: '0' }
    } else if (angle === 90) {
      // Vertical gradient
      return { x1: '0', y1: '0', x2: '0', y2: `${canvasHeight}` }
    } else if (angle === 180) {
      // Horizontal gradient (reverse)
      return { x1: `${canvasWidth}`, y1: '0', x2: '0', y2: '0' }
    } else if (angle === 270) {
      // Vertical gradient (reverse)
      return { x1: '0', y1: `${canvasHeight}`, x2: '0', y2: '0' }
    } else {
      // Diagonal gradient
      const diagonalLength = Math.sqrt(
        canvasWidth * canvasWidth + canvasHeight * canvasHeight,
      )
      const dx = Math.cos((angle * Math.PI) / 180) * diagonalLength
      const dy = Math.sin((angle * Math.PI) / 180) * diagonalLength
      return {
        x1: `${canvasWidth / 2 - dx / 2}`,
        y1: `${canvasHeight / 2 - dy / 2}`,
        x2: `${canvasWidth / 2 + dx / 2}`,
        y2: `${canvasHeight / 2 + dy / 2}`,
      }
    }
  }, [])

  return { convert }
}
