import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import ThemeProvider from '@mui/material/styles/ThemeProvider'

import React, { PropsWithChildren } from 'react'
import { theme as emotionTheme } from '.'
import theme from './muiTheme'

const MainThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider
      theme={{
        ...theme,
        // ...emotionTheme,
        // zIndex: { ...theme.zIndex, ...emotionTheme.zIndex },
      }}
    >
      <EmotionThemeProvider theme={emotionTheme}>
        {children}
      </EmotionThemeProvider>
    </ThemeProvider>
  )
}

export default MainThemeProvider
