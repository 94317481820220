import { css } from '@emotion/react'

interface IBaseElementStyles {
  isDragging?: boolean
  disabled?: boolean
}
export const baseElementStyles = ({
  isDragging,
  disabled,
}: IBaseElementStyles) => css`
  position: relative;
  cursor: ${isDragging ? 'grabbing' : 'grab'};
  opacity: ${isDragging || disabled ? 0.5 : 1};
`
