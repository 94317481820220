import isEqual from 'lodash/isEqual'
import { ISlide, ISlideDiff, ISlideUpdate } from './types'
import { IDeckV3SlideContent } from 'src/types/deck-v3'

export const calculateDiff = (
  previousSlides: ISlide[],
  currentSlides: ISlideUpdate[],
  deleted: string[] = [],
): ISlideDiff => {
  const diff: ISlideDiff = {
    created: [],
    updated: [],
    deleted,
    reindexed: [],
  }
  // Find created and updated slides
  currentSlides.forEach((slide, index) => {
    const previousSlide = previousSlides.find((p) => p.id === slide.id)

    if (!previousSlide) {
      // New slide
      diff.created.push({
        slideIndex: index + 1,
        content: slide.content,
      })
    } else if (
      !isEqual(previousSlide.content, slide.content) ||
      previousSlide.slideIndex !== index
    ) {
      // Updated content or index
      if (slide.id && previousSlide.slideIndex !== index) {
        diff.reindexed.push({ id: slide.id, newIndex: index + 1 })
      }
      if (!isEqual(previousSlide.content, slide.content)) {
        diff.updated.push({
          id: slide.id,
          slideIndex: index + 1,
          content: slide.content,
        })
      }
    }
  })

  // Find deleted slides
  // previousSlides.forEach((previousSlide) => {
  //   if (!currentSlides.find((s) => s.id === previousSlide.id)) {
  //     diff.deleted.push(previousSlide.id)
  //   }
  // })

  return diff
}

export const mapSlidesFromPages = (
  previousPages?: IDeckV3SlideContent[],
  newPages?: IDeckV3SlideContent[],
): [ISlide[], ISlideUpdate[]] => {
  const previousSlides: ISlide[] =
    previousPages?.map((page: IDeckV3SlideContent, slideIndex) => ({
      id: page.custom?.internalId ?? '',
      slideIndex,
      content: page,
    })) ?? []

  const newSlides: ISlideUpdate[] =
    newPages?.map((page: IDeckV3SlideContent, slideIndex) => ({
      id: page.custom?.internalId,
      slideIndex,
      content: page,
    })) ?? []
  return [previousSlides, newSlides]
}

export const haveDiff = (diff: ISlideDiff): boolean => {
  return (
    diff.created.length > 0 ||
    diff.updated.length > 0 ||
    diff.deleted.length > 0 ||
    diff.reindexed.length > 0
  )
}

export const getChangedContentIds = (diff: ISlideDiff): string[] => {
  const ids = []
  diff.created.length > 0 &&
    ids.push(...diff.created.map((item) => item.content.id as string))
  diff.updated.length > 0 &&
    ids.push(...diff.updated.map((item) => item.content.id as string))
  diff.deleted.length > 0 &&
    ids.push(...diff.deleted.map((item) => item as string))
  diff.reindexed.length > 0 &&
    ids.push(...diff.reindexed.map((item) => item.id as string))

  return ids
}
