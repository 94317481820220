import React, { PropsWithChildren } from 'react'
import * as ScrollArea from '@radix-ui/react-scroll-area'

const AutoScrollArea: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ScrollArea.Root
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <ScrollArea.Viewport
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="vertical"
      >
        <ScrollArea.Thumb className="ScrollAreaThumb" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Scrollbar
        className="ScrollAreaScrollbar"
        orientation="horizontal"
        style={{
          margin: '4px 8px',
        }}
      >
        <ScrollArea.Thumb className="ScrollAreaThumb" />
      </ScrollArea.Scrollbar>
      <ScrollArea.Corner className="ScrollAreaCorner" />
    </ScrollArea.Root>
  )
}
export default AutoScrollArea
