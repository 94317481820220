import { dataAttrType } from 'src/types'

export enum HEADER_TYPE {
  DEFAULT = 'default',
  DECK_EDIT = 'deck-edit',
}
export interface IHeader {
  type?: HEADER_TYPE
  hasSearch?: boolean
  className?: string
  dataAttr?: dataAttrType
}
