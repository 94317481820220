export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_101_50)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_101_50"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_46_67)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM316.338 528.5C316.338 677.616 437.222 798.5 586.338 798.5C735.455 798.5 856.338 677.616 856.338 528.5C856.338 379.383 735.455 258.5 586.338 258.5C437.222 258.5 316.338 379.383 316.338 528.5Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M427.871 793.903C411.723 796.921 395.068 798.5 378.045 798.5C229.077 798.5 108.314 677.616 108.314 528.5C108.314 379.383 229.077 258.5 378.045 258.5C395.068 258.5 411.723 260.079 427.871 263.097C302.706 286.496 207.966 396.423 207.966 528.5C207.966 660.576 302.706 770.504 427.871 793.903Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M533 793.064C515.467 796.629 497.318 798.5 478.731 798.5C329.341 798.5 208.236 677.616 208.236 528.5C208.236 379.383 329.341 258.5 478.731 258.5C497.318 258.5 515.467 260.371 533 263.936C409.618 289.018 316.775 397.936 316.775 528.5C316.775 659.063 409.618 767.982 533 793.064Z" fill="$color3"/></g><defs><clipPath id="clip0_46_67"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H1920V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_46_57)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1603.66 528.5C1603.66 677.616 1482.78 798.5 1333.66 798.5C1184.55 798.5 1063.66 677.616 1063.66 528.5C1063.66 379.383 1184.55 258.5 1333.66 258.5C1482.78 258.5 1603.66 379.383 1603.66 528.5Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1492.13 793.903C1508.28 796.921 1524.93 798.5 1541.96 798.5C1690.92 798.5 1811.69 677.616 1811.69 528.5C1811.69 379.383 1690.92 258.5 1541.96 258.5C1524.93 258.5 1508.28 260.079 1492.13 263.097C1617.29 286.496 1712.03 396.423 1712.03 528.5C1712.03 660.576 1617.29 770.504 1492.13 793.903Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1387 793.064C1404.53 796.629 1422.68 798.5 1441.27 798.5C1590.66 798.5 1711.76 677.616 1711.76 528.5C1711.76 379.383 1590.66 258.5 1441.27 258.5C1422.68 258.5 1404.53 260.371 1387 263.936C1510.38 289.018 1603.23 397.936 1603.23 528.5C1603.23 659.063 1510.38 767.982 1387 793.064Z" fill="$color3"/></g><defs><clipPath id="clip0_46_57"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H0V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><circle opacity="0.3" cx="960" cy="540" r="465" fill="$color2"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_46_97)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM582.5 652C473.424 652 385 740.424 385 849.5C385 958.576 473.424 1047 582.5 1047H1569.5C1678.58 1047 1767 958.576 1767 849.5C1767 740.424 1678.58 652 1569.5 652H582.5Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M153 850.318C153 960.95 242.686 1050.64 353.318 1050.64C374.832 1050.64 395.554 1047.24 414.978 1040.97C334.515 1014.96 276.318 939.437 276.318 850.318C276.318 761.199 334.515 685.672 414.978 659.669C395.554 653.391 374.832 650 353.318 650C242.686 650 153 739.685 153 850.318Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M276.316 850.318C276.316 960.95 366.002 1050.64 476.635 1050.64C497.436 1050.64 517.496 1047.47 536.359 1041.58C449.539 1020.8 384.998 942.689 384.998 849.5C384.998 757.377 448.072 679.985 533.39 658.154C515.396 652.847 496.348 650 476.635 650C366.002 650 276.316 739.685 276.316 850.318Z" fill="$color3"/></g><defs><clipPath id="clip0_46_97"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="540" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
