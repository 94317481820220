import { EditorVersions } from 'src/types/api/enums'

export default function getDeckEditorPath(
  deckId?: number | string,
  editorVersion?: EditorVersions,
  targetSlideIndex?: number,
): string {
  if (!deckId) {
    throw new Error('Deck ID is required')
  }
  return `/deck${editorVersion === EditorVersions.V3 ? '-v3' : ''}/${deckId}${targetSlideIndex ? `/${targetSlideIndex}` : ''}`
}
