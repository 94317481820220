import React from 'react'

import { ICategoryItem } from './types'
import { wrapperStyles } from './styles'

export const CategoryItem: React.FC<ICategoryItem> = React.memo(
  ({ label, value, selected, onClick }) => {
    return (
      <div onClick={() => onClick?.(value)} css={wrapperStyles({ selected })}>
        {label}
      </div>
    )
  },
)
