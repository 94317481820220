import { icons } from 'src/lib'
import { ComponentChartTypes, ComponentTypes } from 'src/types/api/enums'
import { ComponentChartDataSchema } from 'src/types/api/requestObjects'
import { MediaDisplay } from '../components/media-display'
import { IElementDefinition } from '../types'
import i18n from 'src/i18n/config'

export const lineChartDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.line_chart.display_name', { lng }),
      styles: {},
      icon: icons.increase,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.CHART,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 1200,
          height: 800,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          options: {
            type: ComponentChartTypes.LINE,
            title: '',
            showGrids: true,
            legends: true,
            labels: {
              x: '',
              showX: false,
              y: '',
              showY: false,
            },
          },
          series: [
            {
              label: i18n.t('edit.definitions.line_chart.label1', { lng }),
              values: [
                {
                  name: i18n.t('edit.definitions.line_chart.label1.name1', {
                    lng,
                  }),
                  value: 96,
                },
                {
                  name: i18n.t('edit.definitions.line_chart.label1.name2', {
                    lng,
                  }),
                  value: 100,
                },
                {
                  name: i18n.t('edit.definitions.line_chart.label1.name3', {
                    lng,
                  }),
                  value: 102,
                },
                {
                  name: i18n.t('edit.definitions.line_chart.label1.name4', {
                    lng,
                  }),
                  value: 99,
                },
                {
                  name: i18n.t('edit.definitions.line_chart.label1.name5', {
                    lng,
                  }),
                  value: 99,
                },
              ],
            },
            {
              label: i18n.t('edit.definitions.line_chart.label2', { lng }),
              values: [
                {
                  name: i18n.t('edit.definitions.line_chart.label2.name1', {
                    lng,
                  }),
                  value: 90,
                },
                {
                  name: i18n.t('edit.definitions.line_chart.label2.name2', {
                    lng,
                  }),
                  value: 105,
                },
                {
                  name: i18n.t('edit.definitions.line_chart.label2.name3', {
                    lng,
                  }),
                  value: 80,
                },
                {
                  name: i18n.t('edit.definitions.line_chart.label2.name4', {
                    lng,
                  }),
                  value: 50,
                },
                {
                  name: i18n.t('edit.definitions.line_chart.label2.name5', {
                    lng,
                  }),
                  value: 85,
                },
              ],
            },
          ],
        } as ComponentChartDataSchema,
      },
    ],
  }
}
