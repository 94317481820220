import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useLanguage, useNotification, useUserApi } from 'src/hooks'
import { BUTTON_THEME, BUTTON_TYPE, Button, Input } from 'src/lib'

export const VerifyEmail: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const { success } = useNotification()
  const { sendVerificationEmail, validateVerificationCode } = useUserApi()
  const [emailSent, setEmailSent] = useState(false)
  const [submitActive, setSubmitActive] = useState(false)
  const [verifyCode, setVerifyCode] = useState('')

  const handleVerificationEmail = () => {
    sendVerificationEmail()
      .then(() => {
        success('verify.verification_email_sent')
        setEmailSent(true)
      })
      .catch(() => {})
  }

  const handleVerifyCode = () => {
    validateVerificationCode(verifyCode)
  }

  return emailSent ? (
    <>
      <div>{t('verify.verification_email_sent_info')}</div>
      <Input
        placeholder={t('verify.verification_enter_code')}
        onChange={setVerifyCode}
        value={verifyCode}
        solidError
        validation={{
          min: {
            value: 3,
            text: t('validation.min_char', { value: 3, name: 'Code' }),
          },
        }}
        onValidation={setSubmitActive}
      />
      <Button
        text={t('verify.verify_your_email')}
        type={BUTTON_TYPE.REVERSE}
        onClick={handleVerifyCode}
        disabled={!submitActive}
      />
      <div>
        <Trans
          i18nKey={'verify.verification_didnt_reveive_email'}
          components={[
            <Button
              key="resend"
              isLink
              text={t('verify.verification_click_to_resend')}
              theme={BUTTON_THEME.PRIMARY}
              type={BUTTON_TYPE.GHOST}
              onClick={handleVerificationEmail}
            />,
          ]}
        />
      </div>
    </>
  ) : (
    <>
      <div>{t('verify.verify_your_email_info')}</div>
      <Button
        text={t('verify.verify_your_account')}
        type={BUTTON_TYPE.REVERSE}
        onClick={handleVerificationEmail}
      />
    </>
  )
})
