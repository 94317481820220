import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const bannerStyles = ({ colors, dimensions, gap }: ITheme) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.primary.DEFAULT};
  height: ${dimensions.banner.DEFAULT}px;
  padding: 0 ${gap['2xlarge']}px;

  & > div {
    color: ${colors.white.DEFAULT};
    display: flex;
    align-items: center;
    gap: ${gap['3xsmall']}px;
    min-width: 210px;
  }
`
