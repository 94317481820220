import React, { useCallback, useMemo } from 'react'
import { IColorMappingItem } from './types'
import { mappingItemWrapperStyles, mappingItemNameStyles } from './styles'
import { PropColor } from 'src/pages/deck-page/components/edit-properties/props'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'
import { dimensions } from 'src/theme/dimensions'
import { useLanguage } from 'src/hooks'

export const ColorMappingItem: React.FC<IColorMappingItem> = React.memo(
  ({
    name,
    mappingKey,
    mappingIndex,
    color,
    isSwap,
    onChange,
    className,
    dataAttr,
  }) => {
    const { t } = useLanguage()

    const colorChangeHandler = useCallback(
      (color: GradientLikeColorSchema) => {
        onChange({ name: mappingKey, mappingIndex, color, isSwap })
      },
      [mappingKey, mappingIndex, isSwap, color],
    )

    const activeColor = useMemo(() => {
      return {
        colors: color.colors.map((val) =>
          ['first', 'second', 'third', 'fourth', 'wht', 'blck'].includes(val)
            ? `var(--${val})`
            : val,
        ),
        rotation: color.rotation || 0,
      }
    }, [color, mappingKey])

    return (
      <div css={mappingItemWrapperStyles} className={className} {...dataAttr}>
        <PropColor
          noGradient
          onChange={(newColor: GradientLikeColorSchema) =>
            colorChangeHandler(newColor)
          }
          color={activeColor}
          width={dimensions.colorMappingItemColorBox.DEFAULT + 'px'}
          height={dimensions.colorMappingItemColorBox.DEFAULT + 'px'}
        />
        <span css={mappingItemNameStyles}>
          {mappingIndex === 0 &&
            mappingKey.includes('charts') &&
            t('edit.color_mapping.chart.background')}
          {!mappingKey.includes('charts') &&
            !mappingKey.includes('decor') &&
            name}
          {mappingIndex !== undefined &&
            (mappingIndex !== 0 || mappingKey.includes('decor')) &&
            name +
              ' ' +
              (mappingKey.includes('decor') ? mappingIndex + 1 : mappingIndex)}
        </span>
      </div>
    )
  },
)

ColorMappingItem.displayName = 'ColorMappingItem'
