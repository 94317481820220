import { makeMuiStyles } from 'src/theme/muiTheme'

export const useStyles = makeMuiStyles()((theme) => ({
  workspaceWrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: [1, '0 1 100%'],
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  zoomButtons: {
    'position': 'absolute',
    'top': '-8px',
    'right': '72px',
    'button:first-of-type': {
      svg: {
        transform: 'scale(0.75)',
        path: {
          d: `path("M4.375 10.4788V9.52051H15.625V10.4788H4.375Z")`,
          fill: '#373F4F',
        },
      },
    },
    'button:nth-of-type(2)': {
      svg: {
        transform: 'scale(0.75)',
        path: {
          d: `path("M9.52083 15.625V10.4792H4.375V9.52083H9.52083V4.375H10.4792V9.52083H15.625V10.4792H10.4792V15.625H9.52083Z")`,
          fill: '#373F4F',
        },
      },
    },
    'span.bp5-icon': {
      width: '20px',
      height: '20px',
    },
    'button.bp5-button': {
      'outline': 'none',
      'fontSize': '12px',
      ':hover': {
        backgroundColor: theme.palette.primary._states?.hover,
      },
    },
  },
  noPages: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
