import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IMembers } from './types'

export const membersStyles = ({ colors, gap }: ITheme) => css`
  background: ${colors.white.DEFAULT};
  width: 100%;
  padding-bottom: ${gap.small}px;
`

export const membersTableColumnNameStyles = ({
  breakpointsV2: breakpoints,
  gap,
}: ITheme) => css`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: ${gap['8xlarge']}px;
  padding-right: ${gap['8xlarge']}px;
  margin-bottom: ${gap.small}px;

  @media (min-width: ${breakpoints.small}px) {
    padding: 0 ${gap.xlarge}px;
  }
`

type IMembersStyles = Pick<IMembers, 'nameWidth'>
export const nameStyles =
  ({ nameWidth }: IMembersStyles) =>
  (theme: ITheme) => css`
    width: ${nameWidth}px;
    font-size: ${theme.gap.small}px;
    font-weight: ${theme.fontWeight.medium};

    @media (min-width: ${theme.breakpointsV2.tablet}px) {
      width: ${nameWidth}px;
    }
  `

export const roleStyles = ({ fontWeight, gap }: ITheme) => css`
  width: auto;
  display: flex;
  justify-content: flex-end;
  font-size: ${gap.small}px;
  font-weight: ${fontWeight.medium};
`
