import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Icon, MODAL_POSITION, Modal, icons } from 'src/lib'
import { RootState } from 'src/store'
import { useTheme } from 'src/theme'

import { PaymentConfirmation } from 'src/components/payment-confirmation/payment-confirmation'
import { SettingsPanel } from 'src/components/settings-panel'
import HeaderActionsWrapper from './HeaderActionsWrapper'
import RightActions from './RightActions'
import { userPanelStyles } from './styles'
import { IHeaderActions } from './types'

export const HeaderActions: React.FC<IHeaderActions> = React.memo(
  ({ className, dataAttr }) => {
    const { colors } = useTheme()

    const [isSideMenuOpen, setSideMenuOpen] = useState(false)

    const { isPaymentConfirmationModalOpen } = useSelector(
      ({ plan }: RootState) => ({
        isPaymentConfirmationModalOpen: plan.isPaymentConfirmationModalOpen,
      }),
    )

    return (
      <>
        <HeaderActionsWrapper className={className} {...dataAttr}>
          <RightActions openMenu={() => setSideMenuOpen(true)} />
        </HeaderActionsWrapper>
        <Icon
          icon={icons.menu}
          color={colors.primary.DEFAULT}
          size={24}
          css={userPanelStyles}
          onClick={() => setSideMenuOpen(true)}
        />
        <Modal
          isOpen={isSideMenuOpen}
          onClose={() => setSideMenuOpen(false)}
          position={MODAL_POSITION.RIGHT}
        >
          <SettingsPanel onCloseClick={() => setSideMenuOpen(false)} />
        </Modal>
        {isPaymentConfirmationModalOpen && <PaymentConfirmation />}
      </>
    )
  },
)

HeaderActions.displayName = 'HeaderActions'
