import React from 'react'
import IconButton from '@mui/material/IconButton'
import { MaterialSymbol } from 'react-material-symbols'
import { useStyles } from './styles'

interface IHomeButtonProps {
  onClick?: () => void
}

const HomeButton: React.FC<IHomeButtonProps> = React.memo(({ onClick }) => {
  const { classes } = useStyles()
  return (
    <IconButton
      aria-label="home-page"
      onClick={onClick}
      className={classes?.iconButton}
    >
      <MaterialSymbol icon="home" size={24} weight={300} />
    </IconButton>
  )
})

export default HomeButton
