import styled from '@mui/material/styles/styled'
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup'

const SectionTab = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    'margin': 0,
    'border': 0,
    'borderRadius': theme.spacing(2),
    'padding': theme.spacing(2),
    'textTransform': 'none',
    'color': theme.palette.text.primary,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
    ':hover, :active': {
      backgroundColor: theme.palette.primary._states?.focus,
    },
  },
  [`& .${toggleButtonGroupClasses.selected}`]: {
    backgroundColor: `${theme.palette.primary._states?.focus} !important`,
  },
}))

export default SectionTab
