import React, { useState } from 'react'

import { Button, BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE } from 'src/lib/button'
import { icons } from 'src/lib/icon'
import { useLanguage } from 'src/hooks'

import { ICopyArea } from './types'
import { copyTextStyles, frameStyles, iconStyles, wrapperStyles } from './style'

export const CopyArea: React.FC<ICopyArea> = React.memo(
  ({ className, dataAttr, width, height, text }) => {
    const { t } = useLanguage()

    const [copyButtonState, setCopyButtonState] = useState(false)

    const onCopyClick = async () => {
      await navigator.clipboard.writeText(text)
      setCopyButtonState(true)
      setTimeout(() => {
        setCopyButtonState(false)
      }, 2000)
    }

    return (
      <div {...dataAttr} className={className}>
        <div css={frameStyles}>
          <textarea
            css={wrapperStyles({ width, height })}
            defaultValue={text}
          ></textarea>
          <div css={iconStyles}>
            {!copyButtonState && (
              <Button
                type={BUTTON_TYPE.GHOST}
                icon={icons.copy}
                onClick={onCopyClick}
                theme={BUTTON_THEME.GRAY}
                size={BUTTON_SIZE.XSMALL}
                isLink
              />
            )}
            {copyButtonState && (
              <span css={copyTextStyles}>{t('common.informative.copied')}</span>
            )}
          </div>
        </div>
      </div>
    )
  },
)
