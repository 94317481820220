import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const initHeaderStyles = ({
  dimensions,
  colors,
  breakpointsV2: breakpoints,
  gap,
}: ITheme) => css`
  background: ${colors.dark2.DEFAULT};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .header-logo {
    height: ${dimensions.headerHeight.big}px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      height: 24px;
    }
  }

  .header-action {
    height: ${dimensions.headerHeight.big}px;
    display: flex;
    flex-direction: column;
    gap: ${gap.small}px;
    width: 100%;
    padding: 0 ${gap['4xlarge']}px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
    padding: 0 ${gap['6xlarge']}px;

    .header-action {
      flex-direction: row;
      align-items: center;
      height: 100%;
      padding: 0;
    }

    .header-input-area {
      order: -1;
      flex-grow: 1;
      display: flex;
      justify-content: center;
    }

    .header-input {
      width: 350px;
    }
  }

  @media (min-width: ${breakpoints.desktop}px) {
    .header-input {
      width: 450px;
    }
  }
`
