import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  BUTTON_WIDTH,
  Button,
  INPUT_SIZE,
  Icon,
  Input,
  Modal,
  icons,
} from 'src/lib'
import {
  leftMenuStyles,
  mediaTypeItemStyles,
  mediaTypeStyles,
  propMediaModalStyles,
  rightAreaHeaderStyles,
  rightAreaStyles,
  searchInputStyles,
  uploadButtonStyles,
} from './styles'
import { IPropMedia, MEDIA_MENU_ITEMS, mapTypeToExtension } from './types'
import { MediaTypes } from 'src/types/api/enums'
import { useTheme } from 'src/theme'
import {
  VALIDATION_RULE_TYPES,
  useConfigApi,
  useLanguage,
  useNotification,
} from 'src/hooks'
import { APP_CONFIG } from 'src/config'
import { useDispatch } from 'react-redux'
import { setAllowDeleteShortcut } from 'src/store'
import { PropMediaSearchResults } from './components'

export const PropMedia: React.FC<IPropMedia> = React.memo(
  ({ label, icon, selectedType, availableTypes, directUpload, onChange }) => {
    const dispatch = useDispatch()
    const { t, Trans } = useLanguage()
    const { colors } = useTheme()
    const [isModalOpen, setModalOpen] = useState(false)
    const [currentType, setCurrentType] = useState(selectedType)

    const { error, info } = useNotification()
    const { uploadImage, isLoading } = useConfigApi()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const mediaTypeName = useMemo(
      () =>
        currentType
          ? MEDIA_MENU_ITEMS[currentType]?.name || 'common.files.image'
          : 'common.files.image',
      [currentType],
    )

    const allowedTypes = APP_CONFIG.fileUploadList.map(({ mime }) => mime)

    const handleOnChange = useCallback(
      async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target?.files?.[0]) {
          if (e.target?.files?.[0].size > APP_CONFIG.uploadMediaAllowedSize) {
            info(t('common.informative.large_media_file'))
          }
          const fileType = e.target.files[0].type
          if (!allowedTypes.includes(fileType)) {
            error(t('profile.update.image_upload_error', { fileType }))
            return
          }
          const accessUrl = await uploadImage({
            file: e.target.files[0],
          })
          if (currentType && accessUrl) {
            onChange?.(currentType, accessUrl)
            setModalOpen(false)
          }
        }
      },
      [allowedTypes, currentType, onChange],
    )

    const [searchText, setSearchText] = useState('')

    useEffect(() => {
      setSearchText('')
    }, [selectedType])

    const handleModalOpen = useCallback(() => {
      setModalOpen(true)
      dispatch(setAllowDeleteShortcut(false))
    }, [])

    const handleModalClose = useCallback(() => {
      setModalOpen(false)
      dispatch(setAllowDeleteShortcut(true))
      setSearchText('')
      setCurrentType(MediaTypes.IMAGE)
    }, [selectedType])

    const mediaUploadButton = useMemo(
      () => (
        <div css={uploadButtonStyles}>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleOnChange}
            accept={mapTypeToExtension[currentType || MediaTypes.IMAGE].join(
              ',',
            )}
          />
          <Button
            icon={icons.upload}
            text={`${t('common.actions.upload')} ${t(mediaTypeName)}`}
            disabled={isLoading}
            isLoading={isLoading}
            {...(directUpload ? { width: BUTTON_WIDTH.FULL } : {})}
          />
        </div>
      ),
      [currentType, mediaTypeName, directUpload, handleOnChange],
    )

    if (directUpload) {
      return mediaUploadButton
    }

    return (
      <>
        <Button
          width={BUTTON_WIDTH.FULL}
          text={label}
          icon={icon}
          onClick={handleModalOpen}
        />
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          <div css={propMediaModalStyles}>
            <div css={leftMenuStyles}>
              <div css={mediaTypeStyles}>
                {availableTypes.map((key: MediaTypes) => {
                  const typeDetails = MEDIA_MENU_ITEMS[key]
                  if (!typeDetails) {
                    return null
                  }

                  return (
                    <div
                      css={mediaTypeItemStyles({
                        isSelected: typeDetails.value === currentType,
                      })}
                      key={key}
                      onClick={() => {
                        setSearchText('')
                        setCurrentType?.(typeDetails.value)
                      }}
                    >
                      <Icon
                        icon={typeDetails.icon}
                        size={20}
                        color={
                          typeDetails.value === currentType
                            ? colors.primary.DEFAULT
                            : colors.text.DEFAULT
                        }
                      />
                      <div>{t(typeDetails.name)}</div>
                    </div>
                  )
                })}
              </div>
              {mediaTypeName && mediaUploadButton}
            </div>
            <div css={rightAreaStyles}>
              <div css={rightAreaHeaderStyles}>
                <div className="close-button">
                  <Icon
                    icon={icons.close}
                    size={16}
                    onClick={handleModalClose}
                  />
                </div>
                <div className="title">
                  {currentType === MediaTypes.IMAGE ? (
                    <Trans
                      i18nKey={'edit.media.modal.image.title'}
                      components={[
                        <a
                          key="unsplash_library"
                          href="https://unsplash.com/?utm_source=decktopus&utm_medium=referral"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('edit.media.modal.image.unsplash_library')}
                        </a>,
                      ]}
                    />
                  ) : (
                    t('edit.media.modal.title', { type: t(mediaTypeName) })
                  )}
                </div>
              </div>
              <div css={searchInputStyles}>
                <Input
                  icon={icons.find}
                  size={INPUT_SIZE.SMALL}
                  placeholder={t('common.actions.search_with_name', {
                    name: t(mediaTypeName),
                  })}
                  onChange={setSearchText}
                  value={searchText}
                  validation={{
                    [VALIDATION_RULE_TYPES.MIN]: {
                      value: 3,
                      text: t('validation.min_char', {
                        name: t('common.value'),
                        value: 3,
                      }),
                    },
                  }}
                />
              </div>
              <PropMediaSearchResults
                searchText={searchText}
                currentType={currentType}
                onMediaUrlChange={(url) => {
                  currentType && onChange?.(currentType, url)
                  setModalOpen(false)
                }}
              />
            </div>
          </div>
        </Modal>
      </>
    )
  },
)
