import { dataAttrType } from 'src/types'

export enum TABS_SIZE {
  DEFAULT = 'default',
  BIG = 'big',
  SMALL = 'small',
}

export enum TABS_THEME {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface ITabs {
  size?: TABS_SIZE
  tabs: string[]
  theme?: TABS_THEME
  activeTab?: number
  onChange: (index: number) => void
  className?: string
  dataAttr?: dataAttrType
}

export interface ITabItem {
  isActive?: boolean
  label: string
  onChange?: (event?: React.MouseEvent<HTMLElement>) => void
  theme?: TABS_THEME
}
