import React, { useEffect, useState } from 'react'

import { IAiAudience } from './types'
import {
  buttonStyles,
  aiSuggestionStyles,
  exampleAreaStyles,
  viewStyles,
  inputStyles,
} from '../ai-prompt/styles'
import {
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  INPUT_VARIANT,
  Input,
  icons,
} from 'src/lib'
import { Steps } from 'src/lib/steps'
import { useDispatch, useSelector } from 'react-redux'
import { AI_FLOW_STEP, RootState, setAiFlowStep, setAudience } from 'src/store'
import {
  VALIDATION_RULE_TYPES,
  useLanguage,
  useValidation,
  useValidatedText,
} from 'src/hooks'
import { aiAudienceStyles } from './styles'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'

export const AiAudience: React.FC<IAiAudience> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()

    const dispatch = useDispatch()

    const { getAiFlowAim } = useAiFlowApi()

    const posthogCapture = usePostHogCapture()
    const { selectedAudience, audienceSuggestion, promptId, aiFlowId } =
      useSelector(({ aiFlow }: RootState) => ({
        selectedAudience: aiFlow.selectedAudience,
        audienceSuggestion: aiFlow.audienceSug,
        promptId: aiFlow.selectedPromptId,
        aiFlowId: aiFlow.aiFlowId,
      }))

    useEffect(() => {
      aiFlowId &&
        posthogCapture(POSTHOG_EVENTS.AI_FLOW_AUDIENCE_VIEW, {
          ai_flow_id: aiFlowId,
        })
    }, [aiFlowId])

    const validatedText = useValidatedText(
      () => {
        nextButton()
      },
      selectedAudience,
      3,
    )

    const [isValid] = useValidation({
      value: selectedAudience,
      rules: {
        [VALIDATION_RULE_TYPES.MIN]: {
          value: 3,
          text: t('validation.min_char', {
            value: 3,
            name: selectedAudience,
          }),
        },
      },
    })

    useEffect(() => {
      validatedText
    }, [selectedAudience])

    const [loadingState, setLoadingState] = useState(false)

    const updateSelectedAudience = (item: string) => {
      dispatch(setAudience(item))
    }

    const onAudienceChange = (value: string) => {
      dispatch(setAudience(value))
    }

    const nextButton = async () => {
      if (!audienceSuggestion.length) {
        return
      }

      setLoadingState(true)
      const apiResult = await getAiFlowAim({
        audience: selectedAudience,
        deckPromptId: promptId,
      })
      if (apiResult) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.AIM))
        dispatch(setAudience(selectedAudience))
      }
      setLoadingState(false)
    }

    const backButton = () => {
      dispatch(setAiFlowStep(AI_FLOW_STEP.CATEGORY))
    }

    return (
      <div className={className} {...dataAttr}>
        <div css={viewStyles(false)}>
          <Input
            placeholder={t('create_with_ai.audience_placeholder')}
            value={selectedAudience}
            onChange={onAudienceChange}
            css={inputStyles(false)}
            variant={INPUT_VARIANT.GHOST}
          />
          <Steps steps={5} current={2} />

          <div css={exampleAreaStyles}>
            <div css={aiAudienceStyles}>
              {audienceSuggestion.map((item, index) => (
                <div
                  css={aiSuggestionStyles(selectedAudience == item)}
                  key={index}
                  onClick={() => updateSelectedAudience(item)}
                >
                  <div>{item}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div css={buttonStyles}>
          <Button
            type={BUTTON_TYPE.GHOST}
            text={t('common.actions.back')}
            icon={icons.chevron_left}
            isLink
            onClick={backButton}
          />
          <Button
            text={t('common.actions.next')}
            theme={BUTTON_THEME.GRADIENT}
            onClick={nextButton}
            disabled={!isValid}
            isLoading={loadingState}
          />
        </div>
      </div>
    )
  },
)

AiAudience.displayName = 'AiAudience'
