import { useCallback, useEffect, useState } from 'react'
import { PivotDeckCategories } from 'src/types/api/enums'

export function useInfiniteScroll<T extends HTMLElement>({
  ref,
  callback,
  limit,
  isDomLoading,
  category,
  search,
}: {
  ref: React.RefObject<T>
  callback: ({
    offset,
    category,
    search,
  }: {
    offset?: number
    category?: PivotDeckCategories | string
    search?: string
  }) => Promise<any>
  limit: number
  isDomLoading: boolean
  category?: PivotDeckCategories | string
  search?: string
}) {
  const [isOnScreen, setIsOnScreen] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(false)
  const [responseData, setResponseData] = useState<any[]>([])
  const [categoryData, setCategoryData] = useState<string>('')

  const options = {
    rootMargin: '1px',
    threshold: 0.1,
  }

  useEffect(() => {
    if (isDomLoading) {
      return
    }
    const observer = new IntersectionObserver(([entry]) => {
      setIsOnScreen(entry.isIntersecting)
    }, options)

    const currentRef = ref.current
    if (currentRef) {
      observer.observe(currentRef)
    }
    return () => {
      if (currentRef) {
        observer?.unobserve(currentRef)
      }
    }
  }, [ref, isDomLoading])

  const callbackFn = useCallback(
    async ({
      offset,
      category,
      search,
    }: {
      offset?: number
      category?: PivotDeckCategories | string
      search?: string
    }) => {
      const response = await callback({
        offset,
        category,
        search,
      })

      if (
        !response.length ||
        response.length < limit ||
        JSON.stringify(responseData) === JSON.stringify(response)
      ) {
        setHasMoreData(false)
      } else {
        setHasMoreData(true)
      }

      setResponseData(response)
    },
    [setResponseData, responseData, setHasMoreData, limit],
  )

  useEffect(() => {
    if (isOnScreen && hasMoreData && !isDomLoading && search === undefined) {
      callbackFn({})
    }
  }, [isOnScreen, isDomLoading, search])

  useEffect(() => {
    if (category && categoryData !== category) {
      setCategoryData(category)
      callbackFn({ offset: 0, category, search })
    } else if (search) {
      callbackFn({ offset: 0, search })
    }
  }, [category, search])

  return { hasMoreData, responseData }
}
