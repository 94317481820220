import React, { useCallback, useState } from 'react'
import { IDialog, DIALOG_THEME, DIALOG_PADDING } from './types'
import {
  Button,
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
} from 'src/lib/button'
import { INPUT_SIZE, INPUT_THEME, INPUT_VARIANT, Input } from 'src/lib/input'
import {
  buttonAreaStyles,
  closeButtonAreaStyles,
  imageStyles,
  inputAreaStyles,
  textStyles,
  titleStyles,
  wrapperStyles,
} from './styles'
import { icons } from '../icon'

export const Dialog: React.FC<IDialog> = React.memo(
  ({
    theme = DIALOG_THEME.LIGHT,
    submitTheme = BUTTON_THEME.PRIMARY,
    cancelTheme,
    padding = DIALOG_PADDING.DEFAULT,
    submitLabel,
    onSubmit,
    submitIcon,
    submitDisabled,
    cancelLabel,
    onCancel,
    onCloseClick,
    promptLabel,
    promptPlaceholder,
    promptValue,
    onPromptChange,
    promptValidationRules,
    title,
    text,
    image,
    className,
    dataAttr,
    showCloseButton = true,
    titleClassName,
    textClassName,
    isLoading,
    inputProps,
    footerText,
  }) => {
    const [inputValid, setInputValid] = useState(
      !(promptPlaceholder || promptLabel),
    )
    const [inputValue, setInputValue] = useState(promptValue)

    const handleInputChange = useCallback(
      (value: string) => {
        setInputValue(value)
        onPromptChange?.(value)
      },
      [inputValue],
    )

    return (
      <div
        css={wrapperStyles({ theme, padding })}
        className={className}
        {...dataAttr}
      >
        {showCloseButton && (
          <div css={closeButtonAreaStyles}>
            <Button
              icon={icons.close}
              size={BUTTON_SIZE.AUTO}
              type={BUTTON_TYPE.GHOST}
              theme={BUTTON_THEME.ELEMENTS}
              iconSize={20}
              onClick={onCloseClick}
            />
          </div>
        )}
        {image && (
          <div css={imageStyles}>
            <img src={image} />
          </div>
        )}
        {title && (
          <div css={titleStyles({ theme })} className={titleClassName}>
            {title}
          </div>
        )}
        {text?.length && (
          <div css={textStyles({ theme })} className={textClassName}>
            {text.map((line, i) => (
              <p key={i} dangerouslySetInnerHTML={{ __html: line }} />
            ))}
          </div>
        )}
        {(promptPlaceholder || promptLabel) && (
          <div css={inputAreaStyles}>
            <Input
              size={INPUT_SIZE.SMALL}
              placeholder={promptPlaceholder}
              label={promptLabel}
              value={promptValue}
              onChange={handleInputChange}
              onEnter={inputValid ? onSubmit : undefined}
              variant={
                theme === DIALOG_THEME.DARK
                  ? INPUT_VARIANT.GHOST
                  : INPUT_VARIANT.DEFAULT
              }
              theme={
                theme === DIALOG_THEME.DARK
                  ? INPUT_THEME.DARK
                  : INPUT_THEME.LIGHT
              }
              validation={promptValidationRules}
              onValidation={setInputValid}
              {...inputProps}
            />
          </div>
        )}
        {footerText && (
          <div css={textStyles({ theme })} className={textClassName}>
            {footerText}
          </div>
        )}
        <div className="separator" />
        <div css={buttonAreaStyles({ theme })}>
          {cancelLabel && (
            <Button
              text={cancelLabel}
              type={BUTTON_TYPE.GHOST}
              theme={
                cancelTheme
                  ? cancelTheme
                  : theme === DIALOG_THEME.DARK
                    ? BUTTON_THEME.WHITE
                    : BUTTON_THEME.PRIMARY
              }
              isLink={true}
              onClick={onCancel}
            />
          )}
          <Button
            text={submitLabel}
            theme={submitTheme}
            isLoading={isLoading}
            onClick={onSubmit}
            width={
              theme === DIALOG_THEME.COLORFUL
                ? BUTTON_WIDTH.FULL
                : BUTTON_WIDTH.DEFAULT
            }
            icon={submitIcon}
            disabled={!inputValid || submitDisabled}
          />
        </div>
      </div>
    )
  },
)
