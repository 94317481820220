import { makeMuiStyles } from 'src/theme/muiTheme'

export const useStyles = makeMuiStyles()((theme) => ({
  icon: {
    'color': theme.palette.grey[800],
    'borderRadius': theme.spacing(2),
    ':hover': {
      color: `${theme.palette.primary.main}`,
      backgroundColor: `${theme.palette.primary._states?.focus}`,
    },
  },
}))
