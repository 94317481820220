import { css } from '@emotion/react'

export const formStyles = css`
  width: 100%;
  height: 100%;
`

export const formStateStyles = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const formSlideStyles = css`
  overflow: hidden;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;

  & .formGDPRStyles {
    padding-left: 0;
  }
`

export const formQuestionStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  max-width: 100%;
`

export const titleStyles = css`
  color: var(--formQuestion);
  white-space: wrap;
  text-align: center;

  span {
    color: #f00;
  }
`

export const renderItemsStyles = css`
  display: flex;
  justify-content: center;
  max-width: 100%;
`

export const textInputStyles = css`
  font-size: 1em;
  padding: 0.3em 0.6em;
  width: 18em;
  max-width: 100%;
  outline: none;
  border: 0.1em solid #eeeeee;
`

export const checklistStyles = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 0.04em;
  padding-left: 1.2em;
  max-width: inherit;
`

export const checklistItemStyles = css`
  min-width: 50%;
  position: relative;
  display: flex;
  align-items: center;

  input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0;
    border: 0;
  }
`
export const checkboxWrapperStyles = css`
  position: relative;
  min-width: 1em;
  max-width: 1.7em;
  height: 100%;
`

export const rateStyles = css`
  display: flex;

  .rate-button {
    width: 1.8em;
    height: 1.2em;
    padding: 0 0.3em;
    cursor: pointer;
  }
`

export const optionLabelStyles = css`
  color: var(--formQuestion);
  padding-left: 0.3em;
  padding-top: 2px;
  cursor: pointer;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const buttonsStyles = css`
  display: inline-flex;
  gap: 0.5em;

  button {
    font-size: 0.8em;
    background: var(--formButtonBg);
    border-radius: 2em;
    color: var(--formButtonText);
    padding: 0.2em 2em;
    cursor: pointer;
    border: none;
    outline: none;
  }
`
