import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const textDisplayStyles = (theme: ITheme) => css`
  background: ${theme.colors.dark.DEFAULT};
  width: 100%;
  padding: ${theme.gap.small}px;
  display: flex;
  align-items: center;
  justify-content: center;
`
