import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { setAdminUsers } from 'src/store'
import { getUserQuery } from 'src/types/api/requestObjects'
import {
  getUserResponse,
  postLoginResponse,
} from 'src/types/api/responseObjects'
import { useNotification, useLanguage } from 'src/hooks'

export const useAdminApi = () => {
  const dispatch = useDispatch()
  const { info } = useNotification()
  const { t } = useLanguage()

  const searchUsers = useCallback(async (params: getUserQuery) => {
    try {
      const res: RequestResponse<getUserResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.ADMIN_USERS,
          params,
        })
      if (!res.data.data.user) {
        info(t('admin.search.user_not_found'))
      } else {
        dispatch(setAdminUsers({ user: res.data.data.user }))
      }
    } catch {
      return false
    }
  }, [])

  const loginAsUser = useCallback(async (userId: number) => {
    try {
      const res: RequestResponse<postLoginResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: API_CONFIG.ADMIN_LOGIN_AS_USER,
          data: {
            userId,
          },
        })
      return res.data.data.authToken
    } catch {
      return false
    }
  }, [])

  const deleteUser = useCallback(async (userId: string) => {
    try {
      await RequestServices.callApi({
        method: 'DELETE',
        url: API_CONFIG.ADMIN_DELETE_USER(userId),
      })
      dispatch(setAdminUsers({ user: null }))
    } catch {
      return false
    }
  }, [])

  return { searchUsers, loginAsUser, deleteUser }
}
