import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { HelmetProvider } from 'src/provider'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useLanguage } from 'src/hooks'

export const UnprotectedPageLayout: React.FC = React.memo(() => {
  const ldClient = useLDClient()
  const { changeLanguage } = useLanguage()

  useEffect(() => {
    ldClient
      ?.identify({
        kind: 'user',
        key: 'USER_ANNMYS',
        name: 'User Anonymous',
        email: 'user_annmys@decktopus.com',
      })
      .catch((error) => {
        console.error('Launchdarkly client identify error:', error.message)
      })

    const userLang = navigator.language
    const langValue = userLang.split('-')[0]
    const langLS = localStorage.getItem('currentLanguage')
    if (langLS) {
      changeLanguage(langLS)
    } else if (userLang && langValue) {
      changeLanguage(langValue)
    }
  }, [])

  return (
    <>
      <HelmetProvider />
      <Outlet />
    </>
  )
})

export default UnprotectedPageLayout
