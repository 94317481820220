import React from 'react'
import { ExpiredPage } from 'src/pages/expired-page'
import { ExpiredHeader } from 'src/components/expired-header'
import { wrapperStyles } from './styles'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { Header } from 'src/components/header'

export const ExpiredPageLayout: React.FC = React.memo(() => {
  const { userId } = useSelector(({ user }: RootState) => ({
    userId: user.data?.id,
  }))
  return (
    <>
      {userId ? <Header /> : <ExpiredHeader />}
      <div css={wrapperStyles}>
        <ExpiredPage />
      </div>
    </>
  )
})
export default ExpiredPageLayout
