import React, { useCallback, useMemo } from 'react'

import { IMedia } from './types'
import { ComponentMediaDataSchema } from 'src/types/api/requestObjects'
import { playIconStyles, videoStyles } from './styles'
import { StringServices } from 'src/services'
import { Icon, icons } from 'src/lib'
import { useTheme } from 'src/theme'
import { CANVAS_TYPE } from 'src/components/canvas/types'

enum VideoProvider {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  HTML5 = 'html5',
}

export const Video: React.FC<IMedia> = React.memo(
  ({ data, canvasType, className, dataAttr }) => {
    const { colors } = useTheme()
    const componentData: ComponentMediaDataSchema = useMemo(
      () => data.data as ComponentMediaDataSchema,
      [data.data],
    )

    const isPreview = useMemo(
      () => canvasType === CANVAS_TYPE.PREVIEW,
      [canvasType],
    )

    const getVideoProvider = useCallback((hostName: string): VideoProvider => {
      if (hostName.includes('youtube') || hostName.includes('youtu.be')) {
        return VideoProvider.YOUTUBE
      }

      if (hostName.includes('vimeo')) {
        return VideoProvider.VIMEO
      }

      return VideoProvider.HTML5
    }, [])

    const videoData = useMemo(() => {
      const parsed = StringServices.urlParser(componentData.mediaUrl, true)

      const provider = getVideoProvider(parsed.host)

      switch (provider) {
        case VideoProvider.YOUTUBE:
          const youtubeId =
            parsed.host === 'youtu.be'
              ? parsed.pathname.replace('/', '')
              : parsed.query.v
          return {
            provider: VideoProvider.YOUTUBE,
            url: `https://www.youtube.com/embed/${youtubeId}?enablejsapi=1&origin=https://app.decktopus.com&widgetid=1`,
            thumbnail: `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`,
          }
        case VideoProvider.VIMEO:
          const vimeoId = parsed.pathname.replace('/', '')
          return {
            provider: VideoProvider.VIMEO,
            url: `https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&badge=0`,
            thumbnail: `https://vumbnail.com/${vimeoId}.jpg`,
          }
        default:
          return {
            provider: VideoProvider.HTML5,
            url: componentData.mediaUrl,
          }
      }
    }, [componentData.mediaUrl])

    const iFrame = useMemo(
      () =>
        isPreview ? (
          <iframe
            width={data.positions.width || 0}
            height={data.positions.height || 0}
            src={videoData.url}
            allow="
							accelerometer; 
							autoplay; 
							clipboard-write; 
							encrypted-media; 
							gyroscope; 
							picture-in-picture; 
							web-share
						"
            allowFullScreen={true}
            style={{ border: 0 }}
          />
        ) : null,
      [isPreview, videoData],
    )

    return (
      <div
        css={videoStyles}
        className={className}
        {...dataAttr}
        style={{
          backgroundImage: !isPreview ? `url(${videoData.thumbnail})` : '',
        }}
      >
        {!isPreview && (
          <div css={playIconStyles}>
            <Icon icon={icons.play} color={colors.white.DEFAULT} size={128} />
          </div>
        )}
        {iFrame}
      </div>
    )
  },
)

Video.displayName = 'Video'
