import React, { useCallback, useEffect, useMemo, useState } from 'react'

import logoIconWhite from 'src/assets/images/logo-icon-white.svg'
import logoFullWhite from 'src/assets/images/logo-full-white.svg'

import { IInitHeader } from './types'
import { initHeaderStyles } from './styles'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  Button,
  INPUT_SIZE,
  INPUT_THEME,
  Input,
  Modal,
  icons,
} from 'src/lib'
import { useLocation, useWindowSize, useDebouncer } from 'src/hooks'
import { useTheme } from 'src/theme'
import { useNavigate } from 'react-router-dom'
import { CreateWithAiForm } from '../create-with-ai-form/CreateWithAiForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  AI_FLOW_STEP,
  RootState,
  setAiFlowStep,
  setDecksQuery,
  setPivotDecks,
  setPivotLength,
} from 'src/store'
import { useLanguage } from 'src/hooks'
import { APP_CONFIG } from 'src/config'

export const InitHeader: React.FC<IInitHeader> = React.memo(
  ({ className, dataAttr }) => {
    const { breakpointsV2: breakpoints } = useTheme()
    const dispatch = useDispatch()
    const size = useWindowSize()
    const navigate = useNavigate()
    const { lastPath } = useLocation()
    const { t } = useLanguage()

    const { decksQuery } = useSelector(({ decks }: RootState) => ({
      decksQuery: decks.decksQuery,
    }))

    const logo = useMemo(
      () =>
        size.width < breakpoints.tablet || size.width > breakpoints.laptop - 1
          ? logoFullWhite
          : logoIconWhite,
      [size],
    )

    const [openModal, setOpenModal] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const debounced = useDebouncer(
      () => {
        dispatch(
          setDecksQuery({
            search: searchValue.length >= 3 ? searchValue : undefined,
          }),
        )
      },
      { delay: APP_CONFIG.searchDelay },
    )

    useEffect(() => {
      debounced()
    }, [searchValue])

    const onClick = () => {
      dispatch(setAiFlowStep(AI_FLOW_STEP.PROMPT))
      setOpenModal(true)
    }

    const onCloseHandler = useCallback(() => {
      setOpenModal(false)
    }, [])

    const navigateDashboardHandler = useCallback(() => {
      dispatch(
        setDecksQuery({
          search: undefined,
        }),
      )

      dispatch(setPivotDecks([]))
      dispatch(setPivotLength(0))
      navigate('/dashboard', { replace: true })
    }, [])

    return (
      <div css={initHeaderStyles} className={className} {...dataAttr}>
        <div className="header-logo">
          <img src={logo} alt="logo" onClick={navigateDashboardHandler} />
        </div>
        <div className="header-action">
          <Button
            className="header-button"
            icon={icons.sparkling}
            size={BUTTON_SIZE.LARGE}
            theme={BUTTON_THEME.GRADIENT}
            text={t('create_with_ai.create_with_ai')}
            onClick={onClick}
          />
          {openModal && (
            <Modal
              isOpen={openModal}
              onClose={onCloseHandler}
              preventClickOutside
            >
              <CreateWithAiForm onCloseClick={onCloseHandler} />
            </Modal>
          )}
          <div className="header-input-area">
            {lastPath === 'template' && (
              <Input
                className="header-input"
                value={searchValue}
                theme={INPUT_THEME.DARK}
                placeholder={
                  decksQuery.search ?? t('dashboard.search.search_on_decktopus')
                }
                icon={icons.find}
                size={INPUT_SIZE.MEDIUM}
                onChange={(value) => setSearchValue(value)}
              />
            )}
          </div>
        </div>
      </div>
    )
  },
)
