import { NodeType } from 'polotno/model/node-model'
import { StoreType } from 'polotno/model/store'
import { Vector2d } from 'konva/lib/types'
import { TKey } from 'src/i18n/types'
import { IMediaItem } from '../components/image-grid-loader/types'

export enum FontAlignments {
  left = 'format_align_left',
  right = 'format_align_right',
  center = 'format_align_center',
  justify = 'format_align_justify',
}

export enum FontVariants {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  'line-through' = 'line-through',
}

export type FontAlignment = keyof typeof FontAlignments
export type VariantProps = {
  fontWeight?: string
  fontStyle?: string
  textDecoration?: string
}

export enum LineEdgeEnum {
  singleLine = '',
  arrowHead = 'arrow',
  arrowFilled = 'triangle',
  arrowClosed = 'bar',
  arrowClosedFilled = 'square',
  arrowCircle = 'circle',
}

export type LineEdge = 'start' | 'end' | undefined

export interface IPolotnoComponent {
  store: StoreType
}

export enum TextSubType {
  BIG_TEXT = 'BigText',
  HEADING = 'Heading',
  SUBHEADING = 'Subheading',
  NORMAL_TEXT = 'NormalText',
  SMALL_TEXT = 'SmallText',
}

export type TextItemSubPropsType = {
  fontSize: number
  fontWeight: number
  textAlign: string
  custom: Record<string, any> & { subType: TextSubType }
}

export type TextItemType = {
  id: number
  label: TKey
  props: TextItemSubPropsType
}

export interface IMediaAttributes {
  width?: number
  height?: number
}

export interface IDropMediaElement {
  store: StoreType
  image: IMediaItem
  droppedPos?: Vector2d
  targetElement?: NodeType
  attrs?: IMediaAttributes
}

export interface IMediaDragStart {
  store: StoreType
  url: string
  attrs?: IMediaAttributes
  beforeDrop?: () => Promise<string | undefined>
  afterDrop?: () => void
}

export type SizeActionType = {
  prop: 'x' | 'y' | 'width' | 'height'
  tooltip: TKey
  label: string
}
