import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

// Define the size variable here (in pixels)
const size = 20

export const hitPointsStyles = ({
  colors,
  borderRadius,
  zIndex,
}: ITheme) => css`
  position: absolute;
  z-index: ${zIndex.hitPoints};

  &.corner {
    position: absolute;
    width: ${size}px;
    height: ${size}px;
    cursor: resize;

    &.top.left,
    &.bottom.right {
      cursor: nwse-resize;
    }

    &.bottom.left,
    &.top.right {
      cursor: nesw-resize;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      background: ${colors.primary.DEFAULT};
      border-radius: ${borderRadius[3]};
      width: ${size / 2}px;
      height: ${size}px;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      background: ${colors.primary.DEFAULT};
      border-radius: ${borderRadius[3]};
      height: ${size / 2}px;
      width: ${size}px;
    }

    &.top {
      top: -${size / 2}px;
    }

    &.bottom {
      bottom: -${size / 2}px;
      &:after {
        bottom: 0;
      }
    }

    &.left {
      left: -${size / 2}px;
      &:before {
        left: 0;
      }
    }

    &.right {
      right: -${size / 2}px;
      &:before {
        right: 0;
      }
    }

    &:hover {
      transform: scale(1.5);
    }
  }

  &:not(.corner) {
    background: ${colors.primary.DEFAULT};
    border-radius: ${borderRadius[3]};
    transform: translate(-50%, -50%);

    &:hover {
      transform: translate(-50%, -50%) scale(1.5);
    }

    &.top,
    &.bottom {
      left: 50%;
      height: ${size / 2}px;
      width: ${size * 2}px;
      cursor: ns-resize;
    }

    &.left,
    &.right {
      top: 50%;
      width: ${size / 2}px;
      height: ${size * 2}px;
      cursor: ew-resize;
    }

    &.top {
      top: -1px;
    }

    &.bottom {
      top: calc(100% + 1px);
    }

    &.left {
      left: -1px;
    }

    &.right {
      left: calc(100% + 1px);
    }
  }
`
