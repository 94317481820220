import React, { useCallback, useState } from 'react'
import { VALIDATION_RULE_TYPES, useLanguage, useNotification } from 'src/hooks'
import { useFolderApi } from 'src/hooks/api/useFolderApi'
import { Dialog, FOLDER_TYPE, FolderItem } from 'src/lib'
import { Modal } from 'src/lib/modal'
import { IDashboardNewFolder } from './types'

export const DashboardNewFolder: React.FC<IDashboardNewFolder> = React.memo(
  () => {
    const { t } = useLanguage()
    const { success } = useNotification()
    const { createFolder } = useFolderApi()

    const [newFolderName, setNewFolderName] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)

    const onClickHandler = () => {
      setNewFolderName('')
      setIsModalOpen(true)
    }

    const onSubmitHandler = useCallback(() => {
      if (!isModalOpen) {
        return
      }
      setIsModalOpen(false)
      createFolder(newFolderName).then((status) => {
        if (status) {
          success('dashboard.folder.folder_is_created')
          setNewFolderName('')
        }
      })
    }, [newFolderName, isModalOpen])

    const onCloseHandler = useCallback(() => {
      setNewFolderName('')
      setIsModalOpen(false)
    }, [isModalOpen])

    return (
      <>
        <FolderItem
          type={FOLDER_TYPE.CREATE}
          text={t('dashboard.folder.create_new_folder')}
          onClick={onClickHandler}
        />
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <Dialog
            title={t('dashboard.folder.create_folder')}
            submitLabel={t('common.actions.create')}
            onSubmit={onSubmitHandler}
            promptPlaceholder={t('dashboard.folder.type_folder_name')}
            onPromptChange={setNewFolderName}
            promptValue={newFolderName}
            onCloseClick={onCloseHandler}
            promptValidationRules={{
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  value: 3,
                  name: t('dashboard.folder.name'),
                }),
              },
            }}
          />
        </Modal>
      </>
    )
  },
)
