import React, { useMemo } from 'react'

import { CANVAS_TYPE } from 'src/components/canvas'
import { IDragController } from './types'
import { dragControllerStyles } from './styles'
import { Element } from '../element'
import {
  ComponentListDataSchema,
  ComponentTextDataSchema,
} from 'src/types/api/requestObjects'

export const NonDragController: React.FC<IDragController> = React.memo(
  ({
    canvasType = CANVAS_TYPE.STATIC,
    data,
    scale = 1,
    zIndexList,
    slideDataId,
    className,
    dataAttr,
  }) => {
    const zIndex = useMemo(() => {
      if (data.positions.zIndex === 2) {
        return 2
      } else {
        return zIndexList.findIndex((i) => i === data.positions.zIndex) + 10
      }
    }, [data.positions.zIndex, zIndexList])

    return (
      <div
        css={dragControllerStyles({ canvasType })}
        style={{
          width: `${data.positions.width}px`,
          height: `${data.positions.height}px`,
          top: `${data.positions.y}px`,
          left: `${data.positions.x}px`,
          zIndex,
        }}
        className={className}
        {...dataAttr}
      >
        <Element
          data={data}
          overrides={{
            fontSize:
              (data.data as ComponentTextDataSchema)?.style?.font?.size ||
              undefined,
            fontBodySize:
              (data.data as ComponentListDataSchema)?.style?.fontBody?.size ||
              undefined,
          }}
          scale={scale}
          slideDataId={slideDataId}
          canvasType={canvasType}
        />
      </div>
    )
  },
)

NonDragController.displayName = 'NonDragController'
