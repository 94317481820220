import React, { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, cleanClickCreateList, setActiveSlideID } from 'src/store'

import { SlideCard, NewSlide } from './components'
import { IEditSlides } from './types'
import { editSlidesStyles } from './styles'
import { CANVAS_TYPE } from 'src/components/canvas'

export const EditSlides: React.FC<IEditSlides> = React.memo(
  ({ canvasType = CANVAS_TYPE.STATIC, className, dataAttr }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()

    const { activeSlides, activeSlideId } = useSelector(
      ({ edit }: RootState) => ({
        activeSlides: edit.activeDeck.data?.deckData?.data.slides,
        activeSlideId: edit.activeSlideID,
      }),
    )

    const sortSlides = useMemo(() => {
      return activeSlides
        ?.slice()
        .filter(({ isDeleted }) => !isDeleted)
        .sort((a, b) => a.orderIndex - b.orderIndex)
    }, [activeSlides])

    const locationContext = useMemo(() => {
      const contextArray = location.pathname.split('/')
      return contextArray[1]
    }, [location.pathname])

    // select the slide by url params
    useEffect(() => {
      if (!params.slide) {
        navigate(`/${locationContext}/${params.id}/1`, { replace: true })
      }
      if (!sortSlides?.length) {
        return
      }
      const targetSlide: number = params.slide ? parseInt(params?.slide) : 0
      const maxSlide: number = sortSlides.length
      if (targetSlide > maxSlide) {
        navigate(`/${locationContext}/${params.id}/${maxSlide}`, {
          replace: true,
        })
      } else if (targetSlide < 1) {
        navigate(`/${locationContext}/${params.id}/1`, { replace: true })
      } else {
        const targetSlideId = sortSlides[targetSlide - 1]?.slideId
        targetSlideId && dispatch(setActiveSlideID(targetSlideId))
      }
    }, [sortSlides, params])

    // if slide forced to change by undo or any other functionality, sync url with it
    useEffect(() => {
      if (sortSlides && activeSlideId) {
        const targetSlideNumber =
          sortSlides.findIndex(({ slideId }) => slideId === activeSlideId) + 1

        // to prevent memory leak, run this only there is mismatch between url params and the state
        if (targetSlideNumber.toString() !== params?.slide) {
          navigate(`/${locationContext}/${params.id}/${targetSlideNumber}`, {
            replace: true,
          })
        }
      }
    }, [activeSlideId, sortSlides])

    const onSlideClick = useCallback((slideId?: number) => {
      dispatch(cleanClickCreateList())
      navigate(`/${locationContext}/${params.id}/${slideId}`, {
        replace: true,
      })
    }, [])

    const renderSortedSlides = useMemo(
      () =>
        sortSlides?.map((slideData, index) => (
          <SlideCard
            key={index}
            orderIndex={slideData.orderIndex}
            canvasType={canvasType}
            data={slideData}
            isActive={activeSlideId === slideData.slideId}
            onClick={() => onSlideClick(index + 1)}
          />
        )),
      [sortSlides, activeSlideId],
    )

    return (
      <div css={editSlidesStyles} className={className} {...dataAttr}>
        {renderSortedSlides}
        <NewSlide />
      </div>
    )
  },
)

EditSlides.displayName = 'EditSlides'
