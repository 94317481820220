import React, { useState, useCallback, useEffect } from 'react'
import { statusCss, wrapperCss, sliderCss, inputCss } from './styles'
import { ISwitch, SWITCH_SIZE } from './types'

export const Switch: React.FC<ISwitch> = React.memo(
  ({
    className,
    dataAttr,
    disabled = false,
    size = SWITCH_SIZE.LARGE,
    selected = false,
    onChange,
  }) => {
    const [isSelected, setIsSelected] = useState(selected)
    useEffect(() => {
      setIsSelected(selected)
    }, [selected])
    const onClickSwitch = useCallback(() => {
      if (disabled) {
        return
      }
      setIsSelected(!isSelected)
      if (onChange) {
        onChange(!isSelected)
      }
    }, [isSelected, disabled, onChange])

    return (
      <div className={className} {...dataAttr}>
        <label css={wrapperCss(size)}>
          <input
            type="checkbox"
            css={inputCss}
            checked={isSelected}
            onChange={onClickSwitch}
            disabled={disabled}
          />
          <span
            css={[sliderCss(size), statusCss({ isSelected, disabled, size })]}
          />
        </label>
      </div>
    )
  },
)
