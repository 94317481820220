import React, { useEffect, useMemo, useState } from 'react'

import { IPlans } from './types'
import {
  discountStyles,
  loaderStyles,
  subtitleStyles,
  titleStyles,
  wrapperStyles,
} from './styles'
import { CoolSwitch } from 'src/lib/cool-switch'
import { usePlanApi } from 'src/hooks/api/usePlanApi'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { PLAN_CARD_THEME, PlanCard } from 'src/components/plan-card'
import { PlanNames, PlanPeriods } from 'src/types/api/enums'
import { useLanguage } from 'src/hooks'
import { CURRENCY_DATA } from 'src/data'
import { usePlanFeatures } from './hooks'
import { Loader } from 'src/lib'

export const Plans: React.FC<IPlans> = React.memo(
  ({
    className,
    dataAttr,
    upgradeModal,
    title,
    subtitle,
    availablePlanNames,
    context,
  }) => {
    const { t } = useLanguage()
    const [selectedItem, setSelectedItem] = useState(
      upgradeModal ? PlanPeriods.MONTHLY : PlanPeriods.ANNUAL,
    )
    const { getPossiblePlans, isLoading } = usePlanApi()
    const { getTitle, getFeatures } = usePlanFeatures()

    useEffect(() => {
      getPossiblePlans()
    }, [])

    const { currentPlan, currentSeats } = useSelector(
      ({ user }: RootState) => ({
        currentSeats: user.data?.activeUserPlan?.seatSize,
        currentPlan: user.data?.activeUserPlan?.plan,
      }),
    )

    const possiblePlans = useSelector(
      ({ plan }: RootState) => plan.possiblePlans,
    )

    const availablePossiblePlans = useMemo(() => {
      let tempPlans = possiblePlans
      if (availablePlanNames?.length) {
        tempPlans =
          possiblePlans?.filter(({ name }) => {
            return availablePlanNames.includes(name)
          }) || []
      }

      return tempPlans
    }, [availablePlanNames, possiblePlans])

    const hasMonthlyPlans = useMemo(
      () =>
        availablePossiblePlans?.filter(
          ({ period }) => period === PlanPeriods.MONTHLY,
        ),
      [availablePossiblePlans],
    )

    const filteredPlans = useMemo(
      () =>
        availablePossiblePlans?.filter(({ period }) => period === selectedItem),
      [availablePossiblePlans, selectedItem, currentSeats],
    )

    useEffect(() => {
      if (availablePossiblePlans?.length && !hasMonthlyPlans?.length) {
        setSelectedItem(PlanPeriods.ANNUAL)
      }
    }, [availablePossiblePlans, hasMonthlyPlans])

    const switchItems = useMemo(
      () => [
        {
          label: t('common.plan_period.monthly'),
          onClick: () => {
            setSelectedItem(PlanPeriods.MONTHLY)
          },
        },
        {
          label: t('common.plan_period.annually'),
          onClick: () => {
            setSelectedItem(PlanPeriods.ANNUAL)
          },
        },
      ],
      [],
    )

    if (isLoading) {
      return (
        <div css={loaderStyles}>
          <Loader size={16} />
        </div>
      )
    }

    return (
      <div
        css={wrapperStyles(upgradeModal!)}
        className={className}
        {...dataAttr}
      >
        <div>
          {upgradeModal && (
            <div className="switch-area">
              <div css={titleStyles}>{title}</div>
              <div css={subtitleStyles}>{subtitle}</div>
            </div>
          )}
          {!!hasMonthlyPlans?.length && (
            <div className="switch-area">
              <CoolSwitch
                selectedItem={parseInt(selectedItem)}
                items={switchItems}
                upgradeModal={upgradeModal}
              />
              {upgradeModal && (
                <div css={discountStyles}>
                  {t('subscriptions.plan.discount_info')}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="plans-list">
          {filteredPlans?.map((plan) => (
            <PlanCard
              key={plan.name}
              name={plan.name}
              period={plan.period}
              upgradeModal={upgradeModal}
              currentSeats={
                currentPlan?.name === PlanNames.BUSINESS ? currentSeats : 0
              }
              price={plan.price.unitAmount / 100}
              currency={
                plan.price.currency
                  ? CURRENCY_DATA[plan.price.currency].symbol
                  : ''
              }
              featuresTitle={getTitle({ name: plan.name, period: plan.period })}
              features={getFeatures({ name: plan.name, period: plan.period })}
              featuresHeight={1000}
              theme={
                plan.name === PlanNames.PRO
                  ? PLAN_CARD_THEME.GRADIENT
                  : PLAN_CARD_THEME.DEFAULT
              }
              context={context}
            />
          ))}
        </div>
      </div>
    )
  },
)
