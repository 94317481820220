import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const propDropdownStyles = css`
  position: relative;
`

interface IPropDropdownMenuStyles {
  isOpen?: boolean
}
export const propDropdownMenuStyles =
  ({ isOpen }: IPropDropdownMenuStyles) =>
  ({ colors, gap, borderRadius }: ITheme) => css`
    background: ${colors.dark.DEFAULT};
    position: absolute;
    display: flex;
    top: calc(100% - 2px);
    left: 0;
    padding: ${gap.small}px;
    gap: ${gap.small}px;
    border-radius: ${borderRadius[4]};
    z-index: 1;
    & > * {
      width: 24px;
      height: 24px;
    }

    ${!isOpen &&
    `
			opacity: 0;
			pointer-events: none;
			position: absolute;
			z-index: -1;
		`}
  `
