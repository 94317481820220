import React, { useCallback, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { IFormQuestion } from './types'
import { PropHeader } from '../../props'
import { FormQuestionTypes } from 'src/types/api/enums'
import { FormQuestionText } from './FormQuestionText'
import { FormQuestionOption } from './FormQuestionOption'
import { FormQuestionRate } from './FormQuestionRate'
import { Switch } from 'src/lib'
import { formQuestionStyles } from './styles'
import { DeepPartial } from 'src/types'
import {
  ComponentFormDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'

export const FormQuestion: React.FC<IFormQuestion> = React.memo(
  ({ components, question, options }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedQuestions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['questions'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentFormDataSchema).questions

            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleRemove = useCallback(() => {
      const newQuestions = selectedQuestions?.filter(
        ({ questionId }) => questionId !== question.questionId,
      )

      const partialData: DeepPartial<ComponentFormDataSchema> = {
        questions: newQuestions,
      }
      const updatedComponents: UpdateComponentSchema[] =
        ComponentServices.updateComponentData<ComponentFormDataSchema>({
          components,
          partialData,
        })

      dispatch(componentsUpdate({ components: updatedComponents }))
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }, [components, question])

    const changeRequirement = useCallback(
      (reqirement: boolean) => {
        const newQuestionsData: ComponentFormDataSchema['questions'] =
          JSON.parse(JSON.stringify(selectedQuestions))

        const targetQuestion = newQuestionsData.findIndex(
          ({ questionId }) => questionId === question.questionId,
        )

        newQuestionsData[targetQuestion].isRequired = reqirement

        const partialData: DeepPartial<ComponentFormDataSchema> = {
          questions: newQuestionsData,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedQuestions, question],
    )

    const renderQuestion = useMemo(() => {
      switch (question.questionType) {
        case FormQuestionTypes.TEXT:
          return (
            <FormQuestionText
              question={question}
              options={options}
              components={components}
            />
          )
        case FormQuestionTypes.OPTIONS:
          return (
            <>
              <FormQuestionText
                question={question}
                options={options}
                components={components}
              />
              <FormQuestionOption
                question={question}
                options={options}
                components={components}
              />
            </>
          )
        case FormQuestionTypes.RATE:
          return (
            <>
              <FormQuestionText
                question={question}
                options={options}
                components={components}
              />
              <FormQuestionRate
                question={question}
                options={options}
                components={components}
              />
            </>
          )
      }
    }, [question.questionType, components, options])

    return (
      <div css={formQuestionStyles} className="grid">
        <div className="c-full">
          <PropHeader
            text={question.questionText}
            sideButton={{
              label: t('common.actions.remove'),
              onClick: handleRemove,
            }}
          />
          {renderQuestion}
        </div>
        <div className="c-full control-row">
          <span>{t('common.required')}</span>
          <Switch selected={question.isRequired} onChange={changeRequirement} />
        </div>
      </div>
    )
  },
)

FormQuestion.displayName = 'FormQuestion'
