import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const scratchPageStyles = ({
  gap,
  dimensions,
  breakpointsV2: breakpoints,
  colors,
}: ITheme) => css`
  color: ${colors.white.DEFAULT};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${gap.xlarge}px 0
    ${dimensions.initPageFooterHeight.DEFAULT + gap.xlarge}px;
  gap: ${gap.xlarge}px;
  overflow-y: auto;

  img {
    width: ${dimensions.deckInitImageWidth.small}px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    gap: ${gap['5xlarge']}px;
    img {
      width: ${dimensions.deckInitImageWidth.DEFAULT}px;
    }
  }
`
