import * as React from 'react'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import ToggleButton from '@mui/material/ToggleButton'
import { observer } from 'mobx-react-lite'
import { TextElementType } from 'polotno/model/text-model'
import { MaterialSymbol, SymbolCodepoints } from 'react-material-symbols'
import StyledToggleButtonGroup from 'src/components/toggle-button-group'
import {
  FontAlignment,
  FontAlignments,
  FontVariants,
  IPolotnoComponent,
  VariantProps,
} from '../../types'

function getElementVariants(textElement: TextElementType) {
  const initialFormats: FontVariants[] = []
  const isBold =
    textElement.fontWeight === 'bold' ||
    textElement.fontWeight === '500' ||
    textElement.fontWeight === '700' ||
    textElement.fontWeight === '600'
  if (isBold) {
    initialFormats.push(FontVariants.bold)
  }
  if (textElement.fontStyle === 'italic') {
    initialFormats.push(FontVariants.italic)
  }
  if (textElement.fontStyle === 'italic') {
    initialFormats.push(FontVariants.italic)
  }
  if (textElement.textDecoration.includes('underline')) {
    initialFormats.push(FontVariants.underline)
  }
  if (textElement.textDecoration.includes('line-through')) {
    initialFormats.push(FontVariants['line-through'])
  }
  return initialFormats
}

const TextFontVariantActions = observer<IPolotnoComponent>(({ store }) => {
  const element = store.selectedElements[0]
  const textElement = element as TextElementType

  const allTextItems = store.selectedElements?.every(
    (element) => element.type === 'text',
  )

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const [alignment, setAlignment] = React.useState<FontAlignment>(
    textElement.align as FontAlignment,
  )

  const [formats, setFormats] = React.useState<Array<FontVariants>>(
    getElementVariants(textElement),
  )

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: FontVariants[],
  ) => {
    setFormats(newFormats)

    const format: VariantProps = {
      fontWeight: newFormats.includes(FontVariants.bold) ? 'bold' : 'normal',
      fontStyle: newFormats.includes(FontVariants.italic) ? 'italic' : 'normal',
      textDecoration: newFormats
        .filter(
          (format) =>
            format === FontVariants.underline ||
            format === FontVariants['line-through'],
        )
        .join(' '),
    }
    store.selectedElements.forEach((element) => {
      element.set({
        ...format,
      })
    })
  }

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    if (newAlignment) {
      setAlignment(newAlignment as FontAlignment)
      store.selectedElements.forEach((element) => {
        element.set({
          align: newAlignment,
        })
      })
    }
  }

  return allTextItems ? (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexShrink: 0,
          flexGrow: 1,
        }}
      >
        <StyledToggleButtonGroup size="small" value={alignment} exclusive>
          <ToggleButton
            value={alignment}
            aria-label="left aligned"
            onClick={handleClick}
          >
            <MaterialSymbol
              icon={FontAlignments[alignment] as SymbolCodepoints}
              size={20}
              weight={300}
            />
          </ToggleButton>
        </StyledToggleButtonGroup>
        <StyledToggleButtonGroup
          size="small"
          value={formats}
          onChange={handleFormat}
          aria-label="text formatting"
          sx={{
            'button:first-of-type': {
              marginLeft: 0,
            },
          }}
        >
          <ToggleButton value={FontVariants.bold} aria-label="bold">
            <MaterialSymbol icon="format_bold" size={20} weight={300} />
          </ToggleButton>
          <ToggleButton value={FontVariants.italic} aria-label="italic">
            <MaterialSymbol icon="format_italic" size={20} weight={300} />
          </ToggleButton>
          <ToggleButton value={FontVariants.underline} aria-label="underlined">
            <MaterialSymbol icon="format_underlined" size={20} weight={300} />
          </ToggleButton>
          <ToggleButton
            value={FontVariants['line-through']}
            aria-label="underlined"
          >
            <MaterialSymbol
              icon="format_strikethrough"
              size={20}
              weight={300}
            />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
      <Popover
        id={'font-alignments'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 48,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={32}
        elevation={6}
      >
        <Stack direction="row" gap={3} alignItems="center">
          <StyledToggleButtonGroup
            size="small"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton value="left" aria-label="left aligned">
              <MaterialSymbol icon="format_align_left" size={20} weight={300} />
            </ToggleButton>
            <ToggleButton value="center" aria-label="centered">
              <MaterialSymbol
                icon="format_align_center"
                size={20}
                weight={300}
              />
            </ToggleButton>
            <ToggleButton value="right" aria-label="right aligned">
              <MaterialSymbol
                icon="format_align_right"
                size={20}
                weight={300}
              />
            </ToggleButton>
            <ToggleButton value="justify" aria-label="justified">
              <MaterialSymbol
                icon="format_align_justify"
                size={20}
                weight={300}
              />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Stack>
      </Popover>
    </div>
  ) : null
})

export default TextFontVariantActions
