import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const mappingItemWrapperStyles = ({ dimensions, gap }: ITheme) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: ${dimensions.colorMappingItem.DEFAULT}px;
  min-height: ${dimensions.colorMappingItem.DEFAULT}px;
  gap: ${gap['2xsmall']}px;
`

export const mappingItemNameStyles = ({ colors, fontSize }: ITheme) => css`
  text-align: center;
  font-size: ${fontSize.small};
  color: ${colors.outline.DEFAULT};
`
