import { dataAttrType } from 'src/types'

export enum COLOR_PICKER_TYPE {
  SOLID = 'solid',
  GRADIENT = 'gradient',
}
export interface IColorPicker {
  noGradient?: boolean
  initialColors?: (string | undefined)[]
  initialType?: COLOR_PICKER_TYPE
  initialRotate?: number
  onChange: (colors: string[], rotate?: number) => void
  onClose?: (colors: string[], rotate?: number) => void
  onClickAdd?: (colors: string[], rotate?: number) => void
  className?: string
  dataAttr?: dataAttrType
}
