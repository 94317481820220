import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { INPUT_THEME, Input } from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import {
  ComponentMediaDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { VALIDATION_RULE_TYPES, useDebouncer, useLanguage } from 'src/hooks'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { videoInfoTextStyles } from './styles'
import { IEditPropSection } from '../../types'
import { PropHeader } from 'src/pages/deck-page/components/edit-properties/props'

export const WebsiteProps: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const [validUrl, setValidUrl] = useState(false)

    const selectedVideoUrl = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const title = (c.data as ComponentMediaDataSchema).mediaUrl
          if (a === '' && title) {
            return title
          }

          return title !== a ? '' : a
        }, ''),
      [components],
    )

    const [value, setValue] = useState(selectedVideoUrl)
    const handleOnChange = useCallback(
      (value?: string) => {
        setValue(value)
      },
      [components],
    )

    const debounced = useDebouncer(
      () => {
        if (validUrl) {
          updateComponentState(value)
        }
      },
      { delay: 500 },
    )

    useEffect(() => {
      debounced()
    }, [value, validUrl])

    const updateComponentState = useCallback(
      (val?: string) => {
        const partialData: DeepPartial<ComponentMediaDataSchema> = {
          mediaUrl: val,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentMediaDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, validUrl],
    )

    return (
      <div className="grid">
        <div className="c-full">
          <PropHeader text={t('edit.properties.website_props.website')} />
          <div css={videoInfoTextStyles}>
            {t('edit.properties.website_props.paste')}
          </div>
          <Input
            theme={INPUT_THEME.DARK}
            placeholder="https://www.decktopus.com"
            value={value}
            onChange={handleOnChange}
            onFocus={() => dispatch(setAllowDeleteShortcut(false))}
            onBlur={() => dispatch(setAllowDeleteShortcut(true))}
            constantValidation
            validation={{
              [VALIDATION_RULE_TYPES.REQUIRED]: {
                text: t('validation.error.required', {
                  name: t('webhook.url'),
                }),
              },
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  value: 3,
                  name: t('webhook.url'),
                }),
              },
              [VALIDATION_RULE_TYPES.VALIDURL]: {
                text: t('validation.error.url'),
              },
            }}
            onValidation={setValidUrl}
          />
        </div>
      </div>
    )
  },
)

WebsiteProps.displayName = 'WebsiteProps'
