import React from 'react'
import {
  wrapperStyles,
  innerStyles,
  innersStyles,
  imageStyles,
  titleStyles,
} from './styles'
import hideImg from 'src/assets/images/hide.svg'
import { useLanguage } from 'src/hooks'

export const NotSupportedPage: React.FC = React.memo(() => {
  const { t } = useLanguage()
  return (
    <div css={innersStyles}>
      <div css={wrapperStyles}>
        <div css={innerStyles}>
          <img src={hideImg} css={imageStyles} />
          <div css={titleStyles}>{t('not_supported.title')}</div>
        </div>
      </div>
    </div>
  )
})
export default NotSupportedPage
