import React from 'react'

import { IUserBadge } from './types'
import { planCardStyles, textAreaStyles, wrapperStyles } from './styles'
import { Avatar } from 'src/lib'

export const UserBadge: React.FC<IUserBadge> = React.memo(
  ({ name, email, plan, image, className, dataAttr }) => {
    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <Avatar data={[{ name, image }]} size={44} border={2} />
        <div css={textAreaStyles}>
          <div className="title">{name}</div>
          <div className="subtitle">{email}</div>
        </div>
        <div css={planCardStyles}>{plan}</div>
      </div>
    )
  },
)
