import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const aiAudienceStyles = (theme: ITheme) => css`
  position: relative;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: ${theme.gap.small}px;
`
