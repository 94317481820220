export default function getTextSize(
  text: string,
  fontSize: string,
  lineHeight: string,
  fontWeight = '400',
) {
  const mainDiv = document.createElement('div')
  mainDiv.style.display = 'inline-block'
  const span = document.createElement('div')
  span.style.display = '-webkit-box'
  span.style.minWidth = '200px'
  span.style.maxWidth = '1920px'
  span.style.whiteSpace = 'pre-wrap'
  span.style.overflowWrap = 'break-word'
  span.style.wordWrap = 'break-word'
  span.style.height = 'auto'
  span.style.lineHeight = lineHeight
  span.style.fontSize = fontSize
  span.style.fontFamily = document.body.style.fontFamily
  span.style.fontWeight = fontWeight
  span.style.visibility = 'hidden'
  span.style.textOverflow = 'ellipsis'
  span.style.webkitLineClamp = '10'
  span.style.webkitBoxOrient = 'vertical'
  span.style.overflow = 'hidden'

  span.innerHTML = text
  mainDiv.appendChild(span)
  document.body.append(mainDiv)
  const rect = mainDiv.getBoundingClientRect()
  mainDiv.remove()
  return rect
}
