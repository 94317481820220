import debounce from 'lodash/debounce'
import { observer } from 'mobx-react-lite'
import { StoreType } from 'polotno/model/store'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsMounted } from 'src/hooks/useMounted'
import { NewSlide } from 'src/pages/deck-page/components/edit-slides/components'
import { ThumbnailPage } from 'src/pages/deck-page/components/edit-slides/components/slide-card/ThumbnailPage'
import { editSlidesStyles } from 'src/pages/deck-page/components/edit-slides/styles'
import { RootState, SAVE_STATE } from 'src/store'
import { DeckV3Document } from 'src/types/api/responseObjects'
import { IPolotnoComponent } from '../../types'
import AutoScrollArea from '../scroll-area'
import {
  calculateDiff,
  getChangedContentIds,
  haveDiff,
  mapSlidesFromPages,
} from '../store/utils'
import { useTimelineStyles } from './styles'
import { setV3SaveState } from 'src/store/decks-v3/decksV3Slice'
import { showAddSlideDrawer } from 'src/store/generalV3'

interface ITimeLineProps extends IPolotnoComponent {}

type PageThumbnailProps = { id: string; timestamp: number }

const Timeline = observer<ITimeLineProps>(({ store }) => {
  const dispatch = useDispatch()
  const { document } = useSelector(
    ({ persistedDeckV3: deckV3 }: RootState) => ({
      document: deckV3.documentData?.document,
    }),
  )

  const isMounted = useIsMounted()

  const [pageImages, setPageImages] = useState<PageThumbnailProps[]>()

  const { classes } = useTimelineStyles()

  const getImages = useCallback(
    async (newPages?: StoreType['pages'], changes?: string[]) => {
      try {
        const pages = newPages ? newPages : store.pages
        if (changes && pageImages) {
          const newArr = [...pageImages]
          changes.forEach((changeId) => {
            const itemIdx = pageImages.findIndex((page) => page.id === changeId)
            if (itemIdx > -1) {
              newArr[itemIdx].timestamp = new Date().getTime()
            } else {
              newArr.push({ id: changeId, timestamp: new Date().getTime() })
            }
          })
          setPageImages(newArr)
        } else {
          const images: PageThumbnailProps[] = pages.map((page) => ({
            id: page.id,
            timestamp: new Date().getTime(),
          }))
          setPageImages(images)
        }
      } catch (error) {
        //console.warn("Error", error)
      }
    },
    [store.pages, pageImages],
  )

  useEffect(() => {
    if (!isMounted) {
      getImages()
    }
  }, [isMounted, getImages])

  useEffect(() => {
    if (store.pages.length !== pageImages?.length) {
      getImages()
    }
  }, [getImages, pageImages?.length, store.pages.length])

  const handleStoreChange = useCallback(async () => {
    const newDocument = store.toJSON()

    const clonedNewDocument = JSON.parse(
      JSON.stringify(newDocument),
    ) as DeckV3Document

    const [previousSlides, newSlides] = mapSlidesFromPages(
      document?.pages,
      clonedNewDocument?.pages,
    )
    const diff = calculateDiff(previousSlides, newSlides)
    if (!haveDiff(diff)) {
      return
    } else {
      dispatch(setV3SaveState(SAVE_STATE.NOT_SAVED))
      const changes = getChangedContentIds(diff)
      getImages(store.pages, changes)
    }
  }, [dispatch, document?.pages, getImages, store])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleStoreChange = useCallback(
    debounce(handleStoreChange, 500),
    [handleStoreChange],
  )

  useEffect(() => {
    window.addEventListener(
      'editor:store:change',
      debouncedHandleStoreChange as unknown as EventListener,
      false,
    )

    return () => {
      window.removeEventListener(
        'editor:store:change',
        debouncedHandleStoreChange as unknown as EventListener,
        false,
      )
      debouncedHandleStoreChange.cancel()
    }
  }, [debouncedHandleStoreChange])

  const handleNewPage = () => {
    dispatch(showAddSlideDrawer(true))
    //store.addPage()
  }

  return (
    <AutoScrollArea>
      <div css={editSlidesStyles} className={classes.content}>
        <PageThumbnails store={store} pageImages={pageImages} />
        <NewSlide className={classes.newButton} onClick={handleNewPage} />
      </div>
    </AutoScrollArea>
  )
})
export default Timeline

interface IPageThumbnailsProps {
  store: StoreType
  pageImages?: PageThumbnailProps[]
}

const PageThumbnails = observer<IPageThumbnailsProps>(
  ({ store, pageImages }) => {
    const [droppedItems, setDroppedItems] = useState<string[]>([])
    const [internalImages, setInternalImages] = useState<
      PageThumbnailProps[] | undefined
    >(pageImages)
    const [selectedPageId, setSelectedPageId] = useState<string>()

    useEffect(() => {
      setInternalImages(pageImages)
    }, [pageImages])

    useEffect(() => {
      setSelectedPageId(store?.activePage?.id)
    }, [store?.activePage?.id])

    const onSwap = useCallback((swapItems: string[]) => {
      setDroppedItems(swapItems)
    }, [])

    const onDropFinished = useCallback(() => {
      const pages = store.pages.filter((page) => droppedItems.includes(page.id))
      if (pages.length === 2) {
        const idx0 = store.pages.findIndex((page) => page.id === pages[0].id)
        const idx1 = store.pages.findIndex((page) => page.id === pages[1].id)
        if (idx0 > -1 && idx1 > -1) {
          pages[0].setZIndex(idx1)
          pages[1].setZIndex(idx0)

          // below code block helps immediate update when drag & drop happens,
          // check the performance again.

          if (internalImages) {
            const tempElem = internalImages[idx0]
            const newArray = [...internalImages]
            newArray[idx1] = tempElem
            newArray[idx0] = internalImages[idx1]
            setInternalImages(newArray)
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [droppedItems, store.pages])

    const handleClick = useCallback(
      (pageId: string) => {
        store.selectPage(pageId)
        setSelectedPageId(pageId)
      },
      [store],
    )

    return store.pages.map((page) => (
      <ThumbnailPage
        key={page.id}
        isActive={page.id === selectedPageId}
        page={page}
        timeStamp={
          internalImages?.find((image) => image.id === page.id)?.timestamp
        }
        onIndexChange={onSwap}
        onDropFinished={onDropFinished}
        onClick={handleClick}
      />
    ))
  },
)
