import React, { useCallback } from 'react'
import { useLanguage } from 'src/hooks'
import { IFontFamily } from './types'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { fontBoxStyles } from './styles'
import { Icon, TooltipView, icons } from 'src/lib'
import { colors } from 'src/theme'
import { FontServices } from 'src/services'

export const FontFamily: React.FC<IFontFamily> = React.memo(
  ({
    id,
    primary,
    secondary,
    context,
    displayOnly,
    deleteMode,
    className,
    dataAttr,
  }) => {
    const { t } = useLanguage()
    const { activeThemeFontData, activeThemeFontId } = useSelector(
      ({ edit }: RootState) => ({
        activeThemeFontData: edit.activeDeck.data?.deckData?.themeFont.data,
        activeThemeFontId: edit.activeDeck.data?.deckData?.themeFont.id,
      }),
    )

    const primaryFamily = primary ?? activeThemeFontData?.primary
    const secondaryFamily = secondary ?? activeThemeFontData?.secondary

    const fontName = useCallback(
      (font?: string) => FontServices.fontFamilyCleaner(font),
      [],
    )

    const fontWeight = useCallback(
      (font?: string) => FontServices.fontWeightCalculator(font),
      [],
    )

    return (
      <div
        css={fontBoxStyles({
          isSelected: activeThemeFontId === id && !displayOnly,
          deleteMode,
          context,
        })}
        className={className}
        {...dataAttr}
      >
        {context && context !== 'predefined' && (
          <div className="font-box-icon">
            {context === 'sharedOnOrg' && (
              <Icon icon={icons.team} color={colors.white.DEFAULT} size={16} />
            )}
            {context === 'own' && (
              <Icon icon={icons.user} color={colors.white.DEFAULT} size={16} />
            )}
          </div>
        )}
        <div
          className="font-display"
          style={{
            fontFamily: fontName(primaryFamily),
            fontWeight: fontWeight(primaryFamily),
          }}
        >
          {primaryFamily}
        </div>
        <div className="border" />
        <div
          className="font-display"
          style={{
            fontFamily: fontName(secondaryFamily),
            fontWeight: fontWeight(secondaryFamily),
          }}
        >
          {secondaryFamily}
        </div>
        {context !== 'predefined' && (
          <div className="font-family-actions">
            <Icon icon={icons.trash_can} color={colors.white.DEFAULT} />
          </div>
        )}
        <TooltipView
          width={200}
          text={t('edit.design.default_fonts_delete')}
          className="fonts-tooltip"
        />
      </div>
    )
  },
)

FontFamily.displayName = 'FontFamily'
