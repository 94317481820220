import { figureToSvg, TYPES } from 'polotno/utils/figure-to-svg'

const FIGURE_DEFAULTS = {
  width: 300,
  height: 300,
  fill: '#909AAE',
  stroke: '#0c0c0c',
  strokeWidth: 0,
  url: '',
}

const subTypes = Object.keys(TYPES)
const DEFAULTS = [FIGURE_DEFAULTS]

const FIGURES: any[] = []
subTypes.forEach((subType) => {
  DEFAULTS.forEach((t) => {
    FIGURES.push(Object.assign({ subType: subType }, t))
  })
})

FIGURES.forEach((figure) => (figure.url = figureToSvg(figure)))
const squareIdx = FIGURES.findIndex((figure) => figure.subType === 'rect')
const triangle = FIGURES.findIndex((figure) => figure.subType === 'triangle')
const circle = FIGURES.findIndex((figure) => figure.subType === 'circle')
const diamond = FIGURES.findIndex((figure) => figure.subType === 'diamond')

let tempElement = FIGURES[2]
FIGURES[2] = FIGURES[squareIdx]
FIGURES[squareIdx] = tempElement

tempElement = FIGURES[0]
FIGURES[0] = FIGURES[circle]
FIGURES[circle] = tempElement

tempElement = FIGURES[1]
FIGURES[1] = FIGURES[triangle]
FIGURES[triangle] = tempElement

tempElement = FIGURES[3]
FIGURES[3] = FIGURES[diamond]
FIGURES[diamond] = tempElement

export default FIGURES
