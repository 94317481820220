import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDispatch } from 'react-redux'
import throttle from 'lodash/throttle'

import { listPropsStyles } from './styles'
import { IEditPropSection } from '../../types'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ListItemDragLayer, DraggableListItem } from './DraggableListItem'
import { NewListItem } from './NewListItem'
import { ListTypes } from 'src/types/api/enums'
import { ComponentServices } from 'src/services'
import { componentsUpdate, setSaveState, SAVE_STATE } from 'src/store'

export const ListItems: React.FC<IEditPropSection> = React.memo(
  ({ components, isGrid, className, dataAttr }) => {
    const [expandedIndex, setExpandedIndex] = useState<number>()
    const dispatch = useDispatch()

    useEffect(() => {
      const listener = ({ detail }: any) => {
        setExpandedIndex(detail)
      }
      document.addEventListener('list-item-click', listener)
      return () => {
        document.removeEventListener('list-item-click', listener)
      }
    }, [])

    const {
      selectedItems,
      selectedListType,
      isMaxItemCount,
      selectedMediaType,
    } = useMemo(() => {
      const items = components?.reduce(
        (
          a: ComponentListDataSchema['listItems'] | undefined,
          c: UpdateComponentSchema,
        ) => {
          const listItems = (c.data as ComponentListDataSchema).listItems
          return a === undefined && listItems
            ? listItems
            : listItems !== a
              ? undefined
              : a
        },
        undefined,
      )

      const listType = components?.reduce(
        (
          a: ComponentListDataSchema['listType'] | undefined,
          c: UpdateComponentSchema,
        ) => {
          const type = (c.data as ComponentListDataSchema).listType
          return a === undefined && type ? type : type !== a ? undefined : a
        },
        undefined,
      )

      const maxCount = listType === ListTypes.TIMELINE ? 8 : 12
      const isMax = (items?.length || 0) >= maxCount

      const mediaType =
        listType === ListTypes.MEDIA ? items?.[0]?.image?.type : undefined

      return {
        selectedItems: items,
        selectedListType: listType,
        isMaxItemCount: isMax,
        selectedMediaType: mediaType,
      }
    }, [components])

    const moveItem = useCallback(
      throttle((dragIndex: number, hoverIndex: number) => {
        const newItems = selectedItems ? [...selectedItems] : []
        const [reorderedItem] = newItems.splice(dragIndex, 1)
        newItems.splice(hoverIndex, 0, reorderedItem)

        const partialData = { listItems: newItems }
        const updatedComponents = ComponentServices.updateComponentData({
          components,
          partialData,
        })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }, 50),
      [components, selectedItems, dispatch],
    )

    return (
      <DndProvider backend={HTML5Backend}>
        <div css={listPropsStyles} className={className} {...dataAttr}>
          {selectedItems?.map((item, index) => (
            <DraggableListItem
              key={item.id}
              index={index}
              id={item.id}
              moveItem={moveItem}
              setExpandedIndex={setExpandedIndex}
              data={item}
              isExpanded={expandedIndex === index}
              onClick={() => setExpandedIndex(index)}
              listItems={selectedItems}
              components={components}
              type={selectedListType}
              isGrid={isGrid}
            />
          ))}
          {!isMaxItemCount && (
            <NewListItem
              selectedMediaType={selectedMediaType}
              selectedItems={selectedItems}
              components={components}
              onNewItemClick={() => setExpandedIndex(selectedItems?.length)}
            />
          )}
        </div>
        <ListItemDragLayer />
      </DndProvider>
    )
  },
)

ListItems.displayName = 'ListItems'
