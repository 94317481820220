import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'lodash/isEqual'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { useDispatch } from 'react-redux'
import usePrevious from 'src/hooks/usePrevious'
import { Spinner } from 'src/lib'
import { SlideNumber } from './components'
import { draggableAreaStyles } from './styles'
import { useStyles } from './stylesV3'
import { ISlideCardV3 } from './types'
import SlideCardMenu, { SlideMenuAction } from './SlideCardMenu'
import { StoreType } from 'polotno/model/store'
import { setDeletedSlides } from 'src/store/decks-v3'

const SLIDE_CARD_TYPE = 'slide_card_type'

const ThumbnailPageComp: React.FC<ISlideCardV3> = ({
  page,
  timeStamp,
  isActive,
  onClick,
  className,
  onIndexChange,
  onDropFinished,
}) => {
  const [image, setImage] = useState<string>()
  const prevTimestamp = usePrevious(timeStamp)
  const dispatch = useDispatch()

  const [{ isDragging }, elementDrag] = useDrag(() => {
    return {
      type: SLIDE_CARD_TYPE,
      item: {
        slideId: page.id,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: onDropFinished,
    }
  }, [page, onDropFinished])

  const [{ isOver, dropItem }, dropTarget] = useDrop(
    () => ({
      accept: [SLIDE_CARD_TYPE],
      collect: (monitor: DropTargetMonitor<{ slideId: string }, void>) => ({
        isOver: monitor.isOver(),
        dropItem: monitor.getItem(),
      }),
    }),
    [],
  )
  const { classes, cx } = useStyles({
    isDragging,
    isOver: !isDragging && isOver,
    isActive,
  })

  const elRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isActive && elRef.current) {
      elRef.current.scrollIntoView({ block: 'end', inline: 'center' })
    }
  }, [isActive])

  useEffect(() => {
    if (isOver && page.id) {
      if (dropItem.slideId !== page.id) {
        onIndexChange?.([dropItem.slideId, page.id])
      } else {
        onIndexChange?.([])
      }
    }
  }, [isOver, dropItem, page.id, onIndexChange])

  const getImage = useCallback(
    (waitFirst: boolean) => {
      setTimeout(
        async () => {
          try {
            const img = await page.store.toDataURL({
              pageId: page.id,
              pixelRatio: 0.2,
              quickMode: true,
            })
            setImage(img)
          } catch (error) {
            console.warn('Thumbnail image', error)
          }
        },
        waitFirst ? 1000 : 1,
      )
    },
    [page],
  )

  useEffect(() => {
    if (prevTimestamp !== timeStamp) {
      getImage(!prevTimestamp)
    }
  }, [getImage, prevTimestamp, timeStamp])

  const pageNumber = page.store.pages.findIndex(
    (item: any) => item.id === page.id,
  )

  const handleMenuAction = useCallback(
    (action: SlideMenuAction) => {
      const store = page.store as StoreType
      switch (action) {
        case 'add':
          const activeId = store.activePage?.id
          const pageIdx = store.pages.findIndex(
            (spage) => spage.id === activeId,
          )
          const newPage = store?.addPage()
          if (pageIdx > -1) {
            newPage.setZIndex(pageIdx + 1)
          }
          setTimeout(() => {
            store.selectPage(newPage.id)
          }, 100)

          break
        case 'duplicate':
          page.clone({ custom: undefined })
          break
        case 'delete':
          const idx = store?.pages.findIndex((spage) => spage.id === page.id)
          if (idx === -1) {
            return
          }
          if (idx > 0) {
            const prevId = store?.pages[idx - 1]?.id
            setTimeout(() => {
              store.selectPage(prevId)
            }, 0)
          }

          const internaId = page.custom?.internalId
          store?.deletePages([page.id])
          internaId && dispatch(setDeletedSlides([internaId]))
          break
      }
    },
    [dispatch, page],
  )

  const renderComponents = useMemo(
    () => (
      <div
        aria-label={page.id}
        css={draggableAreaStyles}
        style={{
          opacity: isDragging ? 0 : 1,
        }}
        className={`theme-default`}
        ref={elementDrag}
      >
        <SlideCardMenu onActionClick={handleMenuAction} />
        <SlideNumber current={pageNumber + 1} className={classes.slideNumber} />
        <img
          src={image}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
    ),
    [
      classes.slideNumber,
      elementDrag,
      handleMenuAction,
      image,
      isDragging,
      page.id,
      pageNumber,
    ],
  )

  return (
    <div
      onClick={() => onClick?.(page.id)}
      className={cx(classes.root, className)}
      ref={dropTarget}
    >
      <div ref={elRef} />
      {image === undefined ? <Spinner size={48} /> : renderComponents}
    </div>
  )
}

function propsAreEqual(
  prevProps: Readonly<ISlideCardV3>,
  nextProps: Readonly<ISlideCardV3>,
) {
  return Object.keys(prevProps).every((key) => {
    const val = isEqual(
      prevProps[key as keyof typeof prevProps],
      nextProps[key as keyof typeof nextProps],
    )
    return val
  })
}
export const ThumbnailPage = React.memo(ThumbnailPageComp, propsAreEqual)
ThumbnailPage.displayName = 'ThumbnailPage'
