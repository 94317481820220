import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { StoreType } from 'polotno/model/store'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import SizeAction from '../toolbar/SizeAction'
import { SizeActionType } from '../../types'
import AutoScrollArea from '../scroll-area'
import { useStyles } from './styles'
import { useLanguage } from 'src/hooks'

const SizePanel = observer(({ store }: { store: StoreType }) => {
  const { classes } = useStyles()
  const { t } = useLanguage()
  const element = store.selectedElements[0]
  let props: SizeActionType[] = [
    {
      prop: 'x',
      label: 'X:',
      tooltip: 'v3.toolbar.x',
    },
    {
      prop: 'y',
      label: 'Y:',
      tooltip: 'v3.toolbar.y',
    },
    {
      prop: 'width',
      label: 'W:',
      tooltip: 'v3.toolbar.width',
    },
    {
      prop: 'height',
      label: 'H:',
      tooltip: 'v3.toolbar.height',
    },
  ]
  if (element && store.selectedElements.length > 0) {
    if (element.type === 'text') {
      props = props.filter((prop) => prop.prop !== 'height')
    }
  }

  useEffect(() => {
    if (store.selectedElements.length !== 1) {
      if (store.openedSidePanel === 'size-panel') {
        store.openSidePanel('')
      }
    }
  }, [store, store.selectedElements.length])

  return (
    <AutoScrollArea>
      <Stack className={classes.container}>
        <Typography variant="body2" fontWeight={500}>
          {t('v3.toolbar.size_and_position')}
        </Typography>
        <SizeAction store={store} props={props} />
      </Stack>
    </AutoScrollArea>
  )
})

export default SizePanel
