import { VideoElementType } from 'polotno/model/video-model'
import { selectImage } from 'polotno/side-panel/select-image'
import { selectSvg } from 'polotno/side-panel/select-svg'
import { selectVideo } from 'polotno/side-panel/select-video'
import getMediaTypeFromUrl from 'src/helpers/getMediaTypeFromUrl'
import { registerNextDomDrop } from 'polotno/canvas/page'
import { IDropMediaElement, IMediaDragStart } from '../types'

export const dropMediaElement = async ({
  store,
  image,
  droppedPos,
  targetElement,
  attrs,
}: IDropMediaElement) => {
  const mediaType = getMediaTypeFromUrl(image.url)

  if (mediaType === 'image') {
    selectImage({ src: image.url, droppedPos, targetElement, store })
    return
  }
  if (mediaType === 'video') {
    selectVideo({
      src: image.url,
      droppedPos,
      targetElement:
        targetElement?.type === 'video'
          ? (targetElement as VideoElementType)
          : undefined,
      store,
      attrs,
    })
    return
  }
  if (mediaType === 'svg') {
    selectSvg({ src: image.url, droppedPos, targetElement, store })
    return
  }
}

export const onMediaDragStart = ({
  store,
  url,
  attrs,
  beforeDrop,
  afterDrop,
}: IMediaDragStart) => {
  registerNextDomDrop(async (droppedPos, targetElement) => {
    const urlToDrop = (beforeDrop && (await beforeDrop())) ?? url
    dropMediaElement({
      store,
      image: { url: urlToDrop },
      droppedPos,
      targetElement,
      attrs,
    })
    afterDrop?.()
  })
}

export const onMediaDragEnd = () => {
  registerNextDomDrop(null)
}
