import { css } from '@emotion/react'
import { ITheme, gap } from 'src/theme'

export const mediaDisplayStyles = ({ colors, borderRadius }: ITheme) => css`
  position: relative;
  background: ${colors.dark.DEFAULT};
  width: 100%;
  border-radius: ${borderRadius[4]};
  aspect-ratio: 1;
  background-size: cover;
  background-position: center center;
  background-clip: border-box;
  overflow: hidden;

  &:hover {
    border: 1px solid ${colors.primary.DEFAULT};
  }
`

export const mediaDisplayOverlayStyles = ({ colors }: ITheme) => css`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: ${colors.overlay.modal};
  opacity: 0.7;
`

export const svgMaskStyles = ({ colors }: ITheme) => css`
  background-color: ${colors.white.DEFAULT};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`

export const mediaDisplayContentStyles = ({
  fontSize,
  fontWeight,
}: ITheme) => css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
  gap: ${gap['2xsmall']}px;

  & .icon-wrapper {
    max-width: 36px;
    height: 36px;
  }
`

export const mediaDisplayNameStyles = ({ gap }: ITheme) => css`
  text-align: center;
  padding: 0 ${gap['4xsmall']}px;
`
