export enum SAVE_STATE {
  NOT_SAVED = 'not_saved',
  FAILED = 'failed',
  SAVING = 'saving',
  SAVED = 'saved',
}

export type LoaderState<T> = {
  data?: T
  isLoading?: boolean
}

export type StrictLoaderState<T> = {
  data: T
  isLoading?: boolean
}
