import React from 'react'
import { AuthPageLayout } from 'src/layouts'
import { AUTH_PAGE_LAYOUT } from 'src/layouts/auth-page-layout/types'
import { ResetPasswordForm } from 'src/components/reset-password-form'

export const ResetPasswordPage: React.FC = React.memo(() => {
  return (
    <AuthPageLayout type={AUTH_PAGE_LAYOUT.LOGIN}>
      <ResetPasswordForm />
    </AuthPageLayout>
  )
})
