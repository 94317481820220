import Popover from '@mui/material/Popover'
import { observer } from 'mobx-react-lite'
import { VideoElementType } from 'polotno/model/video-model'
import { useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import StyledToggleButton from 'src/components/toggle-button'
import { useLanguage } from 'src/hooks'
import SliderWithInput from './SliderWithInput'
import { useVolumeStyles } from './styles'
import { IPolotnoComponent } from '../../types'

const VolumeAction = observer<IPolotnoComponent>(({ store }) => {
  const hasLockedItems = store.selectedElements?.some(
    (element) => element.locked,
  )
  const volume = (store.selectedElements?.[0] as VideoElementType).volume ?? 1

  const { classes } = useVolumeStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { t } = useLanguage()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'volume-popover' : undefined

  const handleChange = (newValue: number) => {
    const value = newValue
    store.selectedElements?.forEach((element) => {
      element.set({
        volume: value / 100,
      })
    })
  }

  return !hasLockedItems ? (
    <>
      <StyledToggleButton selected={open} value={open} onClick={handleClick}>
        <MaterialSymbol icon="volume_up" size={20} weight={300} />
      </StyledToggleButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 48,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={32}
        elevation={6}
      >
        <SliderWithInput
          className={classes.container}
          min={0}
          max={100}
          label={t('v3.volume')}
          value={Math.round(volume * 100)}
          onValueChange={handleChange}
        />
      </Popover>
    </>
  ) : null
})
export default VolumeAction
