import React, { useState } from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import { MaterialSymbol } from 'react-material-symbols'
import Box from '@mui/material/Box'
import { uploadImageProcess } from 'src/hooks/api/useConfigApi'
import { useAssetsApi } from 'src/hooks/api/useAssetsApi'
import { AssetTypes } from 'src/types/api/enums'
import { FileUploaderProps } from './types'
import { useLanguage } from 'src/hooks'
import Typography from '@mui/material/Typography'

const ALLOWED_TYPES = {
  images: [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp',
    'image/svg+xml',
  ],
  documents: [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
  ],
  videos: ['video/mp4', 'video/webm', 'video/ogg'],
  audio: ['audio/mpeg', 'audio/wav', 'audio/ogg'],
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const getAssetType = (file: File): AssetTypes | null => {
  if (file.type.startsWith('image')) return AssetTypes.IMAGE
  if (file.type.startsWith('video')) return AssetTypes.VIDEO
  return null
}

const MediaUploader = ({
  config,
  onAssetReady,
  className,
  buttonVariant = 'contained',
}: FileUploaderProps) => {
  const { t } = useLanguage()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string>()
  const { createAsset } = useAssetsApi()
  const validateFile = (file: File) => {
    // Check file size
    if (file.size > config.maxSizeInMB * 1024 * 1024) {
      return t('v3.media_uploader.file_size_error', {
        maxSize: config.maxSizeInMB,
      })
    }

    // Check file type
    const allowedMimeTypes = new Set<string>()
    Object.entries(config.allowedTypes).forEach(([type, isAllowed]) => {
      if (isAllowed && type in ALLOWED_TYPES) {
        ALLOWED_TYPES[type as keyof typeof ALLOWED_TYPES].forEach((mimeType) =>
          allowedMimeTypes.add(mimeType),
        )
      }
    })

    if (!allowedMimeTypes.has(file.type)) {
      return t('v3.media_uploader.file_type_error')
    }

    // Check file extension
    if (config.allowedExtensions) {
      const hasValidExtension = config.allowedExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext.toLowerCase()),
      )
      if (!hasValidExtension) {
        return t('v3.media_uploader.allowed_extensions_error', {
          extensions: config.allowedExtensions.join(', '),
        })
      }
    }

    return null
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      const file = event.target.files?.item(0)
      if (!file) return

      const error = validateFile(file)

      if (error) {
        setError(error)
      } else {
        setIsLoading(true)
        const accessUrl = await uploadImageProcess(file)
        const type = getAssetType(file)
        if (type) {
          const asset = {
            url: new URL(accessUrl).href,
            name: file.name,
            type,
          }
          const success = await createAsset(asset)
          success &&
            onAssetReady?.({
              id: 1,
              ...asset,
              url: accessUrl,
            })
        }
      }

      // Reset input
      event.target.value = ''
    } catch (error) {
      setError(t('v3.media_uploader.file_upload_error'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        component="label"
        role={undefined}
        size="small"
        variant={buttonVariant}
        disableElevation
        tabIndex={-1}
        fullWidth
        startIcon={
          <MaterialSymbol icon="cloud_upload" size={20} weight={300} />
        }
        disabled={isLoading}
        className={className}
      >
        {!isLoading ? 'Upload Media' : 'Uploading...'}
        <VisuallyHiddenInput
          type="file"
          onChange={handleFileChange}
          accept={config.allowedExtensions?.join(',')}
        />
      </Button>
      {error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </Box>
  )
}

export default MediaUploader
