import { makeMuiStyles } from 'src/theme/muiTheme'

export const useStyles = makeMuiStyles()((theme) => ({
  textButton: {
    'height': '60px',
    'backgroundColor': theme.palette.blueGrey[50],
    ':hover': {
      border: `1px solid ${theme.palette.blueGrey[800]}`,
      color: 'inherit',
    },
    ':active': {
      border: `1px solid ${theme.palette.blueGrey[800]}`,
      color: 'inherit',
    },
  },
  container: {
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    height: '100%',
    overflowY: 'auto',
  },
  showMoreButton: {
    'textDecoration': 'underline',
    'fontSize': '12px',
    'padding': 0,
    'textAlign': 'right',
    'minWidth': '32px',
    ':hover': {
      color: 'inherit',
      backgroundColor: 'inherit',
      textDecoration: 'underline',
      opacity: 0.7,
    },
  },
  figureButton: {
    'height': '62px',
    'width': '100%',
    'alignItems': 'center',
    'justifyContent': 'center',
    'backgroundColor': theme.palette.blueGrey[50],
    ':hover': {
      border: `1px solid ${theme.palette.blueGrey[800]}`,
      color: 'inherit',
    },
    ':active': {
      border: `1px solid ${theme.palette.blueGrey[800]}`,
      color: 'inherit',
    },
  },
  outlinedButton: {
    fontSize: '12px',
  },
  mediaGridItem: {
    position: 'relative',
    height: '60px',
    width: '100%',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
    cursor: 'pointer',
    img: {
      'objectFit': 'cover',
      'backgroundColor': theme.palette.blueGrey[100],
      'transition': 'all 0.3s ease-in-out',
      ':hover': {
        transform: 'scale(1.2)',
      },
    },
    span: {
      position: 'absolute',
      left: '4px',
      bottom: '4px',
      backgroundColor: theme.palette.blueGrey[800],
      padding: '4px 8px',
      fontSize: '10px',
      color: 'white',
      borderRadius: '8px',
    },
  },
  searchField: {
    input: {
      fontSize: '13px',
      padding: '9px 4px',
    },
  },
}))
