import React from 'react'

import { IHorizontalProgress } from './types'
import { progressStyles, wrapperStyles } from './styles'

export const HorizontalProgress: React.FC<IHorizontalProgress> = React.memo(
  ({ percentage = 0, isFull, className, dataAttr }) => {
    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div css={progressStyles(percentage, !!isFull)} />
      </div>
    )
  },
)
