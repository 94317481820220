import { css } from '@emotion/react'
import laptopFrame from 'src/assets/images/device-laptop-.png'
import laptopFrameMask from 'src/assets/images/device-laptop-mask.png'
import tabletFrame from 'src/assets/images/device-tablet.png'
import tabletFrameMask from 'src/assets/images/device-tablet-mask.png'
import mobileFrame from 'src/assets/images/device-phone.png'
import mobileFrameMask from 'src/assets/images/device-phone-mask.png'

export const deviceStyles = css`
  font-size: 32em;
  position: relative;
  width: 100%;
  height: 100%;

  .media {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center center;
  }

  .frame {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }

  .laptop-frame {
    &.media {
      mask-image: url(${laptopFrameMask});
    }

    &.frame {
      background-image: url(${laptopFrame});
    }
  }

  .tablet-frame {
    &.media {
      mask-image: url(${tabletFrameMask});
    }

    &.frame {
      background-image: url(${tabletFrame});
    }
  }

  .mobile-frame {
    &.media {
      mask-image: url(${mobileFrameMask});
    }

    &.frame {
      background-image: url(${mobileFrame});
    }
  }
`
