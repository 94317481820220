import { valueOf } from 'src/types'

export const zIndex = {
  hiddenItem: -1,
  canvasItem: 1,
  slideNumber: 1,
  icon: 2,
  slideHeaderFooter: 2,
  fontFamilyActions: 2,
  dropInfo: 3,
  fontBoxIcon: 3,
  orgMenu: 7,
  expanderMenu: 7,
  header: 9,
  popUpMenu: 10,
  tooltip: 99,
  chatWidget: 100,
  modal: 1000,
  toaster: 1001,
  slideMenu: 1100,
  dragPreview: 2000,
  hitPoints: 2001,
  overlayLayout: 10001,
}

export type IZIndex = valueOf<typeof zIndex, number>
