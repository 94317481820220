export const AVAILABLE_LANGUAGES = ['en', 'tr', 'de', 'pt', 'es', 'fr']
export type DecktopusLanguages = 'tr' | 'en' | 'de' | 'pt' | 'es' | 'fr'

export const LANGUAGE_NAMES: {
  [key in DecktopusLanguages]: string
} = {
  en: 'English',
  tr: 'Türkçe',
  de: 'Deutsch',
  pt: 'Português',
  es: 'Español',
  fr: 'Français',
}
