import React, { useEffect, useMemo, useState } from 'react'

import { IAnalytics } from './types'
import {
  analyticsStyles,
  headerStyles,
  bodyStyles,
  chartStyles,
  infoStyles,
  lastTimeViewStyles,
  deckViewStyles,
  chartWrapperStyles,
  chartTitleStyles,
  chartSubtitleStyles,
  chartTitleWrapperStyles,
  lastTimeViewTitleStyles,
  lastTimeViewDescriptionStyles,
  deckViewTitleStyles,
  deckViewDescriptionStyles,
  closeWrapperStyles,
  noViewsStyles,
  eyeStyles,
  noViewsDescription,
} from './styles'
import meetingTime from 'src/assets/images/meeting-time.svg'
import findEmail from 'src/assets/images/find-email.svg'
import eye from 'src/assets/images/eye.svg'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'
import { AnalyticsGraph } from '../analytics-graph'
import { useDecksApi, useLanguage } from 'src/hooks'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'
import { getDeckAnalyticsResponse } from 'src/types/api/responseObjects'
import { formatDateTime } from 'src/services/dateTimeServices'

export const Analytics: React.FC<IAnalytics> = React.memo(
  ({ className, dataAttr, onClickClose, deckId, onClickShare }) => {
    const { decks } = useSelector(({ decks }: RootState) => ({
      decks: decks.list.data,
    }))
    const { t } = useLanguage()
    const { deckAnalytics } = useDecksApi()
    const [analytics, setAnalytics] = useState<
      getDeckAnalyticsResponse['data']['analytics'] | null
    >(null)
    const [deckViewCount, setDeckViewCount] = useState<number>(0)
    const [latestViewDate, setLatestViewDate] = useState<Date | null>(null)

    const activeDeck = useMemo(() => {
      return decks?.find((deck) => deck.deck.id === deckId)
    }, [decks, deckId])

    const getDeckAnalytics = async () => {
      const deckOrganizationId = activeDeck?.deck.organizationId
      const response = await deckAnalytics(
        { deckId: deckId! },
        deckOrganizationId,
      )
      setAnalytics(response)
      setDeckViewCount(response?.views.deck.viewCount || 0)
      setLatestViewDate(response?.views.deck.latestViewDate || null)
    }

    const data = useMemo(() => {
      return analytics?.views.slides
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((item) => {
          return {
            ...item,
            orderIndex: t('common.nth', { count: item.orderIndex }),
          }
        })
    }, [analytics])

    useEffect(() => {
      getDeckAnalytics()
    }, [deckId])

    const oneSlideCase = useMemo(() => data && data.length === 1, [data])

    return (
      <div css={analyticsStyles} className={className} {...dataAttr}>
        <div css={headerStyles}>{t('common.analytics')}</div>
        <Button
          css={closeWrapperStyles}
          icon={icons.close}
          type={BUTTON_TYPE.GHOST}
          onClick={onClickClose}
          theme={BUTTON_THEME.GRAY}
        />
        {deckViewCount === 0 ? (
          <div css={noViewsStyles}>
            <img src={eye} css={eyeStyles} />
            <div css={noViewsDescription}>{t('analytics.no_views_yet')}</div>
            <Button
              onClick={onClickShare}
              type={BUTTON_TYPE.GHOST}
              text={t('analytics.share_this_deck')}
              size={BUTTON_SIZE.XSMALL}
            />
          </div>
        ) : (
          <div css={bodyStyles}>
            <div css={chartWrapperStyles(oneSlideCase)}>
              <div css={chartTitleWrapperStyles}>
                <div css={chartTitleStyles}>
                  {t(
                    `${
                      oneSlideCase
                        ? 'analytics.total_views_one_slide'
                        : 'analytics.total_views'
                    }`,
                    {
                      view: deckViewCount,
                    },
                  )}
                </div>
                <div css={chartSubtitleStyles(oneSlideCase)}>
                  {t('analytics.chart_description')}
                </div>
              </div>

              <div css={chartStyles(oneSlideCase)}>
                {data && data?.length > 1 && <AnalyticsGraph data={data} />}
              </div>
            </div>
            <div css={infoStyles}>
              <div css={lastTimeViewStyles}>
                <img src={meetingTime} />
                <div css={lastTimeViewTitleStyles}>
                  {formatDateTime(latestViewDate)}
                </div>
                <div css={lastTimeViewDescriptionStyles}>
                  {t('analytics.last_time_open')}
                </div>
              </div>
              <div css={deckViewStyles}>
                <img src={findEmail} />
                <div css={deckViewTitleStyles}>
                  {t('analytics.views', { view: deckViewCount })}
                </div>
                <div css={deckViewDescriptionStyles}>
                  {t('analytics.openedTimes', { view: deckViewCount })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  },
)

Analytics.displayName = 'Analytics'
