import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const propMutliInputStyles = ({
  colors,
  gap,
  borderRadius,
  fontSize,
}: ITheme) => css`
  background: ${colors.dark.DEFAULT};
  padding: ${gap.xsmall}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${gap.xsmall}px;
  border-radius: ${borderRadius[4]};

  .multiple-input-area {
    border-radius: ${borderRadius[3]};
    background: ${colors.dark2.DEFAULT};

    .multiple-input-label {
      padding: ${gap.small}px ${gap.small}px 0 ${gap.small}px;
      color: ${colors.elements.DEFAULT};
      font-size: ${fontSize.small};
    }
  }
`
