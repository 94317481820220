import { makeMuiStyles } from 'src/theme/muiTheme'

export const useStyles = makeMuiStyles()((theme) => ({
  normal: {
    'cursor': 'pointer',
    'justifyContent': 'center',
    'alignItems': 'center',
    'height': '72px',
    'width': '72px',
    ':hover': {
      'color': theme.palette.primary.main,
      '& [aria-label="section-panel-button"]': {
        backgroundColor: theme.palette.primary._states?.focus,
        color: theme.palette.primary.main,
      },
    },
  },
  active: {
    'color': theme.palette.primary.main,
    '& [aria-label="section-panel-button"]': {
      backgroundColor: theme.palette.primary._states?.focus,
      color: theme.palette.primary.main,
    },
  },
  iconButton: {
    'borderRadius': theme.spacing(2),
    ':hover': {
      backgroundColor: theme.palette.primary._states?.focus,
      color: theme.palette.primary.main,
    },
  },
}))
