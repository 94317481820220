import React, { useMemo } from 'react'
import { IMembers } from './types'
import {
  nameStyles,
  roleStyles,
  membersTableColumnNameStyles,
  membersStyles,
} from './styles'
import { MemberRow } from './member-row'
import { MEMBER_STATUS } from './members-role'
import { useWindowSize, useLanguage } from 'src/hooks'
import { useTheme } from 'src/theme'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const Members: React.FC<IMembers> = React.memo(
  ({ className, dataAttr, nameWidth }) => {
    const { t } = useLanguage()
    const size = useWindowSize()
    const { orgUsers, pendingUsers, user, orgOwner } = useSelector(
      ({ org, user }: RootState) => ({
        orgUsers: org.users,
        pendingUsers: org.pendingInvitedUsers,
        user: user.data?.id,
        orgOwner: org.details?.ownerUser.id,
      }),
    )
    const theme = useTheme()

    const smallInputSize = useMemo(
      () => size.width < theme.breakpointsV2.tablet,
      [size],
    )
    const currentUser = useMemo(() => {
      return orgUsers?.find((orgUser) => orgUser.user.id === user)
    }, [orgUsers, user])
    const items = useMemo(
      () =>
        [
          ...(pendingUsers || []).map((item) => ({
            id: item.id,
            name: '',
            image: null,
            email: item.invitedEmail,
            status: MEMBER_STATUS.PENDING,
            canUserEdit: currentUser?.isAdmin,
          })),
          ...(orgUsers || []).map((item) => ({
            id: item.user.id,
            name: item.user.fullName,
            image: item.user.picture,
            email: item.user.email,
            status: item.isAdmin ? MEMBER_STATUS.ADMIN : MEMBER_STATUS.MEMBER,
            canUserEdit:
              item.user.id !== user &&
              orgOwner !== item.user.id &&
              currentUser?.isAdmin,
          })),
        ].sort((a, b) => {
          const nameA = a?.name?.toLowerCase()
          const nameB = b?.name?.toLowerCase()
          if (nameA < nameB) return -1
          if (nameA > nameB) return 1
          return 0
        }),
      [pendingUsers, orgUsers],
    )

    return (
      <div css={membersStyles} className={className} {...dataAttr}>
        <div css={membersTableColumnNameStyles}>
          <span css={nameStyles({ nameWidth })}>{t('common.name')}</span>
          {!smallInputSize && <span css={roleStyles}>{t('common.role')}</span>}
        </div>
        {items.map((item, index) => (
          <MemberRow
            key={index}
            item={item}
            width={nameWidth}
            smallInputSize={smallInputSize}
          />
        ))}
      </div>
    )
  },
)
Members.displayName = 'OrganizationMembers'
