import React, { useMemo } from 'react'

import { IMedia } from './types'
import { ComponentMediaDataSchema } from 'src/types/api/requestObjects'
import { MediaTypes } from 'src/types/api/enums'
import { Image } from './Image'
import { Shape } from './Shape'
import { Video } from './Video'
import { Icon } from './Icon'
import { Website } from './Website'

export const Media: React.FC<IMedia> = React.memo(
  ({ data, canvasType, className, dataAttr }) => {
    const componentData: ComponentMediaDataSchema = useMemo(
      () => data.data as ComponentMediaDataSchema,
      [data.data],
    )

    const componentMediaType = useMemo(() => {
      switch (componentData.mediaType) {
        case MediaTypes.IMAGE:
          return <Image data={data} className={className} {...dataAttr} />
        case MediaTypes.LOGO:
          return <Image data={data} className={className} {...dataAttr} />
        case MediaTypes.GIF:
          return <Image data={data} className={className} {...dataAttr} />
        case MediaTypes.ICON:
          return <Icon data={data} className={className} {...dataAttr} />
        case MediaTypes.VIDEO:
          return (
            <Video
              data={data}
              canvasType={canvasType}
              className={className}
              {...dataAttr}
            />
          )
        case MediaTypes.WEBSITE:
          return (
            <Website
              data={data}
              canvasType={canvasType}
              className={className}
              {...dataAttr}
            />
          )
        case MediaTypes.SHAPE:
          return <Shape data={data} className={className} {...dataAttr} />
        default:
          return <div style={{ fontSize: '12em' }}>not supported</div>
      }
    }, [componentData])

    return componentMediaType
  },
)

Media.displayName = 'Media'
