import React from 'react'
import {
  descriptionStyles,
  wrapperStyles,
  innerStyles,
  imageStyles,
  titleStyles,
} from './styles'
import brokenRobotImg from 'src/assets/images/broken-robot.svg'
import { useLanguage } from 'src/hooks'

export const ExpiredPage: React.FC = React.memo(() => {
  const { t } = useLanguage()

  return (
    <div css={wrapperStyles}>
      <div css={innerStyles}>
        <img src={brokenRobotImg} css={imageStyles} />
        <div css={titleStyles}>{t('expired.expire_link_title')}</div>
        <div css={descriptionStyles}>
          {t('expired.expire_link_description')}
        </div>
      </div>
    </div>
  )
})
export default ExpiredPage
