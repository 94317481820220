import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLanguage } from 'src/hooks'
import {
  DROPDOWN_SIZE,
  DROPDOWN_THEME,
  DROPDOWN_WIDTH,
  Dropdown,
  IDropdownItem,
} from 'src/lib'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { ComponentServices } from 'src/services'
import { FontTypes } from './types'
import {
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { IEditPropSection } from '../../types'

export const PropFontFamily: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()

    const selectedFontFamily = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const cType = (c.data as ComponentTextDataSchema).style.font.family
          if (a === '' && cType) {
            return cType
          }

          return cType !== a ? 'multi' : a
        }, ''),
      [components],
    )

    const convertedFontFamily = useMemo(() => {
      switch (selectedFontFamily) {
        case 'primary':
          return '--font-primary'
        case 'secondary':
          return '--font-secondary'

        default:
          return selectedFontFamily
      }
    }, [selectedFontFamily])

    const handleFontChange = useCallback(
      (value?: IDropdownItem) => {
        const font = value?.value as FontTypes
        const convertedFont =
          font === '--font-primary'
            ? 'primary'
            : font === '--font-secondary'
              ? 'secondary'
              : font

        const partialData: DeepPartial<ComponentTextDataSchema> = {
          style: {
            font: {
              family: convertedFont,
            },
          },
        }
        const updatedComponents = ComponentServices.updateComponentData({
          components,
          partialData,
        })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <Dropdown
        width={DROPDOWN_WIDTH.FULL}
        theme={DROPDOWN_THEME.DARK}
        size={DROPDOWN_SIZE.BIG}
        selected={convertedFontFamily}
        onChange={handleFontChange}
        placeholder={t('edit.properties.font.select_option')}
        items={[
          {
            name: 'Primary',
            value: FontTypes.PRIMARY,
          },
          {
            name: 'Secondary',
            value: FontTypes.SECONDARY,
          },
          {
            name: 'Inter',
            value: FontTypes.INTER,
          },
          {
            name: 'Merriweather',
            value: FontTypes.MERRIWEATHER,
          },
          {
            name: 'Nunito',
            value: FontTypes.NUNITO,
          },
          {
            name: 'Open Sans',
            value: FontTypes.OPEN_SANS,
          },
          {
            name: 'Playfair Display',
            value: FontTypes.PLAYFAIR_DISPLAY,
          },
          {
            name: 'Raleway',
            value: FontTypes.RALEWAY,
          },
          {
            name: 'Roboto',
            value: FontTypes.ROBOTO,
          },
        ]}
      />
    )
  },
)

PropFontFamily.displayName = 'PropFontFamily'
