import { useImperativeHandle } from 'react'
import { observer } from 'mobx-react-lite'
import { useInfiniteAPI } from 'polotno/utils/use-api'
import { Vector2d } from 'konva/lib/types'
import { NodeType } from 'polotno/model/node-model'
import { ImagesGrid } from 'polotno/side-panel/images-grid'

import { useAssetsApi } from 'src/hooks/api/useAssetsApi'
import { IMediaItem, IImageGridProps, IImageGridRef } from './types'
import { dropMediaElement } from '../../helpers/dropMediaElement'

const ImageGridLoader = observer<IImageGridProps, IImageGridRef>(
  ({ store, types }, ref) => {
    const { getAssets } = useAssetsApi()

    const { data, isLoading, loadMore, reset } = useInfiniteAPI({
      getAPI: ({ page }) => `${types.join('_')}-${page}`,
      getSize: (firstResult) => firstResult.totalPages,
      fetchFunc: (key) => getAssets(key.split('-')[1], types),
    })
    const items = data?.reduce((acc, page) => {
      return [...acc, ...page.items]
    }, [])

    useImperativeHandle(ref, () => ({
      reset,
    }))

    const onSelect = async (
      image: IMediaItem,
      droppedPos?: Vector2d,
      targetElement?: NodeType,
    ) => {
      dropMediaElement({ store, image, droppedPos, targetElement })
    }
    return (
      <ImagesGrid
        images={items}
        getPreview={(image) => image.url}
        onSelect={onSelect}
        rowsNumber={2}
        isLoading={isLoading}
        loadMore={loadMore}
      />
    )
  },
  { forwardRef: true },
)

export default ImageGridLoader
