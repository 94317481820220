import React, { useMemo } from 'react'
import { timelineStyles } from './styles'
import { IList } from '../../types'
import { ComponentListDataSchema } from 'src/types/api/requestObjects'
import { TimelineItem } from './TimelineItem'

export const TimelineView: React.FC<IList> = React.memo(
  ({ data, overrides, className, dataAttr }) => {
    const componentData: ComponentListDataSchema = useMemo(
      () => data.data as ComponentListDataSchema,
      [data.data],
    )

    return (
      <div
        css={timelineStyles({
          direction: componentData.style.layout.direction,
        })}
        className={className}
        {...dataAttr}
      >
        <div className="line">
          {componentData?.listItems?.map((itemData, index) => (
            <TimelineItem
              key={index}
              index={index}
              data={itemData}
              width={data.positions.width || undefined}
              componentData={componentData}
              overrides={overrides}
              itemCount={componentData?.listItems.length}
            />
          ))}
        </div>
      </div>
    )
  },
)
