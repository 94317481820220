import { UserCurrency } from 'src/types/api/enums'

export const CURRENCY_DATA = {
  [UserCurrency.TRY]: { name: 'TRY', symbol: '₺' },
  [UserCurrency.USD]: { name: 'USD', symbol: '$' },
  [UserCurrency.EUR]: { name: 'EUR', symbol: '€' },
  [UserCurrency.GBP]: { name: 'GBP', symbol: '£' },
  [UserCurrency.INR]: { name: 'INR', symbol: '₹' },
  [UserCurrency.EGP]: { name: 'EGP', symbol: 'E£' },
  [UserCurrency.BDT]: { name: 'BDT', symbol: '৳' },
  [UserCurrency.NPR]: { name: 'NPR', symbol: 'NPR' },
  [UserCurrency.PKR]: { name: 'PKR', symbol: 'Rs' },
  [UserCurrency.IDR]: { name: 'IDR', symbol: 'Rp' },
  [UserCurrency.BRL]: { name: 'BRL', symbol: 'R$' },
  [UserCurrency.COP]: { name: 'COP', symbol: 'COL$' },
  [UserCurrency.BGN]: { name: 'BGN', symbol: 'BGN' },
  [UserCurrency.PLN]: { name: 'PLN', symbol: 'PLN' },
  [UserCurrency.QAR]: { name: 'QAR', symbol: 'QAR' },
  [UserCurrency.ZAR]: { name: 'ZAR', symbol: 'ZAR' },
  [UserCurrency.AED]: { name: 'AED', symbol: 'AED' },
  [UserCurrency.SAR]: { name: 'SAR', symbol: 'SAR' },
  [UserCurrency.CHF]: { name: 'CHF', symbol: 'CHF' },
  [UserCurrency.ARS]: { name: 'ARS', symbol: 'ARS' },
  [UserCurrency.MXN]: { name: 'MXN', symbol: 'MXN' },
  [UserCurrency.LBP]: { name: 'LBP', symbol: 'LBP' },
}
