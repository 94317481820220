import React from 'react'
import { IHelmetProvider } from './types'
import { Gtag } from './integrations/Gtag'
import { Gtm } from './integrations/Gtm'
import { NewRelic } from './integrations/NewRelic'
import { Rewardful } from './integrations/Rewardful'
import { useInitGleap } from './integrations/Gleap'

export const HelmetProvider: React.FC<IHelmetProvider> = React.memo(
  ({ isExportMode = false }) => {
    useInitGleap()

    return (
      !isExportMode && (
        <>
          <Gtm />
          <Gtag />
          <NewRelic />
          <Rewardful />
        </>
      )
    )
  },
)

export default HelmetProvider
