import React, { useCallback } from 'react'

import { initPageStyles } from './styles'

import emptyStateImage from 'src/assets/images/init-empty-state.svg'
import { Button, icons } from 'src/lib'
import { useLanguage } from 'src/hooks'
import { useDispatch } from 'react-redux'
import { setAddSlideModal } from 'src/store'

export const NewDeckPage: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const dispatch = useDispatch()

  const handleOnClick = useCallback(() => {
    dispatch(setAddSlideModal({ isOpen: true }))
  }, [])

  return (
    <div css={initPageStyles}>
      <img src={emptyStateImage} alt="" />
      <Button
        icon={icons.plus}
        text={t('common.deck.add_slide')}
        onClick={handleOnClick}
      />
    </div>
  )
})
