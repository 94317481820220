import React, { useCallback, useMemo } from 'react'

import { ICardLayout } from './types'
import { cardLayoutStyles } from './styles'
import { CANVAS_DATA_TYPE, CANVAS_TYPE, Canvas } from 'src/components/canvas'
import { Icon, icons } from '../icon'
import { useTheme } from 'src/theme'
import { useActiveCanvas, useActiveThemeId, useLayoutsApi } from 'src/hooks'
import { decorMap } from 'src/assets/decors'
import { SVGTypes } from 'src/types/api/enums'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'

export const CardLayout: React.FC<ICardLayout> = React.memo(
  ({
    id,
    name,
    components,
    onClick,
    showDeleteButton,
    svgUrl,
    svgType,
    background,
    thumbnailUrl,
    className,
    solidColor,
    dataAttr,
  }) => {
    const { deleteLayout } = useLayoutsApi()

    const { cssVariables } = useActiveCanvas({})
    const { activeThemeBg } = useSelector(({ edit }: RootState) => ({
      activeThemeBg:
        edit.activeDeck.data?.deckData?.theme.data.colorMap?.background,
    }))
    const themeId = useActiveThemeId()
    const { colors } = useTheme()
    const handleOnClick = useCallback(() => {
      onClick?.(id)
    }, [id, onClick])

    const handleDeleteClick = useCallback(
      (e: React.MouseEvent<HTMLOrSVGElement>) => {
        e.preventDefault()
        e.stopPropagation()
        deleteLayout({ slideLayoutId: id })
      },
      [],
    )

    const backgroundsStyles = useMemo(() => {
      if (solidColor) {
        return {
          backgroundColor: solidColor,
        }
      } else if (thumbnailUrl) {
        return { backgroundImage: `url(${thumbnailUrl})` }
      }
    }, [solidColor, thumbnailUrl])

    const activeThemeBackground = useMemo(() => {
      if (background) {
        return background
      }

      return {
        colors: activeThemeBg?.default.colors.map((color) =>
          ['wht', 'blck', 'first', 'second', 'third', 'fourth'].includes(color)
            ? `var(--${color})`
            : color,
        ),
        rotation: activeThemeBg?.default.rotation,
      } as GradientLikeColorSchema
    }, [background, activeThemeBg])

    return (
      <div
        css={cardLayoutStyles}
        className={className}
        {...dataAttr}
        onClick={handleOnClick}
      >
        {thumbnailUrl || solidColor ? (
          <div className="render-area" style={backgroundsStyles} />
        ) : (
          <div className="render-area theme-default">
            {showDeleteButton && (
              <div className="delete-button">
                <Icon
                  icon={icons.trash_can}
                  color={colors.error.DEFAULT}
                  onClick={handleDeleteClick}
                />
              </div>
            )}
            <Canvas
              canvasType={CANVAS_TYPE.STATIC}
              dataType={CANVAS_DATA_TYPE.LAYOUT}
              external={{
                components,
                svgUrl,
                svgType,
                svgDecor:
                  themeId?.toString() && svgType
                    ? svgType === SVGTypes.BLANK
                      ? '&npbs;'
                      : decorMap[themeId][svgType]
                    : '',
                background: activeThemeBackground,
                cssVariables,
              }}
            />
          </div>
        )}

        <div className="name-area">{name}</div>
      </div>
    )
  },
)

CardLayout.displayName = 'CardLayout'
