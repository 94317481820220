import { useState } from 'react'
import { API_CONFIG, APP_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { getUploadUrlResponse } from 'src/types/api/responseObjects'

export const uploadImageProcess = async (file: File) => {
  const fileTypeToExtension = APP_CONFIG.fileUploadList.find(
    ({ mime }) => file.type === mime,
  )?.format
  const res: RequestResponse<getUploadUrlResponse, any> =
    await RequestServices.callApi({
      method: 'GET',
      url: API_CONFIG.CONFIG_UPLOAD,
      params: {
        extension: fileTypeToExtension,
      },
    })

  await RequestServices.extRequest({
    method: 'PUT',
    url: res.data.data.uploadUrl,
    data: file,
    headers: {
      'Content-Type': file.type,
    },
  })

  return res.data.data.accessUrl
}
export const useConfigApi = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const uploadImage = async ({ file }: { file: any }): Promise<string> => {
    setIsLoading(true)
    try {
      return await uploadImageProcess(file)
    } finally {
      setIsLoading(false)
    }
  }

  const uploadFile = async ({
    file,
    extension,
  }: {
    file: File
    extension: string
  }) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getUploadUrlResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.CONFIG_UPLOAD,
          params: {
            extension,
          },
        })

      await RequestServices.extRequest({
        method: 'PUT',
        url: res.data.data.uploadUrl,
        data: file,
        headers: {
          'Content-Type': file.type,
        },
      })

      return res.data.data.accessUrl
    } finally {
      setIsLoading(false)
    }
  }

  return {
    uploadImage,
    uploadFile,
    isLoading,
  }
}
