export const planFeatures = {
  'plan_features.pro.monthly.title': 'AI Credits 750 / month',
  'plan_features.pro.monthly.1': 'PDF / PPT Export',
  'plan_features.pro.monthly.2': 'Presentation Analytics',
  'plan_features.pro.monthly.3': 'Sharing Link',
  'plan_features.pro.monthly.4': 'Form Response Email Notifications',
  'plan_features.pro.monthly.5': 'Slide & Speaker Notes',
  'plan_features.pro.monthly.6': 'Tips & QA Generation',
  'plan_features.pro.annual.title': 'AI Credits 750 / month',
  'plan_features.pro.annual.1': 'PDF / PPT Export',
  'plan_features.pro.annual.2': 'Presentation Analytics',
  'plan_features.pro.annual.3': 'Sharing Link',
  'plan_features.pro.annual.4': 'Form Response Email Notifications',
  'plan_features.pro.annual.5': 'Slide & Speaker Notes',
  'plan_features.pro.annual.6': 'Tips & QA Generation',
  'plan_features.business.monthly.title': 'AI Credits 1000 / month',
  'plan_features.business.monthly.1': 'All PRO AI Features +',
  'plan_features.business.monthly.2': 'Brand Features',
  'plan_features.business.monthly.3': 'Advanced Form Response',
  'plan_features.business.monthly.4': 'Webhook',
  'plan_features.business.monthly.5': 'Custom Domain Connection',
  'plan_features.business.monthly.6': 'Team & Organization',
  'plan_features.business.annual.title': 'AI Credits 1000 / month',
  'plan_features.business.annual.1': 'All Pro AI Features +',
  'plan_features.business.annual.2': 'Brand Features',
  'plan_features.business.annual.3': 'Advanced Form Response',
  'plan_features.business.annual.4': 'Webhook',
  'plan_features.business.annual.5': 'Custom Domain Connection',
  'plan_features.business.annual.6': 'Teams & Organization',
}
