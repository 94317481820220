import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const mappingWrapperStyles = css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const swapStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${gap.small}px;
  padding-top: ${gap['2xsmall']}px;
  padding-bottom: ${gap.large}px;
  width: 100%;
`

export const swapSwitchStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${gap.small}px;
`

export const swapSwitchTextStyles = ({ fontSize }: ITheme) => css`
  font-size: ${fontSize.small};
`

export const defaultColorsStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: ${gap['4xsmall']}px;
`

export const swapColorsStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const swapColorsTitleStyles = ({
  fontWeight,
  fontSize,
  gap,
}: ITheme) => css`
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
  padding-top: ${gap.small}px;
  padding-bottom: ${gap.small}px;
`

export const swapColorsInlineStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: ${gap['4xsmall']}px;
`
