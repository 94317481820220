import React, { useCallback, useMemo } from 'react'

import { SWITCH_SIZE, Switch } from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  ComponentChartDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { IEditPropSection } from '../../types'

export const PropChartShowBackground: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()

    const selectedLegends = useMemo(
      () =>
        components?.reduce(
          (a: boolean | undefined, c: UpdateComponentSchema) => {
            const showBackground = (c.data as ComponentChartDataSchema).options
              .showBackground
            if (a === undefined && showBackground) {
              return showBackground
            }

            return showBackground !== a ? false : a
          },
          undefined,
        ),
      [components],
    )

    const handleClick = useCallback(
      (value?: boolean) => {
        const partialData: DeepPartial<ComponentChartDataSchema> = {
          options: { showBackground: value },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentChartDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )
    return (
      <Switch
        size={SWITCH_SIZE.SMALL}
        selected={selectedLegends}
        onChange={handleClick}
      />
    )
  },
)

PropChartShowBackground.displayName = 'PropChartShowBackground'
