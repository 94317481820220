import React, { useState } from 'react'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'
import { buttonStyles, linkButtonStyles } from '../styles'
import { useLanguage } from 'src/hooks'
import { ITypesOfBusiness } from './types'

export const TypesOfBusiness: React.FC<ITypesOfBusiness> = ({
  onNextStep,
  setSelectedBusinessType,
}) => {
  const { t } = useLanguage()
  const [showOtherOptions, setShowOtherOptions] = useState(false)
  const seeOtherOptions = () => {
    setShowOtherOptions(!showOtherOptions)
  }
  const handleBusinessTypeClick = (businessType: string) => {
    setSelectedBusinessType(businessType)
    onNextStep()
  }
  return (
    <div css={buttonStyles}>
      {showOtherOptions ? (
        <>
          <Button
            icon={icons.businessman}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.management')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Management')}
          />
          <Button
            icon={icons.technical_support}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.customer_service')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Customer Service')}
          />
          <Button
            icon={icons.graduation_cap}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.education')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Education')}
          />
          <Button
            icon={icons.worker}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.engineering')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Engineering')}
          />
          <Button
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.other')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Other')}
          />
          <Button
            theme={BUTTON_THEME.PRIMARY}
            type={BUTTON_TYPE.GHOST}
            css={linkButtonStyles}
            text={t('user_classify.see_other_options')}
            onClick={seeOtherOptions}
            isLink
          />
        </>
      ) : (
        <>
          <Button
            icon={icons.law}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.legal')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Legal')}
          />
          <Button
            icon={icons.find_user_male}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.hr')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('HR')}
          />
          <Button
            icon={icons.sell}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.product')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Product')}
          />
          <Button
            icon={icons.money}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.finance')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Finance')}
          />
          <Button
            icon={icons.paint_palette}
            size={BUTTON_SIZE.XSMALL}
            text={t('common.design')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Design')}
          />
          <Button
            icon={icons.increase}
            size={BUTTON_SIZE.XSMALL}
            text={t('user_classify.sales_marketing')}
            theme={BUTTON_THEME.WHITE}
            onClick={() => handleBusinessTypeClick('Sales Marketing')}
          />
          <Button
            theme={BUTTON_THEME.PRIMARY}
            type={BUTTON_TYPE.GHOST}
            css={linkButtonStyles}
            text={t('user_classify.see_other_options')}
            onClick={seeOtherOptions}
            isLink
          />
        </>
      )}
    </div>
  )
}
export default TypesOfBusiness
