import { dataAttrType } from 'src/types'

export enum SWITCH_SIZE {
  LARGE = 'large',
  SMALL = 'small',
  XSMALL = 'xsmall',
}

export interface ISwitch {
  className?: string
  dataAttr?: dataAttrType
  size?: SWITCH_SIZE
  disabled?: boolean
  selected?: boolean
  onChange?: (value: boolean) => void
}

export interface ISwitchSizeStyles {
  wrapperWidth: string
  wrapperHeight: string
  sliderWidth: string
  sliderHeight: string
  sliderDistance: string
  sliderPosition: string
}

export interface IStatusCss {
  size: SWITCH_SIZE
  disabled: boolean
  isSelected: boolean
}
