import { css } from '@emotion/react'
import { sizeStyles } from './data'
import { SWITCH_SIZE, IStatusCss } from './types'
import { ITheme } from 'src/theme'

export const statusCss =
  ({ isSelected, disabled, size }: IStatusCss) =>
  (theme: ITheme) => {
    if (disabled) {
      if (isSelected) {
        return css`
          background: ${theme.colors.black[20]};
          &::before {
            transform: translateX(${sizeStyles(size).sliderDistance});
            transition: transform 0.3s ease;
          }
        `
      } else {
        return css`
          background: ${theme.colors.black[20]};
          &::before {
            transform: translateX(${sizeStyles(size).sliderPosition});
            transition: transform 0.3s ease;
          }
        `
      }
    } else {
      if (isSelected) {
        return css`
          background: ${theme.colors.primary.DEFAULT};
          &:hover {
            background: ${theme.colors.primary[110]};
          }
          &::before {
            transform: translateX(${sizeStyles(size).sliderDistance});
            transition: transform 0.3s ease;
          }
        `
      } else {
        return css`
          background: ${theme.colors.text[2]};
          &:hover {
            background: ${theme.colors.dark[70]};
          }
          &::before {
            transform: translateX(${sizeStyles(size).sliderPosition});
            transition: transform 0.3s ease;
          }
        `
      }
    }
  }

export const wrapperCss = (size: SWITCH_SIZE) => css`
  position: relative;
  display: inline-block;
  width: ${sizeStyles(size).wrapperWidth};
  height: ${sizeStyles(size).wrapperHeight};
`
export const inputCss = css`
  opacity: 0;
  width: 0;
  height: 0;
`

export const sliderCss = (size: SWITCH_SIZE) => (theme: ITheme) => css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${theme.colors.black[20]};
  border-radius: 100px;
  &::before {
    position: absolute;
    content: '';
    width: ${sizeStyles(size).sliderWidth};
    height: ${sizeStyles(size).sliderHeight};
    bottom: ${sizeStyles(size).sliderPosition};
    background: ${theme.colors.white.DEFAULT};
    border-radius: ${theme.borderRadius.DEFAULT};
  }
`
