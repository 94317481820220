import { SVGTypes } from 'src/types/api/enums'
import { decorConfig } from 'src/config'

export const decorPathFinder = ({
  svgType,
  themeId,
}: {
  svgType?: SVGTypes | null
  themeId?: number
}): string | undefined => {
  if (!svgType || !themeId) {
    return
  }

  const fullDecorConfig = {
    ...decorConfig.defaults,
    ...decorConfig.svgMapException[themeId],
  }

  return fullDecorConfig[svgType]
    ? fullDecorConfig[svgType].path ||
        decorPathFinder({
          svgType: fullDecorConfig[svgType].alias!,
          themeId,
        })
    : undefined
}
