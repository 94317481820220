import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IPropSection } from './types'

export const propSectionStyles = ({ gap, colors }: ITheme) => css`
  margin-bottom: ${gap.xsmall}px;
  padding-bottom: ${gap.small}px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.dark[80]};
  }
`

type IPropSectionHeaderStyles = Pick<IPropSection, 'expandable'> & {
  transition?: boolean
}
export const propSectionHeaderStyles =
  ({ expandable, transition }: IPropSectionHeaderStyles) =>
  ({ gap }: ITheme) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;

    ${transition && `transition: margin-bottom var(--expand-timeout);`}

    &.margin {
      margin-bottom: ${gap.xsmall}px;
    }
    ${expandable && `cursor: pointer;`}
  `

export const propSectionTitleStyles = ({ fontWeight }: ITheme) => css`
  font-weight: ${fontWeight.medium};
`

type IExpandableAreaStyles = Pick<IPropSection, 'initialState'>
export const expandableAreaStyles = ({
  initialState,
}: IExpandableAreaStyles) => css`
  ${!initialState &&
  `
		max-height: 0;
		opacity: 0;
		overflow: hidden;
	`}

  transition:
    max-height var(--expand-timeout),
    opacity var(--expand-timeout);

  &.enter {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &.enter-active {
    max-height: var(--expand-area-max-height);
    opacity: 1;
  }

  &.enter-done {
    max-height: none;
    opacity: 1;
    overflow: visible;
  }

  &.exit {
    max-height: var(--expand-area-max-height);
    opacity: 1;

    overflow: hidden;
  }

  &.exit-active {
    max-height: 0;
    opacity: 0;
  }

  &.exit-done {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
`
