import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { decorMap } from 'src/assets/decors'
import { FontServices } from 'src/services'
import { RootState } from 'src/store'
import { SVGTypes } from 'src/types/api/enums'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'
import { ComponentSchema } from 'src/types/api/responseObjects'
import { useActiveColorMap } from './useActiveColorMap'
import { decorPathFinder } from 'src/services/decorPathFinder'
import { colorMapSchemaHandler } from 'src/services/colorServices'

export interface IUseActiveCanvas {
  slideDataId?: number
}

export interface IUseActiveCanvasReturn {
  components?: ComponentSchema[]
  isSwapped?: boolean
  tempSvgUrl?: string | null
  backgroundColor?: GradientLikeColorSchema
  svgType?: SVGTypes
  svgDecorString?: string
  cssVariables?: string
  activeColorFromMap?: GradientLikeColorSchema | undefined
  autoResizeNeeded?: boolean
}

export const useActiveCanvas = ({
  slideDataId,
}: IUseActiveCanvas): IUseActiveCanvasReturn => {
  const {
    activeSlidesData,
    activeSlideDataID,
    slideData,
    themeId,
    activeColorMap,
    themeColor,
    themeFont,
    autoResizeNeeded,
  } = useSelector(({ edit }: RootState) => ({
    activeSlidesData: edit.activeSlideData,
    activeSlideDataID: edit.activeSlideDataID,
    slideData: edit.activeSlideData.data,
    themeId: edit.activeDeck.data?.deckData?.theme.id,
    activeColorMap: edit.activeDeck.data?.deckData?.theme.data.colorMap,
    themeColor: edit.activeDeck.data?.deckData?.themeColor.data,
    themeFont: edit.activeDeck.data?.deckData?.themeFont.data,
    autoResizeNeeded: edit.activeDeck.data?.deckData?.data.autoResizeNeeded,
  }))

  const alteredColorMap = useActiveColorMap({})

  const targetSlideDataId = useMemo(
    () => slideDataId || activeSlideDataID,
    [slideDataId, activeSlideDataID],
  )

  const currentSlideData = useMemo(
    () => slideData?.find(({ id }) => id === targetSlideDataId),
    [slideData, targetSlideDataId],
  )

  const components = useMemo(
    () =>
      activeSlidesData?.data
        ?.find(({ id }) => id === targetSlideDataId)
        ?.slideDataComponents.map(({ component }) => component),
    [activeSlidesData, targetSlideDataId],
  )

  const isSwapped = useMemo(
    () => slideData?.find(({ id }) => id === targetSlideDataId)?.isSwapColor,
    [slideData, targetSlideDataId],
  )

  const tempSvgUrl = useMemo(() => {
    if (currentSlideData?.tempSvg && themeId) {
      return currentSlideData.tempSvg[themeId]
    }
  }, [currentSlideData, themeId])

  const activeColorFromMap = useMemo<
    GradientLikeColorSchema | undefined
  >(() => {
    const targetBackground =
      alteredColorMap?.background?.[isSwapped ? 'swap' : 'default']

    return targetBackground
      ? {
          colors: targetBackground.colors.map((val) =>
            ['first', 'second', 'third', 'fourth', 'wht', 'blck'].includes(val)
              ? `var(--${val})`
              : val,
          ),
          rotation: targetBackground.rotation,
        }
      : undefined
  }, [alteredColorMap?.background, activeColorMap?.background, isSwapped])

  const backgroundColor = useMemo(
    () =>
      activeSlidesData?.data?.find(({ id }) => id === targetSlideDataId)
        ?.background ||
      activeColorFromMap ||
      undefined,
    [
      activeSlidesData,
      activeColorFromMap,
      activeSlideDataID,
      targetSlideDataId,
    ],
  )

  const svgDecorStr = useMemo(() => {
    const selectedSvg = decorPathFinder({
      svgType: currentSlideData?.svgType,
      themeId: themeId,
    })

    return themeId?.toString() && selectedSvg
      ? decorMap[themeId?.toString()][selectedSvg as SVGTypes]
      : ''
  }, [themeId, currentSlideData])

  const svgDecorString = useMemo(() => {
    return currentSlideData?.svgType === SVGTypes.BLANK ? '&nbsp;' : svgDecorStr
  }, [svgDecorStr])

  const overrideColorMap = useMemo(() => {
    return Object.entries(alteredColorMap || {})
      .map(([key, values]) =>
        colorMapSchemaHandler({ values, key, swapped: isSwapped }),
      )
      .filter((v) => v)
  }, [alteredColorMap, isSwapped])

  const cssVariables = useMemo(() => {
    return `
      clear: none;
			--first: ${themeColor?.first};
			--second: ${themeColor?.second};
			--third: ${themeColor?.third};
			--fourth: ${themeColor?.fourth};
			--wht: #ffffff;
			--blck: #000000;
			
			--font-primary: ${FontServices.fontFamilyCleaner(themeFont?.primary)};
			--font-primary-weight: ${FontServices.fontWeightCalculator(themeFont?.primary)};
			--font-secondary: ${FontServices.fontFamilyCleaner(themeFont?.secondary)};
			--font-secondary-weight: ${FontServices.fontWeightCalculator(
        themeFont?.secondary,
      )};

			${overrideColorMap.flatMap((colorMap) => colorMap).join(' ')};
		`
  }, [overrideColorMap])

  return {
    components,
    isSwapped,
    tempSvgUrl,
    backgroundColor,
    svgDecorString,
    cssVariables,
    activeColorFromMap,
    autoResizeNeeded,
  }
}
