import { observer } from 'mobx-react-lite'
import Stack from '@mui/material/Stack'
import { useSvgColors } from 'polotno/utils/svg'
import ColorPickerElement from './ColorPickerElement'
import { IPolotnoComponent } from '../../types'
import { useLanguage } from 'src/hooks'
import { SVGElementType } from 'polotno/model/svg-model'
import { useSvgFillStyles } from './styles'

const SvgColorsAction = observer<IPolotnoComponent>(({ store }) => {
  const { t } = useLanguage()
  const { classes } = useSvgFillStyles()
  const svgElement = store.selectedElements[0] as SVGElementType
  const colors = useSvgColors(svgElement?.src)

  return svgElement ? (
    <Stack direction="row" sx={{ ml: 2 }}>
      {colors.map((color, index) => (
        <ColorPickerElement
          key={color}
          store={store}
          prop={'fill'}
          label={index === 0 ? t('v3.colors') : undefined}
          color={color}
          className={classes.fillStyles}
          onSelect={(newColor) => svgElement.replaceColor(color, newColor)}
        />
      ))}
    </Stack>
  ) : null
})
export default SvgColorsAction
