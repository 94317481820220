import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const captchaWrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
`

export const hCaptchaInfoStyles = ({
  breakpointsV2: breakpoints,
  colors,
  fontSize,
  gap,
}: ITheme) => css`
  color: ${colors.text[2]};
  font-size: ${fontSize.xsmall};
  position: absolute;
  bottom: ${gap['4xlarge']}px;
  padding: 0 ${gap['4xlarge']}px;
  text-align: center;

  & > a {
    color: ${colors.text[2]};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    position: sticky;
    padding: 0;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    position: absolute;
    bottom: ${gap['4xlarge']}px;
    padding: 0 ${gap['6xlarge']}px;
  }
`
