import React, { useCallback, useMemo } from 'react'
import { IEditPropSection } from '../../types'

import { Switch } from 'src/lib'
import {
  ComponentFormDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { useDispatch } from 'react-redux'

export const FormSubmitRequired: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()

    const selectedOptions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['options'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentFormDataSchema).options
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const changeRequirement = useCallback(
      (reqirement: boolean) => {
        const partialData: DeepPartial<ComponentFormDataSchema> = {
          options: {
            isSubmitRequired: reqirement,
          },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <Switch
        selected={selectedOptions?.isSubmitRequired}
        onChange={changeRequirement}
      />
    )
  },
)
