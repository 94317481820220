import React from 'react'
import { Loader } from 'src/lib'
import {
  cardCanvasStyles,
  contentStyles,
  contentWrapperStyles,
  loadingStyles,
  wrapperStyles,
} from './styles'
import { ICardAction } from '../../types'
import { css } from '@emotion/react'

import fallbackImage from 'src/assets/images/placeholder.svg'
import { theme } from 'src/theme'
import { useFlags } from 'src/hooks'
import { CANVAS_DATA_TYPE, Canvas } from 'src/components/canvas'

export const CardAction: React.FC<ICardAction> = React.memo(
  ({
    deckId,
    type,
    isLoading = false,
    showActions = false,
    primaryButtons,
    secondaryButtons,
    thumbnailUrl,
    isDeckFinal,
  }) => {
    const flags = useFlags()
    return (
      <div css={wrapperStyles({ type })}>
        <div
          css={contentWrapperStyles({ type })}
          style={{
            backgroundImage: flags.FE_DECK_THUMBNAIL
              ? `url(${fallbackImage}`
              : thumbnailUrl
                ? `url(${thumbnailUrl}`
                : `url(${fallbackImage}`,
          }}
        >
          <div css={loadingStyles({ isLoading })}>
            <Loader size={6} />
          </div>
          <div css={contentStyles({ showActions })}>
            {primaryButtons && primaryButtons}
            <div
              css={css`
                display: flex;
                flex-direction: row;
                gap: ${theme.spacingV2.xsmall};
              `}
            >
              {secondaryButtons && secondaryButtons}
            </div>
          </div>
          {flags.FE_DECK_THUMBNAIL && isDeckFinal && (
            <div data-font-family-exception="true" css={cardCanvasStyles}>
              <Canvas
                dataType={CANVAS_DATA_TYPE.DECK}
                dataProps={{ deckId, orderIndex: 1 }}
              />
            </div>
          )}
        </div>
      </div>
    )
  },
)
