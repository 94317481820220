export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_101_38)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_101_38"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_32_626)"><path fill-rule="evenodd" clip-rule="evenodd" d="M959.381 318.69C959.381 705.671 645.671 1019.38 258.69 1019.38C167.321 1019.38 80.0356 1001.89 0 970.08V1080H1920V0H882.879C931.79 95.605 959.381 203.926 959.381 318.69Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M714 -41C714 -161.95 812.05 -260 933 -260C1053.95 -260 1152 -161.95 1152 -41C1152 79.9504 1053.95 178 933 178C812.05 178 714 79.9504 714 -41ZM933 -250C817.572 -250 724 -156.428 724 -41C724 74.4275 817.572 168 933 168C1048.43 168 1142 74.4275 1142 -41C1142 -156.428 1048.43 -250 933 -250Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M-87 1040.5C-87 934.663 0.156143 849 107.5 849C214.844 849 302 934.663 302 1040.5C302 1146.34 214.844 1232 107.5 1232C0.156143 1232 -87 1146.34 -87 1040.5ZM107.5 859C5.52795 859 -77 940.335 -77 1040.5C-77 1140.66 5.52795 1222 107.5 1222C209.472 1222 292 1140.66 292 1040.5C292 940.335 209.472 859 107.5 859Z" fill="$color2"/></g><defs><clipPath id="clip0_32_626"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H1920V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_32_632)"><path fill-rule="evenodd" clip-rule="evenodd" d="M960.619 318.69C960.619 705.671 1274.33 1019.38 1661.31 1019.38C1752.68 1019.38 1839.96 1001.89 1920 970.08V1080H0V0H1037.12C988.21 95.605 960.619 203.926 960.619 318.69Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1206 -41C1206 -161.95 1107.95 -260 987 -260C866.05 -260 768 -161.95 768 -41C768 79.9504 866.05 178 987 178C1107.95 178 1206 79.9504 1206 -41ZM987 -250C1102.43 -250 1196 -156.428 1196 -41C1196 74.4275 1102.43 168 987 168C871.572 168 778 74.4275 778 -41C778 -156.428 871.572 -250 987 -250Z" fill="$color3"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2007 1040.5C2007 934.663 1919.84 849 1812.5 849C1705.16 849 1618 934.663 1618 1040.5C1618 1146.34 1705.16 1232 1812.5 1232C1919.84 1232 2007 1146.34 2007 1040.5ZM1812.5 859C1914.47 859 1997 940.335 1997 1040.5C1997 1140.66 1914.47 1222 1812.5 1222C1710.53 1222 1628 1140.66 1628 1040.5C1628 940.335 1710.53 859 1812.5 859Z" fill="$color2"/></g><defs><clipPath id="clip0_32_632"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H0V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_32_624)"><rect width="1920" height="1080" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M83 540C83 55.6463 475.646 -337 960 -337C1444.35 -337 1837 55.6463 1837 540C1837 1024.35 1444.35 1417 960 1417C475.646 1417 83 1024.35 83 540ZM960 -327C481.169 -327 93 61.1691 93 540C93 1018.83 481.169 1407 960 1407C1438.83 1407 1827 1018.83 1827 540C1827 61.1691 1438.83 -327 960 -327Z" fill="$color2"/></g><defs><clipPath id="clip0_32_624"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_32_618)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM60 1020L1860 1020V644L60 644L60 1020Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1885 1045L35 1045L35 619L1885 619L1885 1045ZM1875 1035L1875 629L45 629L45 1035L1875 1035Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M83 1182C-19.7249 1182 -103 1098.72 -103 996C-103 893.275 -19.7249 810 83 810C185.725 810 269 893.275 269 996C269 1098.72 185.725 1182 83 1182ZM-113 996C-113 1104.25 -25.2478 1192 83 1192C191.248 1192 279 1104.25 279 996C279 887.752 191.248 800 83 800C-25.2478 800 -113 887.752 -113 996Z" fill="$color3"/></g><defs><clipPath id="clip0_32_618"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="540" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_32_602)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM178 539.5C178 706.566 313.21 842 480 842C646.79 842 782 706.566 782 539.5C782 372.434 646.79 237 480 237C313.21 237 178 372.434 178 539.5Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M836 539.5C836 343.156 676.607 184 480 184C283.393 184 124 343.156 124 539.5C124 735.844 283.393 895 480 895C676.607 895 836 735.844 836 539.5ZM480 194C671.097 194 826 348.692 826 539.5C826 730.308 671.097 885 480 885C288.903 885 134 730.308 134 539.5C134 348.692 288.903 194 480 194Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M639 894.5C639 806.948 567.798 736 480 736C392.202 736 321 806.948 321 894.5C321 982.052 392.202 1053 480 1053C567.798 1053 639 982.052 639 894.5ZM480 746C562.306 746 629 812.501 629 894.5C629 976.499 562.306 1043 480 1043C397.694 1043 331 976.499 331 894.5C331 812.501 397.694 746 480 746Z" fill="$color3"/></g><defs><clipPath id="clip0_32_602"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_32_613)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM920 1040L40 1040L39.9999 40.0002L920 40.0001L920 1040Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M15 1065L945 1065L945 15L14.9999 15.0001L15 1065ZM27.1569 1055L27.1568 25.0001L932.843 25L932.843 1055L27.1569 1055Z" fill="$color2"/></g><defs><clipPath id="clip0_32_613"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_32_596)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1742 539.5C1742 706.566 1606.79 842 1440 842C1273.21 842 1138 706.566 1138 539.5C1138 372.434 1273.21 237 1440 237C1606.79 237 1742 372.434 1742 539.5Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1084 539.5C1084 343.156 1243.39 184 1440 184C1636.61 184 1796 343.156 1796 539.5C1796 735.844 1636.61 895 1440 895C1243.39 895 1084 735.844 1084 539.5ZM1440 194C1248.9 194 1094 348.692 1094 539.5C1094 730.308 1248.9 885 1440 885C1631.1 885 1786 730.308 1786 539.5C1786 348.692 1631.1 194 1440 194Z" fill="$color2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1281 894.5C1281 806.948 1352.2 736 1440 736C1527.8 736 1599 806.948 1599 894.5C1599 982.052 1527.8 1053 1440 1053C1352.2 1053 1281 982.052 1281 894.5ZM1440 746C1357.69 746 1291 812.501 1291 894.5C1291 976.499 1357.69 1043 1440 1043C1522.31 1043 1589 976.499 1589 894.5C1589 812.501 1522.31 746 1440 746Z" fill="$color3"/></g><defs><clipPath id="clip0_32_596"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_32_608)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1000 1040L1880 1040L1880 40.0002L1000 40.0001L1000 1040Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1905 1065L975 1065L975 15.0002L1905 15.0002L1905 1065ZM1892.84 1055L1892.84 25.0002L987.157 25.0002L987.157 1055L1892.84 1055Z" fill="$color2"/></g><defs><clipPath id="clip0_32_608"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
