import React, { useState, useMemo, useCallback } from 'react'
import { VALIDATION_RULE_TYPES, useLanguage } from 'src/hooks'
import { v4 as uuidv4 } from 'uuid'
import { IOrganizationAddMembers } from './types'
import {
  orgAddMembersStyles,
  orgAddMembersLeftColStyles,
  orgAddMembersRightColStyles,
  orgAddMembersTitleStyles,
  orgAddMembersFirstRowStyles,
  orgAddMemberEmailItemStyles,
  orgAddMembersUsedSeatsStyles,
  orgAddMembersInformationStyles,
  orgAddMembersActionStyles,
} from './styles'
import {
  Button,
  BUTTON_THEME,
  BUTTON_TYPE,
  Input,
  INPUT_SIZE,
  INPUT_WIDTH,
  Icon,
  icons,
} from 'src/lib'
import { colors, dimensions } from 'src/theme'
import { useOrgApi } from 'src/hooks/api/useOrgApi'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const OrganizationAddMembers: React.FC<IOrganizationAddMembers> =
  React.memo(({ onClose, totalSeats, occupiedSeats, workspaceId }) => {
    const { t, Trans } = useLanguage()
    const [email, setEmail] = useState('')
    const [emails, setEmails] = useState<string[] | null>(null)
    const [emailValid, setEmailValid] = useState(false)
    const { inviteUsers, getOrgUsers, isLoading } = useOrgApi()

    const { users } = useSelector(({ org }: RootState) => ({
      users: org.users,
    }))

    const isEmailExits = useMemo(
      () =>
        !!emails &&
        emails.some(
          (existingEmail) =>
            existingEmail.toLowerCase() === email.toLowerCase(),
        ),
      [emails, email, emailValid],
    )

    const isMember = useMemo(
      () =>
        !!users &&
        users.some(
          (value) => value.user.email.toLowerCase() === email.toLowerCase(),
        ),
      [users, emails, email],
    )

    const occupiedSeatsCount = useMemo(
      () => occupiedSeats! + (emails?.length ?? 0),
      [occupiedSeats, emails],
    )

    const areSeatsFull = useMemo(
      () => occupiedSeatsCount! >= totalSeats!,
      [occupiedSeatsCount, totalSeats],
    )

    const isAddMembersDisabled = useMemo(
      () => occupiedSeatsCount > totalSeats! || !emails?.length || isLoading,
      [occupiedSeatsCount, totalSeats, emails],
    )

    const onChangeHandler = useCallback((value: string) => setEmail(value), [])
    const onValidationHandler = useCallback(
      (value: boolean) => setEmailValid(value),
      [],
    )
    const onAddMailHandler = useCallback(() => {
      if (isEmailExits || isMember || !emailValid) return
      const emailLowerCase = email.toLowerCase()
      const validatedEmails = emails
        ? [emailLowerCase].concat(emails)
        : [emailLowerCase]
      setEmails(validatedEmails)
      setEmail('')
    }, [isEmailExits, emailValid, email, emails, isMember])

    const deleteEmailHandler = useCallback(
      (selectedEmail: string) => {
        const updatedEmails = emails!.filter(
          (value: string) => value !== selectedEmail,
        )
        setEmails(updatedEmails)
      },
      [emails],
    )

    const addMembersHandler = useCallback(() => {
      if (!emails || !workspaceId) return
      const inviteUsersParams = {
        id: workspaceId!,
        emails,
      }
      invite(inviteUsersParams)
    }, [workspaceId, emails])

    const invite = async (params: { id: number; emails: string[] }) => {
      const res = await inviteUsers(params)
      if (res) {
        getOrgUsers(params.id)
        onClose()
        setEmails(null)
      }
    }

    return (
      <div css={orgAddMembersStyles}>
        <div css={orgAddMembersLeftColStyles}>
          <Icon
            icon={icons.team}
            size={dimensions.emptyMemberImageWidth.small}
            color={colors.primary.DEFAULT}
          />
        </div>
        <div css={orgAddMembersRightColStyles}>
          <div css={orgAddMembersTitleStyles}>
            <span>{t('organization.add_members_title')}</span>
            <Button
              icon={icons.close}
              theme={BUTTON_THEME.GRAY}
              type={BUTTON_TYPE.GHOST}
              isLink
              iconSize={16}
              onClick={onClose}
            />
          </div>

          <div css={orgAddMembersFirstRowStyles}>
            <div className="email-button-row">
              <Input
                size={INPUT_SIZE.SMALL}
                width={INPUT_WIDTH.FULL}
                value={email}
                onChange={onChangeHandler}
                onEnter={onAddMailHandler}
                onValidation={onValidationHandler}
                validation={{
                  [VALIDATION_RULE_TYPES.REQUIRED]: {
                    text: t('validation.error.required', {
                      name: t('common.email'),
                    }),
                  },
                  [VALIDATION_RULE_TYPES.VALIDMAIL]: {
                    text: t('validation.error.email'),
                  },
                  [VALIDATION_RULE_TYPES.EXISTS]: {
                    value: !isEmailExits && !isMember,
                    text: t('validation.validation_user_exists'),
                  },
                }}
                solidError
                errorAlignRight
                placeholder="yourname@email.com"
              />
              <Button
                icon={icons.plus}
                theme={BUTTON_THEME.PRIMARY}
                isLink
                iconSize={16}
                onClick={onAddMailHandler}
              />
            </div>
            <div css={orgAddMembersInformationStyles('information')}>
              <div>
                <Icon icon={icons.info} size={16} color={colors.text[2]} />
              </div>
              <div>{t('organization.add_member_information')}</div>
            </div>
          </div>

          {emails?.map((email) => (
            <div key={uuidv4()} css={orgAddMemberEmailItemStyles}>
              <span>{email}</span>
              <Button
                icon={icons.trash_can}
                theme={BUTTON_THEME.RED}
                type={BUTTON_TYPE.GHOST}
                isLink
                iconSize={16}
                onClick={() => deleteEmailHandler(email)}
              />
            </div>
          ))}

          <div css={orgAddMembersUsedSeatsStyles(false)}>
            <Trans
              i18nKey={'organization.used_seats'}
              values={{
                occupiedSeats: occupiedSeatsCount,
                totalSeats,
              }}
            />
          </div>

          {areSeatsFull && (
            <div css={orgAddMembersInformationStyles('warning')}>
              <div>
                <Icon icon={icons.info} size={16} color={colors.red.DEFAULT} />
              </div>
              <div>{t('organization.not_enough_seats')}</div>
            </div>
          )}

          <div css={orgAddMembersActionStyles}>
            <Button
              text={t('organization.add_members')}
              disabled={isAddMembersDisabled}
              onClick={addMembersHandler}
            />
          </div>
        </div>
      </div>
    )
  })
