import { ChangeEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setOrgAICredits,
  setOrgAICreditsSummary,
  setOrgDetails,
  setOrgUsers,
  setOrgPendingUsers,
  setDeckSharesCustomDomainsNull,
} from 'src/store'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import {
  getAICreditSpendSummarizedResponse,
  getAICreditsTotalResponse,
  getOrganizationCustomDomainSingleResponse,
  getOrganizationCustomDomainsResponse,
  getOrganizationDetailsResponse,
  getUsersInOrganizationResponse,
  postActivateOrganizationResponse,
} from 'src/types/api/responseObjects'
import { useConfigApi, useNotification, useLanguage } from 'src/hooks'
import {
  organizationIdCustomDomainIdParams,
  organizationIdParams,
  postCreateOrganizationCustomDomainBody,
} from 'src/types/api/requestObjects'

interface IUseOrgApiReturn {
  getOrgAiCredits: (id: number) => Promise<number>
  getOrgAICreditsSummary: (range: {
    id: number
    startDate: Date
    endDate: Date
  }) => Promise<void>
  getOrgDetails: (id: number) => Promise<getOrganizationDetailsResponse['data']>
  getOrgUsers: (id: number) => Promise<getUsersInOrganizationResponse>
  inviteUsers: ({
    id,
    emails,
    isResend,
  }: {
    id: number
    emails: string[]
    isResend?: boolean
  }) => Promise<boolean>
  addCustomDomain: (
    params: postCreateOrganizationCustomDomainBody,
    orgID: number,
  ) => Promise<boolean>
  leaveOrganization: (id: number) => Promise<boolean>
  updateOrgUserStatus: ({
    id,
    isAdmin,
    userID,
  }: {
    id: number
    isAdmin: boolean
    userID: number
  }) => Promise<boolean>
  removeOrgUser: ({
    id,
    userID,
  }: {
    id: number
    userID: number
  }) => Promise<boolean>
  removePendingInvitation: ({
    id,
    inviteID,
  }: {
    id: number
    inviteID: number
  }) => Promise<boolean>
  updateOrgDetails: ({
    id,
    name,
    description,
    logoUrl,
  }: {
    id: number
    name?: string
    description?: string
    logoUrl?: ChangeEvent<HTMLInputElement>
  }) => Promise<void>
  activateOrganization: (
    id: number,
  ) => Promise<postActivateOrganizationResponse['data']['checkoutSessionUrl']>
  getCustomDomainInfo: (
    params: organizationIdCustomDomainIdParams,
  ) => Promise<
    getOrganizationCustomDomainSingleResponse['data']['customDomain']
  >
  getOrgCustomDomainInfo: (
    params: organizationIdParams,
  ) => Promise<getOrganizationCustomDomainsResponse['data']['customDomains']>
  deleteOrgCustomDomain: (
    params: organizationIdCustomDomainIdParams,
  ) => Promise<boolean>
  isLoading: boolean
}

export const useOrgApi = (): IUseOrgApiReturn => {
  const { t } = useLanguage()
  const dispatch = useDispatch()
  const { success } = useNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { uploadImage } = useConfigApi()

  const getCustomDomainInfo = async (
    params: organizationIdCustomDomainIdParams,
  ): Promise<
    getOrganizationCustomDomainSingleResponse['data']['customDomain']
  > => {
    setIsLoading(true)
    try {
      const res: RequestResponse<
        getOrganizationCustomDomainSingleResponse,
        any
      > = await RequestServices.callApi({
        method: 'GET',
        url: `${
          API_CONFIG.ORGANIZATION
        }/${params.organizationId.toString()}/domains/${params.customDomainId.toString()}`,
      })
      setIsLoading(false)
      return res.data.data.customDomain
    } finally {
      setIsLoading(false)
    }
  }

  const getOrgCustomDomainInfo = async (
    params: organizationIdParams,
  ): Promise<getOrganizationCustomDomainsResponse['data']['customDomains']> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getOrganizationCustomDomainsResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: `${
            API_CONFIG.ORGANIZATION
          }/${params.organizationId.toString()}/domains`,
        })
      setIsLoading(false)
      return res.data.data.customDomains
    } finally {
      setIsLoading(false)
    }
  }

  const deleteOrgCustomDomain = async (
    params: organizationIdCustomDomainIdParams,
  ): Promise<boolean> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getOrganizationCustomDomainsResponse, any> =
        await RequestServices.callApi({
          method: 'DELETE',
          url: `${
            API_CONFIG.ORGANIZATION
          }/${params.organizationId.toString()}/domains/${params.customDomainId.toString()}`,
        })
      setIsLoading(false)

      const isSuccess = res.data.code === 200

      if (isSuccess) {
        dispatch(
          setDeckSharesCustomDomainsNull({
            customDomainId: params.customDomainId,
          }),
        )
      }
      return isSuccess
    } finally {
      setIsLoading(false)
    }
  }

  const getOrgAiCredits = async (id: number): Promise<number> => {
    setIsLoading(true)

    try {
      const res: RequestResponse<getAICreditsTotalResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: `${API_CONFIG.ORGANIZATION}/${id}/aicredits/total`,
        })
      dispatch(setOrgAICredits(res.data.data.totalCredits))
      return res.data.data.totalCredits
    } finally {
      setIsLoading(false)
    }
  }

  const getOrgAICreditsSummary = async ({
    id,
    startDate,
    endDate,
  }: IUseOrgApiReturn['getOrgAICreditsSummary']['arguments']): Promise<void> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getAICreditSpendSummarizedResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: `${API_CONFIG.ORGANIZATION}/${id}/aicredits/history/spend/summary`,
          params: {
            startDate,
            endDate,
          },
        })
      dispatch(setOrgAICreditsSummary(res.data.data))
    } finally {
      setIsLoading(false)
    }
  }

  const getOrgDetails = async (
    id: number,
  ): Promise<getOrganizationDetailsResponse['data']> => {
    setIsLoading(true)

    try {
      const res: RequestResponse<getOrganizationDetailsResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: `${API_CONFIG.ORGANIZATION}/${id}`,
        })
      dispatch(setOrgDetails(res.data.data))
      return res.data.data
    } finally {
      setIsLoading(false)
    }
  }

  const getOrgUsers = async (
    id: number,
  ): Promise<IUseOrgApiReturn['getOrgUsers']['arguments']> => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getUsersInOrganizationResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: `${API_CONFIG.ORGANIZATION_USERS(id.toString())}`,
        })
      dispatch(setOrgUsers(res.data.data))
      dispatch(setOrgPendingUsers(res.data.data))
      return res.data.data
    } finally {
      setIsLoading(false)
    }
  }
  const updateOrgUserStatus = async ({
    id,
    isAdmin,
    userID,
  }: IUseOrgApiReturn['updateOrgUserStatus']['arguments']) => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: `${API_CONFIG.ORGANIZATION_USERS_UPDATE(
          id.toString(),
          userID.toString(),
        )}`,
        data: {
          isAdmin: isAdmin,
        },
      })
      getOrgUsers(id)
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const removeOrgUser = async ({
    id,
    userID,
  }: IUseOrgApiReturn['removeOrgUser']['arguments']) => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'DELETE',
        url: `${API_CONFIG.ORGANIZATION_USERS_DELETE(
          id.toString(),
          userID.toString(),
        )}`,
      })
      getOrgUsers(id)
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const removePendingInvitation = async ({
    id,
    inviteID,
  }: IUseOrgApiReturn['removePendingInvitation']['arguments']) => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'DELETE',
        url: `${API_CONFIG.ORGANIZATION_INVITE_DELETE(
          id.toString(),
          inviteID.toString(),
        )}`,
      })
      getOrgUsers(id)
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const inviteUsers = async ({
    emails,
    id,
    isResend,
  }: {
    emails: string[]
    id: number
    isResend?: boolean
  }) => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: `${API_CONFIG.ORGANIZATION_INVITE(id.toString())}`,
        data: {
          emails,
        },
      })
      if (isResend)
        success(
          t('organization.resend_info', {
            email: emails[0],
          }),
        )
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const addCustomDomain = async (
    params: postCreateOrganizationCustomDomainBody,
    orgID: number,
  ) => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: `${API_CONFIG.ORGANIZATION}/${orgID.toString()}/domain`,
        data: {
          domain: params.domain,
        },
      })
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const leaveOrganization = async (orgID: number): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'DELETE',
        url: `${API_CONFIG.USER_ORGANIZATIONS}/${orgID}`,
      })
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const updateOrgDetails = async ({
    id,
    name,
    description,
    logoUrl,
  }: {
    id: number
    name?: string
    description?: string
    logoUrl?: ChangeEvent<HTMLInputElement>
  }) => {
    setIsLoading(true)
    try {
      let imageUploadUrl = null
      if (logoUrl) {
        imageUploadUrl = await uploadImage({ file: logoUrl })
      }
      await RequestServices.callApi({
        method: 'PUT',
        url: `${API_CONFIG.ORGANIZATION}/${id}`,
        data: {
          ...(name && { name: name }),
          description,
          ...((logoUrl || logoUrl === null) && { logoUrl: imageUploadUrl }),
        },
      })
      getOrgDetails(id)
      success(
        'organization.organization_profile_update.name_and_description_success',
      )
    } finally {
      setIsLoading(false)
    }
  }

  const activateOrganization = async (
    orgID: number,
  ): Promise<
    postActivateOrganizationResponse['data']['checkoutSessionUrl']
  > => {
    setIsLoading(true)
    try {
      const res: RequestResponse<postActivateOrganizationResponse, any> =
        await RequestServices.callApi({
          method: 'POST',
          url: `${API_CONFIG.ORGANIZATION_ACTIVATE(orgID.toString())}`,
        })
      return res.data.data.checkoutSessionUrl
    } finally {
      setIsLoading(false)
    }
  }

  return {
    getOrgAiCredits,
    getOrgAICreditsSummary,
    getOrgDetails,
    getOrgUsers,
    updateOrgUserStatus,
    removeOrgUser,
    removePendingInvitation,
    inviteUsers,
    addCustomDomain,
    leaveOrganization,
    updateOrgDetails,
    activateOrganization,
    getCustomDomainInfo,
    getOrgCustomDomainInfo,
    deleteOrgCustomDomain,
    isLoading,
  }
}
