import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IFileImport, IFileUpload } from './types'
import {
  imageStyles,
  importAreaStyles,
  pdfImportStyles,
  pdfImportTextStyles,
  subTitleStyles,
  titleStyles,
  uploadedPdfStyles,
  fileSizeStyles,
  pdfBoxStyles,
  iconCloseRowStyles,
  sizeErrorStyles,
  fileNameStyles,
  fileInfoBoxStyles,
  progressBoxStyles,
  uploadingPercentStyles,
} from './styles'
import { buttonStyles } from '../ai-prompt/styles'
import {
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  HorizontalProgress,
  Icon,
  Loader,
  TOOLTIP_THEME,
  Tooltip,
  icons,
} from 'src/lib'
import { useLanguage } from 'src/hooks'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import {
  AI_FLOW_STEP,
  RootState,
  setAiFlowStep,
  setUploadedFiles,
} from 'src/store'
import { useAiFlowApi } from 'src/hooks/api/useAiFlowApi'

import { APP_CONFIG } from 'src/config'
import { colors } from 'src/theme'
import { Steps } from 'src/lib/steps'
import { AiDeckCategories, AiDeckFileStates } from 'src/types/api/enums'
import { postDeckFileUploadUrlsBody } from 'src/types/api/requestObjects'
import axios from 'axios'
import delay from 'src/helpers/delay'
import isEqual from 'lodash/isEqual'
import { postCheckDeckFileStatusResponse } from 'src/types/api/responseObjects'

import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
const MB = 1024 * 1024

export const FileImport: React.FC<IFileImport> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const posthogCapture = usePostHogCapture()
    const { selectedCategory, uploadedFiles, aiFlowId, selectedPrompt } =
      useSelector(({ aiFlow }: RootState) => ({
        selectedCategory: aiFlow.selectedCategory,
        uploadedFiles: aiFlow.uploadedFiles,
        aiFlowId: aiFlow.aiFlowId,
        selectedPrompt: aiFlow.selectedPrompt,
      }))

    const isOtherCategorySelected = selectedCategory === AiDeckCategories.OTHER
    const {
      getAiFlowFileUploadUrls,
      getAiFlowOutline,
      checkAiFlowFileStatus,
      deleteAiFlowFiles,
      getAiFlowAudience,
    } = useAiFlowApi()

    const [loadingState, setLoadingState] = useState(false)

    const [uploadFiles, setUploadFiles] = useState<IFileUpload[]>(uploadedFiles)

    const [fileRejection, setFileRejection] = useState<string>()

    const isReachedMaximumLimit = useMemo(
      () => uploadFiles?.length > 4,
      [uploadFiles],
    )

    useEffect(() => {
      aiFlowId &&
        posthogCapture(POSTHOG_EVENTS.AI_FLOW_FILE_UPLOAD_VIEW, {
          ai_flow_id: aiFlowId,
        })
    }, [aiFlowId])

    const updateFileState = (
      id?: string,
      updateObject?: Partial<IFileUpload>,
    ) => {
      setUploadFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === id
            ? {
                ...file,
                ...updateObject,
              }
            : file,
        ),
      )
    }

    const fileUploader = useCallback(async (files: IFileUpload[]) => {
      try {
        const uploadPromises = files
          .map(({ uploadUrl, id, file }) => {
            if (!file) {
              return null
            }
            const controller = new AbortController()
            updateFileState(id, {
              uploadInProgress: true,
              abortController: controller,
            })

            return new Promise((resolve, reject) => {
              axios
                .put(uploadUrl ?? '', file, {
                  signal: controller.signal,
                  headers: {
                    'Content-Type': file.type,
                  },
                  onUploadProgress: (progressEvent) => {
                    const percent =
                      progressEvent.total &&
                      (progressEvent.loaded / progressEvent.total) * 100

                    // set state for progress
                    updateFileState(id, { uploadProgress: percent })
                  },
                })
                .then(() => {
                  updateFileState(id, {
                    uploaded: true,
                    uploadInProgress: false,
                    uploadProgress: 100,
                  })

                  resolve(true)
                })
                .catch(() => reject())
            })
          })
          .filter(Boolean)
        await Promise.all(uploadPromises)
      } catch (error) {
        console.error('Upload Error', error)
      }
    }, [])

    useEffect(() => {
      if (uploadFiles.length > 0) {
        fileUploader(
          uploadFiles.filter(
            (file) => !file.uploaded && !file.uploadInProgress,
          ),
        )
      }
    }, [uploadFiles])

    const dropzoneValidator = (file: File) => {
      if (file.size > APP_CONFIG.pdfImportAllowedSize) {
        return {
          code: 'custom-file-too-large',
          message: t('file_import.size_error', { fileName: file.name }),
        }
      }
      //eleminate duplicate files
      if (uploadFiles.find((f) => f.name === file.name)) {
        return {
          code: 'custom-file-duplicate',
          message: t('file_import.already_uploaded', {
            fileName: file.name,
          }),
        }
      }
      //eleminate unsupported formatted files
      if (
        ![
          'application/pdf',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/json',
          'text/plain',
          'text/markdown',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ].includes(file.type) &&
        !file.name.endsWith('.md')
      ) {
        return {
          code: 'custom-file-invalid-type',
          message: t('file_import.use_file_format', { fileName: file.name }),
        }
      }
      return null
    }

    const onDrop = async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
    ) => {
      if (isReachedMaximumLimit) {
        setFileRejection(undefined)
        return
      }
      const files: IFileUpload[] = acceptedFiles.map((file) => ({
        file,
        name: file.name,
        size: file.size,
      }))

      if (uploadFiles.length + files.length > 5) {
        files.splice(5 - uploadFiles.length)
      }
      if (files.length < 5) {
        const rejection = fileRejections
          .map(
            (rejection) =>
              rejection.errors.find((error) => error.code.startsWith('custom-'))
                ?.message,
          )
          .filter(Boolean)
          .filter((item, pos, self) => self.indexOf(item) == pos)
          .join('.\n')

        setFileRejection(rejection)
      }

      if (!files.length) {
        return
      }

      setLoadingState(true)

      const uploadUrls = (
        await getAiFlowFileUploadUrls({
          files: files.map(({ name }) => {
            const splittedName = name.split('.')
            const extension = splittedName.pop()
            const fileName = splittedName.join('.')
            return {
              fileName,
              extension,
            }
          }) as postDeckFileUploadUrlsBody['files'],
        })
      )?.uploadUrls

      uploadUrls &&
        files.forEach((file, index) => {
          file.uploadUrl = uploadUrls[index].uploadUrl
          file.id = uploadUrls[index].fileId
        })
      const fileUploads = files.filter((file) => file.uploadUrl)
      setUploadFiles((prevFiles) => [...prevFiles, ...fileUploads])
      setLoadingState(false)
    }

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
        'application/pdf': ['.pdf'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx'],
        'application/json': ['.json'],
        'text/plain': ['.txt'],
        'text/markdown': ['.md'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          ['.pptx'],
      },
      multiple: true,
      disabled: isReachedMaximumLimit,
      validator: dropzoneValidator,
    })

    const isUploading = uploadFiles.some(
      (file) => file.uploadInProgress && !file.uploaded,
    )

    const isCheckingFileStatus = uploadFiles.some((file) => !file.state)

    const checkFileStatus = useCallback(async () => {
      const files = uploadedFiles.filter((file) => !file.state)
      if (files.length > 0) {
        let filesWithStatus:
          | postCheckDeckFileStatusResponse['data']['deckFiles']
          | null = null
        let maxRetry = 12
        while (filesWithStatus?.length !== files.length && maxRetry > 0) {
          filesWithStatus = await checkAiFlowFileStatus({
            fileIds: files.map((file) => ({ fileId: file.id ?? '' })),
          })
          if (!filesWithStatus || !filesWithStatus.length) {
            await delay(2000)
          }
          maxRetry--
        }
        filesWithStatus?.forEach((file) => {
          updateFileState(file.fileId, { state: file.state })
        })
      }
    }, [uploadedFiles])

    useEffect(() => {
      checkFileStatus()
    }, [uploadedFiles])

    useEffect(() => {
      if (!isUploading) {
        const files = uploadFiles
          .map(({ id, name, size, uploaded, state }) => ({
            id,
            name,
            size,
            uploaded,
            state,
          }))
          .filter((file) => file.uploaded)
        if (!isEqual(files, uploadedFiles)) {
          dispatch(setUploadedFiles(files))
        }
      }
    }, [uploadFiles, isUploading])

    const captureFileUploadEvent = useCallback((readyFiles: IFileUpload[]) => {
      /// group by type
      const groupedFiles = readyFiles.reduce(
        (acc, file) => {
          const type = file.name.split('.').pop()
          if (!type) {
            return acc
          }
          if (!acc[type]) {
            acc[type] = []
          }
          acc[type].push(file)
          return acc
        },
        {} as Record<string, IFileUpload[]>,
      )
      readyFiles.length &&
        posthogCapture(POSTHOG_EVENTS.AI_FLOW_FILE_UPLOADED, {
          aiFlowId,
          file_count: readyFiles.length,
          file_details: Object.keys(groupedFiles).map((key) => ({
            file_type: key,
            file_type_count: groupedFiles[key].length,
          })),
        })
    }, [])

    const nextButton = async () => {
      setLoadingState(true)

      const deckPromptId = await getAiFlowAudience({
        prompt: selectedPrompt,
        category: selectedCategory,
      })
      if (!deckPromptId) {
        return
      }

      if (selectedCategory === AiDeckCategories.OTHER) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.AUDIENCE))
        return
      }
      const readyFiles = uploadFiles.filter(
        (file) => file.state === AiDeckFileStates.READY,
      )
      captureFileUploadEvent(readyFiles)
      const apiResult = await getAiFlowOutline({
        deckPromptId,
        fileIds: readyFiles.map(({ id }) => id ?? ''),
      })
      if (apiResult) {
        dispatch(setAiFlowStep(AI_FLOW_STEP.OUTLINE))
      }
    }

    const backButton = () => {
      dispatch(setAiFlowStep(AI_FLOW_STEP.CATEGORY))
    }

    const onRemoveFile = useCallback(
      async (id?: string) => {
        if (id) {
          const file = uploadFiles.find((file) => file.id === id)
          file?.uploadInProgress && file?.abortController?.abort()
          setUploadFiles(uploadFiles.filter((file) => file.id !== id))
          file?.uploaded && (await deleteAiFlowFiles({ fileIds: [id] }))
        }
      },
      [uploadFiles],
    )

    const steps = isOtherCategorySelected ? 5 : 3
    const navigationDisabled =
      loadingState || isUploading || isCheckingFileStatus

    return (
      <div css={pdfImportStyles} className={className} {...dataAttr}>
        {
          <div>
            {loadingState && (
              <Loader size={24} isFullWidth className="loader" />
            )}

            <Steps steps={steps} current={1} />
            {!loadingState && (
              <div
                css={importAreaStyles(!!uploadFiles.length)}
                {...(!isReachedMaximumLimit && { ...getRootProps() })}
              >
                <div css={pdfImportTextStyles}>
                  <input type="file" {...getInputProps()} />
                  <div css={imageStyles}>
                    <Icon
                      icon={icons.upload}
                      size={36}
                      color={
                        isReachedMaximumLimit
                          ? colors.deactive.DEFAULT
                          : colors.primary.DEFAULT
                      }
                    />
                  </div>
                  <div css={titleStyles(false)}>
                    {isReachedMaximumLimit
                      ? t('file_import.maximum')
                      : t('file_import.drag_and_drop')}
                  </div>
                  <div css={subTitleStyles}>
                    <span>
                      {isReachedMaximumLimit
                        ? t('file_import.maximum_info')
                        : t('file_import.maximum_files')}
                    </span>
                  </div>
                  {!isReachedMaximumLimit && fileRejection && (
                    <div css={sizeErrorStyles}>{fileRejection}</div>
                  )}
                </div>
              </div>
            )}

            <div css={uploadedPdfStyles}>
              {!!uploadFiles.length &&
                !loadingState &&
                uploadFiles?.map(
                  ({
                    id,
                    name,
                    size,
                    uploadInProgress,
                    uploadProgress,
                    uploaded,
                    state,
                  }) => (
                    <div
                      key={id}
                      css={pdfBoxStyles(state === AiDeckFileStates.ERROR)}
                    >
                      <div css={iconCloseRowStyles}>
                        <Icon
                          icon={icons.document}
                          size={24}
                          color={colors.black.DEFAULT}
                        />
                        <Icon
                          icon={icons.close}
                          size={12}
                          color={colors.black.DEFAULT}
                          onClick={() => onRemoveFile(id)}
                        />
                      </div>
                      <Tooltip
                        width={282}
                        text={name}
                        theme={TOOLTIP_THEME.DARK}
                      >
                        <div css={fileNameStyles}>{name}</div>
                      </Tooltip>
                      <div css={fileInfoBoxStyles}>
                        {uploaded && (
                          <span css={fileSizeStyles}>
                            {(size / MB).toFixed(1)} MB
                          </span>
                        )}

                        {!isUploading && (
                          <Tooltip
                            width={282}
                            text={t(
                              `file_import.file_state.${
                                !state
                                  ? 'processing'
                                  : state === AiDeckFileStates.READY
                                    ? 'ready'
                                    : 'error'
                              }_info`,
                            )}
                            theme={TOOLTIP_THEME.DARK}
                          >
                            <span css={fileSizeStyles}>
                              {t(
                                `file_import.file_state.${
                                  !state
                                    ? 'processing'
                                    : state === AiDeckFileStates.READY
                                      ? 'ready'
                                      : 'error'
                                }`,
                              )}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      {uploadInProgress && (
                        <div css={progressBoxStyles}>
                          <HorizontalProgress percentage={uploadProgress} />
                          <span css={uploadingPercentStyles}>
                            {t('common.percent', {
                              value: uploadProgress?.toFixed(0),
                            })}
                          </span>
                        </div>
                      )}
                    </div>
                  ),
                )}
            </div>
          </div>
        }
        <div css={buttonStyles}>
          <Button
            type={BUTTON_TYPE.GHOST}
            text={t('common.actions.back')}
            icon={icons.chevron_left}
            disabled={navigationDisabled}
            isLink
            onClick={backButton}
          />
          <Button
            text={t(
              uploadFiles.length
                ? 'common.actions.next'
                : 'common.actions.skip',
            )}
            theme={BUTTON_THEME.GRADIENT}
            onClick={nextButton}
            disabled={navigationDisabled}
            isLoading={loadingState || isUploading || isCheckingFileStatus}
          />
        </div>
      </div>
    )
  },
)

FileImport.displayName = 'FileImport'
