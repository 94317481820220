import React, { useCallback, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { RootState } from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import { SideMenu } from 'src/components/side-menu'
import { Icon, icons } from 'src/lib'
import {
  adminMenuStyles,
  adminMenuInnerStyles,
  bottomMenuItemStyles,
} from '../styles'
import { colors } from 'src/theme'
import { setAdminUsers } from 'src/store'

export const AdminMenu: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const dispatch = useDispatch()

  const { adminSearchResult } = useSelector(({ admin }: RootState) => ({
    adminSearchResult: admin.user,
  }))

  const handleBackToSearch = useCallback(() => {
    dispatch(setAdminUsers({ user: null }))
  }, [])

  const stickyItemAtBottom = useMemo(() => {
    if (adminSearchResult) {
      return (
        <div css={bottomMenuItemStyles} onClick={handleBackToSearch}>
          <Icon icon={icons.back_to} color={colors.outline[2]} />
          <span>{t('admin.menu.back_to_search')}</span>
        </div>
      )
    }
  }, [adminSearchResult])

  return (
    <div css={adminMenuStyles}>
      <div css={adminMenuInnerStyles}>
        <SideMenu
          items={[
            {
              label: t('admin.menu.user_info'),
              icon: icons.team,
              isActive: true,
            },
          ]}
        />
        {stickyItemAtBottom}
      </div>
    </div>
  )
})
