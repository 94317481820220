import React from 'react'

import { IPropHeader } from './types'
import { propHeaderStyles } from './styles'

export const PropHeader: React.FC<IPropHeader> = React.memo(
  ({ text, sideButton, className, dataAttr }) => {
    return (
      <div css={propHeaderStyles} className={className} {...dataAttr}>
        {text}
        {sideButton && (
          <div className="side-button" onClick={sideButton.onClick}>
            {sideButton.label}
          </div>
        )}
      </div>
    )
  },
)

PropHeader.displayName = 'PropHeader'
