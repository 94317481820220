import { useCallback, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useDecksV3Api } from 'src/hooks/api/useDecksV3Api'
import { IStoreLoaderProps } from './types'

const StoreLoader = observer<IStoreLoaderProps>(
  ({ store, deckId, shareKey }) => {
    const { getDocument, getPublicDocument } = useDecksV3Api()

    const loadDocument = useCallback(async () => {
      let documentData
      if (shareKey) {
        documentData = await getPublicDocument(shareKey)
      } else if (deckId) {
        documentData = await getDocument(deckId)
      }
      if (documentData) {
        store.loadJSON(documentData.document)
      }
    }, [deckId, getDocument, getPublicDocument, shareKey, store])

    useEffect(() => {
      loadDocument()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
  },
)

export default StoreLoader
