import { useLayoutEffect, useState } from 'react'

export const useRectObserver = <T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
): DOMRect | undefined => {
  const [rect, setRect] = useState<DOMRect | undefined>(undefined)
  useLayoutEffect(() => {
    setRect(ref?.current?.getBoundingClientRect())
    const observer = new ResizeObserver((entries) => {
      setRect(entries[0].contentRect)
    })

    ref.current && observer.observe(ref.current)

    return () => {
      ref.current && observer.unobserve(ref.current)
    }
  }, [ref])

  return rect
}
