import Grid from '@mui/material/Grid2'
import { observer } from 'mobx-react-lite'
import { useLanguage } from 'src/hooks'

import Button from '@mui/material/Button'
import { useCallback, useRef } from 'react'
import {
  IPropAiImageProtocol,
  PropAiImage,
} from 'src/pages/deck-page/components/edit-properties/props'
import sparkleIcon from '../../../../lib/icon/svg/sparkle.svg'
import MediaUploader from '../media-uploader'
import { uploaderConfig } from '../panels/constants/mediaConstants'
import { useStyles } from './styles'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { dropMediaElement } from '../../helpers/dropMediaElement'
import { assetResponseSchema } from 'src/types/api/responseObjects'
import { IMediaItem } from '../image-grid-loader/types'
import { IPolotnoComponent } from '../../types'

const MediaCreateButtons = observer(({ store }: IPolotnoComponent) => {
  const { t } = useLanguage()
  const { classes } = useStyles()

  const aiPromptRef = useRef<IPropAiImageProtocol>(null)

  const { selectedType } = useSelector(({ generalV3 }: RootState) => ({
    selectedType: generalV3.selectedMediaTab,
  }))

  const handleMediaClick = useCallback(
    (media: IMediaItem) => {
      dropMediaElement({ store, image: media })
    },
    [store],
  )

  const onAssetReady = async (asset: assetResponseSchema) => {
    dropMediaElement({ store, image: { url: asset.url } })
  }

  const onAiMediaChange = useCallback(
    (url: string) => {
      if (url) {
        handleMediaClick({
          id: 100,
          url,
          type: 'image',
        })
      }
    },
    [handleMediaClick],
  )

  const showAi = !selectedType || selectedType === 'images'

  return (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid size={showAi ? 6 : 12}>
        <MediaUploader
          config={uploaderConfig}
          onAssetReady={onAssetReady}
          buttonVariant="outlined"
          className={classes.outlinedButton}
        />
      </Grid>
      {showAi && (
        <Grid size={6}>
          <Button
            variant="outlined"
            fullWidth
            disabled
            startIcon={
              <img width={16} height={16} src={sparkleIcon} alt="sparkle" />
            }
            className={classes.outlinedButton}
            onClick={() => aiPromptRef.current?.handleClickAction()}
          >
            {t('edit.properties.ai_image.create')}
          </Button>
          <PropAiImage
            ref={aiPromptRef}
            onChange={onAiMediaChange}
            hideButton
          />
        </Grid>
      )}
    </Grid>
  )
})

export default MediaCreateButtons
