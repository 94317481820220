import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useWindowSize } from 'src/hooks'

import { IContextMenu, IContextMenuStyles } from './types'
import { contextMenuStyles } from './styles'

export const ContextMenu = React.forwardRef<HTMLDivElement, IContextMenu>(
  ({ side = ['right', 'bottom'], pos, children, className, dataAttr }, ref) => {
    const childRef = useRef<HTMLDivElement>(null)
    const windowSize = useWindowSize()
    const [menuStyles, setMenuStyles] = useState<IContextMenuStyles>({})

    const updateMenuPosition = useCallback(() => {
      const menuRect = childRef.current?.getBoundingClientRect()
      if (!menuRect) return

      const menuHeight = menuRect.height
      const menuWidth = menuRect.width
      const menuY = menuRect.y
      const menuX = menuRect.x
      const topAvail = menuY - menuHeight > 0
      const bottomAvail = windowSize.height > menuY + menuHeight
      const leftAvail = menuX - menuWidth > 0
      const rightAvail = windowSize.width > menuX + menuWidth

      const styles: any = {}

      if (side[0] === 'right') {
        styles.left = `${Math.min(
          windowSize.width - (menuX + menuWidth + 10),
          leftAvail ? 0 : -50,
        )}px`
      }

      if (side[0] === 'left') {
        styles.right = `${Math.min(
          windowSize.width - (menuX + menuWidth + 50),
          rightAvail ? 0 : -50,
        )}px`
      }

      if (side[1] === 'top') {
        styles.bottom = `${Math.min(
          windowSize.height - (menuY + menuHeight + 50),
          topAvail ? 0 : -50,
        )}px`
      }

      if (side[1] === 'bottom') {
        styles.top = `${Math.min(
          windowSize.height - (menuY + menuHeight + 50),
          bottomAvail ? 0 : -50,
        )}px`
      }

      setMenuStyles(styles)
    }, [side, windowSize.height, windowSize.width])

    useEffect(() => {
      updateMenuPosition()

      const resizeObserver = new ResizeObserver(updateMenuPosition)
      if (childRef.current) {
        resizeObserver.observe(childRef.current)
      }

      return () => {
        resizeObserver.disconnect()
      }
    }, [updateMenuPosition, windowSize])

    return createPortal(
      <div
        css={contextMenuStyles}
        style={{
          opacity: 1,
          left: `${pos?.x}px`,
          top: `${pos?.y}px`,
          position: 'fixed',
        }}
        ref={ref}
        className={className}
        {...dataAttr}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div style={{ position: 'absolute', ...menuStyles }} ref={childRef}>
          {children}
        </div>
      </div>,
      document.body,
    )
  },
)

ContextMenu.displayName = 'ContextMenu'
