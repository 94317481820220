import React, { useMemo } from 'react'

import { ELEMENT_DEFINITION, IEditElements } from './types'
import { editElementsStyles, headerStyles, scrollAreaStyles } from './styles'
import { BaseElement, ELEMENTS_BLOCK_TYPE, ElementsBlock } from './components'
import { ShapeElements } from './components/shapes/ShapeElements'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { ComponentTypes } from 'src/types/api/enums'
import { Tooltip } from 'src/lib'
import { useLanguage } from 'src/hooks'

export const EditElements: React.FC<IEditElements> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const { activeSlideData, activeSlideDataId } = useSelector(
      ({ edit }: RootState) => ({
        activeSlideDataId: edit.activeSlideDataID,
        activeSlideData: edit.activeSlideData,
      }),
    )

    const hasForm = useMemo(() => {
      return !!activeSlideData.data
        ?.find(({ id }) => id === activeSlideDataId)
        ?.slideDataComponents.find(
          ({ component }) => component.type === ComponentTypes.FORM,
        )
    }, [activeSlideData, activeSlideDataId])

    return (
      <div css={editElementsStyles} className={className} {...dataAttr}>
        <div css={headerStyles}>{t('edit.panel.elements')}</div>
        <div css={scrollAreaStyles}>
          <ElementsBlock
            name={t('common.text')}
            type={ELEMENTS_BLOCK_TYPE.FULL_BOX}
          >
            <BaseElement definition={ELEMENT_DEFINITION.JUMBO} />
            <BaseElement definition={ELEMENT_DEFINITION.TITLE} />
            <BaseElement definition={ELEMENT_DEFINITION.SUBTITLE} />
            <BaseElement definition={ELEMENT_DEFINITION.BODY} />
            <BaseElement definition={ELEMENT_DEFINITION.LABEL} />
          </ElementsBlock>
          <ElementsBlock name={t('common.files.media')} expandable>
            <div className="grid">
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.IMAGE} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.GIF} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.ICON} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.LOGO} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.VIDEO} />
              </div>
            </div>
          </ElementsBlock>
          <ElementsBlock
            name={t('edit.elements.interactive_elements')}
            expandable
          >
            <div className="grid">
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.LINE_CHART} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.TABLE} />
              </div>
              {hasForm ? (
                <Tooltip text={t('edit.elements.tooltip_text')}>
                  <div className="c-1">
                    <BaseElement
                      definition={ELEMENT_DEFINITION.FORM}
                      disabled
                    />
                  </div>
                </Tooltip>
              ) : (
                <div className="c-1">
                  <BaseElement definition={ELEMENT_DEFINITION.FORM} />
                </div>
              )}
              {hasForm ? (
                <Tooltip text={t('edit.elements.tooltip_text')}>
                  <div className="c-1">
                    <BaseElement
                      definition={ELEMENT_DEFINITION.RATING}
                      disabled
                    />
                  </div>
                </Tooltip>
              ) : (
                <div className="c-1">
                  <BaseElement definition={ELEMENT_DEFINITION.RATING} />
                </div>
              )}
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.BUTTON} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.PIE_CHART} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.BAR_CHART} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.DONUT_CHART} />
              </div>
            </div>
          </ElementsBlock>
          <ElementsBlock name={t('edit.elements.content_blocks')} expandable>
            <div className="grid">
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.ICON_LIST} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.IMAGE_LIST} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.BULLET_LIST} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.TIMELINE} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.DEVICE} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.LOGO_LIST} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.QUOTE} />
              </div>
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.IMAGE_GRID} />
              </div>
            </div>
          </ElementsBlock>
          <ElementsBlock name={t('edit.elements.embeds')}>
            <div className="grid">
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.WEBSITE} />
              </div>
              {/* <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.CODE} />
              </div> */}
              <div className="c-1">
                <BaseElement definition={ELEMENT_DEFINITION.LINKS} />
              </div>
            </div>
          </ElementsBlock>
          <ShapeElements />
        </div>
      </div>
    )
  },
)

EditElements.displayName = 'EditElements'
