import React from 'react'
import { IItemText } from './types'
import { useGradientColor } from 'src/hooks'
import { listItemStyles } from './styles'

export const ItemText: React.FC<IItemText> = React.memo(
  ({ text, color, fontFamily, fontSize, fontWeight, topGap }) => {
    const { asText } = useGradientColor(color)

    return (
      <div
        css={listItemStyles}
        style={{
          fontSize,
          fontFamily,
          fontWeight,
          ...asText,
          marginTop: topGap,
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  },
)
