import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  Button,
  INPUT_SIZE,
  INPUT_WIDTH,
  Input,
} from 'src/lib'
import { Separator } from 'src/lib/separator'
import { ISignUpForm } from './types'
import {
  VALIDATION_RULE_TYPES,
  useAuthApi,
  useFlags,
  useLanguage,
} from 'src/hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  actionsStyles,
  formInner,
  gradientTextStyles,
  titleContainerStyles,
} from './styles'
import { LottieButton } from '../lottie-button'
import { Captcha } from '../captcha'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { APP_CONFIG } from 'src/config'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { PROVIDER } from 'src/plugins/google/consts'

export const SignUpForm: React.FC<ISignUpForm> = React.memo(
  ({ onGoogleAuth, className, dataAttr }) => {
    const navigate = useNavigate()
    const { t, Trans } = useLanguage()
    const { signUp, isLoading } = useAuthApi()
    const flags = useFlags()
    const posthogCapture = usePostHogCapture()

    const [fullName, setFullName] = useState('')
    const [fullNameValid, setFullNameValid] = useState(false)
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const [isFormValid, setIsFormValid] = useState(false)
    const [shouldResetCaptcha, setShouldResetCaptcha] = useState(false)
    const [isCaptchaLoading, setIsCaptchaLoading] = useState(false)

    const [searchParams] = useSearchParams()
    const captchaRef = useRef<HCaptcha>(null)

    const onSubmitHandler = useCallback(async () => {
      if (flags.FE_635_CAPTCHA) {
        setIsCaptchaLoading(true)
        captchaRef.current?.execute()
      } else {
        signUpActionHandler({ fullName, email, password })
      }
    }, [fullName, email, password, flags.FE_635_CAPTCHA, captchaRef])

    const onLoginClickHandler = useCallback(() => {
      navigate('/login', { replace: true })
    }, [])

    const openTerms = useCallback(() => {
      window.open('https://www.decktopus.com/terms', '_blank')
    }, [])

    const openPrivacy = useCallback(() => {
      window.open('https://www.decktopus.com/privacy', '_blank')
    }, [])

    const onVerificationSuccessHandler = useCallback(
      async ({
        fullName,
        email,
        token,
        password,
      }: {
        fullName?: string
        email?: string
        token: string
        password?: string
      }) => {
        setIsCaptchaLoading(false)
        signUpActionHandler({
          fullName: fullName!,
          email: email!,
          token,
          password: password!,
        })
      },
      [],
    )

    const signUpActionHandler = useCallback(
      async ({
        fullName,
        email,
        token,
        password,
      }: {
        fullName: string
        email: string
        token?: string
        password?: string
      }) => {
        try {
          const isSuccess = await signUp({
            fullName,
            email,
            password: password!,
            referralCode: searchParams.get('referralCode') || undefined,
            ...(token && { captchaToken: token }),
          })
          if (isSuccess) {
            posthogCapture(POSTHOG_EVENTS.SIGNUP, {
              signup_source: document.referrer,
              signup_method: PROVIDER.GOOGLE,
            })
          }
        } finally {
          setShouldResetCaptcha(true)
        }
      },
      [],
    )

    const onCaptchaResetHandler = useCallback(() => {
      setShouldResetCaptcha(false)
    }, [])

    useEffect(() => {
      setIsFormValid(fullNameValid && emailValid && passwordValid)
    }, [fullNameValid, emailValid, passwordValid])

    return (
      <div css={formInner} className={className} {...dataAttr}>
        <div css={titleContainerStyles}>
          <div css={gradientTextStyles}>{t('signup_page_title')}</div>
        </div>
        <Button
          text={t('login.continue_with_google')}
          theme={BUTTON_THEME.GOOGLE}
          width={BUTTON_WIDTH.FULL}
          onClick={onGoogleAuth}
        />
        <Separator text={t('common.or')} />
        <Input
          size={INPUT_SIZE.SMALL}
          width={INPUT_WIDTH.FULL}
          label={t('signup.full_name')}
          value={fullName}
          onChange={setFullName}
          onEnter={onSubmitHandler}
          validation={{
            [VALIDATION_RULE_TYPES.REQUIRED]: {
              text: t('validation.error.required', {
                name: t('common.full_name'),
              }),
            },
          }}
          onValidation={setFullNameValid}
          errorAlignRight
        />
        <Input
          size={INPUT_SIZE.SMALL}
          width={INPUT_WIDTH.FULL}
          label={t('common.email_address')}
          onChange={setEmail}
          value={email}
          onEnter={onSubmitHandler}
          validation={{
            [VALIDATION_RULE_TYPES.REQUIRED]: {
              text: t('validation.error.required', {
                name: t('common.email'),
              }),
            },
            [VALIDATION_RULE_TYPES.VALIDMAIL]: {
              text: t('validation.error.email'),
            },
          }}
          onValidation={setEmailValid}
          errorAlignRight
        />
        <Input
          size={INPUT_SIZE.SMALL}
          width={INPUT_WIDTH.FULL}
          label={t('common.password')}
          type="password"
          onChange={setPassword}
          value={password}
          onEnter={onSubmitHandler}
          validation={{
            [VALIDATION_RULE_TYPES.REQUIRED]: {
              text: t('validation.error.required', {
                name: t('common.password'),
              }),
            },
            [VALIDATION_RULE_TYPES.MIN]: {
              value: APP_CONFIG.passwordMinLength,
              text: t('validation.min_char', {
                name: t('common.password'),
                value: APP_CONFIG.passwordMinLength,
              }),
            },
            [VALIDATION_RULE_TYPES.MAX]: {
              value: APP_CONFIG.passwordMaxLength,
              text: t('validation.max_char', {
                name: t('common.password'),
                value: APP_CONFIG.passwordMaxLength,
              }),
            },
          }}
          onValidation={setPasswordValid}
          errorAlignRight
        />

        {flags.FE_635_CAPTCHA && (
          <Captcha
            key="hCaptcha-signup"
            ref={captchaRef}
            fullName={fullName}
            email={email}
            password={password}
            onCaptchaReset={onCaptchaResetHandler}
            shouldResetCaptcha={shouldResetCaptcha}
            onVerificationSuccess={onVerificationSuccessHandler}
          />
        )}

        <div css={actionsStyles}>
          <div className="info-text">
            <Trans
              i18nKey={'signup.create_account_info'}
              components={[
                <Button
                  key="terms_link"
                  isLink
                  size={BUTTON_SIZE.SMALL}
                  text={t('signup.terms')}
                  theme={BUTTON_THEME.PRIMARY}
                  type={BUTTON_TYPE.GHOST}
                  onClick={openTerms}
                />,
                <Button
                  key="privacy_link"
                  isLink
                  size={BUTTON_SIZE.SMALL}
                  text={t('signup.privacy_policy')}
                  theme={BUTTON_THEME.PRIMARY}
                  type={BUTTON_TYPE.GHOST}
                  onClick={openPrivacy}
                />,
              ]}
            />
          </div>

          {isFormValid ? (
            <LottieButton
              text={t('signup.create_account')}
              isLoading={isLoading || isCaptchaLoading}
              onClick={onSubmitHandler}
            />
          ) : (
            <Button
              size={BUTTON_SIZE.SMALL}
              text={t('signup.create_account')}
              theme={BUTTON_THEME.GRADIENT}
              width={BUTTON_WIDTH.FULL}
              onClick={onSubmitHandler}
              isLoading={isLoading || isCaptchaLoading}
              disabled={!isFormValid}
            />
          )}
          <div>
            {t('signup.already_member')}
            <Button
              isLink
              size={BUTTON_SIZE.LARGE}
              text={t('login.login')}
              theme={BUTTON_THEME.PRIMARY}
              type={BUTTON_TYPE.GHOST}
              onClick={onLoginClickHandler}
            />
          </div>
        </div>
      </div>
    )
  },
)
