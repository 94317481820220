import Popover from '@mui/material/Popover'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import ToggleButton from 'src/components/toggle-button'
import { useLanguage } from 'src/hooks'
import { IPolotnoComponent } from '../../types'
import SliderWithInput from './SliderWithInput'
import { useTransparencyStyles } from './styles'

const TransparencyAction = observer<IPolotnoComponent>(({ store }) => {
  const hasLockedItems = store.selectedElements?.some(
    (element) => element.locked,
  )

  const lowestOpaqueElement = store.selectedElements?.sort(
    (elem1, elem2) => elem1.opacity - elem2.opacity,
  )?.[0]
  const opacity = Math.round((lowestOpaqueElement?.opacity ?? 1) * 100)

  const { classes: transparencyClasses } = useTransparencyStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const { t } = useLanguage()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'transparency-popover' : undefined

  const handleChange = (newValue: number) => {
    const value = Math.floor(newValue)
    store.selectedElements?.forEach((element) => {
      element.set({
        opacity: value / 100,
      })
    })
  }

  return !hasLockedItems ? (
    <>
      <ToggleButton
        selected={open}
        value={open}
        onClick={handleClick}
        tooltip="v3.transparency"
      >
        <MaterialSymbol icon="opacity" size={20} weight={300} />
      </ToggleButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 48,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={32}
        elevation={6}
      >
        <SliderWithInput
          className={transparencyClasses.container}
          min={0}
          max={100}
          label={t('v3.transparency')}
          value={opacity}
          onValueChange={handleChange}
        />
      </Popover>
    </>
  ) : null
})
export default TransparencyAction
