import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { EditSubmenuTypes, IEditPropSection } from '../../types'
import {
  PropBoxButton,
  PropHeader,
} from 'src/pages/deck-page/components/edit-properties/props'
import { INPUT_THEME, Input, icons } from 'src/lib'
import {
  ComponentFormDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { FormQuestion } from './FormQuestion'
import { formPropsStyles } from './styles'
import { useDispatch } from 'react-redux'
import {
  FormQuestionOptionsTypes,
  FormQuestionTypes,
} from 'src/types/api/enums'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { VALIDATION_RULE_TYPES, useDebouncer, useLanguage } from 'src/hooks'

export const FormProps: React.FC<IEditPropSection> = React.memo(
  ({ components, showSubMenu }) => {
    const dispatch = useDispatch()
    const { t } = useLanguage()

    const selectedQuestions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['questions'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const target = (c.data as ComponentFormDataSchema).questions
            if (a === undefined && target) {
              return target
            }

            return target !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedOptions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['options'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const target = (c.data as ComponentFormDataSchema).options
            if (a === undefined && target) {
              return target
            }

            return target !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const addNewQuestion = useCallback(
      (
        questionType: FormQuestionTypes,
        optionType?: FormQuestionOptionsTypes,
      ) => {
        const newQuestionData: ComponentFormDataSchema['questions'][0] = {
          isRequired: false,
          questionId: uuidv4(),
          questionText: `${t('edit.properties.form_props.question')} ${
            (selectedQuestions?.length || 0) + 1
          }`,
          questionType,
          ...(questionType === FormQuestionTypes.RATE
            ? {
                rate: {
                  icon: 'like',
                  range: 10,
                  description: t('edit.properties.form_props.description'),
                },
              }
            : {}),
          ...(questionType === FormQuestionTypes.OPTIONS && optionType
            ? {
                options: {
                  type: optionType,
                  choices: [
                    t('edit.properties.form_props.choice_1'),
                    t('edit.properties.form_props.choice_2'),
                    t('edit.properties.form_props.choice_3'),
                  ],
                },
              }
            : {}),
        }

        const partialData: DeepPartial<ComponentFormDataSchema> = {
          questions: [...(selectedQuestions || []), newQuestionData],
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedQuestions],
    )

    const [validUrl, setValidUrl] = useState(false)
    const [value, setValue] = useState(
      selectedOptions?.defaultTexts.submitMessage,
    )
    const handleOnChange = useCallback(
      (value?: string) => {
        setValue(value)
      },
      [components],
    )

    const debounced = useDebouncer(
      () => {
        if (validUrl) {
          updateComponentState(value)
        }
      },
      { delay: 500 },
    )

    useEffect(() => {
      debounced()
    }, [value, validUrl])

    const updateComponentState = useCallback(
      (val?: string) => {
        const partialData: DeepPartial<ComponentFormDataSchema> = {
          options: {
            defaultTexts: {
              submitMessage: val,
            },
          },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, validUrl],
    )

    return (
      <div css={formPropsStyles}>
        <div>
          <PropHeader
            text={t('edit.properties.form.header')}
            sideButton={{
              label: t('edit.properties.form.form_properties'),
              onClick: () =>
                showSubMenu?.({ type: EditSubmenuTypes.FORM_PROPS }),
            }}
          />
          <div className="grid">
            <div className="c-1">
              <PropBoxButton
                name={t('edit.properties.form.free_text')}
                icon={icons.text}
                hoverIcon={icons.add}
                onClick={() => addNewQuestion(FormQuestionTypes.TEXT)}
              />
            </div>
            <div className="c-1">
              <PropBoxButton
                name={t('edit.properties.form.option_list')}
                icon={icons.list}
                hoverIcon={icons.add}
                onClick={() =>
                  addNewQuestion(
                    FormQuestionTypes.OPTIONS,
                    FormQuestionOptionsTypes.SELECT,
                  )
                }
              />
            </div>
            <div className="c-1">
              <PropBoxButton
                name={t('common.rate')}
                icon={icons.star}
                hoverIcon={icons.add}
                onClick={() => addNewQuestion(FormQuestionTypes.RATE)}
              />
            </div>
            <div className="c-1">
              <PropBoxButton
                name={t('edit.properties.form.check_list')}
                icon={icons.checked_checkbox}
                hoverIcon={icons.add}
                onClick={() =>
                  addNewQuestion(
                    FormQuestionTypes.OPTIONS,
                    FormQuestionOptionsTypes.CHECKBOX,
                  )
                }
              />
            </div>
          </div>
        </div>
        {selectedQuestions?.map(
          (q) =>
            selectedOptions && (
              <FormQuestion
                key={q.questionId}
                question={q}
                options={selectedOptions}
                components={components}
              />
            ),
        )}
        <div className="c-full">
          <PropHeader text={t('edit.properties.form.header.button_text')} />
          <Input
            theme={INPUT_THEME.DARK}
            value={value}
            onChange={handleOnChange}
            onFocus={() => dispatch(setAllowDeleteShortcut(false))}
            onBlur={() => dispatch(setAllowDeleteShortcut(true))}
            constantValidation
            validation={{
              [VALIDATION_RULE_TYPES.REQUIRED]: {
                text: t('validation.error.required', {
                  name: t('edit.properties.form.button_text'),
                }),
              },
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  value: 3,
                  name: t('edit.properties.form.button_text'),
                }),
              },
            }}
            onValidation={setValidUrl}
          />
        </div>
      </div>
    )
  },
)

FormProps.displayName = 'FormProps'
