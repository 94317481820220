import { useState } from 'react'
import { API_CONFIG } from 'src/config'
import { useSelector } from 'react-redux'
import { RequestResponse, RequestServices } from 'src/services'
import { RootState } from 'src/store'
import { getOrganizationWebhooksResponse } from 'src/types/api/responseObjects'
import { WebhookTypes } from 'src/types/api/enums'
interface IUseWebhookApiReturn {
  getWebhooks: () => Promise<
    getOrganizationWebhooksResponse['data']['webhooks']
  >
  deleteWebhook: (webhookID: number) => Promise<boolean>
  createWebhook: (params: { name: string; url: string }) => Promise<number>
  updateWebhook: (params: {
    name: string
    url: string
    webhookID: number
  }) => Promise<boolean>
  isLoading: boolean
}

export const useWebhookApi = (): IUseWebhookApiReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { workspaceId } = useSelector(({ workspace }: RootState) => ({
    workspaceId: workspace.id,
  }))

  const getWebhooks = async () => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getOrganizationWebhooksResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: `${API_CONFIG.ORGANIZATION}/${workspaceId}/webhooks`,
        })
      return res.data.data.webhooks
      //TODO: Switch between webhooks
    } finally {
      setIsLoading(false)
    }
  }

  const updateWebhook = async ({
    name,
    url,
    webhookID,
  }: {
    name: string
    url: string
    webhookID: number
  }): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: `${API_CONFIG.ORGANIZATION}/${workspaceId}/webhooks/${webhookID}`,
        data: {
          name,
          url,
          type: WebhookTypes.DECK_RESPONSE,
        },
      })
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const createWebhook = async ({
    name,
    url,
  }: {
    name: string
    url: string
  }): Promise<number> => {
    setIsLoading(true)
    try {
      const res = await RequestServices.callApi({
        method: 'POST',
        url: `${API_CONFIG.ORGANIZATION}/${workspaceId}/webhook`,
        data: {
          name,
          url,
          type: WebhookTypes.DECK_RESPONSE,
        },
      })
      return res.data.data.webhookId
    } finally {
      setIsLoading(false)
    }
  }

  const deleteWebhook = async (webhookID: number): Promise<boolean> => {
    setIsLoading(true)
    try {
      await RequestServices.callApi({
        method: 'DELETE',
        url: `${API_CONFIG.ORGANIZATION}/${workspaceId}/webhooks/${webhookID}`,
      })
      getWebhooks()
      return true
    } catch {
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return {
    getWebhooks,
    createWebhook,
    deleteWebhook,
    updateWebhook,
    isLoading,
  }
}
