import { css } from '@emotion/react'
import { ICopyArea } from './types'
import { ITheme } from 'src/theme'

type IWrapperStyles = Pick<ICopyArea, 'width' | 'height'>

export const wrapperStyles = ({ width, height }: IWrapperStyles) => css`
  width: ${width};
  height: ${height};
  font-size: 10px;
  pointer-events: none;
  resize: none;
  border: none;
  line-height: 155%;
  overflow: hidden;
`
export const frameStyles = (theme: ITheme) => css`
  width: 100%;
  height: 197px;
  padding: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.colors.outline[2]};
  border-radius: ${theme.borderRadius[4]};
  background-color: ${theme.colors.white.DEFAULT};
`
export const iconStyles = css`
  width: 100%;
  height: 55px;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  fill: #9aa0ab;
`
export const copyTextStyles = (theme: ITheme) => css`
  color: ${theme.colors.primary.DEFAULT};
`
