import React from 'react'
import { overlayStyles } from './styles'
const RefreshOverlay: React.FC = () => {
  return (
    <div css={overlayStyles}>
      <div>Are you still there?</div>
    </div>
  )
}
export default RefreshOverlay
