import { useCallback, useEffect } from 'react'
import { VALIDATION_RULE_TYPES, useValidation } from './useValidation'
import { useLanguage } from './useLanguage'

type CallbackFunction = () => void

export const useValidatedText = (
  callback: CallbackFunction,
  text: string,
  value: number,
) => {
  const { t } = useLanguage()
  const [isValid] = useValidation({
    value: text,
    rules: {
      [VALIDATION_RULE_TYPES.MIN]: {
        value: value,
        text: t('validation.min_char', {
          value: value,
          name: text,
        }),
      },
    },
  })
  const keyUpHandler = useCallback(
    (event: KeyboardEvent) => {
      if (isValid && event.key == 'Enter') {
        callback()
      }
    },
    [text],
  )
  useEffect(() => {
    document.addEventListener('keyup', keyUpHandler)
    return () => {
      document.removeEventListener('keyup', keyUpHandler)
    }
  }, [keyUpHandler])
}

export default useValidatedText
