import HCaptcha from '@hcaptcha/react-hcaptcha'
import React, { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Captcha } from 'src/components/captcha'
import { useAuthApi, useFlags, useLanguage, useNotification } from 'src/hooks'
import { BUTTON_TYPE, Button } from 'src/lib'
import { RootState } from 'src/store'

export const ResetPassword: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const { success } = useNotification()
  const { sendResetEmail, isLoading } = useAuthApi()
  const flags = useFlags()

  const captchaRef = useRef<HCaptcha>(null)

  const { email } = useSelector(({ user }: RootState) => ({
    email: user.data?.email,
  }))

  const handlePasswordReset = useCallback(() => {
    if (flags.FE_635_CAPTCHA) {
      setIsCaptchaLoading(true)
      captchaRef.current?.execute()
    } else {
      onVerificationSuccessHandler({})
    }
  }, [flags.FE_635_CAPTCHA, captchaRef])

  const [isCaptchaLoading, setIsCaptchaLoading] = useState(false)
  const [shouldResetCaptcha, setShouldResetCaptcha] = useState(false)
  const onCaptchaResetHandler = useCallback(() => {
    setShouldResetCaptcha(false)
  }, [])

  const onVerificationSuccessHandler = useCallback(
    async ({ token }: { token?: string }) => {
      setIsCaptchaLoading(false)
      if (email)
        sendResetEmail({ email, captchaToken: token }).then(() => {
          success('reset.password_reset_sent')
        })
    },
    [email],
  )

  return (
    <>
      <div>{t('reset.change_your_password_info')}</div>
      <Button
        text={t('reset.reset_password')}
        type={BUTTON_TYPE.REVERSE}
        onClick={handlePasswordReset}
        isLoading={isLoading || isCaptchaLoading}
      />
      {flags.FE_635_CAPTCHA && (
        <Captcha
          key="hCaptcha-account-reset-password"
          ref={captchaRef}
          resetEmail={email}
          onCaptchaReset={onCaptchaResetHandler}
          shouldResetCaptcha={shouldResetCaptcha}
          onVerificationSuccess={onVerificationSuccessHandler}
        />
      )}
    </>
  )
})
