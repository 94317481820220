import { css } from '@emotion/react'

export const carouselStyles = css`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .dots {
    position: absolute;
    bottom: 0.5em;
    display: flex;
    gap: 1em;
    left: 50%;
    transform: translateX(-50%);

    div {
      border: 0.06em solid var(--formIcon);
      width: 0.6em;
      height: 0.6em;
      cursor: pointer;
      transition: background 0.3s;
      border-radius: 50%;

      &.active {
        background: var(--formIcon);
      }
    }
  }
`

export const carouselInnerStyles = css`
  position: absolute;
  display: flex;
  height: calc(100% - 2em);
  transition: left 0.3s;
`
