import React from 'react'

import { ISpinner } from './types'
import { wrapperStyles } from './styles'

export const Spinner: React.FC<ISpinner> = React.memo(
  ({ size = 14, speed = 2000, className, dataAttr }) => {
    const renderHalf = (halfClassName: string) => (
      <div className={`half ${halfClassName}`}>
        <div className="square">
          <div className="mask" />
        </div>
      </div>
    )

    return (
      <div
        css={wrapperStyles({ size, speed })}
        className={className}
        {...dataAttr}
      >
        {renderHalf('top')}
        {renderHalf('bottom')}
      </div>
    )
  },
)
