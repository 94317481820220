import React from 'react'
import { useLanguage } from 'src/hooks'
import { hCaptchaInfoStyles } from './styles'

export const CaptchaInformation: React.FC = React.memo(() => {
  const { t, Trans } = useLanguage()

  return (
    <div css={hCaptchaInfoStyles}>
      <Trans
        i18nKey={'auth.site_protection'}
        components={[
          <a
            key="privacy_link"
            href="https://www.hcaptcha.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            {t('signup.privacy_policy')}
          </a>,
          <a
            key="terms_link"
            href="https://www.hcaptcha.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            {t('auth.terms_of_service')}
          </a>,
        ]}
      />
    </div>
  )
})
