import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { useLanguage } from 'src/hooks'
import { RootState, setFiltering } from 'src/store'
import { setDecksQuery } from 'src/store'
import { Dropdown, IDropdownItem } from 'src/lib'
import { wrapperStyles } from './styles'
import { ViewModes } from 'src/types/api/enums'

export const DashboardFilter: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { decksQuery, workspaceId, workspaceFiltering } = useSelector(
    ({ decks, workspace }: RootState) => ({
      decksQuery: decks.decksQuery,
      workspaceId: workspace.id,
      workspaceFiltering: workspace.filtering,
    }),
  )

  useEffect(() => {
    dispatch(
      setDecksQuery({
        viewMode:
          workspaceFiltering === 'personal'
            ? ViewModes.PRIVATE
            : ViewModes.ORGANIZATION,
      }),
    )
  }, [])

  const onChange = (value?: IDropdownItem) => {
    switch (value!.value) {
      case 'personal':
        dispatch(setFiltering('personal'))
        searchParams.delete('folderId')
        setSearchParams(searchParams)
        dispatch(
          setDecksQuery({
            ...decksQuery,
            organizationId: workspaceId,
            viewMode: ViewModes.PRIVATE,
            folderId: undefined,
            sharedWithMe: false,
          }),
        )
        break
      case 'organization':
        dispatch(setFiltering('organization'))
        searchParams.delete('folderId')
        setSearchParams(searchParams)
        dispatch(
          setDecksQuery({
            ...decksQuery,
            organizationId: workspaceId,
            viewMode: ViewModes.ORGANIZATION,
            folderId: undefined,
            sharedWithMe: false,
          }),
        )
        break
      default:
        break
    }
  }

  return (
    <div css={wrapperStyles}>
      {workspaceId && (
        <Dropdown
          items={[
            { name: t('common.personal'), value: 'personal' },
            {
              name: t('common.organization'),
              value: 'organization',
            },
          ]}
          placeholder={t('common.personal')}
          onChange={onChange}
          selected={workspaceFiltering}
        />
      )}
    </div>
  )
})
