import {
  ComponentTypes,
  ListLayoutDirections,
  ListTypes,
  Sides,
} from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentListDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const bulletListDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.bullet_list.display_name', { lng }),
      styles: {},
      icon: icons.list,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.LIST,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 800,
          height: 950,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          isGrid: false,
          style: {
            layout: {
              auto: true,
              division: 3,
              direction: ListLayoutDirections.VERTICAL,
              mediaPlace: Sides.LEFT,
            },
            color: { text: { colors: ['var(--listTitle)'] } },
            colorBody: { text: { colors: ['var(--listBody)'] } },
            colorIcon: { text: { colors: ['var(--listTitle)'] } },
            colorBullet: { text: { colors: ['var(--listBullet)'] } },
            font: {
              family: 'primary',
              size: '12em',
            },
            fontBody: {
              family: 'secondary',
              size: '8em',
            },
          },
          listType: ListTypes.BULLET,
          listItems: [
            {
              id: '58df05ea-e94f-46a3-a917-7a7bfa63d771',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.bullet_list.item_title1', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.bullet_list.item_body1', {
                lng,
              })}</p>`,
            },
            {
              id: '58df05ea-e94f-46a3-a917-7a7bfa63d772',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.bullet_list.item_title2', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.bullet_list.item_body2', {
                lng,
              })}</p>`,
            },
            {
              id: '58df05ea-e94f-46a3-a917-7a7bfa63d773',
              parentId: null,
              title: `<p>${i18n.t('edit.definitions.bullet_list.item_title3', {
                lng,
              })}</p>`,
              body: `<p>${i18n.t('edit.definitions.bullet_list.item_body3', {
                lng,
              })}</p>`,
            },
          ],
        } as ComponentListDataSchema,
      },
    ],
  }
}
