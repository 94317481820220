/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import {
  useConfigApi,
  useDecksApi,
  useLayoutsApi,
  useNotification,
  useUnSelect,
  useWindowSize,
  useElementDefinitions,
  useSelectedComponents,
} from 'src/hooks'
import {
  addClickCreateList,
  clearEditSoft,
  componentCreate,
  componentsDelete,
  historyRedo,
  historyUndo,
  removeClickCreateList,
  RootState,
  SAVE_STATE,
  setSaveState,
  setSelectedComponents,
} from 'src/store'
import {
  CreateSlideModal,
  EditComponentFixer,
  EditPanel,
  EditSideMenu,
  EditSlides,
  ELEMENT_DEFINITION,
  NewDeckPage,
} from './components'
import { editPageStyles, selectionRectangleStyles } from './styles'
import { Expander, EXPANDER_DIR, EXPANDER_THEME } from 'src/lib'
import { Canvas, CANVAS_DATA_TYPE, CANVAS_TYPE } from 'src/components/canvas'
import { APP_CONFIG } from 'src/config'
import { QuillToolbar } from './components/quill-toolbar'
import { useTheme } from 'src/theme'
import { ComponentServices } from 'src/services'
import { NewComponentSchema } from 'src/types/api/requestObjects'
import { ComponentSchema } from 'src/types/api/responseObjects'
import { DeckStates } from 'src/types/api/enums'
import NotSupportedPage from 'src/pages/not-supported-page/NotSupportedPage'
import { FontImporter } from './components/font-importer'
import { SelectionLayer } from './components/selection-layer'

export const EditPage: React.FC = React.memo(() => {
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const { breakpointsV2: breakpoints } = useTheme()
  const { saveChanges } = useDecksApi()
  const { getLayouts } = useLayoutsApi()
  const { uploadImage } = useConfigApi()
  const { info, success, error } = useNotification()
  const { getSingleDeck } = useDecksApi()
  const timeRef = useRef<NodeJS.Timeout>()
  const editAreaRef = useRef(null)
  const selectionRectangleRef = useRef(null)
  const { definitions } = useElementDefinitions()

  useUnSelect('data-unselect-exception', editAreaRef, selectionRectangleRef)

  const isSmallScreen = useMemo(() => width <= 1024, [width])

  const sideMenuWidth = useMemo(
    () => (width >= breakpoints.desktop ? '388px' : '300px'),
    [width],
  )

  const {
    activeSlides,
    activeSlideId,
    saveState,
    toBeSaved,
    instantSave,
    allowDeleteShortcut,
    selectedComponents,
    clickCreateCount,
    aiCreatedDeckId,
  } = useSelector(({ edit, canvas, aiFlow }: RootState) => ({
    activeSlides: edit.activeDeck.data?.deckData?.data.slides,
    activeSlideId: edit.activeSlideID,
    saveState: canvas.saveState,
    toBeSaved: edit.toBeSaved.data,
    instantSave: edit.instantSave.data,
    selectedComponents: canvas.selectedComponents,
    allowDeleteShortcut: canvas.allowDeleteShortcut,
    clickCreateCount: canvas.clickCreateList.length,
    aiCreatedDeckId: aiFlow.aiCreatedDeckId,
  }))

  const { getComponents } = useSelectedComponents()

  const hasSlides = useMemo(
    () => !!activeSlides?.filter(({ isDeleted }) => !isDeleted).length,
    [activeSlides],
  )
  const [isMenuExpanded, setMenuExpanded] = useState(hasSlides)
  const [isSlidesExpanded, setSlidesExpanded] = useState(true)

  const isBlocked = useMemo(
    () => !allowDeleteShortcut || document.activeElement?.tagName === 'INPUT',
    [allowDeleteShortcut, document.activeElement],
  )

  useEffect(() => {
    if (aiCreatedDeckId) {
      timeRef.current = setInterval(async () => {
        const deckState = await getSingleDeck({ deckId: aiCreatedDeckId })
        if (deckState === DeckStates.FINAL) {
          clearInterval(timeRef.current)
          success('common.informative.your_deck_is_ready')
        } else if (deckState === DeckStates.ERRORED) {
          clearInterval(timeRef.current)
          error('common.informative.ai_high_demand_text')
        }
      }, APP_CONFIG.aiDeckInterval)
    }

    return () => clearInterval(timeRef.current)
  }, [aiCreatedDeckId])

  useEffect(() => {
    setMenuExpanded(hasSlides)
    if (!hasSlides) {
      dispatch(clearEditSoft())
    }
  }, [hasSlides])

  // periodic save trigger
  useEffect(() => {
    const saveInterval = setInterval(() => {
      saveChanges()
    }, APP_CONFIG.editSaveTime)

    return () => clearInterval(saveInterval)
  }, [saveState, toBeSaved, instantSave])

  // instants save trigger
  useEffect(() => {
    if (instantSave.length) {
      saveChanges(true)
    }
  }, [instantSave])

  const zeroWidthEncode = (input: string) => {
    return input
      .split('')
      .map((char) => {
        const code = char.charCodeAt(0).toString(2) // Get binary representation of char code
        return code
          .split('')
          .map((bit) => (bit === '1' ? '\u200B' : '\u200C')) // Map 1 -> Zero-Width Space, 0 -> Zero-Width Non-Joiner
          .join('')
      })
      .join('\u200D') // Use Zero-Width Joiner as a separator between encoded characters
  }

  const zeroWidthDecode = (input: string) => {
    return input
      .split('\u200D') // Split by the Zero-Width Joiner
      .map((binary) => {
        const code = binary
          .split('')
          .map((bit) => (bit === '\u200B' ? '1' : '0')) // Map Zero-Width Space -> 1, Zero-Width Non-Joiner -> 0
          .join('')
        return String.fromCharCode(parseInt(code, 2))
      })
      .join('')
  }

  const writeToClipboard = useCallback(
    async (event: KeyboardEvent) => {
      const selectedComponents = getComponents()
      if (!isBlocked && selectedComponents.components?.length) {
        event.preventDefault()
        const type = 'text/plain'

        const transformedData = zeroWidthEncode(
          JSON.stringify(selectedComponents),
        )

        const blob = new Blob([transformedData], { type })
        const data = [new ClipboardItem({ [type]: blob })]
        await navigator.clipboard.write(data)
      }
    },
    [getComponents, isBlocked],
  )

  const pasteFromClipboard = useCallback(
    async (event: KeyboardEvent) => {
      try {
        const clipboardItems = await navigator.clipboard.read()
        for (const clipboardItem of clipboardItems) {
          if (clipboardItem.types.includes('text/plain')) {
            const blob = await clipboardItem.getType('text/plain')
            const data = await blob.text()

            const originalData = zeroWidthDecode(data)

            if (originalData.includes('components')) {
              event.preventDefault()

              const pasteComponentType = JSON.parse(originalData)

              if (pasteComponentType?.components) {
                const pasteComponentData: NewComponentSchema[] =
                  pasteComponentType.components as NewComponentSchema[]

                const newComponents = pasteComponentData.map(
                  ({ type, positions, style, data }) => ({
                    tempId: uuidv4(),
                    type,
                    positions:
                      activeSlideId === pasteComponentType.slideId
                        ? {
                            ...positions,
                            x: positions.x + 50,
                            y: positions.y + 50,
                          }
                        : positions,
                    style,
                    data,
                  }),
                )

                dispatch(componentCreate(newComponents))
              }
            }
          }
        }
      } catch (error) {
        console.error('Failed to read from clipboard: ', error)
      }
    },
    [activeSlideId],
  )

  // shortcuts
  useEffect(() => {
    const shortCuts = (event: KeyboardEvent) => {
      if (
        (event.metaKey || event.ctrlKey) &&
        !event.shiftKey &&
        event.key === 'z'
      ) {
        dispatch(historyUndo())
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }
      // redo functionality, incase it's needed in the future
      if (
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey &&
        event.key === 'z'
      ) {
        dispatch(historyRedo())
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }

      if (event.key === 'Backspace' && !isBlocked) {
        dispatch(
          removeClickCreateList(
            selectedComponents.map(({ tempId }) => tempId || ''),
          ),
        )
        dispatch(componentsDelete(selectedComponents))
        dispatch(setSelectedComponents())
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }

      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault()
        saveChanges()
      }

      if (
        (event.metaKey || event.ctrlKey) &&
        !event.shiftKey &&
        event.key === 'c'
      ) {
        writeToClipboard(event)
      }

      if (
        (event.metaKey || event.ctrlKey) &&
        !event.shiftKey &&
        event.key === 'v'
      ) {
        pasteFromClipboard(event)
      }
    }

    addEventListener('keydown', shortCuts, true)
    return () => {
      removeEventListener('keydown', shortCuts, true)
    }
  }, [saveChanges, allowDeleteShortcut, selectedComponents, isBlocked])

  useEffect(() => {
    const clipboardListener = (event: any) => {
      console.log(event)
    }

    navigator.clipboard.addEventListener('clipboardchange', clipboardListener)

    return () => {
      navigator.clipboard.removeEventListener(
        'clipboardchange',
        clipboardListener,
      )
    }
  }, [])

  // beforeunload saving
  useEffect(() => {
    const uListener = (e: any) => {
      saveChanges()
      if (saveState !== SAVE_STATE.SAVED) {
        e.preventDefault()
        e.stopPropagation()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', uListener)
    return () => {
      window.removeEventListener('beforeunload', uListener)
    }
  }, [saveState])

  const createNewImageFromPaste = useCallback((url: string) => {
    const newUuid = uuidv4()

    const createdComponent =
      ComponentServices.updateComponent<NewComponentSchema>({
        components: definitions[ELEMENT_DEFINITION.IMAGE].schemas,
        partialUpdate: {
          data: { mediaUrl: url },
          positions: {
            x: (clickCreateCount + 1) * 50,
            y: (clickCreateCount + 1) * 50,
          },
          tempId: newUuid,
        },
      })
    success('edit.canvas.paste.image_pasted')

    dispatch(addClickCreateList(newUuid))
    dispatch(componentCreate(createdComponent as ComponentSchema[]))
    dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
  }, [])

  const handleImagePaste = useCallback(async (e: ClipboardEvent) => {
    if (!e.clipboardData) {
      return
    }
    const clipboardItems = e.clipboardData.items
    const items = [].slice.call(clipboardItems).filter((item: any) => {
      // Filter the image items only
      return item.type.indexOf('image') !== -1
    })
    if (items.length === 0) {
      return
    }

    info('edit.canvas.paste.processing_image')

    const item: any = items[0]
    // Get the blob of image
    const blob = await item.getAsFile()
    const accessUrl = await uploadImage({ file: blob })
    createNewImageFromPaste(accessUrl)
  }, [])

  useEffect(() => {
    document.addEventListener('paste', handleImagePaste)

    return () => {
      document.removeEventListener('paste', handleImagePaste)
    }
  }, [])

  useEffect(() => {
    getLayouts()
  }, [])

  useEffect(() => {
    const listener = () => {
      setMenuExpanded(true)
    }
    document.addEventListener('element-double-click', listener)
    return () => {
      document.removeEventListener('element-double-click', listener)
    }
  }, [])

  if (isSmallScreen) {
    return <NotSupportedPage />
  }

  return (
    <>
      <EditComponentFixer />
      <div ref={selectionRectangleRef} css={selectionRectangleStyles}></div>
      <div css={editPageStyles} id="quill-toolbar">
        {hasSlides && (
          <>
            <EditPanel onItemClick={() => setMenuExpanded(true)} />
            <Expander
              theme={EXPANDER_THEME.DARK}
              expanded={isMenuExpanded}
              onStateChange={setMenuExpanded}
              props={['width', ['0px', sideMenuWidth]]}
            >
              <EditSideMenu />
            </Expander>
          </>
        )}
        <div className="editor">
          <div className="edit-area" ref={editAreaRef}>
            <div className="edit-area-inner">
              <SelectionLayer />
              {APP_CONFIG.editPage.showToolbar && (
                <div className="toolbar">
                  <QuillToolbar />
                </div>
              )}
              {hasSlides ? (
                <Canvas
                  canvasType={CANVAS_TYPE.DND}
                  dataType={CANVAS_DATA_TYPE.ACTIVE_SLIDE}
                />
              ) : (
                <NewDeckPage />
              )}
            </div>
          </div>
          <Expander
            theme={EXPANDER_THEME.LIGHT}
            dir={EXPANDER_DIR.TOP}
            expanded={isSlidesExpanded}
            onStateChange={setSlidesExpanded}
            props={['height', ['0px', '184px']]}
          >
            <EditSlides canvasType={CANVAS_TYPE.SLIDE_CARD} />
          </Expander>
        </div>
      </div>
      <CreateSlideModal />
      <FontImporter />
    </>
  )
})
