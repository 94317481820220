import i18n from 'src/i18n/config'

export const TEXT_HIGHLIGHT_MAPPINGS = (lng: string) => [
  {
    key: 'textHighlight',
    value: i18n.t('edit.color_mapping.text_highlight.item1', { lng }),
  },
  {
    key: 'textHighlightBg',
    value: i18n.t('edit.color_mapping.text_highlight.item2', { lng }),
  },
]
export const TEXT_MAPPINGS = (lng: string) => [
  {
    key: 'jumbo',
    value: i18n.t('edit.color_mapping.texts.item1', { lng }),
  },
  {
    key: 'title',
    value: i18n.t('edit.color_mapping.texts.item2', { lng }),
  },
  {
    key: 'subtitle',
    value: i18n.t('edit.color_mapping.texts.item3', { lng }),
  },
  {
    key: 'bodyText',
    value: i18n.t('edit.color_mapping.texts.item4', { lng }),
  },
  {
    key: 'label',
    value: i18n.t('edit.color_mapping.texts.item5', { lng }),
  },
]
export const QUOTE_MAPPINGS = (lng: string) => [
  {
    key: 'quote',
    value: i18n.t('edit.color_mapping.quote.item1', { lng }),
  },
  {
    key: 'quoteAuthor',
    value: i18n.t('edit.color_mapping.quote.item2', { lng }),
  },
]
export const ICON_MAPPINGS = (lng: string) => [
  {
    key: 'icon',
    value: i18n.t('edit.color_mapping.icons.item1', { lng }),
  },
  {
    key: 'iconBg',
    value: i18n.t('edit.color_mapping.icons.item2', { lng }),
  },
]
export const LIST_MAPPINGS = (lng: string) => [
  {
    key: 'listBullet',
    value: i18n.t('edit.color_mapping.lists.item1', { lng }),
  },
  {
    key: 'listTitle',
    value: i18n.t('edit.color_mapping.lists.item2', { lng }),
  },
  {
    key: 'listBody',
    value: i18n.t('edit.color_mapping.lists.item3', { lng }),
  },
]
export const TIMELINE_MAPPINGS = (lng: string) => [
  {
    key: 'timelineBar',
    value: i18n.t('edit.color_mapping.timeline.item1', { lng }),
  },
  {
    key: 'timelineDot',
    value: i18n.t('edit.color_mapping.timeline.item2', { lng }),
  },
]
export const TABLE_MAPPINGS = (lng: string) => [
  {
    key: 'tableContent',
    value: i18n.t('edit.color_mapping.table.item1', { lng }),
  },
  {
    key: 'tableHighlight',
    value: i18n.t('edit.color_mapping.table.item2', { lng }),
  },
]
export const CHART_MAPPINGS = (lng: string) => [
  {
    key: 'charts',
    value: i18n.t('edit.color_mapping.chart.items', { lng }),
  },
]
export const FORM_MAPPINGS = (lng: string) => [
  {
    key: 'formQuestion',
    value: i18n.t('edit.color_mapping.form.item1', { lng }),
  },
  {
    key: 'formButtonText',
    value: i18n.t('edit.color_mapping.form.item2', { lng }),
  },
  {
    key: 'formButtonBg',
    value: i18n.t('edit.color_mapping.form.item3', { lng }),
  },
  {
    key: 'formIcon',
    value: i18n.t('edit.color_mapping.form.item4', { lng }),
  },
]
export const BUTTON_MAPPINGS = (lng: string) => [
  {
    key: 'buttonText',
    value: i18n.t('edit.color_mapping.buttons.item1', { lng }),
  },
  {
    key: 'buttonBg',
    value: i18n.t('edit.color_mapping.buttons.item2', { lng }),
  },
  {
    key: 'buttonBorder',
    value: i18n.t('edit.color_mapping.buttons.item3', { lng }),
  },
]
export const SHAPE_MAPPINGS = (lng: string) => [
  {
    key: 'shape',
    value: i18n.t('edit.color_mapping.shape.item1', { lng }),
  },
]
export const DECORATION_MAPPINGS = (lng: string) => [
  {
    key: 'decorations',
    value: i18n.t('edit.color_mapping.decoration.items', { lng }),
  },
]
export const BACKGROUND_MAPPINGS = (lng: string) => [
  {
    key: 'background',
    value: i18n.t('edit.color_mapping.background', { lng }),
  },
]

export const COLOR_MAPPING_BLOCKS = (lng: string) => [
  /*{
    blockName: i18n.t('edit.color_mapping.text_highlight', { lng }),
    mapping: TEXT_HIGHLIGHT_MAPPINGS(lng),
  },*/
  {
    blockName: i18n.t('edit.color_mapping.texts', { lng }),
    mapping: TEXT_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.background', { lng }),
    mapping: BACKGROUND_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.quote', { lng }),
    mapping: QUOTE_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.icons', { lng }),
    mapping: ICON_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.lists', { lng }),
    mapping: LIST_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.timeline', { lng }),
    mapping: TIMELINE_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.table', { lng }),
    mapping: TABLE_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.chart', { lng }),
    mapping: CHART_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.form', { lng }),
    mapping: FORM_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.buttons', { lng }),
    mapping: BUTTON_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.shape', { lng }),
    mapping: SHAPE_MAPPINGS(lng),
  },
  {
    blockName: i18n.t('edit.color_mapping.decoration', { lng }),
    mapping: DECORATION_MAPPINGS(lng),
  },
]
