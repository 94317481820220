import React, { useCallback, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { useSelector, useDispatch } from 'react-redux'
import { OrganizationHeader } from '../'
import { Members } from 'src/components/members'
import { RemainingSeats } from 'src/components/remaining-seats'
import { Separator } from 'src/lib'
import { IOrganizationMembers } from './types'
import {
  orgMembersStyles,
  stickySeatsStyles,
  separatorStyles,
  contentStyles,
} from './styles'
import { RootState, setIsOrgMenuOpen } from 'src/store'

export const OrganizationMembers: React.FC<IOrganizationMembers> = React.memo(
  () => {
    const dispatch = useDispatch()
    const { t } = useLanguage()

    const {
      currentSeats,
      totalUsers,
      isOrgMenuOpen,
      isCurrentUserAdmin,
      isCurrentUserOwner,
      workspaceId,
      activeUserPlan,
    } = useSelector(({ user, org, workspace }: RootState) => ({
      currentSeats: org.seatSize,
      totalUsers:
        (org.users! && org.users!.length) +
        (org.pendingInvitedUsers! && org.pendingInvitedUsers!.length),
      isOrgMenuOpen: org.isOrgMenuOpen,
      isCurrentUserAdmin: org.users?.find(
        (orgUser) => orgUser.user?.id === user.data?.id,
      )?.isAdmin,
      isCurrentUserOwner: org.details?.ownerUser.id === user.data?.id,
      workspaceId: workspace.id,
      activeUserPlan: user.data?.activeUserPlan,
    }))

    const totalSeats = useMemo(() => currentSeats ?? 0, [currentSeats])
    const occupiedSeats = useMemo(() => totalUsers, [totalUsers])

    const handleClick = useCallback(() => {
      dispatch(setIsOrgMenuOpen(!isOrgMenuOpen))
    }, [isOrgMenuOpen])

    return (
      <>
        <OrganizationHeader
          onMenuClick={() => handleClick()}
          title={t('organization.member_settings')}
          isAddMembersAvailable={isCurrentUserAdmin}
          totalSeats={totalSeats}
          occupiedSeats={occupiedSeats}
          workspaceId={workspaceId}
        />
        <div css={orgMembersStyles}>
          <Members nameWidth={400} />
          <div css={stickySeatsStyles}>
            <Separator css={separatorStyles} />
            <RemainingSeats
              css={contentStyles}
              totalSeats={totalSeats}
              occupiedSeats={occupiedSeats}
              isManageSeatsAvailable={
                isCurrentUserOwner && !!activeUserPlan?.nextRenewDate
              }
            />
          </div>
        </div>
      </>
    )
  },
)
