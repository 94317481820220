import { icons } from 'src/lib/icon'
import { dataAttrType } from 'src/types'

export enum CREATECARD_TYPE {
  DEFAULT = 'default',
  GRADIENT = 'gradient',
}

export interface ICreateCard {
  icon?: icons
  text?: string
  img?: string
  type?: CREATECARD_TYPE
  onClick?: () => void
  disabled?: boolean
  className?: string
  dataAttr?: dataAttrType
}
