import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'
import { ITheme } from 'src/theme'
import { makeMuiStyles } from 'src/theme/muiTheme'

export const headerStyles = (theme: ITheme) => {
  const isMuiTheme = !!(theme as any)['palette']
  const muiTheme = theme as unknown as Theme
  const breakpoint = isMuiTheme
    ? (theme.breakpointsV2 as any)?.values?.tablet
    : theme.breakpointsV2.tablet
  const height = isMuiTheme
    ? 'auto'
    : theme.dimensions.headerHeight.DEFAULT + 'px'
  const spacing = isMuiTheme ? muiTheme.spacing(4) : theme.spacingV2['2xlarge']
  const bg = isMuiTheme
    ? muiTheme.palette.background.default
    : theme.colors.white.DEFAULT

  return css`
    height: ${height};
    padding: 0 ${spacing};
    display: flex;
    align-items: center;
    background: ${bg};
    width: 100%;
    @media (max-width: ${breakpoint - 1}px) {
      justify-content: space-between;
      padding: 0 ${theme.spacingV2.medium};
    }

    .left-side,
    .right-side {
      display: flex;
      flex-grow: 1;
      flex-shrink: 1;
      flex-direction: row;
    }

    .right-side {
      justify-content: flex-end;
    }
  `
}

export const inputContainerStyles = (theme: ITheme) => css`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 ${theme.gap.large}px 0 ${theme.gap['5xlarge']}px;

  @media (min-width: ${theme.breakpointsV2.desktop}px) {
    margin: 0 ${theme.spacingV2['5xlarge']};
  }

  @media (max-width: ${theme.breakpointsV2.laptop - 1}px) {
    width: ${theme.dimensions.headerInputWidth.medium}px;
    margin: 0 ${theme.spacingV2['2xlarge']};
  }
  @media (max-width: ${theme.breakpointsV2.tablet - 1}px) {
    width: ${theme.dimensions.headerInputWidth.small}px;
    margin: 0 ${theme.spacingV2.medium};
  }
  @media (min-width: ${theme.breakpointsV2.laptop}px) {
    width: ${theme.dimensions.headerInputWidth.DEFAULT}px;
  }
`

export const workspaceStyles = ({ zIndex }: ITheme) => css`
  z-index: ${zIndex.header};
`

export const useStyles = makeMuiStyles()((theme) => ({
  header: {
    height: 'auto',
    borderBottom: `1px solid ${theme.palette?.elevation?.outlined ?? '#F5F5F5'}`,
  },
}))
