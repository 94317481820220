import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  BUTTON_THEME,
  BUTTON_WIDTH,
  Button,
  ITable,
  Icon,
  Modal,
  Table,
  icons,
} from 'src/lib'
import {
  ComponentChartDataSchema,
  ComponentChartDataSeriesSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { chartEditModalStyles } from './styles'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { ComponentServices } from 'src/services'
import { DeepPartial } from 'src/types'
import { ChartType } from './ChartType'
import { IEditPropSection } from '../../types'
import { useTranslation } from 'react-i18next'
import { useDebouncer } from 'src/hooks'

export const EditData: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const dispatch = useDispatch()
    const [hasChanges, setHasChanges] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const { t } = useTranslation()
    const [tableError, setTableError] = useState<ITable['error']>()
    const [chartSeries, setChartSeries] =
      useState<ComponentChartDataSeriesSchema[]>()

    const [tableData, setTableData] = useState<string[][]>()
    useEffect(() => {
      const listener = () => {
        setModalOpen(true)
        dispatch(setAllowDeleteShortcut(false))
      }
      document.addEventListener('element-double-click', listener)
      return () => {
        document.removeEventListener('element-double-click', listener)
      }
    }, [])

    const handleTableDataChange = useCallback((data: string[][]) => {
      setHasChanges(true)
      setTableData(data)
    }, [])

    const selectedChartData = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentChartDataSchema['series'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const data = (c.data as ComponentChartDataSchema).series
            if (a === undefined && data) {
              return data
            }

            return data !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    useEffect(() => {
      const newTableData = []
      const firstRow = selectedChartData?.[0].values.map(({ name }) => name)
      firstRow && newTableData.push(['', ...firstRow])

      selectedChartData?.forEach((sr) => {
        const newRow = [sr.label]
        sr.values.forEach(({ value }) => {
          newRow.push(value?.toString() || '0')
        })
        newTableData.push(newRow)
      })
      setTableData(newTableData)
    }, [selectedChartData, isModalOpen])

    const tableDataToSeries = useCallback(() => {
      const newSeries: ComponentChartDataSeriesSchema[] = []
      let catNames: string[] = []
      let error: number[] | undefined = undefined

      tableData?.forEach((row, ri) => {
        if (ri === 0) {
          catNames = tableData?.[0].filter((_, i) => i)
        } else {
          newSeries.push({
            label: row[0],
            values: row
              .filter((_, i) => i)
              .map((value, ci) => {
                const intValue = parseInt(value)
                if (!error && Number.isNaN(intValue)) {
                  error = [ri, ci + 1]
                }
                return {
                  name: catNames[ci],
                  value: intValue,
                }
              }),
          })
        }
      })

      return {
        series: newSeries,
        error,
      }
    }, [tableData])

    useEffect(() => {
      setTableError(undefined)
      const { series, error } = tableDataToSeries()
      if (error) {
        setTableError({
          cell: error,
          text: t('edit.properties.edit_data.error'),
        })
      } else {
        setChartSeries(series)
      }
    }, [tableData, tableDataToSeries])

    const handleSave = useCallback(() => {
      const partialData: DeepPartial<ComponentChartDataSchema> = {
        series: chartSeries,
      }
      const updatedComponents: UpdateComponentSchema[] =
        ComponentServices.updateComponentData<ComponentChartDataSchema>({
          components,
          partialData,
        })

      dispatch(componentsUpdate({ components: updatedComponents }))
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      setHasChanges(false)
    }, [components, chartSeries])

    useEffect(() => {
      debounced()
    }, [handleSave])

    const debounced = useDebouncer(
      () => {
        if (hasChanges) {
          handleSave()
        }
      },
      { delay: 300 },
    )
    return (
      <>
        <Button
          theme={BUTTON_THEME.PRIMARY}
          text={t('edit.properties.data')}
          width={BUTTON_WIDTH.FULL}
          onClick={() => {
            setModalOpen(true)
            dispatch(setAllowDeleteShortcut(false))
          }}
        />
        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            setModalOpen(false)
            dispatch(setAllowDeleteShortcut(true))
          }}
        >
          <div css={chartEditModalStyles}>
            <div className="inner">
              <div className="chart-edit-header">
                <div className="chart-edit-title">
                  {t('edit.properties.data')}
                </div>
                <div className="chart-edit-type">
                  {t('edit.properties.chart.chart_data')}
                  <ChartType components={components} />
                </div>
                <Icon
                  icon={icons.close}
                  size={16}
                  onClick={() => {
                    setModalOpen(false)
                    setHasChanges(false)
                  }}
                />
              </div>
              <div className="table-area">
                <Table
                  error={tableError}
                  options={{
                    edit: true,
                    headerCol: true,
                    headerRow: true,
                    headerConst: true,
                    rowNumbers: true,
                  }}
                  onChange={handleTableDataChange}
                  data={tableData}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  },
)

EditData.displayName = 'EditData'
