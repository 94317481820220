import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const createSlideModalStyles = ({
  colors,
  borderRadius,
  gap,
  boxShadow,
}: ITheme) => css`
  background: ${colors.dark2.DEFAULT};
  border-radius: ${borderRadius[10]};
  box-shadow: ${boxShadow[2]};
  width: 1020px;
  height: 600px;
  padding: 24px 32px;

  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: ${gap.xlarge}px;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .tab-buttons {
    display: flex;
    justify-content: center;
  }

  .search-area {
    padding: 0 60px;
    display: flex;
    align-items: center;
    gap: ${gap['2xlarge']}px;
  }

  .layouts {
    padding: 0 60px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    .layouts-inner {
      display: flex;
      flex-wrap: wrap;
      overflow-y: scroll;
      align-items: flex-start;
      justify-content: space-between;
      gap: ${gap.medium}px;
      max-height: 100%;
    }
  }

  .add-button {
    display: flex;
    justify-content: center;
  }
`

export const noLayoutViewStyles = ({ colors, gap }: ITheme) => css`
  color: ${colors.text[2]};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${gap.xlarge}px;
  align-items: center;
  justify-content: center;
`
