import React from 'react'
import { observer } from 'mobx-react-lite'
import { IPolotnoComponent } from '../../types'
import Stack from '@mui/material/Stack'
import MoveDownPageAction from './MoveDownPageAction'
import MoveUpPageAction from './MoveUpPageAction'
import DuplicatePageAction from './DuplicatePageAction'
import DeletePageAction from './DeletePageAction'
import AddPageAction from './AddPageAction'

interface IPageControlsProps extends IPolotnoComponent {
  xPadding: number
  yPadding: number
}

const PageControls = observer<IPageControlsProps>(
  ({ store, yPadding, xPadding }) => {
    return (
      <Stack
        direction="row"
        sx={{
          position: 'absolute',
          top: yPadding - 40 + 'px',
          right: xPadding + 'px',
          display: 'flex',
        }}
      >
        <MoveDownPageAction store={store} />
        <MoveUpPageAction store={store} />
        <DuplicatePageAction store={store} />
        <DeletePageAction store={store} />
        <AddPageAction store={store} />
      </Stack>
    )
  },
)

export default PageControls
