import { COLOR } from './types'

export const purple = {
  DEFAULT: COLOR.purple_100,
  90: COLOR.purple_90,
  80: COLOR.purple_80,
  70: COLOR.purple_70,
  60: COLOR.purple_60,
  50: COLOR.purple_50,
  40: COLOR.purple_40,
  30: COLOR.purple_30,
  20: COLOR.purple_20,
  10: COLOR.purple_10,
}
