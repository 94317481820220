const snapImage = (id: string) => {
  try {
    const video = document.getElementById(id) as HTMLVideoElement
    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas!
      .getContext('2d')!
      .drawImage(video, 0, 0, canvas.width, canvas.height)
    const image: string | undefined = canvas.toDataURL(
      'image/jpeg',
      0.3 /* quality */,
    ) as unknown as string
    return image
  } catch {
    return null
  }
}

export const getVideoThumbnailImages = (
  id: string,
  videoSrc: string,
  countToProduce: number,
  interval: number,
  imageSize: number,
  threadCount: number,
): Promise<string[][]> => {
  const threadShare = Math.ceil(countToProduce / threadCount)
  const promises = []
  let sharedSeconds = 0
  let index = 0
  while (index < threadCount) {
    const start = sharedSeconds
    sharedSeconds += threadShare * interval
    const stop = sharedSeconds
    const promise = createImages(
      videoSrc,
      start,
      stop,
      interval,
      imageSize,
      id + '-' + index,
    )
    promises.push(promise)
    index += 1
  }
  return Promise.all(promises)
}

const createImages = (
  videoSrc: string,
  start: number,
  stop: number,
  interval: number,
  imageSize: number,
  id: string,
): Promise<string[]> => {
  return new Promise((resolve) => {
    const video = document.createElement('video')
    video.preload = 'metadata'
    video.src = videoSrc
    // Load video in Safari / IE11
    video.muted = true
    video.playsInline = true

    video.crossOrigin = 'Anonymous'
    video.currentTime = start
    video.width = 100
    video.height = 100
    video.style.setProperty('position', 'absolute')
    video.style.setProperty('z-index', '-2000000')
    video.style.setProperty('top', '0')
    video.style.setProperty('opacity', '0')
    video.id = id
    document.body.append(video)

    video.addEventListener('loadeddata', function () {
      video.currentTime = start + 1
    })
    const countToProduce = Math.floor((stop - start) / interval)

    let index = 0
    const imgs: string[] = Array(countToProduce).fill(null)
    video.addEventListener('timeupdate', function () {
      setTimeout(() => {
        if (
          video.currentTime + interval <= stop + 1 &&
          video.currentTime + interval <= video.duration
        ) {
          const imgSrc = snapImage(id)
          if (imgSrc) {
            imgs[index] = imgSrc
          }
          index += 1
          video.currentTime = video.currentTime + interval
        } else {
          const element = document.getElementById(id)
          element && document.body.removeChild(element)
          resolve(imgs)
        }
      }, 10)
    })
  })
}

export function loadVideoAndStart(
  videoSrc: string,
  callback: (duration: number) => void,
) {
  const video = document.createElement('video')
  video.preload = 'metadata'
  video.src = videoSrc
  // Load video in Safari / IE11
  video.muted = true
  video.playsInline = true

  video.crossOrigin = 'Anonymous'
  video.currentTime = 20
  video.width = 10
  video.height = 10
  video.style.setProperty('position', 'absolute')
  video.style.setProperty('z-index', '-2000000')
  video.style.setProperty('top', '0')
  video.style.setProperty('opacity', '0')
  document.body.append(video)
  video.id = 'trimmer-1'

  function onLoadedData() {
    const elementToRemove = document.getElementById(
      'trimmer-1',
    ) as HTMLVideoElement
    callback(elementToRemove?.duration ?? 0)
    elementToRemove?.removeEventListener('loadeddata', onLoadedData)
    elementToRemove && document.body.removeChild(elementToRemove)
  }

  video.addEventListener('loadeddata', onLoadedData)
}
