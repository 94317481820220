// import React from 'react'
// import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom/client'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import App from './App'
import './i18n/config'
import env from 'react-dotenv'
import { flags } from './config'

const LDProvider = withLDProvider({
  clientSideID: env.LD_SDK_KEY,
  context: {
    kind: 'user',
    key: 'USER_ANNMYS',
    name: 'User Anonymous',
    email: 'user_annmys@decktopus.com',
  },
  user: {
    key: 'USER_ANNMYS',
    name: 'User Anonymous',
  },
  options: {
    bootstrap: 'localStorage',
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  flags,
})(App)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<LDProvider />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
