import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const adminPageWrapperStyles = (theme: ITheme) => css`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.background.DEFAULT};
  display: flex;
  flex-direction: row;
`

export const adminMenuStyles = (theme: ITheme) => css`
  display: flex;
  position: fixed;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  padding: ${theme.gap.xlarge}px;
  row-gap: ${theme.gap.large}px;
  background-color: ${theme.colors.white.DEFAULT};
  border: 1px solid ${theme.colors.outline.DEFAULT};
  left: 0;
  top: 0;

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: ${theme.dimensions.organizationMenuWidth.small}px;
    position: absolute;
    top: ${theme.dimensions.headerHeight.DEFAULT}px;
    z-index: ${theme.zIndex.orgMenu};

    .profile-badge {
      padding-top: 0;
    }
  }

  @media (min-width: ${theme.breakpointsV2.laptop}px) {
    position: relative;
    top: 0;
  }

  @media (min-width: ${theme.breakpointsV2.xlarge}px) {
    width: ${theme.dimensions.organizationMenuWidth.DEFAULT}px;
  }
`

export const adminMenuInnerStyles = css`
  position: relative;
  height: 100%;
  width: 100%;
`

export const bottomMenuItemStyles = ({
  borderRadius,
  dimensions,
  colors,
  gap,
}: ITheme) => css`
  position: absolute;
  bottom: ${gap['2xsmall']}px;
  display: flex;
  align-items: center;
  height: ${dimensions.sideMenuItemHeight.DEFAULT}px;
  color: ${colors.text[2]};
  gap: ${gap['2xsmall']}px;
  padding: ${gap.xsmall} ${gap.small}px;
  cursor: pointer;
  border-radius: ${borderRadius[5]};
  transition: background 0.3s;

  .icon path {
    fill: ${colors.text[2]};
  }
`

export const adminViewWrapperStyles = css`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const adminSearchBoxStyles = ({
  colors,
  boxShadow,
  borderRadius,
}: ITheme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 550px;
  background-color: ${colors.white.DEFAULT};
  box-shadow: ${boxShadow.DEFAULT};
  border: 1px solid ${colors.outline.DEFAULT};
  border-radius: ${borderRadius[10]};
`

export const adminSearchBoxInlineStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  row-gap: ${gap.small}px;
`

export const adminSearchBoxTitleStyles = ({
  colors,
  fontSize,
  fontWeight,
}: ITheme) => css`
  font-size: ${fontSize.xlarge};
  font-weight: ${fontWeight.medium};
  text-align: center;

  & strong {
    color: ${colors.primary.DEFAULT};
    font-weight: ${fontWeight.medium};
  }
`

export const adminSearchBoxSubTitleStyles = ({ fontWeight }: ITheme) => css`
  font-weight: ${fontWeight.medium};
  text-align: center;
`

export const adminSearchInputsWrapperStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap.xlarge}px;
  padding-top: ${gap.xlarge}px;
  padding-left: ${gap['5xlarge']}px;
  padding-right: ${gap['5xlarge']}px;
`

export const adminSearchButtonWrapperStyles = ({ gap }: ITheme) => css`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: ${gap.xlarge}px;
`
interface IMenuStyles {
  isMenuOpen: boolean
}
export const menuStyles =
  ({ isMenuOpen }: IMenuStyles) =>
  ({ gap, boxShadow, borderRadius, colors }: ITheme) => css`
    position: absolute;
    z-index: ${isMenuOpen ? 11 : -1};
    transition: opacity;
    top: calc(100% + ${gap['2xsmall']}px);
    right: 0;
    box-shadow: ${boxShadow[2]};
    border: 1px solid ${colors.outline.DEFAULT};
    border-radius: ${borderRadius[4]};
    opacity: ${isMenuOpen ? 1 : 0};
    width: 191px;
  `

export const actionRowStyles = ({ gap }: ITheme) => css`
  position: relative;
  width: 160px;
  display: flex;
  justify-content: end;
  padding-right: ${gap.medium}px;
`

export const userNameStyles = ({ fontWeight, gap }: ITheme) => css`
  font-weight: ${fontWeight.medium};
  padding-bottom: ${gap['3xsmall']}px;
`
