import React, { useState, useEffect } from 'react'
import {
  containerStyle,
  strengthBarStyle,
  strengthFillStyle,
  messageStyle,
  rightContainerStyle,
} from './styles'
import { useLanguage } from 'src/hooks'
import { StrengthBarProps } from './types'
import { Tooltip, TOOLTIP_PLACE, TOOLTIP_THEME } from 'src/lib/tooltip'
import { Icon, icons } from 'src/lib/icon'
import { colors } from 'src/theme/colors'

export const PromptStrengthBar: React.FC<StrengthBarProps> = ({
  inputLength,
  maxLength = 100,
}) => {
  const { t } = useLanguage()
  const [strength, setStrength] = useState(0)

  useEffect(() => {
    const newStrength = Math.min(100, (inputLength / maxLength) * 100)
    setStrength(newStrength)
  }, [inputLength, maxLength])

  const getMessage = () => {
    if (strength <= 33) {
      return 'create_with_ai.strength.low'
    } else if (strength <= 66) {
      return 'create_with_ai.strength.medium'
    } else if (strength < 100) {
      return 'create_with_ai.strength.high'
    }
    return 'create_with_ai.strength.done'
  }

  return (
    <div css={containerStyle}>
      <div css={messageStyle(strength)}>{t(getMessage())}</div>
      <div css={rightContainerStyle}>
        <div css={strengthBarStyle}>
          <div css={strengthFillStyle(strength)}></div>
        </div>
        <Tooltip
          width={282}
          text={t('create_with_ai.strength.tooltip')}
          place={TOOLTIP_PLACE.TOP_LEFT}
          theme={TOOLTIP_THEME.LIGHT}
          className="tooltip"
        >
          <div>
            <Icon icon={icons.info} size={16} color={colors.outline.DEFAULT} />
          </div>
        </Tooltip>
      </div>
    </div>
  )
}
