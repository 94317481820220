import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getDeckFormResponsesResponse } from 'src/types/api/responseObjects'
import { IFormTable } from './types'
import {
  formTableStyles,
  footerStyles,
  tableBodyStyles,
  tableHeaderStyles,
  tableStyles,
  dropdownContainer,
  downloadContainer,
  ratingCellStyle,
  tableRowStyles,
  deletedQuestionCell,
  deletedTooltipStyle,
  tableWrapperStyles,
  ratingStyle,
  ratingTooltipStyle,
} from './styles'
import {
  Button,
  BUTTON_THEME,
  BUTTON_TYPE,
  BUTTON_WIDTH,
  Dropdown,
  DROPDOWN_MENU_POSITION,
  DROPDOWN_WIDTH,
  Icon,
  icons,
  IDropdownItem,
  TooltipView,
} from 'src/lib'
import { theme } from 'src/theme'
import { saveAs } from 'file-saver'
import { useLanguage } from 'src/hooks'
import { DeckResponseAnswerTypes } from 'src/types/api/enums'
import { formatDateTime } from 'src/services/dateTimeServices'

export const FormTable: React.FC<IFormTable> = React.memo(
  ({ className, dataAttr, data }) => {
    const [showDeleteTooltip, setDeleteShowTooltip] = useState(false)
    const [showRatingTooltip, setShowRatingTooltip] = useState(false)
    const { t } = useLanguage()

    const [selectedTitle, setSelectedTitle] = useState<IDropdownItem>()

    useEffect(() => {
      questionsDropdownItems && setSelectedTitle(questionsDropdownItems[0])
    }, [])

    const handleTitleChange = (selectedTitle?: IDropdownItem) => {
      selectedTitle && setSelectedTitle(selectedTitle)
    }

    const questionsDropdownItems = useMemo(
      () =>
        data?.forms.map((item) => {
          return {
            name: item.title,
            value: item.id,
          }
        }),
      [data],
    )
    const deletedQuestions = useMemo(() => {
      const selectedFormQuestions = data?.forms
        .filter((form) => form.id === selectedTitle?.value)
        .map((form) => form.questions)[0]
      const selectedFormResponses = data?.responses
        .filter((response) => response.formId === selectedTitle?.value)
        .map((response) => response.questionId)

      const deletedQuestionIds = selectedFormResponses?.filter(
        (questionId) =>
          !selectedFormQuestions?.some(
            (question) => question.id === questionId,
          ),
      )
      return deletedQuestionIds
    }, [data, selectedTitle])

    const ratingQuestions = useMemo(() => {
      const selectedForm = data?.forms.find(
        (form) => form.id === selectedTitle?.value,
      )
      return (
        selectedForm?.questions
          .filter((question) =>
            data?.responses.some(
              (response) =>
                response.answerType === DeckResponseAnswerTypes.NUMBER &&
                response.questionId === question.id,
            ),
          )
          .map((question) => question.id) || []
      )
    }, [data, selectedTitle])

    const decimalToFraction = useCallback((decimal: number) => {
      const numerator = decimal * 10
      const denominator = 10
      const roundedNumerator = parseFloat(numerator.toFixed(1))
      return `${roundedNumerator} / ${denominator}`
    }, [])

    const ratingCell = useCallback(
      (answer: number | string, index: number, ratingQuestion?: string) => {
        return (
          <div css={ratingCellStyle}>
            {index === -1 && <span>{ratingQuestion}</span>}
            <div
              css={ratingStyle}
              onMouseEnter={() => setShowRatingTooltip(true)}
              onMouseLeave={() => setShowRatingTooltip(false)}
            >
              <Icon
                icon={icons.star}
                size={16}
                color={
                  index === -1
                    ? theme.colors.text[2]
                    : index % 2 === 0
                      ? theme.colors.primary.DEFAULT
                      : theme.colors.text.DEFAULT
                }
              />
              <span>{decimalToFraction(Number(answer))}</span>
              {showRatingTooltip && index === -1 && (
                <TooltipView
                  text={t('form_response.table.avarage_score')}
                  css={ratingTooltipStyle}
                />
              )}
            </div>
          </div>
        )
      },
      [showRatingTooltip],
    )

    const tableData = useMemo(() => {
      const selectedFormResponse = data?.responses.filter(
        (response) => response.formId === selectedTitle?.value,
      )

      const groupedData: {
        [sessionId: string]: {
          sessionId: string
          createdAt: Date
          userResponses: getDeckFormResponsesResponse['data']['responses']
        }
      } = {}

      selectedFormResponse?.forEach((elem) => {
        const sessionId = elem.sessionId
        const createdAt = elem.createdAt
        if (!groupedData[sessionId]) {
          groupedData[sessionId] = { sessionId, createdAt, userResponses: [] }
        }
        groupedData[sessionId].userResponses.push(elem)
      })

      const usersData = Object.values(groupedData)

      return usersData
    }, [selectedTitle, data])

    const calculateAverageRating = useCallback(
      (questionId: string) => {
        const responsesForQuestion = tableData.map((response) => {
          const userResponse = response.userResponses.find(
            (res) => res.questionId === questionId,
          )
          return userResponse ? parseFloat(userResponse.answerValue) : 0
        })
        const totalRating = responsesForQuestion.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0,
        )

        const averageRating =
          responsesForQuestion.length > 0
            ? (totalRating / responsesForQuestion.length).toFixed(2)
            : '0.00'
        return averageRating
      },
      [tableData],
    )

    const convertToCSV = useCallback(() => {
      let csv = 'ID,Date,'

      const selectedForm = data?.forms.find(
        (form) => form.id === selectedTitle?.value,
      )
      if (selectedForm) {
        csv +=
          selectedForm.questions.map((question) => question.text).join(',') +
          '\n'
      }

      tableData.forEach((response) => {
        const questions = selectedForm?.questions
        if (questions) {
          const rowValues = Array(questions.length + 2).fill('')
          rowValues[0] = response.sessionId
          rowValues[1] = new Date(response.createdAt).toISOString()
          questions.forEach((question, index) => {
            const userResponse = response.userResponses.find(
              (res) => res.questionId === question.id,
            )
            if (userResponse) {
              rowValues[index + 2] = userResponse.answerValue
            }
          })
          csv += rowValues.join(',') + '\n'
        }
      })

      return csv
    }, [data, selectedTitle])

    const downloadCSV = useCallback(() => {
      const csv = convertToCSV()
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })

      saveAs(blob, `${selectedTitle?.name}_form_responses.csv`)
    }, [selectedTitle, convertToCSV])

    return (
      <div css={formTableStyles} className={className} {...dataAttr}>
        <div css={tableWrapperStyles}>
          <table css={tableStyles}>
            <thead css={tableHeaderStyles}>
              <tr>
                <th>{t('common.date')}</th>
                {data?.forms
                  .find((form) => form.id === selectedTitle?.value)
                  ?.questions.map((question) => {
                    const questionId = question.id
                    const questionText = question.text
                    if (ratingQuestions.includes(questionId)) {
                      const avarageRating = calculateAverageRating(questionId)
                      return (
                        <th key={questionId}>
                          {ratingCell(avarageRating, -1, questionText)}
                        </th>
                      )
                    } else {
                      return <th key={question.id}>{question.text}</th>
                    }
                  })}
                {data?.responses
                  .filter(
                    (response) =>
                      response.formId === selectedTitle?.value &&
                      deletedQuestions?.includes(response.questionId),
                  )

                  .map((elem) => {
                    return (
                      <th
                        key={elem.questionId}
                        css={deletedQuestionCell}
                        onMouseEnter={() => setDeleteShowTooltip(true)}
                        onMouseLeave={() => setDeleteShowTooltip(false)}
                      >
                        {elem.questionText}
                        {showDeleteTooltip && (
                          <TooltipView
                            text={t('form_response.table.deleted_question')}
                            css={deletedTooltipStyle}
                          />
                        )}
                      </th>
                    )
                  })}
              </tr>
            </thead>
            <tbody css={tableBodyStyles}>
              {tableData.map((response, index) => {
                return (
                  <tr key={response.sessionId} css={tableRowStyles(index)}>
                    <td>{formatDateTime(response.createdAt)}</td>
                    {data?.forms
                      .find((form) => form.id === selectedTitle?.value)
                      ?.questions.map((question) => {
                        const userResponse = response.userResponses.find(
                          (res) => res.questionId === question.id,
                        )
                        return (
                          <td key={question.id}>
                            {userResponse &&
                            userResponse.answerType ===
                              DeckResponseAnswerTypes.NUMBER
                              ? ratingCell(userResponse.answerValue, index)
                              : userResponse
                                ? userResponse.answerValue
                                : ''}
                          </td>
                        )
                      })}
                    {data?.responses
                      .filter(
                        (res) =>
                          res.formId === selectedTitle?.value &&
                          deletedQuestions?.includes(res.questionId),
                      )
                      .map((elem) => {
                        const correspondingResponse =
                          response.userResponses.find(
                            (userResponse) =>
                              userResponse.questionId === elem.questionId,
                          )
                        return (
                          <td key={elem.id} css={deletedQuestionCell}>
                            {correspondingResponse
                              ? correspondingResponse.answerValue
                              : ''}
                          </td>
                        )
                      })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <div css={footerStyles}>
          <div css={dropdownContainer}>
            <Dropdown
              items={questionsDropdownItems!}
              onChange={handleTitleChange}
              selected={selectedTitle?.value}
              width={DROPDOWN_WIDTH.FULL}
              menuPosition={DROPDOWN_MENU_POSITION.UP}
            />
          </div>
          <div css={downloadContainer}>
            <Button
              onClick={downloadCSV}
              text={t('form_response.table.download_form')}
              type={BUTTON_TYPE.GHOST}
              icon={icons.download}
              theme={BUTTON_THEME.ELEMENTS}
              width={BUTTON_WIDTH.FULL}
            />
          </div>
        </div>
      </div>
    )
  },
)

FormTable.displayName = 'FormTable'
