import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const aiLoadingStyles = ({ colors, gap }: ITheme) => css`
  background: ${colors.white.DEFAULT};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${gap['4xlarge']}px;
`

export const animationStyles = ({ gap }: ITheme) => css`
  width: 235px;
  height: 193px;
  margin: ${gap.large}px 0;
`

export const loadingTextStyles = ({
  colors,
  fontSize,
  fontWeight,
  gap,
}: ITheme) => css`
  color: ${colors.primary.DEFAULT};
  font-size: ${fontSize.large};
  font-weight: ${fontWeight.medium};
  padding: ${gap['4xlarge']}px;
  opacity: 1;
  transition: opacity 1s ease; /* Smooth transition for fading */
  width: 100%;
  height: 130px;
  text-align: center;
`

export const buttonWrapperStyles = ({ gap }: ITheme) => css`
  width: 100%;
  margin-top: -${gap['2xlarge']}px;
  margin-left: -${gap['2xlarge']}px;
`
