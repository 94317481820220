import { IButton } from 'src/lib'
import { dataAttrType } from 'src/types'

export interface IWebhookForm {
  className?: string
  dataAttr?: dataAttrType
  onClickClose?: IButton['onClick']
  onClickSave?: IButton['onClick']
  onClickEdit?: IButton['onClick']
  onClickCancel?: IButton['onClick']
  onClickDelete?: IButton['onClick']
  onClickCancelDelete?: IButton['onClick']
}

export enum WEBHOOK_MODAL_STATE {
  DEFAULT_WEBHOOK_INFO = 'DEFAULT_WEBHOOK_INFO',
  ENTER_WEBHOOK_INFO = 'ENTER_WEBHOOK_INFO',
  SAVED_WEBHOOK_INFO = 'SAVED_WEBHOOK_INFO',
  DELETE_WEBHOOK_INFO = 'DELETE_WEBHOOK_INFO',
}
