import React from 'react'

import { IEditHeader } from './types'
import { editHeaderStyles } from './styles'
import { useLanguage } from 'src/hooks'

export const EditHeader: React.FC<IEditHeader> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    return (
      <div css={editHeaderStyles} className={className} {...dataAttr}>
        {t('edit.header.title')}
      </div>
    )
  },
)

EditHeader.displayName = 'EditHeader'
