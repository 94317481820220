import { ComponentTypes, FormQuestionTypes } from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentFormDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const ratingDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.rating.display_name', { lng }),
      styles: {},
      icon: icons.rate,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.FORM,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 1700,
          height: 700,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          title: i18n.t('edit.definitions.rating.title', { lng }),
          formId: '58df05ea-a24f-46a3-a917-7a7bfa63d001',
          buttonText: i18n.t('edit.definitions.rating.button_text', { lng }),
          options: {
            notificationEmails: [],
            isSubmitRequired: false,
            isGDPRConsentEnabled: false,
            defaultTexts: {
              questionAnswerPlaceholders: {
                [FormQuestionTypes.TEXT]: i18n.t(
                  'edit.definitions.rating.placeholder.text',
                  { lng },
                ),
                [FormQuestionTypes.OPTIONS]: i18n.t(
                  'edit.definitions.rating.placeholder.options',
                  { lng },
                ),
                [FormQuestionTypes.RATE]: i18n.t(
                  'edit.definitions.rating.placeholder.rate',
                  { lng },
                ),
              },
              buttons: {
                next: i18n.t('edit.definitions.rating.next', { lng }),
                back: i18n.t('edit.definitions.rating.back', { lng }),
              },
              submitMessage: i18n.t('edit.definitions.rating.submit', { lng }),
              GDPRConsentMessage: i18n.t(
                'edit.definitions.rating.gdpr_consent',
                { lng },
              ),
            },
          },
          questions: [
            {
              isRequired: false,
              questionId: '58df05ea-a24f-46a3-a917-7a7bfa63d012',
              questionText: i18n.t('edit.definitions.rating.question_text', {
                lng,
              }),
              questionType: FormQuestionTypes.RATE,
              rate: {
                icon: 'like',
                range: 10,
                description: i18n.t(
                  'edit.definitions.rating.question_description',
                  { lng },
                ),
              },
            },
          ],
        } as ComponentFormDataSchema,
      },
    ],
  }
}
