import React, { useMemo } from 'react'

import { IMedia } from './types'
import { shapeWrapperStyles } from './styles'
import { ComponentMediaDataSchema } from 'src/types/api/requestObjects'
import { SvgMasked } from 'src/lib/svg-masked'
import { ComponentMediaZoomTypes } from 'src/types/api/enums'

export const Shape: React.FC<IMedia> = React.memo(
  ({ data, className, dataAttr }) => {
    const componentData: ComponentMediaDataSchema = useMemo(
      () => data.data as ComponentMediaDataSchema,
      [data.data],
    )

    const mediaUrl = useMemo(
      () => (data.data as ComponentMediaDataSchema).mediaUrl,
      [(data.data as ComponentMediaDataSchema).mediaUrl],
    )

    const opacity = useMemo(() => data.style.opacity || 1, [data])

    return (
      <div
        css={shapeWrapperStyles}
        className={className}
        {...dataAttr}
        style={{
          opacity,
          borderTopLeftRadius: `${data.style.corner?.tl || 0}em`,
          borderTopRightRadius: `${data.style.corner?.tr || 0}em`,
          borderBottomLeftRadius: `${data.style.corner?.bl || 0}em`,
          borderBottomRightRadius: `${data.style.corner?.br || 0}em`,
        }}
      >
        <SvgMasked
          url={mediaUrl}
          color={componentData.style?.color.text.colors[0] || '#000000'}
          gradientColor={componentData.style?.color.text}
          distort={componentData.zoom?.type === ComponentMediaZoomTypes.DISTORT}
        />
      </div>
    )
  },
)

Shape.displayName = 'Shape'
