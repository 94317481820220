import { ComponentTypes } from 'src/types/api/enums'
import { SAVE_STATE } from '../types'
import {
  ComponentDataSchema,
  DeckData_DataSchema,
} from 'src/types/api/requestObjects'
import { ComponentSchema } from 'src/types/api/responseObjects'
import { DeepPartial } from 'src/types'

export enum CANVAS_PANEL {
  DESIGN = 'design',
  ELEMENTS = 'elements',
  SLIDE = 'slide',
  NOTES = 'notes',
  AI = 'ai',
  BRAND = 'brand',
}

export interface IComponentFixerItem {
  slideDataId: number
  updateData: DeepPartial<ComponentDataSchema>
  data: ComponentSchema
}

export interface ICanvasState {
  panel: CANVAS_PANEL
  selectedComponents: { id?: number; tempId?: string; type?: ComponentTypes }[]
  componentsDragging: boolean
  saveState: SAVE_STATE
  addSlideModal: {
    isOpen: boolean
    isDuplicate?: boolean
    targetSlide?: DeckData_DataSchema['slides'][0]
  }
  allowDeleteShortcut: boolean
  clickCreateList: (string | null)[]
  componentClipboard?: ComponentSchema
  isNextSlideBlocked?: boolean
  slideForms: {
    id: string
    state: boolean
    data: {
      [key in string]: string | number | string[] | null
    }
  }[]
  componentFixInProgress: boolean
  componentFixer: IComponentFixerItem[]
}
