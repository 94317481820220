import {
  ComponentTextTypes,
  ComponentTypes,
  TextAlignmentsHorizontal,
  TextAlignmentsVertical,
} from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { TextDisplay } from '../components/text-display'
import { ComponentTextDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const bodyDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.body.display_name', { lng }),
      styles: {
        fontSize: '12px',
      },
      Component: TextDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.TEXT,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 600,
          height: 100,
          rotate: 0,
          scale: 1,
        },
        style: {
          opacity: 1,
        },
        data: {
          style: {
            color: {
              text: { colors: ['var(--bodyText)'] },
            },
            font: {
              size: '8em',
              family: 'secondary',
              alignmentHorizontal: TextAlignmentsHorizontal.LEFT,
              alignmentVertical: TextAlignmentsVertical.TOP,
            },
          },
          text: i18n.t('edit.definitions.body.text', { lng }),
          textType: ComponentTextTypes.BODY,
        } as ComponentTextDataSchema,
      },
    ],
  }
}
