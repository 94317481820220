import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const gradientBarStyles = () => css`
  height: 36px;
  margin: 16px;
  display: flex;
  align-items: flex-end;
  position: relative;

  .gradient-bar {
    height: 12px;
    width: 100%;
    border-radius: 6px;
  }
`

interface IGradientStep {
  isSelected?: boolean
}
export const gradientStep =
  ({ isSelected }: IGradientStep) =>
  ({ colors }: ITheme) => css`
    cursor: pointer;
    position: absolute;
    bottom: 14px;
    border: 2px solid ${isSelected ? colors.primary.DEFAULT : 'transparent'};
    border-radius: 4px;

    > div {
      border: 2px solid ${colors.white.DEFAULT};
      border-radius: 2px;
      width: 20px;
      height: 20px;
      position: relative;

      &:before {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        border-width: 6px;
        border-style: solid;
        border-color: transparent;
        border-top-color: ${colors.white.DEFAULT};
      }
    }
  `
