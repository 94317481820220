export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3_112)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_3_112"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3_100)"><mask id="path-1-outside-1_3_100" maskUnits="userSpaceOnUse" x="413" y="-12" width="1519" height="1104" fill="black"><rect fill="white" x="413" y="-12" width="1519" height="1104"/><path fill-rule="evenodd" clip-rule="evenodd" d="M433 1080H1920V0H960.107L433 1080Z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M433 1080H1920V0H960.107L433 1080Z" fill="url(#decor-bg)"/><path d="M1920 1080V1092H1932V1080H1920ZM433 1080L422.216 1074.74L413.79 1092H433V1080ZM1920 0H1932V-12H1920V0ZM960.107 0V-12H952.61L949.322 -5.26331L960.107 0ZM1920 1068H433V1092H1920V1068ZM1908 0V1080H1932V0H1908ZM960.107 12H1920V-12H960.107V12ZM443.784 1085.26L970.891 5.26331L949.322 -5.26331L422.216 1074.74L443.784 1085.26Z" fill="$color1" mask="url(#path-1-outside-1_3_100)"/></g><defs><clipPath id="clip0_3_100"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 -1 1920 1080)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3_106)"><mask id="path-1-outside-1_3_106" maskUnits="userSpaceOnUse" x="413" y="-12" width="1519" height="1104" fill="black"><rect fill="white" x="413" y="-12" width="1519" height="1104"/><path fill-rule="evenodd" clip-rule="evenodd" d="M433 0H1920V1080H960.107L433 0Z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M433 0H1920V1080H960.107L433 0Z" fill="url(#decor-bg)"/><path d="M1920 0V-12H1932V0H1920ZM433 0L422.216 5.26332L413.79 -12H433V0ZM1920 1080H1932V1092H1920V1080ZM960.107 1080V1092H952.61L949.322 1085.26L960.107 1080ZM1920 12H433V-12H1920V12ZM1908 1080V0H1932V1080H1908ZM960.107 1068H1920V1092H960.107V1068ZM443.784 -5.26332L970.891 1074.74L949.322 1085.26L422.216 5.26332L443.784 -5.26332Z" fill="$color1" mask="url(#path-1-outside-1_3_106)"/></g><defs><clipPath id="clip0_3_106"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3_87)"><mask id="path-1-outside-1_3_87" maskUnits="userSpaceOnUse" x="-12" y="-12" width="1519" height="1104" fill="black"><rect fill="white" x="-12" y="-12" width="1519" height="1104"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1487 1080H0V0H959.893L1487 1080Z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M1487 1080H0V0H959.893L1487 1080Z" fill="url(#decor-bg)"/><path d="M0 1080V1092H-12V1080H0ZM1487 1080L1497.78 1074.74L1506.21 1092H1487V1080ZM0 0H-12V-12H0V0ZM959.893 0V-12H967.39L970.678 -5.26331L959.893 0ZM0 1068H1487V1092H0V1068ZM12 0V1080H-12V0H12ZM959.893 12H0V-12H959.893V12ZM1476.22 1085.26L949.109 5.26331L970.678 -5.26331L1497.78 1074.74L1476.22 1085.26Z" fill="$color1" mask="url(#path-1-outside-1_3_87)"/></g><defs><clipPath id="clip0_3_87"><rect width="1920" height="1080" fill="white" transform="matrix(1 0 0 -1 0 1080)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3_94)"><mask id="path-1-outside-1_3_94" maskUnits="userSpaceOnUse" x="-12" y="-12" width="1519" height="1104" fill="black"><rect fill="white" x="-12" y="-12" width="1519" height="1104"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1487 0H0V1080H959.893L1487 0Z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M1487 0H0V1080H959.893L1487 0Z" fill="url(#decor-bg)"/><path d="M0 0V-12H-12V0H0ZM1487 0L1497.78 5.26332L1506.21 -12H1487V0ZM0 1080H-12V1092H0V1080ZM959.893 1080V1092H967.39L970.678 1085.26L959.893 1080ZM0 12H1487V-12H0V12ZM12 1080V0H-12V1080H12ZM959.893 1068H0V1092H959.893V1068ZM1476.22 -5.26332L949.109 1074.74L970.678 1085.26L1497.78 5.26332L1476.22 -5.26332Z" fill="$color1" mask="url(#path-1-outside-1_3_94)"/></g><defs><clipPath id="clip0_3_94"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_147_3)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V733.5L1920 637V0Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1924.4 636.835L-0.636703 735.152L-1.4018 720.172L1923.63 621.854L1924.4 636.835Z" fill="$color1"/></g><defs><clipPath id="clip0_147_3"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="540" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3_129)"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H1920V0H960V1080Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M972 -7V1088H960V-7H972Z" fill="$color1"/></g><defs><clipPath id="clip0_3_129"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H1920V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3_116)"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H0V0H960V1080Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M948 -7V1088H960V-7H948Z" fill="$color1"/></g><defs><clipPath id="clip0_3_116"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H0V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
