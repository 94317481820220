import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const decksWrapperStyles = () => css`
  width: 100%;
`
export const decksHeaderStyles = () => css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`

export const titleStyles = (theme: ITheme) => css`
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.colors.text.DEFAULT};
`
export const directoryWrapperStyles = () => css`
  display: flex;
  cursor: pointer;
`
export const directoryStyles = (theme: ITheme) => css`
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.colors.text.DEFAULT};
  display: flex;
`

export const subTitleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.xsmall};
  color: ${theme.colors.text[2]};
  margin-bottom: ${theme.spacingV2.large};
`

export const areaStyles = (theme: ITheme) => css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacingV2.large};
`
export const loaderWrapperStyles = () => css`
  width: 100vw;
  display: flex;
  justify-content: center;
`
