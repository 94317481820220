import { dataAttrType } from 'src/types'
import { SVGTypes } from 'src/types/api/enums'
import {
  GradientLikeColorSchema,
  ThemeColorDataSchema,
  ThemeColorMapSchema,
} from 'src/types/api/requestObjects'
import {
  getDeckDetailedResponse,
  getSlideDataResponse,
} from 'src/types/api/responseObjects'

export enum CANVAS_TYPE {
  // editable area
  DND = 'dnd',
  // non editable places
  STATIC = 'static',
  // react-dnd previews
  FIXED = 'fixed',
  // preview section
  PREVIEW = 'preview',
  // font size calculation
  SLIDE_CARD = 'slide_card',
}

export enum CANVAS_DATA_TYPE {
  ACTIVE_SLIDE = 'active_slide',
  ACTIVE_DECK = 'active_deck', // activeSlide / slideId
  LAYOUT = 'layout', // layoutId
  DECK = 'deck', // deckId, slideId (request)
}

export enum CANVAS_ITEM_TYPE {
  SIDEBAR_ITEM = 'sidebar-item',
  CANVAS_ITEM = 'canvas-item',
  HITPOINT = 'hitpoint',
}

export interface ICanvas {
  canvasType?: CANVAS_TYPE
  dataType?: CANVAS_DATA_TYPE

  // ACTIVE_SLIDE
  // nothing

  dataProps?: {
    slideDataId?: number
    // ACTIVE_DECK_SLIDE
    slideOrder?: number

    // DECK
    deck?: getDeckDetailedResponse['data']
    slideData?: getSlideDataResponse['data']['slideData']
    deckId?: number | null
    orderIndex?: number
    defaultColorMap?: ThemeColorMapSchema
    colorMap?: ThemeColorMapSchema
    themeColors?: ThemeColorDataSchema
    shareKey?: string
  }

  external?: {
    components?: getSlideDataResponse['data']['slideData']['slideDataComponents'][0]['component'][]
    svgUrl?: string | null
    themeColors?: ThemeColorDataSchema
    isSwapColor?: boolean
    slideDataId?: number
    background?: GradientLikeColorSchema | null
    svgDecor?: string
    svgType?: SVGTypes | null
    className?: string
    cssVariables?: string
  }

  svgUrl?: string | null
  themeColors?: ThemeColorDataSchema
  components?: getSlideDataResponse['data']['slideData']['slideDataComponents'][0]['component'][]
  isSwapColor?: boolean
  slideDataId?: number
  background?: GradientLikeColorSchema
  svgDecor?: string
  svgType?: SVGTypes | null
  className?: string
  dataAttr?: dataAttrType
}

export type ICanvasScale = number

export interface ICanvasDnD extends Pick<ICanvas, 'components'> {
  scale?: ICanvasScale
}

export interface ICanvasStatic
  extends Pick<ICanvas, 'canvasType' | 'components' | 'slideDataId'> {
  scale?: ICanvasScale
}
