import React from 'react'

import { IPropSlider } from './types'
import { propSliderStyles } from './styles'
import { Slider } from 'src/lib/slider'

export const PropSlider: React.FC<IPropSlider> = React.memo(
  ({ className, dataAttr }) => {
    return (
      <div css={propSliderStyles} className={className} {...dataAttr}>
        <Slider value={50} onChange={() => {}} min={0} max={100} />
      </div>
    )
  },
)

PropSlider.displayName = 'PropSlider'
