import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import {
  ListItemImageTypes,
  ListLayoutDirections,
  ListTypes,
  Sides,
} from 'src/types/api/enums'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  addComponentFixQueue,
  componentsUpdate,
  setSaveState,
} from 'src/store'
import { ComponentServices, StringServices } from 'src/services'
import { IList } from '../../types'
import { bulletListItemStyles, bulletListStyles } from './styles'
import { ItemBullet } from '../ItemBullet/ItemBullet'
import { ItemText } from '../ItemText'
import { ItemMedia } from '../ItemMedia'
import { APP_CONFIG, autoLayoutRules } from 'src/config'
import { CANVAS_TYPE } from 'src/components/canvas/types'
import { useActiveCanvas, useCanvas, useDebouncer } from 'src/hooks'

export const ListView: React.FC<IList> = React.memo(
  ({ data, overrides, canvasType, scale, slideDataId }) => {
    const dispatch = useDispatch()
    const { autoResizeNeeded } = useActiveCanvas({})

    const { isComponentFixing } = useCanvas()

    const componentData = useMemo(
      () => data.data as ComponentListDataSchema,
      [data],
    )

    const listItems = useMemo(() => componentData.listItems, [componentData])

    const isBigMedia = useMemo(
      () =>
        listItems.length <
        APP_CONFIG.editPage.component.list.media.bigVersionCount,
      [listItems.length],
    )

    const layout = useMemo(
      () => componentData.style.layout,
      [componentData.style.layout],
    )

    const direction = useMemo(
      () => layout.direction || ListLayoutDirections.HORIZONTAL,
      [layout],
    )

    const division = useMemo(() => {
      if (layout.auto) {
        return (
          autoLayoutRules[ListTypes.BULLET][direction][listItems.length] ||
          Math.ceil(listItems.length / 2)
        )
      }

      return layout.division
    }, [layout, direction, listItems.length])

    const counterDivision = useMemo(() => {
      return Math.ceil(listItems.length / division)
    }, [division, listItems])

    const isLogo = useMemo(() => {
      return listItems?.[0]?.image?.type === ListItemImageTypes.LOGO
    }, [listItems])

    const frameType = useMemo(
      () => componentData.style.iconRadiusType,
      [componentData],
    )

    const listAreaRef = useRef<HTMLDivElement>(null)

    const [fitRatio, setFitRatio] = useState(0)

    const addFixRequestToQueue = useCallback(() => {
      const newValue = parseInt(overrides?.fontSize) / Math.sqrt(fitRatio || 1)

      const newFontSize = (
        parseFloat(overrides?.fontSize) / Math.sqrt(fitRatio || 1)
      ).toFixed(2)
      const newBodyFontSize = (
        parseFloat(overrides?.fontBodySize) / Math.sqrt(fitRatio || 1)
      ).toFixed(2)

      if (slideDataId && newValue) {
        dispatch(
          addComponentFixQueue({
            slideDataId,
            data,
            updateData: {
              style: {
                font: { size: `${newFontSize}em` },
                fontBody: { size: `${newBodyFontSize}em` },
              },
            },
          }),
        )
      }
    }, [fitRatio, overrides?.fontSize, slideDataId, data])

    const fixRequestDebounce = useDebouncer(
      () => {
        addFixRequestToQueue()
      },
      { delay: 1 },
    )

    useLayoutEffect(() => {
      if (autoResizeNeeded && canvasType === CANVAS_TYPE.SLIDE_CARD) {
        const currentHeight = data.positions.height || 1
        const contentHeight =
          (listAreaRef.current?.getBoundingClientRect()?.height || 1) /
          (scale || 1)
        const calcFitRatio = contentHeight / currentHeight
        setFitRatio(calcFitRatio)
      }
    }, [scale, data.positions.height])

    useLayoutEffect(() => {
      if (fitRatio > 1.01) {
        fixRequestDebounce()
      }
    }, [fitRatio])

    const updateBoxHeight = useCallback(() => {
      const currentHeight = data.positions.height || 1
      const contentHeight =
        (listAreaRef.current?.getBoundingClientRect()?.height || 1) /
        (scale || 1)
      if (contentHeight > currentHeight) {
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponent<UpdateComponentSchema>({
            components: [data],
            partialUpdate: {
              positions: {
                height: contentHeight,
              },
            },
          })
        dispatch(
          componentsUpdate({
            components: updatedComponents,
            isAutoProcess: true,
          }),
        )
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      }
    }, [listAreaRef.current, scale, data])

    useLayoutEffect(() => {
      if (!isComponentFixing && canvasType === CANVAS_TYPE.DND) {
        updateBoxHeight()
      }
    }, [componentData, componentData.style.font])

    const mediaWidth = useMemo(() => {
      if (isBigMedia) {
        return {
          width: `360px`,
          height: `320px`,
        }
      } else {
        return {
          width: `240px`,
          height: `220px`,
        }
      }
    }, [isBigMedia])

    const mediaDescTopGap = useMemo(() => {
      if (componentData.listType === ListTypes.MEDIA) {
        return `16px`
      }
    }, [componentData.listType])

    return (
      <div css={bulletListStyles({ direction })} ref={listAreaRef}>
        {listItems.map(({ title, body, image }, index) => {
          return (
            <div
              key={index}
              css={bulletListItemStyles({
                division,
                counterDivision,
                direction,
                mediaSide: componentData.style.layout.mediaPlace || Sides.LEFT,
              })}
              onClick={() => {
                document.dispatchEvent(
                  new CustomEvent('list-item-click', { detail: index }),
                )
              }}
              style={{
                padding: `calc(${overrides?.fontSize} / 2)` || '',
                order:
                  direction === ListLayoutDirections.VERTICAL
                    ? Math.floor(index / division) +
                      (index % division) * counterDivision
                    : index,
              }}
            >
              {(componentData.listType === ListTypes.BULLET ||
                componentData.listType === ListTypes.NUMBER) && (
                <ItemBullet
                  size={
                    overrides?.fontSize || componentData.style.font.size || ''
                  }
                  color={
                    componentData?.style.colorBullet?.text?.colors[0] ||
                    componentData.style.colorIcon.text.colors[0]
                  }
                  number={
                    componentData.listType === ListTypes.NUMBER
                      ? index + 1
                      : undefined
                  }
                />
              )}

              {componentData.listType === ListTypes.MEDIA && (
                <ItemMedia
                  image={image}
                  width={mediaWidth.width}
                  height={mediaWidth.height}
                  fontSize={
                    overrides?.fontSize || componentData.style.font.size
                  }
                  frameType={frameType}
                  color={componentData.style.colorIcon.text}
                  background={componentData.style.colorIcon.background}
                  fitCrop={isLogo}
                />
              )}

              {!isLogo && (
                <div>
                  <ItemText
                    text={title}
                    fontFamily="var(--font-primary)"
                    fontWeight="var(--font-primary-weight)"
                    fontSize={
                      overrides?.fontSize || componentData.style.font.size || ''
                    }
                    color={componentData.style.color.text}
                  />
                  {StringServices.getTextContent(body) && (
                    <ItemText
                      text={body}
                      fontFamily="var(--font-secondary)"
                      fontWeight="var(--font-secondary-weight)"
                      fontSize={
                        overrides?.fontBodySize ||
                        componentData.style.fontBody.size ||
                        ''
                      }
                      topGap={mediaDescTopGap}
                      color={componentData.style.colorBody.text}
                    />
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  },
)
