import { IApiEnums } from 'src/i18n/types'
import {
  AICreditSpendReasonNames,
  PlanNames,
  PlanPeriods,
  UserPlanPaymentStates,
  UserLanguages,
} from 'src/types/api/enums'

export const apiEnums: IApiEnums = {
  [`enum.planNames.${PlanNames.PRO}`]: 'pro',
  [`enum.planNames.${PlanNames.BUSINESS}`]: 'entreprise',
  [`enum.planPeriods.${PlanPeriods.MONTHLY}`]: 'mensuel',
  [`enum.planPeriods.${PlanPeriods.ANNUAL}`]: 'annuel',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_DECK}`]:
    'Créer un deck IA',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_PDF_DECK}`]:
    'Créer un deck PDF',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_ICON}`]:
    "Suggestion d'icône",
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_IMAGE}`]:
    "Suggestion d'image",
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_LIST_ITEM}`]:
    "Suggestion d'élément de liste",
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_TIPS}`]:
    'Créer des conseils',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_QUESTIONS_ANSWERS}`]:
    'Générer des Q&R',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_SINGLE_SLIDE_NOTES}`]:
    'Créer des notes pour une seule diapositive',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.GENERATE_MULTIPLE_SLIDE_NOTES}`]:
    'Créer des notes pour plusieurs diapositives',
  [`enum.aiCreditSpendReasons.${AICreditSpendReasonNames.AI_TEXT_EDIT}`]:
    'Édition de texte IA',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED}`]:
    'Action de paiement requise',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_PENDING}`]:
    'Paiement en attente',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_FAILED}`]:
    'Annulé',
  [`enum.userPlanPaymentState.${UserPlanPaymentStates.PAYMENT_SUCCESS}`]:
    'Actif',
  [`enum.language.${UserLanguages.EN_US}`]: 'Anglais',
  [`enum.language.${UserLanguages.TR_TR}`]: 'Turc',
}
