import React, { cloneElement } from 'react'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { v4 as uuidv4 } from 'uuid'
import { ITooltip, TOOLTIP_PLACE, TOOLTIP_THEME } from './types'
import { tooltipStyles } from './styles'

export const Tooltip: React.FC<ITooltip> = React.memo(
  ({
    theme = TOOLTIP_THEME.LIGHT,
    text,
    width = 220,
    height,
    place = TOOLTIP_PLACE.BOTTOM,
    children,
    className,
    dataAttr,
  }) => {
    const uniqueId = uuidv4()

    const clonedElement =
      children &&
      cloneElement(children, {
        'data-tooltip-id': uniqueId,
      })

    const tooltipUniqueId = `[data-tooltip-id = '${uniqueId}']`

    return (
      <div {...dataAttr} className={className}>
        {clonedElement}
        <ReactTooltip
          css={tooltipStyles({ width, height })}
          anchorSelect={tooltipUniqueId}
          content={text}
          place={place}
          variant={theme}
        />
      </div>
    )
  },
)
