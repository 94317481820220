export const texts = {
  // Común
  '': '',
  'common.decktopus': 'Decktopus',
  'common.decks': 'Presentaciones',
  'common.free': 'Gratis',
  'common.description': 'Descripción',
  'common.name': 'Nombre',
  'common.email': 'correo electrónico',
  'common.full_name': 'nombre completo',
  'common.role': 'Rol',
  'common.dashboard': 'Tablero',
  'common.analytics': 'Analíticas',
  'common.responses': 'Respuestas',
  'common.read_only': 'Solo lectura',
  'common.read_write': 'Lectura y escritura',
  'common.last': 'Último',
  'common.first': 'Primero',
  'common.title_asc': 'Título (A-Z)',
  'common.title_desc': 'Título (Z-A)',
  'common.recent': 'Modificado recientemente',
  'common.oldest': 'Modificado más antiguo',
  'common.personal': 'Personal',
  'common.date': 'Fecha',
  'common.auto': 'Automático',
  'common.rate': 'Tasa',
  'common.text': 'Texto',
  'common.value': 'Valor',
  'common.empty': 'Vacío',
  'common.or': 'o',
  'common.and': 'y',
  'common.email_address': 'Dirección de correo electrónico',
  'common.password': 'Contraseña',
  'common.organization': 'Organización',
  'common.free_uppercased': 'GRATIS',
  'common.design': 'Diseño',
  'common.access': 'Acceso',
  'common.required': 'Requerido',
  'common.button': 'Botón',
  'common.template_name': 'Nombre de la plantilla',
  'common.language': 'Idioma',
  'common.texts': 'Textos',

  'common.files': '{{count}} archivos',
  'common.files_zero': '{{count}} archivo',
  'common.files_one': '{{count}} archivo',
  'common.files_other': '{{count}} archivos',

  'common.slides': '{{count}} diapositivas',
  'common.slides_zero': '{{count}} diapositiva',
  'common.slides_one': '{{count}} diapositiva',
  'common.slides_other': '{{count}} diapositivas',

  'common.actions.change': 'Cambiar',
  'common.actions.close': 'Cerrar',
  'common.actions.complete': 'Completar',
  'common.actions.back': 'Atrás',
  'common.actions.reset': 'Reiniciar',
  'common.actions.delete': 'Eliminar',
  'common.actions.create': 'Crear',
  'common.actions.share': 'Compartir',
  'common.actions.search': 'Buscar',
  'common.actions.search_with_name': 'Buscar {{name}}',
  'common.actions.check': 'Comprobar',
  'common.actions.connect': 'Conectar',
  'common.actions.export': 'Exportar',
  'common.actions.download': 'Descargar',
  'common.actions.sort_by': 'Ordenar por',
  'common.actions.edit': 'Editar',
  'common.actions.save': 'Guardar',
  'common.actions.cancel': 'Cancelar',
  'common.actions.add': 'Añadir',
  'common.actions.remove': 'Eliminar',
  'common.actions.rename': 'Renombrar',
  'common.actions.discard': 'Descartar',
  'common.actions.approve': 'Aprobar',
  'common.actions.next': 'Siguiente',
  'common.actions.move_to': 'Mover a',
  'common.actions.play': 'Reproducir',
  'common.actions.duplicate': 'Duplicar',
  'common.actions.activate': 'Activar',
  'common.actions.save_changes': 'Guardar cambios',
  'common.actions.paste': 'Pegar',
  'common.actions.upload': 'Subir',
  'common.actions.submit': 'Enviar',
  'common.actions.listen_voice': 'Escuchar voz',
  'common.actions.record_voice': 'Grabar voz',
  'common.actions.lets_start': 'Vamos a empezar',
  'common.actions.leave': 'Dejar',
  'common.actions.upload_picture': 'Subir imagen',
  'common.actions.remove_picture': 'Eliminar imagen',
  'common.actions.select': 'Seleccionar',
  'common.actions.preview': 'Vista previa',

  'common.informative.copied': '¡Copiado!',
  'common.informative.click_here': 'haz clic aquí.',
  'common.informative.coming_soon': 'Próximamente',
  'common.informative.fail': 'Error',
  'common.informative.processing': 'Procesando',
  'common.informative.thanks': '¡Gracias!',
  'common.informative.done': 'Hecho',
  'common.informative.payment_successful': 'Pago exitoso',
  'common.informative.your_deck_is_ready': 'Tu presentación está lista.',
  'common.informative.ai_loading_text':
    'Creando tu obra maestra, un momento...',
  'common.informative.ai_error_text':
    'Estamos experimentando una demanda excepcionalmente alta.',
  'common.informative.wait_on_dashboard': 'Esperar en el panel',
  'common.informative.back_to_first': 'Volver al primero',
  'common.informative.ai_high_demand_text':
    'No se pudo crear tu presentación. Estamos experimentando una demanda excepcionalmente alta.',
  'common.informative.error_start_recording': 'Error al iniciar la grabación:',
  'common.informative.error_stop_recording': 'Error al detener la grabación:',
  'common.informative.error_changing_language':
    'Hubo un problema al cambiar el idioma, por favor contacta con nuestro soporte.',
  'common.informative.large_media_file':
    '¡Ups! Tu archivo multimedia es mayor de 5MB. Por favor selecciona un archivo más pequeño para una experiencia más fluida.',
  'common.informative.captcha_error':
    'La verificación de captcha falló, por favor intenta nuevamente.',
  'common.informative.captcha_expired':
    'El captcha ha expirado, por favor intenta nuevamente.',
  'common.informative.captcha_challange_expired':
    'El desafío de captcha ha expirado, por favor intenta nuevamente.',

  'common.directions.left': 'Izquierda',
  'common.directions.middle': 'Centro',
  'common.directions.right': 'Derecha',
  'common.directions.up': 'Arriba',
  'common.directions.center': 'Centro',
  'common.directions.down': 'Abajo',

  'common.nth': '{{count}}º',
  'common.nth_one': '{{count}}º',
  'common.nth_two': '{{count}}º',
  'common.nth_three': '{{count}}º',
  'common.nth_other': '{{count}}º',

  'common.files.media': 'Medios',
  'common.files.video': 'video',
  'common.files.image': 'Imagen',
  'common.files.gif': 'Gif',
  'common.files.icon': 'Ícono',
  'common.files.powerpoint': 'PowerPoint',
  'common.files.pdf': 'PDF',

  'common.roles.owner': 'Propietario',
  'common.roles.admin': 'Administrador',
  'common.roles.member': 'Miembro',
  'common.roles.pending': 'Pendiente',

  'common.plan_period.monthly': 'Mensual',
  'common.plan_period.annually': 'Anual',

  'common.plan_card.month': ' / mes',

  'common.seats': 'asiento',
  'common.seats_zero': 'asiento',
  'common.seats_one': 'asiento',
  'common.seats_other': 'asientos',

  'common.deck.add_slide': 'Añadir diapositiva',
  'common.deck.duplicate_slide': 'Duplicar diapositiva',
  'common.deck.delete_slide': 'Eliminar diapositiva',
  'common.deck.undo': 'Deshacer',
  // Final de Común

  // Página de inicio de sesión
  'login.welcome': '¡Bienvenido!',
  'login.session_expired':
    'La sesión ha expirado, por favor inicia sesión nuevamente.',
  'login.continue_with_google': 'Continuar con Google',
  'login.login': 'Iniciar sesión',
  'login.logout': 'Cerrar sesión',
  'login.signup': 'Registrarse',
  'login.dont_have_an_account': '¿No tienes una cuenta?',
  'login.forgot_password': '¿Olvidaste tu contraseña?',
  'login.title1': '2 Mn',
  'login.text1': 'Usuarios en todo el mundo',
  'login.title2': '64%',
  'login.text2': 'Tiempo ahorrado preparando presentaciones con IA',
  'login.title3': '412k',
  'login.text3': 'Presentaciones promedio creadas con Decktopus',
  'login.title4': '<1 min',
  'login.text4': 'Tiempo promedio de respuesta del soporte al cliente',
  'login.incorrect_provider_jwt':
    'Parece que ya te has registrado con un correo electrónico y una contraseña. Por favor, inicia sesión usando esas credenciales.',
  'login.incorrect_provider_google':
    'Parece que ya te has registrado con una cuenta de Google. Por favor, intenta iniciar sesión con Google para continuar.',
  // Final de página de inicio de sesión

  // Página caducada
  'expired.get_started': 'Empezar',
  'expired.expire_link_title': 'Este enlace ha caducado',
  'expired.expire_link_description':
    'Este enlace ya no es válido. Ponte en contacto con tu organización para obtener más información.',
  // Final de la página caducada

  // Página de registro
  'signup.terms': 'Términos',
  'signup.privacy_policy': 'Política de privacidad',
  'signup.full_name': 'Nombre completo',
  'signup.already_member': '¿Ya eres miembro?',
  'signup.create_account': 'Crear cuenta',
  'signup_page_title': 'Crea tu cuenta y desbloquea tu presentación',
  'signup.create_account_info':
    'Crear una cuenta significa que estás de acuerdo con nuestros <0>términos</0> y <1>privacidad</1>',
  'signup.banner': 'Estás a un clic de tu presentación',
  // Final de la página de registro

  // Restablecer contraseña
  'reset.password_reset': 'Restablecer contraseña',
  'reset.back_to': 'Volver a',
  'reset.send_email': 'Enviar correo electrónico',
  'reset.resend': 'Reenviar correo electrónico',
  'reset.new_password': 'Nueva contraseña',
  'reset.password_set':
    'Tu contraseña ha sido cambiada, por favor inicia sesión.',
  'reset.reset_password': 'Restablecer contraseña',
  'reset.reset_password_info':
    'Ingresa tu correo electrónico, y te enviaremos las instrucciones para restablecer tu contraseña',
  'reset.password_reset_sent':
    'El correo electrónico para restablecer tu contraseña ha sido enviado',
  'reset.change_your_password_info':
    'Haz clic en el botón para restablecer tu contraseña',
  // Final de restablecer contraseña

  // Páginas comunes de autenticación
  'auth.site_protection':
    'Este sitio está protegido por hCaptcha y su <0>Política de Privacidad</0> y <1>Términos de Servicio</1> aplican.',
  'auth.terms_of_service': 'Términos de servicio',
  // Final de las páginas comunes de autenticación

  // Página no soportada
  'not_supported': 'no soportado',
  'not_supported.title':
    'Desafortunadamente, el modo de edición aún no es compatible con este dispositivo. Para obtener la mejor experiencia, cambia a una computadora de escritorio.',
  //

  // Webhook
  'webhook.endpoint_url': 'Tu URL de punto final',
  'webhook.event_form_responses': 'Evento: Respuestas del formulario',
  'webhook.webhook_delete_warning':
    '¿Estás seguro de que deseas eliminar permanentemente el webhook “<strong>{{name}}</strong>”?',
  'webhook.url': 'URL',
  'webhook.webhook': 'Webhook',
  'webhook.create_webhook': 'Crear Webhook',
  'webhook.webhook_form_general_info':
    'Crea un webhook y recibe notificaciones en tiempo real cuando alguno de tus decks reciba una respuesta de formulario. Agrega tu URL de punto final y nombra tu nuevo webhook.',
  'webhook.webhook_learn':
    'Para aprender más sobre cómo usar webhooks <0>haz clic aquí.</0>',
  // Final de Webhook

  // Pestañas del panel principal
  'main_panel.admin': 'Admin',
  'main_panel.organization': 'Organización',
  'main_panel.profile_settings': 'Configuración de perfil',
  'main_panel.webhook': 'Webhook',
  'main_panel.refer_and_earn': 'Referir y ganar',
  'main_panel.subscription': 'Suscripción',
  'main_panel.onboarding': 'Onboarding',
  'main_panel.logout': 'Cerrar sesión',
  // Final de las pestañas del panel principal

  // Configuración de perfil
  'profile.change_account_information': 'Cambiar la información de la cuenta',
  'profile.edit_your_name': 'Editar tu nombre',
  'profile.change_your_password': 'Cambiar tu contraseña',
  'profile.update.save_changes': 'Guardar cambios',
  'profile.update.image_upload_success': 'Tu foto de perfil ha cambiado.',
  'profile.update.name_change_success': 'Tu nombre ha cambiado.',
  'profile.update.name_min_char_error':
    'El nombre no puede tener menos de 3 caracteres.',
  'profile.update.image_upload_error': '{{fileType}} no es compatible.',
  'profile.verification_success': 'Has verificado tu cuenta con éxito.',
  // Final de la configuración de perfil

  // Verificar correo electrónico
  'verify.verify_your_email': 'Verifica tu correo electrónico',
  'verify.verify_your_account': 'Verifica tu cuenta',
  'verify.verify_your_email_info':
    'Haz clic en el botón para verificar tu cuenta.',
  'verify.verification_email_sent': 'Correo de verificación enviado.',
  'verify.verification_enter_code': 'Introduce tu código',
  'verify.verification_didnt_reveive_email':
    '¿No recibiste el correo electrónico? <0>enlace</0>',
  'verify.verification_click_to_resend': 'Haz clic para reenviar',
  'verify.verification_email_sent_info':
    'Te enviamos un código de verificación a tu correo electrónico.',
  // Final de la verificación de correo electrónico

  // Validaciones
  'validation.min_char':
    '{{name, capitalFirstLetter}} no puede tener menos de {{value}} caracteres.',
  'validation.max_char':
    '{{name, capitalFirstLetter}} no puede tener más de {{value}} caracteres.',
  'validation.error.required':
    '{{name, capitalFirstLetter}} no puede estar vacío',
  'validation.error.email': 'Dirección de correo electrónico no válida',
  'validation.error.url': 'Dirección URL no válida',
  'validation.error.video_url':
    'URL de video no válida. Por favor escribe un enlace de YouTube o Vimeo.',
  'validation.validation_user_exists': 'Este usuario ya existe',
  // Final de las validaciones

  // Modal de diseño
  'layout.save': 'Guardar diseño',
  'layout.save_new': 'Guardar como nuevo diseño',
  'layout.name': 'Nombre del diseño',
  'layout.blank': 'Vacío',
  // Final del modal de diseño

  // Selector de espacio de trabajo
  'workspace.personal_hub': 'Centro personal',
  // Final del selector de espacio de trabajo

  // Modal de inicio
  'init.category_select_info':
    'Elige una categoría de diseño que se ajuste al concepto de tu presentación',
  'init.see_templates': 'Ver plantillas',
  'init.start_from_scratch': 'Comenzar desde cero',
  'init.use_this_template': 'Usar esta plantilla',

  'init.category0': 'Todo',
  'init.category1': 'Negocios',
  'init.category2': 'Propuesta',
  'init.category3': 'Curso en línea',
  'init.category4': 'Educación',
  'init.category5': 'Comunidad',
  'init.category6': 'Nutrición',
  'init.category7': 'Resumen Ejecutivo',
  'init.category8': 'Estudio de Caso',
  'init.category9': 'Presentación de Inicio',
  // Final del modal de inicio

  // Modal de Crear con IA
  'create_with_ai.generate_presentation': 'Generar presentación',
  'create_with_ai.create_with_ai': 'Crear con IA',
  'create_with_ai.prompt_placeholder':
    '¡Escribe el tema de tu presentación aquí!',
  'create_with_ai.audience_placeholder': 'Escribe tu audiencia',
  'create_with_ai.aim_placeholder': 'Escribe tu objetivo',
  'create_with_ai.prompt_title': '¿De qué trata tu presentación?',
  'create_with_ai.audience_title': '¿Quién es tu audiencia?',
  'create_with_ai.aim_title': '¿Cuál es tu objetivo para esta presentación?',
  'create_with_ai.outline_title':
    'Puedes modificar el esquema o continuar sin cambiarlo.',
  'create_with_ai.theme_title': '¿Qué plantilla te gustaría usar?',
  'create_with_ai.theme_subtitle':
    '¡No te preocupes! Puedes cambiarla en cualquier momento.',
  'create_with_ai.ai_subtitle':
    'Inspira a Deckto con un título. Intenta escribir tu tema de presentación como: “Historia de Roma”',
  'create_with_ai.see_examples': 'Ver ejemplos',
  'create_with_ai.hide_examples': 'Ocultar ejemplos',
  'create_with_ai.import_pdf': 'Importar tu PDF',
  'create_with_ai.pdf_subtitle':
    'Deckto transformará tu documento en una presentación con diapositivas editadas.',
  // Final del modal Crear con IA

  // Página de organización
  'organization.organization_profile_update.image_upload_success':
    'La imagen de la organización ha cambiado.',
  'organization.organization_profile_update.name_and_description_success':
    'Los detalles de la organización han cambiado.',
  'organization.organization_details': 'Detalles de la organización',
  'organization.remaining_seats': 'Asientos restantes',
  'organization.manage_seats': 'Gestionar asientos',
  'organization.used_seats':
    '{{occupiedSeats}} de {{totalSeats}} asientos utilizados',
  'organization.adjust_seat_number': 'Ajustar número de asientos',
  'organization.current_users': 'Usuarios actuales ({{count}}) Costo / 12',
  'organization.added_users': 'Usuarios añadidos ({{count}}) Costo / 12',
  'organization.updated_annual_total':
    'Total anual actualizado {{period}} Costo / 12',
  'organization.first_charge': 'El primer cargo será prorrateado.',
  'organization.first_charge_tooltip':
    'El primer cargo de los asientos adicionales será calculado de acuerdo con el número de días restantes en tu ciclo de facturación actual.',
  'organization.reset_invitation': 'Reenviar invitación',
  'organization.cancel_invitation': 'Cancelar invitación',
  'organization.member_settings': 'Configuración de miembro',
  'organization.add_members': 'Añadir miembros',
  'organization.add_members_title': 'Añadir miembros a tu organización',
  'organization.not_enough_seats':
    'No tienes suficientes asientos para añadir más miembros. Ajusta el número de asientos desde la página de organización para hacer espacio.',
  'organization.add_member_information':
    'Siempre puedes invitar a miembros a tu organización desde la barra derecha en la página “Mi Organización”.',
  'organization.organization-details': 'Detalles de la organización',
  'organization.leave_team_title': 'Dejar el equipo',
  'organization.leave_team_button': 'Dejar la organización',
  'organization.leave_team_dialog_text':
    'Al dejar una organización, ya no podrás acceder a las presentaciones colaboradas.',
  'organization.leave_team_text':
    'Si dejas el equipo, no podrás acceder al contenido de la organización ni a las funciones empresariales.',
  'organization.organization_name_label': 'Nombre de la organización',
  'organization.organization_desc_label': 'Descripción de la organización',
  'organization.organization_team_logo': 'Logotipo del equipo',

  'organization.delete_member': 'Eliminar miembro',
  'organization.delete_member_title':
    '¿Estás seguro de que deseas eliminar permanentemente a “{{name}}”?',
  'organization.delete_member_text':
    '“{{name}}” no podrá ver todas las presentaciones ni los detalles de la organización',

  'organization.resend_info':
    '{{email}} aún no ha aceptado tu invitación. Enviarás la invitación de nuevo como recordatorio.',
  'organization.organization_suspended':
    'Esta organización está suspendida. Por favor, actualiza tu método de pago para resolver el problema.',
  'organization.organization_suspended_member':
    'Esta organización está suspendida. Por favor, contacta con el administrador de la organización para obtener más ayuda.',
  // Final de la página de organización

  // Referir
  'refer.invite_friend': '¡Invita a tu amigo!',
  'refer.invite_friend_text':
    'Ambos tú y tu amigo obtienen <strong>30</strong> créditos.',
  'refer.referred': 'Le enviamos un correo electrónico a tu amigo',
  // Final de Referir

  // Analíticas
  'analytics.no_views_yet': 'Aún no hay vistas',
  'analytics.share_this_deck': 'Comparte esta presentación.',
  'analytics.total_views': '{{view}} Vistas totales',
  'analytics.total_views_one_slide':
    '{{view}} Vistas solo de la primera diapositiva',
  'analytics.chart_description':
    'Este gráfico representa el rendimiento de vistas de tu presentación',
  'analytics.last_time_open': 'Última vez abierta',
  'analytics.views': '{{view}} Vistas',
  'analytics.openedTimes': 'Abierta {{view}} veces',
  // Final de Analíticas

  // Suscripciones
  'subscriptions.upgrade': 'Mejorar',
  'subscriptions.show_more': 'Mostrar más',
  'subscriptions.show_less': 'Mostrar menos',
  'subscriptions.subscription': 'Suscripción',
  'subscriptions.current_plan': 'Plan actual',
  'subscriptions.credit_information': 'Información de créditos',
  'subscriptions.ai_credits': 'Créditos IA',
  'subscriptions.choose_plan': 'Elegir plan',
  'subscriptions.plan_renew_info': 'Tu plan se renueva el {{date}}',
  'subscriptions.plan_cancel_info': 'Tu plan se cancelará el {{date}}',
  'subscriptions.plan_renew_info_free':
    'Para acceder a increíbles funciones de IA, mejora tu cuenta',
  'subscriptions.credit_renew_info': 'Tus créditos se renovarán el {{date}}',
  'subscriptions.credit_cancel_info':
    'Tus créditos podrán ser utilizados hasta el {{date}}',
  'subscriptions.credit_history': 'Historial de créditos',
  'subscriptions.credit_history_info':
    'El historial de créditos refleja el gasto hasta el comienzo del periodo de suscripción',
  'subscriptions.subscription_settings': 'Configuración de suscripción',
  'subscriptions.plan.powerup_info': 'Quiero potenciar mi {{plan_name}}',
  'subscriptions.plan.choose_pro': 'Elegir Pro',
  'subscriptions.plan.choose_business': 'Elegir Business',
  'subscriptions.plan.discount_info': 'Ahorra hasta un 40%',

  'subscriptions.subs_state.pending': 'Pendiente',
  'subscriptions.subs_state.action_required': 'Acción requerida',
  'subscriptions.subs_state.failed': 'Fallida',
  'subscriptions.subs_state.canceled': 'Cancelada',
  'subscriptions.subs_state.active': 'Activa',
  'subscriptions.plan.popular': 'Popular',
  'subscriptions.billed_annually': ' facturado anualmente',
  'subscriptions.update_payment': 'Actualizar método de pago',
  // Final de Suscripciones

  // Modal de mejora
  'upgrade_modal.upgrade_to_business': 'Mejorar a Business',
  'upgrade_modal.upgrade_to_business_text':
    'Mejora tus presentaciones y aumenta tu ROI en presentaciones.',
  'subscriptions.plan.cancel_anytime': 'Cancela en cualquier momento',
  'upgrade_modal.refill_your_credits': 'Recarga tus créditos',
  'upgrade_modal.upgrade_refill_modal_subtitle':
    'Mantén tu impulso. Mejora tu plan para seguir trabajando.',
  'upgrade_modal.upgrade_modal_subtitle':
    'Usa Decktopus como tu copiloto IA para presentaciones.',
  'upgrade_modal.choose_plan': 'Elige un plan',
  // Final del modal de mejora

  // Página del tablero
  'dashboard.create.create_presentation': 'Crear presentación',
  'dashboard.create.start_from_scratch': 'Comenzar desde cero',
  'dashboard.create.create_with_a_template': 'Crear con una plantilla',

  'dashboard.folder.folder': 'Carpeta',
  'dashboard.shared_with_me': 'Compartido conmigo',
  'dashboard.folder.create_new_folder': 'Crear nueva carpeta',
  'dashboard.folder.create_folder': 'Crear carpeta',
  'dashboard.folder.delete_title':
    '¿Estás seguro de que deseas eliminar permanentemente “{{folder_name}}” y {{folder_id}} archivos dentro?',
  'dashboard.folder.delete_text':
    'Los archivos no podrán recuperarse una vez eliminados.',
  'dashboard.folder.folder_is_created': 'La carpeta ha sido creada.',
  'dashboard.folder.folder_deleted': 'La carpeta ha sido eliminada.',
  'dashboard.folder.type_folder_name': 'Escribe el nombre de tu carpeta aquí.',
  'dashboard.folder.rename': 'Renombrar carpeta',
  'dashboard.folder.renamed': 'La carpeta ha sido renombrada.',
  'dashboard.folder.name': 'nombre de la carpeta',

  'dashboard.card.rename_deck': 'Renombrar presentación',
  'dashboard.card.transferring':
    'Tu presentación se está transfiriendo desde la versión antigua...',
  'dashboard.card.migration': 'Migrar',
  'dashboard.card.type_deck_name': 'Escribe el nombre de tu presentación aquí.',
  'dashboard.card.deck_name': 'Nombre de la presentación',
  'dashboard.card.deck_renamed': 'La presentación ha sido renombrada.',
  'dashboard.card.deck_delete_text':
    '¿Estás seguro de que deseas eliminar “{{deckName}}”?',
  'dashboard.card.remove_me_text':
    '¿Estás seguro de que deseas eliminarte de “{{deckName}}”?',
  'dashboard.card.remove_me': 'Eliminarme',
  'dashboard.card.file_not_recover':
    'Los archivos no podrán recuperarse una vez eliminados.',
  'dashboard.card.deck_deleted': 'La presentación ha sido eliminada',
  'dashboard.card.deck_removed': 'Has sido eliminado de la presentación',
  'dashboard.card.publish_template': 'Publicar plantilla',
  'dashboard.card.unpublish_template': 'Despublicar plantilla',

  'deck_not_created': 'No se pudo crear la presentación.',

  'dashboard.search.search_my_decks': 'Buscar mis presentaciones en Decktopus',
  'dashboard.search.results_for': 'Resultados para “{{searchParam}}”:',
  'dashboard.search.no_results_for':
    'No se encontraron resultados que coincidan con <strong>{{ searchParam }}</strong>',
  'dashboard.search.try_another_search':
    'Intenta ajustar tu búsqueda para encontrar lo que estás buscando',
  'dashboard.search.return_dashboard': 'o vuelve al tablero',
  'dashboard.search.search_on_decktopus': 'Buscar plantillas en Decktopus',

  'dashboard.publish.success': 'Tu presentación ha sido publicada con éxito',
  'dashboard.unpublish.success':
    'Tu presentación ha sido despublicada con éxito',
  'dashboard.publish.error': 'No se pudo publicar la presentación',
  'dashboard.unpublish.error': 'No se pudo despublicar la presentación',
  // Final de la página del tablero

  // Respuestas del formulario
  'form_response.form_responses': 'Respuestas del formulario',
  'form_response.table.download_form': 'Descargar formulario',
  'form_response.table.deleted_question': 'Pregunta eliminada',
  'form_response.no_answers_yet': 'Aún no hay respuestas.',
  'form_response.table.avarage_score': 'Puntuación promedio',
  // Final de respuestas del formulario

  // Página de edición
  'edit.component_fix.detail':
    'Se están haciendo los últimos ajustes a tu presentación.',
  'edit.component_fix.info': 'Por favor espera',
  'edit.slide_menu.layout': 'Diseño',

  'edit.create_slide.no_custom_layout_text':
    'Aún no has creado un diseño personalizado.',
  'edit.create_slide.no_shared_layout_text':
    'Aún no tienes ningún diseño compartido.',

  'edit.color_picker.select_color': 'Seleccionar color',
  'edit.color_picker.solid': 'Sólido',
  'edit.color_picker.gradient': 'Degradado',
  'edit.color_picker.theme_colors': 'Colores del tema',
  'edit.color_picker.recent_colors': 'Colores recientes',

  'edit.media.modal.image.unsplash_library': 'Biblioteca Unsplash',
  'edit.media.modal.image.title':
    'Seleccionar imagen de la <0>biblioteca Unsplash</0>',
  'edit.media.modal.title': 'Seleccionar {{type}} de la biblioteca',
  'edit.media.modal.load_more': 'Cargar más',
  'edit.media.modal.no_result': 'Sin resultados',
  'edit.media.modal.initial_text':
    'Por favor, ingresa un texto para buscar {{type}}',
  'edit.media.modal.ai.input': 'descripción',
  'edit.media.modal.select_media': 'Seleccionar {{ type }}',
  'edit.media.modal.search_media': 'Buscar {{ type }}',

  'edit.list.submenu.placeholder': 'multi',
  'edit.list.layout.columns': 'Columnas',
  'edit.list.layout.rows': 'Filas',

  'edit.create_slide.copy_from_another_deck': 'Copiar de otra presentación',
  'edit.header.title': 'Editar componente de cabecera',

  'edit.elements.embeds': 'Incrustaciones',
  'edit.elements.interactive_elements': 'Elementos interactivos',
  'edit.elements.see_all': 'Ver todo',
  'edit.elements.see_less': 'Ver menos',
  'edit.elements.shapes': 'Formas',
  'edit.elements.tooltip_text':
    'Solo se puede agregar 1 formulario a una diapositiva',
  'edit.elements.content_blocks': 'Bloques de contenido',

  'edit.canvas.blank': 'Vacío',
  'edit.canvas.context_menu.bring_forward': 'Traer al frente',
  'edit.canvas.context_menu.send_backward': 'Enviar atrás',
  'edit.canvas.context_menu.bring_to_front': 'Traer al frente',
  'edit.canvas.context_menu.send_to_back': 'Enviar al fondo',
  'edit.canvas.context_menu.send_to_background': 'Enviar al fondo',

  'edit.canvas.context_menu.edit_with_ai': 'Editar con IA',
  'edit.canvas.context_menu.edit_with_ai.summarize': 'Resumir',
  'edit.canvas.context_menu.edit_with_ai.fix': 'Corregir',
  'edit.canvas.context_menu.edit_with_ai.rewrite': 'Reescribir',
  'edit.canvas.context_menu.edit_with_ai.extend': 'Extender',
  'edit.canvas.context_menu.edit_with_ai.other': 'Otro',
  'edit.canvas.context_menu.edit_with_ai.generate': 'Generar',
  'edit.canvas.context_menu.edit_with_ai.prompt_placeholder':
    'Escribe tu solicitud aquí.',
  'edit.canvas.context_menu.edit_with_ai.title':
    'Dale a Decktopus un aviso para mejorar tu contenido seleccionado',
  'edit.canvas.context_menu.edit_with_ai.input_error_message':
    'La descripción no puede tener menos de 3 caracteres.',
  'edit.canvas.context_menu.edit_with_ai.nothing_improved':
    'No había nada que corregir',

  'edit.canvas.paste.processing_image': 'Procesando imagen...',
  'edit.canvas.paste.image_pasted': '¡Imagen cargada!',

  'edit.ai.create_qa_text':
    'Consulta las preguntas y respuestas especializadas para tu presentación. Las preguntas que podrían hacerse durante tu presentación y sus respuestas están en esta sección.',
  'edit.ai.create_qa_button': 'Crear Preguntas y Respuestas',
  'edit.ai.create_tip_text':
    'Los consejos de diapositivas están en esta sección para proporcionar una mejor experiencia de presentación. Sugerencias como romper el hielo, ganchos y estructuras tipo historia están aquí para ayudarte.',
  'edit.ai.create_tip_button': 'Crear Consejos',
  'edit.ai.create_tips_hover':
    'Crea una presentación con IA para usar esta función.',

  'edit.canvas_sidebar.start_show': 'Iniciar presentación',
  'edit.canvas_sidebar.start_voice': 'Iniciar voz',
  'edit.canvas_sidebar.swap_colors': 'Intercambiar colores',

  'edit.design.customize_design': 'Personalizar diseño',
  'edit.design.font_family': 'Familia de fuentes',
  'edit.design.default_fonts_delete':
    'No se pueden eliminar las fuentes predeterminadas.',
  'edit.design.upload_custom_font': 'Subir fuente personalizada',
  'edit.design.upload_your_font': 'Sube tu fuente aquí',
  'edit.design.allowed_fonts_text':
    'Por favor, sube solo fuentes en formato .ttf y .otf.',
  'edit.design.custom_font_responsibility':
    'Toda la responsabilidad por el uso comercial de las fuentes subidas corresponde al usuario. Decktopus no asume ninguna responsabilidad por el uso comercial.',
  'edit.design.upload_primary_font': 'Subir fuente primaria',
  'edit.design.upload_secondary_font': 'Subir fuente secundaria',
  'edit.design.color_palette': 'Paleta de colores',
  'edit.design.default_color_palettes':
    'No se pueden eliminar las paletas predeterminadas.',

  'edit.preferences.background_color': 'Color de fondo',
  'edit.preferences.background_tooltip':
    'Selecciona una nueva decoración abajo para cambiar el fondo.',
  'edit.preferences.background_decor': 'Decoración de fondo',

  'edit.properties.list.submenu.title': 'Propiedades de texto e ícono',
  'edit.properties.list.new_list.add_new': 'Agregar nuevo',

  'edit.properties.quote.alignment': 'Alineación de cita',
  'edit.properties.quote.placeholder': 'Cita',
  'edit.properties.quote.author': 'Autor',
  'edit.properties.quote.size.label.text': 'Texto de la cita',
  'edit.properties.quote.size.label.author': 'Autor',

  'edit.properties.media.image_props': 'Propiedades de la imagen',
  'edit.properties.media.image_opacity': 'Opacidad',
  'edit.properties.media.corner_radius': 'Radio de las esquinas',

  'edit.properties.icon.styles': 'Estilos',
  'edit.properties.icon.icon': 'Ícono',
  'edit.properties.icon.background': 'Fondo',

  'edit.properties.data': 'Editar datos',
  'edit.properties.table': 'Editar tabla',
  'edit.properties.show_header_column': 'Mostrar columna de encabezado',
  'edit.properties.show_header_row': 'Mostrar fila de encabezado',
  'edit.properties.form.submenu.title': 'Propiedades del formulario',
  'edit.properties.form.button_text': 'Texto del botón',
  'edit.properties.form.placeholder_text': 'Texto del marcador de posición',
  'edit.properties.form.question_text': 'Texto de la pregunta',
  'edit.properties.form.choice': 'Opción',
  'edit.properties.form.header': 'Añadir aquí tipo de formulario',
  'edit.properties.form.header.button_text': 'Texto del botón',
  'edit.properties.form.option_list': 'Lista de opciones',
  'edit.properties.form.check_list': 'Lista de verificación',
  'edit.properties.form.free_text': 'Texto libre',
  'edit.properties.form.submitting_required_tooltip':
    'Si esto está activado, el espectador no puede ver las siguientes diapositivas hasta que envíe este formulario',

  'edit.properties.button_props.paste_url': 'Pegar la URL de un sitio web.',
  'edit.properties.button_props.outlined': 'Con contorno',

  'edit.properties.chart_props.show_grid': 'Mostrar líneas de la cuadrícula',
  'edit.properties.chart_props.show_legends': 'Mostrar leyendas',
  'edit.properties.chart_props.settings': 'Configuraciones',
  'edit.properties.chart_props.opacity': 'Opacidad de fondo',
  'edit.properties.chart_props.background_color': 'Color de fondo',

  'edit.properties.chart_title.placeholder': 'Título del gráfico',
  'edit.properties.edit_data.error': 'no es un número',

  'edit.properties.x_axis.show_x_axis': 'Mostrar eje X',
  'edit.properties.x_axis.x_axis': 'Eje X',
  'edit.properties.y_axis.show_y_axis': 'Mostrar eje Y',
  'edit.properties.y_axis.y_axis': 'Eje Y',

  'edit.properties.form_choice.choice': 'Opción ',

  'edit.properties.form_props.question': 'Pregunta ',
  'edit.properties.form_props.description': 'Descripción',
  'edit.properties.form_props.choice_1': 'Opción 1',
  'edit.properties.form_props.choice_2': 'Opción 2',
  'edit.properties.form_props.choice_3': 'Opción 3',

  'edit.properties.form_question_option.add_choice': 'Añadir opción',
  'edit.properties.form_question_rate.choose_icon': 'Elegir estilo de ícono',

  'edit.properties.device.header': 'Enlace',
  'edit.properties.device.change_device': 'Cambiar dispositivo',
  'edit.properties.device.laptop': 'Portátil',
  'edit.properties.device.tablet': 'Tableta',
  'edit.properties.device.phone': 'Teléfono',

  'edit.properties.chart.chart_data': 'Tipo de gráfico:',

  'edit.properties.shape.placeholder': 'Forma',
  'edit.properties.shape.distort': 'Distorsionar',

  'edit.properties.ai_image.create': 'Crear con IA',
  'edit.properties.ai_image.generate_with_ai': 'Generar una imagen con IA',
  'edit.properties.ai_image.describe': 'Describe tu solicitud a la IA',
  'edit.properties.ai_image.generate': 'Generar',

  'edit.properties.table.placeholder': 'Configuraciones',

  'edit.properties.text_size.placeholder': 'Múltiple',
  'edit.properties.text_size.big_text': 'Texto grande',
  'edit.properties.text_size.title': 'Título',
  'edit.properties.text_size.subtitle': 'Subtítulo',
  'edit.properties.text_size.body': 'Cuerpo',
  'edit.properties.text_size.label': 'Etiqueta',

  'edit.properties.text_props.header': 'Texto',
  'edit.properties.text_props.reset': 'Restablecer',
  'edit.properties.text_props.fill': 'Relleno',
  'edit.properties.text_props.opacity': 'Opacidad',

  'edit.properties.video_props.video': 'Video',
  'edit.properties.video_props.paste': 'Pegar el enlace de YouTube o Vimeo.',

  'edit.properties.website_props.website': 'Sitio web',
  'edit.properties.website_props.paste': 'Pegar una URL de sitio web.',

  'edit.properties.alignment.position': 'Posición',

  'edit.properties.list.list_type': 'Tipo de lista',
  'edit.properties.list.list_direction': 'Dirección',
  'edit.properties.list.media_position': 'Posición del medio',
  'edit.properties.list.layout': 'Diseño',
  'edit.properties.list.colors': 'Colores',
  'edit.properties.list.font_size': 'Tamaño de fuente',
  'edit.properties.list.size': 'Tamaño',
  'edit.properties.list.icon_frame': 'Marco del ícono',
  'edit.properties.list.color.icon': 'Ícono',
  'edit.properties.list.color.icon_frame': 'Marco',
  'edit.properties.list.color.number': 'Número',
  'edit.properties.list.color.bullet': 'Viñeta',
  'edit.properties.list.font_title': 'Título',
  'edit.properties.list.color.title': 'Título',
  'edit.properties.list.color.body': 'Cuerpo',
  'edit.properties.list.font_body': 'Cuerpo',
  'edit.properties.list.font_logo': 'Logotipo',

  'edit.properties.form.form_properties': 'Propiedades del formulario',
  'edit.properties.form.gdpr_message': 'Mensaje GDPR',
  'edit.properties.form.submitting_required':
    'Es necesario enviar el formulario',
  'edit.properties.form.consent': 'Activar el consentimiento GDPR',
  'edit.properties.form.gdpr_tooltip':
    'Si esto está activado, aparecerá una casilla de verificación sobre el botón de envío.',
  'edit.properties.form.gdpr_prop_placeholder':
    'Marcador de posición para entrada de texto libre',
  'edit.properties.form.option_list_placeholder':
    'Marcador de posición de lista de opciones',
  'edit.properties.form.rate_placeholder': 'Marcador de posición de valoración',
  'edit.properties.form.back_button_text': 'Texto del botón de retroceso',
  'edit.properties.form.next_button_text': 'Texto del botón de siguiente',
  'edit.properties.form.submit_button_text': 'Texto del botón de envío',
  'edit.properties.form.submit_message': 'Mensaje de envío',
  'edit.properties.font.select_option': 'Seleccionar opción',

  'edit.add_new_slide_modal.slide_layouts': 'Diseños de diapositivas',
  'edit.add_new_slide_modal.custom_layouts': 'Diseños personalizados',
  'edit.add_new_slide_modal.search_slide_type':
    'Buscar un tipo de diapositiva en tu biblioteca de diseños, ej. “Imagen”',

  'edit.panel.menu_tabs.slide_notes': 'Notas de diapositiva',
  'edit.panel.menu_tabs.transcripts': 'Transcripciones',
  'edit.panel.menu_tabs.tips': 'Consejos',
  'edit.panel.menu_tabs.qa': 'Preguntas y Respuestas',
  'edit.panel.menu_tabs.themes': 'Temas',
  'edit.panel.menu_tabs.style': 'Estilo',
  'edit.panel.menu_tabs.colors': 'Colores',

  'edit.panel.background': 'Fondo',
  'edit.panel.elements': 'Elementos',
  'edit.panel.notes': 'Notas',
  'edit.panel.ai_assistant': 'Asistente IA',
  'edit.panel.brand': 'Marca',
  'edit.panel.storage': 'Almacenamiento',

  'edit.slide_menu.warning': 'Advertencia',
  'edit.slide_menu.warning_text':
    'Estás a punto de reemplazar un diseño existente, ¿quieres continuar?',
  'edit.slide_menu.replace': 'Reemplazar',

  'edit.properties.zoom_types.fill': 'Rellenar',
  'edit.properties.zoom_types.fit': 'Ajustar',
  'edit.properties.zoom_types.distort': 'Distorsionar',
  'edit.properties.zoom_types.crop': 'Recortar',

  'edit.definitions.bar_chart.display_name': 'Gráfico de barras',
  'edit.definitions.bar_chart.name1': 'Ingeniería',
  'edit.definitions.bar_chart.name2': 'Marketing',
  'edit.definitions.bar_chart.name3': 'Operación',
  'edit.definitions.body.text': 'Nuevo cuerpo',
  'edit.definitions.body.display_name': 'Este es tu cuerpo',
  'edit.definitions.bullet_list.display_name': 'Lista de viñetas',
  'edit.definitions.bullet_list.item_title1': 'Ítem 1',
  'edit.definitions.bullet_list.item_title2': 'Ítem 2',
  'edit.definitions.bullet_list.item_title3': 'Ítem 3',
  'edit.definitions.bullet_list.item_body1': 'Descripción del Ítem 1',
  'edit.definitions.bullet_list.item_body2': 'Descripción del Ítem 2',
  'edit.definitions.bullet_list.item_body3': 'Descripción del Ítem 3',
  'edit.definitions.button.display_name': 'Botón',
  'edit.definitions.button.text': 'Botón',
  'edit.definitions.code.display_name': 'Código',
  'edit.definitions.device.display_name': 'Dispositivo',
  'edit.definitions.donut_chart.display_name': 'Gráfico de rosquilla',
  'edit.definitions.donut_chart.label': 'Serie1',
  'edit.definitions.donut_chart.value1': 'Alfa',
  'edit.definitions.donut_chart.value2': 'Bravo',
  'edit.definitions.donut_chart.value3': 'Charlie',
  'edit.definitions.donut_chart.value4': 'Delta',
  'edit.definitions.donut_chart.value5': 'Echo',
  'edit.definitions.form.display_name': 'Formulario',
  'edit.definitions.form.title': 'Título del formulario',
  'edit.definitions.form.button_text': 'Enviar',
  'edit.definitions.form.answers.text': 'Marcador de texto tipo',
  'edit.definitions.form.answers.option': 'Marcador de opción tipo',
  'edit.definitions.form.answers.rate': 'Marcador de tipo tasa',
  'edit.definitions.form.buttons.next': 'Siguiente',
  'edit.definitions.form.buttons.back': 'Atrás',
  'edit.definitions.form.submit': 'Gracias',
  'edit.definitions.form.gdpr_consent_message': 'Mensaje GDPR',
  'edit.definitions.form.question1': 'Pregunta 1',
  'edit.definitions.form.question2': 'Pregunta 2',
  'edit.definitions.form.question3': 'Pregunta 3',
  'edit.definitions.form.question2.description': 'Descripción',
  'edit.definitions.form.choice1': 'Opción 1',
  'edit.definitions.form.choice2': 'Opción 2',
  'edit.definitions.form.choice3': 'Opción 3',
  'edit.definitions.gif.display_name': 'Gif',
  'edit.definitions.icon.display_name': 'Ícono',
  'edit.definitions.icon_list.display_name': 'Lista de íconos',
  'edit.definitions.icon_list.title1': 'Ítem 1',
  'edit.definitions.icon_list.body1': 'Descripción del Ítem 1',
  'edit.definitions.icon_list.title2': 'Ítem 2',
  'edit.definitions.icon_list.body2': 'Descripción del Ítem 2',
  'edit.definitions.icon_list.title3': 'Ítem 3',
  'edit.definitions.icon_list.body3': 'Descripción del Ítem 3',
  'edit.definitions.image.display_name': 'Imagen',
  'edit.definitions.image_grid.display_name': 'Cuadrícula de imágenes',
  'edit.definitions.image_grid.title1': 'Ítem 1',
  'edit.definitions.image_grid.body1': 'Descripción del Ítem 1',
  'edit.definitions.image_grid.title2': 'Ítem 2',
  'edit.definitions.image_grid.body2': 'Descripción del Ítem 2',
  'edit.definitions.image_grid.title3': 'Ítem 3',
  'edit.definitions.image_grid.body3': 'Descripción del Ítem 3',
  'edit.definitions.image_list.display_name': 'Lista de imágenes',
  'edit.definitions.image_list.title1': 'Ítem 1',
  'edit.definitions.image_list.body1': 'Descripción del Ítem 1',
  'edit.definitions.image_list.title2': 'Ítem 2',
  'edit.definitions.image_list.body2': 'Descripción del Ítem 2',
  'edit.definitions.image_list.title3': 'Ítem 3',
  'edit.definitions.image_list.body3': 'Descripción del Ítem 3',
  'edit.definitions.jumbo.text': 'Nuevo texto Jumbo',
  'edit.definitions.jumbo.display_name': 'Este es un texto grande',
  'edit.definitions.label.text': 'Nueva etiqueta',
  'edit.definitions.label.display_name': 'Esta es tu etiqueta',
  'edit.definitions.line_chart.display_name': 'Gráfico de líneas',
  'edit.definitions.line_chart.label1': 'Línea 1',
  'edit.definitions.line_chart.label1.name1': '09 Feb',
  'edit.definitions.line_chart.label1.name2': '10 Feb',
  'edit.definitions.line_chart.label1.name3': '11 Feb',
  'edit.definitions.line_chart.label1.name4': '12 Feb',
  'edit.definitions.line_chart.label1.name5': '13 Feb',
  'edit.definitions.line_chart.label2': 'Línea 2',
  'edit.definitions.line_chart.label2.name1': '09 Feb',
  'edit.definitions.line_chart.label2.name2': '10 Feb',
  'edit.definitions.line_chart.label2.name3': '11 Feb',
  'edit.definitions.line_chart.label2.name4': '12 Feb',
  'edit.definitions.line_chart.label2.name5': '13 Feb',
  'edit.definitions.links.display_name': 'Enlace',
  'edit.definitions.links.text': 'Texto del botón',
  'edit.definitions.logo.display_name': 'Logotipo',
  'edit.definitions.logo_list.display_name': 'Lista de logotipos',
  'edit.definitions.logo_list.title1': 'Logotipo 1',
  'edit.definitions.logo_list.body1': 'Descripción del Logotipo 1',
  'edit.definitions.logo_list.title2': 'Logotipo 2',
  'edit.definitions.logo_list.body2': 'Descripción del Logotipo 2',
  'edit.definitions.logo_list.title3': 'Logotipo 3',
  'edit.definitions.logo_list.body3': 'Descripción del Logotipo 3',
  'edit.definitions.pie_chart.display_name': 'Gráfico circular',
  'edit.definitions.pie_chart.label': 'Serie 1',
  'edit.definitions.pie_chart.value1': 'Alfa',
  'edit.definitions.pie_chart.value2': 'Bravo',
  'edit.definitions.pie_chart.value3': 'Charlie',
  'edit.definitions.pie_chart.value4': 'Delta',
  'edit.definitions.pie_chart.value5': 'Echo',
  'edit.definitions.quote.display_name': 'Cita',
  'edit.definitions.quote.quote':
    'La imaginación lo es todo. Es el anticipo de las atracciones venideras de la vida.',
  'edit.definitions.quote.author': 'Albert Einstein',
  'edit.definitions.rating.display_name': 'Valoración',
  'edit.definitions.rating.title': 'Título del formulario',
  'edit.definitions.rating.button_text': 'Enviar',
  'edit.definitions.rating.placeholder.text': 'Marcador de texto tipo',
  'edit.definitions.rating.placeholder.options': 'Marcador de opción tipo',
  'edit.definitions.rating.placeholder.rate': 'Marcador de tipo tasa',
  'edit.definitions.rating.next': 'Siguiente',
  'edit.definitions.rating.back': 'Atrás',
  'edit.definitions.rating.submit': 'Gracias',
  'edit.definitions.rating.gdpr_consent': 'Mensaje GDPR',
  'edit.definitions.rating.question_text': '¡Valora tu experiencia!',
  'edit.definitions.rating.question_description': 'Descripción',
  'edit.definitions.subtitle.text': 'Nuevo subtítulo',
  'edit.definitions.subtitle.display_name': 'Este es tu subtítulo',
  'edit.definitions.showcase.display_name': 'Vitrina',
  'edit.definitions.table.display_name': 'Tabla',
  'edit.definitions.table.header1': 'Categorías',
  'edit.definitions.table.header2': 'Valores 1',
  'edit.definitions.table.header3': 'Valores 2',
  'edit.definitions.table.header4': 'Valores 3',
  'edit.definitions.table.header5': 'Valores 4',
  'edit.definitions.table.row1.value1': 'Categorías 1',
  'edit.definitions.table.row1.value2': '30',
  'edit.definitions.table.row1.value3': '40',
  'edit.definitions.table.row1.value4': '50',
  'edit.definitions.table.row1.value5': '60',
  'edit.definitions.table.row2.value1': 'Categorías 2',
  'edit.definitions.table.row2.value2': '10',
  'edit.definitions.table.row2.value3': '20',
  'edit.definitions.table.row2.value4': '30',
  'edit.definitions.table.row2.value5': '40',
  'edit.definitions.table.row3.value1': 'Categorías 3',
  'edit.definitions.table.row3.value2': '7',
  'edit.definitions.table.row3.value3': '23',
  'edit.definitions.table.row3.value4': '100',
  'edit.definitions.table.row3.value5': '70',
  'edit.definitions.table.row4.value1': 'Categorías 4',
  'edit.definitions.table.row4.value2': '97',
  'edit.definitions.table.row4.value3': '2',
  'edit.definitions.table.row4.value4': '13',
  'edit.definitions.table.row4.value5': '55',
  'edit.definitions.timeline.display_name': 'Línea de tiempo',
  'edit.definitions.timeline.item1': 'Ítem 1',
  'edit.definitions.timeline.description1': 'Descripción del Ítem 1',
  'edit.definitions.timeline.item2': 'Ítem 2',
  'edit.definitions.timeline.description2': 'Descripción del Ítem 2',
  'edit.definitions.timeline.item3': 'Ítem 3',
  'edit.definitions.timeline.description3': 'Descripción del Ítem 3',
  'edit.definitions.title.text': 'Nuevo título',
  'edit.definitions.title.display_name': 'Este es tu título',
  'edit.definitions.video.display_name': 'Video',
  'edit.definitions.website.display_name': 'Sitio web',

  'edit.background.options': 'Opciones de fondo',

  'edit.color_mapping.swap_colors': 'Intercambiar colores',
  'edit.color_mapping.restore': 'Restaurar a colores predeterminados',
  'edit.color_mapping.restore_short': 'Restaurar colores',
  'edit.color_mapping.texts': 'Textos',
  'edit.color_mapping.texts.item1': 'Texto grande',
  'edit.color_mapping.texts.item2': 'Título',
  'edit.color_mapping.texts.item3': 'Subtítulo',
  'edit.color_mapping.texts.item4': 'Cuerpo',
  'edit.color_mapping.texts.item5': 'Etiqueta',
  'edit.color_mapping.background': 'Fondo',
  'edit.color_mapping.quote': 'Cita',
  'edit.color_mapping.quote.item1': 'Cita',
  'edit.color_mapping.quote.item2': 'Autor',
  'edit.color_mapping.icons': 'Íconos',
  'edit.color_mapping.icons.item1': 'Ícono',
  'edit.color_mapping.icons.item2': 'Relleno del ícono',
  'edit.color_mapping.lists': 'Listas',
  'edit.color_mapping.lists.item1': 'Viñeta de lista',
  'edit.color_mapping.lists.item2': 'Título de la lista',
  'edit.color_mapping.lists.item3': 'Cuerpo de la lista',
  'edit.color_mapping.table': 'Tabla',
  'edit.color_mapping.table.item1': 'Contenido',
  'edit.color_mapping.table.item2': 'Destacado',
  'edit.color_mapping.buttons': 'Botones',
  'edit.color_mapping.buttons.item1': 'Texto',
  'edit.color_mapping.buttons.item2': 'Relleno del botón',
  'edit.color_mapping.buttons.item3': 'Borde',
  'edit.color_mapping.timeline': 'Línea de tiempo',
  'edit.color_mapping.timeline.item1': 'Barra de la línea de tiempo',
  'edit.color_mapping.timeline.item2': 'Punto de la línea de tiempo',
  'edit.color_mapping.form': 'Formulario',
  'edit.color_mapping.form.item1': 'Pregunta',
  'edit.color_mapping.form.item2': 'Texto del botón',
  'edit.color_mapping.form.item3': 'Relleno del botón',
  'edit.color_mapping.form.item4': 'Ícono',
  'edit.color_mapping.chart': 'Gráfico',
  'edit.color_mapping.chart.items': 'Color',
  'edit.color_mapping.chart.background': 'Fondo del gráfico',
  'edit.color_mapping.text_highlight': 'Resaltar texto',
  'edit.color_mapping.text_highlight.item1': 'Resaltar',
  'edit.color_mapping.text_highlight.item2': 'Fondo resaltado',
  'edit.color_mapping.shape': 'Forma',
  'edit.color_mapping.shape.item1': 'Relleno de forma',
  'edit.color_mapping.decoration': 'Decoraciones',
  'edit.color_mapping.decoration.items': 'Decoración',
  // Final de la página de edición

  // Página de clasificación de usuario
  'user_classify.business': 'Negocios',
  'user_classify.continue_with_decktopus': 'Continuar con Decktopus',
  'user_classify.customer_service': 'Servicio al cliente',
  'user_classify.education': 'Educación',
  'user_classify.educator': 'Educador',
  'user_classify.engineering': 'Ingeniería',
  'user_classify.finance': 'Finanzas',
  'user_classify.friends': 'Amigos',
  'user_classify.google_search': 'Búsqueda en Google',
  'user_classify.hr': 'RRHH',
  'user_classify.legal': 'Legal',
  'user_classify.management': 'Administración',
  'user_classify.other': 'Otro',
  'user_classify.product': 'Producto',
  'user_classify.sales_marketing': 'Ventas y marketing',
  'user_classify.see_other_options': 'Ver otras opciones',
  'user_classify.social_media': 'Redes sociales',
  'user_classify.student': 'Estudiante',
  'user_classify.tell_us_about_yourself': 'Cuéntanos sobre ti',
  'user_classify.classify_question': '¿Con qué propósito usas Decktopus?',
  'user_classify.business_question': '¿Qué tipo de trabajo haces?',
  'user_classify.how_to_hear_about_us': '¿Cómo supiste de nosotros?',
  // Final de la página de clasificación de usuario

  // Modal de compartir
  'share_modal.embed.code': 'Obtener código de inserción',
  'share_modal.share_link': 'Compartir enlace',
  'share_modal.get_share_link': 'Obtener enlace de compartir',
  'share_modal.make_the_share_link_public':
    'Hacer público el enlace de compartir',
  'share_modal.select_from_list': 'Seleccionar de la lista de usuarios',

  'share_modal.share_link_updated':
    'Enlace de compartir actualizado con éxito.',
  'share_modal.invite_people_to_collaborate': 'Invitar a personas a colaborar',
  'share_modal.invitations_sent': 'Invitaciones enviadas',
  'share_modal.organization_deck_permission': 'Permiso de la organización',
  'share_modal.valid_email_message':
    'Por favor ingresa una dirección de correo electrónico válida y presiona enter',
  'share_modal.add_collaborators': 'Añadir colaborador',
  'share_modal.not_collaborating_message':
    'Aún no estás colaborando con nadie.',
  'share_modal.collaborators': 'Colaboradores',
  'share_modal.can_edit': 'Puede editar',
  'share_modal.can_view': 'Puede ver',
  'share_modal.embed_code': 'Código de inserción',
  'share_modal.collaborated_users_updated':
    'Usuarios colaboradores actualizados',
  'share_modal.save_complete': 'Los cambios se han guardado',
  'share_modal.pdf_export': 'Exportar PDF',
  'share_modal.pptx_export': 'Exportar PPTX',
  'share_modal.latest_exports': 'Mis últimas exportaciones',

  'share_modal.custom_domain.status': 'Estado',
  'share_modal.custom_domain.host': 'Host',
  'share_modal.custom_domain.completed': 'COMPLETADO',
  'share_modal.custom_domain.pending': 'PENDIENTE',
  'share_modal.custom_domain.record': 'Registro',
  'share_modal.custom_domain.data': 'Datos',
  'share_modal.custom_domain.cname': 'CNAME',
  'share_modal.custom_domain.share_link_placeholder':
    'ej. https://documento.tudominio.com',
  'share_modal.custom_domain.domain_successfully':
    'Dominio guardado con éxito.',
  'share_modal.custom_domain.domain_deleted': 'Dominio eliminado con éxito.',
  'share_modal.custom_domain.domain_failed':
    'El dominio aún no ha sido verificado. Puede tardar hasta 72 horas dependiendo de tu servidor de dominio',
  'share_modal.custom_domain.dns_record':
    'Para conectar tu dominio personalizado a Decktopus, debes actualizar tus registros DNS según la tabla a continuación.',
  'share_modal.custom_domain.add_your_subdomain': 'Añade tu subdominio:',
  'share_modal.custom_domain.info': 'Información',
  'share_modal.custom_domain.verified': 'Verificado',
  'share_modal.custom_domain.unverified': 'No verificado',
  'share_modal.custom_domain.connect_your_custom_domain':
    'Conecta tu dominio personalizado',
  // Final del modal de compartir

  // Página de administrador
  'admin.menu.user_info': 'Información del usuario',
  'admin.menu.back_to_search': 'Volver a la búsqueda',
  'admin.search.welcome_support_panel':
    'Bienvenido al <strong>Panel de Soporte</strong>',
  'admin.search.you_can_search_by':
    'Puedes buscar por correo electrónico, ID de presentación o clave de compartir para listar lo que necesitas.',
  'admin.search.share_key': 'Clave de compartir',
  'admin.search.deck_id': 'ID de presentación',
  'admin.search.user_not_found': 'Usuario no encontrado.',
  'admin.table.e_mail': 'Correo electrónico',
  'admin.table.delete_user': 'Eliminar usuario',
  'admin.table.created_date': 'Fecha de creación',
  'admin.confirm.cannot_undo_delete_user':
    'No puedes deshacer esta acción. ¿Estás seguro de que deseas eliminar a este usuario?',
  'admin.table.actions': 'Acciones',
  'admin.action_menu.login_as_user': 'Iniciar sesión como usuario',
  'admin.dashboard.header.back_to_support': 'Volver al panel de soporte',
  'admin.dashboard.header.logged_in_as_user':
    'Has iniciado sesión como usuario.',
  // Final de la página de administrador

  // Elementos del menú del componente de tabla
  'table.add_row_above': 'Añadir fila arriba',
  'table.add_row_below': 'Añadir fila abajo',
  'table.add_column_left': 'Añadir columna a la izquierda',
  'table.add_column_right': 'Añadir columna a la derecha',
  'table.delete_row': 'Eliminar fila',
  'table.delete_column': 'Eliminar columna',
  // Final de los elementos del menú del componente de tabla

  // Modal de confirmación de pago
  'payment_confirmation.title': 'Mejora tu plan a {{ planName }}',
  'payment_confirmation.subtitle':
    'Estás a solo un paso de disfrutar de las funciones exclusivas de Decktopus',
  'payment_confirmation.plan': 'Plan:',
  'payment_confirmation.payment_method': 'Método de pago:',
  'payment_confirmation.card_number': 'Número de tarjeta:',
  'payment_confirmation.card_date': 'Fecha de la tarjeta:',
  'payment_confirmation.total': 'Total:',
  'payment_confirmation.contact_us':
    'Si tienes alguna pregunta o necesitas asistencia, no dudes en <0>contactarnos.</0>',
  'payment_confirmation.cancel': 'Cancelar pago',
  'payment_confirmation.confirm': 'Confirmar y pagar',
  'payment_confirmation.seat': 'Asiento',
  'payment_confirmation.pro': 'Pro IA',
  'payment_confirmation.business': 'Business IA',
  'payment_confirmation.remaining_line':
    'Tiempo restante en {{ planName }} después de {{ date }}',
  'payment_confirmation.unused_line':
    'Tiempo no utilizado en {{ planName }} después de {{ date }}',
  'payment_confirmation.total_tooltip':
    'El pago será prorrateado en función de los días restantes en tu ciclo de facturación actual.',
  // Final del modal de confirmación de pago

  'common.history': 'Historial',
  'common.percent': '{{value}}%',
  'common.actions.skip': 'Omitir',
  'common.files.pdf_standard': 'PDF Estándar',
  'common.files.pdf_compressed': 'PDF Comprimido',
  'common.files.ppt_standard': 'PPT Estándar',
  'common.files.ppt_compressed': 'PPT Comprimido',
  'create_with_ai.category_title': 'Elige una categoría para tu presentación.',
  'create_with_ai.category.option1': 'Presentación de Startup',
  'create_with_ai.category.option2': 'Propuesta de Negocio',
  'create_with_ai.category.option3': 'Presentación de Ventas',
  'create_with_ai.category.option4': 'Presentación de Informe',
  'create_with_ai.category.option5': 'Educativa',
  'create_with_ai.category.option6': 'Otra',
  'create_with_ai.strength.low':
    '¡Unas pocas palabras más pueden desbloquear un mundo de ideas más profundas!',
  'create_with_ai.strength.medium':
    'Vas por la mitad, ¡añade un poco más para revelar el panorama completo!',
  'create_with_ai.strength.high':
    '¡Fantástico! Cuanto más contexto proporciones, más precisos serán los resultados.',
  'create_with_ai.strength.done':
    '¡Excelente! ¡Puedes añadir más si lo deseas!',
  'create_with_ai.strength.tooltip':
    'Las indicaciones detalladas conducen a ideas más precisas y relevantes. ¡Proporciona más contexto para obtener los mejores resultados!',
  'file_import.import_file_title': '(Opcional) Sube tus documentos',
  'file_import.import_file_info':
    'Sube cualquier documento para incluir o referenciar en tu presentación.',
  'file_import.use_file_format':
    'No se puede subir el archivo {{fileName}}. Utiliza un archivo en los siguientes formatos: docx, json, md, pdf, pptx, txt',
  'file_import.maximum_files': 'Puedes subir un máximo de 5 archivos',
  'file_import.maximum':
    '¡Has alcanzado el límite máximo de subida de archivos!',
  'file_import.maximum_info': 'Por favor, elimina uno para subir otro.',
  'file_import.drag_and_drop': 'Arrastra tus archivos o explora',
  'file_import.size_error':
    'El archivo {{fileName}} es demasiado grande. Intenta con uno más pequeño.',
  'file_import.already_uploaded':
    'El archivo {{fileName}} ya está subido. Intenta con otro.',
  'file_import.file_state.ready': 'Listo',
  'file_import.file_state.error': 'Error',
  'file_import.file_state.processing': 'Procesando',
  'file_import.file_state.ready_info': 'Archivo subido con éxito.',
  'file_import.file_state.error_info':
    'Lo sentimos, ocurrió un error inesperado. Por favor, intenta subir otro archivo.',
  'file_import.file_state.processing_info':
    'El archivo se está procesando, por favor espera.',
  'subscriptions.change_payment_method': 'Cambiar Método de Pago',
  'subscriptions.update_billing_info': 'Actualizar Información de Facturación',
  'subscriptions.invoices': 'Facturas',
  'subscriptions.cancel_subscription': 'Cancelar Suscripción',
  'subscriptions.renew_subscription': 'Renovar Suscripción',
  'subscriptions.cancel_promo_modal.title': '75% DE DESCUENTO POR 3 MESES',
  'subscriptions.cancel_promo_modal.subtitle':
    'Hemos notado que estás a punto de cancelar tu suscripción',
  'subscriptions.cancel_promo_modal.description':
    'Como usuario valioso, nos gustaría ofrecerte un exclusivo <strong>{{discount}}</strong> en tus próximos {{period}} de suscripción si eliges quedarte con Decktopus.',
  'subscriptions.cancel_promo_modal.cancel': 'Cancelar mi suscripción',
  'subscriptions.cancel_promo_modal.apply': 'Aplicar Descuento',
  'subscriptions.cancel_promo_modal.success':
    'Tu descuento se ha aplicado con éxito',
  'subscriptions.cancel_promo_modal.discount_monthly': '75% de descuento',
  'subscriptions.cancel_promo_modal.discount_annually': '25% de descuento',
  'subscriptions.cancel_promo_modal.period_monthly': '3 meses',
  'subscriptions.cancel_promo_modal.period_annually': 'año',
  'subscriptions.cancel_reason_dialog.title':
    '¿Qué podríamos haber hecho de manera diferente para mejorar tu experiencia?',
  'subscriptions.cancel_reason_dialog.subtitle': 'Mínimo 50 caracteres',
  'subscriptions.cancel_reason_dialog.submit': 'Enviar comentarios',
  'subscriptions.cancel_reason_dialog.input_placeholder':
    'Escribe tu experiencia aquí.',
  'subscriptions.cancel_reason_dialog.input_label': 'Comentarios',
  'subscriptions.cancel_reason_dialog.footer_text':
    'Tu opinión es increíblemente valiosa para nosotros. Nos ayuda a mejorar y crear una mejor experiencia para ti y otros en el futuro. Incluso las ideas más pequeñas pueden marcar una gran diferencia. ¡Gracias por compartir!',
  'edit.properties.chart_props.show_background': 'Mostrar Fondo',
  'share_modal.compressed_export_message':
    'La compresión reduce el tamaño del archivo combinando texto, imágenes y otros componentes en imágenes optimizadas para cada diapositiva, facilitando el almacenamiento y el compartir.',
  'share_modal.include_slide_notes': 'Incluir notas de diapositivas',
  'share_modal.include_slide_notes_info_tooltip':
    'Las notas se añadirán debajo de la miniatura de la página.',
  'ai_loading.text1':
    '¿Sabías que las personas solo recuerdan el 10% de lo que escuchan después de tres días, pero recuerdan el 65% si se añade una imagen?',
  'ai_loading.text2':
    'En promedio, las personas toman una decisión sobre una presentación en los primeros 90 segundos.',
  'ai_loading.text3':
    'Las ayudas visuales en una presentación pueden mejorar el aprendizaje hasta en un 400%.',
  'ai_loading.text4':
    'La presentación más larga jamás realizada duró 124 horas y fue dada por Arvind Mishra en India en 2018.',
  'ai_loading.text5':
    'Usar colores de alto contraste en tus diapositivas puede aumentar la legibilidad hasta en un 78%.',
  'ai_loading.text6':
    '¿Sabías que? El 55% de la comunicación es lenguaje corporal, el 38% es tono de voz, y solo el 7% son las palabras realmente habladas.',
  'ai_loading.text7':
    'La capacidad de atención promedio de una persona es de solo 8 segundos - ¡haz que tus diapositivas cuenten!',
  'ai_loading.text8':
    'Consejo de comunicación: Usa la narración de historias para hacer tus presentaciones más memorables.',
  'ai_loading.text9':
    'Las presentaciones con diapositivas más simples tienden a tener más impacto.',
  'ai_loading.text10':
    'Incorporar historias en tu presentación puede hacerla 22 veces más memorable.',
  'ai_loading.text11':
    'Hacer contacto visual con tu audiencia puede aumentar el compromiso y la confianza.',
  'ai_loading.text12':
    'Las personas solo recuerdan el 10% de lo que escuchan después de tres días, pero esta tasa aumenta al 65% si se añade una imagen.',
  'ai_loading.text13':
    'Las personas deciden sobre una presentación en los primeros 90 segundos.',
  'ai_loading.text14':
    '¡Las ayudas visuales pueden mejorar el aprendizaje hasta en un 400%!',
  'ai_loading.text15':
    'La presentación más larga duró 124 horas y fue realizada por Arvind Mishra en India, 2018.',
  'ai_loading.text16':
    'Los colores de alto contraste en las diapositivas pueden aumentar la legibilidad en un 78%.',
  'ai_loading.text17':
    'El 55% de la comunicación es lenguaje corporal, 38% tono, y 7% palabras.',
  'ai_loading.text18':
    'La capacidad de atención promedio es de 8 segundos - ¡haz que tus diapositivas cuenten!',
  'ai_loading.text19':
    'Consejo: Usa la narración de historias para hacer las presentaciones memorables.',
  'ai_loading.text20':
    'Las diapositivas más simples a menudo tienen un mayor impacto.',
  'ai_loading.text21':
    'Las historias hacen las presentaciones 22 veces más memorables.',
  'ai_loading.text22':
    'El contacto visual aumenta el compromiso y la confianza de la audiencia.',

  // V3 related development texts
  'v3.transparency': 'Transparency',
  'v3.letter_spacing': 'Letter Spacing',
  'v3.line_height': 'Line Height',
  'v3.line_size': 'Line Size',
  'v3.stroke_size': 'Stroke Size',
  'v3.stroke': 'Stroke',
  'v3.fill': 'Fill',
  'v3.group': 'Group',
  'v3.ungroup': 'Ungroup',
  'v3.effects': 'Effects',
  'v3.position': 'Position',
  'v3.present': 'Present',
  'v3.flip': 'Flip',
  'v3.flip_horizontal': 'Flip Horizontally',
  'v3.flip_vertical': 'Flip Vertically',
  'v3.fit': 'Fit to page',
  'v3.apply_mask': 'Apply mask',
  'v3.remove_mask': 'Remove mask',
  'v3.colors': 'Colors',
  'v3.corner_radius': 'Corner radius',
  'v3.trim': 'Trim',
  'v3.preparing': 'Preparing',
  'v3.volume': 'Volume',
  'v3.text.bigtext': 'Big text',
  'v3.text.heading': 'Heading',
  'v3.text.subheading': 'Subheading',
  'v3.text.normaltext': 'Normal text',
  'v3.text.smalltext': 'Small text',
  'v3.text.ai_write': 'AI Write',
  // v3 media uploader
  'v3.media_uploader.file_size_error': 'Tipo de archivo no permitido',
  'v3.media_uploader.file_type_error':
    'El tamaño del archivo debe ser inferior a {{ maxSize }}MB',
  'v3.media_uploader.allowed_extensions_error':
    'Extensiones permitidas: {{ extensions }}',
  'v3.media_uploader.file_upload_error':
    'Error al cargar el archivo. Inténtalo de nuevo más tarde',
  // v3 media uploader ends
  'v3.panel.shapes_lines': 'Shapes and Lines',
  'v3.panel.notes.placeholder': 'Add slide notes...',
  'v3.add_page': 'Add page',
  'v3.delete_page': 'Delete page',
  'v3.duplicate_page': 'Duplicate page',
  'v3.object_locked':
    'Object is locked. Unlock it to allow changes from canvas.',
  'v3.object_unlocked':
    'Object is unlocked. Lock it to prevent changes from canvas.',
  'v3.duplicate_elements': 'Duplicate elements',
  'v3.delete_elements': 'Delete elements',
  'v3.images': 'Images',
  'v3.icons': 'Icons',
  'v3.videos': 'Videos',
  'v3.see_all': 'See all',
  'v3.trending': 'Trending',
  'v3.results': '"{{search}}" results',
  'v3.font': 'Font',
  'v3.style': 'Style',
  'v3.background': 'Background',
  'v3.style.palette': 'Theme palette',
  'v3.style.theme_elements_colors': 'Theme Elements Colors',
  'v3.style.background': 'Background Color',
  'v3.style.icon': 'Icons',
  'v3.style.tableHighlight': 'Table Highlight',
  'v3.style.timeline': 'Timeline Bar and Dot',
  'v3.style.listBullet': 'List Bullet',
  'v3.style.shape': 'Shape and Line Colors',
  'v3.style.builder': 'Style Builder',
  'v3.toolbar.width': 'Width (px)',
  'v3.toolbar.height': 'Height (px)',
  'v3.toolbar.resize': 'Resize',
  'v3.toolbar.x': 'X position',
  'v3.toolbar.y': 'Y position',
  'v3.toolbar.size_and_position': 'Size and Position',
  'v3.activate': 'Activate New Edit Page',
  'v3.activated': 'New editor is active',
  'v3.try_new_editor': 'Try New Editor',
  'v3.revert_editor': 'Use Old Editor',
  'v3.try_new_editor_info':
    'Click here for more detailed information about the new editor.',
  'v3.add_slide.search_placeholder':
    'Search a slide type from our layout library e.g. “Image”',
  'v3.add_slide.all': 'All',
  'v3.add_slide.text_slides': 'Text Slides',
  'v3.add_slide.media': 'Media',
  'v3.add_slide.lists': 'Lists',
  'v3.add_slide.devices': 'Devices',
  'v3.add_slide.timeline': 'Timeline',
  // V3 related development texts ends
}
