import React from 'react'
import { ISeparator } from './types'
import { wrapperStyles } from './styles'

export const Separator: React.FC<ISeparator> = React.memo(
  ({ text, className }) => (
    <div css={wrapperStyles({ text })} className={className}>
      {text && <span>{text}</span>}
    </div>
  ),
)
