import React, { PropsWithChildren } from 'react'
import { RootState } from 'src/store'
import { headerActionsStyles } from './styles'
import { useStyles } from './stylesV3'
import { dataAttrType } from 'src/types'
import { useSelector } from 'react-redux'

interface IHeaderActionsWrapperProps {
  className?: string
  dataAttr?: dataAttrType
}

const HeaderActionsWrapper: React.FC<
  PropsWithChildren<IHeaderActionsWrapperProps>
> = React.memo(({ children, className, dataAttr }) => {
  const { version } = useSelector(({ layouts }: RootState) => ({
    version: layouts.version,
  }))

  return version === 'v3' ? (
    <HeaderActionsV3Wrapper className={className} dataAttr={dataAttr}>
      {children}
    </HeaderActionsV3Wrapper>
  ) : (
    <div css={headerActionsStyles} className={className} {...dataAttr}>
      {children}
    </div>
  )
})

const HeaderActionsV3Wrapper: React.FC<
  PropsWithChildren<IHeaderActionsWrapperProps>
> = ({ children, className, dataAttr }) => {
  const { classes } = useStyles()
  return (
    <div css={classes.headerActionsStyles} className={className} {...dataAttr}>
      {children}
    </div>
  )
}

export default HeaderActionsWrapper
