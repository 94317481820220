import React from 'react'
import { IPropSubMenu } from './types'
import { propSubMenuStyles } from './styles'
import { BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'
import { useLanguage } from 'src/hooks'

export const PropSubMenu: React.FC<IPropSubMenu> = React.memo(
  ({ onBack, title, children }) => {
    const { t } = useLanguage()
    return (
      <div css={propSubMenuStyles}>
        <div className="sub-menu-header">
          <span>{title}</span>
          <Button
            isLink
            theme={BUTTON_THEME.ELEMENTS}
            type={BUTTON_TYPE.GHOST}
            icon={icons.chevron_left}
            text={t('common.actions.back')}
            onClick={onBack}
          />
        </div>
        {children}
      </div>
    )
  },
)
