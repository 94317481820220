import { COLOR } from './types'

export const black = {
  DEFAULT: COLOR.black_100,
  90: COLOR.black_90,
  80: COLOR.black_80,
  70: COLOR.black_70,
  60: COLOR.black_60,
  50: COLOR.black_50,
  40: COLOR.black_40,
  30: COLOR.black_30,
  20: COLOR.black_20,
  10: COLOR.black_10,
}
