import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { IAiLoading } from './types'
import {
  aiLoadingStyles,
  animationStyles,
  buttonWrapperStyles,
  loadingTextStyles,
} from './styles'
import Lottie from 'react-lottie'
import decktoLoading from 'src/assets/lotties/decktoLoading.json'
import { useLanguage } from 'src/hooks'
import { BUTTON_SIZE, BUTTON_TYPE, Button, icons } from 'src/lib'
import { AnimationBar } from 'src/components/animation-bar'
import { aiLoadingTexts } from './consts'
import { AI_FLOW_STEP, RootState } from 'src/store'

export const AiLoading: React.FC<IAiLoading> = React.memo(
  ({ className, dataAttr, onClose }) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: decktoLoading,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }
    const { t, lang } = useLanguage()

    const { aiFlowStep } = useSelector(({ aiFlow }: RootState) => ({
      aiFlowStep: aiFlow.currentStep,
    }))

    const [currentTextIndex, setCurrentTextIndex] = useState(0) // State to keep track of the current text index
    const [isFading, setIsFading] = useState(false) // State to handle fading animation

    const isCompleted = useMemo(
      () => aiFlowStep === AI_FLOW_STEP.COMPLETED,
      [aiFlowStep],
    )

    useEffect(() => {
      const intervalId = setInterval(() => {
        setIsFading(true) // Start fading out
        setTimeout(() => {
          setCurrentTextIndex(
            (prevIndex) => (prevIndex + 1) % aiLoadingTexts(lang).length,
          ) // Change text after fade out
          setIsFading(false) // Start fading in
        }, 1000) // Delay for fade-out duration (1 second)
      }, 7000) // Change text every 7 seconds

      return () => clearInterval(intervalId) // Cleanup interval on unmount
    }, [aiLoadingTexts(lang).length])

    return (
      <div css={aiLoadingStyles} className={className} {...dataAttr}>
        <div css={buttonWrapperStyles}>
          <Button
            text={t('common.informative.wait_on_dashboard')}
            type={BUTTON_TYPE.GHOST}
            onClick={onClose}
            icon={icons.chevron_left}
            size={BUTTON_SIZE.LARGE}
          />
        </div>
        <div css={animationStyles}>
          <Lottie options={defaultOptions} />
        </div>
        <div css={loadingTextStyles} style={{ opacity: isFading ? 0 : 1 }}>
          {aiLoadingTexts(lang)[currentTextIndex]}
        </div>
        <AnimationBar completed={isCompleted} />
      </div>
    )
  },
)

AiLoading.displayName = 'AiLoading'
