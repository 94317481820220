import { makeMuiStyles } from 'src/theme/muiTheme'

export const useFillStyles = makeMuiStyles()((theme) => ({
  fillStyles: {
    'margin': theme.spacing(0, 2),
    'display': 'flex',
    'alignItems': 'center',
    'minWidth': '20px',
    'minHeight': '20px',
    'gap': theme.spacing(2),
    '& span.bp5-popover-target': {
      width: '20px !important',
      height: '20px !important',
    },
    '& div[style*="font-optical-sizing: auto;"]': {
      width: '20px !important',
      height: '20px !important',
      borderRadius: `${theme.spacing(1)} !important`,
      cursor: 'pointer',
      boxShadow: 'none !important',
      div: {
        borderRadius: theme.spacing(1),
        width: '20px !important',
        height: '20px !important',
        border: '1px solid #CCC',
      },
    },
  },
}))

export const useHistoryStyles = makeMuiStyles()((theme) => ({
  container: {
    paddingRight: theme.spacing(2),
    // paddingLeft: theme.spacing(2),
  },
}))

export const useTransparencyStyles = makeMuiStyles()((theme) => ({
  container: {
    padding: theme.spacing(3),
    width: '205px',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  input: {
    input: {
      fontSize: '13px',
      paddingLeft: '12px',
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
}))

export const useFontSizeStyles = makeMuiStyles()((theme) => ({
  input: {
    maxWidth: '80px',
    minWidth: '54px',
    input: {
      fontSize: '13px',
      paddingLeft: '12px',
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
}))

export const useSliderWithInputStyles = makeMuiStyles()((theme) => ({
  root: {
    width: '100%',
    maxWidth: '76px',
  },
  input: {
    width: '100%',
    maxWidth: '76px',
    input: {
      fontSize: '13px',
      paddingLeft: '12px',
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
}))

export const useSpacingStyles = makeMuiStyles()((theme) => ({
  container: {
    padding: theme.spacing(2, 3),
    width: '220px',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  input: {
    input: {
      fontSize: '13px',
      paddingLeft: '12px',
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
}))

export const usePositionStyles = makeMuiStyles()(() => ({
  container: {
    'position': 'relative',
    'button.bp5-button': {
      visibility: 'hidden',
      width: '0px !important',
    },
    'span.bp5-popover-target': {
      width: '0px !important',
    },
  },
}))

export const useLineSettingsStyles = makeMuiStyles()((theme) => ({
  container: {
    borderRadius: theme.spacing(2),
  },
  button: {
    width: '30px',
    height: '30px',
  },
}))

export const useSvgFillStyles = makeMuiStyles()((theme) => ({
  fillStyles: {
    margin: theme.spacing(0, 1),
  },
}))

export const useVideoTrimStyles = makeMuiStyles()((theme) => ({
  container: {
    'display': 'flex',
    'position': 'relative',
    'height': '48px',
    'width': '100%',
    'borderRadius': theme.spacing(2),
    'img:first-of-type': {
      marginLeft: '0px !important',
    },
  },
  imageContainer: {
    position: 'relative',
    maxWidth: '50vw',
    width: '440px',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
}))

export const useVolumeStyles = makeMuiStyles()((theme) => ({
  container: {
    padding: theme.spacing(3),
    width: '205px',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(2),
  },
  input: {
    input: {
      fontSize: '13px',
      paddingLeft: '12px',
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
}))

export const useSimpleColorPickerStyles = makeMuiStyles()((theme) => ({
  container: {
    margin: 0,
  },
  colorBox: {
    display: 'flex',
    overflow: 'hidden',
    width: '28px',
    height: '28px',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[200]}`,
    cursor: 'pointer',
    background:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=) left center',
  },
  addButton: {
    width: '28px',
    height: '28px',
    backgroundColor: theme.palette.primary._states?.focus,
    color: theme.palette.primary.main,
  },
  swatch: {
    padding: theme.spacing(2, 2),
  },
  labelItem: {
    color: theme.palette.grey[700],
  },
}))

export const useAIContextMenuStyles = makeMuiStyles()((theme) => ({
  root: {
    'position': 'absolute',
    'top': '8px',
    'right': '8px',
    'backgroundColor': theme.palette.grey[800],
    'width': '30px',
    'height': '16px',
    'color': 'white',
    ':hover': {
      backgroundColor: theme.palette.grey[800],
      boxShadow: theme.shadows[3],
      color: 'white',
    },
    ':active': {
      backgroundColor: theme.palette.grey[800],
      boxShadow: theme.shadows[3],
      color: 'white',
    },
  },
  menuItem: {
    fontSize: '14px',
    fontWeight: 400,
  },
  dangerMenuItem: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.error.main,
  },
  paper: {
    borderRadius: theme.spacing(1),
  },
  button: {
    backgroundColor: 'white',
    color: theme.palette.text.primary,
  },
  isOpen: {
    backgroundColor: theme.palette.primary._states?.focus,
    color: theme.palette.text.primary,
  },
}))

export const useSizeStyles = makeMuiStyles()((theme) => ({
  container: {
    padding: theme.spacing(0),
  },
  input: {
    width: 'auto',
    maxWidth: '120px',
    minWidth: '36px',
    marginRight: theme.spacing(2),
    input: {
      fontSize: '13px',
      paddingLeft: '8px',
      paddingRight: theme.spacing(1),
      width: '100%',
    },
  },
}))
