import React, { useCallback, useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import InputAdornment from '@mui/material/InputAdornment'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid2'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import { useDispatch, useSelector } from 'react-redux'
import BaseIconButton from 'src/components/base-icon-button'
import { useLanguage } from 'src/hooks'
import { TKey } from 'src/i18n/types'
import { showAddSlideDrawer } from 'src/store/generalV3'
import { IPolotnoComponent } from '../../types'
import AutoScrollArea from '../scroll-area'
import { useStyles } from './styles'
import { useDecksThemeV3Api } from 'src/hooks/api/useDeckV3ThemeApi'
import { RootState } from 'src/store'
import { DeckV3LayoutCategories } from 'src/types/api/enums'
import { forEveryChild } from 'polotno/model/group-model'
import { nanoid } from '@reduxjs/toolkit'
import { PageType } from 'polotno/model/page-model'

interface IAddSlideDrawerProps extends IPolotnoComponent {
  open: boolean
}
const blankLayout = {
  id: 'blank',
  name: 'Blank',
  slideIndex: 0,
  thumbnailUrl: 'https://cdn.decktopus.com/layouts/v3/blank.png',
}
const AddSlideTabs = [
  { id: 1, label: 'v3.add_slide.all', isActive: true, value: 'all' },
  {
    id: 2,
    label: 'v3.add_slide.text_slides',
    isActive: false,
    value: DeckV3LayoutCategories.TEXT,
  },
  {
    id: 3,
    label: 'v3.add_slide.media',
    isActive: false,
    value: DeckV3LayoutCategories.MEDIA,
  },
  {
    id: 4,
    label: 'v3.add_slide.lists',
    isActive: false,
    value: DeckV3LayoutCategories.LIST,
  },
  // {
  //   id: 6,
  //   label: 'v3.add_slide.timeline',
  //   isActive: false,
  //   value: DeckV3LayoutCategories.TIMELINE,
  // },
]

const baseThemeId = 'b7d21d21-5d8b-4dbc-a332-bd2299622272'
const AddSlideDrawer = observer<IAddSlideDrawerProps>(({ store, open }) => {
  const { t } = useLanguage()
  const { classes } = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [selectedTab, setSelectedTab] = useState('all')
  const { getThemeDetails, getThemeLayout } = useDecksThemeV3Api()
  const dispatch = useDispatch()

  const { baseTheme } = useSelector(({ deckV3Theme }: RootState) => ({
    baseTheme: deckV3Theme.baseTheme,
  }))

  const layoutGroup =
    selectedTab === 'all'
      ? baseTheme?.layouts
      : baseTheme?.layouts.filter((layout) => layout.category === selectedTab)
  const filteredLayouts = layoutGroup
    ?.filter((layout) =>
      layout.name.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .sort((a, b) => a.slideIndex - b.slideIndex)
  filteredLayouts?.unshift(blankLayout)

  const toggleDrawer = useCallback(
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setIsOpen(open)
      dispatch(showAddSlideDrawer(open))
    },
    [dispatch],
  )

  useEffect(() => {
    getThemeDetails(baseThemeId)
  }, [getThemeDetails])

  useEffect(() => {
    setIsOpen(open)
    dispatch(showAddSlideDrawer(open))
  }, [dispatch, open])

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchValue(event.target.value)
  }

  const handleClose = () => {
    dispatch(showAddSlideDrawer(false))
  }

  const handleSlideClick = async (themeLayoutId: string) => {
    const activeId = store.activePage?.id
    const pageIdx = store.pages.findIndex((spage) => spage.id === activeId)
    let newPage: PageType
    if (themeLayoutId === 'blank') {
      newPage = store.addPage()
    } else {
      const layout = await getThemeLayout(themeLayoutId)
      if (!layout) {
        return
      }
      const { content } = layout
      content.id = nanoid(10)
      forEveryChild(content, (child: any) => {
        child.id = nanoid(10)
      })
      newPage = store.addPage(content)
    }
    if (pageIdx > -1) {
      newPage.setZIndex(pageIdx + 1)
    }
    setTimeout(() => {
      store.selectPage(newPage.id)
    }, 100)
    dispatch(showAddSlideDrawer(false))
  }

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent)

  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <div className={classes.drawer}>
          <Grid container width="100%" spacing={4} mt={4}>
            <Grid size={12}>
              <Grid
                container
                width="100%"
                spacing={0}
                sx={{
                  pr: {
                    xs: 1,
                    sm: 2,
                    md: 4,
                  },
                }}
                alignItems="center"
              >
                <Grid size={{ xs: 0, sm: 2 }}></Grid>
                <Grid
                  size={{ xs: 10, sm: 8 }}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  className={classes.header}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={null}
                    placeholder={t('v3.add_slide.search_placeholder')}
                    onChange={handleSearchChange}
                    slotProps={{
                      input: {
                        className: classes.searchField,
                        startAdornment: (
                          <InputAdornment position="start">
                            <MaterialSymbol
                              icon="search"
                              size={24}
                              weight={300}
                            />
                          </InputAdornment>
                        ),
                      },
                    }}
                    sx={{ maxWidth: '400px' }}
                  />
                </Grid>
                <Grid
                  size={{ xs: 2, sm: 2 }}
                  justifyContent="flex-end"
                  display="flex"
                >
                  <BaseIconButton onClick={() => handleClose()}>
                    <MaterialSymbol icon="close" size={24} weight={500} />
                  </BaseIconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              size={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <Tabs
                orientation="horizontal"
                variant="scrollable"
                value={selectedTab}
                onChange={(_, value) => setSelectedTab(value)}
              >
                {AddSlideTabs.map((item) => (
                  <Tab
                    label={t(item.label as TKey)}
                    value={item.value}
                    key={item.id}
                    className={classes.tab}
                    iconPosition="start"
                    id={`horizontal-tab-${item.id}`}
                    aria-controls={`horizontal-tabpanel-${item.id}`}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid
              size={12}
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <AutoScrollArea>
                <Grid
                  columns={60}
                  container
                  justifyContent="center"
                  width="100%"
                  spacing={4}
                  mt={4}
                  mb={4}
                  sx={{
                    padding: {
                      xs: '0px 16px',
                      sm: '0px 24px',
                      md: '0px 32px',
                      lg: '0px 48px',
                      xl: '0px 54px',
                    },
                  }}
                >
                  {filteredLayouts?.map((layout) => (
                    <Grid
                      key={layout.id}
                      size={{ xs: 30, sm: 20, md: 15, lg: 12, xl: 10 }}
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                    >
                      <Card
                        className={classes.card}
                        elevation={0}
                        role="button"
                        onClick={() => handleSlideClick(layout.id)}
                      >
                        <CardMedia
                          className={classes.cardMedia}
                          image={
                            layout.thumbnailUrl ??
                            '/static/media/placeholder.ec7bc6c77b856f92c728e37a8831dd3c.svg'
                          }
                          title="slide"
                        />
                        <CardContent className={classes.cardContent}>
                          <Typography variant="body2" className={classes.title}>
                            {layout.name}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </AutoScrollArea>
            </Grid>
          </Grid>
        </div>
      </SwipeableDrawer>
    </div>
  )
})

export default AddSlideDrawer
