import Slider, { SliderThumb } from '@mui/material/Slider'
import styled from '@mui/material/styles/styled'
import { MaterialSymbol } from 'react-material-symbols'

const VideoSlider = styled(Slider)(({ theme }) => ({
  'height': '48px',
  'position': 'absolute',
  'top': 0,
  'left': 0,
  'padding': '0',
  'borderRadius': '8px',
  'margin': '0px 8px',
  'width': 'calc(100% - 16px)',
  '& .MuiSlider-thumb': {
    'height': 48,
    'width': 18,
    'backgroundColor': `${theme.palette.primary.main}`,
    'border': 'none',
    'borderRadius': '8px 0px 0px 8px',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-thumb:last-child': {
    borderRadius: '0px 8px 8px 0px',
  },
  '& .MuiSlider-track': {
    height: 48,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    boxShadow: 'none',
  },
  '& .MuiSlider-rail': {
    color: '#d8d8d8',
    backgroundColor: 'transparent',
    opacity: 1,
    height: 48,
    boxShadow: 'none',
  },
}))

export default VideoSlider

interface IVideoSliderThumbProps extends React.HTMLAttributes<unknown> {}

export function VideoSliderThumb(props: IVideoSliderThumbProps) {
  const { children, ...other } = props

  return (
    <SliderThumb {...other}>
      {children}
      <MaterialSymbol
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        icon={props['data-index'] == 0 ? 'chevron_left' : 'chevron_right'}
        weight={400}
        size={16}
        color="white"
      />
    </SliderThumb>
  )
}
