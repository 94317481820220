import React from 'react'

import { ITextDisplay } from './types'
import { textDisplayStyles } from './styles'

export const TextDisplay: React.FC<ITextDisplay> = React.forwardRef<
  HTMLDivElement,
  ITextDisplay
>(({ name, styles, onClick, className, dataAttr }, ref) => {
  return (
    <div
      ref={ref}
      css={textDisplayStyles}
      style={styles}
      onClick={onClick}
      className={className}
      {...dataAttr}
    >
      {name}
    </div>
  )
})

TextDisplay.displayName = 'TextDisplay'
