import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const canvasSideBarStyles = ({ colors, borderRadius }: ITheme) => css`
  font-size: 24px;
  position: absolute;
  width: 44px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  display: flex;
  flex-direction: column;

  .top {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .blank {
    width: 100%;
    aspect-ratio: 1;
  }

  .button {
    flex-shrink: 0;
    width: 100%;
    aspect-ratio: 1;
    background: ${colors.white.DEFAULT};
    border-radius: ${borderRadius.DEFAULT};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.elements.DEFAULT};
    cursor: pointer;
    position: relative;

    &.swap {
      background: ${colors.black.DEFAULT};
    }
  }
`
