import { makeMuiStyles } from 'src/theme/muiTheme'

export const useStyles = makeMuiStyles()((theme) => ({
  headerActionsStyles: {
    'display': 'flex',
    'gap': theme.spacing(2),
    'alignItems': 'center',
    //@ts-expect-error duplication is needed
    'display': 'none',
    [theme.breakpoints.up('tablet')]: {
      display: 'flex',
    },
    '[data-button-id="plan-button"]': {
      'height': '32px',
      'padding': theme.spacing(0, 2),
      '& .button-text': {
        paddingLeft: theme.spacing(1),
      },
    },
  },
  userPanelStyles: {
    marginLeft: 'auto',
    [theme.breakpoints.up('tablet')]: {
      display: 'none',
    },
  },
}))
