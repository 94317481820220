import React from 'react'
import { useLanguage } from 'src/hooks'
import { TESTIMONY_DATA } from './'
import { ITestimony } from './types'
import {
  testimonyBoxStyles,
  textStyles,
  titleStyles,
  wrapperStyles,
} from './styles'

export const Testimony: React.FC<ITestimony> = React.memo(
  ({ className, dataAttr }) => {
    const { lang } = useLanguage()

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div css={testimonyBoxStyles}>
          <div css={titleStyles}>{TESTIMONY_DATA(lang)[0].title}</div>
          <div css={textStyles}>{TESTIMONY_DATA(lang)[0].text}</div>
        </div>
        <div css={testimonyBoxStyles}>
          <div css={titleStyles}>{TESTIMONY_DATA(lang)[1].title}</div>
          <div css={textStyles}>{TESTIMONY_DATA(lang)[1].text}</div>
        </div>
        <div css={testimonyBoxStyles}>
          <div css={titleStyles}>{TESTIMONY_DATA(lang)[2].title}</div>
          <div css={textStyles}>{TESTIMONY_DATA(lang)[2].text}</div>
        </div>

        <div css={testimonyBoxStyles}>
          <div css={titleStyles}>{TESTIMONY_DATA(lang)[3].title}</div>
          <div css={textStyles}>{TESTIMONY_DATA(lang)[3].text}</div>
        </div>
      </div>
    )
  },
)
