import React, { useMemo } from 'react'
import { IFormQuestion } from './types'
import { formQuestionStyles, renderItemsStyles, titleStyles } from './styles'
import { FormQuestionTypes } from 'src/types/api/enums'
import { FormText } from './FormText'
import { FormRate } from './FormRate'
import { FormOption } from './FormOptions'

export const FormQuestion: React.FC<IFormQuestion> = React.memo(
  ({ formDTO, question, options, formDTOHandler }) => {
    const renderFormItem = useMemo(() => {
      switch (question.questionType) {
        case FormQuestionTypes.TEXT:
          return (
            <FormText
              formDTO={formDTO}
              question={question}
              options={options}
              formDTOHandler={formDTOHandler}
            />
          )
        case FormQuestionTypes.OPTIONS:
          return (
            <FormOption
              formDTO={formDTO}
              question={question}
              formDTOHandler={formDTOHandler}
            />
          )
        case FormQuestionTypes.RATE:
          return (
            <FormRate
              formDTO={formDTO}
              question={question}
              formDTOHandler={formDTOHandler}
            />
          )
      }
    }, [question, formDTO, options, formDTOHandler])

    return (
      <div css={formQuestionStyles}>
        <div css={titleStyles}>
          {question.questionText}
          {question.isRequired && <span>*</span>}
        </div>
        <div css={renderItemsStyles}>{renderFormItem}</div>
      </div>
    )
  },
)
