import { useTheme } from '@emotion/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { VALIDATION_RULE_TYPES, useDebouncer, useDecksApi } from 'src/hooks'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  INPUT_SIZE,
  INPUT_THEME,
  INPUT_VARIANT,
  Icon,
  Input,
  icons,
} from 'src/lib'
import { RootState, SAVE_STATE, changeDeckName, setSaveState } from 'src/store'
import HomeButton from '../home-button'
import { deckNameEditStyles } from './styles'
import { IDeckNameEdit } from './types'

export const DeckNameEdit: React.FC<IDeckNameEdit> = React.memo(
  ({ className, dataAttr }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { colors } = useTheme()

    const { saveChanges, updateDeck } = useDecksApi()

    const [isInitialLoad, setIsInitialLoad] = useState(true)

    const {
      deckName,
      deckId,
      saveState,
      toBeSaved,
      workspaceId,
      version,
      v3SaveState,
    } = useSelector(
      ({
        edit,
        canvas,
        workspace,
        layouts,
        persistedDeckV3: deckV3,
      }: RootState) => ({
        deckName: edit.activeDeck.data?.deck?.name,
        deckId: edit.activeDeck.data?.deck?.id,
        saveState: canvas.saveState,
        toBeSaved: edit.toBeSaved.data,
        workspaceId: workspace.id,
        version: layouts.version,
        v3SaveState: deckV3.saveState,
      }),
    )

    const deckNameSaving = useMemo(() => {
      let icon = icons.saved
      const state = version === 'v3' ? v3SaveState : saveState
      switch (state) {
        case SAVE_STATE.NOT_SAVED:
          icon = icons.not_saved
          break
        case SAVE_STATE.SAVED:
          icon = icons.saved
          break
        case SAVE_STATE.SAVING:
          icon = icons.saving
          break
        case SAVE_STATE.FAILED:
          icon = icons.not_saved
          break
        default:
          icon = icons.saved
      }

      return (
        <Icon
          onClick={() => saveChanges()}
          color={colors.elements.DEFAULT}
          icon={icon}
          size={32}
        />
      )
    }, [saveState, toBeSaved, version, v3SaveState])

    const handleDeckNameChange = useCallback(
      (value: string) => {
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
        dispatch(changeDeckName(value))
      },
      [deckId],
    )

    const handleHomeClick = useCallback(() => {
      navigate('/dashboard', { replace: true })
    }, [])

    const applyNameChange = useCallback(() => {
      if (isInitialLoad) {
        setIsInitialLoad(false)
        return
      }

      if (deckId && (deckName?.length || 0) >= 3) {
        updateDeck(
          { deckId },
          {
            name: deckName,
            organizationId: workspaceId,
          },
        )
      }
    }, [deckName, deckId, workspaceId])

    const debounced = useDebouncer(
      () => {
        applyNameChange()
      },
      { delay: 1000 },
    )

    useEffect(() => {
      debounced()
    }, [deckName, deckId])

    return (
      <div css={deckNameEditStyles} className={className} {...dataAttr}>
        {version === 'v3' ? (
          <HomeButton onClick={handleHomeClick} />
        ) : (
          <Button
            icon={icons.home_page}
            theme={BUTTON_THEME.ELEMENTS}
            type={BUTTON_TYPE.GHOST}
            size={BUTTON_SIZE.LARGE}
            iconSize={24}
            onClick={handleHomeClick}
            isLink
          />
        )}
        <div className="deck-name-input">
          <Input
            value={deckName}
            type={INPUT_THEME.LIGHT}
            size={INPUT_SIZE.XSMALL}
            clearButton={false}
            onChange={handleDeckNameChange}
            variant={INPUT_VARIANT.DASHED}
            squeezed
            validation={{
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
              },
            }}
          />
        </div>
        {deckNameSaving}
      </div>
    )
  },
)

DeckNameEdit.displayName = 'DeckNameEdit'
