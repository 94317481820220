import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetDeckV3ThemeDetails } from 'src/types/api/responseObjects'

export interface IDeckV3ThemeSlice {
  baseTheme?: GetDeckV3ThemeDetails
}

const initialState: IDeckV3ThemeSlice = {
  baseTheme: undefined,
}

export const deckV3ThemeSlice = createSlice({
  name: 'deckV3Theme',
  initialState,
  reducers: {
    setBaseTheme: (state, action: PayloadAction<GetDeckV3ThemeDetails>) => {
      state.baseTheme = action.payload
    },
  },
})

export const { setBaseTheme } = deckV3ThemeSlice.actions

export default deckV3ThemeSlice.reducer
