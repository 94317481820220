import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'src/lib'
import { UpgradeModals } from './UpgradeModals'
import { useInterval, useUserApi } from 'src/hooks'
import { IUpgradeModalManager } from './types'

export const UpgradeModalManager: React.FC<IUpgradeModalManager> = React.memo(
  ({ isOpen, callback, refillMode, availablePlanNames, context, onClose }) => {
    const { getUser } = useUserApi()
    const [paymentAwaits, setPaymentAwaits] = useState<boolean>(false)

    useEffect(() => {
      if (isOpen) {
        setPaymentAwaits(true)
      }
    }, [isOpen])

    const checkPayment = useCallback(async () => {
      const paymentLS = localStorage.getItem('payment-awaits')

      if (paymentLS === 'false') {
        setPaymentAwaits(false)
        await getUser()
        localStorage.removeItem('payment-awaits')
        if (callback) {
          callback()
        }
      }
    }, [])

    useInterval(
      () => {
        if (paymentAwaits) {
          checkPayment()
        }
      },
      paymentAwaits ? 1000 : null,
    )

    const onCloseHandler = useCallback(() => {
      setPaymentAwaits(false)
      if (onClose) {
        onClose()
      }
    }, [])

    const upgradeModal = useMemo(
      () => (
        <Modal isOpen={isOpen} onClose={onCloseHandler}>
          <UpgradeModals
            onCloseClick={onCloseHandler}
            refillMode={refillMode}
            availablePlanNames={availablePlanNames}
            context={context}
          />
        </Modal>
      ),
      [isOpen],
    )

    return isOpen && createPortal(upgradeModal, document.body)
  },
)
