import React, { useCallback, useMemo, useState } from 'react'
import { useLanguage, useDecksApi } from 'src/hooks'
import { ICard, CARD_TYPE } from './types'
import {
  newDeckCoverStyles,
  overlayStyles,
  readyForTransferStyles,
  wrapperStyles,
} from './styles'
import { CardHeader, CardAction, CardFooter } from './components'
import { BUTTON_THEME, Button } from '../button'
import { icons } from '../icon'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, setActiveMigration } from 'src/store'
import getDeckEditorPath from 'src/helpers/getDeckEditorPath'

export const Card: React.FC<ICard> = React.memo(
  ({
    id,
    type = CARD_TYPE.DASHBOARD,
    hasError = false,
    avatar,
    disabled = false,
    footerIcon,
    footerText,
    footerTextInfo,
    isLoading,
    menuItems,
    title,
    subtitle,
    subtitleInfo,
    className,
    dataAttr,
    primaryButtons,
    secondaryButtons,
    thumbnailUrl,
    isNewDeck,
    newDeckButtons,
    isDeckReadyForTransfer,
    isDeckFinal,
    publishButton,
    onClickDelete,
    editorVersion,
  }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const { migrateDeck, isLoading: isMigrationLoading } = useDecksApi()
    const navigate = useNavigate()

    const menuOnClick = (state: boolean) => {
      setIsMenuOpen(state)
    }

    const [hovered, setHovered] = useState(false)

    const isDisabled = useMemo(
      () => isNewDeck || disabled || isDeckReadyForTransfer,
      [isNewDeck, disabled, isDeckReadyForTransfer],
    )

    const { hasActiveMigration } = useSelector(({ decks }: RootState) => ({
      hasActiveMigration: decks.hasActiveMigration,
    }))

    const handleOnMigrateClick = useCallback(async () => {
      if (id) {
        dispatch(setActiveMigration(true))
        const res = await migrateDeck({ deckId: id })

        if (res) {
          navigate(getDeckEditorPath(id, editorVersion), { replace: true })
          dispatch(setActiveMigration(false))
        }
      }
    }, [id])

    return (
      <div
        css={wrapperStyles({ disabled, type })}
        className={className}
        {...dataAttr}
        onMouseEnter={() => !isDisabled && setHovered(true)}
        onMouseLeave={() => !isDisabled && setHovered(false)}
      >
        {isDeckReadyForTransfer ? (
          <div css={readyForTransferStyles}>
            <div
              style={{ padding: '15px', textAlign: 'center', display: 'none' }}
            >
              {t('dashboard.card.transferring')}
            </div>
            <div>
              <Button
                text={t('dashboard.card.migration')}
                theme={BUTTON_THEME.GRADIENT}
                icon={icons.download}
                onClick={handleOnMigrateClick}
                isLoading={isMigrationLoading}
                disabled={hasActiveMigration}
              />
            </div>
          </div>
        ) : (
          isNewDeck && <div css={newDeckCoverStyles}>{newDeckButtons}</div>
        )}
        <div css={overlayStyles({ isMenuOpen })} />
        {type === CARD_TYPE.DASHBOARD && (
          <CardHeader
            hasError={hasError}
            isLoading={isLoading}
            isMenuOpen={isMenuOpen}
            menuItems={menuItems?.filter(({ hidden }) => !hidden && !isNewDeck)}
            menuOnClick={menuOnClick}
            title={title}
            subtitle={subtitle}
            subtitleInfo={subtitleInfo}
            onClickDelete={onClickDelete}
            isNewDeck={isNewDeck}
            publishButton={publishButton}
          />
        )}
        <CardAction
          deckId={id}
          type={type}
          isLoading={isLoading}
          showActions={!hasError && hovered}
          primaryButtons={primaryButtons}
          secondaryButtons={secondaryButtons}
          thumbnailUrl={thumbnailUrl}
          isDeckFinal={isDeckFinal}
        />
        <CardFooter
          type={type}
          avatar={avatar}
          icon={footerIcon}
          text={footerText}
          textInfo={hasError ? '' : footerTextInfo}
        />
      </div>
    )
  },
)

Card.displayName = 'Card'
