import { css } from '@emotion/react'
import { ISvgDecor } from './types'
import alphaIndBg from 'src/assets/images/alpha-ind.png'

type ISvgDecorStyles = Pick<ISvgDecor, 'width' | 'height' | 'alphaIndicator'>

export const svgDecorStyles = ({
  width,
  height,
  alphaIndicator,
}: ISvgDecorStyles) => css`
  width: 100%;
  height: 100%;

  ${alphaIndicator && `background: url(${alphaIndBg});`}
  svg {
    ${width && `width: ${width};`}
    ${height && `height: ${height};`}
  }
`
