import { IButton } from 'src/lib'
import { IWorkspace } from 'src/components/workspace-selector'
import { dataAttrType } from 'src/types'

export enum PLAN_BADGE_TYPE {
  DEFAULT = 'default',
  PRICE = 'price',
}

export interface IPlanBadge {
  title?: string
  price?: string
  type?: PLAN_BADGE_TYPE
  priceDuration?: string
  currency?: string
  workspaceId?: IWorkspace['id']
  isUpgradeable?: boolean
  paymentFailed?: boolean
  shouldUpdatePaymentMethod?: boolean
  onClick?: IButton['onClick']
  className?: string
  dataAttr?: dataAttrType
}
