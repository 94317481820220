import React, { useMemo } from 'react'

import { listPropsStyles } from './styles'
import { IEditPropSection } from '../../types'
import { ListType } from './ListType'
import { ListItemImageTypes, ListTypes } from 'src/types/api/enums'
import {
  ComponentListDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ListDirection } from './ListDirection'
import { ListMediaPosition } from './ListMediaPosition'
import { ListLayout } from './ListLayout'
import { ListColors } from './ListColors'
import { ListFontSize } from './ListFontSize'
import { ListIconFrame } from './ListIconFrame'
import { ListItems } from './ListItems'

export const ListPropsNew: React.FC<IEditPropSection> = React.memo(
  ({ components, className, dataAttr }) => {
    const selectedType = useMemo(
      () =>
        components?.reduce(
          (a: ListTypes | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).listType
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedMediaType = useMemo(
      () =>
        components?.reduce(
          (a: ListItemImageTypes | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).listItems[0]
              .image?.type
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedIsGrid = useMemo(
      () =>
        components?.reduce(
          (a: boolean | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentListDataSchema).isGrid
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const displayDirection = useMemo(() => !selectedIsGrid, [selectedIsGrid])

    const displayListType = useMemo(
      () =>
        selectedType === ListTypes.BULLET ||
        selectedType === ListTypes.NUMBER ||
        selectedType === ListTypes.PLAIN,
      [selectedType],
    )

    const displayMediaPosition = useMemo(
      () =>
        selectedType === ListTypes.MEDIA &&
        selectedMediaType !== ListItemImageTypes.LOGO &&
        !selectedIsGrid,
      [selectedType, selectedMediaType, selectedIsGrid],
    )

    const displayListLayout = useMemo(
      () => selectedType !== ListTypes.TIMELINE && !selectedIsGrid,
      [selectedType, selectedIsGrid],
    )

    const displayColors = useMemo(
      () => !selectedIsGrid && selectedMediaType !== ListItemImageTypes.LOGO,
      [selectedIsGrid, selectedMediaType],
    )

    const displayIconFrame = useMemo(
      () => selectedMediaType === ListItemImageTypes.ICON,
      [selectedMediaType],
    )

    return (
      <div css={listPropsStyles} className={className} {...dataAttr}>
        {displayDirection && <ListDirection components={components} />}
        {displayListType && <ListType components={components} />}
        {displayMediaPosition && <ListMediaPosition components={components} />}
        {displayListLayout && <ListLayout components={components} />}
        {displayColors && <ListColors components={components} />}
        {displayIconFrame && <ListIconFrame components={components} />}
        <ListFontSize components={components} />
        <ListItems components={components} isGrid={selectedIsGrid} />
      </div>
    )
  },
)

ListPropsNew.displayName = 'ListPropsNew'
