import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  height: 100%;
  padding: ${theme.gap.medium}px;
  position: relative;
  border-radius: ${theme.borderRadius[6]};
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: ${theme.dimensions.webhookModalWidth.DEFAULT}px;
    padding: ${theme.gap['3xlarge']}px;
  }
`

export const titleStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
`
export const closeWrapperStyles = (theme: ITheme) => css`
  position: absolute;
  top: ${theme.gap.medium}px;
  right: ${theme.gap.medium}px;
`
export const webhookInfoWrapperStyles = (theme: ITheme) => css`
  margin-top: ${theme.gap.xlarge}px;
  line-height: 155%;
`
export const webhookGeneralInfoStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.regular};
`

export const webhookLearnInfoStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.regular};
  margin-top: ${theme.gap.small}px;
  span {
    font-size: ${theme.fontSize.base} !important;
    font-weight: ${theme.fontWeight.regular} !important;
  }
`
export const createWebhookWrapperStyles = (theme: ITheme) => css`
  margin-top: ${theme.gap.xlarge}px;
  width: 186px;
  height: 44px;
`
export const webhookFormWrapperStyles = (theme: ITheme) => css`
  margin-top: ${theme.gap.xlarge}px;
`

export const webhookInviteWrapperStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: ${theme.gap.xlarge}px;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    flex-direction: row;
  }
`
export const webhookNameWrapperStyles = (theme: ITheme) => css`
  display: flex;
  width: 246px;
  flex-direction: column;
  gap: ${theme.gap.xsmall}px;
  input,
  input::placeholder {
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.medium};
    line-height: 155%;
  }
`
export const webhookNameStyles = (theme: ITheme) => `
font-size: ${theme.fontSize.base};
font-weight: ${theme.fontWeight.medium};
`
export const webhookURLWrapperStyles = (theme: ITheme) => css`
  display: flex;
  width: 246px;
  flex-direction: column;
  gap: ${theme.gap.xsmall}px;
  input,
  input::placeholder {
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.medium};
    line-height: 155%;
  }
`
export const webhookURLStyles = (theme: ITheme) => `
font-size: ${theme.fontSize.base};
font-weight: ${theme.fontWeight.medium};
`
export const webhookEventFormStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  line-height: 155%;
  margin-top: ${theme.gap.xlarge}px;
`
export const webhookEventSavedStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  line-height: 155%;
  margin-top: ${theme.gap.xlarge}px;
  display: none;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    display: block;
  }
`
export const webhookEventSavedMobilStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.medium};
  line-height: 155%;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    display: none;
  }
`
export const webhookFormButtonWrapperStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: flex-start;
  margin-top: ${theme.gap.xlarge}px;
  gap: ${theme.gap.small}px;
  span {
    font-weight: ${theme.fontWeight.medium};
    font-size: ${theme.fontSize.base};
  }
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    justify-content: flex-end;
  }
`
export const webhookSavedWrapperStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.gap.xlarge}px;
  flex-direction: column;
  gap: ${theme.gap.xlarge}px;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    flex-direction: row;
  }
`

export const webhookSavedButtonsWrapperStyles = (theme: ITheme) => css`
  display: flex;
  align-self: flex-start;
  gap: ${theme.gap.small}px;
  span {
    font-weight: ${theme.fontWeight.medium};
  }
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    align-self: flex-end;
  }
`
export const webhookDeleteWrapperStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: space-between;
  margin-top: ${theme.gap.xlarge}px;
  flex-direction: column;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    flex-direction: row;
  }
`
export const webhookDeleteWarningStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.base};
  font-weight: ${theme.fontWeight.regular};
  line-height: 155%;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    max-width: 65%;
  }
  strong {
    font-weight: ${theme.fontWeight.medium};
  }
`

export const webhookDeleteButtonsWrapperStyles = (theme: ITheme) => css`
  display: flex;
  gap: ${theme.gap.small}px;
  align-self: flex-start;
  margin-top: ${theme.gap.xlarge}px;
  span {
    font-weight: ${theme.fontWeight.medium};
  }
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    align-self: flex-end;
    margin-top: 0;
  }
`
