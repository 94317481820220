import Konva from 'konva'
import isEmpty from 'lodash/isEmpty'
import Rotater from 'src/assets/images/Rotater.svg'
let transformerAttributes = {}

export default function createTransformerAttributes() {
  if (!isEmpty(transformerAttributes)) {
    return transformerAttributes
  }
  const layer = new Konva.Layer()
  Konva.Image.fromURL(Rotater, (image) => {
    layer.add(
      new Konva.Circle({
        x: 12,
        y: 12,
        fill: 'white',
        width: 24,
        height: 24,
      }),
    )
    image.setAttrs({
      x: 3,
      y: 3,
      width: 18,
      height: 18,
    })
    layer.add(image)
    const S = layer.toCanvas({
      x: 0,
      y: 0,
      pixelRatio: 4,
      width: 24,
      height: 24,
    })
    transformerAttributes = {
      width: 24,
      height: 24,
      cornerRadius: 12,
      offsetX: 12,
      offsetY: 16,
      fillPatternImage: S,
      fillPatternScaleX: 0.25,
      fillPatternScaleY: 0.25,
      fillPriority: 'pattern',
      fillPatternRepeat: 'no-repeat',
      shadowColor: 'black',
      shadowBlur: 6,
      shadowOffset: {
        x: 0,
        y: 4,
      },
      shadowOpacity: 0.3,
      shadowEnabled: true,
      shadowForStrokeEnabled: false,
    }
    return transformerAttributes
  })
}
