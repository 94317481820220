import { dataAttrType } from 'src/types'

export enum CREATE_SLIDE_TAB {
  PREDEFINED = 'predefined',
  OWN = 'own',
}

export interface ICreateSlideModal {
  className?: string
  dataAttr?: dataAttrType
}
