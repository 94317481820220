import React, { JSXElementConstructor } from 'react'
import { ConnectDragSource } from 'react-dnd'
import { icons } from 'src/lib'
import { dataAttrType } from 'src/types'
import { ComponentSchema } from 'src/types/api/responseObjects'

export interface IEditElements {
  className?: string
  dataAttr?: dataAttrType
}

export enum ELEMENT_DEFINITION {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  BODY = 'body',
  LABEL = 'label',
  IMAGE = 'image',
  VIDEO = 'video',
  GIF = 'gif',
  ICON = 'icon',
  LOGO = 'logo',
  LINE_CHART = 'line_chart',
  TABLE = 'table',
  BAR_CHART = 'bar_chart',
  BUTTON = 'button',
  FORM = 'form',
  PIE_CHART = 'pie_chart',
  DONUT_CHART = 'donut_chart',
  RATING = 'rating',
  ICON_LIST = 'icon_list',
  IMAGE_LIST = 'image_list',
  IMAGE_GRID = 'image_grid',
  BULLET_LIST = 'bullet_list',
  TIMELINE = 'timeline',
  SHOWCASE = 'showcase',
  LOGO_LIST = 'logo_list',
  QUOTE = 'quote',
  WEBSITE = 'website',
  CODE = 'code',
  LINKS = 'links',
  SHAPE = 'shape',
  DEVICE = 'device',
  JUMBO = 'jumbo',
}

export interface IElementDisplayType {
  name?: string
  styles?: React.CSSProperties
  css?: any
  image?: string
  svgUrl?: string
  icon?: icons
  onClick?: () => void
  ref?: ConnectDragSource | React.Ref<HTMLDivElement>
  Component?: JSXElementConstructor<Omit<IElementDisplayType, 'Component'>>
}

export interface IElementDefinition {
  display: IElementDisplayType
  schemas: Omit<ComponentSchema, 'id' | 'sessionId'>[]
}

export type COMPONENT_DEFAULTS_TYPE = Record<
  ELEMENT_DEFINITION,
  IElementDefinition
>
