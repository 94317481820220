import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const useActiveThemeColors = () => {
  const { activeThemeColor } = useSelector(({ edit }: RootState) => ({
    activeThemeColor: edit.activeDeck.data?.deckData?.themeColor.data,
  }))

  return activeThemeColor
}
