import { css } from '@emotion/react'
import { APP_CONFIG } from 'src/config'
import { ITheme } from 'src/theme'

export const cardLayoutStyles = ({
  dimensions,
  gap,
  colors,
  borderRadius,
}: ITheme) => css`
  border-color: transparent;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  background: ${colors.dark.DEFAULT};
  width: ${dimensions.layoutCardWidth.DEFAULT}px;
  padding: ${gap.xsmall}px;
  display: flex;
  flex-direction: column;
  gap: ${gap.xsmall}px;
  border-radius: ${borderRadius[4]};

  .delete-button {
    position: absolute;
    display: none;
    top: 4px;
    right: 4px;
    z-index: 2;
  }

  &:hover {
    border-color: ${colors.primary.DEFAULT};
    .delete-button {
      display: block;
    }
  }

  .render-area {
    position: relative;
    width: 100%;
    aspect-ratio: ${APP_CONFIG.canvasRatio};
    background-size: cover;
    background-position: center center;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .name-area {
    color: ${colors.white.DEFAULT};
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
