import { css } from '@emotion/react'
import { ITheme, gap } from 'src/theme'
import { BOX_BUTTON_ICON_FRAME, IPropBoxButton } from './types'

type IMediaDisplayStyles = Pick<IPropBoxButton, 'isActive'>
export const mediaDisplayStyles =
  ({ isActive }: IMediaDisplayStyles) =>
  ({ colors, borderRadius }: ITheme) => css`
    position: relative;
    background: ${colors.dark.DEFAULT};
    width: 100%;
    border-radius: ${borderRadius[4]};
    aspect-ratio: 1;
    background-size: cover;
    background-position: center center;
    background-clip: border-box;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      border: 1px solid ${colors.primary.DEFAULT};
    }

    ${isActive && `border: 1px solid ${colors.primary.DEFAULT};`}

    .hover-icon {
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.1s;
    }

    &:hover .hover-icon {
      opacity: 1;
    }
  `

export const mediaDisplayContentStyles = ({
  fontSize,
  fontWeight,
}: ITheme) => css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-weight: ${fontWeight.medium};
  font-size: ${fontSize.small};
  gap: ${gap['2xsmall']}px;

  & .icon-wrapper {
    aspect-ratio: 1;
    max-width: 36px;
    height: 36px;
  }
`

type IIconFrameStyles = Pick<IPropBoxButton, 'iconFrame'>
export const iconFrameStyles =
  ({ iconFrame }: IIconFrameStyles) =>
  ({ borderRadius, colors }: ITheme) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 1;

    ${iconFrame === BOX_BUTTON_ICON_FRAME.OUTLINE &&
    `
			border: 2px solid #fff;
			border-radius: 50%;
		`}

    ${iconFrame === BOX_BUTTON_ICON_FRAME.SQUARE &&
    `
			border-radius: ${borderRadius[8]};
			background: ${colors.dark2[70]};
		`}

    ${iconFrame === BOX_BUTTON_ICON_FRAME.CIRCLE &&
    `
			border-radius: ${borderRadius.DEFAULT};
			background: ${colors.dark2[70]};
		`}
  `

export const mediaDisplayNameStyles = ({ gap }: ITheme) => css`
  text-align: center;
  padding: 0 ${gap['4xsmall']}px;
`
