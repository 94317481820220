import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { IPolotnoComponent } from '../../types'
import { showAddSlideDrawer } from 'src/store/generalV3'
import { useDispatch } from 'react-redux'

const AddPageAction = observer<IPolotnoComponent>(() => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(showAddSlideDrawer(true))
  }

  return (
    <BaseIconButton
      onClick={handleClick}
      sx={{
        padding: '6px',
      }}
      tooltip="v3.add_page"
    >
      <MaterialSymbol icon="add_box" size={20} weight={400} />
    </BaseIconButton>
  )
})

export default AddPageAction
