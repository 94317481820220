import React, { useCallback, useEffect, useMemo, useState } from 'react'

import {
  PropAiImage,
  PropHeader,
  PropMedia,
} from 'src/pages/deck-page/components/edit-properties/props'
import { INPUT_SIZE, INPUT_THEME, INPUT_WIDTH, Input, icons } from 'src/lib'
import { PropButton } from 'src/pages/deck-page/components/edit-properties/props'
import { useDispatch } from 'react-redux'
import { ComponentDeviceTypes, MediaTypes } from 'src/types/api/enums'
import {
  ComponentDeviceDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { VALIDATION_RULE_TYPES, useDebouncer, useLanguage } from 'src/hooks'
import { IEditPropSection } from '../../types'

export const DeviceProps: React.FC<IEditPropSection> = React.memo(
  ({ components, className, dataAttr }) => {
    const dispatch = useDispatch()
    const { t } = useLanguage()
    const [validUrl, setValidUrl] = useState(false)

    const selectedDeviceType = useMemo(
      () =>
        components?.reduce(
          (a: ComponentDeviceTypes | undefined, c: UpdateComponentSchema) => {
            const deviceType = (c.data as ComponentDeviceDataSchema).type
            if (a === undefined && deviceType) {
              return deviceType
            }

            return deviceType !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const selectedImageUrl = useMemo(
      () =>
        components?.reduce(
          (a: string | undefined, c: UpdateComponentSchema) => {
            const imageUrl = (c.data as ComponentDeviceDataSchema).imageUrl
            if (a === undefined && imageUrl) {
              return imageUrl
            }

            return imageUrl !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleDeviceTypeChange = useCallback(
      (value?: ComponentDeviceTypes) => {
        const partialData: DeepPartial<ComponentDeviceDataSchema> = {
          type: value,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentDeviceDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const handleDeviceUrlChange = useCallback(
      (value?: string, override?: boolean) => {
        const partialData: DeepPartial<ComponentDeviceDataSchema> = {
          imageUrl: value,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentDeviceDataSchema>({
            components,
            partialData,
          })

        if (validUrl || override) {
          dispatch(componentsUpdate({ components: updatedComponents }))
          dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
        }
      },
      [components, validUrl],
    )

    const [value, setValue] = useState(selectedImageUrl)
    const handleOnChange = useCallback(
      (value?: string) => {
        setValue(value)
      },
      [components],
    )

    const debounced = useDebouncer(
      () => {
        if (validUrl) {
          handleDeviceUrlChange(value)
        }
      },
      { delay: 500 },
    )

    useEffect(() => {
      debounced()
    }, [value, validUrl])

    const handleMediaUploadChange = useCallback(
      (type: any, mediaUrl: string) => {
        setValue(mediaUrl)
      },
      [],
    )

    const onAiMediaChange = useCallback((url: string) => {
      setValue(url)
    }, [])

    return (
      <div className={className} {...dataAttr}>
        <div className="grid">
          <div className="c-2 c-t-3">
            <PropMedia
              label={t('edit.media.modal.select_media', {
                type: t('common.files.media'),
              })}
              selectedType={MediaTypes.IMAGE}
              availableTypes={[MediaTypes.IMAGE]}
              onChange={handleMediaUploadChange}
            />
          </div>
          <div className="c-2 c-t-3">
            <PropAiImage onChange={onAiMediaChange} />
          </div>
        </div>
        <PropHeader text={t('edit.properties.device.header')} />
        <div className="grid">
          <div className="c-full">
            <Input
              width={INPUT_WIDTH.FULL}
              size={INPUT_SIZE.BIG}
              theme={INPUT_THEME.DARK}
              value={value}
              onChange={handleOnChange}
              constantValidation
              onFocus={() => dispatch(setAllowDeleteShortcut(false))}
              onBlur={() => dispatch(setAllowDeleteShortcut(true))}
              validation={{
                [VALIDATION_RULE_TYPES.REQUIRED]: {
                  text: t('validation.error.required', {
                    name: t('common.files.video'),
                  }),
                },
                [VALIDATION_RULE_TYPES.MIN]: {
                  value: 3,
                  text: t('validation.min_char', {
                    value: 3,
                    name: t('common.files.video'),
                  }),
                },
                [VALIDATION_RULE_TYPES.VALIDURL]: {
                  text: t('validation.error.url'),
                },
              }}
              onValidation={setValidUrl}
            />
          </div>
        </div>
        <PropHeader text={t('edit.properties.device.change_device')} />
        <div className="grid">
          <div className="c-1">
            <PropButton
              isBox
              icon={icons.laptop}
              onClick={() =>
                handleDeviceTypeChange(ComponentDeviceTypes.DESKTOP)
              }
              isActive={selectedDeviceType === ComponentDeviceTypes.DESKTOP}
              text={t('edit.properties.device.laptop')}
            />
          </div>
          <div className="c-1">
            <PropButton
              isBox
              icon={icons.tablet}
              onClick={() =>
                handleDeviceTypeChange(ComponentDeviceTypes.TABLET)
              }
              isActive={selectedDeviceType === ComponentDeviceTypes.TABLET}
              text={t('edit.properties.device.tablet')}
            />
          </div>
          <div className="c-1">
            <PropButton
              isBox
              icon={icons.phone}
              onClick={() => handleDeviceTypeChange(ComponentDeviceTypes.PHONE)}
              isActive={selectedDeviceType === ComponentDeviceTypes.PHONE}
              text={t('edit.properties.device.phone')}
            />
          </div>
        </div>
      </div>
    )
  },
)

DeviceProps.displayName = 'DeviceProps'
