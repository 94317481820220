import { css } from '@emotion/react'

export const listItemStyles = css`
  width: 100%;
  word-break: break-word;
  white-space: break-spaces;

  p {
    word-break: break-word;
    white-space: break-spaces;
  }
`
