import React, { useCallback, useMemo } from 'react'
import { useElementDefinitions, useLanguage } from 'src/hooks'
import {
  DROPDOWN_SIZE,
  DROPDOWN_THEME,
  DROPDOWN_WIDTH,
  Dropdown,
  IDropdownItem,
} from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { ComponentServices } from 'src/services'
import { DeepPartial } from 'src/types'
import { ComponentTextTypes } from 'src/types/api/enums'
import { ELEMENT_DEFINITION } from '../../../edit-elements'
import { IEditPropSection } from '../../types'

export const PropTextType: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t, lang } = useLanguage()
    const dispatch = useDispatch()
    const { definitions } = useElementDefinitions()

    const selectedTextTypes = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const cType = (c.data as ComponentTextDataSchema).textType
          if (a === '' && cType) {
            return cType
          }

          return cType !== a ? 'multi' : a
        }, ''),
      [components],
    )

    const handleTypeChange = useCallback(
      (value?: IDropdownItem) => {
        const targetDefinitionMap: Partial<
          Record<ComponentTextTypes, ELEMENT_DEFINITION>
        > = {
          [ComponentTextTypes.TITLE]: ELEMENT_DEFINITION.TITLE,
          [ComponentTextTypes.SUBTITLE]: ELEMENT_DEFINITION.SUBTITLE,
          [ComponentTextTypes.BODY]: ELEMENT_DEFINITION.BODY,
          [ComponentTextTypes.LABEL]: ELEMENT_DEFINITION.LABEL,
          [ComponentTextTypes.JUMBO]: ELEMENT_DEFINITION.JUMBO,
        }

        const partialData: DeepPartial<ComponentTextDataSchema> = value?.value
          ? {
              ...(definitions[
                targetDefinitionMap[
                  value.value as ComponentTextTypes
                ] as ELEMENT_DEFINITION
              ].schemas[0].data as ComponentTextDataSchema),
            }
          : {}

        const updatedFields: DeepPartial<ComponentTextDataSchema> = {
          textType: partialData.textType,
          style: {
            font: {
              size: partialData.style?.font?.size,
            },
            color: partialData.style?.color,
          },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTextDataSchema>({
            components,
            partialData: updatedFields,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [selectedTextTypes, components, lang],
    )

    return (
      <Dropdown
        width={DROPDOWN_WIDTH.FULL}
        theme={DROPDOWN_THEME.DARK}
        size={DROPDOWN_SIZE.BIG}
        selected={selectedTextTypes === 'multi' ? '' : selectedTextTypes}
        onChange={handleTypeChange}
        placeholder={t('edit.properties.text_size.placeholder')}
        items={[
          {
            name: t('edit.properties.text_size.big_text'),
            value: ComponentTextTypes.JUMBO,
          },
          {
            name: t('edit.properties.text_size.title'),
            value: ComponentTextTypes.TITLE,
          },
          {
            name: t('edit.properties.text_size.subtitle'),
            value: ComponentTextTypes.SUBTITLE,
          },
          {
            name: t('edit.properties.text_size.body'),
            value: ComponentTextTypes.BODY,
          },
          {
            name: t('edit.properties.text_size.label'),
            value: ComponentTextTypes.LABEL,
          },
        ]}
      />
    )
  },
)

PropTextType.displayName = 'PropTextType'
