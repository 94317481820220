import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = ({ colors, gap }: ITheme) => css`
  background: ${colors.white.DEFAULT};
  padding-top: ${gap['4xlarge']}px;
`

export const accountInfoStyles = ({ gap }: ITheme) => css`
  padding: 0 ${gap.xsmall}px;
`

export const accountInfoHeaderStyles = ({ fontWeight, gap }: ITheme) => css`
  display: flex;
  align-items: center;
  gap: ${gap['2xsmall']}px;
  font-weight: ${fontWeight.medium};
  padding: ${gap.xsmall}px ${gap.small}px;
  margin-bottom: ${gap.small}px;
`

export const accountInfoFormAreaStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${gap['4xlarge']}px;
  gap: ${gap.large}px;
`

export const accountInfoFormAreaBlockStyles = ({
  gap,
  fontSize,
  colors,
}: ITheme) => css`
  width: 100%;
  color: ${colors.text[2]};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${gap.xsmall}px;
  font-size: ${fontSize.small};
`

export const accountInfoFormAreaBlockHeaderStyles = ({
  fontWeight,
}: ITheme) => css`
  font-weight: ${fontWeight.medium};
`
