export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0H1920V1080H0V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM1852 36C1869.67 36 1884 50.3269 1884 68V1012C1884 1029.67 1869.67 1044 1852 1044H68C50.3269 1044 36 1029.67 36 1012V68C36 50.3269 50.3269 36 68 36H1852Z" fill="url(#decor-bg)"/><rect x="36" y="36" width="1848" height="1008" rx="32" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM924 68C924 50.3269 909.673 36 892 36H530C512.327 36 498 50.3269 498 68V277C498 294.673 512.327 309 530 309H892C909.673 309 924 294.673 924 277V68ZM892 345C909.673 345 924 359.327 924 377V1012C924 1029.67 909.673 1044 892 1044H530C512.327 1044 498 1029.67 498 1012V377C498 359.327 512.327 345 530 345H892ZM462 68C462 50.3269 447.673 36 430 36H68C50.3269 36 36 50.3269 36 68V703C36 720.673 50.3269 735 68 735H430C447.673 735 462 720.673 462 703V68ZM430 771C447.673 771 462 785.327 462 803V1012C462 1029.67 447.673 1044 430 1044H68C50.3269 1044 36 1029.67 36 1012V803C36 785.327 50.3269 771 68 771H430Z" fill="url(#decor-bg)"/><rect x="-0.5" y="0.5" width="425" height="272" rx="31.5" transform="matrix(-1 0 0 1 923 36)" stroke="#999999"/><rect x="-0.5" y="0.5" width="425" height="698" rx="31.5" transform="matrix(-1 0 0 1 923 345)" stroke="#999999"/><rect x="-0.5" y="0.5" width="425" height="698" rx="31.5" transform="matrix(-1 0 0 1 461 36)" stroke="#999999"/><rect x="-0.5" y="0.5" width="425" height="272" rx="31.5" transform="matrix(-1 0 0 1 461 771)" stroke="#999999"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM462 68C462 50.3269 447.673 36 430 36H68C50.3269 36 36 50.3269 36 68V1012C36 1029.67 50.3269 1044 68 1044H430C447.673 1044 462 1029.67 462 1012V68ZM892 36C909.673 36 924 50.3269 924 68V1012C924 1029.67 909.673 1044 892 1044H530C512.327 1044 498 1029.67 498 1012V68C498 50.3269 512.327 36 530 36H892Z" fill="url(#decor-bg)"/><rect x="498.5" y="36.5" width="425" height="1007" rx="31.5" stroke="#999999"/><rect x="36.5" y="36.5" width="425" height="1007" rx="31.5" stroke="#999999"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM996 68C996 50.3269 1010.33 36 1028 36H1390C1407.67 36 1422 50.3269 1422 68V277C1422 294.673 1407.67 309 1390 309H1028C1010.33 309 996 294.673 996 277V68ZM1028 345C1010.33 345 996 359.327 996 377V1012C996 1029.67 1010.33 1044 1028 1044H1390C1407.67 1044 1422 1029.67 1422 1012V377C1422 359.327 1407.67 345 1390 345H1028ZM1458 68C1458 50.3269 1472.33 36 1490 36H1852C1869.67 36 1884 50.3269 1884 68V703C1884 720.673 1869.67 735 1852 735H1490C1472.33 735 1458 720.673 1458 703V68ZM1490 771C1472.33 771 1458 785.327 1458 803V1012C1458 1029.67 1472.33 1044 1490 1044H1852C1869.67 1044 1884 1029.67 1884 1012V803C1884 785.327 1869.67 771 1852 771H1490Z" fill="url(#decor-bg)"/><rect x="996.5" y="36.5" width="425" height="272" rx="31.5" stroke="#999999"/><rect x="996.5" y="345.5" width="425" height="698" rx="31.5" stroke="#999999"/><rect x="1458.5" y="36.5" width="425" height="698" rx="31.5" stroke="#999999"/><rect x="1458.5" y="771.5" width="425" height="272" rx="31.5" stroke="#999999"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM1458 68C1458 50.3269 1472.33 36 1490 36H1852C1869.67 36 1884 50.3269 1884 68V1012C1884 1029.67 1869.67 1044 1852 1044H1490C1472.33 1044 1458 1029.67 1458 1012V68ZM1028 36C1010.33 36 996 50.3269 996 68V1012C996 1029.67 1010.33 1044 1028 1044H1390C1407.67 1044 1422 1029.67 1422 1012V68C1422 50.3269 1407.67 36 1390 36H1028Z" fill="url(#decor-bg)"/><rect x="1458.5" y="36.5" width="425" height="1007" rx="31.5" stroke="#999999"/><rect x="996.5" y="36.5" width="425" height="1007" rx="31.5" stroke="#999999"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><g clip-path="url(#clip0_288_106)"><rect x="48" y="48" width="1824" height="984" rx="32" fill="url(#decor-bg)"/><g opacity="0.5" filter="url(#filter0_f_288_106)"><rect x="1347" y="-240" width="691" height="691" rx="345.5" fill="$color2"/></g></g><rect x="48.5" y="48.5" width="1823" height="983" rx="31.5" stroke="#999999"/><defs><filter id="filter0_f_288_106" x="647" y="-940" width="2091" height="2091" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="350" result="effect1_foregroundBlur_288_106"/></filter><clipPath id="clip0_288_106"><rect x="48" y="48" width="1824" height="984" rx="32" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  GENERIC_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><g clip-path="url(#clip0_288_109)"><rect x="48" y="48" width="1824" height="984" rx="32" fill="url(#decor-bg)"/><g opacity="0.5" filter="url(#filter0_f_288_109)"><rect x="-118" y="-226" width="691" height="691" rx="345.5" fill="$color2"/></g></g><rect x="48.5" y="48.5" width="1823" height="983" rx="31.5" stroke="#999999"/><defs><filter id="filter0_f_288_109" x="-818" y="-926" width="2091" height="2091" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feGaussianBlur stdDeviation="350" result="effect1_foregroundBlur_288_109"/></filter><clipPath id="clip0_288_109"><rect x="48" y="48" width="1824" height="984" rx="32" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM1852 624C1869.67 624 1884 638.327 1884 656V1012C1884 1029.67 1869.67 1044 1852 1044H68C50.3269 1044 36 1029.67 36 1012V656C36 638.327 50.3269 624 68 624H1852Z" fill="url(#decor-bg)"/><rect x="36.5" y="624.5" width="1847" height="419" rx="31.5" stroke="#999999"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V624H0V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V1080H0V0ZM892 36C909.673 36 924 50.3269 924 68V1012C924 1029.67 909.673 1044 892 1044H68C50.3269 1044 36 1029.67 36 1012V68C36 50.3269 50.3269 36 68 36H892Z" fill="url(#decor-bg)"/><rect x="36.5" y="36.5" width="887" height="1007" rx="31.5" stroke="#999999"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H1920V1080H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V1080H1920V0ZM1028 36C1010.33 36 996 50.3269 996 68V1012C996 1029.67 1010.33 1044 1028 1044H1852C1869.67 1044 1884 1029.67 1884 1012V68C1884 50.3269 1869.67 36 1852 36H1028Z" fill="url(#decor-bg)"/><rect x="996.5" y="36.5" width="887" height="1007" rx="31.5" stroke="#999999"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 0H0V1080H960V0Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
