import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

interface IWrapperStyles {
  isDownloading: boolean
}
export const wrapperStyles =
  ({ isDownloading }: IWrapperStyles) =>
  ({ colors, dimensions }: ITheme) => css`
    position: relative;
    background: ${colors.dark.DEFAULT};
    height: ${dimensions.cardHeight.DEFAULT}px;
    width: ${dimensions.columnWidth.DEFAULT}px;
    overflow: hidden;
    border: 8px solid ${colors.dark.DEFAULT};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      transition: opacity 0.3s;
      ${isDownloading && `opacity: 0;`}
      width: 100%;
      height: 100%;
    }
  `
