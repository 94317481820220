import React, { useCallback, useState } from 'react'
import { useLanguage, useAdminApi } from 'src/hooks'
import { Button, INPUT_SIZE, Input, icons } from 'src/lib'
import {
  adminSearchBoxStyles,
  adminSearchBoxInlineStyles,
  adminSearchBoxTitleStyles,
  adminSearchBoxSubTitleStyles,
  adminSearchInputsWrapperStyles,
  adminSearchButtonWrapperStyles,
} from '../styles'
import { IAdminSearchFields } from '../types'

export const AdminSearch: React.FC = React.memo(() => {
  const { Trans, t } = useLanguage()
  const { searchUsers } = useAdminApi()

  const [supportPanelFields, setSupportPanelFields] =
    useState<IAdminSearchFields | null>(null)

  const handleFieldChange = useCallback(
    ({ fieldName, value }: { fieldName: string; value: string }) => {
      setSupportPanelFields({
        ...supportPanelFields,
        [fieldName]: value,
      })
    },
    [supportPanelFields],
  )

  const handleAdminSearch = useCallback(() => {
    if (supportPanelFields) {
      const updatedFields = {
        ...supportPanelFields,
        ...(supportPanelFields.deckId
          ? { deckId: Number(supportPanelFields.deckId) }
          : { deckId: undefined }),
      }
      searchUsers(updatedFields)
    }
  }, [supportPanelFields])

  return (
    <div css={adminSearchBoxStyles}>
      <div css={adminSearchBoxInlineStyles}>
        <div css={adminSearchBoxTitleStyles}>
          <Trans i18nKey={'admin.search.welcome_support_panel'} />
        </div>
        <div css={adminSearchBoxSubTitleStyles}>
          {t('admin.search.you_can_search_by')}
        </div>
        <div css={adminSearchInputsWrapperStyles}>
          <Input
            value={supportPanelFields?.email}
            icon={icons.find}
            placeholder={t('admin.table.e_mail')}
            size={INPUT_SIZE.MEDIUM}
            onChange={(value: string) =>
              handleFieldChange({ fieldName: 'email', value })
            }
            onEnter={handleAdminSearch}
          />
          <Input
            value={supportPanelFields?.deckId}
            icon={icons.find}
            placeholder={t('admin.search.deck_id')}
            size={INPUT_SIZE.MEDIUM}
            onChange={(value: string) =>
              handleFieldChange({ fieldName: 'deckId', value })
            }
            onEnter={handleAdminSearch}
          />
          <Input
            value={supportPanelFields?.deckShareShortCode}
            icon={icons.find}
            placeholder={t('admin.search.share_key')}
            size={INPUT_SIZE.MEDIUM}
            onChange={(value: string) =>
              handleFieldChange({ fieldName: 'deckShareShortCode', value })
            }
            onEnter={handleAdminSearch}
          />
        </div>
        <div css={adminSearchButtonWrapperStyles}>
          <Button
            text={t('common.actions.search')}
            onClick={handleAdminSearch}
          />
        </div>
      </div>
    </div>
  )
})
