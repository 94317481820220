import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { LayoutVersion } from 'src/types'

interface IWrapperStyles {
  isEditMode?: boolean
  version?: LayoutVersion
}
export const wrapperStyles =
  ({ isEditMode, version }: IWrapperStyles) =>
  (theme: ITheme) => css`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    ${isEditMode &&
    version !== 'v3' &&
    `
  		max-height: calc(100vh - ${theme.dimensions.headerHeight.DEFAULT}px);
		`}
  `

export const headerLayoutStyle = (theme: ITheme) => css`
  position: sticky;
  top: 0;
  z-index: ${theme.zIndex.header};
  height: 60px;
`
