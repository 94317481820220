import React, { useState, useCallback } from 'react'
import { Button } from 'src/lib/button/Button'
import {
  customDomainContainerStyles,
  inputContainerStyles,
  shareTextStyles,
  spanWrapperStyles,
} from 'src/components/share-view/styles'
import {
  Input,
  INPUT_SIZE,
  BUTTON_TYPE,
  BUTTON_THEME,
  BUTTON_WIDTH,
  INPUT_WIDTH,
  Icon,
  icons,
} from 'src/lib'
import { ConnectCustomDomainV2Props } from './types'
import { CustomDomain } from '../custom-domain/'
import { useLanguage, useOrgApi } from 'src/hooks'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'
import {
  customDomainItemStyles,
  customDomainLeftStyles,
  customDomainListStyles,
  customDomainVerifyStyles,
} from './styles'

export const ConnectCustomDomainV2: React.FC<ConnectCustomDomainV2Props> =
  React.memo(({ onClose, allDomains }) => {
    const { addCustomDomain, isLoading } = useOrgApi()
    const { t } = useLanguage()

    const { workspaceId } = useSelector(({ workspace }: RootState) => ({
      workspaceId: workspace.id,
    }))

    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [customDomain, setCustomDomain] = useState('')
    const [selectedDomain, setSelectedDomain] = useState('')
    const [domainList, setDomainList] = useState(allDomains)

    const handleConnectClick = useCallback(async () => {
      if (customDomain && !isLoading) {
        const res = await addCustomDomain(
          { domain: customDomain },
          workspaceId!,
        )
        if (res) {
          setDomainList([
            ...domainList,
            {
              id: 0,
              domain: customDomain,
              isVerified: false,
            },
          ])
          setCustomDomain('')
        }
      }
    }, [workspaceId, customDomain, domainList])

    return (
      <div>
        {!selectedDomain ? (
          <>
            <div css={customDomainContainerStyles}>
              <Icon
                icon={icons.chevron_left}
                size={20}
                onClick={() => onClose()}
              />
              <span css={spanWrapperStyles}>
                {t('share_modal.custom_domain.connect_your_custom_domain')}
              </span>
            </div>
            <span css={shareTextStyles}>
              {t('share_modal.custom_domain.add_your_subdomain')}
            </span>
            <div css={inputContainerStyles}>
              <Input
                width={INPUT_WIDTH.FULL}
                size={INPUT_SIZE.SMALL}
                placeholder={t(
                  'share_modal.custom_domain.share_link_placeholder',
                )}
                value={customDomain}
                onChange={(value) => {
                  setCustomDomain(value)
                  setIsButtonDisabled(value === '')
                }}
              />
              <Button
                text={t('common.actions.connect')}
                type={BUTTON_TYPE.DEFAULT}
                width={BUTTON_WIDTH.DEFAULT}
                theme={BUTTON_THEME.PRIMARY}
                disabled={isLoading || isButtonDisabled}
                onClick={handleConnectClick}
              />
              <div css={customDomainListStyles}>
                {domainList.map((cd, index) => (
                  <div key={`domains-${index}`} css={customDomainItemStyles}>
                    <div css={customDomainLeftStyles}>
                      <div>{cd.domain.replace(/http(s)?\:\/\//, '')}</div>
                      <div css={customDomainVerifyStyles}>
                        {cd.isVerified
                          ? t('share_modal.custom_domain.verified')
                          : t('share_modal.custom_domain.unverified')}
                      </div>
                    </div>
                    <div>
                      {cd.isVerified ? null : (
                        <Button
                          text={t('share_modal.custom_domain.info')}
                          type={BUTTON_TYPE.GHOST}
                          width={BUTTON_WIDTH.DEFAULT}
                          theme={BUTTON_THEME.PRIMARY}
                          isLink
                          onClick={() => {
                            setSelectedDomain(cd.domain)
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <CustomDomain
            onClose={() => {
              setSelectedDomain('')
            }}
            customDomain={selectedDomain}
            isVerified={
              domainList.find((d) => d.domain === selectedDomain)?.isVerified ||
              false
            }
          />
        )}
      </div>
    )
  })
