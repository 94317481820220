import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLanguage } from 'src/hooks'
import {
  PropButton,
  PropSection,
} from 'src/pages/deck-page/components/edit-properties/props'
import { icons } from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  ComponentQuoteDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { useDebouncer } from 'src/hooks'
import { IEditPropSection } from '../../types'
import { TextAlignmentsHorizontal } from 'src/types/api/enums'
import { PropQuill } from '../../props/prop-quill'
import { QuoteAuthorTextSize } from './QuoteAuthorTextSize'
import { QuoteTextSize } from './QuoteTextSize'
import { PropMutliInput } from '../../props/prop-mutli-input'
import { useTheme } from 'src/theme'

export const QuoteProps: React.FC<IEditPropSection> = React.memo(
  ({ components, className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const { colors } = useTheme()

    /// QUOTE TEXT
    const selectedQuote = useMemo(
      () =>
        components?.reduce(
          (a: string | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentQuoteDataSchema).quote
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const [quoteText, setQuoteText] = useState(selectedQuote)

    const handleQuoteChange = useCallback(
      (value?: string) => {
        const partialData: DeepPartial<ComponentQuoteDataSchema> = {
          quote: value || '',
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentQuoteDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const handleOnQuoteChange = useCallback((value?: string) => {
      setQuoteText(value)
    }, [])

    const debouncedQuote = useDebouncer(
      () => {
        handleQuoteChange(quoteText)
      },
      { delay: 500 },
    )
    useEffect(() => {
      debouncedQuote()
    }, [quoteText])

    /// AUTHOR TEXT
    const selectedAuthor = useMemo(
      () =>
        components?.reduce(
          (a: string | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.data as ComponentQuoteDataSchema).author
            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleAuthorChange = useCallback(
      (value?: string) => {
        const partialData: DeepPartial<ComponentQuoteDataSchema> = {
          author: value,
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentQuoteDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const [authorText, setAuthorText] = useState(selectedAuthor)
    const handleOnAuthorChange = useCallback((value?: string) => {
      setAuthorText(value)
    }, [])

    const debouncedAuthor = useDebouncer(
      () => {
        handleAuthorChange(authorText)
      },
      { delay: 500 },
    )

    useEffect(() => {
      debouncedAuthor()
    }, [authorText])

    /// ALIGNMENT
    const selectedAlignment = useMemo(
      () =>
        components?.reduce(
          (
            a: TextAlignmentsHorizontal | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentQuoteDataSchema).style.quote
              .font.alignmentHorizontal

            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleAlignmentClick = useCallback(
      (value?: TextAlignmentsHorizontal) => {
        const partialData: DeepPartial<ComponentQuoteDataSchema> = {
          style: {
            quote: {
              font: {
                alignmentHorizontal: value,
              },
            },
          },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentQuoteDataSchema>({
            components,
            partialData,
          })
        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <div className={className} {...dataAttr}>
        <PropSection>
          <>
            <div className="grid">
              <div className="c-1">
                {t('edit.properties.quote.size.label.text')}
              </div>
              <div className="c-1">
                {t('edit.properties.quote.size.label.author')}
              </div>
            </div>
            <div className="grid">
              <div className="c-1">
                <QuoteTextSize components={components} />
              </div>
              <div className="c-1">
                <QuoteAuthorTextSize components={components} />
              </div>
            </div>
          </>
        </PropSection>
        <PropSection title={t('edit.properties.quote.alignment')}>
          <div className="grid">
            <div className="c-1">
              <PropButton
                icon={icons.letf_alignment}
                onClick={() =>
                  handleAlignmentClick(TextAlignmentsHorizontal.LEFT)
                }
                isActive={selectedAlignment === TextAlignmentsHorizontal.LEFT}
              />
            </div>
            <div className="c-1">
              <PropButton
                icon={icons.center_alignment}
                onClick={() =>
                  handleAlignmentClick(TextAlignmentsHorizontal.CENTER)
                }
                isActive={selectedAlignment === TextAlignmentsHorizontal.CENTER}
              />
            </div>
            <div className="c-1">
              <PropButton
                icon={icons.right_alignment}
                onClick={() =>
                  handleAlignmentClick(TextAlignmentsHorizontal.RIGHT)
                }
                isActive={selectedAlignment === TextAlignmentsHorizontal.RIGHT}
              />
            </div>
          </div>
        </PropSection>
        <PropSection title={t('edit.properties.quote.placeholder')}>
          <div className="grid">
            <div className="c-full">
              <PropMutliInput
                inputs={[
                  {
                    label: t('edit.properties.quote.size.label.text'),
                    render: (
                      <PropQuill
                        background={colors.dark2.DEFAULT}
                        key="quote"
                        id="quote"
                        height="96px"
                        value={quoteText}
                        onChange={handleOnQuoteChange}
                      />
                    ),
                  },
                  {
                    label: t('edit.properties.quote.size.label.author'),
                    render: (
                      <PropQuill
                        background={colors.dark2.DEFAULT}
                        key="author"
                        id="author"
                        height="52px"
                        value={authorText}
                        onChange={handleOnAuthorChange}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </PropSection>
      </div>
    )
  },
)

QuoteProps.displayName = 'QuoteProps'
