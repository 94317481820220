export const planFeatures = {
  'plan_features.pro.monthly.title': 'Créditos de IA 750 / mês',
  'plan_features.pro.monthly.1': 'Exportação PDF / PPT',
  'plan_features.pro.monthly.2': 'Análise de Apresentações',
  'plan_features.pro.monthly.3': 'Link de Compartilhamento',
  'plan_features.pro.monthly.4':
    'Notificações por E-mail de Respostas de Formulário',
  'plan_features.pro.monthly.5': 'Notas de Slide e do Apresentador',
  'plan_features.pro.monthly.6': 'Geração de Dicas e P&R',
  'plan_features.pro.annual.title': 'Créditos de IA 750 / mês',
  'plan_features.pro.annual.1': 'Exportação PDF / PPT',
  'plan_features.pro.annual.2': 'Análise de Apresentações',
  'plan_features.pro.annual.3': 'Link de Compartilhamento',
  'plan_features.pro.annual.4':
    'Notificações por E-mail de Respostas de Formulário',
  'plan_features.pro.annual.5': 'Notas de Slide e do Apresentador',
  'plan_features.pro.annual.6': 'Geração de Dicas e P&R',
  'plan_features.business.monthly.title': 'Créditos de IA 1000 / mês',
  'plan_features.business.monthly.1': 'Todas as Funcionalidades de IA PRO +',
  'plan_features.business.monthly.2': 'Recursos de Marca',
  'plan_features.business.monthly.3': 'Resposta Avançada de Formulário',
  'plan_features.business.monthly.4': 'Webhook',
  'plan_features.business.monthly.5': 'Conexão de Domínio Personalizado',
  'plan_features.business.monthly.6': 'Equipe e Organização',
  'plan_features.business.annual.title': 'Créditos de IA 1000 / mês',
  'plan_features.business.annual.1': 'Todas as Funcionalidades de IA Pro +',
  'plan_features.business.annual.2': 'Recursos de Marca',
  'plan_features.business.annual.3': 'Resposta Avançada de Formulário',
  'plan_features.business.annual.4': 'Webhook',
  'plan_features.business.annual.5': 'Conexão de Domínio Personalizado',
  'plan_features.business.annual.6': 'Equipes e Organização',
}
