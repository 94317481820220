import TextField from '@mui/material/TextField'
import { observer } from 'mobx-react-lite'
import { TextElementType } from 'polotno/model/text-model'
import { IPolotnoComponent } from '../../types'
import { useFontSizeStyles } from './styles'

const TextFontSizeAction = observer<IPolotnoComponent>(({ store }) => {
  const element = store.selectedElements[0]
  const allTextItems = store.selectedElements?.every(
    (element) => element.type === 'text',
  )

  const fontSize = (element as TextElementType)?.fontSize ?? 16

  const { classes } = useFontSizeStyles()

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value)
    if (value > 4320) {
      value = 4320
    } else if (value < 5) {
      value = 5
    }
    store.selectedElements.forEach((element) => {
      element.set({
        fontSize: value,
      })
    })
  }

  return allTextItems ? (
    <TextField
      className={classes.input}
      slotProps={{
        input: {
          className: classes.input,
        },
      }}
      size="small"
      type="number"
      value={Math.ceil(fontSize)}
      onChange={handleTextChange}
    />
  ) : null
})
export default TextFontSizeAction
