import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

interface IWrapperStyles {
  selected?: boolean
}
export const wrapperStyles =
  ({ selected }: IWrapperStyles) =>
  ({ gap, colors, borderRadius, fontWeight, fontSize }: ITheme) => css`
    padding: ${gap.xsmall}px ${gap.small}px;
    color: ${selected ? colors.dark.DEFAULT : colors.white.DEFAULT};
    background: ${selected ? colors.white.DEFAULT : 'transparent'};
    border-radius: ${borderRadius[4]};
    cursor: pointer;
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.small};
    white-space: nowrap;
    user-select: none;
    transition:
      background 0.3s,
      color 0.3s;
  `
