import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useLanguage } from 'src/hooks'
import { TKey } from 'src/i18n/types'
import { useStyles } from './styles'
import { Section } from 'polotno/side-panel'

interface IBaseSectionProps extends Omit<Section, 'Tab'> {
  Icon: React.ReactNode
  labelIdentifier: TKey
  visibleInList?: boolean
}

const BaseSection = (baseProps: IBaseSectionProps) => {
  const { name, labelIdentifier, Icon, Panel, visibleInList = true } = baseProps
  const section = {
    name: name,
    Tab: (props: any) => {
      const { active, onClick } = props
      const { t } = useLanguage()
      const { classes, cx } = useStyles()
      return (
        <Stack
          direction="column"
          role="button"
          onClick={onClick}
          className={cx(classes.normal, active && classes.active)}
        >
          <IconButton
            aria-label="section-panel-button"
            color={active ? 'primary' : 'default'}
            className={classes.iconButton}
            size="small"
          >
            {Icon}
          </IconButton>
          <Typography variant="caption" sx={{ display: 'block' }}>
            {t(labelIdentifier)}
          </Typography>
        </Stack>
      )
    },
    Panel,
  }
  if (!visibleInList) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    section.Tab = () => null
  }
  return section
}

export default BaseSection
