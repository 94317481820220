import React, { useCallback, useEffect, useState } from 'react'

import { ICarousel } from './types'
import { carouselStyles, carouselInnerStyles } from './styles'

export const Carousel: React.FC<ICarousel> = React.memo(
  ({
    canChangeCurrentSlide,
    children,
    current = 0,
    totalQuestionCount,
    onPageChange,
    className,
    dataAttr,
  }) => {
    const [currentSlide, setCurrentSlide] = useState(0)

    useEffect(() => {
      if (totalQuestionCount <= currentSlide) {
        setCurrentSlide(totalQuestionCount - 1)
      }
    }, [totalQuestionCount, currentSlide])

    useEffect(() => {
      setCurrentSlide(current)
    }, [current])

    const handleSlideChange = useCallback(
      (index: number) => {
        if (canChangeCurrentSlide && onPageChange) onPageChange(index)
      },
      [canChangeCurrentSlide],
    )

    return (
      <div css={carouselStyles} className={className} {...dataAttr}>
        <div
          css={carouselInnerStyles}
          style={{
            width: `${totalQuestionCount * 100}%`,
            left: `-${current * 100}%`,
          }}
        >
          {children}
        </div>

        {totalQuestionCount > 1 && (
          <div className="dots">
            {[...Array(totalQuestionCount)].map((_, index) => (
              <div
                key={index}
                className={index === currentSlide ? 'active' : ''}
                onClick={() => handleSlideChange(index)}
              />
            ))}
          </div>
        )}
      </div>
    )
  },
)
