import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const mediaPropsStyles = css``

export const sliderWrapperStyles = (theme: ITheme) => css`
  padding: ${theme.gap.small}px;
  background: ${theme.colors.dark.DEFAULT};
  border-radius: ${theme.borderRadius[4]};
`

export const valueStyles = (theme: ITheme) => css`
  padding: ${theme.gap.small}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.dark.DEFAULT};
  border-radius: ${theme.borderRadius[4]};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.medium};
`
