import Typography from '@mui/material/Typography'
import { observer } from 'mobx-react-lite'
import { useLanguage } from 'src/hooks'

import Stack from '@mui/material/Stack'
import AutoScrollArea from '../scroll-area'
import { TextareaAutosize } from '../textarea-autosize'
import { useStyles } from './styles'
import { IPolotnoComponent } from '../../types'
import { useCallback, useEffect, useState } from 'react'
import { RootState } from 'src/store'
import { useDispatch, useSelector } from 'react-redux'
import { IDeckV3SlideNote } from 'src/types/api/responseObjects'
import { useDecksV3Api } from 'src/hooks/api/useDecksV3Api'
import debounce from 'lodash/debounce'
import { setSlideNote } from 'src/store/decks-v3/decksV3Slice'

const NotesPanel = observer<IPolotnoComponent>(({ store }) => {
  const { t } = useLanguage()
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const [note, setNote] = useState<Partial<IDeckV3SlideNote>>()
  const { saveSlideNote } = useDecksV3Api()
  const { slideNotes, deckId } = useSelector(
    ({ persistedDeckV3: deckV3, edit }: RootState) => ({
      deckId: edit.activeDeck.data?.deck?.id,
      slideNotes: deckV3.documentData?.extraData.slideNotes,
    }),
  )

  useEffect(() => {
    if (store.activePage) {
      const note = slideNotes?.find(
        (note) => note.internalId === store.activePage.custom?.internalId,
      )
      setNote(note)
    }
  }, [store.activePage, slideNotes])

  const onNoteChange = useCallback(
    async (value: string, slideId: string) => {
      if (deckId) {
        const slideNote = await saveSlideNote(deckId, {
          slideId,
          note: value,
        })
        slideNote && dispatch(setSlideNote(slideNote))
      }
    },
    [deckId, dispatch, saveSlideNote],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleNoteChange = useCallback(debounce(onNoteChange, 500), [
    onNoteChange,
  ])

  const handleNoteChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    debouncedHandleNoteChange.cancel()
    debouncedHandleNoteChange(
      event.target.value,
      store.activePage.custom.internalId,
    )
    setNote((prev) => ({ ...prev, note: event.target.value }))
  }

  return (
    <AutoScrollArea>
      <Stack className={classes.container}>
        <Typography variant="body2" fontWeight={500}>
          {t('edit.panel.notes')}
        </Typography>
        <TextareaAutosize
          value={note?.note}
          onChange={handleNoteChange}
          placeholder="Add slide notes..."
          minRows={4}
        />
      </Stack>
    </AutoScrollArea>
  )
})

export default NotesPanel
