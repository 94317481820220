import { Global, css } from '@emotion/react'
import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { useActiveCanvas, useDecksApi } from 'src/hooks'
import { wrapperStyles } from './styles'

export const StyleBuilderPageLayout: React.FC = React.memo(() => {
  const { isLoading } = useDecksApi()
  const { cssVariables } = useActiveCanvas({})

  if (isLoading) {
    return null
  }

  return (
    <Suspense>
      <Global
        styles={css`
          body {
            ${cssVariables};
          }
        `}
      />
      <div css={wrapperStyles({ isEditMode: true, version: 'v3' })}>
        <Outlet />
      </div>
    </Suspense>
  )
})
