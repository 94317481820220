export const CanvasRectEventName = 'canvas-rect-event'

export interface CanvasRectEventDetail {
  rect: DOMRect | undefined
  scale: number
}

export class CanvasRectEvent extends CustomEvent<CanvasRectEventDetail> {
  constructor(detail: CanvasRectEventDetail) {
    super(CanvasRectEventName, { detail })
  }
}
