import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { IInitFooter } from './types'
import { initFooterStyles } from './styles'
import { BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'
import { useLanguage, useLocation } from 'src/hooks'
import { useNavigate } from 'react-router-dom'
import { setPivotDecks, setPivotLength } from 'src/store'

export const InitFooter: React.FC<IInitFooter> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const navigate = useNavigate()
    const { path, lastPath, backFromPath } = useLocation()
    const dispatch = useDispatch()

    const onBackClick = useCallback(() => {
      dispatch(setPivotDecks([]))
      dispatch(setPivotLength(0))
      navigate(path[2] ? backFromPath : '/dashboard', { replace: true })
    }, [path, backFromPath])

    const onScratchClick = useCallback(() => {
      dispatch(setPivotDecks([]))
      dispatch(setPivotLength(0))
      navigate('/init/scratch', { replace: true })
    }, [])

    const onTemplateClick = useCallback(() => {
      navigate('/init/template', { replace: true })
    }, [])

    const renderSwitchButton = useMemo(() => {
      if (lastPath === 'template') {
        return (
          <Button
            onClick={onScratchClick}
            icon={icons.plus}
            text={t('init.start_from_scratch')}
          />
        )
      }

      return <Button onClick={onTemplateClick} text={t('init.see_templates')} />
    }, [lastPath])

    return (
      <div css={initFooterStyles} className={className} {...dataAttr}>
        <div className="inner">
          <div className="edge">
            <Button
              type={BUTTON_TYPE.GHOST}
              theme={BUTTON_THEME.WHITE}
              icon={icons.chevron_left}
              isLink
              text={t('common.actions.back')}
              onClick={onBackClick}
            />
          </div>
          {renderSwitchButton}
          <div className="edge"></div>
        </div>
      </div>
    )
  },
)
