import React, { useCallback, useState } from 'react'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'
import { MainPanel, Subscriptions, Plans, ProfileSettings } from './components'
import { contentAreaStyles, wrapperStyles } from './styles'
import { ISettingsPanel } from './types'
import { ACTION_CLICKS } from 'src/plugins/google/consts'

export const SettingsPanel: React.FC<ISettingsPanel> = React.memo(
  ({ onCloseClick }) => {
    const [currentPanel, setCurrentPanel] = useState<number>()

    const handlePanelOpen = useCallback((index: number) => {
      setCurrentPanel(index)
    }, [])

    const handleOnCloseClick = useCallback(() => {
      currentPanel !== undefined ? setCurrentPanel(undefined) : onCloseClick?.()
    }, [currentPanel])

    const handlePanelClose = () => {
      handleOnCloseClick()
    }

    const Panel = [
      <Subscriptions key="subscriptions" onPanelOpen={handlePanelOpen} />,
      <Plans key="plans" context={ACTION_CLICKS.UPGRADE_CLICK} />,
      <ProfileSettings key="profile-settings" />,
    ]

    return (
      <div css={wrapperStyles({ isMainPanel: currentPanel === undefined })}>
        <div className="close-button-area">
          <Button
            icon={currentPanel !== undefined ? icons.chevron_left : icons.close}
            size={BUTTON_SIZE.AUTO}
            type={BUTTON_TYPE.GHOST}
            theme={BUTTON_THEME.ELEMENTS}
            iconSize={20}
            onClick={handleOnCloseClick}
          />
        </div>
        <div css={contentAreaStyles(false)}>
          {currentPanel !== undefined ? (
            Panel[currentPanel]
          ) : (
            <MainPanel
              onPanelOpen={handlePanelOpen}
              onPanelClose={handlePanelClose}
            />
          )}
        </div>
      </div>
    )
  },
)
