import React, { useCallback } from 'react'

import { FolderItem } from 'src/lib'
import { IDashboardFolderSharedWithMe } from './types'

export const DashboardFolderSharedWithMe: React.FC<IDashboardFolderSharedWithMe> =
  React.memo(({ id, name, onClick, isActive = false }) => {
    const onClickHandler = useCallback(() => {
      onClick()
    }, [isActive])

    return (
      <FolderItem
        key={id}
        isActive={isActive}
        text={name}
        onClick={onClickHandler}
      />
    )
  })

DashboardFolderSharedWithMe.displayName = 'DashboardFolderSharedWithMe'
