import React, { useCallback, useState } from 'react'

import { IGradientBar } from './types'
import { gradientBarStyles, gradientStep } from './styles'

export const GradientBar: React.FC<IGradientBar> = React.memo(
  ({ colors, onChange, className, dataAttr }) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const handleClick = useCallback((index: number) => {
      setSelectedIndex(index)
      onChange?.(index)
    }, [])

    return (
      <div css={gradientBarStyles} className={className} {...dataAttr}>
        <div
          css={gradientStep({ isSelected: selectedIndex === 0 })}
          style={{ left: 0 }}
          onClick={() => handleClick(0)}
        >
          <div style={{ background: colors?.[0] }}></div>
        </div>
        <div
          css={gradientStep({ isSelected: selectedIndex === 1 })}
          style={{ right: 0 }}
          onClick={() => handleClick(1)}
        >
          <div style={{ background: colors?.[1] }}></div>
        </div>
        <div
          className="gradient-bar"
          style={{
            background: `linear-gradient(90deg, ${colors?.[0]} 0%, ${colors?.[1]} 100%)`,
          }}
        ></div>
      </div>
    )
  },
)

GradientBar.displayName = 'GradientBar'
