import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const sliderStyles = ({ colors, borderRadius }: ITheme) => css`
  position: relative;
  width: 100%;
  height: 20px;

  .filled-range {
    position: absolute;
    background: ${colors.white.DEFAULT};
    top: 50%;
    left: 3px;
    height: 8px;
    overflow: hidden;
    transform: translateY(-50%);
    border-radius: ${borderRadius[5]};
    display: block;
    pointer-events: none;
    min-width: 5%;
  }
`

export const barStyles = ({ colors, borderRadius }: ITheme) => css`
  -webkit-appearance: none;
  background: ${colors.dark[70]};
  height: 8px;
  width: 100%;
  z-index: 1;
  border-radius: ${borderRadius[4]};

  &::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background: ${colors.white.DEFAULT};
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: ${borderRadius.DEFAULT};
  }

  &::-moz-range-thumb {
    background: ${colors.white.DEFAULT};
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: ${borderRadius.DEFAULT};
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
`
