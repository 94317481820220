import { svgToURL } from 'polotno/utils/svg'

export const LINES = [
  {
    preview: svgToURL(`
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
    <path
      stroke="#909AAE"
      strokeWidth="4"
      d="M 25 6 L 29 8 L 25 10"
      fill="none"
      strokeLinejoin="round"
      strokeLinecap="round"
    ></path>
    <path
      stroke="#909AAE"
      strokeWidth="8" d="M 1 8 L 29 8"
      strokeLinejoin="round"
      strokeLinecap="round"
    ></path>
  </svg>
`),
    data: { endHead: 'arrow' },
  },
  {
    preview: svgToURL(`
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
    <path stroke="#909AAE" strokeWidth="8" d="M 1 8 L 30 8"></path>
  </svg>
  `),
    data: {},
  },
  {
    preview: svgToURL(`
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
    <path stroke="#909AAE" strokeWidth="8" stroke-dasharray="4 2" d="M 1 8 L 30 8"></path>
  </svg>
  `),
    data: { dash: [10, 4] },
  },
  {
    preview: svgToURL(`
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
      <path
        stroke="#909AAE"
        strokeWidth="4"
        d="M 25 6 L 29 8 L 25 10 Z"
        fill="#909AAE"
        strokeLinejoin="round"
        strokeLinecap="round"
      ></path>
      <path
        stroke="#909AAE"
        strokeWidth="8" d="M 1 8 L 29 8"
        strokeLinejoin="round"
        strokeLinecap="round"
      ></path>
      <circle cx="3" cy="8" r="2" fill="#909AAE"></circle>
    </svg>
  `),
    data: { startHead: 'circle', endHead: 'triangle' },
  },
]
