import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { IMenu } from './types'

type IWrapperStyles = Pick<IMenu, 'shadow'>
export const wrapperStyles =
  ({ shadow }: IWrapperStyles) =>
  (theme: ITheme) => css`
    background: ${theme.colors.white.DEFAULT};
    padding: ${theme.spacingV2['3xsmall']} 0;
    border-radius: ${theme.borderRadius[4]};
    ${shadow ? `box-shadow: ${theme.boxShadow[2]};` : ``}
  `
