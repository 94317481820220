import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ITable } from './types'

type ITableStyles = Pick<ITable, 'styles'>
export const tableStyles =
  ({ styles }: ITableStyles) =>
  ({ colors, fontWeight }: ITheme) => css`
    background: ${styles?.colorBg};
    position: relative;
    width: 100%;
    max-height: 100px;
    height: 100%;
    border-radius: ${styles?.borderRadius}px;
    border-collapse: collapse;
    border: ${styles?.borderWidth}px solid ${styles?.colorBorder};
    color: ${styles?.colorText};

    thead th {
      top: 0;
      position: sticky;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 10px;
        bottom: 1px;
        // sticky border: border-bottom: ${styles?.borderWidth}px solid ${styles?.colorBorder};
        left: 0;
      }
    }

    thead th {
      color: ${styles?.colorPri};
      font-weight: ${fontWeight.regular};
    }

    td,
    th {
      border-bottom: ${styles?.borderWidth}px solid ${styles?.colorBorder};
      border-right: ${styles?.borderWidth}px solid ${styles?.colorBorder};
      background: ${styles?.colorBg};
      padding: ${styles?.gaps}px;
      vertical-align: top;
      word-break: break-word;

      textarea {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        position: absolute;
        resize: none;
        opacity: 0;
        padding: ${styles?.gaps}px;
        outline: none;
        border: ${styles?.borderWidth}px solid ${styles?.colorPri};
        overflow: hidden;
        word-break: break-word;

        &:focus {
          opacity: 1;
        }
      }
    }

    th {
      padding: ${styles?.gaps}px;

      textarea {
        text-align: center;
        color: inherit;
      }
    }

    td {
      position: relative;

      &.error {
        border: 1px solid ${colors.error.DEFAULT};
      }

      .error-text {
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 0;
        background: ${colors.error.DEFAULT};
        color: ${colors.white.DEFAULT};
        padding: 2px;
      }

      &.active {
        background: ${styles?.colorBgAct};
      }
    }

    td.row-number {
      width: 50px;
      text-align: center;
      cursor: pointer;
    }

    th,
    td.row-number {
      background: ${styles?.colorBgAct};

      textarea {
        background: ${styles?.colorBgAct};
      }
    }
  `
