import { COLOR } from './types'

export const data = {
  1: COLOR.data_1,
  2: COLOR.data_2,
  3: COLOR.data_3,
  4: COLOR.data_4,
  5: COLOR.data_5,
  6: COLOR.data_6,
  7: COLOR.data_7,
  8: COLOR.data_8,
  9: COLOR.data_9,
  10: COLOR.data_10,
  11: COLOR.data_11,
  12: COLOR.data_12,
  13: COLOR.data_13,
  14: COLOR.data_14,
  15: COLOR.data_15,
  16: COLOR.data_16,
  17: COLOR.data_17,
  18: COLOR.data_18,
  19: COLOR.data_19,
  20: COLOR.data_20,
}
