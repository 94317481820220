import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = ({ gap, fontSize, fontWeight }: ITheme) => css`
  margin-bottom: ${gap.xlarge}px;

  .profile-picture-area {
    display: flex;
    justify-content: center;
    margin-bottom: ${gap.medium}px;
  }

  .full-name {
    text-align: center;
    font-size: ${fontSize.large};
    font-weight: ${fontWeight.medium};
    margin-bottom: ${gap['3xsmall']}px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .email {
    text-align: center;
    font-size: ${fontSize.medium};
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
