import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const svgMaskedStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  width: 100%;
  height: 100%;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center center;
`
