import { setHighlighterStyle, setTransformerStyle } from 'polotno/config'
import createStore, { StoreType } from 'polotno/model/store'
import theme from 'src/theme/muiTheme'
import createTransformerAttributes from './getTransformerIcon'
import env from 'react-dotenv'

export class PolotnoStore {
  store: StoreType
  constructor() {
    this.store = createStore({
      key: env.EDITOR_API_KEY,
      showCredit: false,
    })

    setTransformerStyle({
      anchorStroke: theme.palette.primary.main,
      anchorStyleFunc: (anchor) => {
        if (!anchor.hasName('rotater')) {
          anchor.cornerRadius(10)
          anchor.stroke(theme.palette.primary.main)
        } else {
          anchor.stroke('transparent')
          anchor.setSize(24)
          anchor.setAttrs(createTransformerAttributes())
        }

        if (anchor.hasName('top-center') || anchor.hasName('bottom-center')) {
          anchor.height(8)
          anchor.offsetY(4)
          anchor.width(12)
          anchor.offsetX(6)
        }
        if (anchor.hasName('middle-left') || anchor.hasName('middle-right')) {
          anchor.height(12)
          anchor.offsetY(6)
          anchor.width(8)
          anchor.offsetX(4)
        }
      },
      borderStroke: theme.palette.primary.main,
    })

    setHighlighterStyle({
      cornerRadius: 4,
      stroke: theme.palette.primary.main,
    })
  }
}
