import React, { useCallback, useMemo } from 'react'
import { Slider } from 'src/lib/slider'
import { IEditPropSection } from '../../types'
import { useDispatch } from 'react-redux'
import {
  ComponentStyleSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { ComponentServices } from 'src/services'
import { DeepPartial } from '@reduxjs/toolkit'
import { sliderWrapperStyles, valueStyles } from './styles'
import { PropSection } from '../../props'
import { useLanguage } from 'src/hooks'

export const MediaOpacity: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const selectedOpacity = useMemo(
      () =>
        components?.reduce(
          (a: number | undefined, c: UpdateComponentSchema) => {
            const targetField = (c.style as ComponentStyleSchema).opacity

            if (a === undefined && targetField !== null) {
              return targetField
            }

            return targetField !== a ? 1 : a
          },
          undefined,
        ),
      [components],
    )

    const handleOnChange = useCallback(
      (newOpacity: number) => {
        const partialUpdate: DeepPartial<UpdateComponentSchema> = {
          style: { opacity: Number((newOpacity / 100).toFixed(2)) },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponent<UpdateComponentSchema>({
            components,
            partialUpdate,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const opacityValue = useMemo(
      () => (selectedOpacity === undefined ? 100 : selectedOpacity * 100),
      [selectedOpacity],
    )

    return (
      <>
        <PropSection title={t('edit.properties.media.image_opacity')}>
          <div className="grid">
            <div css={sliderWrapperStyles} className="c-3">
              <Slider
                min={0}
                max={100}
                value={opacityValue}
                onChange={handleOnChange}
              />
            </div>
            <div css={valueStyles} className="c-1">
              {opacityValue.toFixed(0) + '%'}
            </div>
          </div>
        </PropSection>
      </>
    )
  },
)

MediaOpacity.displayName = 'MediaOpacity'
