import React from 'react'
import { timelineItemStyles } from './styles'
import { ITimelineItem } from './types'
import { ItemText } from '../../ItemText'
import { ListLayoutDirections } from 'src/types/api/enums'

export const TimelineItem: React.FC<ITimelineItem> = React.memo(
  ({
    data,
    width,
    componentData,
    overrides,
    itemCount,
    index,
    className,
    dataAttr,
  }) => {
    return (
      <div
        css={timelineItemStyles({
          direction: componentData?.style.layout.direction,
          itemCount,
        })}
        onClick={() => {
          document.dispatchEvent(
            new CustomEvent('list-item-click', { detail: index }),
          )
        }}
        className={className}
        {...dataAttr}
      >
        <div
          className="pointer"
          style={{
            ...(overrides?.fontSize
              ? { width: `calc(${overrides.fontSize} * 0.6)` }
              : {}),
            background:
              componentData?.style.colorBullet?.text?.colors[0] ||
              componentData?.style.colorIcon.text.colors[0],
          }}
        />
        <div
          className="item-detail"
          style={{
            width:
              componentData?.style.layout.direction ===
              ListLayoutDirections.VERTICAL
                ? `calc(${width}px - 30em)`
                : '100%',
          }}
        >
          <ItemText
            text={data?.title || ''}
            fontFamily="var(--font-primary)"
            fontWeight="var(--font-primary-weight)"
            fontSize={overrides?.fontSize}
            color={componentData?.style.color.text}
          />
          <ItemText
            text={data?.body || ''}
            fontFamily="var(--font-secondary)"
            fontWeight="var(--font-secondary-weight)"
            fontSize={overrides?.fontBodySize}
            color={componentData?.style.colorBody.text}
          />
        </div>
      </div>
    )
  },
)
