import Button from '@mui/material/Button'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { IPolotnoComponent } from '../../types'
import { useLanguage } from 'src/hooks'

const GroupAction = observer<IPolotnoComponent>(({ store }) => {
  const { t } = useLanguage()
  const isGroupVisible = store.selectedElements.length > 1
  const isUngroupVisible =
    store.selectedElements.length === 1 &&
    store.selectedElements[0].type === 'group'

  const handleClick = useCallback(() => {
    if (isGroupVisible) {
      store.groupElements(store.selectedElementsIds)
    } else if (isUngroupVisible) {
      store.ungroupElements([store.selectedElements[0].id])
      store.selectElements([store.selectedElements[0].id])
    }
  }, [isGroupVisible, isUngroupVisible, store])

  return isGroupVisible || isUngroupVisible ? (
    <Button
      size="small"
      variant="outlined"
      disableElevation
      onClick={handleClick}
      sx={{
        flexShrink: 0,
      }}
    >
      {isGroupVisible ? t('v3.group') : t('v3.ungroup')}
    </Button>
  ) : (
    <div></div>
  )
})

export default GroupAction
