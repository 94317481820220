import React, { PropsWithChildren, useCallback, useRef } from 'react'
import Stack from '@mui/material/Stack'
import { CSSTransition } from 'react-transition-group'

import { EXPANDER_DIR, EXPANDER_THEME, IExpander } from './types'
import { expanderStyles } from './styles'

import expanderWhite from 'src/assets/images/expander-white.svg'
import expanderDark from 'src/assets/images/expander-dark.svg'

const OuterChilWrap: React.FC<PropsWithChildren> = ({ children }) => {
  return <Stack sx={{ position: 'relative' }}>{children}</Stack>
}

export const Expander: React.FC<IExpander> = React.memo(
  ({
    theme = EXPANDER_THEME.LIGHT,
    dir = EXPANDER_DIR.RIGHT,
    expanded = true,
    props,
    onStateChange,
    className,
    dataAttr,
    children,
    outerChildren,
    expander,
  }) => {
    const nodeRef = useRef(null)

    const handelOnChange = useCallback(() => {
      onStateChange?.(!expanded)
    }, [expanded, onStateChange])

    const OuterChildWrapper = outerChildren ? OuterChilWrap : React.Fragment

    return (
      <CSSTransition nodeRef={nodeRef} in={expanded} timeout={500}>
        <OuterChildWrapper>
          <div
            ref={nodeRef}
            css={expanderStyles({ expanded, dir, theme, props })}
            className={className}
            {...dataAttr}
          >
            <div className="content-wrapper">
              <div className="content">{children}</div>
            </div>
            <div className="button" onClick={handelOnChange}>
              {expander ? (
                expander
              ) : (
                <img
                  className="button-icon"
                  src={
                    theme === EXPANDER_THEME.LIGHT
                      ? expanderDark
                      : expanderWhite
                  }
                />
              )}
            </div>
          </div>
          {outerChildren}
        </OuterChildWrapper>
      </CSSTransition>
    )
  },
)

Expander.displayName = 'Expander'
