import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { ISubscriptions, SubscriptionSettings } from './types'
import { wrapperStyles } from './styles'
import { PLAN_BADGE_TYPE, PlanBadge } from 'src/components/plan-badge'
import { IListCard, ListCard, Modal, TooltipView, icons } from 'src/lib'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { DateTimeServices, StringServices } from 'src/services'
import {
  useLanguage,
  useUserApi,
  useNotification,
  useInterval,
  usePlanApi,
} from 'src/hooks'
import {
  CancelReasonTypes,
  PlanNames,
  UserPlanPaymentStates,
} from 'src/types/api/enums'
import { useOrgApi } from 'src/hooks/api/useOrgApi'
import { sendEvent } from 'src/plugins/google/gtag'
import { GTAG_EVENTS, ACTION_CLICKS } from 'src/plugins/google/consts'
import { useStripePortalUrls } from './hooks/useStripePortalUrls'
import { CancelPromotionDialog } from './components/cancel-promotion-dialog'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'
import usePostHogCapture from 'src/hooks/usePostHogCapture'
import { buildPlanType } from 'src/services/stringServices'
import { CancelReasonDialog } from './components/cancel-reason-dialog'

export const Subscriptions: React.FC<ISubscriptions> = React.memo(
  ({ onPanelOpen, className, dataAttr }) => {
    const { t } = useLanguage()
    const posthogCapture = usePostHogCapture()

    const {
      getAICreditsSummary,
      getAICredits,
      getPortalUrl,
      getUser,
      getOrganizations,
      isLoading: userApiLoading,
    } = useUserApi()
    const {
      isLoading: planApiLoading,
      applyCancelPromotion,
      cancelPlan,
    } = usePlanApi()

    const { getOrgAiCredits, getOrgAICreditsSummary, activateOrganization } =
      useOrgApi()

    const { success } = useNotification()
    const { getStripePortalUrl } = useStripePortalUrls()
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
    const [showReasonDialog, setShowReasonDialog] = useState(false)
    const {
      data,
      isCancelled,
      aiCredits,
      orgAiCredits,
      orgs,
      userId,
      workspaceId,
      paymentFailed,
      orgAicSpends,
      userAicSpends,
      isCancelPromoUsed,
      isChurn,
    } = useSelector(({ user, org, workspace }: RootState) => ({
      data: user.data,
      isChurn: user.meta?.subscription?.isChurn,
      isCancelled: user.meta?.subscription?.cancelAtPeriodEnd,
      isCancelPromoUsed: user.data?.isCancelPromoUsed,
      aiCredits: user.aicredits,
      orgAiCredits: org.aicredits,
      orgs: user.organizationUsers,
      userId: user.data?.id,
      workspaceId: workspace.id,
      paymentFailed: workspace.paymentFailed,
      orgAicSpends: org.aicredits.summary?.aicSpends,
      userAicSpends: user.aicredits.summary?.aicSpends,
    }))

    const isSelectedWorkspaceOwned = useMemo(() => {
      const selectedOrg = orgs?.find(
        (org) => org.organization.id === workspaceId,
      )
      return selectedOrg?.organization.ownerUserId === userId
    }, [workspaceId, userId])

    const isUpgradeable = useMemo(() => {
      const planName = data?.activeUserPlan?.plan?.name
      return data?.activeUserPlan?.nextRenewDate
        ? data.activeUserPlan.nextRenewDate &&
            planName &&
            (planName === PlanNames.PRO ||
              (planName === PlanNames.BUSINESS && isSelectedWorkspaceOwned))
        : data?.activeUserPlan?.nextRenewDate !== null
    }, [
      data?.activeUserPlan?.plan?.name,
      data?.activeUserPlan?.nextRenewDate,
      isSelectedWorkspaceOwned,
    ])

    useEffect(() => {
      getPortalUrl()

      if (workspaceId && !paymentFailed) {
        getOrgAiCredits(workspaceId)
        getOrgAICreditsSummary({
          id: workspaceId,
          startDate: DateTimeServices.dayjs(
            data?.activeUserPlan?.nextRenewDate || new Date(),
          )
            .subtract(1, 'month')
            .toDate(),
          endDate: DateTimeServices.dayjs(
            data?.activeUserPlan?.nextRenewDate || new Date(),
          ).toDate(),
        }).then(() => {})
      } else {
        getAICredits()
        getAICreditsSummary({
          startDate: DateTimeServices.dayjs(data?.activeUserPlan?.nextRenewDate)
            .subtract(1, 'month')
            .toDate(),
          endDate: DateTimeServices.dayjs(
            data?.activeUserPlan?.nextRenewDate,
          ).toDate(),
        }).then(() => {})
      }
    }, [workspaceId, paymentFailed])

    const planTitle = useMemo(
      () =>
        `${
          workspaceId
            ? StringServices.capitalCase(t('enum.planNames.1'))
            : data?.activeUserPlan?.plan?.name
              ? StringServices.capitalCase(
                  t(
                    data?.activeUserPlan?.plan?.name
                      ? `enum.planNames.${data?.activeUserPlan?.plan?.name}`
                      : '',
                  ),
                )
              : t('common.free')
        } Plan`,
      [workspaceId, data?.activeUserPlan?.plan?.name],
    )

    const paymentStateText = useMemo(() => {
      if (workspaceId && paymentFailed)
        return t('subscriptions.subs_state.failed')
      switch (data?.activeUserPlan?.paymentState) {
        case UserPlanPaymentStates.PAYMENT_PENDING:
          return t('subscriptions.subs_state.pending')
        case UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED:
          return t('subscriptions.subs_state.action_required')
        case UserPlanPaymentStates.PAYMENT_FAILED:
          return t('subscriptions.subs_state.failed')
        default:
          if (isCancelled) {
            return t('subscriptions.subs_state.canceled')
          } else {
            return t('subscriptions.subs_state.active')
          }
      }
    }, [
      data?.activeUserPlan?.paymentState,
      isCancelled,
      workspaceId,
      paymentFailed,
    ])

    const planRenewInfo = useMemo(() => {
      if (workspaceId && !isSelectedWorkspaceOwned) return ''

      return data?.activeUserPlan
        ? t(
            isCancelled
              ? 'subscriptions.plan_cancel_info'
              : 'subscriptions.plan_renew_info',
            {
              date: DateTimeServices.formatDate(
                data?.activeUserPlan?.expireDate,
              ),
            },
          )
        : t('subscriptions.plan_renew_info_free')
    }, [
      data?.activeUserPlan,
      isCancelled,
      workspaceId,
      isSelectedWorkspaceOwned,
    ])

    const creditInfo = useMemo(() => {
      if (!isSelectedWorkspaceOwned) return ''
      if (data?.activeUserPlan?.nextRenewDate) {
        return t(
          isCancelled
            ? 'subscriptions.credit_cancel_info'
            : 'subscriptions.credit_renew_info',
          {
            date: DateTimeServices.formatDate(
              data?.activeUserPlan?.nextRenewDate,
            ),
          },
        )
      }
    }, [data?.activeUserPlan?.nextRenewDate, isCancelled, workspaceId])

    const [paymentAwaits, setPaymentAwaits] = useState<null | boolean>(null)

    //checkPayment will be moved in hook and refactored
    const checkPayment = useCallback(async () => {
      if (!isCancelled) {
        await getUser()
      } else {
        setPaymentAwaits(false)
        localStorage.removeItem('payment-awaits')
      }
    }, [])
    useInterval(
      () => {
        const paymentLS = localStorage.getItem('payment-awaits')
        if (paymentAwaits === true && paymentLS) {
          checkPayment()
        }
      },
      isCancelled ? null : 9000,
    )

    const handleCustomerPanelClick = useCallback(
      async (setting: SubscriptionSettings) => {
        setPaymentAwaits(true)
        localStorage.setItem('payment-awaits', 'true')
        const res = await getPortalUrl()
        const url = getStripePortalUrl(res, setting)
        window.open(url, '_self', 'noopener,noreferrer')
      },
      [],
    )

    const shouldUpdatePaymentMethod = useMemo(() => {
      switch (data?.activeUserPlan?.paymentState) {
        case UserPlanPaymentStates.PAYMENT_PENDING:
          return true
        case UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED:
          return true
        case UserPlanPaymentStates.PAYMENT_FAILED:
          return true
        default:
          false
      }
    }, [data?.activeUserPlan?.paymentState])

    const handleUpgradeButtonClick = useCallback(async () => {
      if (shouldUpdatePaymentMethod) {
        await handleCustomerPanelClick(SubscriptionSettings.PAYMENT_METHOD)
        return
      }
      if (paymentFailed && workspaceId) {
        const res = await activateOrganization(workspaceId)

        if (res) {
          window.open(res, '_self', 'noopener,noreferrer')
        } else {
          await getUser()
          await getOrganizations()
          success('common.informative.payment_successful')
        }
      } else {
        sendEvent(GTAG_EVENTS.PURCHASE_INTENT, {
          user_email: data?.email,
          purchase_intent_method: ACTION_CLICKS.UPGRADE_CLICK,
        })
        onPanelOpen?.(1)
      }
    }, [paymentFailed, workspaceId, shouldUpdatePaymentMethod])

    const creditsMapper = useMemo(
      () =>
        (orgAicSpends ?? userAicSpends)?.map((item) => ({
          text: t(`enum.aiCreditSpendReasons.${item.aiCreditReason.name}`),
          value: item.total.toString(),
        })),
      [orgAicSpends, userAicSpends],
    )

    const settings: IListCard['items'] = useMemo(() => {
      const list: IListCard['items'] = [
        ...(data?.activeUserPlan
          ? [
              {
                text: t('subscriptions.change_payment_method'),
                icon: icons.link,
                onClick: () => {
                  handleCustomerPanelClick(SubscriptionSettings.PAYMENT_METHOD)
                },
              },
              {
                text: t('subscriptions.update_billing_info'),
                icon: icons.link,
                onClick: () => {
                  handleCustomerPanelClick(SubscriptionSettings.UPDATE_BILLING)
                },
              },
            ]
          : []),
      ]
      if (data?.activeUserPlan || isChurn) {
        list.push({
          text: t('subscriptions.invoices'),
          icon: icons.link,
          onClick: () => {
            handleCustomerPanelClick(SubscriptionSettings.INVOICES)
          },
        })
      }

      if (data?.activeUserPlan) {
        if (isCancelled) {
          list.push({
            text: t('subscriptions.renew_subscription'),
            onClick: () => {
              handleCustomerPanelClick(SubscriptionSettings.RENEW)
            },
          })
        } else {
          list.push({
            text: t('subscriptions.cancel_subscription'),
            onClick: () => {
              if (!isCancelPromoUsed) {
                setIsCancelModalOpen(true)
                return
              }
              setShowReasonDialog(true)
            },
          })
        }
      }
      return list
    }, [isCancelPromoUsed, isCancelled])

    const onCancelPromotionApply = async () => {
      posthogCapture(POSTHOG_EVENTS.CANCEL_PROMOTION_APPLY, {
        plan_type: buildPlanType(
          data?.activeUserPlan?.plan?.name,
          data?.activeUserPlan?.plan?.period,
        ),
      })
      const res = await applyCancelPromotion()
      if (res) {
        setIsCancelModalOpen(false)
        success('subscriptions.cancel_promo_modal.success')
        await getUser()
        await getOrganizations()
      }
    }
    const onCancelPromotionCancel = () => {
      posthogCapture(POSTHOG_EVENTS.CANCEL_PROMOTION_CANCEL, {
        plan_type: buildPlanType(
          data?.activeUserPlan?.plan?.name,
          data?.activeUserPlan?.plan?.period,
        ),
      })
      setIsCancelModalOpen(false)

      setShowReasonDialog(true)
    }
    const onCancelReasonSubmit = async (cancelReason: string) => {
      await cancelPlan({
        reasonCategory: CancelReasonTypes.OTHER,
        cancelReason,
      })
      await handleCustomerPanelClick(SubscriptionSettings.CANCEL)
      setShowReasonDialog(false)
    }

    const onCancelReasonClose = () => {
      setShowReasonDialog(false)
    }
    const isLoading = userApiLoading || planApiLoading
    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div className="title">{t('subscriptions.subscription')}</div>
        <div>
          <div className="subtitle">
            <div>{t('subscriptions.current_plan')}</div>
            <div>
              {data?.activeUserPlan?.nextRenewDate && (
                <TooltipView text={paymentStateText} />
              )}
            </div>
          </div>
          <div className="plan-badge">
            <PlanBadge
              title={planTitle}
              onClick={handleUpgradeButtonClick}
              workspaceId={workspaceId}
              isUpgradeable={!!isUpgradeable}
              paymentFailed={paymentFailed}
              shouldUpdatePaymentMethod={shouldUpdatePaymentMethod}
            />
          </div>
          <div className="subtext">
            {data?.activeUserPlan?.nextRenewDate && <div>{planRenewInfo}</div>}
          </div>
        </div>
        {!paymentFailed && (
          <>
            <div>
              <div className="subtitle">
                {t('subscriptions.credit_information')}
              </div>
              <div className="plan-badge">
                <PlanBadge
                  title={t('subscriptions.ai_credits')}
                  price={
                    workspaceId
                      ? orgAiCredits?.total?.toString()
                      : aiCredits?.total?.toString()
                  }
                  type={PLAN_BADGE_TYPE.PRICE}
                />
              </div>
              <div className="subtext">
                <div>{isUpgradeable && creditInfo}</div>
              </div>
            </div>
            {(data?.activeUserPlan || isChurn) && (
              <div>
                <div>
                  <div className="subtitle">
                    {t('subscriptions.subscription_settings')}
                  </div>
                  <div className="plan-badge">
                    <ListCard items={settings} />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div>
                <div className="subtitle">
                  {t('subscriptions.credit_history')}
                </div>
                <div className="plan-badge">
                  <ListCard items={creditsMapper} />
                </div>
                {data?.activeUserPlan?.nextRenewDate && (
                  <div className="subtext">
                    {t('subscriptions.credit_history_info')}
                  </div>
                )}
              </div>
            </div>

            <Modal
              isOpen={isCancelModalOpen}
              onClose={() => setIsCancelModalOpen(false)}
            >
              <CancelPromotionDialog
                period={data?.activeUserPlan?.plan?.period}
                onCancel={onCancelPromotionCancel}
                onApply={onCancelPromotionApply}
                onClose={() => setIsCancelModalOpen(false)}
                isLoading={isLoading}
              />
            </Modal>
            <Modal
              isOpen={showReasonDialog}
              preventClickOutside
              onClose={() => setIsCancelModalOpen(false)}
            >
              <CancelReasonDialog
                onSubmit={onCancelReasonSubmit}
                onClose={onCancelReasonClose}
                isLoading={isLoading}
              />
            </Modal>
          </>
        )}
      </div>
    )
  },
)
