import React, { useCallback, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import {
  DROPDOWN_SIZE,
  DROPDOWN_THEME,
  DROPDOWN_WIDTH,
  Dropdown,
  IDropdownItem,
} from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { ComponentServices } from 'src/services'
import { DeepPartial } from 'src/types'
import { IEditPropSection } from '../../types'
import { APP_CONFIG } from 'src/config'

export const PropTextSize: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const selectedFontSize = useMemo(
      () =>
        components?.reduce((a: string, c: UpdateComponentSchema) => {
          const cSize = (c.data as ComponentTextDataSchema).style.font.size
          if (a === '' && cSize) {
            return cSize
          }

          return cSize !== a ? 'multi' : a
        }, ''),
      [components],
    )

    const handleFontSizeChange = useCallback(
      (value?: IDropdownItem) => {
        const fontSize = isNaN(parseInt(value?.value as string))
          ? '0em'
          : (value?.value as string)

        const partialData: DeepPartial<ComponentTextDataSchema> = {
          style: { font: { size: fontSize } },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTextDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [selectedFontSize, components],
    )

    const selectedValue = useMemo(() => {
      return selectedFontSize === 'multi' ? '' : selectedFontSize
    }, [selectedFontSize])

    return (
      <Dropdown
        width={DROPDOWN_WIDTH.FULL}
        theme={DROPDOWN_THEME.DARK}
        size={DROPDOWN_SIZE.BIG}
        selected={selectedValue}
        onChange={handleFontSizeChange}
        onInputFocus={() => dispatch(setAllowDeleteShortcut(false))}
        onInputBlur={() => dispatch(setAllowDeleteShortcut(true))}
        placeholder={t('edit.properties.text_size.placeholder')}
        flexValue
        flexFormatters={[
          (val) => (!isNaN(parseInt(val)) ? parseInt(val).toString() : ''),
          (val) => `${val}em`,
        ]}
        items={APP_CONFIG.editPage.fontSizeOptions}
      />
    )
  },
)

PropTextSize.displayName = 'PropTextSize'
