import React from 'react'

import { ICoolSwitch } from './types'
import { itemStyles, wrapperStyles } from './styles'

export const CoolSwitch: React.FC<ICoolSwitch> = React.memo(
  ({ items, selectedItem = 0, className, dataAttr, upgradeModal }) => {
    return (
      <div
        css={wrapperStyles(upgradeModal)}
        className={className}
        {...dataAttr}
      >
        <div className="border-area">
          {items?.map((item, index) => {
            return (
              <div
                key={item.label}
                css={itemStyles({ isSelected: selectedItem === index })}
                onClick={item.onClick}
              >
                {item.label}
              </div>
            )
          })}
        </div>
      </div>
    )
  },
)
