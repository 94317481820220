import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  .title {
    font-size: ${theme.fontSize.large};
    font-weight: ${theme.fontWeight.medium};
    color: ${theme.colors.primary.DEFAULT};
    text-align: center;
  }
  .text {
    text-align: center;
    font-size: ${theme.fontSize.base};
  }
`
