import { StoreType } from 'polotno/model/store'

export function lockPageElements(store: StoreType, pageId: string) {
  const page = store.pages.find((page) => page.id === pageId)
  if (page) {
    page.children.forEach((element) => {
      element.set({
        // can element be moved and rotated
        draggable: false,
        // can we change content of element?
        contentEditable: false,
        // can we change style of element?
        styleEditable: false,
        // can we resize element?
        resizable: false,
        // can we remove an element?
        removable: false,
        selectable: false,
      })
    })
  }
}
