import { dataAttrType } from 'src/types'

export enum PROP_QUILL_TOOLBAR_SIDE {
  TOP = 'top',
  BOTTOM = 'bottom',
}
export interface IPropQuill {
  id: string
  height?: string
  maxHeight?: string
  background?: string
  value?: string
  onChange?: (value?: string) => void
  toolbarSide?: PROP_QUILL_TOOLBAR_SIDE
  contentCheck?: boolean
  className?: string
  dataAttr?: dataAttrType
}
