import React, { useEffect, useState } from 'react'

import { IListEditor } from './types'
import ReactQuill from 'react-quill'
import { listEditorStyles } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, updateSelectedOutline } from 'src/store'

export const ListEditor: React.FC<IListEditor> = React.memo(() => {
  const dispatch = useDispatch()

  const { outlineSuggestion } = useSelector(({ aiFlow }: RootState) => ({
    outlineSuggestion: aiFlow.selectedOutline,
  }))

  const [outline, setOutline] = useState('')

  useEffect(() => {
    setOutline(
      `<ul>${outlineSuggestion.reduce(
        (a, c) => a + `<li>` + c + `</li>`,
        '',
      )}</ul>`,
    )
  }, [])

  const onChange = (value: string) => {
    const updatedValue = value
      .replace('<li><br></li>', '<li>&#8203;</li>')
      .replace(/<p>.*?<\/p>/gs, '')
    setOutline(updatedValue)
    let val = updatedValue.replaceAll('<li>', '').replaceAll('<p></p>', '')
    val = val.replaceAll('</li>', ',')
    val = val.replaceAll('<ul>', '')
    val = val.replaceAll('</ul>', '')
    dispatch(updateSelectedOutline(val.split(',')))
  }

  const modules = {
    toolbar: false,
  }

  const formats = ['list']

  return (
    <ReactQuill
      css={listEditorStyles}
      theme="snow"
      modules={modules}
      formats={formats}
      value={outline}
      onChange={onChange}
    />
  )
})

ListEditor.displayName = 'ListEditor'
