import React from 'react'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useSliderWithInputStyles } from './styles'

interface ISliderWithInputProps {
  value: number
  label?: string
  className?: string
  min?: number
  max?: number
  step?: number
  onValueChange: (value: number) => void
  endAdornment?: JSX.Element
}

const SliderWithInput: React.FC<ISliderWithInputProps> = React.memo(
  ({
    value,
    onValueChange,
    min = 0,
    max = 100,
    label,
    className,
    endAdornment,
    step = 1,
  }) => {
    const { classes } = useSliderWithInputStyles()

    const handleChange = (event: Event, newValue: number | number[]) => {
      const nValue = newValue as number
      onValueChange(nValue)
    }

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let nValue = parseFloat(event.target.value)
      if (nValue > max) {
        nValue = max
      } else if (nValue < 0) {
        nValue = min
      }
      onValueChange(nValue)
    }

    return (
      <Stack direction="column" gap={1} className={className}>
        {label && (
          <Typography variant="caption" align="left">
            {label}
          </Typography>
        )}
        <Stack direction="row" gap={3} alignItems="center">
          <Slider
            value={value}
            onChange={handleChange}
            min={min}
            max={max}
            step={step}
          />
          <TextField
            className={classes.root}
            slotProps={{
              input: {
                className: classes.input,
                endAdornment,
                slotProps: {
                  input: {
                    min: min,
                    max: max,
                    step: step,
                  },
                },
              },
            }}
            size="small"
            type="number"
            value={value}
            onChange={handleTextChange}
            sx={
              endAdornment
                ? {
                    '.MuiInputBase-root': {
                      paddingRight: '6px',
                    },
                  }
                : undefined
            }
          />
        </Stack>
      </Stack>
    )
  },
)

export default SliderWithInput
