import React, { useCallback, useRef, useState } from 'react'
import { DropTargetMonitor, useDrop } from 'react-dnd'
import { CANVAS_ITEM_TYPE, CANVAS_TYPE, ICanvasDnD } from './types'
import { DragController, CanvasGrid, CanvasContextMenu } from './components'
import { canvasDnDStyles, innerStyles } from './styles'
import { ComponentSchema } from 'src/types/api/responseObjects'

import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentCreate,
  setSaveState,
  componentsUpdate,
  removeClickCreateList,
} from 'src/store'
import { useGridSnap } from 'src/hooks'
import { ComponentServices, ObjectServices } from 'src/services'
import {
  ComponentListDataSchema,
  ComponentTextDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ICanvasGridLines } from './components/canvas-grid/types'

export const CanvasDnD: React.FC<ICanvasDnD> = React.memo(
  ({ components, scale = 1 }) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const { snapFromExternal, snapFromInternal } = useGridSnap()
    const [gridLines, setGridLines] = useState<ICanvasGridLines>()

    const [{ isOver }, dropTarget] = useDrop(
      () => ({
        accept: [CANVAS_ITEM_TYPE.CANVAS_ITEM, CANVAS_ITEM_TYPE.SIDEBAR_ITEM],
        drop: (
          items: ComponentSchema[],
          monitor: DropTargetMonitor<ComponentSchema[], unknown>,
        ) => {
          const itemType = monitor.getItemType()
          const currentItem = items[0]

          // Create
          if (itemType === CANVAS_ITEM_TYPE.SIDEBAR_ITEM) {
            const snapped = snapFromExternal({
              rect: wrapperRef?.current?.getBoundingClientRect(),
              offset: monitor.getClientOffset(),
              scale,
            })

            const createdComponent =
              ComponentServices.updateComponent<ComponentSchema>({
                components: [currentItem],
                partialUpdate: {
                  positions: {
                    x: snapped?.x,
                    y: snapped?.y,
                  },
                },
              })

            dispatch(componentCreate(createdComponent))
          }

          // Update
          items.forEach((it) => {
            if (itemType === CANVAS_ITEM_TYPE.CANVAS_ITEM) {
              const snapped = snapFromInternal({
                item: it,
                diff: monitor.getDifferenceFromInitialOffset(),
                scale,
              })

              const updatedComponents =
                ComponentServices.updateComponent<UpdateComponentSchema>({
                  components: [it],
                  partialUpdate: {
                    positions: {
                      x: snapped?.x,
                      y: snapped?.y,
                    },
                  },
                })

              it.tempId && dispatch(removeClickCreateList([it.tempId]))

              // TODO: dispatcher should be outside of the loop. all the data should be prepared and sent at once
              // But currently componentUpdate method only works as setting the same values to all the components.
              // here all the components will have different positions.
              // Only downside of this current structure is it will add multiple logs to the undo history for a single action.
              // Moved components will be placed back one by one when the undo is performed.
              dispatch(componentsUpdate({ components: updatedComponents }))
            }
          })

          setGridLines({ x: [], y: [] })

          // SAVE
          dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
        },
        hover: (items, monitor: DropTargetMonitor<ComponentSchema[], void>) => {
          const itemType = monitor.getItemType()
          const currentItem = items?.[0]
          let snapped

          if (itemType === CANVAS_ITEM_TYPE.SIDEBAR_ITEM) {
            snapped = snapFromExternal({
              rect: wrapperRef?.current?.getBoundingClientRect(),
              offset: monitor.getClientOffset(),
              scale,
            })

            if (snapped?.snapX !== undefined && snapped?.snapY !== undefined) {
              setGridLines({ x: [snapped?.snapX], y: [snapped?.snapY] })
            }
          } else {
            snapped = snapFromInternal({
              item: currentItem,
              diff: monitor.getDifferenceFromInitialOffset(),
              scale,
            })
          }

          if (snapped?.snapX !== undefined && snapped?.snapY !== undefined) {
            setGridLines({ x: [snapped?.snapX], y: [snapped?.snapY] })
          }
        },
        collect: (monitor: DropTargetMonitor<ComponentSchema[], void>) => {
          return {
            isOver: monitor.isOver(),
          }
        },
      }),
      [scale],
    )

    const getComponentHashData = useCallback(
      (component: ComponentSchema) => [
        component.id,
        component.tempId,
        component.style,
        component.positions,
        (component.data as ComponentTextDataSchema)?.style?.font,
        (component.data as ComponentListDataSchema)?.style?.fontBody,
      ],
      [],
    )

    const renderComponents = useCallback(() => {
      const zIndexList: number[] =
        components
          ?.map(({ positions: { zIndex } }) => zIndex || 5000)
          .filter((v) => v)
          .sort() || []
      return (
        <div css={innerStyles} ref={dropTarget}>
          {isOver && <CanvasGrid lines={gridLines} />}
          {components?.map((component) => (
            <DragController
              canvasType={CANVAS_TYPE.DND}
              key={ObjectServices.objectHash(getComponentHashData(component))}
              data={component}
              scale={scale}
              zIndexList={zIndexList}
            />
          ))}
        </div>
      )
    }, [dropTarget, isOver, components, gridLines])

    return (
      <>
        <div css={canvasDnDStyles} ref={wrapperRef}>
          {renderComponents()}
        </div>
        <CanvasContextMenu />
      </>
    )
  },
)

CanvasDnD.displayName = 'CanvasDnD'
