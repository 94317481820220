import { css } from '@emotion/react'
import { APP_CONFIG } from 'src/config'
import { ITheme } from 'src/theme'
import { CANVAS_TYPE, ICanvas } from './types'

type ICanvasStyles = Pick<ICanvas, 'canvasType'>
export const canvasStyles = ({ canvasType }: ICanvasStyles) => css`
  width: 100%;
  height: 100%;
  font-size: ${APP_CONFIG.canvasBaseFontSize}px;
  ${canvasType === CANVAS_TYPE.DND && `padding-right: 50px;`}
  transform: translateZ(0);
`

export const canvasDnDStyles = css`
  width: 100%;
  height: 100%;
`

export const fitAreaStyles = css`
  width: 100%;
  height: 100%;
  position: relative;
`

export const innerStyles = css`
  height: 100%;
  width: 100%;
`

interface IDropAreaStyles {
  scale: number
}
export const dropAreaStyles =
  ({ scale }: IDropAreaStyles) =>
  ({ colors, boxShadow }: ITheme) => css`
    background: ${colors.white.DEFAULT};
    width: 1920px;
    aspect-ratio: ${APP_CONFIG.canvasRatio};
    position: absolute;
    overflow: hidden;
    transform-origin: left top;
    transform: scale(${scale}) translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: var(--background);
    box-shadow: ${boxShadow[4]};
  `
