import { useCallback, useEffect, useState } from 'react'
import { useDecksApi } from '../api'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'
import {
  ComponentSchema,
  getDeckDetailedResponse,
  getSlideDataResponse,
} from 'src/types/api/responseObjects'
import { SVGTypes } from 'src/types/api/enums'
import { decorMap } from 'src/assets/decors'
import { FontServices } from 'src/services'
import { decorPathFinder } from 'src/services/decorPathFinder'
import { colorMapSchemaHandler } from 'src/services/colorServices'
import { useActiveColorMap } from 'src/hooks'

export interface IUsePromiseCanvas {
  deckId?: number | null
  shareKey?: string
  orderIndex?: number
  slideData?: getSlideDataResponse['data']['slideData']
  deck?: getDeckDetailedResponse['data']
}

export interface IUsePromiseCanvasReturn {
  components?: ComponentSchema[]
  isSwapped?: boolean
  tempSvgUrl?: string | null
  backgroundColor?: GradientLikeColorSchema
  svgType?: SVGTypes
  svgDecorString?: string
  cssVariables?: string
}

export const usePromiseCanvas = ({
  deckId,
  shareKey,
  orderIndex = 1,
  slideData,
  deck,
}: IUsePromiseCanvas): IUsePromiseCanvasReturn => {
  const { getPublicDeckData, getPublicSlideData } = useDecksApi()

  const [components, setComponents] = useState<ComponentSchema[]>([])
  const [backgroundColor, setBackground] = useState<GradientLikeColorSchema>()
  const [tempSvgUrl, setTempSvgUrl] = useState<string>()
  const [isSwapped, setIsSwapped] = useState(false)
  const [svgDecorString, setSvgDecorString] = useState<string>()
  const [cssVariables, setCssVariables] = useState('')

  const activeColorMap = useActiveColorMap({
    providedDefaultColorMap: deck?.deckData?.theme.data.colorMap,
    providedColorMap: deck?.deckData?.data.userThemePreferences?.colorMap,
  })

  const buildCanvasData = useCallback(async () => {
    if (!shareKey || !deckId) {
      return false
    }
    const deckData = deck?.deck?.id
      ? deck
      : await getPublicDeckData({
          shareKey,
        })
    const targetSlideDetail = deckData?.deckData?.data.slides.find(
      (targetSlide) =>
        targetSlide.orderIndex === orderIndex && !targetSlide.isDeleted,
    )

    if (!targetSlideDetail?.slideId || !targetSlideDetail?.slideDataId) {
      return false
    }

    const targetSlideData = slideData
      ? slideData
      : await getPublicSlideData({
          deckId,
          slideId: targetSlideDetail.slideId,
          slideDataId: targetSlideDetail.slideDataId,
        })

    // COMPONENTS
    setComponents(
      targetSlideData?.slideDataComponents.map(({ component }) => component) ||
        [],
    )

    // TEMPSVG
    if (deckData?.deckData?.theme.id) {
      setTempSvgUrl(
        targetSlideData?.tempSvg?.[deckData?.deckData?.theme.id] || undefined,
      )
    }

    // SWAPCOLOR
    const isSwappedColor = targetSlideData?.isSwapColor || false
    setIsSwapped(isSwappedColor)

    // BACKGROUND COLOR
    const deckDataBg =
      deckData?.deckData?.theme.data.colorMap?.background?.[
        isSwappedColor ? 'swap' : 'default'
      ]
    if (deckDataBg) {
      setBackground({
        colors: deckDataBg?.colors.map((val) =>
          ['first', 'second', 'third', 'fourth', 'wht', 'blck'].includes(val)
            ? `var(--${val})`
            : val,
        ),
        rotation: deckDataBg?.rotation,
      })
    } else if (targetSlideData?.background)
      setBackground({
        colors: targetSlideData?.background.colors.map((val) =>
          ['first', 'second', 'third', 'fourth', 'wht', 'blck'].includes(val)
            ? `var(--${val})`
            : val,
        ),
        rotation: targetSlideData?.background.rotation,
      })

    // SVG DECOR
    if (targetSlideData?.svgType && deckData?.deckData?.theme.id) {
      const themeId = deckData?.deckData?.theme.id
      const svg2Type = targetSlideData?.svgType
      const selectedSvg = decorPathFinder({
        svgType: svg2Type,
        themeId,
      })

      setSvgDecorString(
        themeId?.toString() && selectedSvg
          ? decorMap[themeId.toString()][selectedSvg as SVGTypes]
          : '',
      )
    }

    // CSS VARIABLES
    const themeColor = deckData?.deckData?.themeColor.data
    const themeFont = deckData?.deckData?.themeFont.data
    if (
      !deckData?.deckData?.theme.data.colorMap ||
      !deckData?.deckData?.data.userThemePreferences?.colorMap
    )
      return

    const overrideColorMap = Object.entries(activeColorMap || {})
      .map(([key, values]) =>
        colorMapSchemaHandler({ values, key, swapped: isSwappedColor }),
      )
      .filter((v) => v)

    setCssVariables(`
      clear: none;
			--first: ${themeColor?.first};
			--second: ${themeColor?.second};
			--third: ${themeColor?.third};
			--fourth: ${themeColor?.fourth};
			--wht: #ffffff;
			--blck: #000000;
			
			--font-primary: ${FontServices.fontFamilyCleaner(themeFont?.primary)};
			--font-primary-weight: ${FontServices.fontWeightCalculator(themeFont?.primary)};
			--font-secondary: ${FontServices.fontFamilyCleaner(themeFont?.secondary)};
			--font-secondary-weight: ${FontServices.fontWeightCalculator(
        themeFont?.secondary,
      )};

			${overrideColorMap.flatMap((colorMap) => colorMap).join(' ')};
	`)
  }, [deckId, orderIndex])

  useEffect(() => {
    buildCanvasData()
  }, [deckId])

  return {
    components,
    tempSvgUrl,
    isSwapped,
    backgroundColor,
    svgDecorString,
    cssVariables,
  }
}
