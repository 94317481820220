import { IButton } from 'src/lib'
import { dataAttrType } from 'src/types'
import { PlanNames, PlanPeriods } from 'src/types/api/enums'

export enum PLAN_CARD_THEME {
  DEFAULT = 'default',
  GRADIENT = 'gradient',
}

export interface IPlanCard {
  name: PlanNames
  period: PlanPeriods
  price?: number
  currency?: string
  featuresTitle?: string
  features?: string[]
  featuresHeight?: number
  currentSeats?: number
  theme?: PLAN_CARD_THEME
  onButtonClick?: (value: number) => void
  buttonLoading?: IButton['isLoading']
  className?: string
  dataAttr?: dataAttrType
  upgradeModal?: boolean
  context?: string
}
