import { css } from '@emotion/react'

interface ISvgParsedStyles {
  width?: string
  height?: string
}
export const svgParsedStyles = ({ width, height }: ISvgParsedStyles) => css`
  width: 100%;
  height: 100%;

  svg {
    ${width && `width: ${width};`}
    ${height && `height: ${height};`}
  }
`
