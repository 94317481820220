import {
  ButtonTypes,
  ComponentRadiusTypes,
  ComponentTypes,
} from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import { ComponentButtonDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const linksDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.links.display_name', { lng }),
      styles: {},
      icon: icons.hyperlink,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.BUTTON,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 300,
          height: 100,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          style: {
            color: {
              text: { colors: ['#000'] },
              background: { colors: ['#000'] },
            },
            font: {
              family: 'primary',
              size: '12',
            },
            isOutlined: true,
            radiusType: ComponentRadiusTypes.FLAT,
          },
          text: i18n.t('edit.definitions.links.text', { lng }),
          buttonType: ButtonTypes.TEXT,
          linkUrl: `https://app.decktopus.com/`,
        } as ComponentButtonDataSchema,
      },
    ],
  }
}
