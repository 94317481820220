import React, { useCallback, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import { IFormQuestion } from './types'
import { BUTTON_WIDTH, Button } from 'src/lib'
// import { PropButton, PropHeader } from '../../props'
// import { FormQuestionOptionsTypes } from 'src/types/api/enums'
import { FormChoice } from './FormChoice'
import {
  ComponentFormDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { useDispatch } from 'react-redux'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'

export const FormQuestionOption: React.FC<IFormQuestion> = React.memo(
  ({ question, options, components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const selectedQuestions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['questions'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentFormDataSchema).questions

            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const onNewChoice = useCallback(() => {
      const newQuestionsData: ComponentFormDataSchema['questions'] = JSON.parse(
        JSON.stringify(selectedQuestions),
      )

      const targetQuestion = newQuestionsData.findIndex(
        ({ questionId }) => questionId === question.questionId,
      )

      newQuestionsData[targetQuestion].options!.choices.push(
        `${t('edit.properties.form_choice.choice')} ${
          newQuestionsData[targetQuestion].options!.choices.length + 1
        }`,
      )

      const partialData: DeepPartial<ComponentFormDataSchema> = {
        questions: newQuestionsData,
      }

      const updatedComponents: UpdateComponentSchema[] =
        ComponentServices.updateComponentData<ComponentFormDataSchema>({
          components,
          partialData,
        })

      dispatch(componentsUpdate({ components: updatedComponents }))
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }, [components, selectedQuestions, question])

    // const changeChoiceType = useCallback(
    //   (choiceType: FormQuestionOptionsTypes) => {
    //     const newQuestionsData: ComponentFormDataSchema['questions'] =
    //       JSON.parse(JSON.stringify(selectedQuestions))

    //     const targetQuestion = newQuestionsData.findIndex(
    //       ({ questionId }) => questionId === question.questionId,
    //     )

    //     newQuestionsData[targetQuestion].options!.type = choiceType

    //     const partialData: DeepPartial<ComponentFormDataSchema> = {
    //       questions: newQuestionsData,
    //     }

    //     const updatedComponents: UpdateComponentSchema[] =
    //       ComponentServices.updateComponentData<ComponentFormDataSchema>({
    //         components,
    //         partialData,
    //       })

    //     dispatch(componentsUpdate({ components: updatedComponents }))
    //     dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    //   },
    //   [components, selectedQuestions, question],
    // )

    return (
      <>
        {question.options?.choices.map((choiceText, index) => (
          <FormChoice
            key={index}
            question={question}
            options={options}
            components={components}
            text={choiceText}
            index={index}
          />
        ))}
        <div className="grid">
          <div className="c-full">
            <Button
              text={t('edit.properties.form_question_option.add_choice')}
              width={BUTTON_WIDTH.FULL}
              onClick={onNewChoice}
            />
          </div>
        </div>
        {/* <div className="grid">
          <div className="c-full">
            <PropHeader text="List Type" />
          </div>
          <div className="c-2">
            <PropButton
              text="Check List"
              isFocus={
                question.options?.type === FormQuestionOptionsTypes.CHECKBOX
              }
              onClick={() =>
                changeChoiceType(FormQuestionOptionsTypes.CHECKBOX)
              }
            />
          </div>
          <div className="c-2">
            <PropButton
              text="Option List"
              isFocus={
                question.options?.type === FormQuestionOptionsTypes.SELECT
              }
              onClick={() => changeChoiceType(FormQuestionOptionsTypes.SELECT)}
            />
          </div>
        </div> */}
      </>
    )
  },
)

FormQuestionOption.displayName = 'FormQuestionOption'
