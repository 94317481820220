import { blueGrey } from '@mui/material/colors'
import { breakpoints } from '../breakpoints'

export const MuiThemeConfig = {
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#4F61FF',
          dark: '#3F4ECC',
          light: '#95A0FF',
          contrastText: '#FFFFFF',
          _states: {
            hover: 'rgba(79, 97, 255, 0.04)',
            selected: 'rgba(79, 97, 255, 0.08)',
            focus: 'rgba(79, 97, 255, 0.12)',
            focusVisible: 'rgba(79, 97, 255, 0.3)',
            outlinedBorder: 'rgba(79, 97, 255, 0.5)',
          },
        },
        secondary: {
          contrastText: '#FFFFFF',
          main: '#9C27B0',
          _states: {
            focusVisible: 'rgba(156, 39, 176, 0.3)',
            outlinedBorder: 'rgba(156, 39, 176, 0.5)',
          },
        },
        error: {
          contrastText: '#FFFFFF',
          main: '#D32F2F',
          _states: {
            focusVisible: 'rgba(211, 47, 47, 0.3)',
            outlinedBorder: 'rgba(211, 47, 47, 0.5)',
          },
        },
        warning: {
          contrastText: '#FFFFFF',
          main: '#EF6C00',
          dark: '#E65100',
          _states: {
            focusVisible: 'rgba(239, 108, 0, 0.3)',
            outlinedBorder: 'rgba(239, 108, 0, 0.5)',
          },
        },
        info: {
          main: '#454F64',
          dark: '#262C38',
          light: '#606E8B',
          contrastText: '#FFFFFF',
          _states: {
            hover: 'rgba(69, 90, 100, 0.04)',
            selected: 'rgba(69, 90, 100, 0.08)',
            focusVisible: 'rgba(69, 90, 100, 0.3)',
            outlinedBorder: 'rgba(69, 90, 100, 0.5)',
          },
        },
        success: {
          contrastText: '#FFFFFF',
          main: '#2E7D32',
          _states: {
            focusVisible: 'rgba(46, 125, 50, 0.3)',
            outlinedBorder: 'rgba(46, 125, 50, 0.5)',
          },
        },
        elevation: {
          outlined: '#F5F5F5',
        },
        _components: {
          alert: {
            info: {
              color: '#262C38',
              background: '#ECEFF1',
            },
          },
          switch: {
            knobFillEnabled: '#FAFAFA',
            slideFill: '#000000',
          },
        },
        action: {
          disabled: 'rgba(0, 0, 0, 0.38)',
        },
        text: {
          primary: 'rgba(0, 0, 0, 0.87)',
          _states: {
            focusVisible: 'rgba(0, 0, 0, 0.3)',
          },
        },
        blueGrey,
      },
    },
    dark: false,
  },
  typography: {
    h1: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    h2: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    h3: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    h4: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    h5: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    h6: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    body1: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    body2: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    subtitle1: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    subtitle2: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    overline: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    caption: {
      fontFamily: 'Sailec, Helvetica, Arial, sans-serif',
    },
    button: {
      textTransform: 'none',
    },
  },
  shape: {
    borderRadius: 4,
    borderRadius2: 8,
    borderRadius3: 12,
  },
  spacing: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80, 88, 96],
  breakpoints: {
    values: {
      xs: 440,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
      ...breakpoints,
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          variants: [
            {
              style: {
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: '14px',
                textTransform: 'none',
              },
            },
          ],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          variants: [
            {
              props: { variant: 'contained' },
              style: {
                textTransform: 'none',
                padding: '6px 10px',
                lineHeight: '22px',
              },
            },
            {
              props: { variant: 'text' },
              style: {
                'textTransform': 'none',
                'padding': '6px 10px',
                'lineHeight': '22px',
                'justifyContent': 'flex-start',
                'color': 'var(--mui-palette-primary-text)',
                '&:hover': {
                  color: 'var(--mui-palette-primary-main)',
                },
              },
            },
            {
              props: { variant: 'outlined' },
              style: {
                'textTransform': 'none',
                'backgroundColor': '#EEEFFF',
                'borderColor': 'transparent',
                'padding': '4px 10px',
                'lineHeight': '22px',
                '&:hover': {
                  backgroundColor:
                    'var(--mui-palette-primary-_states-focusVisible)',
                },
              },
            },
          ],
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorDefault)': {
            '& .MuiSwitch-switchBase': {
              padding: '9px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(20px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(20px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background: 'var(--mui-palette-common-white_states-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorPrimary)': {
            '& .MuiSwitch-switchBase': {
              padding: '9px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(20px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(20px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background: 'var(--mui-palette-primary-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-primary-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorError)': {
            '& .MuiSwitch-switchBase': {
              padding: '9px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(20px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(20px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background: 'var(--mui-palette-error-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-error-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorWarning)': {
            '& .MuiSwitch-switchBase': {
              padding: '9px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(20px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(20px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background: 'var(--mui-palette-warning-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-warning-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorInfo)': {
            '& .MuiSwitch-switchBase': {
              padding: '9px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(20px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(20px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background: 'var(--mui-palette-info-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-info-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorSuccess)': {
            '& .MuiSwitch-switchBase': {
              padding: '9px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(20px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(20px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background: 'var(--mui-palette-success-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-success-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorSecondary)': {
            '& .MuiSwitch-switchBase': {
              padding: '9px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(20px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(20px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background: 'var(--mui-palette-secondary-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-secondary-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '58px',
                'height': '38px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '12px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '20px',
                    height: '20px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '34px',
                    height: '14px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorDefault)': {
            '& .MuiSwitch-switchBase': {
              padding: '4px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(16px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(16px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background: 'var(--mui-palette-common-white_states-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorPrimary)': {
            '& .MuiSwitch-switchBase': {
              padding: '4px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(16px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(16px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background: 'var(--mui-palette-primary-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-primary-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorError)': {
            '& .MuiSwitch-switchBase': {
              padding: '4px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(16px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(16px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background: 'var(--mui-palette-error-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-error-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorWarning)': {
            '& .MuiSwitch-switchBase': {
              padding: '4px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(16px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(16px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background: 'var(--mui-palette-warning-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-warning-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorInfo)': {
            '& .MuiSwitch-switchBase': {
              padding: '4px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(16px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(16px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background: 'var(--mui-palette-info-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-info-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorSuccess)': {
            '& .MuiSwitch-switchBase': {
              padding: '4px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(16px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(16px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background: 'var(--mui-palette-success-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-success-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorSecondary)': {
            '& .MuiSwitch-switchBase': {
              padding: '4px',
              borderRadius: '100px',
              background: 'none',
              opacity: '1',
              boxShadow: 'none',
            },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(16px) translateY(0px)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(16px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background: 'var(--mui-palette-secondary-main)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background: 'var(--mui-palette-secondary-main)',
                    padding: '0',
                    opacity: '0.5',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                '& .MuiSwitch-switchBase': {
                  transform: 'translateX(0px) translateY(0px)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                'width': '40px',
                'height': '24px',
                'transform': 'translateX(0px) translateY(0px)',
                'padding': '7px',
                'background': 'none',
                'opacity': '1',
                'borderRadius': '0',
                'boxShadow': 'none',
                '& .MuiSwitch-switchBase': {
                  'transform': 'translateX(0px) translateY(0px)',
                  '& .MuiSwitch-thumb': {
                    width: '16px',
                    height: '16px',
                    background:
                      'var(--mui-palette-_components-switch-knobFillEnabled)',
                    boxShadow: 'var(--mui-shadows-1)',
                    padding: '0',
                    opacity: '1',
                    borderRadius: '50%',
                  },
                  '& + .MuiSwitch-track': {
                    width: '26px',
                    height: '10px',
                    borderRadius: '100px',
                    background:
                      'var(--mui-palette-_components-switch-slideFill)',
                    padding: '0',
                    opacity: '0.38',
                    boxShadow: 'none',
                    transform: 'translateX(0px) translateY(0px)',
                  },
                },
              },
          },
        },
      },
    },
  },
}
