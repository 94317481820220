import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCategoryLoading,
  setThemeCategories,
  setThemeLoading,
  setThemes,
  setThemeFonts,
  RootState,
  setThemeColors,
} from 'src/store'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import {
  getThemeCategoriesResponse,
  getThemeColorsResponse,
  getThemeFontsResponse,
  getThemesInThemeCategoryResponse,
} from 'src/types/api/responseObjects'
import {
  getThemesInThemeCategoryParams,
  postCreateThemeColorBody,
  postCreateThemeFontBody,
} from 'src/types/api/requestObjects'
import { ViewModes } from 'src/types/api/enums'

interface IUseThemeApiReturn {
  getThemeCategories: () => Promise<void>
  getThemeCategory: (params: getThemesInThemeCategoryParams) => Promise<void>
  isLoading: boolean
  getThemeFonts: (
    params: getThemesInThemeCategoryParams,
  ) => Promise<getThemeFontsResponse['data']>
  deleteDeckThemeFont: ({
    themeFontId,
    viewMode,
    organizationId,
    themeCategoryId,
  }: {
    themeFontId: number
    viewMode?: ViewModes
    organizationId?: number
    themeCategoryId: number
  }) => Promise<boolean>
  createThemeFont: ({
    params: { organizationId, data },
    themeCategoryId,
  }: {
    params: postCreateThemeFontBody
    themeCategoryId: number
  }) => Promise<getThemeFontsResponse['data']>
  getThemeColors: (
    params: getThemesInThemeCategoryParams,
  ) => Promise<getThemeColorsResponse['data']>
  deleteDeckThemeColor: ({
    themeColorId,
    viewMode,
    organizationId,
    themeCategoryId,
  }: {
    themeColorId: number
    viewMode?: ViewModes
    organizationId?: number
    themeCategoryId: number
  }) => Promise<boolean>
  createThemeColor: ({
    params: { organizationId, data },
    themeCategoryId,
  }: {
    params: postCreateThemeColorBody
    themeCategoryId: number
  }) => Promise<getThemeColorsResponse['data']>
}

export const useThemeApi = (): IUseThemeApiReturn => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getThemeCategories = async () => {
    setIsLoading(true)
    dispatch(setCategoryLoading())
    try {
      const res: RequestResponse<getThemeCategoriesResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.THEME_CATEGORIES,
        })
      dispatch(setThemeCategories(res.data.data.themeCategories))
    } finally {
      setIsLoading(false)
    }
  }

  const getThemeCategory = async ({
    themeCategoryId,
  }: getThemesInThemeCategoryParams) => {
    setIsLoading(true)
    dispatch(setThemeLoading())
    try {
      const res: RequestResponse<getThemesInThemeCategoryResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: `${API_CONFIG.THEME_CATEGORIES}/${themeCategoryId}`,
        })
      dispatch(setThemes(res.data.data.themes))
    } finally {
      setIsLoading(false)
    }
  }

  const { workspaceId, themeId } = useSelector(
    ({ workspace, edit }: RootState) => ({
      workspaceId: workspace.id,
      themeId: edit.activeDeck.data?.deckData?.theme.id,
    }),
  )

  const getThemeFonts = async ({
    themeCategoryId,
  }: getThemesInThemeCategoryParams) => {
    setIsLoading(true)
    dispatch(setThemeLoading())
    try {
      const res: RequestResponse<getThemeFontsResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.THEMES_FONTS,
          params: {
            preDefined: true,
            themeCategoryId,
            own: true,
            sharedOnOrg: workspaceId ? true : false,
            organizationId: workspaceId,
          },
        })
      dispatch(setThemeFonts(res.data.data))
      return res.data.data
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDeckThemeFont = async ({
    themeFontId,
    viewMode,
    organizationId,
    themeCategoryId,
  }: {
    themeFontId: number
    viewMode?: ViewModes
    organizationId?: number
    themeCategoryId: number
  }) => {
    setIsLoading(true)
    dispatch(setThemeLoading())
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: API_CONFIG.DELETE_THEMES_FONTS(themeFontId.toString()),
        data: {
          viewMode,
          organizationId,
          isHidden: true,
        },
      })
      await getThemeFonts({ themeCategoryId })
      return true
    } finally {
      setIsLoading(false)
    }
  }

  const createThemeFont = async ({
    params: { organizationId, data },
    themeCategoryId,
  }: {
    params: postCreateThemeFontBody
    themeCategoryId: number
  }) => {
    setIsLoading(true)
    dispatch(setThemeLoading())
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: API_CONFIG.THEMES_FONTS,
        data: { organizationId: organizationId, data },
      })
      return await getThemeFonts({ themeCategoryId })
    } finally {
      setIsLoading(false)
    }
  }

  const getThemeColors = async ({
    themeCategoryId,
  }: getThemesInThemeCategoryParams) => {
    setIsLoading(true)
    dispatch(setThemeLoading())
    try {
      const res: RequestResponse<getThemeColorsResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.THEMES_COLORS,
          params: {
            preDefined: true,
            themeCategoryId,
            themeId: themeId,
            own: true,
            sharedOnOrg: workspaceId ? true : false,
            organizationId: workspaceId,
          },
        })
      dispatch(setThemeColors(res.data.data))
      return res.data.data
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDeckThemeColor = async ({
    themeColorId,
    viewMode,
    organizationId,
    themeCategoryId,
  }: {
    themeColorId: number
    viewMode?: ViewModes
    organizationId?: number
    themeCategoryId: number
  }) => {
    setIsLoading(true)
    dispatch(setThemeLoading())
    try {
      await RequestServices.callApi({
        method: 'PUT',
        url: API_CONFIG.DELETE_THEMES_COLORS(themeColorId.toString()),
        data: {
          viewMode,
          organizationId,
          isHidden: true,
        },
      })
      await getThemeColors({ themeCategoryId })
      return true
    } finally {
      setIsLoading(false)
    }
  }

  const createThemeColor = async ({
    params: { organizationId, data },
    themeCategoryId,
  }: {
    params: postCreateThemeColorBody
    themeCategoryId: number
  }) => {
    setIsLoading(true)
    dispatch(setThemeLoading())
    try {
      await RequestServices.callApi({
        method: 'POST',
        url: API_CONFIG.THEMES_COLORS,
        data: { organizationId: organizationId, data },
      })
      return await getThemeColors({ themeCategoryId })
    } finally {
      setIsLoading(false)
    }
  }

  return {
    getThemeCategories,
    getThemeCategory,
    getThemeFonts,
    deleteDeckThemeFont,
    createThemeFont,
    getThemeColors,
    deleteDeckThemeColor,
    createThemeColor,
    isLoading,
  }
}
