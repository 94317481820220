import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

// TODO: update 36px with 3xlarge
export const wrapperStyles = (theme: ITheme) => css`
  position: relative;
  background: ${theme.colors.white.DEFAULT};

  padding: ${theme.gap.medium}px;
  width: 100%;
  height: 100%;
  border-radius: 0;

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: 582px;
    padding: ${theme.gap['2xlarge']}px;
    border-radius: ${theme.borderRadius[10]};
    height: auto;
  }

  @media (min-width: ${theme.breakpointsV2.desktop}px) {
    width: 710px;
    padding: 36px;
  }
`

export const closeButtonAreaStyles = (theme: ITheme) => css`
  text-align: right;
  margin-bottom: ${theme.gap.medium}px;
  height: 20px;
  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    position: absolute;
    top: ${theme.gap.xlarge}px;
    right: ${theme.gap.xlarge}px;
  }
`

export const titleStyles = (aiFlow: boolean) => (theme: ITheme) => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
  display: flex;
  align-items: center;
  ${aiFlow
    ? `margin-bottom: ${theme.gap.xlarge}px;   text-align: center;`
    : `margin-bottom: ${theme.gap.small}px;   text-align: left; `};

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    text-align: left;
  }

  @media (max-width: ${theme.breakpointsV2.laptop}px) {
    ${aiFlow
      ? `margin-bottom: ${theme.gap.xlarge}px; font-size: ${theme.fontSize.large}`
      : `margin-bottom: ${theme.gap.xsmall}px; font-size: ${theme.fontSize.medium} `};
  }
  .tooltip {
    font-size: ${theme.fontSize.medium};
    height: 16px;
    margin-left: ${theme.spacingV2.xsmall};
  }
`
