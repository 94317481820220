import React, { useCallback, useMemo } from 'react'

import { PropColor } from 'src/pages/deck-page/components/edit-properties/props'
import {
  ComponentMediaDataSchema,
  ComponentTextDataSchema,
  GradientLikeColorSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'
import { IEditPropSection } from '../../types'
import { PropHeader } from 'src/pages/deck-page/components/edit-properties/props'
import { ComponentMediaZoomTypes } from 'src/types/api/enums'
import { Switch } from 'src/lib'
import { useLanguage } from 'src/hooks'

export const ShapeProps: React.FC<IEditPropSection> = React.memo(
  ({ components }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const selectedColor = useMemo(
      () =>
        components?.reduce(
          (
            a: GradientLikeColorSchema | undefined,
            c: UpdateComponentSchema,
          ) => {
            const textColor = (c.data as ComponentTextDataSchema).style.color
              .text
            if (a === undefined && textColor) {
              return textColor
            }

            return textColor !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const handleOnChange = useCallback(
      (newColor: GradientLikeColorSchema) => {
        const partialData: DeepPartial<ComponentTextDataSchema> = {
          style: { color: { text: newColor } },
        }
        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentTextDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    const selectedZoomType = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentMediaZoomTypes | undefined,
            c: UpdateComponentSchema,
          ) => {
            const zoomType = (c.data as ComponentMediaDataSchema).zoom?.type

            if (a === undefined && zoomType !== undefined) {
              return zoomType
            }

            return zoomType !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const changeZoomType = useCallback(
      (value: boolean) => {
        const partialData: DeepPartial<ComponentMediaDataSchema> = {
          zoom: {
            type: value
              ? ComponentMediaZoomTypes.DISTORT
              : ComponentMediaZoomTypes.FIT,
          },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentMediaDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedZoomType],
    )

    return (
      <>
        <div className="grid">
          <div className="c-1">
            <PropHeader text={t('edit.properties.shape.placeholder')} />
            <PropColor color={selectedColor} onChange={handleOnChange} />
          </div>
        </div>
        <div className="grid" style={{ display: 'none' }}>
          <div className="c-full control-row">
            <span>{t('edit.properties.shape.distort')}</span>
            <Switch
              selected={selectedZoomType === ComponentMediaZoomTypes.DISTORT}
              onChange={changeZoomType}
            />
          </div>
        </div>
      </>
    )
  },
)

ShapeProps.displayName = 'ShapeProps'
