import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { IWorkspaceSelector } from './types'
import { useSelector, useDispatch } from 'react-redux'
import { useLanguage, useClickOutside } from 'src/hooks'
import { RootState, setDecksQuery, setIsWorkspaceReady } from 'src/store'
import { Avatar, Icon, icons } from 'src/lib'
import {
  workspaceBadgeStyles,
  menuItemStyles,
  nameStyles,
  statusStyles,
  menuStyles,
  orgNameStyles,
  nameWidthStyles,
  lineStyles,
} from './styles'
import { setWorkspaceId, setIsOwner, setPaymentFailed } from 'src/store'
import { useSearchParams } from 'react-router-dom'

export const WorkspaceSelector: React.FC<IWorkspaceSelector> = React.memo(
  () => {
    const { t, lang } = useLanguage()
    const { user, userId, orgs, workspaceId } = useSelector(
      ({ user, workspace }: RootState) => ({
        user: user.data,
        userId: user.data?.id,
        orgs: user.organizationUsers,
        workspaceId: workspace.id,
      }),
    )
    const [orgName, setOrgName] = useState(t('workspace.personal_hub'))
    const [orgImageUrl, setOrgImageUrl] = useState<string | null>(null)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
      const findOrgWithId = orgs?.find(
        ({ organization: { id } }) => id === workspaceId,
      )?.organization
      const findOwnerOrg = orgs?.find(
        ({ organization: { ownerUserId } }) => ownerUserId === userId,
      )?.organization

      setOrgName(findOrgWithId?.name || t('workspace.personal_hub'))
      setOrgImageUrl(findOrgWithId?.logoUrl || null)
      dispatch(setWorkspaceId(findOrgWithId?.id || findOwnerOrg?.id))
    }, [orgs, workspaceId, userId, lang])

    useEffect(() => {
      const handleFetching = async () => {
        await workspacePreparation()
      }

      handleFetching()
    }, [workspaceId])

    // check that localstorage workspace id is exist in userOrganizations and update or remove workspace accordingly
    const workspacePreparation = useCallback(() => {
      const findOrgWithWorkspaceId = orgs?.find(
        (organization) => organization.organization.id === workspaceId,
      )?.organization.id

      const ownedOrgId = orgs?.find(
        (organization) => organization.organization.ownerUserId === user?.id,
      )?.organization.id

      const isOrganizationActive = orgs?.find(
        (organization) =>
          organization.organization.id === (workspaceId || ownedOrgId),
      )?.organization.isActive

      if (workspaceId && !isOrganizationActive) {
        dispatch(setPaymentFailed(true))
      } else {
        dispatch(setPaymentFailed(false))
      }

      dispatch(setWorkspaceId(findOrgWithWorkspaceId ?? ownedOrgId))
      dispatch(setIsOwner(findOrgWithWorkspaceId === ownedOrgId))

      dispatch(setIsWorkspaceReady(true))
      return
    }, [orgs, user, workspaceId])

    const workspaceRef = useRef(null)
    const outsideClick = () => setIsMenuOpen(false)
    useClickOutside(workspaceRef, outsideClick)

    const items = useMemo(() => {
      return [
        ...(!orgs?.some(
          ({ organization: { ownerUserId } }) => ownerUserId === userId,
        )
          ? [
              {
                name: t('workspace.personal_hub'),
                value: undefined,
                isAdmin: false,
                logoUrl: null,
              },
            ]
          : []),
        ...(orgs?.map(({ organization: { name, id, logoUrl }, isAdmin }) => ({
          name,
          logoUrl,
          value: id,
          isAdmin,
        })) || []),
      ].sort((a, b) => {
        const isAdminA = a?.isAdmin
        const isAdminB = b?.isAdmin
        return isAdminA === isAdminB ? 0 : isAdminA ? -1 : 1
      })
    }, [orgs, userId, lang])

    const handleChangeWorkspace = useCallback(
      (idParam?: number) => {
        dispatch(setDecksQuery({}))
        dispatch(setDecksQuery({ folderId: undefined }))
        searchParams.delete('folderId')
        setSearchParams({})

        setIsMenuOpen(!isMenuOpen)
        dispatch(setWorkspaceId(idParam))
        dispatch(setIsOwner(!!isWorkspaceOwned(idParam)))

        const orgIsActive = !!orgs?.find(
          (organization) => organization.organization.id === idParam,
        )?.organization.isActive
        dispatch(setPaymentFailed(!orgIsActive))
      },
      [isMenuOpen],
    )

    const isWorkspaceOwned = useCallback(
      (value?: number) => {
        if (!value) return
        const selectedOrg = orgs?.find((org) => org.organization.id === value)
        return selectedOrg?.organization.ownerUserId === userId
      },
      [orgs, userId],
    )

    return (
      <div ref={workspaceRef}>
        <div
          css={workspaceBadgeStyles}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Avatar data={[{ name: orgName, image: orgImageUrl }]} size={28} />

          <span css={orgNameStyles}>{orgName}</span>
          <Icon icon={icons.chevron_down} size={16}></Icon>
        </div>

        <div css={menuStyles}>
          {isMenuOpen &&
            items?.map(({ name, value, isAdmin, logoUrl }) => (
              <>
                <div
                  css={menuItemStyles(workspaceId === value)}
                  onClick={() => handleChangeWorkspace(value)}
                >
                  <Avatar
                    key={value}
                    size={28}
                    data={[
                      {
                        name: name,
                        image: logoUrl,
                      },
                    ]}
                  />
                  <div css={nameStyles}>
                    <span css={nameWidthStyles}>{name}</span>
                    {isAdmin ? (
                      <span css={statusStyles}>
                        (
                        {isWorkspaceOwned(value)
                          ? t('common.roles.owner')
                          : t('common.roles.admin')}
                        )
                      </span>
                    ) : (
                      value && (
                        <span css={statusStyles}>
                          ({t('common.roles.member')})
                        </span>
                      )
                    )}
                  </div>
                </div>
                {isAdmin && <div css={lineStyles} />}
              </>
            ))}
        </div>
      </div>
    )
  },
)
