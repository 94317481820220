import { observer } from 'mobx-react-lite'
import TransparencyAction from './TransparencyAction'
import { IPolotnoComponent } from '../../types'

const TransparencyActionForRightPane = observer<IPolotnoComponent>(
  ({ store }) => {
    const hasLockedItems = store.selectedElements?.some(
      (element) => element.locked,
    )

    // if they are all text item, text toolbar has it's own opacity action
    const allTextItems = store.selectedElements?.every(
      (element) => element.type === 'text',
    )

    return !hasLockedItems && !allTextItems ? (
      <TransparencyAction store={store} />
    ) : null
  },
)
export default TransparencyActionForRightPane
