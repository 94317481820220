import {
  bottomSpaceWrapper,
  spanWrapperStyles,
  deckNameStyles,
} from '../../styles'
import React, { useCallback, useMemo, useState } from 'react'
import { useLanguage } from 'src/hooks'
import { CopyArea } from 'src/lib'
import { IEmbedCode } from './types'
import { Button } from 'src/lib'
import { useDecksApi } from 'src/hooks/api'
import { deckNameFontWeight } from '../export/styles'

export const EmbedCode: React.FC<IEmbedCode> = React.memo(
  ({ deckShareCode, deckName, deckId, deckOrganizationId, context }) => {
    const { t } = useLanguage()
    const { shareDeck, isLoading } = useDecksApi()

    const [shortCode, setShortCode] = useState(deckShareCode)

    const getShareCode = useCallback(async () => {
      const code = await shareDeck(
        { deckId: deckId! },
        deckOrganizationId,
        context,
      )
      setShortCode(code)
    }, [deckId, deckOrganizationId])

    const handleGetShareLinkClick = useCallback(async () => {
      if (!shortCode && !isLoading) {
        await getShareCode()
      }
    }, [shortCode, isLoading])

    const fullLink = useMemo(
      () => `${window.location.origin}/share/${shortCode}`,
      [shortCode],
    )

    const embedCode = useMemo(() => {
      return `<!-- Decktopus Presentation -->
        <div style="position: relative;  padding-top:  56.25%;  overflow: hidden"><iframe  allowfullscreen  src="${fullLink}?embedded=true"  style="position:  absolute;  top:  0;  left:  0;  width: 100%;  height:100%;  border:none"></iframe></div>`
    }, [fullLink])

    return (
      <div>
        <div css={bottomSpaceWrapper}>
          <div css={deckNameStyles}>
            <span css={spanWrapperStyles}>
              {t('share_modal.embed_code')}:
              <span css={deckNameFontWeight}>{deckName}</span>
            </span>
          </div>
        </div>
        {shortCode ? (
          <div css={bottomSpaceWrapper}>
            <CopyArea width="100%" height="122px" text={embedCode} />
          </div>
        ) : (
          <Button
            onClick={handleGetShareLinkClick}
            text={t('share_modal.embed.code')}
          ></Button>
        )}
      </div>
    )
  },
)
