import React, { useState } from 'react'
import {
  imageAreaStyles,
  imageStyles,
  textStyles,
  wrapperStyles,
} from './styles'
import { Icon, icons } from 'src/lib/icon'
import { CREATECARD_TYPE, ICreateCard } from './types'
import { useTheme } from 'src/theme'
import { useWindowSize } from 'src/hooks'
import aiStar from 'src/assets/images/ai-star-animation.gif'

export const CreateCard: React.FC<ICreateCard> = React.memo(
  ({
    text,
    type = CREATECARD_TYPE.DEFAULT,
    img,
    onClick,
    disabled,
    dataAttr,
    className,
  }) => {
    const [isHover, setIsHover] = useState(false)
    const theme = useTheme()
    const { width } = useWindowSize()

    const handleMouseEnter = () => {
      setIsHover(true)
    }

    const handleMouseLeave = () => {
      setIsHover(false)
    }

    // TODO: move inline-style to styles.ts
    return (
      <div
        css={wrapperStyles({ type })}
        onClick={() => !disabled && onClick?.()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={className}
        style={{
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? 'none' : 'all',
        }}
        {...dataAttr}
      >
        {type === CREATECARD_TYPE.DEFAULT && (
          <img src={img} style={{ height: `${width > 767 ? 50 : 24}px` }} />
        )}
        {type === CREATECARD_TYPE.GRADIENT && (
          <div css={imageAreaStyles}>
            {isHover ? (
              <img css={imageStyles} src={aiStar} />
            ) : (
              <Icon
                icon={icons.sparkling}
                color={theme.colors.white.DEFAULT}
                size={width > 767 ? 34 : 16}
              />
            )}
          </div>
        )}
        <div css={textStyles({ type })}>{text}</div>
      </div>
    )
  },
)
