import { COLOR } from './types'

export const pink = {
  DEFAULT: COLOR.pink_100,
  90: COLOR.pink_90,
  80: COLOR.pink_80,
  70: COLOR.pink_70,
  60: COLOR.pink_60,
  50: COLOR.pink_50,
  40: COLOR.pink_40,
  30: COLOR.pink_30,
  20: COLOR.pink_20,
  10: COLOR.pink_10,
}
