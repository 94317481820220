import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const wrapperStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.spacingV2.small};
`

export const topStyles = (theme: ITheme) => css`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${theme.spacingV2['3xsmall']};
`

export const titleStyles = (theme: ITheme) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${theme.fontWeight.medium};
`

export const bottomStyles = (theme: ITheme) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacingV2['2xsmall']};
  color: ${theme.colors.text[2]};
  font-size: ${theme.fontSize.small};

  span {
    width: 3px;
    height: 3px;
    border-radius: ${theme.borderRadius.DEFAULT};
    background: ${theme.colors.text[2]};
  }
`

export const menuAreaStyles = css`
  position: relative;
`

interface IMenuStyles {
  isMenuOpen: boolean
}

export const menuStyles =
  ({ isMenuOpen }: IMenuStyles) =>
  (theme: ITheme) => css`
    position: absolute;
    z-index: ${isMenuOpen ? 11 : -1};
    transition: opacity;
    top: calc(100% + ${theme.spacingV2['2xsmall']});
    right: 0;

    opacity: ${isMenuOpen ? 1 : 0};
  `
export const errorTextStyle = (theme: ITheme) => css`
  color: ${theme.colors.error.DEFAULT};
`
