import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IFormQuestion } from './types'
import { INPUT_THEME, Input } from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import {
  ComponentFormDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { VALIDATION_RULE_TYPES, useDebouncer, useLanguage } from 'src/hooks'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'

export const FormQuestionText: React.FC<IFormQuestion> = React.memo(
  ({ question, components }) => {
    const dispatch = useDispatch()
    const { t } = useLanguage()

    const selectedQuestions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['questions'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentFormDataSchema).questions

            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const changeQuestionText = useCallback(
      (text: string) => {
        const newQuestionsData: ComponentFormDataSchema['questions'] =
          JSON.parse(JSON.stringify(selectedQuestions))

        const targetQuestion = newQuestionsData.findIndex(
          ({ questionId }) => questionId === question.questionId,
        )

        if (newQuestionsData[targetQuestion].questionText) {
          newQuestionsData[targetQuestion].questionText = text
        }

        const partialData: DeepPartial<ComponentFormDataSchema> = {
          questions: newQuestionsData,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedQuestions, question],
    )

    const [validUrl, setValidUrl] = useState(false)
    const [value, setValue] = useState<string | undefined>(
      question.questionText,
    )
    const handleOnChange = useCallback(
      (val?: string) => {
        setValue(val)
      },
      [components],
    )

    const debounced = useDebouncer(
      () => {
        if (validUrl && value) {
          changeQuestionText(value)
        }
      },
      { delay: 500 },
    )

    useEffect(() => {
      debounced()
    }, [value, validUrl])

    return (
      <div className="grid">
        <div className="c-full">
          <Input
            theme={INPUT_THEME.DARK}
            value={value}
            onChange={handleOnChange}
            onFocus={() => dispatch(setAllowDeleteShortcut(false))}
            onBlur={() => dispatch(setAllowDeleteShortcut(true))}
            constantValidation
            validation={{
              [VALIDATION_RULE_TYPES.REQUIRED]: {
                text: t('validation.error.required', {
                  name: t('edit.properties.form.question_text'),
                }),
              },
              [VALIDATION_RULE_TYPES.MIN]: {
                value: 3,
                text: t('validation.min_char', {
                  value: 3,
                  name: t('edit.properties.form.question_text'),
                }),
              },
            }}
            onValidation={setValidUrl}
          />
        </div>
      </div>
    )
  },
)

FormQuestionText.displayName = 'FormQuestionText'
