export const planFeatures = {
  'plan_features.pro.monthly.title': 'Crédits IA 750 / mois',
  'plan_features.pro.monthly.1': 'Exportation PDF / PPT',
  'plan_features.pro.monthly.2': 'Analyses de présentation',
  'plan_features.pro.monthly.3': 'Lien de partage',
  'plan_features.pro.monthly.4':
    'Notifications par e-mail des réponses aux formulaires',
  'plan_features.pro.monthly.5': 'Notes de diapositives et du présentateur',
  'plan_features.pro.monthly.6': 'Génération de conseils et de Q&R',
  'plan_features.pro.annual.title': 'Crédits IA 750 / mois',
  'plan_features.pro.annual.1': 'Exportation PDF / PPT',
  'plan_features.pro.annual.2': 'Analyses de présentation',
  'plan_features.pro.annual.3': 'Lien de partage',
  'plan_features.pro.annual.4':
    'Notifications par e-mail des réponses aux formulaires',
  'plan_features.pro.annual.5': 'Notes de diapositives et du présentateur',
  'plan_features.pro.annual.6': 'Génération de conseils et de Q&R',
  'plan_features.business.monthly.title': 'Crédits IA 1000 / mois',
  'plan_features.business.monthly.1': 'Toutes les fonctionnalités IA PRO +',
  'plan_features.business.monthly.2': 'Fonctionnalités de marque',
  'plan_features.business.monthly.3': 'Réponse avancée aux formulaires',
  'plan_features.business.monthly.4': 'Webhook',
  'plan_features.business.monthly.5': 'Connexion de domaine personnalisé',
  'plan_features.business.monthly.6': 'Équipe et organisation',
  'plan_features.business.annual.title': 'Crédits IA 1000 / mois',
  'plan_features.business.annual.1': 'Toutes les fonctionnalités IA Pro +',
  'plan_features.business.annual.2': 'Fonctionnalités de marque',
  'plan_features.business.annual.3': 'Réponse avancée aux formulaires',
  'plan_features.business.annual.4': 'Webhook',
  'plan_features.business.annual.5': 'Connexion de domaine personnalisé',
  'plan_features.business.annual.6': 'Équipes et organisation',
}
