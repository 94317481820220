import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const formInner = (theme: ITheme) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacingV2.medium};
  position: relative;

  @media (min-width: ${theme.breakpointsV2.large}px) {
    gap: ${theme.spacingV2.xlarge};
    margin-bottom: ${theme.spacingV2.xlarge};
  }
`

export const actionsStyles = (theme: ITheme) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacingV2.xsmall};

  .forgot-password {
    align-self: flex-end;
  }

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    text-align: center;
  }

  @media (min-width: ${theme.breakpointsV2.large}px) {
    gap: ${theme.spacingV2.xsmall};
  }
`

export const welcomeTextStyle = ({
  gradient,
  fontSize,
  fontWeight,
}: ITheme) => css`
  display: flex;
  justify-content: center;
  font-size: ${fontSize.xlarge};
  font-weight: ${fontWeight.bold};
  background: ${gradient.DEFAULT};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
`
