import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const orgMenuStyles = (isMenuOpen: boolean) => (theme: ITheme) => css`
  display: ${isMenuOpen ? 'flex' : 'none'};
  position: fixed;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  padding: ${theme.gap.xlarge}px;
  row-gap: ${theme.gap.large}px;
  background-color: ${theme.colors.white.DEFAULT};
  border: 1px solid ${theme.colors.outline.DEFAULT};
  left: 0;
  top: 0;
  z-index: ${theme.zIndex.popUpMenu};

  .profile-badge {
    padding-top: ${theme.gap['2xlarge']}px;
  }

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    width: ${theme.dimensions.organizationMenuWidth.small}px;
    position: absolute;
    top: ${theme.dimensions.headerHeight.DEFAULT}px;
    z-index: ${theme.zIndex.orgMenu};

    .profile-badge {
      padding-top: 0;
    }
  }

  @media (min-width: ${theme.breakpointsV2.laptop}px) {
    display: ${isMenuOpen ? 'flex' : 'none'};
    position: relative;
    top: 0;
  }

  @media (min-width: ${theme.breakpointsV2.xlarge}px) {
    width: ${theme.dimensions.organizationMenuWidth.DEFAULT}px;
  }
`

export const orgMenuCloseStyles = ({
  breakpointsV2: breakpoints,
  gap,
}: ITheme) => css`
  display: block;
  position: absolute;
  top: ${gap.small}px;
  right: ${gap.small}px;

  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`
