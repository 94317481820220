import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GradientLikeColorSchema } from 'src/types/api/requestObjects'

export interface IRecentColors {
  swatches: GradientLikeColorSchema[]
}

const initialState: IRecentColors = {
  swatches: [],
}

export const recentColorsSlice = createSlice({
  name: 'recentColors',
  initialState,
  reducers: {
    newSwatch: (state, action: PayloadAction<GradientLikeColorSchema>) => {
      state.swatches.unshift(action.payload)
    },
    deleteSwatch: (state, action: PayloadAction<number>) => {
      state.swatches.splice(action.payload, 1)
    },
  },
})
export const { newSwatch, deleteSwatch } = recentColorsSlice.actions
export default recentColorsSlice.reducer
