import React, { useMemo, useState } from 'react'
import { useAuthProvider } from 'src/provider'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { useLanguage } from 'src/hooks'
import { UserBadge } from 'src/components/user-badge'
import { SideMenu } from 'src/components/side-menu'
import { WebhookForm } from 'src/components/webhook-form'
import { Modal, icons } from 'src/lib'
import { IMainPanel } from './types'
import { wrapperStyles } from './styles'
import { ReferModal } from '../../../refer-modal/index'
import { UpgradeModalManager } from 'src/components/upgrade-modals'
import { PlanNames } from 'src/types/api/enums'
import { sendEvent } from 'src/plugins/google/gtag'
import {
  GTAG_EVENTS,
  GTAG_EVENT_CATEGORIES,
  ACTION_CLICKS,
} from 'src/plugins/google/consts'
import { usePostHog } from 'posthog-js/react'
import { DECKTOPUS_WORKSPACE_ID } from 'src/components/workspace-selector/consts'
import Gleap from 'gleap'

export const MainPanel: React.FC<IMainPanel> = React.memo(
  ({ onPanelOpen, onPanelClose, className, dataAttr }) => {
    const { t } = useLanguage()
    const { clearToken } = useAuthProvider()
    const navigate = useNavigate()
    const { userData, workspaceId, userPlan, orgUsers } = useSelector(
      ({ user, workspace }: RootState) => ({
        userData: user.data,
        workspaceId: workspace.id,
        userPlan: user.data?.activeUserPlan?.plan.name,
        orgUsers: user.organizationUsers,
      }),
    )
    const posthog = usePostHog()

    const [webhookModal, setWebhookModal] = useState(false)
    const [referModalOpen, setReferModalOpen] = useState(false)

    const planText: string = useMemo(() => {
      return workspaceId
        ? t('enum.planNames.1')
        : t(
            userData?.activeUserPlan?.plan?.name
              ? `enum.planNames.${userData?.activeUserPlan?.plan?.name}`
              : 'common.free_uppercased',
          )
    }, [workspaceId, userData])

    const handleLogoutClick = () => {
      sendEvent(GTAG_EVENTS.SIGN_OUT, {
        user_email: userData?.email,
        event_category: GTAG_EVENT_CATEGORIES.ENGAGEMENT,
      })

      try {
        if (posthog && posthog.__loaded) {
          posthog.reset()
        }
      } catch {
        console.error('Posthog init MainPanel error: ')
      }

      try {
        if (Gleap) Gleap.clearIdentity()
      } catch {
        console.error('Gleap clearIdentity MainPanel error: ')
      }

      clearToken()
      navigate('/login', { replace: true })
    }

    const handleOrganizationClick = () => {
      onPanelClose()
      navigate('/organization', { replace: true })
    }

    const handleAdminClick = () => {
      onPanelClose()
      navigate('/admin', { replace: true })
    }

    const handleWebhookClick = (isCallback?: boolean) => {
      if (isCallback) {
        setIsUpgradeModalOpen(false)
      }
      if (
        (userPlan === PlanNames.PRO || !userPlan) &&
        !workspaceId &&
        !isCallback
      ) {
        sendEvent(GTAG_EVENTS.PURCHASE_INTENT, {
          user_email: userData?.email,
          purchase_intent_method: ACTION_CLICKS.WEBHOOK_CLICK,
        })
        setIsUpgradeModalOpen(true)
        return
      }
      setWebhookModal(true)
    }

    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false)

    const isAdminButtonHidden = useMemo(() => {
      const isUserAdmin = orgUsers?.find(
        (org) => org.organization.id === DECKTOPUS_WORKSPACE_ID,
      )?.isAdmin

      return DECKTOPUS_WORKSPACE_ID !== workspaceId || !isUserAdmin
    }, [workspaceId, orgUsers])

    return (
      <>
        <div css={wrapperStyles} className={className} {...dataAttr}>
          <div className="user-badge-area">
            <UserBadge
              image={userData?.picture || ''}
              name={userData?.fullName || ''}
              email={userData?.email}
              plan={planText}
            />
          </div>
          <SideMenu
            items={[
              {
                label: t('main_panel.admin'),
                icon: icons.admin,
                onClick: handleAdminClick,
                hidden: isAdminButtonHidden,
              },
              {
                label: t('main_panel.organization'),
                icon: icons.team,
                onClick: handleOrganizationClick,
                hidden: !workspaceId,
              },
              {
                label: t('main_panel.profile_settings'),
                icon: icons.settings,
                onClick: () => onPanelOpen(2),
              },
              {
                label: t('main_panel.webhook'),
                icon: icons.webhook,
                onClick: () => handleWebhookClick(false),
              },
              {
                label: t('main_panel.refer_and_earn'),
                icon: icons.refer_earn,
                onClick: () => setReferModalOpen(true),
              },
              {
                label: t('main_panel.subscription'),
                icon: icons.terms_and_conditions,
                onClick: () => onPanelOpen(0),
              },
              {
                label: t('main_panel.onboarding'),
                icon: icons.monitor,
                hidden: true,
              },
              { separator: true },
              {
                label: t('main_panel.logout'),
                icon: icons.log_out,
                onClick: handleLogoutClick,
              },
            ]}
          />
        </div>
        <Modal isOpen={webhookModal} onClose={() => setWebhookModal(false)}>
          <WebhookForm onClickClose={() => setWebhookModal(false)} />
        </Modal>
        {isUpgradeModalOpen && (
          <UpgradeModalManager
            isOpen={isUpgradeModalOpen}
            callback={() => handleWebhookClick(true)}
            availablePlanNames={[PlanNames.BUSINESS]}
            context={ACTION_CLICKS.WEBHOOK_CLICK}
            onClose={() => setIsUpgradeModalOpen(false)}
          />
        )}
        {referModalOpen && (
          <ReferModal
            isOpen={referModalOpen}
            onClose={() => setReferModalOpen(false)}
          />
        )}
      </>
    )
  },
)

MainPanel.displayName = 'MainPanel'
