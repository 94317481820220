import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IEditNotes, NOTE_TYPE } from './types'
import { editNotesStyles, noteStyles, paddingStyles } from './styles'
import { Tabs } from 'src/lib/tabs/Tabs'
import { TABS_THEME, Textarea } from 'src/lib'
import { useDecksApi, useLanguage } from 'src/hooks'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const EditNotes: React.FC<IEditNotes> = React.memo(
  ({ className, dataAttr }) => {
    const { updateSlideNotes } = useDecksApi()
    const { t } = useLanguage()

    const [activeTab, setActiveTab] = useState(0)
    const [speakerNote, setSpeakerNote] = useState('')
    const [slideNote, setSlideNote] = useState('')

    const { deckId, activeSlideDataId, slideId, activeSlideData } = useSelector(
      ({ edit }: RootState) => ({
        deckId: edit.activeDeck.data?.deck?.id,
        slideId: edit.activeSlideID,
        activeSlideDataId: edit.activeSlideDataID,
        activeSlideData: edit.activeSlideData,
      }),
    )

    const selectedSlideData = useMemo(
      () =>
        activeSlideData.data?.find(
          (slideData) => slideData.id === activeSlideDataId,
        ),
      [activeSlideData.data, activeSlideDataId],
    )

    useEffect(() => {
      handleSlideNoteChange()
    }, [slideId, deckId, selectedSlideData?.slide.id])

    const updateNotes = useCallback(
      ({ type }: { type: NOTE_TYPE }) =>
        async (value?: string) => {
          if (!activeSlideDataId) {
            return
          }
          await updateSlideNotes({
            slideNote: type === NOTE_TYPE.SLIDE ? value : slideNote,
            speakerNote: type === NOTE_TYPE.SPEAKER ? value : speakerNote,
          })
        },
      [slideNote, speakerNote, activeSlideDataId],
    )

    const handleSlideNoteChange = useCallback(() => {
      if (!selectedSlideData) {
        return
      }
      setSlideNote(selectedSlideData?.slide.slideNote)
      setSpeakerNote(selectedSlideData?.slide.speakerNote)
    }, [deckId, slideId, selectedSlideData])

    const onChangeHandler = useCallback((val: any) => {
      setSlideNote(val)
    }, [])

    const onTrancriptChangeHandler = useCallback((val: any) => {
      setSpeakerNote(val)
    }, [])

    return (
      <div css={editNotesStyles} className={className} {...dataAttr}>
        <div css={paddingStyles}>
          <Tabs
            theme={TABS_THEME.DARK}
            tabs={[
              t('edit.panel.menu_tabs.slide_notes'),
              t('edit.panel.menu_tabs.transcripts'),
            ]}
            activeTab={activeTab}
            onChange={setActiveTab}
          />
          {activeTab == 0 && (
            <Textarea
              value={slideNote}
              onChange={onChangeHandler}
              onBlur={updateNotes({ type: NOTE_TYPE.SLIDE })}
              css={noteStyles}
              note={true}
            />
          )}
          {activeTab == 1 && (
            <Textarea
              value={speakerNote}
              onChange={onTrancriptChangeHandler}
              onBlur={updateNotes({ type: NOTE_TYPE.SPEAKER })}
              css={noteStyles}
              note={true}
            />
          )}
        </div>
      </div>
    )
  },
)

EditNotes.displayName = 'EditNotes'
