import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import ToggleButton from '@mui/material/ToggleButton'

import { observer } from 'mobx-react-lite'
import { StoreType } from 'polotno/model/store'
import React, { useEffect, useState } from 'react'
import { MaterialSymbol } from 'react-material-symbols'
import StyledToggleButton from 'src/components/toggle-button'
import StyledToggleButtonGroup from 'src/components/toggle-button-group'
import { useLanguage } from 'src/hooks'
import dashedIcon from '../../../../assets/images/dashed.svg'
import dashedMoreIcon from '../../../../assets/images/dashedMore.svg'
import dashedPointsIcon from '../../../../assets/images/dashedPoints.svg'
import noStroke from '../../../../assets/images/no-stroke.svg'
import singleLine from '../../../../assets/images/singleLine.svg'
import SliderWithInput from './SliderWithInput'

import { FigureElementType } from 'polotno/model/figure-model'
import { useLineSettingsStyles } from './styles'

enum LineSize {
  noStroke = 'noStroke',
  straight = 'straight',
  dashed = 'dashed',
  moreDashed = 'moreDashed',
  points = 'points',
}

const icons: Record<string, string> = {
  noStroke: noStroke,
  straight: singleLine,
  dashed: dashedIcon,
  moreDashed: dashedMoreIcon,
  points: dashedPointsIcon,
}

const StrokeSettingsAction = observer(
  ({
    store,
    type = 'line',
  }: {
    store: StoreType
    type?: 'line' | 'figure'
  }) => {
    const hasLockedItems = store.selectedElements?.some(
      (element) => element.locked,
    )

    const allLines = store.selectedElements?.every(
      (element) => element.type === 'line',
    )

    const allFigures = store.selectedElements?.every(
      (element) => element.type === 'figure',
    )

    const element = store.selectedElements?.[0] as FigureElementType

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const { t } = useLanguage()
    const { classes } = useLineSettingsStyles()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'line-settings-popover' : undefined

    const handleChange = (newValue: number) => {
      if (isNaN(newValue)) {
        return
      }
      const value = Math.floor(newValue)
      if (type === 'line') {
        store.selectedElements.forEach((element) => {
          element.set({
            height: value,
          })
        })
      } else {
        store.selectedElements.forEach((element) => {
          element.set({
            strokeWidth: value,
          })
        })
      }
    }

    const handleCornerRadiusChange = (newValue: number) => {
      if (isNaN(newValue)) {
        return
      }

      store.selectedElements.forEach((element) => {
        element.set({
          cornerRadius: newValue,
        })
      })
    }

    const [lineSize, setLineSize] = React.useState<LineSize>(LineSize.straight)

    useEffect(() => {
      let initialLineSize = LineSize.straight
      const elementDash = element.dash
      if (elementDash) {
        if (elementDash[0] === 10) {
          initialLineSize = LineSize.dashed
        } else if (elementDash[0] === 6) {
          initialLineSize = LineSize.moreDashed
        } else if (elementDash[0] === 2) {
          initialLineSize = LineSize.points
        }
      }

      if (
        type === 'figure' &&
        element.strokeWidth === 0 &&
        initialLineSize === LineSize.straight
      ) {
        initialLineSize = LineSize.noStroke
      }
      setLineSize(initialLineSize)
    }, [type])

    const getDash = (newLineSize: LineSize) => {
      switch (newLineSize) {
        case LineSize.straight:
          return []
        case LineSize.dashed:
          return [10, 4]
        case LineSize.moreDashed:
          return [6, 4]
        case LineSize.points:
          return [2, 2]
        default:
          return null
      }
    }

    const handleFormat = (
      event: React.MouseEvent<HTMLElement>,
      newLineSize: LineSize | undefined,
    ) => {
      if (!newLineSize) {
        return
      }
      setLineSize(newLineSize)

      if (type === 'line') {
        store.selectedElements.forEach((element) => {
          element.set({
            dash: getDash(newLineSize) ?? [],
          })
        })
      } else {
        const dashValue = getDash(newLineSize)
        if (dashValue) {
          store.selectedElements.forEach((element) => {
            element.set({
              dash: dashValue,
            })
          })
        } else {
          store.selectedElements.forEach((element) => {
            element.set({
              strokeWidth: 0,
            })
          })
        }
      }
    }

    const SettingsButtons = Object.keys(LineSize).map((value) => {
      if (value === LineSize.noStroke && type === 'line') {
        return null
      }
      return (
        <ToggleButton
          key={value}
          value={value}
          aria-label="points"
          className={classes.button}
        >
          <img src={icons[value]} alt="dashedPoints" />
        </ToggleButton>
      )
    })

    return (allLines || allFigures) && !hasLockedItems ? (
      <>
        <StyledToggleButton selected={open} value={open} onClick={handleClick}>
          <MaterialSymbol
            icon="settings_input_component"
            size={20}
            weight={300}
          />
        </StyledToggleButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 48,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          marginThreshold={32}
          elevation={6}
          classes={{
            paper: classes.container,
          }}
        >
          <Stack sx={{ p: 3, gap: 2 }}>
            <StyledToggleButtonGroup
              size="small"
              value={lineSize}
              onChange={handleFormat}
              aria-label="text formatting"
              exclusive
              sx={{
                'button:first-of-type': {
                  marginLeft: 0,
                },
              }}
            >
              {SettingsButtons}
            </StyledToggleButtonGroup>
            <SliderWithInput
              min={1}
              max={100}
              label={t(type === 'line' ? 'v3.line_size' : 'v3.stroke_size')}
              value={type === 'line' ? element.height : element.strokeWidth}
              onValueChange={handleChange}
            />
            {element.subType === 'rect' && (
              <SliderWithInput
                min={1}
                max={Math.ceil(element.width / 2)}
                label={t('v3.corner_radius')}
                value={element.cornerRadius}
                onValueChange={handleCornerRadiusChange}
              />
            )}
          </Stack>
        </Popover>
      </>
    ) : null
  },
)
export default StrokeSettingsAction
