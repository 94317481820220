import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'src/store'

export const useSlideNavControls = (isEnabled?: boolean) => {
  const params = useParams()
  const navigate = useNavigate()

  const { activeSlides, isNextSlideBlocked } = useSelector(
    ({ edit, canvas }: RootState) => ({
      activeSlides: edit.activeDeck.data?.deckData?.data.slides,
      isNextSlideBlocked: canvas.isNextSlideBlocked,
    }),
  )

  const locationContext = useMemo(() => {
    const contextArray = location.pathname.split('/')
    return contextArray[1]
  }, [location.pathname])

  const documentKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const currentSlide = params.slide
      const filteredSlides = activeSlides?.filter(({ isDeleted }) => !isDeleted)
      const maxSlide = filteredSlides?.length
      if (!currentSlide || !maxSlide) {
        return
      }

      if (
        (event.code === 'Space' || event.code === 'ArrowRight') &&
        !isNextSlideBlocked
      ) {
        const targetNext =
          parseInt(currentSlide) + 1 > maxSlide
            ? maxSlide
            : parseInt(currentSlide) + 1

        navigate(`/${locationContext}/${params.id}/${targetNext}`)
      }

      if (event.code === 'ArrowLeft') {
        const targetPrev =
          parseInt(currentSlide) - 1 < 1 ? 1 : parseInt(currentSlide) - 1
        navigate(`/${locationContext}/${params.id}/${targetPrev}`)
      }
    },
    [params, activeSlides, isNextSlideBlocked],
  )

  useEffect(() => {
    if (isEnabled) {
      document.addEventListener('keydown', documentKeyDown)
      return () => document.removeEventListener('keydown', documentKeyDown)
    }
  }, [documentKeyDown])
}
