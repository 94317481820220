import { COLOR } from './types'

export const primary = {
  DEFAULT: COLOR.primary_100,
  110: COLOR.primary_110,
  90: COLOR.primary_90,
  80: COLOR.primary_80,
  70: COLOR.primary_70,
  60: COLOR.primary_60,
  50: COLOR.primary_50,
  40: COLOR.primary_40,
  30: COLOR.primary_30,
  20: COLOR.primary_20,
  10: COLOR.primary_10,
}
