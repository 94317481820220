import React, { useCallback, useEffect, useState } from 'react'
import { useLanguage, VALIDATION_RULE_TYPES } from 'src/hooks'
import { IReferModal } from './types'
import {
  BUTTON_THEME,
  Modal,
  Dialog,
  DIALOG_THEME,
  DIALOG_PADDING,
} from 'src/lib'
import inviteFormImage from 'src/assets/images/invite-form-image.png'
import happyDeckto from 'src/assets/images/happy-deckto.png'
import { useUserApi } from 'src/hooks'

export const ReferModal: React.FC<IReferModal> = React.memo(
  ({ isOpen, onClose }) => {
    const { t } = useLanguage()
    const { isLoading, postReferEmail } = useUserApi()

    const [email, setEmail] = useState('')
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(isOpen)
    const [isThanksModalOpen, setIsThanksModalOpen] = useState(false)

    useEffect(() => {
      setIsInviteModalOpen(isOpen)
    }, [isOpen])

    const onReferModalClose = useCallback(() => {
      setEmail('')

      setIsThanksModalOpen(false)
      onClose()
    }, [onClose])

    const onInputSubmit = useCallback(async () => {
      if (!email || isLoading) {
        return
      }

      await postReferEmail(email)

      setEmail('')
      setIsInviteModalOpen(false)
      setIsThanksModalOpen(true)
    }, [email, isLoading])

    const onThanksModalSubmit = useCallback(async () => {
      onReferModalClose()
    }, [email, isLoading])

    return (
      <>
        <Modal isOpen={isInviteModalOpen} onClose={onReferModalClose}>
          <Dialog
            title={t('refer.invite_friend')}
            text={[t('refer.invite_friend_text')]}
            theme={DIALOG_THEME.COLORFUL}
            onSubmit={onInputSubmit}
            onCloseClick={onReferModalClose}
            submitTheme={BUTTON_THEME.GRADIENT}
            image={inviteFormImage}
            submitLabel={t('common.actions.submit')}
            promptLabel={t('common.email_address')}
            promptValue={email}
            onPromptChange={setEmail}
            promptValidationRules={{
              [VALIDATION_RULE_TYPES.VALIDMAIL]: {
                text: t('validation.error.email'),
              },
            }}
          />
        </Modal>
        <Modal isOpen={isThanksModalOpen} onClose={onReferModalClose}>
          <Dialog
            theme={DIALOG_THEME.COLORFUL}
            padding={DIALOG_PADDING.BIG}
            image={happyDeckto}
            title={t('common.informative.thanks')}
            text={[t('refer.referred')]}
            submitTheme={BUTTON_THEME.GRADIENT}
            submitLabel={t('common.informative.done')}
            onSubmit={onThanksModalSubmit}
            onCloseClick={onReferModalClose}
          />
        </Modal>
      </>
    )
  },
)
