export const DragRectEventName = 'drag-rect-event'

export interface DragRectEventDetail {
  top: number
  left: number
  width: number
  height: number
}

export class DragRectEvent extends CustomEvent<DragRectEventDetail> {
  constructor(detail: DragRectEventDetail) {
    super(DragRectEventName, { detail })
  }
}
