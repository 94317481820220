import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const aiErrorStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.gap.xlarge}px;
  padding: ${theme.gap['3xlarge']}px 0 0 0;
`
export const errorImageWrapperStyles = () => css`
  width: 300px;
  height: 230px;
`
export const errorTextStyles = (theme: ITheme) => css`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
`
export const buttonWrapperStyles = () => css`
  width: 100%;
`
