import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ISeparator } from './types'

type IWrapperStyles = Pick<ISeparator, 'text'>
export const wrapperStyles =
  ({ text }: IWrapperStyles) =>
  (theme: ITheme) => css`
    height: 19px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.text[2]};

    gap: ${!!text && theme.spacingV2.small};

    span {
      text-transform: uppercase;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      flex-grow: 1;
      border-top: 1px solid ${theme.colors.text[2]};
    }
  `
