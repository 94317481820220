import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLayoutsApi } from 'src/hooks'
import { RootState } from 'src/store'
import { PolotnoStore } from 'src/utils/PolotnoStore'
import StoreLoader from '../deck-page-v3/components/store/StoreLoader'
import { PreviewPage } from './PreviewPage'
import { IPreviewPage } from './types'
import { useParams } from 'react-router-dom'

const { store } = new PolotnoStore()

export const PreviewVersionWrapper: React.FC<IPreviewPage> = React.memo(
  (props) => {
    const { setLayoutVersion } = useLayoutsApi()
    const params = useParams()

    const { deckId, editor } = useSelector(({ edit }: RootState) => ({
      deckId: edit.activeDeck.data?.deck?.id,
      editor: edit.activeDeck.data?.deck?.editor,
    }))

    useEffect(() => {
      if (editor === 'v3') {
        setLayoutVersion('v3')
      }
    }, [setLayoutVersion, editor])

    const isV3 = editor === 'v3'
    const shareKey = location.pathname.includes('share') && params.id

    return (
      <>
        {deckId && isV3 && (
          <StoreLoader
            store={store}
            deckId={deckId}
            shareKey={shareKey || undefined}
          />
        )}
        <PreviewPage isV3={isV3} store={store} {...props} />
      </>
    )
  },
)
