import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const newSlideStyles = ({ colors, borderRadius }: ITheme) => css`
  border: 1px solid ${colors.elements.DEFAULT};
  height: 136px;
  width: 240px;
  border-radius: ${borderRadius[4]};
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-color: ${colors.primary.DEFAULT};
    cursor: pointer;

    path {
      fill: ${colors.primary.DEFAULT};
    }
  }
`
