import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const useActiveThemeId = () => {
  const { activeThemeId } = useSelector(({ edit }: RootState) => ({
    activeThemeId: edit.activeDeck.data?.deckData?.theme.id,
  }))

  return activeThemeId
}
