import React, { useCallback, useMemo } from 'react'
import { useLanguage } from 'src/hooks'
import {
  BOX_BUTTON_ICON_FRAME,
  PropBoxButton,
  PropHeader,
} from 'src/pages/deck-page/components/edit-properties/props'
import { IEditPropSection } from '../../types'
import { FillColor } from './FillColor'
import { ComponentRadiusTypes } from 'src/types/api/enums'
import {
  ComponentMediaDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { BackgroundColor } from './BackgroundColor'
import { icons } from 'src/lib'
import { DeepPartial } from 'src/types'
import { ComponentServices } from 'src/services'
import { useDispatch } from 'react-redux'
import { SAVE_STATE, componentsUpdate, setSaveState } from 'src/store'

export const IconFrame: React.FC<IEditPropSection> = React.memo(
  ({ components, className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const selectedRadiusType = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentRadiusTypes | undefined | null,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentMediaDataSchema).style
              ?.radiusType

            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? null : a
          },
          undefined,
        ),
      [components],
    )

    const handleRadiusChange = useCallback(
      (value?: ComponentRadiusTypes) => {
        const partialData: DeepPartial<ComponentMediaDataSchema> = {
          style: {
            radiusType: value,
          },
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentMediaDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components],
    )

    return (
      <div className={className} {...dataAttr}>
        <>
          <PropHeader text={t('edit.properties.icon.styles')} />
          <div className="grid">
            <div className="c-1">
              <PropBoxButton
                icon={icons.picture}
                iconSize={24}
                isActive={selectedRadiusType === ComponentRadiusTypes.FLAT}
                onClick={() => handleRadiusChange(ComponentRadiusTypes.FLAT)}
              />
            </div>
            <div className="c-1">
              <PropBoxButton
                icon={icons.picture}
                iconSize={24}
                isActive={selectedRadiusType === ComponentRadiusTypes.OUTLINE}
                onClick={() => handleRadiusChange(ComponentRadiusTypes.OUTLINE)}
                iconFrame={BOX_BUTTON_ICON_FRAME.OUTLINE}
              />
            </div>
            <div className="c-1">
              <PropBoxButton
                icon={icons.picture}
                iconSize={24}
                isActive={selectedRadiusType === ComponentRadiusTypes.ROUNDED}
                onClick={() => handleRadiusChange(ComponentRadiusTypes.ROUNDED)}
                iconFrame={BOX_BUTTON_ICON_FRAME.SQUARE}
              />
            </div>
            <div className="c-1">
              <PropBoxButton
                icon={icons.picture}
                iconSize={24}
                iconFrame={BOX_BUTTON_ICON_FRAME.CIRCLE}
                isActive={selectedRadiusType === ComponentRadiusTypes.CIRCLE}
                onClick={() => handleRadiusChange(ComponentRadiusTypes.CIRCLE)}
              />
            </div>
          </div>
        </>
        <div className="grid">
          <div className="c-1">
            <PropHeader text={t('edit.properties.icon.icon')} />
            <FillColor components={components} />
          </div>
          <div className="c-1">
            <PropHeader text={t('edit.properties.icon.background')} />
            <BackgroundColor components={components} />
          </div>
        </div>
      </div>
    )
  },
)

IconFrame.displayName = 'IconFrame'
