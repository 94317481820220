import {
  ComponentMediaZoomTypes,
  ComponentTypes,
  MediaTypes,
} from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { MediaDisplay } from '../components/media-display'

export const shapeDef: IElementDefinition = {
  display: {
    name: '',
    styles: {},
    image: '',
    svgUrl: 'https://cdn.decktopus.com/shapes/Circle.svg',
    Component: MediaDisplay,
  },
  schemas: [
    {
      tempId: '',
      type: ComponentTypes.MEDIA,
      isLocked: false,
      positions: {
        x: 0,
        y: 0,
        width: 150,
        height: 150,
        rotate: 0,
        scale: 1,
      },
      style: {},
      data: {
        mediaType: MediaTypes.SHAPE,
        mediaUrl: 'https://cdn.decktopus.com/shapes/Circle.svg',
        style: {
          color: {
            text: { colors: ['var(--shape)'] },
            background: { colors: ['var(--shape)'] },
          },
        },
        caption: null,
        zoom: {
          x: '0',
          y: '0',
          level: 1,
          type: ComponentMediaZoomTypes.DISTORT,
        },
      },
    },
  ],
}
