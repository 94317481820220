import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { useLanguage, useClickOutside } from 'src/hooks'
import { useTheme } from 'src/theme'
import { usePlanApi, useUserApi } from 'src/hooks'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'
import { IAdjustSeats } from './types'
import {
  adjustSeatsStyles,
  adjustSeatsTitleStyles,
  adjustSeatsRowStyles,
  seperatorStyles,
  adjustSeatsInfoStyles,
  adjustSeatsTotalStyles,
  adjustSeatsActionsRowStyle,
  adjustSeatsCloseStyles,
} from './styles'
import { NumberSelect } from 'src/lib/number-select'
import {
  BUTTON_SIZE,
  BUTTON_THEME,
  BUTTON_TYPE,
  Button,
  Separator,
  Icon,
  icons,
  Tooltip,
  TOOLTIP_PLACE,
} from 'src/lib'
import { CURRENCY_DATA } from 'src/data'
import { PlanPeriods, UserPlanPaymentStates } from 'src/types/api/enums'
import { useOrgApi } from 'src/hooks/api/useOrgApi'

export const AdjustSeats: React.FC<IAdjustSeats> = React.memo(
  ({ totalSeats, onClose, className, dataAttr }) => {
    const { t } = useLanguage()
    const { colors } = useTheme()

    const adjustSeatsRef = useRef(null)
    const outsideClick = () => onClose()
    useClickOutside(adjustSeatsRef, outsideClick)

    const { purchasePlan, getPossiblePlans, isPurchaseLoading } = usePlanApi()
    const { getUser } = useUserApi()
    const { getOrgDetails } = useOrgApi()

    useEffect(() => {
      getPossiblePlans()
    }, [])

    const { plan, price, currency, paymentState, workspaceId } = useSelector(
      ({ user, plan, workspace }: RootState) => ({
        plan: user.data?.activeUserPlan?.plan,
        price: plan.possiblePlans?.find(
          (plan) => plan.name === user.data?.activeUserPlan?.plan.name,
        )?.price,
        currency: CURRENCY_DATA[user.data?.currency ?? 1],
        paymentState: user.data?.activeUserPlan?.paymentState,
        workspaceId: workspace.id,
      }),
    )

    const [seatSize, setSeatSize] = useState<number>(totalSeats)

    useEffect(() => {
      setSeatSize(totalSeats)
    }, [totalSeats])

    const isSeatsIncreased = useMemo(
      () => seatSize > totalSeats,
      [seatSize, totalSeats],
    )

    const pricePerUnit = useMemo(() => {
      return price?.unitAmount && price?.unitAmount / 100
    }, [plan, price])

    const calcPerUserPrice = useMemo(() => {
      if (plan?.period === PlanPeriods.ANNUAL && pricePerUnit) {
        return (pricePerUnit / 12) * (seatSize - totalSeats)
      }
      return pricePerUnit! * (seatSize - totalSeats)
    }, [plan, pricePerUnit, seatSize, totalSeats])

    const calcTotalPrice = useMemo(() => {
      if (plan?.period === PlanPeriods.ANNUAL && pricePerUnit) {
        return (pricePerUnit / 12) * seatSize
      }
      return pricePerUnit! * seatSize
    }, [plan, pricePerUnit, seatSize])

    const currentPrice = useMemo(
      () => calcTotalPrice! - calcPerUserPrice!,
      [calcTotalPrice, calcPerUserPrice],
    )

    const isApproveDisabled = useMemo(
      () => isPurchaseLoading || totalSeats === seatSize,
      [isPurchaseLoading, totalSeats, seatSize],
    )

    const planPeriodText = useMemo(
      () =>
        plan?.period === PlanPeriods.ANNUAL
          ? t('common.plan_period.annually')
          : t('common.plan_period.monthly'),
      [plan],
    )

    const handleDiscard = () => {
      setSeatSize(totalSeats)
      onClose()
    }

    const handleApprove = useCallback(async () => {
      await purchasePlan({
        planName: plan!.name,
        planPeriod: plan!.period,
        seatSize,
        isPreview: true,
      })
    }, [plan, seatSize])

    const [retries, setRetries] = useState(0)
    const getUserAfterPurchase = useCallback(async () => {
      const res = await getUser()
      workspaceId && getOrgDetails(workspaceId)
      if (
        (res.user?.activeUserPlan?.paymentState ===
          UserPlanPaymentStates.PAYMENT_PENDING ||
          res.user?.activeUserPlan?.paymentState ===
            UserPlanPaymentStates.PAYMENT_ACTION_REQUIRED) &&
        retries < 3
      ) {
        setRetries(retries + 1)
        setTimeout(() => {
          getUserAfterPurchase()
          workspaceId && getOrgDetails(workspaceId)
        }, 3000)
      } else {
        setRetries(0)
      }
    }, [workspaceId, retries, paymentState])

    const handleSeatSizeChange = (size: number) => {
      setSeatSize(size)
    }

    return (
      <div
        css={adjustSeatsStyles}
        className={className}
        {...dataAttr}
        ref={adjustSeatsRef}
      >
        <div css={adjustSeatsTitleStyles}>
          {t('organization.adjust_seat_number')}
        </div>
        <NumberSelect
          min={totalSeats}
          value={seatSize}
          name={t('common.seats', { count: totalSeats })}
          onChange={handleSeatSizeChange}
          className="number-select"
        />
        <div css={adjustSeatsRowStyles} className="first-row">
          <span>{t('organization.current_users', { count: totalSeats })}</span>
          <span>
            {currency.symbol}
            {currentPrice?.toFixed(2)}
          </span>
        </div>
        {isSeatsIncreased && (
          <div css={adjustSeatsRowStyles}>
            <span>
              {t('organization.added_users', { count: seatSize - totalSeats })}
            </span>
            <span>
              {currency.symbol}
              {calcPerUserPrice?.toFixed(2)}
            </span>
          </div>
        )}
        <Separator css={seperatorStyles} />
        <div css={adjustSeatsRowStyles}>
          <span css={adjustSeatsTotalStyles}>
            {t('organization.updated_annual_total', { period: planPeriodText })}
          </span>
          <span>
            {currency.symbol}
            {calcTotalPrice?.toFixed(2)}
          </span>
        </div>
        <Tooltip
          width={282}
          text={t('organization.first_charge_tooltip')}
          place={TOOLTIP_PLACE.TOP_LEFT}
        >
          <div css={adjustSeatsInfoStyles}>
            <Icon
              className="icon"
              icon={icons.info}
              size={16}
              color={colors.text[2]}
            />
            <span>{t('organization.first_charge')}</span>
          </div>
        </Tooltip>
        <div css={adjustSeatsActionsRowStyle}>
          <Button
            theme={BUTTON_THEME.RED}
            type={BUTTON_TYPE.GHOST}
            size={BUTTON_SIZE.XSMALL}
            text={t('common.actions.discard')}
            onClick={handleDiscard}
            disabled={isPurchaseLoading}
          />
          <Button
            text={t('common.actions.approve')}
            size={BUTTON_SIZE.XSMALL}
            onClick={handleApprove}
            disabled={isApproveDisabled}
          />
        </div>
        <div css={adjustSeatsCloseStyles}>
          <Button
            icon={icons.close}
            theme={BUTTON_THEME.GRAY}
            type={BUTTON_TYPE.GHOST}
            isLink
            iconSize={20}
            onClick={onClose}
          />
        </div>
      </div>
    )
  },
)
