import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const orgAddMembersStyles = ({
  borderRadius,
  boxShadow,
  breakpointsV2: breakpoints,
  dimensions,
}: ITheme) => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  box-shadow: ${boxShadow.DEFAULT};
  overflow: hidden;

  @media (min-width: ${breakpoints.tablet}px) {
    border-radius: ${borderRadius[10]};
    width: ${dimensions.addMembersModalWidth.DEFAULT}px;
    height: ${dimensions.addMembersModalHeight.DEFAULT}px;
  }
`

export const orgAddMembersLeftColStyles = ({
  breakpointsV2: breakpoints,
  colors,
  dimensions,
}: ITheme) => css`
  display: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: ${dimensions.addMembersModalLeftColWidth.DEFAULT}px;
  height: 100%;
  background: ${colors.background.DEFAULT};

  @media (min-width: ${breakpoints.tablet}px) {
    display: inline-flex;
  }
`

export const orgAddMembersRightColStyles = ({ colors, gap }: ITheme) => css`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: ${colors.white.DEFAULT};
  padding: ${gap.xlarge}px;
  row-gap: ${gap.xsmall}px;
`

export const orgAddMembersTitleStyles = ({
  breakpointsV2: breakpoints,
  fontSize,
  fontWeight,
  gap,
}: ITheme) => css`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: max-content;
  font-size: ${fontSize.small};
  font-weight: ${fontWeight.medium};
  padding-top: ${gap.xlarge}px;

  button {
    position: absolute;
    top: ${gap.medium}px;
    right: ${gap.medium}px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    padding-top: 0;

    button {
      position: relative;
      top: unset;
      right: unset;
      padding: 0 !important;
    }
  }
`

export const orgAddMembersFirstRowStyles = ({ dimensions, gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  row-gap: ${gap.xsmall}px;
  padding-bottom: ${gap.medium}px;

  & .email-button-row {
    display: flex;
    flex-direction: row;

    & > button {
      width: ${dimensions.iconButtonWidth.DEFAULT}px;
      height: ${dimensions.buttonHeight.DEFAULT}px;
    }

    & > div {
      width: calc(100% - ${dimensions.iconButtonWidth.DEFAULT}px);
      padding-right: ${gap.xsmall}px;
    }
  }
`

export const orgAddMemberEmailItemStyles = ({
  borderRadius,
  colors,
  dimensions,
  fontSize,
  gap,
}: ITheme) => css`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${dimensions.addMembersModalMailItemHeight.DEFAULT}px;
  padding: ${gap.xsmall}px;
  background-color: ${colors.background.DEFAULT};
  border-radius: ${borderRadius[4]};

  span {
    font-size: ${fontSize.small};
  }
`
export const orgAddMembersUsedSeatsStyles =
  (isFull: boolean) =>
  ({ colors, fontSize }: ITheme) => css`
    color: ${isFull ? colors.error[90] : colors.text[2]};
    font-size: ${fontSize.small};
  `

export const orgAddMembersInformationStyles =
  (type: 'warning' | 'information') =>
  ({ borderRadius, colors, fontSize, gap }: ITheme) => css`
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${gap.xsmall}px;
    column-gap: ${gap.xsmall}px;
    border: 1px solid
      ${type === 'warning' ? colors.red.DEFAULT : colors.outline.DEFAULT};
    color: ${colors.text[2]};
    font-size: ${fontSize.xsmall};
    line-height: 155%;
    border-radius: ${borderRadius[4]};
  `

export const orgAddMembersActionStyles = ({ gap }: ITheme) => css`
  padding-top: ${gap.medium}px;
`
