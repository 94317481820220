import { ISwitchSizeStyles, SWITCH_SIZE } from './types'

export const sizeStyles = (size: SWITCH_SIZE): ISwitchSizeStyles => {
  switch (size) {
    case SWITCH_SIZE.LARGE:
      return {
        wrapperWidth: '52px',
        wrapperHeight: '32px',
        sliderWidth: '24px',
        sliderHeight: '24px',
        sliderDistance: '24px',
        sliderPosition: '4px',
      }
    case SWITCH_SIZE.SMALL:
      return {
        wrapperWidth: '44px',
        wrapperHeight: '28px',
        sliderWidth: '20px',
        sliderHeight: '20px',
        sliderDistance: '20px',
        sliderPosition: '4px',
      }
    case SWITCH_SIZE.XSMALL:
      return {
        wrapperWidth: '28px',
        wrapperHeight: '18px',
        sliderWidth: '14px',
        sliderHeight: '14px',
        sliderDistance: '12px',
        sliderPosition: '2px',
      }
  }
}
