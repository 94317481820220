import React, { useMemo } from 'react'

import { ITable } from './types'
import { tableStyles } from './styles'
import { Table as LibTable } from 'src/lib'
import { ComponentTableDataSchema } from 'src/types/api/requestObjects'

export const Table: React.FC<ITable> = React.memo(
  ({ data, className, dataAttr }) => {
    const componentData: ComponentTableDataSchema = useMemo(
      () => data.data as ComponentTableDataSchema,
      [data.data],
    )
    return (
      <div css={tableStyles} className={className} {...dataAttr}>
        <LibTable
          options={{
            headerCol: componentData.headerCol,
            headerRow: componentData.headerRow,
          }}
          styles={{
            borderWidth: 3,
            gaps: 30,
            colorBg: 'transparent',
            colorText: 'var(--tableContent)',
            colorBgAct: 'var(--tableHighlight)',
            colorPri: 'var(--tableContent)',
            colorBorder: 'var(--tableHighlight)',
          }}
          data={componentData.tableContent.map((v) =>
            v.map((v2) => v2.toString()),
          )}
        />
      </div>
    )
  },
)

Table.displayName = 'Table'
