import { useLanguage } from 'src/hooks'
import { planFeatures } from 'src/i18n/locales/en/planFeatures'
import { TKey } from 'src/i18n/types'
import { PlanNames, PlanPeriods } from 'src/types/api/enums'

interface IusePlanFeatures {
  name: PlanNames
  period: PlanPeriods
}

interface IusePlanFeaturesReturn {
  getTitle: (data: IusePlanFeatures) => string
  getFeatures: (data: IusePlanFeatures) => string[]
}

export const usePlanFeatures = (): IusePlanFeaturesReturn => {
  const { t } = useLanguage()

  const getFeatures = ({ name, period }: IusePlanFeatures): string[] => {
    return (Object.keys(planFeatures) as TKey[])
      .filter((feat) => {
        return (
          feat.includes(name === PlanNames.BUSINESS ? 'business' : 'pro') &&
          feat.includes(period === PlanPeriods.ANNUAL ? 'annual' : 'monthly') &&
          !feat.includes('title')
        )
      })
      .map((featKey) => t(featKey))
  }

  const getTitle = ({ name, period }: IusePlanFeatures): string => {
    const titleKey = (Object.keys(planFeatures) as TKey[]).find((feat) => {
      return (
        feat.includes(name === PlanNames.BUSINESS ? 'business' : 'pro') &&
        feat.includes(period === PlanPeriods.ANNUAL ? 'annual' : 'monthly') &&
        feat.includes('title')
      )
    })
    return titleKey ? t(titleKey) : ''
  }

  return { getTitle, getFeatures }
}
