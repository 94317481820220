import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API_CONFIG } from 'src/config'
import { RequestResponse, RequestServices } from 'src/services'
import { RootState } from 'src/store'
import { setDocumentData } from 'src/store/decks-v3'
import {
  postSaveSlideNoteBody,
  postSyncSlidesBody,
} from 'src/types/api/requestObjects'
import {
  GetDeckV3Document,
  getDeckV3DocumentResponse,
  postSaveSlideNoteBodyResponse,
  postSyncSlidesBodyResponse,
} from 'src/types/api/responseObjects'

interface IUseDecksV3ApiReturn {
  getDocument: (deckId: number) => Promise<GetDeckV3Document | undefined>
  getPublicDocument: (
    shareKey: string,
  ) => Promise<GetDeckV3Document | undefined>
  syncSlides: (
    deckId: number,
    slides: postSyncSlidesBody,
  ) => Promise<postSyncSlidesBodyResponse['data'] | undefined>
  saveSlideNote: (
    deckId: number,
    data: postSaveSlideNoteBody,
  ) => Promise<postSaveSlideNoteBodyResponse['data'] | undefined>
  isLoading: boolean
}

export const useDecksV3Api = (): IUseDecksV3ApiReturn => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { organizationId } = useSelector(({ workspace }: RootState) => ({
    organizationId: workspace.id,
  }))

  const getDocument = async (deckId: number) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getDeckV3DocumentResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.DECK_V3_DOCUMENT(deckId),
          params: { organizationId },
        })
      dispatch(setDocumentData(res.data.data))
      return res.data.data
    } catch {
      return undefined
    } finally {
      setIsLoading(false)
    }
  }

  const getPublicDocument = async (shareKey: string) => {
    setIsLoading(true)
    try {
      const res: RequestResponse<getDeckV3DocumentResponse, any> =
        await RequestServices.callApi({
          method: 'GET',
          url: API_CONFIG.PUBLIC_DECK_V3_DOCUMENT(shareKey),
          params: { organizationId },
        })
      dispatch(setDocumentData(res.data.data))
      return res.data.data
    } catch {
      return undefined
    } finally {
      setIsLoading(false)
    }
  }

  const syncSlides = useCallback(
    async (deckId: number, data: postSyncSlidesBody) => {
      setIsLoading(true)
      try {
        const res: RequestResponse<postSyncSlidesBodyResponse, any> =
          await RequestServices.callApi({
            method: 'POST',
            url: API_CONFIG.DECK_V3_SYNC_SLIDES(deckId),
            data: { ...data, organizationId },
          })

        return res.data.data
      } catch {
        return undefined
      } finally {
        setIsLoading(false)
      }
    },
    [organizationId],
  )
  const saveSlideNote = useCallback(
    async (deckId: number, data: postSaveSlideNoteBody) => {
      setIsLoading(true)
      try {
        const res: RequestResponse<postSaveSlideNoteBodyResponse, any> =
          await RequestServices.callApi({
            method: 'POST',
            url: API_CONFIG.DECK_V3_SAVE_SLIDE_NOTE(deckId),
            data: { ...data, organizationId },
          })

        return res.data.data
      } catch {
        return undefined
      } finally {
        setIsLoading(false)
      }
    },
    [organizationId],
  )

  return {
    getDocument,
    syncSlides,
    saveSlideNote,
    getPublicDocument,
    isLoading,
  }
}
