import Button from '@mui/material/Button'
import { observer } from 'mobx-react-lite'
import { useLanguage } from 'src/hooks'
import { IPolotnoComponent } from '../../types'
import { MaterialSymbol } from 'react-material-symbols'

const FitToPageAction = observer<IPolotnoComponent>(({ store }) => {
  const hasLockedItems = store.selectedElements?.some(
    (element) => element.locked,
  )

  const { t } = useLanguage()

  const handleClick = () => {
    store.selectedElements?.forEach((element) => {
      element.set({
        x: 0,
        y: 0,
        width: store.activePage.computedWidth,
        height: store.activePage.computedHeight,
      })
    })
  }

  return !hasLockedItems ? (
    <Button
      size="small"
      variant="outlined"
      disableElevation
      onClick={handleClick}
      sx={{
        flexShrink: 0,
      }}
      startIcon={<MaterialSymbol icon="fit_screen" weight={300} />}
    >
      {t('v3.fit')}
    </Button>
  ) : null
})
export default FitToPageAction
