import React from 'react'
import { useLanguage } from 'src/hooks'

import { BUTTON_SIZE, Button } from 'src/lib'
import { IPlanBadge, PLAN_BADGE_TYPE } from './types'
import { wrapperStyles } from './styles'
import decktopusLogoCircle from 'src/assets/images/decktopus-logo-circle.svg'

export const PlanBadge: React.FC<IPlanBadge> = React.memo(
  ({
    title,
    price,
    type = PLAN_BADGE_TYPE.DEFAULT,
    workspaceId,
    paymentFailed,
    isUpgradeable,
    shouldUpdatePaymentMethod,
    onClick,
    className,
    dataAttr,
  }) => {
    const { t } = useLanguage()

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div className="title">{title}</div>

        {type === PLAN_BADGE_TYPE.DEFAULT ? (
          <div>
            {shouldUpdatePaymentMethod && (
              <Button
                onClick={onClick}
                size={BUTTON_SIZE.XSMALL}
                text={t('subscriptions.update_payment')}
              />
            )}
            {!shouldUpdatePaymentMethod && (isUpgradeable || !workspaceId) && (
              <Button
                onClick={onClick}
                size={BUTTON_SIZE.XSMALL}
                text={
                  paymentFailed
                    ? t('common.actions.activate')
                    : t('subscriptions.upgrade')
                }
              />
            )}
          </div>
        ) : (
          <div className="currency">
            <img src={decktopusLogoCircle} />
            <div className="price">{price || 0}</div>
          </div>
        )}
      </div>
    )
  },
)
