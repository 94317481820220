import React, { useEffect } from 'react'

import { IAiCategory } from './types'
import {
  aiCategoryPageStyles,
  aiCategoryWrapperStyles,
  aiCategoryStyles,
  aiCategoryNameStyles,
  aiCategoryItemStyles,
} from './styles'
import { colors } from 'src/theme'

import { buttonStyles } from '../ai-prompt/styles'
import { BUTTON_TYPE, Button, Icon, icons } from 'src/lib'
import { useDispatch, useSelector } from 'react-redux'
import { AI_FLOW_STEP, RootState, setAiFlowStep, setCategory } from 'src/store'
import { useLanguage } from 'src/hooks'
import { AiDeckCategories } from 'src/types/api/enums'
import { POSTHOG_EVENTS } from 'src/plugins/posthog/consts'
import usePostHogCapture from 'src/hooks/usePostHogCapture'

export const AiCategory: React.FC<IAiCategory> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()

    const dispatch = useDispatch()
    const posthogCapture = usePostHogCapture()

    const { selectedCategory, aiFlowId } = useSelector(
      ({ aiFlow }: RootState) => ({
        selectedCategory: aiFlow.selectedCategory,
        aiFlowId: aiFlow.aiFlowId,
      }),
    )

    useEffect(() => {
      aiFlowId &&
        posthogCapture(POSTHOG_EVENTS.AI_FLOW_CATEGORY_VIEW, { aiFlowId })
    }, [aiFlowId])

    const categorySelectionHandler = async (item: AiDeckCategories) => {
      posthogCapture(POSTHOG_EVENTS.AI_FLOW_CATEGORY_SELECTED, {
        aiFlowId,
        category_type: item,
      })
      dispatch(setCategory(item))
      dispatch(setAiFlowStep(AI_FLOW_STEP.IMPORT))
    }

    const categories = [
      {
        icon: icons.rocket,
        name: t('create_with_ai.category.option1'),
        value: AiDeckCategories.STARTUP_PITCH,
      },
      {
        icon: icons.business_category,
        name: t('create_with_ai.category.option2'),
        value: AiDeckCategories.BUSINESS_PROPOSAL,
      },
      {
        icon: icons.calculator,
        name: t('create_with_ai.category.option3'),
        value: AiDeckCategories.SALES,
      },
      {
        icon: icons.report_category,
        name: t('create_with_ai.category.option4'),
        value: AiDeckCategories.REPORT,
      },
      {
        icon: icons.educational,
        name: t('create_with_ai.category.option5'),
        value: AiDeckCategories.EDUCATIONAL,
      },
      {
        icon: icons.dots,
        name: t('create_with_ai.category.option6'),
        value: AiDeckCategories.OTHER,
      },
    ]

    const backButton = () => {
      dispatch(setAiFlowStep(AI_FLOW_STEP.PROMPT))
    }

    return (
      <div css={aiCategoryPageStyles}>
        <div css={aiCategoryWrapperStyles} className={className} {...dataAttr}>
          {categories.map((category) => (
            <div key={category.value} css={aiCategoryItemStyles}>
              <div
                css={aiCategoryStyles(
                  category.value === AiDeckCategories.OTHER,
                )}
                onClick={() => categorySelectionHandler(category.value)}
                className={
                  selectedCategory === category.value ? 'selected' : ''
                }
                {...dataAttr}
              >
                <Icon
                  icon={category.icon}
                  size={36}
                  color={colors.primary.DEFAULT}
                />
                <span css={aiCategoryNameStyles}>{category.name}</span>
              </div>
            </div>
          ))}
        </div>

        <div css={buttonStyles} style={{ height: '41px' }}>
          <Button
            type={BUTTON_TYPE.GHOST}
            text={t('common.actions.back')}
            icon={icons.chevron_left}
            isLink
            onClick={backButton}
          />
        </div>
      </div>
    )
  },
)

AiCategory.displayName = 'AiCategory'
