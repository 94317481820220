import {
  ComponentMediaZoomTypes,
  ComponentRadiusTypes,
  ComponentTypes,
  MediaTypes,
} from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { icons } from 'src/lib'
import { MediaDisplay } from '../components/media-display'
import i18n from 'src/i18n/config'

export const showcaseDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.showcase.display_name', { lng }),
      styles: {},
      icon: icons.showcase,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.MEDIA,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 600,
          height: 600,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          mediaType: MediaTypes.IMAGE,
          mediaUrl: '',
          style: {
            color: {
              text: { colors: ['#000000'] },
            },
            radiusType: ComponentRadiusTypes.FLAT,
          },
          caption: null,
          zoom: {
            x: '0',
            y: '0',
            level: 1,
            type: ComponentMediaZoomTypes.FILL,
          },
        },
      },
    ],
  }
}
