import React, { useCallback, useMemo, useState } from 'react'
import { IFormElement } from './types'
import { SvgMasked } from 'src/lib/svg-masked'
import { rateStyles } from './styles'

const rateIconCheckMap: Record<string, string> = {
  happy: 'happy-outlined',
  like: 'thumsup-outlined',
  heart: 'heart-outlined',
  star: 'star-outlined',
  user: 'user-outlined',
}

export const FormRate: React.FC<IFormElement> = React.memo(
  ({ formDTO, question, formDTOHandler }) => {
    const [rateHover, setRateHover] = useState<number | undefined>()

    const answerIndex = useMemo(() => {
      return formDTO?.data[question.questionId] as number
    }, [formDTO, question])

    const onChange = useCallback(
      (i: number) => {
        if (i && formDTO) {
          formDTOHandler({
            id: formDTO.id,
            state: formDTO.state,
            data: { ...formDTO?.data, [question.questionId]: i },
          })
        }
      },
      [formDTO, question.questionId, formDTOHandler],
    )

    const currentRateIndex = useMemo(() => {
      return rateHover !== undefined
        ? rateHover
        : answerIndex !== undefined
          ? answerIndex
          : -1
    }, [formDTO?.data[question?.questionId], rateHover, answerIndex])

    return (
      <div css={rateStyles}>
        {[...Array(question.rate?.range)].map((_, index) => (
          <div
            className="rate-button"
            key={index}
            onClick={() => onChange(index + 1)}
            onMouseOver={() => setRateHover(index + 1)}
            onMouseLeave={() => setRateHover(undefined)}
          >
            <SvgMasked
              url={`https://cdn.decktopus.com/icons/${
                currentRateIndex > index
                  ? question.rate?.icon
                  : rateIconCheckMap[question.rate?.icon || 'like']
              }.svg`}
              color={'var(--formIcon)'}
            />
          </div>
        ))}
      </div>
    )
  },
)
