import React from 'react'
import { formSlideStyles } from './styles'
import { FormQuestion } from './FormQuestion'
import { FormActions } from './FormActions'
import { FormGDPRCheck } from './FormGDPRCheck'
import { IFormContent } from './types'

export const FormContent: React.FC<IFormContent> = React.memo(
  ({
    baseSize,
    canvasType,
    currentSlide,
    componentData,
    formDTO,
    gdprCheck,
    isLastSlide,
    question,
    orderIndex: index,
    totalQuestionCount,
    width,
    gDPRCheckHandler,
    slideChangeHandler,
    formDTOHandler,
  }) => {
    return (
      <div
        css={formSlideStyles}
        style={{ width: `${width}px` }}
        key={question.questionId + index}
      >
        <FormQuestion
          data={componentData}
          options={componentData.options}
          question={question}
          total={totalQuestionCount}
          index={index}
          size={baseSize}
          canvasType={canvasType}
          formDTO={formDTO}
          formDTOHandler={formDTOHandler}
        />
        <FormGDPRCheck
          gDPRConsentMessage={
            componentData.options.defaultTexts.GDPRConsentMessage
          }
          isLastSlide={isLastSlide}
          isGDPRConsentEnabled={componentData.options.isGDPRConsentEnabled}
          isSelected={gdprCheck}
          gDPRCheckHandler={gDPRCheckHandler}
          className="formGDPRStyles"
        />
        <FormActions
          currentSlide={currentSlide}
          defaultTexts={componentData.options.defaultTexts}
          submitText={componentData.buttonText}
          totalQuestionCount={totalQuestionCount}
          slideChangeHandler={slideChangeHandler}
        />
      </div>
    )
  },
)
