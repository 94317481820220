import { css } from '@emotion/react'

interface IGridViewStyles {
  itemCount: number
}
export const gridViewStyles = ({ itemCount }: IGridViewStyles) => css`
  width: 100%;
  height: 100%;
  display: flex;
  ${itemCount >= 4 &&
  `
		flex-wrap: wrap;
	`}
`
