import React from 'react'
import { ITooltip, TOOLTIP_THEME } from './types'
import { tooltipViewStyles } from './styles'

export const TooltipView: React.FC<ITooltip> = React.memo(
  ({ theme = TOOLTIP_THEME.LIGHT, text, className, dataAttr }) => {
    return (
      <div
        css={tooltipViewStyles({ theme })}
        content={text}
        {...dataAttr}
        className={className}
      >
        {text}
      </div>
    )
  },
)
