import React from 'react'
import { IShapes } from './types'
import { BaseElement, ElementsBlock } from '../../components'
import { ELEMENT_DEFINITION } from '../../types'
import { useLanguage } from 'src/hooks'

const predefinedShapes = [
  'https://cdn.decktopus.com/shapes/Circle.svg',
  'https://cdn.decktopus.com/shapes/Line.svg',
  'https://cdn.decktopus.com/shapes/Polygon.svg',
  'https://cdn.decktopus.com/shapes/Polygon-4.svg',
  'https://cdn.decktopus.com/shapes/Polygon-2.svg',
  'https://cdn.decktopus.com/shapes/Polygon-3.svg',
  'https://cdn.decktopus.com/shapes/Rectangle.svg',
  'https://cdn.decktopus.com/shapes/Star.svg',
  'https://cdn.decktopus.com/shapes/Triangle.svg',
  'https://cdn.decktopus.com/shapes/Union.svg',
  'https://cdn.decktopus.com/shapes/Union-2.svg',
  'https://cdn.decktopus.com/shapes/Union-3.svg',
]

export const ShapeElements: React.FC<IShapes> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    return (
      <ElementsBlock
        name={t('edit.elements.shapes')}
        className={className}
        dataAttr={dataAttr}
      >
        <div className="grid">
          {predefinedShapes.map((svgUrl) => {
            return (
              <div className="c-1" key={'shape_' + svgUrl}>
                <BaseElement
                  className="col1"
                  definition={ELEMENT_DEFINITION.SHAPE}
                  svgUrl={svgUrl}
                />
              </div>
            )
          })}
        </div>
      </ElementsBlock>
    )
  },
)

ShapeElements.displayName = 'ShapeElements'
