import { useCallback } from 'react'
import { XYCoord } from 'react-dnd'
import { MathServices } from 'src/services'
import { ComponentSchema } from 'src/types/api/responseObjects'

const closerToFraction = (x: number) => Math.pow(x * 2 - 1, 2)

export const useGridSnap = () => {
  const gridParams = MathServices.gridParams()

  const snapToGrid = useCallback(
    (params: { x?: number; y?: number; width?: number; height?: number }) => {
      if (
        params.x !== undefined &&
        params.y !== undefined &&
        params.width !== undefined &&
        params.height !== undefined
      ) {
        // X
        const x = params.x / gridParams.width
        const width = (params.x + params.width) / gridParams.width
        const xWeight = closerToFraction(x % 1)
        const widthWeight = closerToFraction(width % 1)

        let snapX = 0
        let returnX = 0

        if (xWeight > widthWeight) {
          snapX = Math.round(x) * gridParams.width
          returnX = Math.round(x) * gridParams.width
        } else {
          snapX = Math.round(width) * gridParams.width
          returnX = Math.round(width) * gridParams.width - params.width
        }

        // Y
        const y = params.y / gridParams.height
        const height = (params.y + params.height) / gridParams.height
        const yWeight = closerToFraction(y % 1)
        const heightWeight = closerToFraction(height % 1)

        let snapY = 0
        let returnY = 0

        if (yWeight > heightWeight) {
          snapY = Math.round(y) * gridParams.height
          returnY = Math.round(y) * gridParams.height
        } else {
          snapY = Math.round(height) * gridParams.height
          returnY = Math.round(height) * gridParams.height - params.height
        }

        return { x: returnX, snapX, y: returnY, snapY }
      }

      return undefined
    },
    [],
  )

  interface ISnapFromExternal {
    rect?: DOMRect
    offset: XYCoord | null
    scale: number
  }
  const snapFromExternal = ({ rect, offset, scale }: ISnapFromExternal) => {
    const x = ((offset?.x || 0) - (rect?.x || 0)) / scale
    const y = ((offset?.y || 0) - (rect?.y || 0)) / scale
    const width = (rect?.width || 0) / scale
    const height = (rect?.height || 0) / scale
    return snapToGrid({ x, y, width, height })
  }

  interface ISnapFromInternal {
    item: ComponentSchema
    diff?: XYCoord | null
    scale: number
  }
  const snapFromInternal = ({ item, diff, scale }: ISnapFromInternal) => {
    const x = item.positions.x + (diff?.x || 0) / scale
    const y = item.positions.y + (diff?.y || 0) / scale
    const width = item.positions.width || 0
    const height = item.positions.height || 0
    return snapToGrid({ x, y, width, height })
  }

  return { snapToGrid, snapFromExternal, snapFromInternal }
}
