import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMediaItem } from 'src/pages/deck-page-v3/components/image-grid-loader/types'

export type MediaItemTypes = 'icons' | 'images' | 'videos'

export type GeneralV3SliceType = {
  selectedMediaTab?: MediaItemTypes
  medias: {
    [key in MediaItemTypes]: IMediaItem[]
  }
  showAddSlide?: boolean
}

const initialState: GeneralV3SliceType = {
  selectedMediaTab: undefined,
  medias: {
    icons: [],
    images: [],
    videos: [],
  },
  showAddSlide: undefined,
}

export const generalV3Slice = createSlice({
  name: 'generalV3',
  initialState,
  reducers: {
    setSelectedMediaTab: (
      state,
      action: PayloadAction<GeneralV3SliceType['selectedMediaTab']>,
    ) => {
      state.selectedMediaTab = action.payload
    },
    setMedia: (
      state,
      action: PayloadAction<{ type: MediaItemTypes; data: IMediaItem[] }>,
    ) => {
      state.medias[action.payload.type] = action.payload.data
    },
    showAddSlideDrawer: (
      state,
      action: PayloadAction<GeneralV3SliceType['showAddSlide']>,
    ) => {
      state.showAddSlide = action.payload
    },
  },
})

export const { setSelectedMediaTab, setMedia, showAddSlideDrawer } =
  generalV3Slice.actions

export default generalV3Slice.reducer
