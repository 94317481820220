import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import ToggleButton from 'src/components/toggle-button'
import { IPolotnoComponent } from '../../types'

const LockAction = observer<IPolotnoComponent>(({ store }) => {
  const elements = store.selectedElements
  const anyLocked = elements.some((element) => element.locked)

  const handleChange = () => {
    // unlock or lock all
    elements.forEach((element) =>
      element.set({
        // can element be moved and rotated
        draggable: anyLocked,
        // can we change content of element?
        contentEditable: anyLocked,
        // can we change style of element?
        styleEditable: anyLocked,
        // can we resize element?
        resizable: anyLocked,
        // can we remove an element?
        removable: anyLocked,
      }),
    )
  }

  const show = elements.length > 0

  return show ? (
    <ToggleButton
      selected={anyLocked}
      value={anyLocked}
      onClick={handleChange}
      tooltip={anyLocked ? 'v3.object_locked' : 'v3.object_unlocked'}
    >
      <MaterialSymbol
        icon={anyLocked ? 'lock' : 'lock_open'}
        size={20}
        weight={300}
      />
    </ToggleButton>
  ) : null
})
export default LockAction
