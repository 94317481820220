export default {
  BLANK:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1080" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_ALPHA_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_101_52)"><rect width="1920" height="1080" fill="url(#decor-bg)" fill-opacity="0.8"/></g><defs><clipPath id="clip0_101_52"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_154_9)"><path fill-rule="evenodd" clip-rule="evenodd" d="M1920 0H0V327.838C76.0193 178.947 230.854 77 409.5 77C663.275 77 869 282.725 869 536.5C869 790.275 663.275 996 409.5 996C230.854 996 76.0193 894.053 0 745.162V1080H1920V0ZM0 745.162C-31.9696 682.547 -50 611.629 -50 536.5C-50 461.371 -31.9696 390.453 0 327.838V745.162Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M410 1080C111.766 1080 -130 838.234 -130 540C-130 241.766 111.766 0 410 0C708.234 0 950 241.766 950 540C950 838.234 708.234 1080 410 1080ZM410 992C160.367 992 -41.9999 789.633 -41.9999 540C-41.9999 290.367 160.367 88 410 88C659.633 88 862 290.367 862 540C862 789.633 659.633 992 410 992Z" fill="$color2"/><circle cx="490" cy="490" r="465" transform="matrix(-1 0 0 1 900 50)" stroke="$color3" stroke-width="50"/></g><defs><clipPath id="clip0_154_9"><rect width="1920" height="1080" fill="white" transform="matrix(-1 0 0 1 1920 0)"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_LEFT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H1920V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_47_109)"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H1920V327.838C1843.98 178.947 1689.15 77 1510.5 77C1256.73 77 1051 282.725 1051 536.5C1051 790.275 1256.73 996 1510.5 996C1689.15 996 1843.98 894.053 1920 745.162V1080H0V0ZM1920 745.162C1951.97 682.547 1970 611.629 1970 536.5C1970 461.371 1951.97 390.453 1920 327.838V745.162Z" fill="url(#decor-bg)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1510 1080C1808.23 1080 2050 838.234 2050 540C2050 241.766 1808.23 0 1510 0C1211.77 0 970 241.766 970 540C970 838.234 1211.77 1080 1510 1080ZM1510 992C1759.63 992 1962 789.633 1962 540C1962 290.367 1759.63 88 1510 88C1260.37 88 1058 290.367 1058 540C1058 789.633 1260.37 992 1510 992Z" fill="$color2"/><circle cx="1510" cy="540" r="465" stroke="$color3" stroke-width="50"/></g><defs><clipPath id="clip0_47_109"><rect width="1920" height="1080" fill="white"/></clipPath></defs><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  COVER_RIGHT_2:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M960 1080H0V0H960V1080Z" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
  MASK_BOTTOM_1:
    '<svg width="1920" height="1080" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="540" fill="url(#decor-bg)"/><defs><linearGradient id="decor-bg" x1="$bg-x1" x2="$bg-x2" y1="$bg-y1" y2="$bg-y2" gradientUnits="userSpaceOnUse"><stop stop-color="$bg-start"/><stop offset="1" stop-color="$bg-stop"/></linearGradient></defs></svg>',
}
