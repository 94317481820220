import React from 'react'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { useLanguage } from 'src/hooks'
import { BUTTON_TYPE, Button } from 'src/lib'
import { RootState } from 'src/store'
import { setBetaV3 } from 'src/store/decks-v3'
import { useTheme } from 'src/theme'

export const V3BetaSwitch: React.FC = React.memo(() => {
  const { t } = useLanguage()
  const { colors } = useTheme()

  const { useBeta } = useSelector(({ persistedDeckV3 }: RootState) => ({
    useBeta: persistedDeckV3.useBeta,
  }))
  const dispatch = useDispatch()
  const handleSwitch = () => {
    dispatch(setBetaV3(!useBeta))
  }

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Typography variant="caption" fontWeight={500} color={colors.text[2]}>
          {useBeta ? t('v3.activated') : t('v3.activate')}
        </Typography>
        <Chip
          sx={{
            borderRadius: '2px',
            fontSize: '10px',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          }}
          label="BETA"
        />
      </Stack>
      <div>
        <Button
          text={useBeta ? t('v3.revert_editor') : t('v3.try_new_editor')}
          type={BUTTON_TYPE.REVERSE}
          onClick={handleSwitch}
        />
      </div>
      <Link
        href="https://help.decktopus.com/en/articles/82-v3-editor-features"
        variant="body2"
        color={colors.text[2]}
        fontSize="12px"
        target="_blank"
        rel="noopener"
      >
        {t('v3.try_new_editor_info')}
      </Link>
    </Stack>
  )
})
