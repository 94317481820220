import { makeMuiStyles } from 'src/theme/muiTheme'

export const useTimelineStyles = makeMuiStyles()((theme) => ({
  newButton: {
    'height': '90px',
    'width': '160px',
    'border': `1px solid ${theme.palette.grey[300]}`,
    'borderRadius': theme.spacing(2),
    'overflow': 'hidden',
    'path': {
      fill: `${theme.palette.grey[300]}`,
    },

    ':hover': {
      borderColor: `${theme.palette.primary.main}`,
      cursor: 'pointer',

      path: {
        fill: `${theme.palette.primary.main}`,
      },
    },
  },
  content: {
    borderTop: `1px solid ${theme.palette.elevation?.outlined}`,
  },
}))
