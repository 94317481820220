import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const subTitleStyles = (theme: ITheme) => css`
  margin-top: ${theme.gap.xsmall}px;
  margin-bottom: ${theme.gap.small}px;
  font-size: ${theme.fontSize.base};

  @media (min-width: ${theme.breakpointsV2.laptop}px) {
    margin-top: ${theme.gap.small}px;
    margin-bottom: ${theme.gap.small}px;
  }
`
export const themeAreaStyles = (theme: ITheme) => css`
  display: flex;
  width: auto;
  align-items: center;
  align-content: center;
  gap: ${theme.gap.xsmall}px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  margin-top: ${theme.gap.small}px;

  @media (min-width: ${theme.breakpointsV2.tablet}px) {
    gap: ${theme.gap.medium}px;
    width: ${theme.dimensions.aiModalWidth.medium}px;
  }

  @media (min-width: ${theme.breakpointsV2.desktop}px) {
    width: ${theme.dimensions.aiModalWidth.large}px;
    gap: ${theme.gap.xlarge}px;
    margin-top: ${theme.gap.small}px;
  }
`
export const imageStyles = (selected: boolean) => (theme: ITheme) => css`
  width: ${theme.dimensions.aiModalImageWidth.xsmall}px;
  height: ${theme.dimensions.aiModalImageHeight.xsmall}px;
  flex-shrink: 0;
  outline: ${selected ? `2px solid ${theme.colors.primary.DEFAULT};` : `none`};
  border-collapse: collapse;
  border-radius: ${theme.borderRadius[8]};
  cursor: pointer;

  @media (min-width: ${theme.breakpointsV2.desktop}px) {
    width: ${theme.dimensions.aiModalImageWidth.DEFAULT}px;
    height: ${theme.dimensions.aiModalImageHeight.DEFAULT}px;
  }
`
