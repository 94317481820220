import { usePostHog } from 'posthog-js/react'
import { isProductionEnv } from 'src/helpers/env'

const usePostHogCapture = () => {
  const posthog = usePostHog()

  const capture = (event: string, properties?: Record<string, any>) => {
    if (!isProductionEnv) {
      console.log('PostHog Event:', event, properties)
      return
    }
    posthog.capture(event, properties)
  }

  return capture
}

export default usePostHogCapture
