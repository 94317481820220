import React from 'react'
import { wrapperStyles } from './styles'
import { UserClassifyLayout } from 'src/layouts/user-classify-layout'
export const UserClassifyPage: React.FC = React.memo(() => {
  return (
    <div css={wrapperStyles}>
      <UserClassifyLayout />
    </div>
  )
})
