import { Alignment, Navbar } from '@blueprintjs/core'
import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { useHistoryStyles } from './styles'
import { IPolotnoComponent } from '../../types'

const HistoryActions = observer<IPolotnoComponent>(({ store }) => {
  const { classes } = useHistoryStyles()

  return (
    <Navbar.Group align={Alignment.LEFT} className={classes.container}>
      <BaseIconButton
        disabled={!store.history.canUndo}
        onClick={() => store.history.undo()}
      >
        <MaterialSymbol icon="undo" size={20} weight={300} />
      </BaseIconButton>
      <BaseIconButton
        disabled={!store.history.canRedo}
        onClick={() => store.history.redo()}
      >
        <MaterialSymbol icon="redo" size={20} weight={300} />
      </BaseIconButton>
    </Navbar.Group>
  )
})
export default HistoryActions
