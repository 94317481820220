import React, { useCallback } from 'react'

import { alignmentPropsStyles } from './styles'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_WIDTH, Button, icons } from 'src/lib'
import { useDispatch, useSelector } from 'react-redux'
import {
  COMPONENT_ALIGN,
  COMPONENT_DIST,
  RootState,
  SAVE_STATE,
  componentAlign,
  setSaveState,
} from 'src/store'
import { useLanguage } from 'src/hooks'
import { IEditPropSection } from '../../types'
import { PropHeader } from 'src/pages/deck-page/components/edit-properties/props'

export const AlignmentProps: React.FC<IEditPropSection> = React.memo(
  ({ className, dataAttr }) => {
    const { t } = useLanguage()
    const dispatch = useDispatch()
    const { selectedComponents } = useSelector(({ canvas }: RootState) => ({
      selectedComponents: canvas.selectedComponents,
    }))

    const handleAlignment = useCallback(
      (align: COMPONENT_ALIGN) => {
        dispatch(
          componentAlign({
            components: selectedComponents,
            align,
          }),
        )
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [selectedComponents],
    )

    const handleDistribute = useCallback(
      (distribute: COMPONENT_DIST) => {
        dispatch(
          componentAlign({
            components: selectedComponents,
            distribute,
            useNewDistribution: true,
          }),
        )
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [selectedComponents],
    )

    return (
      <div css={alignmentPropsStyles} className={className} {...dataAttr}>
        <PropHeader text={t('edit.properties.alignment.position')} />
        <div className="grid actions">
          <div className="c-1">
            <Button
              className="action"
              theme={BUTTON_THEME.DARK}
              size={BUTTON_SIZE.XLARGE}
              width={BUTTON_WIDTH.FULL}
              icon={icons.left_positon}
              onClick={() => handleAlignment(COMPONENT_ALIGN.LEFT)}
              text={t('common.directions.left')}
              isLightText
            />
          </div>
          <div className="c-1">
            <Button
              className="action"
              theme={BUTTON_THEME.DARK}
              size={BUTTON_SIZE.XLARGE}
              width={BUTTON_WIDTH.FULL}
              icon={icons.vertical_center_positon}
              onClick={() => handleAlignment(COMPONENT_ALIGN.VERTICAL)}
              text={t('common.directions.middle')}
              isLightText
            />
          </div>
          <div className="c-1">
            <Button
              className="action"
              theme={BUTTON_THEME.DARK}
              size={BUTTON_SIZE.XLARGE}
              width={BUTTON_WIDTH.FULL}
              icon={icons.right_positon}
              onClick={() => handleAlignment(COMPONENT_ALIGN.RIGHT)}
              text={t('common.directions.right')}
              isLightText
            />
          </div>
          <div className="c-1">
            <Button
              className="action"
              theme={BUTTON_THEME.DARK}
              size={BUTTON_SIZE.XLARGE}
              width={BUTTON_WIDTH.FULL}
              icon={icons.distribute_vertical}
              isLightText
              onClick={() => handleDistribute(COMPONENT_DIST.HORIZONTAL)}
            />
          </div>
          <div className="c-1">
            <Button
              className="action"
              theme={BUTTON_THEME.DARK}
              size={BUTTON_SIZE.XLARGE}
              width={BUTTON_WIDTH.FULL}
              icon={icons.up_positon}
              onClick={() => handleAlignment(COMPONENT_ALIGN.TOP)}
              text={t('common.directions.up')}
              isLightText
            />
          </div>
          <div className="c-1">
            <Button
              className="action"
              theme={BUTTON_THEME.DARK}
              size={BUTTON_SIZE.XLARGE}
              width={BUTTON_WIDTH.FULL}
              icon={icons.horizontal_center_positon}
              onClick={() => handleAlignment(COMPONENT_ALIGN.HORIZONTAL)}
              text={t('common.directions.center')}
              isLightText
            />
          </div>
          <div className="c-1">
            <Button
              className="action"
              theme={BUTTON_THEME.DARK}
              size={BUTTON_SIZE.XLARGE}
              width={BUTTON_WIDTH.FULL}
              icon={icons.down_position}
              onClick={() => handleAlignment(COMPONENT_ALIGN.BOTTOM)}
              text={t('common.directions.down')}
              isLightText
            />
          </div>
          <div className="c-1">
            <Button
              className="action"
              theme={BUTTON_THEME.DARK}
              size={BUTTON_SIZE.XLARGE}
              width={BUTTON_WIDTH.FULL}
              icon={icons.distribute_horizontal}
              isLightText
              onClick={() => handleDistribute(COMPONENT_DIST.VERTICAL)}
            />
          </div>
        </div>
      </div>
    )
  },
)

AlignmentProps.displayName = 'AlignmentProps'
