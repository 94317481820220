import React, { useCallback, useMemo, useState } from 'react'
import ReactQuill from 'react-quill'
import { IPropQuill, PROP_QUILL_TOOLBAR_SIDE } from './types'
import { propQuillStyles, propQuillToolbarStyles } from './styles'
import { useDispatch } from 'react-redux'
import { setAllowDeleteShortcut } from 'src/store'
import { QuillButton } from 'src/lib/quill-button'
import { icons } from 'src/lib'
import { StringServices } from 'src/services'
import { APP_CONFIG } from 'src/config'

export const PropQuill: React.FC<IPropQuill> = React.memo(
  ({
    id,
    height = '100%',
    maxHeight,
    background,
    toolbarSide = PROP_QUILL_TOOLBAR_SIDE.TOP,
    className,
    onChange,
    value,
    contentCheck,
  }) => {
    const [checkRequired, setCheckRequired] = useState(true)
    const dispatch = useDispatch()
    const [isFocus, setIsFocus] = useState(false)

    const handleOnFocus = useCallback(() => {
      dispatch(setAllowDeleteShortcut(false))
    }, [])

    const handleOnBlur = useCallback(() => {
      setIsFocus(false)
      dispatch(setAllowDeleteShortcut(true))
    }, [])

    const checkedValue = useMemo(() => {
      if (!checkRequired) {
        return value
      }
      if (!value) {
        return `<p></p>`
      }

      if (StringServices.isWrappedWithPTagsNew(value)) {
        setCheckRequired(false)
        return value
      }

      return `<p>${value}</p>`
    }, [value, checkRequired])

    const handleSelectionChange = useCallback(
      (range: { index: number; length: number }) => {
        setIsFocus(!!range?.length)
      },
      [checkedValue],
    )

    return (
      <div
        css={propQuillStyles({ height, background, maxHeight })}
        className={className}
      >
        <div id={id} css={propQuillToolbarStyles({ isFocus, toolbarSide })}>
          <QuillButton quillHandler="ql-bold" icon={icons.bold} />
          <QuillButton
            className="quill-button"
            quillHandler="ql-italic"
            icon={icons.italic}
          />
          <QuillButton
            className="quill-button"
            quillHandler="ql-underline"
            icon={icons.underlined}
          />
          <QuillButton
            className="quill-button"
            quillHandler="ql-link"
            icon={icons.hyperlink}
          />
          <QuillButton
            quillHandler="ql-list"
            quillValue="ordered"
            icon={icons.numbered_list}
          />
          <QuillButton
            quillHandler="ql-list"
            quillValue="bullet"
            icon={icons.list}
          />
        </div>
        <ReactQuill
          value={checkedValue}
          onChange={(val: string) => {
            const orgValueContent = StringServices.getTextContent(val || '')
            const checkedValueContent = StringServices.getTextContent(
              checkedValue || '',
            )

            if (!contentCheck || orgValueContent !== checkedValueContent) {
              onChange?.(val.replace(/<img[^>]*>/g, ''))
            }
          }}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChangeSelection={handleSelectionChange}
          theme="snow"
          modules={{
            toolbar: `#${id}`,
          }}
          formats={APP_CONFIG.canvasTextAllowedFormats}
        />
      </div>
    )
  },
)
