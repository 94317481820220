import React from 'react'

import { IPropMutliInput } from './types'
import { propMutliInputStyles } from './styles'

export const PropMutliInput: React.FC<IPropMutliInput> = React.memo(
  ({ className, dataAttr, inputs }) => {
    return (
      <div css={propMutliInputStyles} className={className} {...dataAttr}>
        {inputs.map((input, index) => (
          <div className="multiple-input-area" key={index}>
            <div className="multiple-input-label">{input.label}</div>
            <div>{input.render}</div>
          </div>
        ))}
      </div>
    )
  },
)

PropMutliInput.displayName = 'PropMutliInput'
