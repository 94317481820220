import React, { useEffect } from 'react'
import { PolotnoContainer, WorkspaceWrap } from 'polotno'
import { Workspace } from 'polotno/canvas/workspace'
import useTheme from '@mui/material/styles/useTheme'

import '@blueprintjs/core/lib/css/blueprint.css'

import { Spinner } from 'src/lib'
import AutoScrollArea from '../deck-page-v3/components/scroll-area'
import '../deck-page-v3/styles/polotno-override.css'
import '../deck-page-v3/styles/scrollarea.css'
import { useStyles } from '../deck-page-v3/styles/styles'
import { IPolotnoComponent } from '../deck-page-v3/types'

export const PreviewForV3: React.FC<IPolotnoComponent> = React.memo(
  ({ store }) => {
    const theme = useTheme()
    const { classes } = useStyles()

    useEffect(() => {
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutationRecord) {
          const element = mutationRecord.target as HTMLElement
          element.style?.setProperty('overflow', null)
        })
      })
      const elements = document.getElementsByClassName(
        'polotno-workspace-inner',
      )
      if (elements && elements.length > 0) {
        const arr = Array.from(elements) as HTMLElement[]
        arr.forEach((element: HTMLElement) => {
          element.classList.add('decktopus-workspace-inner')
          const target = element
          observer.observe(target, {
            attributes: true,
            attributeFilter: ['style'],
          })
        })
      }
      return () => {
        observer.disconnect()
      }
    }, [])

    return (
      <PolotnoContainer
        style={{ display: 'flex', width: '100vw', height: '100%' }}
      >
        <WorkspaceWrap className={classes.workspaceWrapper}>
          <AutoScrollArea>
            <Workspace
              paddingX={0}
              paddingY={0}
              store={store}
              components={{
                NoPages: () => (
                  <div className={classes.noPages}>
                    <Spinner size={64} />
                  </div>
                ),
                Tooltip: () => null,
                Duplicate: () => null,
                Position: () => null,
                Remove: () => null,
                PageControls: () => null,
              }}
              backgroundColor={theme.palette.grey[800]}
              renderOnlyActivePage={true}
              activePageBorderColor={theme.palette.grey[800]}
            />
          </AutoScrollArea>
        </WorkspaceWrap>
      </PolotnoContainer>
    )
  },
)
