import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const initFooterStyles = ({
  breakpointsV2: breakpoints,
  dimensions,
  colors,
  gap,
}: ITheme) => css`
  height: ${dimensions.initPageFooterHeight.DEFAULT}px;
  background: ${colors.dark2.DEFAULT};
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  width: 100%;

  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: ${dimensions.columnWidth.DEFAULT}px;
  }

  .edge {
    &:last-child {
      display: none;
    }
  }

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0 ${gap['6xlarge']}px;
    .inner {
      width: 100%;
    }
    .edge {
      flex-grow: 1;
      width: 30%;

      &:last-child {
        display: block;
      }
    }
  }
`
