import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { IFromChoice } from './types'
import {
  BUTTON_THEME,
  BUTTON_WIDTH,
  Button,
  INPUT_THEME,
  Input,
  icons,
} from 'src/lib'
import { useDispatch } from 'react-redux'
import {
  SAVE_STATE,
  componentsUpdate,
  setAllowDeleteShortcut,
  setSaveState,
} from 'src/store'
import { PropHeader } from '../../props'
import {
  ComponentFormDataSchema,
  UpdateComponentSchema,
} from 'src/types/api/requestObjects'
import { ComponentServices } from 'src/services'
import { DeepPartial } from 'src/types'
import { VALIDATION_RULE_TYPES, useDebouncer, useLanguage } from 'src/hooks'

export const FormChoice: React.FC<IFromChoice> = React.memo(
  ({ question, components, text, index }) => {
    const dispatch = useDispatch()
    const { t } = useLanguage()

    const selectedQuestions = useMemo(
      () =>
        components?.reduce(
          (
            a: ComponentFormDataSchema['questions'] | undefined,
            c: UpdateComponentSchema,
          ) => {
            const targetField = (c.data as ComponentFormDataSchema).questions

            if (a === undefined && targetField) {
              return targetField
            }

            return targetField !== a ? undefined : a
          },
          undefined,
        ),
      [components],
    )

    const changeChoiceText = useCallback(
      (text: string) => {
        const newQuestionsData: ComponentFormDataSchema['questions'] =
          JSON.parse(JSON.stringify(selectedQuestions))

        const targetQuestion = newQuestionsData.findIndex(
          ({ questionId }) => questionId === question.questionId,
        )

        newQuestionsData[targetQuestion].options!.choices[index] = text

        const partialData: DeepPartial<ComponentFormDataSchema> = {
          questions: newQuestionsData,
        }

        const updatedComponents: UpdateComponentSchema[] =
          ComponentServices.updateComponentData<ComponentFormDataSchema>({
            components,
            partialData,
          })

        dispatch(componentsUpdate({ components: updatedComponents }))
        dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
      },
      [components, selectedQuestions, question],
    )

    const onRemoveChange = useCallback(() => {
      const newQuestionsData: ComponentFormDataSchema['questions'] = JSON.parse(
        JSON.stringify(selectedQuestions),
      )

      const targetQuestion = newQuestionsData.findIndex(
        ({ questionId }) => questionId === question.questionId,
      )

      newQuestionsData[targetQuestion].options!.choices = newQuestionsData[
        targetQuestion
      ].options!.choices.filter((_, i) => i !== index)

      const partialData: DeepPartial<ComponentFormDataSchema> = {
        questions: newQuestionsData,
      }

      const updatedComponents: UpdateComponentSchema[] =
        ComponentServices.updateComponentData<ComponentFormDataSchema>({
          components,
          partialData,
        })

      dispatch(componentsUpdate({ components: updatedComponents }))
      dispatch(setSaveState(SAVE_STATE.NOT_SAVED))
    }, [components, selectedQuestions, question])

    const [validText, setValidText] = useState(false)
    const [value, setValue] = useState<string | undefined>(text)
    const handleOnChange = useCallback(
      (val?: string) => {
        setValue(val)
      },
      [components],
    )

    const debounced = useDebouncer(
      () => {
        if (validText && value) {
          changeChoiceText(value)
        }
      },
      { delay: 500 },
    )

    useEffect(() => {
      debounced()
    }, [value, validText])

    const moreThanOneItem = useMemo(
      () => (question.options?.choices.length || 0) > 1,
      [question],
    )

    return (
      <>
        <PropHeader
          text={`${t('edit.properties.form_choice.choice')} ${index + 1}`}
        />
        <div className="grid">
          <div className={moreThanOneItem ? 'c-3 c-t-2' : 'c-full'}>
            <Input
              theme={INPUT_THEME.DARK}
              value={value}
              onChange={handleOnChange}
              onFocus={() => dispatch(setAllowDeleteShortcut(false))}
              onBlur={() => dispatch(setAllowDeleteShortcut(true))}
              constantValidation
              validation={{
                [VALIDATION_RULE_TYPES.REQUIRED]: {
                  text: t('validation.error.required', {
                    name: t('edit.properties.form.choice'),
                  }),
                },
                [VALIDATION_RULE_TYPES.MIN]: {
                  value: 3,
                  text: t('validation.min_char', {
                    value: 3,
                    name: t('edit.properties.form.choice'),
                  }),
                },
              }}
              onValidation={setValidText}
            />
          </div>
          {moreThanOneItem && (
            <div className="c-1 control-row">
              <Button
                icon={icons.trash_can}
                theme={BUTTON_THEME.RED}
                width={BUTTON_WIDTH.FULL}
                onClick={onRemoveChange}
              />
            </div>
          )}
        </div>
      </>
    )
  },
)

FormChoice.displayName = 'FormQuestionOption'
