import React from 'react'

import { IMenu } from './types'
import { wrapperStyles } from './styles'
import { MenuItem } from './components/menu-item'

export const Menu: React.FC<IMenu> = React.memo(
  ({ items, onItemClick, shadow, className, dataAttr }) => {
    return (
      <div css={wrapperStyles({ shadow })} className={className} {...dataAttr}>
        {items.map((item, index) => (
          <MenuItem
            key={`${item.label}-${index}`}
            {...item}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    )
  },
)
