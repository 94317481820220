import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ITabs, TABS_SIZE, TABS_THEME } from './types'

type IWrapperStyles = Pick<ITabs, 'theme' | 'size'>
export const wrapperStyles =
  ({ theme: tabTheme, size }: IWrapperStyles) =>
  (theme: ITheme) => css`
    display: flex;
    font-weight: ${theme.fontWeight.medium};
    justify-content: space-around;
    padding: ${theme.spacingV2['3xsmall']};
    border-radius: ${theme.borderRadius[4]};

    ${tabTheme === TABS_THEME.LIGHT
      ? `
				background: ${theme.colors.primary[20]};
				color: ${theme.colors.primary.DEFAULT}; `
      : `
				background: ${theme.colors.dark.DEFAULT};
				color: ${theme.colors.white.DEFAULT};`}

    ${size === TABS_SIZE.SMALL
      ? `
				font-size: ${theme.fontSize.xsmall};
				height: 30px;
				width: 260px;`
      : size === TABS_SIZE.BIG
        ? `
				font-size: ${theme.fontSize.medium};
				height: 36px;
				width: 372px;`
        : `
				font-size: ${theme.fontSize.medium};
				height: 36px;
				width: 100%;
				`}
  `
