import React from 'react'

import { ITabSelection } from './types'
import { tabSelectionStyles } from './styles'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE, Button } from 'src/lib'
import { CREATE_SLIDE_TAB } from '../../types'
import { useLanguage } from 'src/hooks'

export const TabSelection: React.FC<ITabSelection> = React.memo(
  ({ onChange, selected, className, dataAttr }) => {
    const { t } = useLanguage()
    return (
      <div css={tabSelectionStyles} className={className} {...dataAttr}>
        <Button
          text={t('edit.add_new_slide_modal.slide_layouts')}
          type={
            selected === CREATE_SLIDE_TAB.PREDEFINED
              ? BUTTON_TYPE.DEFAULT
              : BUTTON_TYPE.GHOST
          }
          onClick={() => onChange?.(CREATE_SLIDE_TAB.PREDEFINED)}
          theme={BUTTON_THEME.WHITE}
          size={BUTTON_SIZE.XLARGE}
        />
        <Button
          text={t('edit.add_new_slide_modal.custom_layouts')}
          type={
            selected === CREATE_SLIDE_TAB.OWN
              ? BUTTON_TYPE.DEFAULT
              : BUTTON_TYPE.GHOST
          }
          onClick={() => onChange?.(CREATE_SLIDE_TAB.OWN)}
          theme={BUTTON_THEME.WHITE}
          size={BUTTON_SIZE.XLARGE}
        />
      </div>
    )
  },
)

TabSelection.displayName = 'TabSelection'
