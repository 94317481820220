import { COLOR } from './types'

export const secondary = {
  DEFAULT: COLOR.secondary_100,
  90: COLOR.secondary_90,
  80: COLOR.secondary_80,
  70: COLOR.secondary_70,
  60: COLOR.secondary_60,
  50: COLOR.secondary_50,
  40: COLOR.secondary_40,
  30: COLOR.secondary_30,
  20: COLOR.secondary_20,
  10: COLOR.secondary_10,
}
