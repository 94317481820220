import React, { Fragment } from 'react'
import { IAwards } from './types'
import { AwardItem } from './award-item'
import { lineStyles, wrapperStyles } from './styles'

export const Awards: React.FC<IAwards> = React.memo(
  ({ items, className, dataAttr }) => (
    <div css={wrapperStyles} className={className} {...dataAttr}>
      {items?.map(({ text, rate }, i) => (
        <Fragment key={i}>
          <AwardItem text={text} rate={rate} />
          {i < items.length - 1 && <span css={lineStyles}></span>}
        </Fragment>
      ))}
    </div>
  ),
)
