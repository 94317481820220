import { css } from '@emotion/react'
import { IAvatar } from './types'
import { ITheme } from 'src/theme'

type IWrapperStyles = Pick<IAvatar, 'fontSize' | 'size' | 'onClick' | 'fitView'>

export const wrapperStyles = ({
  fontSize,
  size,
  fitView,
  onClick,
}: IWrapperStyles) => css`
  position: relative;
  display: inline-flex;
  font-size: ${fontSize}px;
  margin-left: ${(size || 0) / 2}px;
  ${fitView
    ? `
			width: 100%;
			height: 100%;`
    : ``}
  ${onClick ? `cursor: pointer;` : ''}
`
type IAvatarImageStyles = Pick<IAvatar, 'size' | 'border' | 'fitView'> & {
  bg?: string
}

export const avatarImageStyles =
  ({ size, border, bg, fitView }: IAvatarImageStyles) =>
  (theme: ITheme) => css`
    position: relative;
    display: flex;
    border-radius: ${theme.borderRadius.DEFAULT};
    border-color: ${theme.colors.white.DEFAULT};
    border-style: solid;
    border-width: ${fitView ? 0 : border}px;
    width: ${fitView ? '100%' : `${size}px`};
    height: ${fitView ? '100%' : `${size}px`};
    color: ${theme.colors.white.DEFAULT};
    background: ${bg};
    margin-left: -${(size || 0) / 2}px;
    overflow: hidden;

    .avatar-image {
      width: 100%;
      height: 100%;
      display: block;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  `

export const textStyles = (theme: ITheme) => css`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-weight: ${theme.fontWeight.medium};
`
