import { dataAttrType } from 'src/types'

export enum MEMBER_STATUS {
  MEMBER = 'Member',
  PENDING = 'Pending',
  ADMIN = 'Admin',
}

export interface IMembersRole {
  className?: string
  dataAttr?: dataAttrType
  status: MEMBER_STATUS
}
