import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

export const useActiveSlideData = () => {
  const { slideData, activeSlideDataID } = useSelector(
    ({ edit }: RootState) => ({
      activeSlideDataID: edit.activeSlideDataID,
      slideData: edit.activeSlideData.data,
    }),
  )

  return slideData?.find(({ id }) => id === activeSlideDataID)
}
