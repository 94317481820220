import React, { useMemo } from 'react'
import { useLanguage, useWindowSize } from 'src/hooks'
import { useNavigate, useLocation } from 'react-router-dom'
import { SideMenu } from 'src/components/side-menu'
import { ProfileBadge } from 'src/lib/profile-badge'
import { BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'
import { IOrganizationMenu } from './types'
import { orgMenuStyles, orgMenuCloseStyles } from './styles'
import { ORGANIZATION_MEMBERS_PATH, ORGANIZATION_DETAILS_PATH } from './consts'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'

export const OrganizationMenu: React.FC<IOrganizationMenu> = React.memo(
  ({ isMenuOpen = true, onClose }) => {
    const { t } = useLanguage()
    const location = useLocation()
    const navigate = useNavigate()
    const theme = useTheme()
    const size = useWindowSize()

    const isMobileView = useMemo(
      () => size.width <= theme.breakpointsV2.tablet,
      [size],
    )
    const handleNavigate = (path: string) => {
      navigate(path, { replace: true })
      {
        isMobileView && onClose()
      }
    }

    const { orgName, orgLogo } = useSelector(({ org }: RootState) => ({
      orgName: org.details?.name ?? '',
      orgLogo: org.details?.logoUrl,
    }))
    return (
      <div css={orgMenuStyles(isMenuOpen)}>
        {isMenuOpen}

        <ProfileBadge
          data={[
            {
              name: orgName,
              image: orgLogo,
            },
          ]}
          name={orgName}
          className="profile-badge"
        />
        <SideMenu
          items={[
            {
              label: t('organization.member_settings'),
              icon: icons.team,
              isActive: location.pathname === ORGANIZATION_MEMBERS_PATH,
              onClick: () => handleNavigate(ORGANIZATION_MEMBERS_PATH),
            },
            {
              label: t('organization.organization_details'),
              icon: icons.settings,
              isActive: location.pathname === ORGANIZATION_DETAILS_PATH,
              onClick: () => handleNavigate(ORGANIZATION_DETAILS_PATH),
            },
          ]}
        />
        <div css={orgMenuCloseStyles}>
          <Button
            icon={icons.close}
            theme={BUTTON_THEME.GRAY}
            type={BUTTON_TYPE.GHOST}
            isLink
            iconSize={20}
            onClick={onClose}
          />
        </div>
      </div>
    )
  },
)
