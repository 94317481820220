import React from 'react'
import { ITabs, TABS_SIZE, TABS_THEME } from 'src/lib/tabs'
import { TabItem } from './tab-item'
import { wrapperStyles } from './styles'
export const Tabs: React.FC<ITabs> = React.memo(
  ({
    size = TABS_SIZE.DEFAULT,
    tabs,
    theme = TABS_THEME.DARK,
    onChange,
    activeTab,
    className,
    dataAttr,
  }) => {
    return (
      <div
        css={wrapperStyles({ theme, size })}
        className={className}
        {...dataAttr}
      >
        {tabs.map((label, index) => (
          <TabItem
            isActive={activeTab === index}
            key={index}
            label={label}
            onChange={() => onChange(index)}
            theme={theme}
          />
        ))}
      </div>
    )
  },
)
