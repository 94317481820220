import { observer } from 'mobx-react-lite'

import Stack from '@mui/material/Stack'
import { EditAiAssistant } from 'src/components/edit-ai-assistant'
import AutoScrollArea from '../scroll-area'

const AiAssistantPanel = observer(() => {
  return (
    <AutoScrollArea>
      <Stack sx={{ p: 3, overflow: 'hidden' }}>
        <EditAiAssistant />
      </Stack>
    </AutoScrollArea>
  )
})

export default AiAssistantPanel
