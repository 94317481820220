import { dataAttrType } from 'src/types'
import { icons } from 'src/lib'

export enum BOX_BUTTON_ICON_FRAME {
  DEFAULT = 'default',
  OUTLINE = 'outline',
  SQUARE = 'square',
  CIRCLE = 'circle',
}

export interface IPropBoxButton {
  name?: string
  icon?: icons
  iconFrame?: BOX_BUTTON_ICON_FRAME
  iconSize?: number
  hoverIcon?: icons
  onClick?: () => void
  isActive?: boolean
  className?: string
  dataAttr?: dataAttrType
}
