import { ComponentTypes, TextAlignmentsHorizontal } from 'src/types/api/enums'
import { IElementDefinition } from '../types'
import { MediaDisplay } from '../components/media-display'
import { icons } from 'src/lib'
import { ComponentQuoteDataSchema } from 'src/types/api/requestObjects'
import i18n from 'src/i18n/config'

export const quoteDef = (lng: string): IElementDefinition => {
  return {
    display: {
      name: i18n.t('edit.definitions.quote.display_name', { lng }),
      styles: {},
      icon: icons.quote,
      Component: MediaDisplay,
    },
    schemas: [
      {
        tempId: '',
        type: ComponentTypes.QUOTE,
        isLocked: false,
        positions: {
          x: 0,
          y: 0,
          width: 900,
          height: 600,
          rotate: 0,
          scale: 1,
        },
        style: {},
        data: {
          quote: `<p>${i18n.t('edit.definitions.quote.quote', { lng })}</p>`,
          author: `<p><em>${i18n.t('edit.definitions.quote.author', {
            lng,
          })}</em></p>`,
          style: {
            quote: {
              color: {
                text: {
                  colors: ['var(--quote)'],
                  rotation: 0,
                },
                background: {
                  colors: ['#000000'],
                  rotation: 0,
                },
              },
              font: {
                family: 'primary',
                size: '12em',
                alignmentHorizontal: TextAlignmentsHorizontal.LEFT,
              },
            },
            author: {
              color: {
                text: {
                  colors: ['var(--quoteAuthor)'],
                  rotation: 0,
                },
                background: {
                  colors: ['#000000'],
                  rotation: 0,
                },
              },
              font: {
                family: 'primary',
                size: '10em',
                alignmentHorizontal: TextAlignmentsHorizontal.LEFT,
              },
            },
          },
        } as ComponentQuoteDataSchema,
      },
    ],
  }
}
