import { ITheme } from 'src/theme'
import { FOLDER_TYPE, IFolderItem } from './types'
import { css } from '@emotion/react'

interface IHoverType {
  isHover: boolean
  isActive: boolean
}

type IWrapperStyles = Pick<IFolderItem, 'type'> & IHoverType

export const wrapperStyles =
  ({ type, isHover, isActive }: IWrapperStyles) =>
  (theme: ITheme) => css`
    position: relative;
    width: ${theme.dimensions.columnWidth.DEFAULT}px;
    height: 48px;
    display: flex;
    align-items: center;
    gap: ${theme.gap.xsmall}px;
    background: ${theme.colors.white.DEFAULT};
    border-radius: ${theme.borderRadius[6]};
    box-shadow: ${theme.boxShadow[1]};
    border-width: 2px;
    cursor: pointer;
    border-style: ${type === FOLDER_TYPE.CREATE ? 'dashed' : 'solid'};
    border-color: ${isHover || isActive
      ? theme.colors.primary.DEFAULT
      : theme.colors.outline.DEFAULT};

    padding: 0 ${theme.gap['medium']}px;

    transition: border-color 0.3s;
  `

export const menuStyles = (theme: ITheme) => css`
  position: absolute;
  top: calc(100% + ${theme.gap['3xsmall']}px);
  right: 0;
  z-index: 1000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const icontextWrapperStyles = (theme: ITheme) => css`
  display: flex;
  width: 100%;
  height: 100%;
  gap: ${theme.gap.xsmall}px;
  align-items: center;
`
export const textStyles =
  ({ type, isHover, isActive }: IWrapperStyles) =>
  (theme: ITheme) => css`
    font-size: ${theme.fontSize.small};
    font-weight: ${theme.fontWeight.medium};
    color: ${type === FOLDER_TYPE.CREATE || isHover || isActive
      ? theme.colors.primary.DEFAULT
      : theme.colors.text[2]};
    transition: color 0.3s;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
  `

export const iconStyles =
  ({ type, isHover, isActive }: IWrapperStyles) =>
  (theme: ITheme) => css`
    fill: ${type === FOLDER_TYPE.CREATE || isHover || isActive
      ? theme.colors.primary.DEFAULT
      : theme.colors.text[2]};
    transition: fill 0.3s;
    min-width: ${theme.fontSize.xlarge};
  `

export const menuIconStyles = (theme: ITheme) => css`
  fill: ${theme.colors.text[2]};
  transition: fill 0.3s;
  min-width: ${theme.fontSize.xlarge};

  &:hover {
    fill: ${theme.colors.primary.DEFAULT};
  }
`
