import { observer } from 'mobx-react-lite'
import { MaterialSymbol } from 'react-material-symbols'
import BaseIconButton from 'src/components/base-icon-button'
import { IPolotnoComponent } from '../../types'

const DuplicateAction = observer<IPolotnoComponent>(({ store }) => {
  const handleClick = () => {
    store.selectedElements.forEach((element) => {
      const newElement = element.clone()
      newElement.set({
        x: newElement.x + 24,
        y: newElement.y + 24,
      })
    })
  }

  return (
    <BaseIconButton
      onClick={handleClick}
      sx={{
        padding: '6px',
      }}
      tooltip="v3.duplicate_elements"
    >
      <MaterialSymbol icon="content_copy" size={20} weight={300} />
    </BaseIconButton>
  )
})

export default DuplicateAction
