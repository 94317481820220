import React, { useCallback, useMemo, useRef, useState } from 'react'
import { IMemberRow } from './types'
import {
  wrapperStyles,
  iconStyles,
  menuStyles,
  memberRowStyles,
  iconHoverStyles,
} from './styles'
import { MembersName } from '../members-name'
import { MEMBER_STATUS, MembersRole } from '../members-role'
import {
  BUTTON_THEME,
  Dialog,
  IMenuItem,
  Icon,
  Menu,
  Modal,
  icons,
} from 'src/lib'
import { useClickOutside } from 'src/hooks'
import { useTheme } from 'src/theme'
import { useOrgApi, useLanguage } from 'src/hooks'
import { RootState } from 'src/store'
import { useSelector } from 'react-redux'

export const MemberRow: React.FC<IMemberRow> = React.memo(
  ({ className, dataAttr, item, width, smallInputSize }) => {
    const { t } = useLanguage()

    const [menuButtonState, setMenuButtonState] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const {
      updateOrgUserStatus,
      removeOrgUser,
      inviteUsers,
      removePendingInvitation,
    } = useOrgApi()

    const onClickHandler = useCallback(() => {
      setMenuButtonState(!menuButtonState)
    }, [menuButtonState])

    const theme = useTheme()

    const { workspaceId } = useSelector(({ workspace }: RootState) => ({
      workspaceId: workspace.id,
    }))

    const updateStatus = useCallback(
      (status: MEMBER_STATUS) => {
        updateOrgUserStatus({
          id: workspaceId!,
          isAdmin: status === MEMBER_STATUS.ADMIN ? true : false,
          userID: item.id!,
        })
        setMenuButtonState(false)
      },
      [workspaceId, item, menuButtonState],
    )

    const deleteOrgUser = useCallback(() => {
      removeOrgUser({
        id: workspaceId!,
        userID: item.id!,
      })
      setDeleteModal(false)
    }, [workspaceId, item])

    const openDeleteModal = useCallback(() => {
      setDeleteModal(true)
      setMenuButtonState(false)
    }, [deleteModal, menuButtonState])

    const onDeleteHandler = useCallback(() => {
      deleteOrgUser()
    }, [deleteModal])

    const resetInvitation = useCallback(() => {
      inviteUsers({ id: workspaceId!, emails: [item.email], isResend: true })
      setMenuButtonState(false)
    }, [workspaceId, item])

    const cancelInvitation = useCallback(() => {
      removePendingInvitation({ id: workspaceId!, inviteID: item.id! })
      setMenuButtonState(false)
    }, [workspaceId, item])

    const orgItems = useMemo<IMenuItem[]>(
      () => [
        {
          label: t('common.roles.admin'),
          onClick: () => updateStatus(MEMBER_STATUS.ADMIN),
          isSelected: item.status === MEMBER_STATUS.ADMIN ? true : false,
          rightIcon: item.status === MEMBER_STATUS.ADMIN ? icons.done : null,
        },
        {
          label: t('common.roles.member'),
          rightIcon: item.status === MEMBER_STATUS.MEMBER ? icons.done : null,
          isSelected: item.status === MEMBER_STATUS.MEMBER ? true : false,
          onClick: () => updateStatus(MEMBER_STATUS.MEMBER),
        },
        {
          label: t('common.actions.remove'),
          color: theme.colors.error.DEFAULT,
          onClick: openDeleteModal,
        },
      ],
      [item],
    )

    const pendingItems = useMemo<IMenuItem[]>(
      () => [
        { label: t('organization.reset_invitation'), onClick: resetInvitation },
        {
          label: t('organization.cancel_invitation'),
          color: theme.colors.error.DEFAULT,
          onClick: cancelInvitation,
        },
      ],
      [item],
    )

    const clickOutsidehandler = () => {
      setMenuButtonState(false)
    }

    const menuRef = useRef(null)

    useClickOutside(menuRef, clickOutsidehandler)

    return (
      <div css={wrapperStyles} className={className} {...dataAttr}>
        <div css={memberRowStyles({ width })}>
          <MembersName
            items={item}
            width={width}
            smallInputSize={smallInputSize}
          />
          {!smallInputSize && <MembersRole status={item.status} />}
        </div>
        {item.canUserEdit && (
          <div css={iconStyles}>
            <div onClick={onClickHandler}>
              <Icon
                icon={icons.menu_horizontal}
                css={iconHoverStyles}
                color={theme.colors.text[2]}
              />
            </div>
            {menuButtonState && (
              <div ref={menuRef} css={menuStyles}>
                <Menu
                  items={
                    item.status == MEMBER_STATUS.PENDING
                      ? pendingItems
                      : orgItems
                  }
                  shadow={true}
                />
              </div>
            )}
          </div>
        )}
        <Modal isOpen={deleteModal} onClose={() => setDeleteModal(false)}>
          <Dialog
            title={t('organization.delete_member')}
            submitLabel={t('common.actions.delete')}
            cancelLabel={t('common.actions.cancel')}
            cancelTheme={BUTTON_THEME.GRAY}
            onCancel={() => setDeleteModal(false)}
            submitTheme={BUTTON_THEME.RED}
            text={[
              t('organization.delete_member_title', { name: item.name }),
              t('organization.delete_member_text', { name: item.name }),
            ]}
            onSubmit={onDeleteHandler}
            onCloseClick={() => setDeleteModal(false)}
            promptValue={item.name}
            showCloseButton={false}
          />
        </Modal>
      </div>
    )
  },
)

MemberRow.displayName = 'OrgMemberRow'
