import { css } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ListTypes } from 'src/types/api/enums'

export const listPropsStyles = ({ gap }: ITheme) => css`
  display: flex;
  flex-direction: column;
  gap: ${gap.xsmall}px;
  padding-bottom: ${gap['8xlarge']}px;
`

interface IListItemStyles {
  isExpanded?: boolean
  type?: ListTypes
  isLogo?: boolean
  isGrid?: boolean
}
export const listItemStyles =
  ({ isExpanded }: IListItemStyles) =>
  ({ colors, gap, borderRadius, breakpointsV2: breakpoints }: ITheme) => css`
    background: ${colors.dark.DEFAULT};
    padding: ${gap.small}px;
    border: 1px solid ${isExpanded ? colors.primary.DEFAULT : 'transparent'};
    overflow: hidden;
    border-radius: 0 ${borderRadius[4]} ${borderRadius[4]} 0;
    transition: background 0.3s;
    ${!isExpanded &&
    `
      cursor: pointer;
      &:hover {
        background: ${colors.dark[90]};
      }
    `}

    width: 252px;
    @media (min-width: ${breakpoints.desktop}px) {
      width: 340px;
    }
  `

export const detailStyles =
  ({ isExpanded, type, isLogo, isGrid }: IListItemStyles) =>
  ({ gap }: ITheme) => css`
    display: flex;
    flex-direction: column;
    gap: ${gap.xsmall}px;
    max-height: ${isExpanded ? 'none' : '0 '};
    overflow: hidden;
    transform: translateZ(0);
    transition: max-height 200ms;
    margin-bottom: ${isExpanded ? `${gap.xsmall}px` : 0};

    &.enter {
      max-height: 0;
      margin-bottom: ${gap.xsmall}px;
    }
    &.enter-active {
      max-height: ${type === ListTypes.MEDIA
        ? isLogo || isGrid
          ? 149
          : 253
        : 155}px;
      margin-bottom: ${gap.xsmall}px;
    }
    &.enter-done {
      max-height: none;
      margin-bottom: ${gap.xsmall}px;
    }
    &.exit {
      max-height: ${type === ListTypes.MEDIA
        ? isLogo || isGrid
          ? 149
          : 253
        : 155}px;
    }
    &.exit-active {
      max-height: 0;
    }
    &.exit-done {
      max-height: 0;
    }
  `
export const footerStyles = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const bulletItemStyles = () => css`
  max-width: 90%;
`

export const listMediaStyles = ({ colors, borderRadius, gap }: ITheme) => css`
  display: flex;
  gap: ${gap.xsmall}px;

  .media-preview {
    width: 50%;
    background: ${colors.dark2.DEFAULT};
    border-radius: ${borderRadius[4]};

    .image-preview {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .media-buttons {
    display: flex;
    flex-direction: column;
    gap: ${gap.xsmall}px;
  }
`

export const listLogoStyles = ({ colors, borderRadius, gap }: ITheme) => css`
  .logo-preview {
    height: 100px;
    width: 100%;
    background: ${colors.dark2.DEFAULT};
    border-radius: ${borderRadius[4]};

    .image-preview {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    margin-bottom: ${gap.xsmall}px;
  }
`
export const draggableListItemStyles =
  (isDragging: boolean) =>
  ({ colors, gap, borderRadius }: ITheme) => css`
    display: flex;
    align-items: stretch;

    .drag-handle {
      display: flex;
      align-items: center;
      padding: 0 ${gap.xsmall}px;
      cursor: move;
      background: ${colors.dark[90]};
      border-top-left-radius: ${borderRadius[4]};
      border-bottom-left-radius: ${borderRadius[4]};
    }
    opacity: ${isDragging ? 0.4 : 1};
    background: ${colors.dark.DEFAULT};
    border-radius: ${borderRadius[4]};
    overflow: hidden;
    transition: opacity 0.2s;

    > div:last-child {
      flex-grow: 1;
    }
  `

export const dragLayerStyles = css`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const dragPreviewStyles = (
  currentOffset: { x: number; y: number } | null,
) => {
  if (!currentOffset) {
    return { display: 'none' as const }
  }

  const { x, y } = currentOffset
  const transform = `translate3d(${x}px, ${y}px, 0) rotate(3deg)`

  return {
    position: 'absolute' as const,
    opacity: 0.8,
    transform,
    WebkitTransform: transform,
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
  }
}
