import { css } from '@emotion/react'
import { ITheme } from 'src/theme'

export const dashboardCardStyles = (theme: ITheme) => css`
  background: ${theme.colors.white.DEFAULT};
`

export const pivotIconStyles = ({ gap }: ITheme) => css`
  width: 18px;
  height: 18px;
  margin-right: ${gap['3xsmall']}px;
`
