import { css, keyframes } from '@emotion/react'
import { ITheme } from 'src/theme'
import { ISpinner } from './types'

type IWrapperStyles = Pick<ISpinner, 'size' | 'speed'>
export const wrapperStyles =
  ({ size, speed }: IWrapperStyles) =>
  (theme: ITheme) => css`
    display: flex;
    flex-direction: column;
    height: ${size}px;
    width: ${size}px;

    .half {
      position: relative;
      height: ${(size || 14) / 2}px;
      width: ${size || 14}px;
      overflow: hidden;

      &.top .square {
        animation-delay: ${(speed || 2000) / 4}ms;
      }

      &.bottom {
        transform: rotate(180deg);
      }

      .square {
        display: block;
        width: ${size}px;
        height: ${size}px;
        overflow: hidden;
        animation-name: ${halfCircleAnim};
        animation-duration: ${speed}ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform: rotate(-90deg);

        .mask {
          position: relative;
          display: block;
          overflow: hidden;
          width: ${(size || 10) / 2}px;
          height: ${size}px;

          &:before {
            content: '';
            width: ${size}px;
            height: ${size}px;
            border-radius: 50%;
            box-sizing: border-box;
            border: 2px solid ${theme.colors.primary.DEFAULT};
            position: absolute;
          }
        }
      }
    }
  `

const halfCircleAnim = keyframes`
	0% {
		transform: rotate(-90deg);
	}
	25% {
		transform: rotate(90deg);
	}
	50% {
		transform: rotate(90deg);
	}
	75% {
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(270deg);
	}
	`
