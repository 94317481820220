import React, { useState } from 'react'
import { UsePurpose } from 'src/components/user-classify/use-purpose/UsePurpose'
import { BUTTON_SIZE, BUTTON_THEME, BUTTON_TYPE, Button, icons } from 'src/lib'
import { useLanguage } from 'src/hooks'
import { backButtonStyles } from 'src/components/user-classify/styles'
export const UserClassifyLayout: React.FC = React.memo(() => {
  const [step, setStep] = useState(1)
  const { t } = useLanguage()
  const handleNextStep = () => {
    setStep(step + 1)
  }
  const handlePreviousStep = () => {
    setStep(step - 1)
  }
  return (
    <div>
      {step !== 1 && (
        <div css={backButtonStyles}>
          <Button
            icon={icons.chevron_left}
            size={BUTTON_SIZE.XSMALL}
            theme={BUTTON_THEME.PRIMARY}
            type={BUTTON_TYPE.GHOST}
            text={t('common.actions.back')}
            onClick={handlePreviousStep}
          />
        </div>
      )}
      <UsePurpose onNextStep={handleNextStep} step={step} setStep={setStep} />
    </div>
  )
})
export default UserClassifyLayout
