import React, { useCallback, useEffect, useRef, useState } from 'react'

import { ICanvasContextMenu } from './types'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { ContextMenu, IContextMenu, Menu } from 'src/lib'
import { useClickOutside } from 'src/hooks'
import { useLanguage } from 'src/hooks'

export const CanvasContextMenu: React.FC<ICanvasContextMenu> = React.memo(
  () => {
    const { hasSelectedComponent } = useSelector(({ canvas }: RootState) => ({
      hasSelectedComponent: canvas.selectedComponents.length,
      clipboard: canvas.componentClipboard,
    }))

    const [isMenuOpen, setMenuOpen] = useState<IContextMenu['pos'] | null>(null)
    const menuRef = useRef(null)
    const { t } = useLanguage()

    useClickOutside(menuRef, () => setMenuOpen(null), { events: ['wheel'] })

    const listener = useCallback(
      (e: any) => {
        if (!hasSelectedComponent) {
          setMenuOpen(e.detail)
        }
      },
      [hasSelectedComponent],
    )

    useEffect(() => {
      document.addEventListener('canvas-context-menu', listener)
      return () => {
        document.removeEventListener('canvas-context-menu', listener)
      }
    }, [listener])

    if (!isMenuOpen) {
      return null
    }

    return (
      <ContextMenu pos={isMenuOpen} ref={menuRef}>
        <Menu
          shadow
          items={[
            {
              label: t('common.actions.paste'),
            },
          ]}
        />
      </ContextMenu>
    )
  },
)

CanvasContextMenu.displayName = 'CanvasContextMenu'
